@charset "UTF-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project        :   SPRUHA - HTML5 Bootstrap Admin Template
Version        :   V.1
Create Date    :   03/10/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/
/*
1.	Bootstrap-custom
2.	Accordions
3.	Alerts
4.	Badges
5.	Breadcrumbs
6.	Buttons
7.	Cards
8.	Carousel
9.	Dropdown
10.	Forms
11.	Grid
12.	Input-group
13.	Modals
14.	Navigation
15.	Pagination
16.	Popover
17.	Progress
18.	Tables
19.	Datatable
20.	Toast
21.	Tooltip
22.	Color
23.	Main-content
24.	Header
25.	Footer
26.	Sidebar
27.	Right-sidebar
28.	Profile
29.	Iconbar
30.	Datepicker
31.	Datetimepicker
32.	Jqvmap
33.	Chart
34.	Parsely
35.	Quill
36.	Rangeslider
37.	Select2
38.	Spectrum
39.	Calendar
40.	Chat
41.	Contacts
42.	Error
43.	Invoice
44.	Mail
45.	Profile
46.	Account-setting
47.	Pricing
48.	Scrumboard
49.	Todotask
50.	Product-list
51.	Whishlist
52.	Border
53.	Height
54.	Margin
55.	Opacity
56.	Padding
57.	Position
58.	Typography
59.	Width
60.	Tags
61.	Tabs
62.	Navbar
63.	Wizard
64.	P-scroll
65.	Radio
66.	Rating
67.	Timeline
68.	Countdown
69.	Image
70.	List
71.	Checkbox
72.	Toggle
73.	Listgroup
74.	Jumborton
75.	Custom-control
76.	Element-sizes
77.	Custom-styles
*/
/* ############### FONTS IMPORT ############### */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,800");
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap");

/*Color variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*Dark Theme*/
/*Dark Theme*/
/* ########## BOOTSTRAP OVERRIDES ########## */
/* ###### Bootstrap ###### */
:root {
    --primary-bg-color: #6259ca;
    --primary-bg-hover: #403fad;
    --primary-transparentcolor: rgba(98, 89, 202, 0.16);
    --primary-bg-border: #6259ca;
    --dark-theme: #0e0e23;
    --dark-body: #24243e;
    --dark-border: rgba(255, 255, 255, 0.15);
    --dark-color: #d0d0e2;
    --dark-primary: #6259ca;
    --indigo: #4b0082;
    --purple: #6f42c1;
    --pink: #f1388b;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #ffffff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

* {
    box-sizing: border-box;
}

*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: #ffffff;
    font-family: "Roboto", sans-serif !important;
    background: #eaedf7;
    color: #1d212f;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    -webkit-font-feature-settings: "liga" 0;
    font-feature-settings: "liga" 0;
    overflow-y: scroll;
    width: 100%;
    position: relative;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #e8e8f7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
    list-style-type: none;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
    display: inline-block;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    text-decoration: none;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
    outline: 0;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #a8afc7;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
    text-transform: none;
}

button:focus {
    outline: none;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

select {
    text-transform: none;
    word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type=radio],
input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto;
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: 2.1875rem;
}

h2,
.h2 {
    font-size: 1.75rem;
}

h3,
.h3 {
    font-size: 1.53125rem;
}

h4,
.h4 {
    font-size: 1.3125rem;
}

h5,
.h5 {
    font-size: 1.09375rem;
}

h6,
.h6 {
    font-size: 0.875rem;
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled,
.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #a8afc7;
}

.blockquote-footer::before {
    content: "— ";
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
    border-radius: 0px;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #a8afc7;
}

code {
    font-size: 87.5%;
    color: #f10075;
    word-break: break-word;
}

a>code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #ffffff;
    background-color: #8f9cc0;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    padding: 1rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 3px;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    text-shadow: 0 1px white;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    margin-top: 0;
    margin-bottom: 0;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container,
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

/* ###### Bootstrap ###### */
/* ###### Accordion ###### */
.accordion-color .card-header a.collapsed {
    color: rgba(255, 255, 255, 0.8) !important;
    font-weight: 500;
    letter-spacing: -0.1px;
}

.accordion-color .card-header a.collapsed:hover,
.accordion-color .card-header a.collapsed:focus {
    color: #ffffff;
}

.accordion-faq .accordion .card-header a {
    background-color: #f5f5fd;
}

.accordion .card {
    border-width: 0;
}

.accordion .card+.card {
    margin-top: 1px;
}

.accordion .card-header {
    padding: 0;
    background-color: transparent;
    font-weight: 500;
}

.accordion .card-header a {
    display: block;
    padding: 14px 20px;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    background-color: #e1e1f9;
}

.accordion .card-header a.collapsed {
    color: #3c4858;
    background-color: #e1e1f9;
    border-bottom-color: transparent;
}

.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
    color: #8f9cc0;
    background-color: #eaedf7;
    background-color: #f5f5fd;
}

.accordion .card-body {
    padding: 15px;
    background-color: #ffffff;
    transition: none;
    border: 1px solid #e8e8f7;
    border-bottom: 0;
}

.accordion>.card {
    overflow: hidden;
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}

.accordion>.card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion>.card .card-header {
    margin-bottom: -1px;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}

.accordion .card-header a {
    background-color: #e1e1f9;
}

/* ###### Accordion ###### */
/* ###### Alerts ###### */
.alert {
    border-width: 0;
    padding: 12px 15px;
    border-radius: 0;
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 3px;
}

.alert .close {
    text-shadow: none;
    opacity: 0.9;
}

.alert strong {
    font-weight: 600;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 3.8125rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #1b3585;
    background-color: #d6e0ff;
    border-color: #c6d4ff;
}

.alert-primary hr {
    border-top-color: #adc1ff;
}

.alert-primary .alert-link {
    color: #12245b;
}

.alert-secondary {
    color: #f1388b;
    background-color: #f9e5db;
    border-color: #f9e5db;
}

.alert-secondary hr {
    border-top-color: #cacfdb;
}

.alert-secondary .alert-link {
    color: #f1388b;
}

.alert-success {
    color: #1f5c01;
    background-color: #d8efcc;
    border-color: #c8e9b8;
}

.alert-success hr {
    border-top-color: #b9e3a5;
}

.alert-success .alert-link {
    color: #0e2a00;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert-info hr {
    border-top-color: #abdde5;
}

.alert-info .alert-link {
    color: #062c33;
}

.alert-warning {
    color: #856404;
    background-color: #ffeeba;
    border-color: #ffeeba;
}

.alert-warning hr {
    border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
    color: #533f03;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-danger hr {
    border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
    color: #491217;
}

.alert-light {
    color: #7f7f81;
    background-color: #fdfdfe;
    border-color: #fcfcfd;
}

.alert-light hr {
    border-top-color: #ededf3;
}

.alert-light .alert-link {
    color: #666667;
}

.alert-dark {
    color: #1f2533;
    background-color: #d8dae0;
    border-color: #c8ccd3;
}

.alert-dark hr {
    border-top-color: #babfc8;
}

.alert-dark .alert-link {
    color: #0c0e13;
}

[class*=alert-outline-] {
    border: 1px solid transparent;
}

.alert-outline-success {
    border-color: #36c54d;
    color: #19b159;
}

.alert-outline-info {
    border-color: #1ab6cf;
    color: #01b8ff;
}

.alert-outline-warning {
    border-color: #ffdb6d;
    color: #d39e00;
}

.alert-outline-danger {
    border-color: #eb8c95;
    color: #f16d75;
}

[class*=alert-solid-] {
    border-width: 0;
    color: #ffffff;
}

.alert-solid-success {
    background-color: #19b159;
}

.alert-solid-info {
    background-color: #01b8ff;
}

.alert-solid-warning {
    background-color: #ff9b21;
}

.alert-solid-danger {
    background-color: #f16d75;
}

.alert-message {
    border: 1px solid rgba(232, 232, 247, 0.2);
    border-radius: 3px;
    background: var(--primary-bg-color);
}

.alert-default {
    border: 1px solid #f0f2f8;
}

.alert-message {
    border-color: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.12);
    padding: 1rem;
}

.alert-icons {
    height: 70px;
    width: 70px;
    fill: #ffffff;
}

/* ###### Alerts ###### */
/* ###### Badge ###### */
.badge {
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 4px 6px 5px;
    display: inline-block;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bg-pill {
    padding-left: 8px;
    padding-right: 8px;
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge,
.sp-container button .badge {
    position: relative;
    top: -1px;
}

.bg-secondary {
    color: #ffffff !important;
    background-color: #f1388b;
}

a.bg-secondary:hover {
    color: #ffffff !important;
    background-color: #e06b32;
}

a.bg-secondary:focus {
    color: #ffffff !important;
    background-color: #e06b32;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 131, 27, 0.5);
}

a.bg-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 131, 27, 0.5);
}

.bg-success {
    color: #ffffff !important;
    background-color: #19b159;
}

a.bg-success:hover {
    color: #ffffff !important;
    background-color: #37ce4f;
}

a.bg-success:focus {
    color: #ffffff;
    background-color: #37ce4f;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.5);
}

a.bg-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.5);
}

.bg-info {
    color: #ffffff !important;
    background-color: #01b8ff;
}

a.bg-info:hover {
    color: #ffffff !important;
    background-color: #06aff1;
}

a.bg-info:focus {
    color: #ffffff;
    background-color: #06aff1;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

a.bg-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.bg-warning {
    color: #0e0e0e !important;
    background-color: #ecb529;
}

a.bg-warning:hover {
    color: #8f9cc0 !important;
    background-color: #d39e00;
}

a.bg-warning:focus {
    color: #8f9cc0;
    background-color: #d39e00;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

a.bg-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.bg-danger {
    color: #ffffff !important;
    background-color: #fd6074;
}

a.bg-danger:hover {
    color: #ffffff !important;
    background-color: #f53729;
}

a.bg-danger:focus {
    color: #ffffff;
    background-color: #f53729;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

a.bg-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.bg-pink {
    color: #ffffff !important;
    background-color: #ff75ad;
}

a.bg-pink:hover,
a.bg-pink:focus {
    color: #ffffff !important;
    background-color: #ff75ad;
}

.bg-dark {
    color: #ffffff;
    background-color: #8f9cc0;
}

a.bg-dark:hover {
    color: #ffffff !important;
    background-color: #131212;
}

a.bg-dark:focus {
    color: #ffffff !important;
    background-color: #131212;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

a.bg-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 72, 99, 0.5);
}

.bg-success-light {
    color: #19b159 !important;
    background: rgba(25, 177, 89, 0.2);
}

.bg-info-light {
    background: rgba(1, 184, 255, 0.2);
    color: #01b8ff;
}

/* ###### Badge ###### */
/* ###### Breadcrumb ###### */
.breadcrumb-style1,
.breadcrumb-style2,
.breadcrumb-style3 {
    border-radius: 0;
    background-color: transparent;
    padding: 0;
}

.breadcrumb-style1 .breadcrumb-item,
.breadcrumb-style2 .breadcrumb-item,
.breadcrumb-style3 .breadcrumb-item {
    font-size: 15px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: 0.5px;
}

.breadcrumb-style1 .breadcrumb-item a,
.breadcrumb-style2 .breadcrumb-item a,
.breadcrumb-style3 .breadcrumb-item a {
    color: #6f6f8e;
}

.breadcrumb-style1 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    color: #a8afc7;
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    width: 16px;
    text-align: center;
    font-family: "Ionicons";
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before {
    content: "\f3d1";
}

.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    content: "\f287";
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0px;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #a8afc7;
    content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline;
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #a8afc7;
}

.breadcrumb-5 a {
    text-decoration: none;
    outline: none;
    display: block;
    float: left;
    font-size: 12px;
    line-height: 36px;
    color: white;
    padding: 0 10px 0 39px;
    background: #666;
    background: linear-gradient(#666, #333);
    position: relative;
}

.breadcrumb-5 a:first-child {
    padding-left: 46px;
    border-radius: 5px 0 0 5px;
}

.breadcrumb-5 a:first-child:before {
    left: 14px;
}

.breadcrumb-5 a:last-child {
    border-radius: 0 5px 5px 0;
    padding-right: 20px;
}

.breadcrumb-5 a.active,
.breadcrumb-5 a:hover {
    background: #333;
    background: linear-gradient(#333, #000000);
}

.breadcrumb-5 a.active:after {
    box-shadow: none;
}

.breadcrumb-5 a:after {
    content: "";
    position: absolute;
    top: 0;
    right: -18px;
    width: 36px;
    height: 36px;
    z-index: 1;
    transform: scale(0.707) rotate(45deg);
    box-shadow: 2px -2px 0 2px rgb(240, 242, 248), 3px -3px 0 2px rgba(255, 255, 255, 0.1);
    border-radius: 0 5px 0 50px;
}

.breadcrumb-5 a:last-child:after {
    content: none;
}

.breadcrumb-3 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
}

.breadcrumb-3 li {
    display: contents;
    text-align: center;
}

.breadcrumb-4 ol {
    position: relative;
    display: block;
    width: 100%;
    text-align: right;
}

.breadcrumb-4 li {
    display: contents;
    text-align: right;
}

/* ###### Breadcrumb ###### */
/* ###### Buttons ###### */
.btn,
.sp-container button {
    line-height: 1.538;
    padding: 7px 20px;
    border-radius: 4px;
    transition: none;
    min-height: 38px;
    display: inline-block;
    font-weight: 400;
    color: #8f9cc0;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 3px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:active,
.sp-container button:active,
.btn:focus,
.sp-container button:focus {
    box-shadow: none;
    outline: 0;
    box-shadow: none;
}

.btn-light {
    color: #8f9cc0;
    background-color: #f1f2f9;
    border-color: #f1f2f9;
}

.btn-light:hover {
    color: #8f9cc0;
    background-color: #dde0e9;
    border-color: #d5d9e4;
}

.btn-light:focus,
.btn-light.focus {
    color: #8f9cc0;
    background-color: #dde0e9;
    border-color: #d5d9e4;
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #8f9cc0;
    background-color: #f1f2f9;
    border-color: #f1f2f9;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active {
    color: #8f9cc0;
    background-color: #d5d9e4;
    border-color: #cdd2df;
}

.show>.btn-light.dropdown-toggle {
    color: #8f9cc0;
    background-color: #d5d9e4;
    border-color: #cdd2df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}

.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
}

.btn-indigo {
    color: #ffffff;
    background-color: #4b0082;
    border-color: #4b0082;
}

.btn-indigo:hover {
    color: #ffffff;
    background-color: #49047b;
    border-color: #49047b;
}

.btn-indigo:focus,
.btn-indigo.focus {
    box-shadow: 0 0 0 0.2rem rgba(75, 0, 130, 0.5);
}

.btn-indigo.disabled,
.btn-indigo:disabled {
    color: #ffffff;
    background-color: #4b0082;
    border-color: #4b0082;
}

.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #4b0082;
    border-color: #4b0082;
}

.show>.btn-indigo.dropdown-toggle {
    color: #ffffff;
    background-color: #4b0082;
    border-color: #4b0082;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(75, 0, 130, 0.5);
}

.show>.btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(75, 0, 130, 0.5);
}

.btn-purple {
    color: #ffffff;
    background-color: #6f42c1;
    border-color: #643ab0;
}

.btn-purple:hover {
    color: #ffffff;
    background-color: #5e37a6;
    border-color: #4e2d89;
}

.btn-purple:focus,
.btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
}

.btn-purple.disabled,
.btn-purple:disabled {
    color: #ffffff;
    background-color: #6f42c1;
    border-color: #643ab0;
}

.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #59339d;
    border-color: #482a80;
}

.show>.btn-purple.dropdown-toggle {
    color: #ffffff;
    background-color: #59339d;
    border-color: #482a80;
}

.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
}

.show>.btn-purple.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 87, 188, 0.5);
}

.btn-pink {
    color: #ffffff;
    background-color: #f10075;
    border-color: #d80069;
}

.btn-pink:hover {
    color: #ffffff;
    background-color: #cb0062;
    border-color: #a50050;
}

.btn-pink:focus,
.btn-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
    color: #ffffff;
    background-color: #f10075;
    border-color: #d80069;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #be005c;
    border-color: #98004a;
}

.show>.btn-pink.dropdown-toggle {
    color: #ffffff;
    background-color: #be005c;
    border-color: #98004a;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.show>.btn-pink.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.btn-gray-500 {
    color: #8f9cc0;
    background-color: #a8afc7;
    border-color: #8896af;
}

.btn-gray-500:hover {
    color: #ffffff;
    background-color: #808faa;
    border-color: #697b9a;
}

.btn-gray-500:focus,
.btn-gray-500.focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.btn-gray-500.disabled,
.btn-gray-500:disabled {
    color: #8f9cc0;
    background-color: #a8afc7;
    border-color: #8896af;
}

.btn-gray-500:not(:disabled):not(.disabled):active,
.btn-gray-500:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #7988a4;
    border-color: #637493;
}

.show>.btn-gray-500.dropdown-toggle {
    color: #ffffff;
    background-color: #7988a4;
    border-color: #637493;
}

.btn-gray-500:not(:disabled):not(.disabled):active:focus,
.btn-gray-500:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.show>.btn-gray-500.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.btn-gray-700 {
    color: #ffffff;
    background-color: #3c4858;
    border-color: #4f5c73;
}

.btn-gray-700:hover {
    color: #ffffff;
    background-color: #49566b;
    border-color: #3a4455;
}

.btn-gray-700:focus,
.btn-gray-700.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

.btn-gray-700.disabled,
.btn-gray-700:disabled {
    color: #ffffff;
    background-color: #3c4858;
    border-color: #4f5c73;
}

.btn-gray-700:not(:disabled):not(.disabled):active,
.btn-gray-700:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #445064;
    border-color: #353e4d;
}

.show>.btn-gray-700.dropdown-toggle {
    color: #ffffff;
    background-color: #445064;
    border-color: #353e4d;
}

.btn-gray-700:not(:disabled):not(.disabled):active:focus,
.btn-gray-700:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

.show>.btn-gray-700.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

[class*=btn-outline-] {
    padding: 0.4rem 1rem;
    line-height: 1.657;
    border-width: 1px;
    background-color: transparent;
}

[class*=btn-outline-]:hover,
[class*=btn-outline-]:focus {
    color: #ffffff;
}

.btn-outline-indigo {
    border-color: #4b0082;
    color: #4b0082;
}

.btn-outline-indigo:hover,
.btn-outline-indigo:focus {
    background-color: #49047b;
}

@media (prefers-reduced-motion: reduce) {

    .btn,
    .sp-container button {
        transition: none;
    }
}

.btn:hover,
.sp-container button:hover {
    color: #8f9cc0;
    text-decoration: none;
}

.btn.disabled,
.sp-container button.disabled,
.btn:disabled,
.sp-container button:disabled {
    opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-secondary {
    color: #ffffff;
    background-color: #f1388b;
    border-color: #f1388b;
}

.btn-secondary:hover {
    color: #ffffff;
    background-color: #ec3487;
    border-color: #ec3487;
}

.btn-secondary:focus,
.btn-secondary.focus {
    background-color: #ec3487 !important;
    border-color: #ec3487 !important;
    box-shadow: 0 0 0 1px rgba(241, 56, 139, 0.3);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #ffffff;
    background-color: #f1388b;
    border-color: #f1388b;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #f75da2;
    border-color: #f1388b;
}

.show>.btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #f75da2;
    border-color: #f1388b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgba(241, 56, 139, 0.3);
}

.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(241, 56, 139, 0.3);
}

.btn-success {
    color: #ffffff;
    background-color: #19b159;
    border-color: #19b159;
}

.btn-success:hover {
    color: #ffffff;
    background-color: #15a552;
    border-color: #15a552;
}

.btn-success:focus,
.btn-success.focus {
    color: #ffffff;
    background-color: #15a552;
    border-color: #15a552;
    box-shadow: 0 0 0 1px rgba(21, 165, 82, 0.3);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #ffffff;
    background-color: #04b386;
    border-color: #04b386;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #04b386;
    border-color: #04b386;
}

.show>.btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #04b386;
    border-color: #04b386;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgba(21, 165, 82, 0.3);
}

.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(21, 165, 82, 0.3);
}

.btn-info {
    color: #ffffff;
    background-color: #01b8ff;
    border-color: #01b8ff;
}

.btn-info:hover {
    color: #ffffff;
    background-color: #06aff1;
    border-color: #06aff1;
}

.btn-info:focus,
.btn-info.focus {
    color: #ffffff;
    background-color: #06aff1;
    border-color: #06aff1;
    box-shadow: 0 0 0 1px rgb(1, 184, 255);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #ffffff;
    background-color: #01b8ff;
    border-color: #01b8ff;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #06aff1;
    border-color: #06aff1;
}

.show>.btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #06aff1;
    border-color: #06aff1;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgb(1, 184, 255);
}

.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgb(1, 184, 255);
}

.btn-warning {
    color: #ffffff;
    background-color: #ff9b21;
    border-color: #ff9b21;
}

.btn-warning:hover {
    color: #ffffff;
    background-color: #f1911d;
    border-color: #f1911d;
}

.btn-warning:focus,
.btn-warning.focus {
    color: #ffffff;
    background-color: #f1911d;
    border-color: #f1911d;
    box-shadow: 0 0 0 1px rgba(241, 145, 29, 0.3);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #ffffff;
    background-color: #f1911d;
    border-color: #f1911d;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #f1911d;
    border-color: #f1911d;
}

.show>.btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #f1911d;
    border-color: #f1911d;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgba(241, 145, 29, 0.3);
}

.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(241, 145, 29, 0.3);
}

.btn-danger {
    color: #ffffff;
    background-color: #fd6074;
    border-color: #fd6074;
}

.btn-danger:hover {
    color: #ffffff;
    background-color: #f53729;
    border-color: #f53729;
}

.btn-danger:focus,
.btn-danger.focus {
    box-shadow: 0 0 0 1px rgb(255, 71, 61);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #ffffff;
    background-color: #fd6074;
    border-color: #fd6074;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #fd6074;
    border-color: #fd6074;
}

.show>.btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #fd6074;
    border-color: #fd6074;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgb(255, 71, 61);
}

.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgb(255, 71, 61);
}

.btn-white {
    background-color: #ffffff;
    background-image: none;
    border-color: #ffffff;
    color: #424e79;
}

.btn-white:hover {
    background-color: #ffffff;
    background-image: none;
    border-color: #ffffff;
    color: #424e79;
}

.btn-white:focus,
.btn-white.focus {
    background-color: #ffffff;
    background-image: none;
    border-color: #ffffff;
    color: #424e79;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.btn-white.disabled,
.btn-white:disabled {
    color: #495057;
    background-color: #ffffff;
    border-color: #ffffff;
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active {
    color: #495057;
    background-color: #eff0f6;
    border-color: #e7e9f1;
}

.show>.btn-white.dropdown-toggle {
    color: #495057;
    background-color: #eff0f6;
    border-color: #e7e9f1;
}

.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.show>.btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.btn-dark {
    color: #ffffff;
    background-color: #8f9cc0;
    border-color: #8f9cc0;
}

.btn-dark:hover {
    color: #ffffff;
    background-color: #8f9cc0;
    border-color: #8f9cc0;
}

.btn-dark:focus,
.btn-dark.focus {
    color: #ffffff;
    background-color: #707b99;
    border-color: #8f9cc0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #ffffff;
    background-color: #3b4863;
    border-color: #3b4863;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #131212;
    border-color: #232b3b;
}

.show>.btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #131212;
    border-color: #232b3b;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
}

.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
}

.btn-outline-secondary {
    color: #f1388b !important;
    border-color: #f1388b;
}

.btn-outline-secondary:hover {
    color: #ffffff !important;
    background-color: #f1388b;
    border-color: #f1388b;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 1px rgb(236, 131, 27);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #f1388b !important;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #f1388b;
    border-color: #f1388b;
}

.show>.btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #f1388b;
    border-color: #f1388b;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgb(236, 131, 27);
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgb(236, 131, 27);
}

.btn-outline-success {
    color: #19b159 !important;
    border-color: #19b159;
}

.btn-outline-success:hover {
    color: #ffffff !important;
    background-color: #19b159;
    border-color: #19b159;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    color: #ffffff !important;
    background-color: #19b159;
    border-color: #19b159;
    box-shadow: 0 0 0 1px rgb(56, 210, 80);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #19b159 !important;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #19b159;
    border-color: #19b159;
}

.show>.btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #19b159;
    border-color: #19b159;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgb(56, 210, 80);
}

.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgb(56, 210, 80);
}

.btn-outline-info {
    color: #01b8ff !important;
    border-color: #01b8ff;
}

.btn-outline-info:hover {
    color: #ffffff !important;
    background-color: #01b8ff;
    border-color: #01b8ff;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 1px rgb(1, 184, 255);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #01b8ff !important;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #01b8ff;
    border-color: #01b8ff;
}

.show>.btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #01b8ff;
    border-color: #01b8ff;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgb(1, 184, 255);
}

.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgb(1, 184, 255);
}

.btn-outline-warning {
    color: #000000 !important;
    border-color: #ff9b21;
}

.btn-outline-warning:hover {
    color: #000000 !important;
    background-color: #ff9b21;
    border-color: #ff9b21;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 1px rgb(255, 193, 7);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #000000 !important;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active {
    color: #8f9cc0;
    background-color: #ff9b21;
    border-color: #ff9b21;
}

.show>.btn-outline-warning.dropdown-toggle {
    color: #8f9cc0;
    background-color: #ff9b21;
    border-color: #ff9b21;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgb(255, 193, 7);
}

.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgb(255, 193, 7);
}

.btn-outline-danger {
    color: #f16d75 !important;
    border-color: #f16d75;
}

.btn-outline-danger:hover {
    color: #ffffff !important;
    background-color: #f16d75;
    border-color: #f16d75;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 1px rgb(255, 71, 61);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #f16d75 !important;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active {
    color: #ffffff !important;
    background-color: #f16d75;
    border-color: #f16d75;
}

.show>.btn-outline-danger.dropdown-toggle {
    color: #ffffff !important;
    background-color: #f16d75;
    border-color: #f16d75;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgb(255, 71, 61);
}

.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgb(255, 71, 61);
}

.btn-outline-light {
    color: #eaedf7 !important;
    border-color: #eaedf7;
}

.btn-outline-light:hover {
    color: #000000 !important;
    background-color: #eaedf7;
    border-color: #eaedf7;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 1px rgb(225, 230, 241);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #000000 !important;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active {
    color: #8f9cc0;
    background-color: #eaedf7;
    border-color: #eaedf7;
}

.show>.btn-outline-light.dropdown-toggle {
    color: #8f9cc0;
    background-color: #eaedf7;
    border-color: #eaedf7;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgb(225, 230, 241);
}

.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgb(225, 230, 241);
}

.btn-outline-dark {
    color: #000000 !important;
    border-color: #000000;
}

.btn-outline-dark:hover {
    color: #ffffff !important;
    background-color: #8f9cc0;
    border-color: #8f9cc0;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #3b4863 !important;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: #8f9cc0;
    border-color: #000000;
}

.show>.btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #8f9cc0;
    border-color: #000000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
}

.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
}

.btn-link {
    font-weight: 400;
    text-decoration: none;
}

.btn-link:hover {
    text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: none;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #a8afc7;
    pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn,
.sp-container .btn-group-lg>button {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}

.btn-sm,
.btn-group-sm>.btn,
.sp-container .btn-group-sm>button {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 0.5rem;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group>.btn,
.sp-container .btn-group>button,
.btn-group-vertical>.btn,
.sp-container .btn-group-vertical>button {
    position: relative;
    flex: 1 1 auto;
}

.btn-group>.btn:hover,
.sp-container .btn-group>button:hover,
.btn-group-vertical>.btn:hover,
.sp-container .btn-group-vertical>button:hover,
.btn-group>.btn:focus,
.sp-container .btn-group>button:focus,
.btn-group>.btn:active,
.sp-container .btn-group>button:active,
.btn-group>.btn.active,
.sp-container .btn-group>button.active,
.btn-group-vertical>.btn:focus,
.sp-container .btn-group-vertical>button:focus,
.btn-group-vertical>.btn:active,
.sp-container .btn-group-vertical>button:active,
.btn-group-vertical>.btn.active,
.sp-container .btn-group-vertical>button.active {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group>.btn:not(:first-child),
.sp-container .btn-group>button:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.sp-container .btn-group>button:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn,
.sp-container .btn-group>.btn-group:not(:last-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group>.btn:not(:first-child),
.sp-container .btn-group>button:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn,
.sp-container .btn-group>.btn-group:not(:first-child)>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split,
.sp-container .btn-group-sm>button+.dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split,
.sp-container .btn-group-lg>button+.dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical>.btn {
    width: 100%;
}

.sp-container .btn-group-vertical>button {
    width: 100%;
}

.btn-group-vertical>.btn-group {
    width: 100%;
}

.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -1px;
}

.sp-container .btn-group-vertical>button:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.sp-container .btn-group-vertical>button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.sp-container .btn-group-vertical>.btn-group:not(:last-child)>button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:not(:first-child),
.sp-container .btn-group-vertical>button:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.sp-container .btn-group-vertical>.btn-group:not(:first-child)>button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.sp-container .btn-group-toggle>button,
.btn-group-toggle>.btn-group>.btn,
.sp-container .btn-group-toggle>.btn-group>button {
    margin-bottom: 0;
}

.btn-group-toggle>.btn input[type=radio],
.sp-container .btn-group-toggle>button input[type=radio],
.btn-group-toggle>.btn input[type=checkbox],
.sp-container .btn-group-toggle>button input[type=checkbox],
.btn-group-toggle>.btn-group>.btn input[type=radio],
.sp-container .btn-group-toggle>.btn-group>button input[type=radio],
.btn-group-toggle>.btn-group>.btn input[type=checkbox],
.sp-container .btn-group-toggle>.btn-group>button input[type=checkbox] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.btn-rounded {
    border-radius: 50px;
}

.btn-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
}

.btn-with-icon i {
    line-height: 0;
    font-size: 20px;
    margin-right: 5px;
}

.btn-with-icon i.typcn {
    line-height: 0.9;
}

.btn-with-icon i.fas {
    font-size: 15px;
}

.btn-with-icon i.icon {
    font-size: 18px;
}

.btn-icon {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.btn-icon i {
    line-height: 1.5;
    font-size: 18px;
}

.btn-icon i.typcn {
    line-height: 0.95;
}

.btn-icon-list {
    display: flex;
    align-items: center;
}

.btn-icon-list .btn+.btn,
.btn-icon-list .sp-container button+.btn {
    margin-left: 5px;
}

.sp-container .btn-icon-list button+.btn,
.btn-icon-list .sp-container .btn+button,
.sp-container .btn-icon-list .btn+button,
.btn-icon-list .sp-container button+button,
.sp-container .btn-icon-list button+button {
    margin-left: 5px;
}

.btn-group-sm>.btn,
.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 3px;
    min-height: 0;
}

.btn-list {
    margin-bottom: -0.5rem;
    font-size: 0;
    padding: 0;
}

.btn-list>.btn:not(:last-child),
.btn-list>.dropdown:not(:last-child) {
    margin-right: 0.5rem;
}

.btn-list>.btn,
.btn-list>.dropdown {
    margin-bottom: 0.5rem;
}

.btn-default {
    color: #292828;
}

.btn-light {
    color: #2c3033;
    background-color: #eaedf7;
    border-color: #eaedf7;
}

.btn-light:hover {
    color: #2c3033;
    background-color: #dbe0ea;
    border-color: #dbe0ea;
}

.btn-light:focus,
.btn-light.focus {
    box-shadow: 0 0 0 1px rgb(225, 230, 241);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #2c3033;
    background-color: #dbe0ea;
    border-color: #dbe0ea;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active {
    color: #2c3033;
    background-color: #dbe0ea;
    border-color: #dbe0ea;
}

.show>.btn-light.dropdown-toggle {
    color: #2c3033;
    background-color: #dbe0ea;
    border-color: #dbe0ea;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px rgb(225, 230, 241);
}

.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px rgb(225, 230, 241);
}

/* ###### Buttons ###### */
/* ###### Card  ###### */
.card {
    border-radius: 11px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #e8e8f7;
}

.card.text-white .main-content-label,
.card.tx-white .main-content-label {
    color: #ffffff;
}

.card-body {
    flex: 1 1 auto;
    padding: 25px;
}

.main-content-label {
    font-weight: 500;
    color: #3c4858;
    font-size: 14px;
    margin-bottom: 0rem;
    line-height: 1;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.card .card {
    box-shadow: none;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 20px 25px 0 25px;
    margin-bottom: 0;
    border-bottom: 1px solid #e8e8f7;
    background: transparent;
}

.card-header:first-child {
    border-radius: 2px 2px 0 0;
    border-radius: 11px 11px 0 0;
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background: #ffffff;
    border-top: 1px solid #e8e8f7;
    background: transparent;
}

.card-footer:last-child {
    border-radius: 11px;
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img {
    width: 100%;
    border-radius: 2px;
}

.card-img-top {
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.card-deck {
    display: flex;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: flex;
    flex-direction: column;
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.card-header
 {
    border-color: #eaedf7;
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-header-tab {
    border-bottom: 0;
    padding: 0;
}

.card-item .card-item-icon {
    width: 45px;
    height: 45px;
    position: absolute;
    right: 20px;
    bottom: 21px;
    top: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-item .card-item-icon.card-icon {
    background: var(--primary01);
}

.card-item .card-item-title {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    margin-bottom: 2rem;
    letter-spacing: 0;
    color: #383942;
}

.card-item .card-item-title small {
    font-size: 80%;
    font-weight: 300;
    text-transform: capitalize;
    color: #8d95b1;
}

.card-item .card-item-body {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.card-item .card-item-body .card-item-stat .card-item-figure {
    display: flex;
    font-weight: 500;
    font-size: 1.125rem;
    letter-spacing: 0.5px;
    color: #383942;
}

.card-item .card-item-body .card-item-stat small {
    font-size: 85%;
    font-weight: 300;
    text-transform: capitalize;
    color: #8d95b1;
}

.card-item .card-item-body .card-item-redirect .btn-card-item-view {
    background: #F4F7FD;
    color: #A5ABBA;
    font-weight: 300;
    letter-spacing: 0.5px;
    border: none;
    transition: all, 0.3s;
}

.card-dashboard-calendar {
    padding: 20px;
    box-shadow: -7.829px 11.607px 20px 0px rgb(228, 227, 245);
}

.card-dashboard-calendar .main-content-label {
    color: #1c273c;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.card-dashboard-calendar .card-body {
    padding: 0;
}

.card-dashboard-calendar .ui-datepicker-inline {
    border-width: 0;
    max-width: none;
    padding: 0;
    margin: 0;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-header {
    border-bottom: 0;
    padding: 13px 10px;
    margin: auto;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.card-dashboard-calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    top: 12px;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
    width: 100%;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar th {
    font-size: 12px;
    text-align: center;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar td {
    text-align: center;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar td a {
    display: block;
    font-size: 11.7px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    color: #ffffff;
    font-weight: 700;
    border-radius: 0;
}

@media (min-width: 576px) {
    .card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar {
        width: 100%;
    }
}

.card-table.table-vcenter th {
    font-weight: 600 !important;
}

.card-table .badge {
    padding: 9px 13px 9px;
}

.custom-card {
    margin-bottom: 20px;
    border: 0;
    box-shadow: -7.829px 11.607px 20px 0px rgb(228, 227, 245);
}

.card-aside {
    -ms-flex-direction: row;
    flex-direction: row;
}

.card-aside-column {
    min-width: 5rem;
    width: 30%;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    background: no-repeat center/cover;
}

.card-options {
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-order: 100;
    order: 100;
    margin-right: -0.5rem;
    color: #a8afc7;
    -ms-flex-item-align: center;
    align-self: center;
}

.card-options a:not(.btn) {
    margin-left: 0;
    color: #505069;
    display: inline-block;
    min-width: 1rem;
    padding: 10px 15px;
}

.card-options a:not(.btn):hover {
    text-decoration: none;
    color: #505069;
}

.card-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    margin: 0;
}

.card-collapsed> :not(.card-header):not(.card-status) {
    display: none;
}

.card-options a:not(.btn) i {
    font-size: 1rem;
    vertical-align: middle;
}

.card-options .dropdown-toggle:after {
    display: none;
}

.custom-card-header {
    background: #ffffff;
    display: flex;
    line-height: 0;
}

.card-body+.card-body {
    border-top: 1px solid #f0f2f8;
}

.card-sub-title {
    font-size: 13px;
    margin-top: 3px;
}

.card-blog-overlay1 {
    /* background: url("../img/media/3.jpg"); */
    background-size: cover;
}

.card-blog-overlay2 {
    /* background: url("../img/media/1.jpg"); */
    background-size: cover;
}

.card-blog-overlay {
    /* background: url("../img/media/2.jpg"); */
    background-size: cover;
    position: relative;
}

.card-blog-overlay:before {
    content: "";
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
}

.card-blog-overlay1:before,
.card-blog-overlay2:before {
    content: "";
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
}

.card-blog-overlay .custom-card-header,
.card-blog-overlay1 .custom-card-header,
.card-blog-overlay2 .custom-card-header {
    background: none !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.card-blog-overlay .card-footer,
.card-blog-overlay1 .card-footer,
.card-blog-overlay2 .card-footer {
    background: none !important;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.card-item-desc .card-item-desc-1 dt {
    font-weight: 500;
    font-size: 13px;
    display: inline-block;
}

.card-item-desc dl,
.card-item-desc dd {
    margin-top: 0;
    margin-bottom: 0;
}

.card-item-desc .card-item-desc-1 dd {
    font-size: 13px;
    color: #737f9e;
    margin-bottom: 0;
}

.cart-details dt {
    font-weight: 400;
    margin-bottom: 0.6rem;
}

.cart-details dd {
    font-weight: 600;
}

@media (max-width: 575px) {
    .card-box img {
        position: relative !important;
        bottom: -27px !important;
        left: -14px !important;
    }
}

/* ###### Card  ###### */
/* ###### carousel ###### */
.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}

.carousel-item.active {
    display: block;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item.active {
    z-index: 0;
    opacity: 1;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 0;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #ffffff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon svg,
.carousel-control-next-icon svg {
    width: 48px;
    height: 48px;
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.carousel-indicators .active {
    opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none;
    }
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff;
    text-align: center;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.carousel-slider #carousel {
    margin: 0;
}

.carousel-slider #thumbcarousel {
    margin: 10px 0 0 0;
    padding: 0;
}

.carousel-slider #thumbcarousel .carousel-item {
    text-align: center;
}

.carousel-slider #thumbcarousel .carousel-item .thumb {
    width: 100%;
    margin: 0 2px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    max-width: 100px;
}

.carousel-slider #thumbcarousel .carousel-item .thumb:hover {
    border-color: #cad4e4;
}

.carousel-slider .carousel-item img {
    width: 100%;
    height: auto;
}

.carousel-control:active,
.carousel-control:focus,
.carousel-control:hover {
    color: #333;
}

#thumbcarousel .carousel-control-prev {
    left: 0;
    background: rgba(0, 0, 0, 0.8);
}

#thumbcarousel .carousel-control-next {
    right: 0;
    background: rgba(0, 0, 0, 0.8);
}

.owl-carousel .owl-item {
    position: relative;
    overflow: hidden;
}

.owl-nav {
    display: block;
}

.owl-carousel {
    position: relative;
}

.owl-nav .owl-prev {
    position: absolute;
    top: 50%;
    left: -25px;
    right: -1.5em;
    margin-top: -1.65em;
}

.owl-nav .owl-next {
    position: absolute;
    top: 50%;
    right: -25px;
    margin-top: -1.65em;
}

.owl-nav button {
    display: block;
    font-size: 1.3rem !important;
    line-height: 2em;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    text-align: center;
    background: rgba(255, 255, 255, 0.5) !important;
    border: 1px solid #e8ebf3 !important;
    z-index: 99;
    box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
}

.owl-nav button:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098d1;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.owl-carousel:hover .owl-nav button {
    background: rgb(255, 255, 255) !important;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    animation: sonarEffect 1.3s ease-out 75ms;
}

.owl-nav>div i {
    margin: 0;
}

.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    bottom: 0.65em;
    left: 0;
    right: 0;
    z-index: 99;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
    width: 1em;
    height: 1em;
    margin: 5px 7px;
    background: rgba(0, 0, 0, 0.3);
    display: block;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    transition: opacity 0.2s ease;
    border-radius: 30px;
}

.product-carousel .thumb {
    border: 1px solid #cad4e4;
    padding: 5px;
}

/* ###### carousel ###### */
/* ########## Dropdown ########## */
.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }

    .header-search {
        display: none;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    vertical-align: 0;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
    vertical-align: 0;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-divider {
    height: 0 !important;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e8e8f7;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #647194;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    padding: 8px 15px;
    font-size: 13px;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #141c2b;
    text-decoration: none;
    background-color: #f1f2f9;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #a8afc7;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.76562rem;
    color: #a8afc7;
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #8f9cc0;
}

.dropdown-menu .main-form-search {
    flex: 1;
    width: 100%;
    position: relative;
}

.dropdown-menu .main-header-center .select2-container--default .select2-selection--single {
    border-radius: 6px 0 0 6px;
    background: #eaebfa;
    height: 40px;
    width: 200px !important;
}

.dropdown-menu .main-header-center .search-btn {
    padding: 0.375rem 0.85rem 0.4rem 0.7rem;
    border-radius: 0 6px 6px 0;
    color: #ffffff;
    display: inline-block;
}

.dropdown-menu .main-form-search .btn,
.dropdown-menu .main-form-search .sp-container button {
    display: inline-block;
    position: initial;
    padding: 0.375rem 0.85rem 0.4rem 0.7rem;
    border-radius: 0 6px 6px 0;
    color: #ffffff;
}

.dropdown-menu .sp-container .main-form-search button {
    display: inline-block;
    position: initial;
    padding: 0.375rem 0.85rem 0.4rem 0.7rem;
    border-radius: 0 6px 6px 0;
    color: #ffffff;
}

.dropdown-menu .main-header-center .form-control {
    background: #eaebfa;
    border: 1px solid #e6eaff;
    transition: none;
    height: 40px;
    border-radius: 0;
}

.dropdown-menu .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after {
    margin-left: 0;
}

.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.dropdown.header-message .nav-link.icon {
    font-size: 18px;
}

.dropdown-menu {
    padding: 0;
    border-width: 1px;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: #8f9cc0;
    text-align: left;
    list-style: none;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e8e8f7;
    border-radius: 0px;
    box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3);
}

.dropdown-menu[data-popper-placement^=top],
.dropdown-menu[data-popper-placement^=right],
.dropdown-menu[data-popper-placement^=bottom],
.dropdown-menu[data-popper-placement^=left] {
    right: auto;
    bottom: auto;
}

/* ########## Dropdown ########## */
/* ###### Form-elements ###### */
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.175rem 0.75rem 0.175rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1d212f;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e8e8f7;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 38px;
    border-radius: 5px;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: #1d212f;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(135, 96, 251, 0.25);
    border-color: #eeeef9;
    box-shadow: none;
}

.form-control::value {
    color: var(--primary-bg-color);
}

.form-control::placeholder {
    color: #a8afc7;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f6f6ff;
    opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

select.form-control:focus::-ms-value {
    color: #8f9cc0;
    background-color: #ffffff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.76562rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #8f9cc0;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
    color: #a8afc7;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.custom-file,
.custom-file-input {
    height: 38px;
}

.form-label {
    display: block;
    margin-bottom: 5px;
    color: #8f9cc0;
    margin-top: 34px;
}

.file-browser .btn-default {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    padding: 0.46rem 15px;
    color: #ffffff;
    border-radius: 0;
    border: 1px solid #e2e8f5;
}

.main-form-group {
    padding: 12px 15px;
    border: 1px solid #e8e8f7;
}

.main-form-group.focus {
    border-color: #e8e8f7;
    box-shadow: 0 0 0 2px rgba(91, 71, 251, 0.16);
}

.main-form-group .form-label {
    font-size: 13px;
    margin-bottom: 2px;
    font-weight: 400;
    color: #a8afc7;
}

.main-form-group .form-control {
    padding: 0;
    border-width: 0;
    height: 25px;
    color: #8f9cc0;
    font-weight: 500;
}

.main-form-group .form-control:focus {
    box-shadow: none;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #19b159;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    color: #ffffff;
    background-color: rgba(59, 176, 1, 0.9);
    border-radius: 3px;
}

.was-validated .form-control:valid,
.form-control.is-valid,
input.form-control.parsley-success,
textarea.form-control.parsley-success {
    border-color: #19b159;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233bb001' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
input.form-control.parsley-success:focus,
textarea.form-control.parsley-success:focus {
    border-color: #19b159;
    box-shadow: none;
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
    display: block;
}

.form-control.is-valid~.valid-feedback,
input.form-control.parsley-success~.valid-feedback,
textarea.form-control.parsley-success~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
input.form-control.parsley-success~.valid-tooltip,
textarea.form-control.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated textarea.form-control:valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

textarea.form-control.is-valid,
textarea.form-control.parsley-success {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid,
input.custom-select.parsley-success,
textarea.custom-select.parsley-success {
    border-color: #19b159;
    padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233bb001' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus,
input.custom-select.parsley-success:focus,
textarea.custom-select.parsley-success:focus {
    border-color: #19b159;
    box-shadow: none;
}

.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip {
    display: block;
}

.custom-select.is-valid~.valid-feedback,
input.custom-select.parsley-success~.valid-feedback,
textarea.custom-select.parsley-success~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
input.custom-select.parsley-success~.valid-tooltip,
textarea.custom-select.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip {
    display: block;
}

.form-control-file.is-valid~.valid-feedback,
input.form-control-file.parsley-success~.valid-feedback,
textarea.form-control-file.parsley-success~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
input.form-control-file.parsley-success~.valid-tooltip,
textarea.form-control-file.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label,
input.form-check-input.parsley-success~.form-check-label,
textarea.form-check-input.parsley-success~.form-check-label {
    color: #19b159;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block;
}

.form-check-input.is-valid~.valid-feedback,
input.form-check-input.parsley-success~.valid-feedback,
textarea.form-check-input.parsley-success~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
input.form-check-input.parsley-success~.valid-tooltip,
textarea.form-check-input.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label,
input.custom-control-input.parsley-success~.custom-control-label,
textarea.custom-control-input.parsley-success~.custom-control-label {
    color: #19b159;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before,
input.custom-control-input.parsley-success~.custom-control-label::before,
textarea.custom-control-input.parsley-success~.custom-control-label::before {
    border-color: #19b159;
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before,
input.custom-control-input.parsley-success:checked~.custom-control-label::before,
textarea.custom-control-input.parsley-success:checked~.custom-control-label::before {
    border-color: #4ce301;
    background-color: #4ce301;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before,
input.custom-control-input.parsley-success:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-success:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
input.custom-control-input.parsley-success:focus:not(:checked)~.custom-control-label::before,
textarea.custom-control-input.parsley-success:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label,
input.custom-file-input.parsley-success~.custom-file-label,
textarea.custom-file-input.parsley-success~.custom-file-label {
    border-color: #19b159;
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
    display: block;
}

.custom-file-input.is-valid~.valid-feedback,
input.custom-file-input.parsley-success~.valid-feedback,
textarea.custom-file-input.parsley-success~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
input.custom-file-input.parsley-success~.valid-tooltip,
textarea.custom-file-input.parsley-success~.valid-tooltip {
    display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label,
input.custom-file-input.parsley-success:focus~.custom-file-label,
textarea.custom-file-input.parsley-success:focus~.custom-file-label {
    border-color: #19b159;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f16d75;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    color: #ffffff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 3px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
input.form-control.parsley-error,
textarea.form-control.parsley-error {
    border-color: #f16d75;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
input.form-control.parsley-error:focus,
textarea.form-control.parsley-error:focus {
    border-color: #f16d75;
    box-shadow: none;
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
    display: block;
}

.form-control.is-invalid~.invalid-feedback,
input.form-control.parsley-error~.invalid-feedback,
textarea.form-control.parsley-error~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
input.form-control.parsley-error~.invalid-tooltip,
textarea.form-control.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated textarea.form-control:invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

textarea.form-control.is-invalid,
textarea.form-control.parsley-error {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid,
input.custom-select.parsley-error,
textarea.custom-select.parsley-error {
    border-color: #f16d75;
    padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus,
input.custom-select.parsley-error:focus,
textarea.custom-select.parsley-error:focus {
    border-color: #f16d75;
    box-shadow: none;
}

.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip {
    display: block;
}

.custom-select.is-invalid~.invalid-feedback,
input.custom-select.parsley-error~.invalid-feedback,
textarea.custom-select.parsley-error~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
input.custom-select.parsley-error~.invalid-tooltip,
textarea.custom-select.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip {
    display: block;
}

.form-control-file.is-invalid~.invalid-feedback,
input.form-control-file.parsley-error~.invalid-feedback,
textarea.form-control-file.parsley-error~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
input.form-control-file.parsley-error~.invalid-tooltip,
textarea.form-control-file.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label,
input.form-check-input.parsley-error~.form-check-label,
textarea.form-check-input.parsley-error~.form-check-label {
    color: #f16d75;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block;
}

.form-check-input.is-invalid~.invalid-feedback,
input.form-check-input.parsley-error~.invalid-feedback,
textarea.form-check-input.parsley-error~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
input.form-check-input.parsley-error~.invalid-tooltip,
textarea.form-check-input.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label,
input.custom-control-input.parsley-error~.custom-control-label,
textarea.custom-control-input.parsley-error~.custom-control-label {
    color: #f16d75;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before,
input.custom-control-input.parsley-error~.custom-control-label::before,
textarea.custom-control-input.parsley-error~.custom-control-label::before {
    border-color: #f16d75;
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before,
input.custom-control-input.parsley-error:checked~.custom-control-label::before,
textarea.custom-control-input.parsley-error:checked~.custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before,
input.custom-control-input.parsley-error:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-error:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
input.custom-control-input.parsley-error:focus:not(:checked)~.custom-control-label::before,
textarea.custom-control-input.parsley-error:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label,
input.custom-file-input.parsley-error~.custom-file-label,
textarea.custom-file-input.parsley-error~.custom-file-label {
    border-color: #f16d75;
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
    display: block;
}

.custom-file-input.is-invalid~.invalid-feedback,
input.custom-file-input.parsley-error~.invalid-feedback,
textarea.custom-file-input.parsley-error~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
input.custom-file-input.parsley-error~.invalid-tooltip,
textarea.custom-file-input.parsley-error~.invalid-tooltip {
    display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label,
input.custom-file-input.parsley-error:focus~.custom-file-label,
textarea.custom-file-input.parsley-error:focus~.custom-file-label {
    border-color: #f16d75;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

select.form-control.select-lg {
    height: 46px !important;
    padding: 10px 16px !important;
    font-size: 17px !important;
    line-height: 1.3333333 !important;
}

select.form-control.select-sm {
    height: 28px !important;
    padding: 0px 10px 1px 10px !important;
    line-height: 1 !important;
}

#example-input .form-control::placeholder {
    color: #19b159 !important;
}

input,
select,
textarea {
    color: #000000;
}

textarea:focus,
input:focus,
textarea {
    color: #ff0000;
}

/* ###### Form-elements ###### */
/* ###### Grid ###### */
/* .row-sm {
    margin-left: -10px;
    margin-right: -10px;
} */

/* .row-sm>div {
    padding-left: 10px !important;
    padding-right: 10px !important;
} */

/* .row-xs {
    margin-left: -5px;
    margin-right: -5px;
} */

/* .row-xs>div {
    padding-left: 5px;
    padding-right: 5px;
} */

@media (min-width: 576px) {
    .row-xs--sm {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--sm>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--sm {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--sm>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--sm {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--sm>div {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 768px) {
    .row-xs--md {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--md>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--md {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--md>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--md {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--md>div {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 992px) {
    .row-xs--lg {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--lg>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--lg {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--lg>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--lg {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--lg>div {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (min-width: 1200px) {
    .row-xs--xl {
        margin-left: -5px;
        margin-right: -5px;
    }

    .row-xs--xl>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-sm--xl {
        margin-left: -10px;
        margin-right: -10px;
    }

    .row-sm--xl>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .row--xl {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row--xl>div {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* ###### Grid ###### */
/* ###### Input-group ###### */
.input-group-prepend,
.input-group-append {
    border-radius: 0;
    display: flex;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c4858;
    text-align: center;
    white-space: nowrap;
    background-color: #f6f6ff;
    border: 1px solid #e8e8f7;
    border-radius: 3px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
    margin-top: 0;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: dstretch;
    width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file {
    margin-left: -1px;
}

.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file {
    margin-left: -1px;
}

.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus {
    z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.custom-file {
    display: flex;
    align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend .btn,
.input-group-prepend .sp-container button {
    position: relative;
    z-index: 0;
}

.sp-container .input-group-prepend button {
    position: relative;
    z-index: 0;
}

.input-group-append .btn,
.input-group-append .sp-container button {
    position: relative;
    z-index: 0;
}

.sp-container .input-group-append button {
    position: relative;
    z-index: 0;
}

.input-group-prepend .btn:focus,
.input-group-prepend .sp-container button:focus {
    z-index: 3;
}

.sp-container .input-group-prepend button:focus {
    z-index: 3;
}

.input-group-append .btn:focus,
.input-group-append .sp-container button:focus {
    z-index: 3;
}

.sp-container .input-group-append button:focus {
    z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .sp-container button+.btn {
    margin-left: -1px;
}

.sp-container .input-group-prepend button+.btn,
.input-group-prepend .sp-container .btn+button,
.sp-container .input-group-prepend .btn+button,
.input-group-prepend .sp-container button+button,
.sp-container .input-group-prepend button+button {
    margin-left: -1px;
}

.input-group-prepend .btn+.input-group-text,
.input-group-prepend .sp-container button+.input-group-text {
    margin-left: -1px;
}

.sp-container .input-group-prepend button+.input-group-text {
    margin-left: -1px;
}

.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-prepend .sp-container .input-group-text+button {
    margin-left: -1px;
}

.sp-container .input-group-prepend .input-group-text+button {
    margin-left: -1px;
}

.input-group-append .btn+.btn,
.input-group-append .sp-container button+.btn {
    margin-left: -1px;
}

.sp-container .input-group-append button+.btn,
.input-group-append .sp-container .btn+button,
.sp-container .input-group-append .btn+button,
.input-group-append .sp-container button+button,
.sp-container .input-group-append button+button {
    margin-left: -1px;
}

.input-group-append .btn+.input-group-text,
.input-group-append .sp-container button+.input-group-text {
    margin-left: -1px;
}

.sp-container .input-group-append button+.input-group-text {
    margin-left: -1px;
}

.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
    margin-left: -1px;
}

.input-group-append .sp-container .input-group-text+button {
    margin-left: -1px;
}

.sp-container .input-group-append .input-group-text+button {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0rem;
}

.sp-container .input-group-lg>.input-group-prepend>button,
.input-group-lg>.input-group-append>.btn,
.sp-container .input-group-lg>.input-group-append>button {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border-radius: 0rem;
}

.sp-container .input-group-sm>.input-group-prepend>button,
.input-group-sm>.input-group-append>.btn,
.sp-container .input-group-sm>.input-group-append>button {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border-radius: 0rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.75rem;
}

.input-group>.input-group-prepend>.btn,
.sp-container .input-group>.input-group-prepend>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.sp-container .input-group>.input-group-append:not(:last-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.sp-container .input-group>.input-group-append>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.sp-container .input-group>.input-group-prepend:not(:first-child)>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 15px;
    line-height: 1.3333333;
}

.input-sm {
    height: 28px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.input-group input-group-btn {
    border-radius: 0 6px 6px 0 !important;
}

/* ###### Input-group ###### */
/* ###### Modal ###### */
.modal.animated .modal-dialog {
    transform: translate(0, 0);
}

.modal.effect-scale .modal-dialog {
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-scale.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.modal.effect-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.effect-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1;
}

.modal.effect-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1;
}

.modal.effect-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0;
}

.modal.effect-newspaper.show~.modal-backdrop,
.modal.effect-newspaper .modal-dialog {
    transition: all 0.5s;
}

.modal.effect-newspaper.show .modal-dialog {
    transform: scale(1) rotate(0deg);
    opacity: 1;
}

.modal.effect-fall {
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px;
}

.modal.effect-fall .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(600px) rotateX(20deg);
    opacity: 0;
}

.modal.effect-fall.show .modal-dialog {
    transition: all 0.3s ease-in;
    transform: translateZ(0px) rotateX(0deg);
    opacity: 1;
}

.modal.effect-flip-horizontal {
    perspective: 1300px;
}

.modal.effect-flip-horizontal .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateY(-70deg);
    transition: all 0.3s;
    opacity: 0;
}

.modal.effect-flip-horizontal.show .modal-dialog {
    transform: rotateY(0deg);
    opacity: 1;
}

.modal.effect-flip-vertical {
    perspective: 1300px;
}

.modal.effect-flip-vertical .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-70deg);
    transition: all 0.3s;
    opacity: 0;
}

.modal.effect-flip-vertical.show .modal-dialog {
    transform: rotateX(0deg);
    opacity: 1;
}

.modal.effect-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.modal.effect-sign {
    perspective: 1300px;
}

.modal.effect-sign .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-60deg);
    transform-origin: 50% 0;
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-sign.show .modal-dialog {
    transform: rotateX(0deg);
    opacity: 1;
}

.modal.effect-rotate-bottom {
    perspective: 1300px;
}

.modal.effect-rotate-bottom .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateY(100%) rotateX(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s ease-out;
}

.modal.effect-rotate-bottom.show .modal-dialog {
    transform: translateY(0%) rotateX(0deg);
    opacity: 1;
}

.modal.effect-rotate-left {
    perspective: 1300px;
}

.modal.effect-rotate-left .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-rotate-left.show .modal-dialog {
    transform: translateZ(0px) translateX(0%) rotateY(0deg);
    opacity: 1;
}

.modal.effect-just-me .modal-dialog {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.3s;
}

.modal.effect-just-me .close {
    text-shadow: none;
    color: #ffffff;
}

.modal.effect-just-me .modal-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.modal.effect-just-me .modal-header h6 {
    color: #ffffff;
    font-weight: 500;
}

.modal.effect-just-me .modal-content {
    background: #000000;
}

.modal.effect-just-me .modal-body {
    color: rgba(255, 255, 255, 0.8);
}

.modal.effect-just-me .modal-body h6 {
    color: #ffffff;
}

.modal.effect-just-me .modal-footer {
    background-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.1);
}

.modal.effect-just-me.show~.modal-backdrop {
    opacity: 0.96;
}

.modal.effect-just-me.show .modal-dialog {
    transform: scale(1);
    opacity: 1;
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #4a526f;
    text-shadow: 0 1px 0 #ffffff;
}

.close:hover {
    color: #8f9cc0;
    text-decoration: none;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
    outline: none;
}

a.close.disabled {
    pointer-events: none;
}

.modal-open {
    overflow: hidden;
}

.modal-open .select2-container--open {
    z-index: 9999;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}

.modal.show .modal-dialog {
    transform: none;
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #202029;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
    opacity: 0.8;
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #e8e8f7;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    align-items: center;
    padding: 15px;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-header .modal-title {
    margin-bottom: 0;
}

.modal-title {
    font-size: 16px;
    font-weight: 700;
    color: #4a526f;
    line-height: 1;
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
    padding: 25px;
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e8e8f7;
    border-bottom-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.modal-footer> :not(:first-child) {
    margin-left: 0.25rem;
}

.modal-footer> :not(:last-child) {
    margin-right: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem);
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem);
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal-content-demo .modal-body h6 {
    color: #1d212f;
    font-size: 15px;
    margin-bottom: 15px;
}

.modal-content-demo .modal-body p {
    margin-bottom: 0;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
    width: 100%;
}

.fade {
    transition: opacity 0.15s linear;
}

.fade:not(.show) {
    opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}

.modal-backdrop {
    background-color: #0a0a0a;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e8e8f7;
    border-radius: 0;
    outline: 0;
    box-shadow: 0 0.5rem 1rem rgba(162, 169, 204, 0.15) !important;
    border-radius: 0;
    border-width: 0;
}

.modal-content .close {
    font-size: 28px;
    padding: 0;
    margin: 0;
    line-height: 0.5;
}

@media (min-width: 576px) {
    .modal-header {
        padding: 15px 20px;
    }
}

@media (min-width: 992px) {
    .modal-header {
        padding: 20px;
    }
}

@media (min-width: 1200px) {
    .modal-header {
        padding: 20px 25px;
    }
}

div.dtr-modal div.dtr-modal-content {
    box-shadow: none;
}

/* ###### Modal ###### */
/* ###### Navigation   ###### */
.nav-link:hover,
.nav-link:focus,
.nav-link:active {
    outline: none;
}

.nav-pills .nav-link {
    color: #3c4858;
    border-radius: 3px;
}

.nav-pills .nav-link:hover,
.nav-pills .nav-link:focus {
    color: #8f9cc0;
}

.nav-pills .nav-link.active {
    border-radius: 2px;
    color: #ffffff;
}

.nav-pills .nav-link.active:hover,
.nav-pills .nav-link.active:focus {
    color: #ffffff;
}

.nav-dark .nav-link {
    color: #a8afc7;
}

.nav-dark .nav-link:hover,
.nav-dark .nav-link:focus {
    color: #ffffff;
}

@media (min-width: 768px) {
    .nav-tabs .nav-link {
        padding: 10px 20px;
    }
}

@media (min-width: 768px) {
    .main-nav {
        align-items: center;
    }
}

.main-nav .nav-link {
    display: block;
    color: #3c4858;
    padding: 0;
    position: relative;
    line-height: normal;
}

.main-nav .nav-link:hover,
.main-nav .nav-link:focus {
    color: #8f9cc0;
}

.main-nav .nav-link+.nav-link {
    padding-top: 12px;
    margin-top: 12px;
}

@media (min-width: 768px) {
    .main-nav .nav-link+.nav-link {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
        padding-left: 15px;
        margin-left: 15px;
    }
}

.main-nav-column {
    flex-direction: column;
}

.main-nav-column .nav-link {
    padding: 0;
    height: 38px;
    color: #606c8c;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.main-nav-column .nav-link i {
    font-size: 18px;
    line-height: 0;
    width: 24px;
    margin-right: 12px;
    text-align: center;
    transition: all 0.2s ease-in-out;
    line-height: 0.9;
}

.main-nav-column .nav-link i:not([class*=" tx-"]) {
    color: #606c8c;
}

.main-nav-column .nav-link span {
    font-weight: 400;
    font-size: 11px;
    margin-left: auto;
}

.main-nav-column .nav-link:hover,
.main-nav-column .nav-link:focus,
.main-nav-column .nav-link:hover i:not([class*=" tx-"]),
.main-nav-column .nav-link:focus i:not([class*=" tx-"]) {
    color: #8f9cc0;
}

.main-nav-column .nav-link.active {
    position: relative;
}

.main-nav-column .nav-link.active i:not([class*=" tx-"]) {
    color: var(--primary-bg-color) !important;
}

.main-nav-column .nav-link.active::before {
    content: "";
    position: absolute;
    top: 6px;
    bottom: 6px;
    left: -28px;
    width: 3px;
    border-radius: 3px;
    display: none;
}

.main-nav-column.sm .nav-link {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 10px 0;
}

.main-nav-column.sm .nav-link i {
    font-size: 21px;
}

@media (prefers-reduced-motion: reduce) {
    .main-nav-column .nav-link i {
        transition: none;
    }
}

.main-nav-dark .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.main-nav-dark .nav-link:hover,
.main-nav-dark .nav-link:focus {
    color: #ffffff;
}

.main-nav-dark .nav-link+.nav-link {
    border-color: #3c4858;
}

.main-nav-colored-bg .nav-link+.nav-link {
    border-color: rgba(255, 255, 255, 0.4);
}

.main-nav-colored-bg .nav-link.active {
    color: #ffffff;
}

.main-nav-line {
    position: relative;
}

.main-nav-line .nav-link {
    padding: 0;
    color: #3c4858;
    position: relative;
}

.main-nav-line .nav-link+.nav-link {
    margin-top: 15px;
}

.main-nav-line .nav-link.active {
    color: #8f9cc0;
}

.main-nav-line.main-nav-dark .nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.main-nav-line.main-nav-dark .nav-link:hover,
.main-nav-line.main-nav-dark .nav-link:focus {
    color: #ffffff;
}

.main-nav-line.main-nav-dark .nav-link.active {
    color: #ffffff;
}

.main-nav-line.main-nav-dark .nav-link.active::before {
    background-color: #ffffff;
}

@media (min-width: 769px) {
    .main-nav-line .nav-link+.nav-link {
        margin-top: 0;
        margin-left: 30px;
    }
}

@media (max-width: 768px) {
    .main-nav-line .nav-link+.nav-link {
        margin-top: 10px;
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    .main-nav-line .nav-link.active::before {
        top: auto;
        bottom: -18px;
        left: 0;
        right: 0;
        height: 2px;
        width: auto;
    }
}

@media (min-width: 992px) {
    .main-nav-line-chat {
        padding-left: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 15px;
        border-bottom: 1px solid #e2e8f5;
    }

    .nav.main-nav-line-chat {
        padding-left: 20px;
    }
}

#underline .main-nav-line .nav-link.active {
    text-decoration: underline;
}

/* ###### Navigation   ###### */
/* ###### Pagination  ###### */
.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.page-link {
    color: #71719a;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.75rem;
    border-width: 0;
    border: 1px solid #e8e8f7;
    font-weight: 400;
    position: relative;
    margin-left: -1px;
    line-height: 1.25;
    background-color: #ffffff;
}

.page-link i {
    font-size: 18px;
    line-height: 0;
}

.page-link:hover,
.page-link:focus {
    background-color: #eaedf7;
    box-shadow: none;
    z-index: 2;
}

.page-link:hover {
    text-decoration: none;
    background-color: #eaedf7;
    border-color: #eaedf7;
}

.page-link:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
}

.page-item.active .page-link {
    color: #ffffff;
}

.page-item.disabled .page-link {
    color: #a8afc7;
    pointer-events: none;
    cursor: auto;
    background-color: #ffffff;
    border-color: #eaedf7;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
}

.page-item:first-child .page-link {
    border-radius: 6px 0 0 6px;
}

.page-item:last-child .page-link {
    border-radius: 0 6px 6px 0;
}

.pagination-dark .page-item.active .page-link {
    color: #ffffff;
}

.pagination-dark .page-link {
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.8);
}

.pagination-dark .page-link:hover,
.pagination-dark .page-link:focus {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.08);
}

.pagination-success .page-item.active .page-link {
    background-color: #19b159;
    border-color: #19b159;
}

.pagination-circled .page-item+.page-item {
    margin-left: 5px;
}

.pagination-circled .page-item:first-child .page-link,
.pagination-circled .page-item:last-child .page-link {
    border-radius: 100% !important;
}

.pagination-circled .page-link {
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.pagination-circle {
    margin-top: 0;
    border-radius: 50px;
    background: #eaedf7;
    padding: 7px;
}

.pagination-circle li a,
.pagination-circle li span {
    padding: 0;
    border-radius: 50px !important;
    border: none;
    margin: 0 3px;
    min-width: 32px;
    height: 32px;
    width: 32px;
    line-height: 31px;
    text-align: center;
    font-size: 14px;
    border: 0;
    background: #ffffff;
}

.pagination-radius {
    margin-top: 0;
    border-radius: 6px;
    padding: 6px;
}

.pagination-radius>li {
    display: inline;
}

.pagination-radius li a,
.pagination-radius li span {
    padding: 0;
    border-radius: 6px !important;
    border: none;
    margin: 0;
    min-width: 32px;
    height: 32px;
    width: 32px;
    line-height: 31px;
    text-align: center;
    font-size: 14px;
    border: 1px solid #dee4f5;
    margin-left: 4px;
    background: #ffffff;
}

.pagination-radius .page-link i {
    font-size: 11px;
    line-height: 0;
}

.pagination-circle .page-link i {
    font-size: 11px;
    line-height: 0;
}

/* ###### Pagination  ###### */
/* ###### Popover  ###### */
.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76562rem;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e8e8f7;
    border-radius: 0.3rem;
    padding: 0;
}

.popover.show {
    box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3);
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.popover-header {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header::before {
    display: none !important;
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #8f9cc0;
}

.popover-body p:last-child {
    margin-bottom: 0;
}

.popover-head-primary .popover-header {
    background-color: var(--primary-bg-color) !important;
    color: #ffffff;
}

.popover-head-primary.bs-popover-top .arrow,
.popover-head-primary.bs-popover-auto[data-popper-placement^=top] .arrow {
    bottom: -7px;
}

.popover-head-primary.bs-popover-left .arrow,
.popover-head-primary.bs-popover-auto[data-popper-placement^=left] .arrow {
    right: -7px;
}

.popover-head-primary.bs-popover-right .arrow,
.popover-head-primary.bs-popover-auto[data-popper-placement^=right] .arrow {
    left: -7px;
}

.popover-head-secondary .popover-header {
    color: #ffffff;
    background-color: #f1388b !important;
}

.popover-head-secondary.bs-popover-top .arrow,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=top] .arrow {
    bottom: -7px;
}

.popover-head-secondary.bs-popover-left .arrow,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=left] .arrow {
    right: -7px;
}

.popover-head-secondary.bs-popover-right .arrow,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=right] .arrow {
    left: -7px;
}

.popover-head-secondary.bs-popover-bottom .arrow::after,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
    border-bottom-color: #f1388b;
}

.popover-head-primary,
.popover-head-secondary {
    border: 0;
}

.popover-head-primary .popover-header,
.popover-head-secondary .popover-header {
    border: 0;
}

.popover-head-primary .popover-body,
.popover-head-secondary .popover-body {
    border: 1px solid rgba(28, 39, 60, 0.2);
    border-top-width: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.popover-head-primary.bs-popover-bottom .arrow::before,
.popover-head-primary.bs-popover-auto[data-popper-placement^=bottom] .arrow::before {
    display: none;
}

.popover-head-secondary.bs-popover-bottom .arrow::before,
.popover-head-secondary.bs-popover-auto[data-popper-placement^=bottom] .arrow::before {
    display: none;
}

.popover-primary {
    border-width: 0;
    padding: 15px;
}

.popover-primary .popover-header {
    background-color: transparent !important;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: #ffffff;
}

.popover-primary .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
}

.popover-primary .arrow::before {
    display: none;
}

.popover-secondary {
    background-color: #f1388b !important;
    border-width: 0;
    padding: 15px;
}

.popover-secondary .popover-header {
    background-color: transparent !important;
    border-bottom-width: 0;
    padding: 0 0 15px;
    color: #ffffff;
}

.popover-secondary .popover-body {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
}

.popover-secondary .arrow::before {
    display: none;
}

.popover-secondary.bs-popover-top .arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
    border-top-color: #f1388b;
}

.popover-secondary.bs-popover-bottom .arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
    border-bottom-color: #f1388b;
}

.popover-secondary.bs-popover-left .arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=left] .arrow::after {
    border-left-color: #f1388b;
}

.popover-secondary.bs-popover-right .arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=right] .arrow::after {
    border-right-color: #f1388b;
}

.popover-static-demo {
    background-color: #eaedf7;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
}

.popover-static-demo .popover {
    z-index: 0;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
}

.popover-static-demo .bs-popover-top .arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^=top] .arrow,
.popover-static-demo .bs-popover-bottom .arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^=bottom] .arrow {
    left: 50%;
    margin-left: -5px;
}

.popover-static-demo .bs-popover-left,
.popover-static-demo .bs-popover-auto[data-popper-placement^=left],
.popover-static-demo .bs-popover-right,
.popover-static-demo .bs-popover-auto[data-popper-placement^=right] {
    margin-top: 5px;
}

.popover-static-demo .bs-popover-left .arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^=left] .arrow,
.popover-static-demo .bs-popover-right .arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^=right] .arrow {
    top: 50%;
    margin-top: -5px;
}

.bs-popover-top,
.bs-popover-auto[data-popper-placement^=top] {
    margin-bottom: 0.5rem;
}

.bs-popover-top>.arrow,
.bs-popover-auto[data-popper-placement^=top]>.arrow {
    bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[data-popper-placement^=top]>.arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #eaedf7;
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[data-popper-placement^=top]>.arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #ffffff;
}

.bs-popover-right,
.bs-popover-auto[data-popper-placement^=right] {
    margin-left: 0.5rem;
}

.bs-popover-right>.arrow,
.bs-popover-auto[data-popper-placement^=right]>.arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[data-popper-placement^=right]>.arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #eaedf7;
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[data-popper-placement^=right]>.arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ffffff;
}

.bs-popover-bottom,
.bs-popover-auto[data-popper-placement^=bottom] {
    margin-top: 0.5rem;
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[data-popper-placement^=bottom]>.arrow {
    top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[data-popper-placement^=bottom]>.arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #eaedf7;
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[data-popper-placement^=bottom]>.arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[data-popper-placement^=left] {
    margin-right: 0.5rem;
}

.bs-popover-left>.arrow,
.bs-popover-auto[data-popper-placement^=left]>.arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[data-popper-placement^=left]>.arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #eaedf7;
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[data-popper-placement^=left]>.arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ffffff;
}

.popover-primary.bs-popover-top .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=top] .popover-arrow::after {
    border-top-color: var(--primary-bg-color);
}

.popover-secondary.bs-popover-top .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^=top] .popover-arrow::after {
    border-top-color: #f1388b;
}

.modal-open .popover {
    z-index: 999999;
}

.modal-open .tooltip {
    z-index: 9999;
}

/* ###### Popover  ###### */
/* ###### Progress ###### */
.progress-bar-xs {
    height: 5px;
}

.progress-bar-sm {
    height: 8px;
}

.progress-bar-lg {
    height: 15px;
}

.progress {
    display: flex;
    height: 10px;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: #eaedf7;
    border-radius: 3px;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    transition: width 0.6s ease;
    border-radius: 10px;
    font-size: 11px;
    height: 10px;
    font-size: 11px;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}

/* ###### Progress ###### */
/* ###### Table  ###### */
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #1d212f;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: middle;
    padding: 9px 19px;
    line-height: 1.462;
    border-top: 0;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 1px solid #e8e8f7 !important;
}

.table tbody+tbody {
    border-top: 1px solid #e8e8f7;
}

.table thead th,
.table thead td {
    color: #3c4858;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    border-bottom-width: 1px;
    border-top-width: 0;
    padding: 11px 19px;
}

.table tbody tr {
    background-color: rgba(255, 255, 255, 0.5);
}

.table tbody tr th {
    font-weight: 500;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #e8e8f7 !important;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #e8e8f7;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
    border-top-width: 1px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: transparent;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    background-color: #f8f8ff;
}

.table-primary {
    background-color: #c6d4ff;
}

.table-primary>th,
.table-primary>td {
    background-color: #c6d4ff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #95afff;
}

.table-hover .table-primary:hover {
    background-color: #adc1ff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #adc1ff;
}

.table-secondary {
    background-color: #d9dde5;
}

.table-secondary>th,
.table-secondary>td {
    background-color: #d9dde5;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #b9c1ce;
}

.table-hover .table-secondary:hover {
    background-color: #cacfdb;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #cacfdb;
}

.table-success {
    background-color: #c8e9b8;
}

.table-success>th,
.table-success>td {
    background-color: #c8e9b8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #99d67b;
}

.table-hover .table-success:hover {
    background-color: #b9e3a5;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b9e3a5;
}

.table-info {
    background-color: #bee5eb;
}

.table-info>th,
.table-info>td {
    background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #86cfda;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5;
}

.table-warning {
    background-color: #ffeeba;
}

.table-warning>th,
.table-warning>td {
    background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1;
}

.table-danger {
    background-color: #f5c6cb;
}

.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ed969e;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7;
}

.table-light {
    background-color: #fcfcfd;
}

.table-light>th,
.table-light>td {
    background-color: #fcfcfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #f9fafb;
}

.table-hover .table-light:hover {
    background-color: #ededf3;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ededf3;
}

.table-dark {
    color: #ffffff;
    background-color: #3b4863;
}

.table-dark>th,
.table-dark>td {
    background-color: #c8ccd3;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #49597b;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.075);
}

.table-hover .table-dark:hover {
    background-color: #babfc8;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #babfc8;
}

.table-active {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #ffffff;
    background-color: #3b4863;
    border-color: #49597b;
}

.table .thead-light th {
    color: #3c4858;
    background-color: #eaedf7;
    border-color: #eaedf7;
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: a;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #efefff;
}

.table.dtr-details {
    margin-bottom: 0;
}

.table-shopping-cart .media {
    display: flex;
    align-items: flex-start;
    padding: 0.75rem;
}

table.table-bordered.dataTable thead tr:first-child th,
table.table-bordered.dataTable thead tr:first-child td {
    border-top-width: 0 !important;
}

.table-bordered thead th {
    background: none !important;
    border-bottom-width: 0px !important;
}

.table-responsive.tasks .table> :not(caption)>*>* {
    border-bottom: 0 !important;
}

.table-responsive.userlist-table .table> :not(caption)>*>* {
    border-bottom: 0 !important;
}

.table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #efefff;
}

.table-responsive .table-product.table> :not(caption)>*>* {
    border-bottom: 0px !important;
}

table.dataTable {
    width: 100% !important;
}

/* ###### Table  ###### */
/* ###### Datatables ###### */
table.dataTable {
    border: 1px solid #e8e8f7;
}

table.dataTable.no-footer {
    border-bottom-color: #eaedf7;
}

table.dataTable thead th,
table.dataTable thead td {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 8px 10px;
    font-weight: 700;
    font-size: 12px;
    color: #434b63;
}

.dataTables_wrapper .dataTables_filter input {
    margin-left: 0;
    border: 1px solid #e8e8f7;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
    border: 0;
    background-image: none;
    color: #ffffff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
    border: 0;
    background-image: none;
    color: #ffffff !important;
}

.table.dataTable tr {
    border: 1px solid #e8e8f7;
    white-space: nowrap;
}

.dataTables_info {
    margin-bottom: 1rem;
}

.table.dataTable th,
.table.dataTable td {
    border-left: 1px solid #e8e8f7;
    outline: none;
}

div.dataTables_wrapper div.dataTables_length select {
    margin-right: 10px;
}

/* ######  Datatables ###### */
/* ###### Toast ###### */
.toast {
    max-width: 100%;
    width: 300px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.95);
    border-color: #eaedf7;
    box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
    border-radius: 0px;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-body {
    padding: 0.75rem;
}

.toast-header {
    border-bottom-color: rgba(205, 212, 224, 0.4);
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.toast-header .close {
    font-weight: 300;
    color: #a8afc7;
}

.demo-static-toast .toast {
    opacity: 1;
}

/* ###### Toast ###### */
/* ###### Tooltip  ###### */
.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76562rem;
    word-wrap: break-word;
    opacity: 0;
    top: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

.tooltip-inner {
    font-size: 12px;
    border-radius: 2px;
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #ffffff;
    text-align: center;
    background-color: #000000;
    border-radius: 3px;
}

.tooltip-primary .tooltip-inner {
    background-color: var(--primary-bg-color) !important;
    color: #ffffff !important;
}

.tooltip-primary.bs-tooltip-top .arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^=top] .arrow::before {
    border-top-color: var(--primary-bg-color) !important;
}

.tooltip-primary.bs-tooltip-bottom .arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before {
    border-bottom-color: var(--primary-bg-color);
}

.tooltip-primary.bs-tooltip-left .arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    border-left-color: var(--primary-bg-color);
}

.tooltip-primary.bs-tooltip-right .arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    border-right-color: var(--primary-bg-color);
}

.tooltip-secondary .tooltip-inner {
    background-color: #f1388b !important;
    color: #ffffff;
}

.tooltip-secondary.bs-tooltip-top .arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=top] .arrow::before {
    border-top-color: #f1388b !important;
}

.tooltip-secondary.bs-tooltip-bottom .arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before {
    border-bottom-color: #f1388b !important;
}

.tooltip-secondary.bs-tooltip-left .arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    border-left-color: #f1388b;
}

.tooltip-secondary.bs-tooltip-right .arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    border-right-color: #f1388b;
}

.tooltip-info .tooltip-inner {
    background-color: #01b8ff !important;
    color: #ffffff !important;
}

.tooltip-info.bs-tooltip-top .arrow::before,
.tooltip-info.bs-tooltip-auto[data-popper-placement^=top] .arrow::before {
    border-top-color: #01b8ff !important;
}

.tooltip-info.bs-tooltip-bottom .arrow::before,
.tooltip-info.bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before {
    border-bottom-color: #01b8ff;
}

.tooltip-info.bs-tooltip-left .arrow::before,
.tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    border-left-color: #01b8ff;
}

.tooltip-info.bs-tooltip-right .arrow::before,
.tooltip-info.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    border-right-color: #01b8ff;
}

.tooltip-danger .tooltip-inner {
    background-color: #f16d75 !important;
    color: #ffffff !important;
}

.tooltip-danger.bs-tooltip-top .arrow::before,
.tooltip-danger.bs-tooltip-auto[data-popper-placement^=top] .arrow::before {
    border-top-color: #f16d75 !important;
}

.tooltip-danger.bs-tooltip-bottom .arrow::before,
.tooltip-danger.bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before {
    border-bottom-color: #f16d75;
}

.tooltip-danger.bs-tooltip-left .arrow::before,
.tooltip-danger.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    border-left-color: #f16d75;
}

.tooltip-danger.bs-tooltip-right .arrow::before,
.tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    border-right-color: #f16d75;
}

.tooltip-success .tooltip-inner {
    background-color: #19b159;
    color: #ffffff;
}

.tooltip-success.bs-tooltip-top .arrow::before,
.tooltip-success.bs-tooltip-auto[data-popper-placement^=top] .arrow::before {
    border-top-color: #19b159;
}

.tooltip-success.bs-tooltip-bottom .arrow::before,
.tooltip-success.bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before {
    border-bottom-color: #19b159;
}

.tooltip-success.bs-tooltip-left .arrow::before,
.tooltip-success.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    border-left-color: #19b159;
}

.tooltip-success.bs-tooltip-right .arrow::before,
.tooltip-success.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    border-right-color: #19b159;
}

.tooltip-warning .tooltip-inner {
    background-color: #ff9b21;
    color: #8f9cc0;
}

.tooltip-warning.bs-tooltip-top .arrow::before,
.tooltip-warning.bs-tooltip-auto[data-popper-placement^=top] .arrow::before {
    border-top-color: #ff9b21;
}

.tooltip-warning.bs-tooltip-bottom .arrow::before,
.tooltip-warning.bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before {
    border-bottom-color: #ff9b21;
}

.tooltip-warning.bs-tooltip-left .arrow::before,
.tooltip-warning.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    border-left-color: #ff9b21;
}

.tooltip-warning.bs-tooltip-right .arrow::before,
.tooltip-warning.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    border-right-color: #ff9b21;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^=top] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[data-popper-placement^=top] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000000;
}

.bs-tooltip-right,
.bs-tooltip-auto[data-popper-placement^=right] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[data-popper-placement^=right] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^=bottom] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[data-popper-placement^=bottom] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000000;
}

.bs-tooltip-left,
.bs-tooltip-auto[data-popper-placement^=left] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[data-popper-placement^=left] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000000;
}

.tooltip-static-demo {
    text-align: center;
    background-color: #eaedf7;
    padding: 20px;
}

.tooltip-static-demo .tooltip {
    z-index: 0;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
}

.tooltip-static-demo .bs-tooltip-top .arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=top] .arrow,
.tooltip-static-demo .bs-tooltip-bottom .arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=bottom] .arrow {
    left: 50%;
    margin-left: -2px;
}

.tooltip-static-demo .bs-tooltip-left,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=left],
.tooltip-static-demo .bs-tooltip-right,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=right] {
    margin-top: 5px;
}

.tooltip-static-demo .bs-tooltip-left .arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=left] .arrow,
.tooltip-static-demo .bs-tooltip-right .arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=right] .arrow {
    top: 40%;
    margin-top: -3px;
}

.tooltip-primary.bs-tooltip-top .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: var(--primary-bg-color) !important;
}

.tooltip-secondary.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: #f1388b !important;
}

.tooltip-info.bs-tooltip-start .tooltip-arrow::before,
.tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color: #01b8ff !important;
}

.tooltip-danger.bs-tooltip-end .tooltip-arrow::before,
.tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: #fd6074 !important;
}

/* ###### Tooltip  ###### */
/* ############### Switcher STYLES ############### */
body {
    background: #eaedf7;
}

.custom-card {
    box-shadow: 0 10px 30px 0 var(--primary005);
}

.bg-primary {
    background-color: var(--primary-bg-color) !important;
}

.bg-primary::hover,
.bg-primary::focus {
    background-color: var(--primary-bg-color) !important;
}

.bg-primary-transparent {
    background-color: var(--primary01);
}

.select2-container--classic .select2-selection--single:focus,
.select2-container--classic.select2-container--open .select2-selection--single,
.select2-container--classic .select2-selection--multiple:focus {
    border-color: var(--primary-bg-color);
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
    border-color: var(--primary-bg-color);
}

.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: var(--primary-bg-color);
}

.main-header-center .search-btn {
    background-color: var(--primary-bg-color) !important;
}

.text-primary {
    color: var(--primary-bg-color) !important;
}

.text-primary:hover,
.text-primary :focus {
    color: var(--primary-bg-color) !important;
}

.border-primary {
    border-color: var(--primary-bg-color) !important;
}

.bd-primary {
    border-color: var(--primary-bg-color);
}

.card-item .card-item-icon.card-icon {
    background: var(--primary01);
    fill: var(--primary-bg-color);
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-month {
    color: var(--primary-bg-color);
}

.card-dashboard-calendar .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

#back-to-top {
    background: var(--primary-bg-color);
}

.ckbox span:after {
    background-color: var(--primary-bg-color);
}

.btn-primary {
    color: #ffffff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-primary:hover {
    color: #ffffff;
    background-color: var(--primary-bg-hover);
    border-color: var(--primary-bg-hover);
}

.btn-primary:focus,
.btn-primary.focus {
    background-color: var(--primary-bg-hover);
    border-color: var(--primary-bg-hover);
    box-shadow: 0 0 0 1px var(--primary07);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #ffffff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: var(--primary08);
    border-color: var(--primary08);
}

.show>.btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: var(--primary08);
    border-color: var(--primary08);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px var(--primary07);
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px var(--primary07);
}

.btn-main-primary {
    color: #ffffff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-main-primary:hover {
    color: #ffffff;
    background-color: var(--primary-bg-hover);
    border-color: var(--primary-bg-hover);
}

.btn-main-primary:focus,
.btn-main-primary.focus {
    box-shadow: 0 0 0 1px var(--primary07);
}

.btn-main-primary.disabled,
.btn-main-primary:disabled {
    color: #ffffff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-main-primary:not(:disabled):not(.disabled):active,
.btn-main-primary:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background-color: var(--primary08);
    border-color: var(--primary08);
}

.show>.btn-main-primary.dropdown-toggle {
    color: #ffffff;
    background-color: var(--primary08);
    border-color: var(--primary08);
}

.btn-main-primary:not(:disabled):not(.disabled):active:focus,
.btn-main-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px var(--primary07);
}

.show>.btn-main-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px var(--primary07);
}

.btn-outline-primary {
    color: var(--primary-bg-color) !important;
    border-color: var(--primary-bg-color);
}

.btn-outline-primary:hover {
    color: #ffffff !important;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 1px var(--primary07);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: var(--primary-bg-color);
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
    color: #ffffff !important;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.show>.btn-outline-primary.dropdown-toggle {
    color: #ffffff !important;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 1px var(--primary07);
}

.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 1px var(--primary07);
}

.bg-primary {
    color: #ffffff !important;
    background-color: var(--primary-bg-color);
}

a.bg-primary:hover {
    color: #ffffff !important;
    background-color: var(--primary-bg-color);
}

a.bg-primary:focus {
    color: #ffffff !important;
    background-color: var(--primary-bg-color);
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--primary05);
}

a.bg-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--primary05);
}

.main-nav-column .nav-link.active {
    color: var(--primary-bg-color) !important;
}

.main-nav-column .nav-link.active:hover,
.main-nav-column .nav-link.active:focus {
    color: var(--primary-bg-color) !important;
}

a {
    color: var(--primary-bg-color);
}

a:hover {
    color: var(--primary-bg-color);
}

.main-nav-line-chat .nav-link.active,
.main-msg-send {
    color: var(--primary-bg-color) !important;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header.fc-today>a {
    color: var(--primary-bg-color);
}

.main-calendar td.fc-today .fc-day-number {
    background-color: var(--primary-bg-color);
}

.main-calendar td.fc-today .fc-day-number:hover,
.main-calendar td.fc-today .fc-day-number:focus {
    background-color: var(--primary-bg-color);
}

.main-avatar {
    background-color: var(--primary-bg-color);
}

.page-item.active .page-link {
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>th:first-child:before {
    background-color: var(--primary-bg-color);
}

.nav-pills .nav-link.active {
    background-color: var(--primary-bg-color);
    color: #ffffff !important;
}

.main-nav .nav-link.active {
    color: var(--primary-bg-color) !important;
}

.accordion .card-header a {
    color: var(--primary-bg-color);
}

.accordion-color .card-header a.collapsed {
    background-color: var(--primary-bg-color) !important;
}

.timeline-wrapper-primary .timeline-panel:before,
.timeline-wrapper-primary .timeline-badge {
    background: var(--primary-bg-color);
}

.main-content-body-profile .main-nav-line .nav-link.active {
    color: var(--primary-bg-color);
}

.custom-switch-input:checked~.custom-switch-indicator {
    background: var(--primary-bg-color);
}

.rdiobox input[type=radio]:checked+span:before {
    border-color: transparent;
    background-color: var(--primary-bg-color);
}

.selectgroup-input:checked+.selectgroup-button {
    border-color: var(--primary-bg-color);
    color: var(--primary-bg-color);
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    background: var(--primary01);
    color: var(--primary-bg-color);
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:hover,
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:focus {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.irs-bar {
    background-color: var(--primary-bg-color);
}

.irs-from,
.irs-to,
.irs-single {
    color: #ffffff;
    background: var(--primary-bg-color);
}

.irs-line-mid,
.irs-line-right {
    background-color: #eaedf7;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: var(--primary-bg-color);
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:hover .number,
.wizard>.steps .current a:active .number {
    background-color: var(--primary-bg-color) !important;
}

.profile-cover__info .nav strong,
.profile-tab .main-nav-line .nav-link.active {
    color: var(--primary-bg-color) !important;
}

.pricing-tabs .nav-price li .active {
    color: var(--primary-bg-color);
}

.signpages .details:before {
    background: var(--primary-bg-color);
}

.construction:before {
    background: var(--primary-bg-color);
}

.tag-primary,
.progress-bar {
    background-color: var(--primary-bg-color);
}

.breadcrumb {
    background-color: #eaedf7;
}

.bg-primary .main-nav .nav-link.active,
.bg-secondary .main-nav .nav-link.active {
    color: #ffffff !important;
}

.popover-header {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.lds-heart div:after,
.lds-heart div:before {
    background: var(--primary-bg-color);
}

.lds-ripple div {
    border-color: var(--primary-bg-color);
}

.lds-spinner div:after {
    background: var(--primary-bg-color);
}

.lds-circle>div {
    background: var(--primary-bg-color);
}

.lds-dual-ring:after {
    border-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color) transparent var(--primary-bg-color) transparent;
}

.lds-facebook div {
    background: var(--primary-bg-color);
}

.main-navbar .nav-item.active .nav-link {
    color: #ffffff;
}

.main-navbar .nav-item.active .nav-link.with-sub::after {
    color: #ffffff;
}

.main-navbar .nav-sub-item.active>.nav-sub-link.active {
    color: #ffffff;
}

.main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
    color: #ffffff;
}

.main-navbar .nav-sub-item.active>.nav-sub-link.active .sub-with-sub::after {
    color: #ffffff !important;
}

.main-navbar .nav-sub-link:hover,
.main-navbar .nav-sub-link:focus {
    color: #ffffff !important;
}

@media (min-width: 992px) {

    .main-navbar .nav-sub-link:hover,
    .main-navbar .nav-sub-link:focus {
        color: #ffffff;
    }

    .main-navbar .nav-sub-item.active>.nav-sub-link.active {
        color: #ffffff;
    }

    .main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
        color: #ffffff;
    }
}

@media (max-width: 991px) {
    .main-navbar .nav-sub-item.active>.nav-sub-link.active {
        color: #ffffff;
    }

    .main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
        color: #ffffff;
    }

    .main-navbar .nav-sub-link:hover,
    .main-navbar .nav-sub-link:focus {
        color: #ffffff !important;
    }
}

.main-form-search .btn,
.main-form-search .sp-container button {
    background-color: var(--primary-bg-color);
}

.flat a:hover,
.flat a.active,
.flat a:hover:after,
.flat a.active:after {
    background: var(--primary-bg-color) !important;
    color: #ffffff !important;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    background-color: var(--primary-bg-color);
    color: #ffffff !important;
}

.lds-heart div {
    background: var(--primary-bg-color);
}

.accordion .card-header a.collapsed:hover,
.accordion .card-header a.collapsed:focus {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.main-sidebar-body li.active .sidemenu-label,
.main-sidebar-body li.active i {
    color: var(--primary-bg-color);
}

.main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub {
    color: #ffffff !important;
}

.main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub .sidemenu-label,
.main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub i,
.main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub::before {
    color: #ffffff !important;
    opacity: 1 !important;
}

.main-sidebar-body li.nav-sub-item.active .nav-sub-item .nav-sub-link.active {
    color: #ffffff !important;
}

.main-sidebar-body li.nav-sub-item.active .nav-sub-item .nav-sub-link.active::before {
    color: #ffffff !important;
    opacity: 1 !important;
}

.main-sidebar-body .nav-sub-link:hover,
.main-sidebar-body .nav-sub-link:focus {
    color: #ffffff !important;
}

.main-sidebar-body .nav-sub-link:hover::before,
.main-sidebar-body .nav-sub-link:focus::before {
    color: #ffffff !important;
    opacity: 1 !important;
}

.main-sidebar-body .nav-sub-link:hover.sub-with-sub .sidemenu-label,
.main-sidebar-body .nav-sub-link:hover.sub-with-sub .angle,
.main-sidebar-body .nav-sub-link:focus.sub-with-sub .sidemenu-label,
.main-sidebar-body .nav-sub-link:focus.sub-with-sub .angle {
    color: #ffffff !important;
}

.main-sidebar-body li.active .sidemenu-icon {
    background: #0E0E23;
}

.main-sidebar-body .nav-sub-item.active .nav-sub-item .nav-sub-link:hover,
.main-sidebar-body .nav-sub-item.active .nav-sub-item .nav-sub-link:focus {
    color: #ffffff !important;
}

.main-sidebar-body .nav-sub-item.active .nav-sub-item .nav-sub-link:hover::before,
.main-sidebar-body .nav-sub-item.active .nav-sub-item .nav-sub-link:focus::before {
    color: #ffffff !important;
}

.project-status.primary:before {
    border-left-color: var(--primary-bg-color);
    border-right-color: var(--primary-bg-color);
}

.bg-primary-light {
    background-color: var(--primary01);
    color: var(--primary-bg-color);
}

.list-card {
    background: var(--primary005);
}

.forth.circle .chart-circle-value.circle-style {
    border-color: var(--primary07);
}

.crypto-btc-usd .nav-link.active {
    background-color: var(--primary-bg-color) !important;
    color: #ffffff;
}

.irs-slider:before {
    background-color: var(--primary-bg-color) !important;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #ffffff;
    border-color: var(--primary-bg-color);
    background-color: var(--primary-bg-color);
}

.sweet-alert button {
    background-color: var(--primary-bg-color) !important;
}

.popover-primary {
    background-color: var(--primary-bg-color) !important;
    border-color: var(--primary-bg-color);
}

.popover-primary.bs-popover-top>.arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^=top]>.arrow::after,
.popover-primary.bs-popover-top>.arrow::before,
.popover-primary.bs-popover-auto[data-popper-placement^=top]>.arrow::before {
    border-top-color: var(--primary-bg-color);
}

.peity path:first-child {
    fill: var(--primary-bg-color);
}

.peity path:last-child {
    fill: var(--primary01);
}

.main-navbar .nav-sub-link.active.sub-with-sub::after {
    color: #ffffff !important;
}

@media (min-width: 992px) {

    .main-navbar .nav-sub-link:hover.sub-with-sub::after,
    .main-navbar .nav-sub-link:focus.sub-with-sub::after {
        color: #ffffff !important;
    }
}

.lds-dual-ring:after {
    border: 5px solid var(--primary-bg-color);
    border-color: var(--primary-bg-color) transparent var(--primary-bg-color) transparent;
}

.lds-ripple div {
    border: 4px solid var(--primary-bg-color);
}

.alert-message {
    border: 1px solid var(--primary-bg-color) !important;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.1) !important;
    padding: 1rem;
}

.main-sidebar-body .nav-sub-item.active>.nav-sub-link.active:before {
    color: #ffffff !important;
    opacity: 1;
}

body.dark-theme {
    background: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme hr {
    border-top-color: var(--dark-border);
}

.dark-theme caption,
.dark-theme .blockquote-footer {
    color: var(--dark-color);
}

.dark-theme .img-thumbnail {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .figure-caption {
    color: var(--dark-color);
}

.dark-theme kbd {
    background-color: var(--dark-theme);
}

.dark-theme .accordion-color .card-header a.collapsed {
    color: var(--dark-color) !important;
}

.dark-theme .accordion-faq .accordion .card-header a {
    background-color: var(--dark-body);
}

.dark-theme .accordion .card-header a {
    background-color: var(--dark-body);
}

.dark-theme .accordion .card-header a.collapsed {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .accordion .card-header a.collapsed:hover,
.dark-theme .accordion .card-header a.collapsed:focus {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .accordion .card-body {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .alert-message,
.dark-theme .alert-default {
    border-color: var(--dark-border);
}

.dark-theme .breadcrumb-style1 .breadcrumb-item a,
.dark-theme .breadcrumb-style2 .breadcrumb-item a,
.dark-theme .breadcrumb-style3 .breadcrumb-item a,
.dark-theme .breadcrumb-style1 .breadcrumb-item+.breadcrumb-item::before,
.dark-theme .breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before,
.dark-theme .breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    color: var(--dark-color);
}

.dark-theme .breadcrumb-item+.breadcrumb-item::before,
.dark-theme .breadcrumb-item.active {
    color: var(--dark-color);
}

.dark-theme .btn-light {
    color: var(--dark-color);
    background-color: var(--dark-body);
    border-color: var(--dark-body);
}

.dark-theme .btn-light:hover,
.dark-theme .btn-light:focus,
.dark-theme .btn-light.focus,
.dark-theme .btn-light.disabled,
.dark-theme .btn-light:disabled {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-theme);
}

.dark-theme .btn-light:not(:disabled):not(.disabled):active,
.dark-theme .btn-light:not(:disabled):not(.disabled).active {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .show>.btn-light.dropdown-toggle {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .btn-light:not(:disabled):not(.disabled):active:focus,
.dark-theme .btn-light:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
}

.dark-theme .btn-link:disabled,
.dark-theme .btn-link.disabled {
    color: var(--dark-color);
}

.dark-theme .btn-default {
    color: var(--dark-color) !important;
}

.dark-theme .card {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .main-content-label {
    color: var(--dark-color);
}

.dark-theme .card-header {
    border-bottom-color: var(--dark-border);
}

.dark-theme .card-footer {
    background: var(--dark-theme);
    border-top-color: var(--dark-border);
}

.dark-theme .card-header,
.dark-theme .card-footer {
    border-color: var(--dark-border);
}

.dark-theme .card-item .card-item-title {
    color: var(--dark-color);
}

.dark-theme .card-item .card-item-title small {
    color: var(--dark-color);
}

.dark-theme .card-item .card-item-body .card-item-stat .card-item-figure,
.dark-theme .card-item .card-item-body .card-item-stat small {
    color: var(--dark-color);
}

.dark-theme .card-item .card-item-body .card-item-redirect .btn-card-item-view {
    background: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .card-dashboard-calendar .main-content-label {
    color: var(--dark-color);
}

.dark-theme .card-options a:not(.btn) {
    color: var(--dark-color);
}

.dark-theme .card-options a:not(.btn):hover {
    color: var(--dark-color);
}

.dark-theme .custom-card-header {
    background: var(--dark-theme);
}

.dark-theme .card-body+.card-body {
    border-top-color: var(--dark-border);
}

.dark-theme .card-blog-overlay .custom-card-header,
.dark-theme .card-blog-overlay1 .custom-card-header,
.dark-theme .card-blog-overlay2 .custom-card-header {
    border-bottom-color: var(--dark-border);
}

.dark-theme .card-blog-overlay .card-footer,
.dark-theme .card-blog-overlay1 .card-footer,
.dark-theme .card-blog-overlay2 .card-footer {
    border-top-color: var(--dark-border);
}

.dark-theme .card-item-desc .card-item-desc-1 dd {
    color: var(--dark-color);
}

.dark-theme .carousel-slider #thumbcarousel .carousel-item .thumb:hover {
    border-color: var(--dark-border);
}

.dark-theme .carousel-control:active,
.dark-theme .carousel-control:focus,
.dark-theme .carousel-control:hover {
    color: var(--dark-color);
}

.dark-theme .owl-nav button {
    background: var(--dark-body) !important;
    border-color: var(--dark-border) !important;
}

.dark-theme .product-carousel .thumb {
    border-color: var(--dark-border);
}

.dark-theme .dropdown-divider {
    border-top-color: var(--dark-border);
}

.dark-theme .dropdown-item {
    color: var(--dark-color);
}

.dark-theme .dropdown-item:hover,
.dark-theme .dropdown-item:focus {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .dropdown-item.disabled,
.dark-theme .dropdown-item:disabled {
    color: var(--dark-color);
}

.dark-theme .dropdown-header,
.dark-theme .dropdown-item-text {
    color: var(--dark-color);
}

.dark-theme .dropdown-menu {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .dropdown-menu .main-header-center .select2-container--default .select2-selection--single {
    background: var(--dark-body);
}

.dark-theme .dropdown-menu .main-header-center .form-control {
    background: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .form-control {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .form-control:focus {
    color: var(--dark-color);
    border-color: var(--dark-border);
}

.dark-theme .form-control::placeholder {
    color: var(--dark-color);
}

.dark-theme .form-control:disabled,
.dark-theme .form-control[readonly] {
    background-color: var(--dark-body);
}

.dark-theme select.form-control:focus::-ms-value {
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme .form-control-plaintext,
.dark-theme .form-check-input:disabled~.form-check-label,
.dark-theme .form-label {
    color: var(--dark-color);
}

.dark-theme .file-browser .btn-default {
    border-color: var(--dark-border);
}

.dark-theme .main-form-group {
    border-color: var(--dark-border);
}

.dark-theme .main-form-group.focus {
    border-color: var(--dark-border);
}

.dark-theme .main-form-group .form-label,
.dark-theme .main-form-group .form-control {
    color: var(--dark-color);
}

.dark-theme input,
.dark-theme select,
.dark-theme textarea {
    color: var(--dark-color);
}

.dark-theme .input-group-text {
    color: var(--dark-color);
    background-color: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .modal.effect-just-me .modal-header {
    border-bottom-color: var(--dark-border);
}

.dark-theme .modal.effect-just-me .modal-content {
    background: var(--dark-body);
}

.dark-theme .modal.effect-just-me .modal-body {
    color: var(--dark-color);
}

.dark-theme .modal.effect-just-me .modal-footer {
    border-top-color: var(--dark-border);
}

.dark-theme .close {
    color: var(--dark-color);
}

.dark-theme .close:hover {
    color: var(--dark-color);
}

.dark-theme .modal-backdrop {
    background-color: var(--dark-body);
}

.dark-theme .modal-header {
    border-bottom-color: var(--dark-border);
}

.dark-theme .modal-title {
    color: var(--dark-color);
}

.dark-theme .modal-footer {
    border-top-color: var(--dark-border);
}

.dark-theme .modal-content-demo .modal-body h6 {
    color: var(--dark-color);
}

.dark-theme .modal-backdrop {
    background-color: var(--dark-body);
}

.dark-theme .modal-content {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.dark-theme .nav-pills .nav-link {
    color: var(--dark-color);
}

.dark-theme .nav-pills .nav-link:hover,
.dark-theme .nav-pills .nav-link:focus {
    color: var(--dark-color);
}

.dark-theme .nav-dark .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-nav .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-nav .nav-link:hover,
.dark-theme .main-nav .nav-link:focus {
    color: var(--dark-color);
}

.dark-theme .main-nav-column .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-nav-column .nav-link i:not([class*=" tx-"]),
.dark-theme .main-nav-column .nav-link:hover,
.dark-theme .main-nav-column .nav-link:focus,
.dark-theme .main-nav-column .nav-link:hover i:not([class*=" tx-"]),
.dark-theme .main-nav-column .nav-link:focus i:not([class*=" tx-"]) {
    color: var(--dark-color);
}

.dark-theme .main-nav-dark .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-nav-dark .nav-link+.nav-link {
    border-color: var(--dark-border);
}

.dark-theme .main-nav-colored-bg .nav-link+.nav-link {
    border-color: var(--dark-border);
}

.dark-theme .main-nav-line .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-nav-line .nav-link.active {
    color: var(--dark-color);
}

.dark-theme .main-nav-line.main-nav-dark .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-nav-line.main-nav-dark .nav-link.active::before {
    background-color: var(--dark-theme);
}

@media (min-width: 992px) {
    .dark-theme .main-nav-line-chat {
        border-bottom-color: var(--dark-border);
    }
}

.dark-theme .page-link {
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme .page-link:hover,
.dark-theme .page-link:focus {
    background-color: var(--dark-theme);
}

.dark-theme .page-link:hover {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .page-link:focus {
    box-shadow: 0 0 0 0.2rem var(--dark-border);
}

.dark-theme .page-item.disabled .page-link {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .pagination-dark .page-link {
    background-color: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .pagination-dark .page-link:hover,
.dark-theme .pagination-dark .page-link:focus {
    background-color: var(--dark-theme);
}

.dark-theme .pagination-circle {
    background: var(--dark-body);
}

.dark-theme .pagination-circle li a,
.dark-theme .pagination-circle li span {
    background: var(--dark-theme);
}

.dark-theme .pagination-radius li a,
.dark-theme .pagination-radius li span {
    border-color: var(--dark-border);
    background: var(--dark-theme);
}

.dark-theme .popover {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .popover-header {
    background-color: var(--dark-theme);
    border-bottom-color: var(--dark-border);
}

.dark-theme .popover-body {
    color: var(--dark-color);
}

.dark-theme .popover-static-demo {
    background-color: var(--dark-theme);
}

.dark-theme .bs-popover-top>.arrow::before,
.dark-theme .bs-popover-auto[data-popper-placement^=top]>.arrow::before,
.dark-theme .bs-popover-top>.arrow::after,
.dark-theme .bs-popover-auto[data-popper-placement^=top]>.arrow::after {
    border-top-color: var(--dark-border);
}

.dark-theme .bs-popover-right>.arrow::before,
.dark-theme .bs-popover-auto[data-popper-placement^=right]>.arrow::before,
.dark-theme .bs-popover-right>.arrow::after,
.dark-theme .bs-popover-auto[data-popper-placement^=right]>.arrow::after {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .bs-popover-bottom>.arrow::before,
.dark-theme .bs-popover-auto[data-popper-placement^=bottom]>.arrow::before,
.dark-theme .bs-popover-bottom>.arrow::after,
.dark-theme .bs-popover-auto[data-popper-placement^=bottom]>.arrow::after,
.dark-theme .bs-popover-bottom .popover-header::before,
.dark-theme .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    border-bottom-color: var(--dark-border);
}

.dark-theme .bs-popover-left>.arrow::before,
.dark-theme .bs-popover-auto[data-popper-placement^=left]>.arrow::before,
.dark-theme .bs-popover-left>.arrow::after,
.dark-theme .bs-popover-auto[data-popper-placement^=left]>.arrow::after {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .popover-secondary.bs-popover-top .popover-arrow::after,
.dark-theme .popover-secondary.bs-popover-auto[data-popper-placement^=top] .popover-arrow::after {
    border-top-color: var(--dark-border);
}

.dark-theme .progress {
    background-color: var(--dark-body);
}

.dark-theme .table {
    color: var(--dark-color);
}

.dark-theme .table thead th {
    border-bottom-color: var(--dark-border) !important;
}

.dark-theme .table tbody+tbody {
    border-top-color: var(--dark-border);
}

.dark-theme .table tbody tr {
    background-color: var(--dark-theme);
}

.dark-theme .table-bordered {
    border-color: var(--dark-border) !important;
}

.dark-theme .table-bordered th,
.dark-theme .table-bordered td {
    border-color: var(--dark-border);
}

.dark-theme .table-striped tbody tr:nth-of-type(odd),
.dark-theme .table-hover tbody tr:hover {
    background-color: var(--dark-theme);
}

.dark-theme .table-active {
    background-color: var(--dark-theme);
}

.dark-theme .table-active>th,
.dark-theme .table-active>td {
    background-color: var(--dark-theme);
}

.dark-theme .table-hover .table-active:hover {
    background-color: var(--dark-theme);
}

.dark-theme .table-hover .table-active:hover>td,
.dark-theme .table-hover .table-active:hover>th {
    background-color: var(--dark-theme);
}

.dark-theme .table .thead-light th {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: var(--primary01);
}

.dark-theme table.dataTable {
    border-color: var(--dark-border);
}

.dark-theme table.dataTable.no-footer {
    border-bottom-color: var(--dark-border);
}

.dark-theme table.dataTable thead th,
.dark-theme table.dataTable thead td {
    color: var(--dark-color);
}

.dark-theme .dataTables_wrapper .dataTables_filter input {
    border-color: var(--dark-border);
}

.dark-theme .table.dataTable tr {
    border-color: var(--dark-border);
}

.dark-theme .table.dataTable th,
.dark-theme .table.dataTable td {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .toast {
    background-color: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .toast-header {
    border-bottom-color: var(--dark-border);
    background-color: var(--dark-body);
}

.dark-theme .toast-header .close {
    color: var(--dark-color);
}

.dark-theme .bs-tooltip-top .arrow::before,
.dark-theme .bs-tooltip-auto[data-popper-placement^=top] .arrow::before {
    border-top-color: var(--dark-border);
}

.dark-theme .bs-tooltip-right .arrow::before,
.dark-theme .bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .bs-tooltip-bottom .arrow::before,
.dark-theme .bs-tooltip-auto[data-popper-placement^=bottom] .arrow::before {
    border-bottom-color: var(--dark-border);
}

.dark-theme .bs-tooltip-left .arrow::before,
.dark-theme .bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .tooltip-static-demo {
    background-color: var(--dark-theme);
}

.dark-theme .main-header-center .form-control {
    border-color: var(--dark-border);
}

.dark-theme pre {
    background-color: var(--dark-body);
}

.dark-theme .form-control:disabled,
.dark-theme .form-control[readonly] {
    background-color: var(--dark-body);
}

.dark-theme .selectgroup-input:checked+.selectgroup-button {
    background: var(--dark-body);
}

.dark-theme .input-group-text {
    background-color: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .irs-line-mid,
.dark-theme .irs-line-right {
    background-color: var(--dark-theme);
}

.dark-theme .irs-min,
.dark-theme .irs-max {
    color: var(--dark-color);
    background: var(--dark-body);
}

.dark-theme .select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: var(--dark-body);
}

.dark-theme .select2-container--default .select2-selection--single {
    background-color: var(--dark-theme);
    border-color: var(--dark-border) !important;
}

.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone {
    border-color: var(--dark-border);
    background-color: var(--dark-body);
}

.dark-theme .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--dark-body) !important;
}

.dark-theme .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: var(--dark-theme);
}

.dark-theme .wizard>.steps a .number,
.dark-theme .wizard>.steps a:hover .number,
.dark-theme .wizard>.steps a:active .number {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .pricing-tabs .nav-price li .active {
    background: var(--dark-body);
}

.dark-theme .table-hover tbody tr:hover {
    background-color: var(--primary01);
}

.dark-theme .breadcrumb {
    background-color: var(--dark-body);
}

.dark-theme .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--dark-body);
    border-color: var(--dark-border);
    color: var(--dark-color);
}

.dark-theme .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: var(--dark-color);
}

.dark-theme .main-sidebar-body li.active li .sidemenu-label,
.dark-theme .main-sidebar-body li.active li i {
    color: rgba(255, 255, 255, 0.4) !important;
}

.dark-theme .project .deadline,
.dark-theme .forth.circle .chart-circle-value.circle-style {
    background: var(--dark-body);
}

.dark-theme .alert-message {
    border-color: var(--dark-border);
    background: var(--dark-body);
}

@media (max-width: 991.98px) {
    .dark-theme .main-content-left-show .main-content-left {
        background-color: var(--dark-theme);
        border-right-color: var(--dark-border);
        border-left-color: var(--dark-border);
    }
}

.dark-theme .main-content-label,
.dark-theme .card-table-two .card-title,
.dark-theme .card-dashboard-eight .card-title,
.dark-theme .main-content-label-sm,
.dark-theme .main-content-text {
    color: var(--dark-color);
}

.dark-theme .main-content-choose-demo {
    color: var(--dark-body);
}

.dark-theme .main-content-choose-demo .card.coming-soon {
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme .main-content-choose-demo .main-content-label,
.dark-theme .main-content-choose-demo .card-table-two .card-title {
    color: var(--dark-color);
}

.dark-theme .card-table-two .main-content-choose-demo .card-title,
.dark-theme .main-content-choose-demo .card-dashboard-eight .card-title,
.dark-theme .card-dashboard-eight .main-content-choose-demo .card-title {
    color: var(--dark-color);
}

@media (max-width: 991.98px) {
    .dark-theme .main-header-menu-show .main-header-menu {
        border-right-color: var(--dark-border);
        border-left-color: var(--dark-border);
    }
}

.dark-theme .main-header {
    background-color: var(--dark-theme);
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-header-menu-icon span {
    background-color: var(--dark-color);
}

.dark-theme .main-header-menu-icon span::before,
.dark-theme .main-header-menu-icon span::after {
    background-color: var(--dark-color);
}

.dark-theme .main-header-menu {
    background-color: var(--dark-theme);
}

.dark-theme .main-header-menu .nav-item::before {
    border-top-color: var(--dark-border);
    border-left-color: var(--dark-border);
    border-right-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .main-header-menu .nav-item>.nav-link {
    color: var(--dark-color);
}

.dark-theme .main-header-menu-header {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-header-center .search-panel .dropdown-toggle {
    background: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .main-header-center .search-panel .btn:hover .dropdown-toggle {
    color: var(--dark-color);
}

.dark-theme .main-header-center .form-control:focus {
    border-color: var(--dark-border) !important;
}

.dark-theme .main-header-center .sp-container .form-control:focus+button {
    color: var(--dark-color);
}

.dark-theme .sp-container .main-header-center .form-control:focus+button,
.dark-theme .sp-container .main-header-center button:hover i {
    color: var(--dark-color);
}

.dark-theme .main-header-center .btn:focus i,
.dark-theme .main-header-center .sp-container button:focus i {
    color: var(--dark-color);
}

.dark-theme .sp-container .main-header-center button:focus i {
    color: var(--dark-color);
}

.dark-theme .main-header-right .btn-social {
    color: var(--dark-color);
}

.dark-theme .main-header-right .btn-social:hover,
.dark-theme .main-header-right .btn-social:focus {
    color: var(--dark-color);
}

.dark-theme .main-header-search-link {
    color: var(--dark-color);
}

.dark-theme .main-header-arrow {
    color: var(--dark-color);
}

.dark-theme .main-header-arrow:hover,
.dark-theme .main-header-arrow:focus {
    color: var(--dark-color);
}

.dark-theme .main-header-message>a,
.dark-theme .main-header-notification>a {
    color: var(--dark-color);
}

.dark-theme .main-header-message .dropdown-menu {
    border-color: var(--dark-border);
}

.dark-theme .main-header-notification .dropdown-footer {
    border-top-color: var(--dark-border);
}

.dark-theme .main-header-profile h6 {
    color: var(--dark-color);
}

.dark-theme .main-menu-sub .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-menu-sub .nav-link+.nav-link {
    border-top-color: var(--dark-border);
}

@media (min-width: 992px) {
    .dark-theme .main-menu-sub {
        background-color: var(--dark-theme);
        border-color: var(--dark-border);
    }
}

.dark-theme .main-menu-sub-mega .nav+.nav {
    border-top-color: var(--dark-border);
}

.dark-theme .main-menu-sub-mega .mega-subtitle {
    color: var(--dark-color);
}

@media (min-width: 992px) {
    .dark-theme .main-menu-sub-mega .container {
        background-color: var(--dark-theme);
        border-color: var(--dark-border);
    }
}

@media (min-width: 992px) {
    .dark-theme .main-menu-sub-mega .container>div+div {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }
}

.dark-theme .main-header-right .nav-link.icon {
    color: var(--dark-color);
}

.dark-theme .main-header-right .nav-link.icon:hover {
    background: var(--dark-body);
}

.dark-theme .main-header-notification.show .dropdown-menu::before,
.dark-theme .main-header-message.show .dropdown-menu::before,
.dark-theme .main-profile-menu.show .dropdown-menu::before {
    background: var(--dark-theme);
    border-top-color: var(--dark-border);
    border-left-color: var(--dark-border);
    border-right-color: var(--dark-border);
}

.dark-theme .header-search.show .dropdown-menu::before {
    background: var(--dark-theme);
    border-top-color: var(--dark-border);
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .header-search .dropdown-menu {
    border-color: var(--dark-border);
}

.dark-theme .main-form-search .form-control {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .main-form-search .form-control:focus {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .main-form-search .form-control:focus+.btn {
    color: var(--dark-color);
}

.dark-theme .main-form-search .sp-container .form-control:focus+button {
    color: var(--dark-color);
}

.dark-theme .sp-container .main-form-search .form-control:focus+button {
    color: var(--dark-color);
}

.dark-theme .main-form-search .btn,
.dark-theme .main-form-search .sp-container button {
    color: var(--dark-color);
}

.dark-theme .sp-container .main-form-search button {
    color: var(--dark-color);
}

.dark-theme .main-form-search .btn:hover,
.dark-theme .main-form-search .sp-container button:hover {
    color: var(--dark-color);
}

.dark-theme .sp-container .main-form-search button:hover {
    color: var(--dark-color);
}

.dark-theme .main-form-search .btn:focus,
.dark-theme .main-form-search .sp-container button:focus {
    color: var(--dark-color);
}

.dark-theme .sp-container .main-form-search button:focus {
    color: var(--dark-color);
}

.dark-theme .main-header-message .dropdown-footer {
    border-top-color: var(--dark-border);
}

.dark-theme .main-header-flags .dropdown-menu {
    border-color: var(--dark-border);
}

.dark-theme .main-header-flags.show .dropdown-menu::before {
    background: var(--dark-theme);
    border-top-color: var(--dark-border);
    border-left-color: var(--dark-border);
    border-right-color: var(--dark-border);
}

.dark-theme .main-header-flags .dropdown-footer {
    border-top-color: var(--dark-border);
}

.dark-theme .main-footer {
    background-color: var(--dark-theme);
}

.dark-theme .main-footer-demo {
    color: var(--dark-border);
}

.dark-theme .main-footer-demo a {
    color: var(--dark-body);
}

.dark-theme .sidebar {
    background-color: var(--dark-theme);
}

.dark-theme .sidebar-body h5 {
    border-bottom-color: var(--dark-border);
    background: var(--dark-body);
}

.dark-theme .main-profile-menu .dropdown-item {
    color: var(--dark-color);
}

.dark-theme .main-profile-menu .dropdown-item:hover,
.dark-theme .main-profile-menu .dropdown-item:focus {
    background-color: var(--dark-body);
}

.dark-theme .main-profile-menu .dropdown-item+.dropdown-item {
    border-top-color: var(--dark-border);
}

.dark-theme .main-profile-menu .border-top {
    border-top-color: var(--dark-border) !important;
}

.dark-theme .main-iconbar {
    background-color: var(--dark-theme);
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
    border-top-color: var(--dark-border);
}

.dark-theme .main-iconbar .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-iconbar .nav-link:hover,
.dark-theme .main-iconbar .nav-link:focus {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-logo {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-logo:hover,
.dark-theme .main-iconbar-logo:focus {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-help {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-help:hover,
.dark-theme .main-iconbar-help:focus {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-aside {
    background-color: var(--dark-theme);
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
    border-top-color: var(--dark-border);
}

.dark-theme .main-iconbar-toggle-menu {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-toggle-menu:hover,
.dark-theme .main-iconbar-toggle-menu:focus {
    color: var(--dark-color);
}

@media (min-width: 1200px) {
    .dark-theme .main-iconbar-toggle-menu::before {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }
}

.dark-theme .main-iconbar-body .nav-item+.nav-item {
    border-top-color: var(--dark-border);
}

.dark-theme .main-iconbar-body .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-body .nav-link i,
.dark-theme .main-iconbar-body .nav-link.with-sub::after {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-body .nav-sub {
    border-top-color: var(--dark-border);
}

.dark-theme .main-iconbar-body .nav-sub .nav-sub-item+.nav-sub-item {
    border-top-color: var(--dark-border);
}

.dark-theme .main-iconbar-body .nav-sub .nav-sub-link {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-title,
.dark-theme .main-iconbar-text {
    color: var(--dark-color);
}

.dark-theme .main-iconbar-primary .nav-link {
    color: var(--dark-body);
}

.dark-theme .main-iconbar-primary .nav-link:hover,
.dark-theme .main-iconbar-primary .nav-link:focus {
    background-color: var(--dark-theme);
}

.dark-theme .main-iconbar-primary .nav-link.active {
    background-color: var(--dark-body);
}

.dark-theme .main-iconbar-primary .main-iconbar-help {
    color: var(--dark-body);
}

.dark-theme .main-iconbar-aside-primary {
    background-color: var(--dark-theme);
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
}

.dark-theme .main-iconbar-aside-primary .main-iconbar-header {
    background-color: var(--dark-theme);
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-iconbar-aside-primary .main-iconbar-body .nav-item+.nav-item,
.dark-theme .main-iconbar-aside-primary .main-iconbar-body .nav-sub,
.dark-theme .main-iconbar-aside-primary .main-iconbar-body .nav-sub-item+.nav-sub-item {
    border-top-color: var(--dark-border);
}

.dark-theme .main-icon-group {
    color: var(--dark-color);
}

.dark-theme .icons-list-item {
    background: var(--dark-body);
    border-color: var(--dark-border);
}

@media (min-width: 992px) {
    .dark-theme.horizontalmenu .hor-menu .nav-link .hor-icon {
        color: rgba(255, 255, 255, 0.6);
        fill: rgba(255, 255, 255, 0.6);
    }

    .dark-theme.horizontalmenu .menu-nav .nav-item ul.nav-sub::before {
        border-top-color: var(--dark-border);
        border-left-color: var(--dark-border);
        background-color: var(--dark-theme);
    }

    .dark-theme .hor-menu .slide-left,
    .dark-theme .hor-menu .slide-right {
        color: var(--dark-color);
        border-color: var(--dark-border);
        background-color: var(--dark-theme);
    }

    .dark-theme .main-navbar .sub-nav-sub {
        background-color: var(--dark-theme);
        border-color: var(--dark-border);
    }

    .dark-theme .main-navbar .nav-sub-link.sub-with-sub::after {
        color: rgba(255, 255, 255, 0.4);
    }
}

@media (max-width: 991.98px) {
    .dark-theme.horizontalmenu .main-body-1 li.nav-item.active .nav-link {
        background-color: var(--dark-body);
        color: var(--dark-color);
    }

    .dark-theme.horizontalmenu .main-body-1 .nav-item.active .nav-link:before,
    .dark-theme.horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
        border-right-color: var(--dark-theme);
        border-left-color: var(--dark-theme);
    }

    .dark-theme.horizontalmenu .main-body-1 .nav-item.active .nav-link .shape1,
    .dark-theme.horizontalmenu .main-body-1 .nav-item.active .nav-link .shape2 {
        background: var(--dark-body);
    }

    .dark-theme.horizontalmenu .main-body-1 .nav-sub .nav-sub-link:before {
        color: var(--dark-color);
    }
}

.dark-theme .sidemenu-logo {
    border-bottom-color: var(--dark-border);
}

@media (max-width: 991.98px) {
    .dark-theme .main-sidebar {
        background-color: var(--dark-theme);
    }
}

.dark-theme .main-sidebar-body li.nav-item.active .nav-link {
    background-color: var(--dark-body);
}

.dark-theme .main-sidebar-body .nav-item.active .nav-link:before,
.dark-theme .main-sidebar-body .nav-item.active .nav-link:after {
    border-right-color: var(--dark-theme);
    border-left-color: var(--dark-theme);
}

.dark-theme .main-sidebar-body .nav-item.active .nav-link .shape1,
.dark-theme .main-sidebar-body .nav-item.active .nav-link .shape2 {
    background: var(--dark-body);
}

.dark-theme .main-sidebar-loggedin .media-body h6,
.dark-theme .main-sidebar-loggedin .media-body span {
    color: var(--dark-color);
}

.dark-theme .main-sidebar-body .nav-label,
.dark-theme .second-sidemenu .nav-label,
.dark-theme .main-sidebar-body .sub-txt,
.dark-theme .second-sidemenu .sub-txt {
    color: rgba(255, 255, 255, 0.3);
}

.dark-theme .main-sidebar-body .nav-link {
    color: rgba(255, 255, 255, 0.4);
}

.dark-theme .main-sidebar-body .nav-sub-link {
    color: rgba(255, 255, 255, 0.4);
}

.dark-theme .main-sidebar-body .nav-sub-link.with-sub::after {
    color: var(--dark-color);
}

.dark-theme .main-sidebar-body .nav-sub-link+.nav-sub-link {
    border-top-color: var(--dark-border);
}

@media (min-width: 992px) {
    .dark-theme.main-body .side-header {
        border-bottom-color: var(--dark-border);
    }

    .dark-theme.main-sidebar-hide .sidemenu-logo,
    .dark-theme.main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        border-bottom-color: var(--dark-border);
    }
}

@media (min-width: 992px) {

    .dark-theme.main-sidebar-open .main-sidebar-body .sub-txt,
    .dark-theme.main-sidebar-open .main-sidebar-body .nav-label {
        color: rgba(255, 255, 255, 0.3);
    }
}

@media (min-width: 992px) {

    .dark-theme.main-body.leftmenu .sidemenu-logo,
    .dark-theme.main-sidebar-hide .sidemenu-logo {
        background: var(--dark-theme);
    }
}

@media screen and (min-width: 992px) {
    .dark-theme.icontext-menu.main-sidebar-hide.main-sidebar-open li.nav-item.active .nav-link {
        background: var(--dark-body) !important;
    }
}

@media screen and (min-width: 992px) {
    .dark-theme.hover-submenu.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--dark-theme);
    }

    .dark-theme.hover-submenu.main-sidebar-hide .nav-sub {
        background: var(--dark-theme);
    }

    .dark-theme.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        background: var(--dark-theme);
    }

    .dark-theme.hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--dark-theme);
    }
}

@media screen and (min-width: 992px) {
    .dark-theme.hover-submenu1.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--dark-theme);
    }

    .dark-theme.hover-submenu1.main-sidebar-hide .nav-sub {
        background: var(--dark-theme);
    }

    .dark-theme.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu-label1 {
        border-bottom-color: var(--dark-border);
    }

    .dark-theme.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        background: var(--dark-theme);
    }

    .dark-theme.hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--dark-theme);
    }
}

.dark-theme .ui-datepicker {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .ui-datepicker .ui-datepicker-header {
    color: var(--dark-color);
}

.dark-theme .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.dark-theme .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
    color: var(--dark-color);
    background: var(--dark-theme);
}

.dark-theme .ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before,
.dark-theme .ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before {
    color: var(--dark-color);
}

.dark-theme .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
.dark-theme .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
    color: var(--dark-color);
}

.dark-theme .ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.dark-theme .ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
    color: var(--dark-color);
}

.dark-theme .ui-datepicker .ui-datepicker-title {
    color: var(--dark-color);
}

.dark-theme .ui-datepicker .ui-datepicker-calendar th {
    color: var(--dark-color);
    border-color: var(--dark-border);
}

.dark-theme .ui-datepicker .ui-datepicker-calendar td {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default,
.dark-theme .ui-datepicker .ui-datepicker-calendar td span {
    color: var(--dark-color);
}

.dark-theme .ui-datepicker .ui-datepicker-calendar td a {
    color: var(--dark-color);
}

.dark-theme .ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .ui-datepicker-inline {
    border-color: var(--dark-border);
}

.dark-theme .datetimepicker {
    border-color: var(--dark-border);
}

.dark-theme .datetimepicker table th.prev,
.dark-theme .datetimepicker table th.next,
.dark-theme .datetimepicker table th.switch {
    background-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .datetimepicker table th.prev span::before,
.dark-theme .datetimepicker table th.next span::before {
    color: var(--dark-color);
}

.dark-theme .datetimepicker table th.prev:hover,
.dark-theme .datetimepicker table th.prev:focus {
    background-color: var(--dark-theme);
}

.dark-theme .datetimepicker table th.next:hover,
.dark-theme .datetimepicker table th.next:focus {
    background-color: var(--dark-theme);
}

.dark-theme .datetimepicker table th.switch {
    color: var(--dark-color);
}

.dark-theme .datetimepicker table td.old,
.dark-theme .datetimepicker table td.new {
    color: var(--dark-color);
}

.dark-theme .datetimepicker .datetimepicker-days table thead tr:last-child th {
    color: var(--dark-color);
}

.dark-theme .main-datetimepicker {
    border-color: var(--dark-border);
}

.dark-theme .main-datetimepicker>.datepicker_inner_container {
    background-color: var(--dark-theme);
}

.dark-theme .main-datetimepicker>.datepicker_inner_container>.datepicker_calendar th {
    color: var(--dark-color);
}

.dark-theme .main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.day_another_month {
    color: var(--dark-color);
}

.dark-theme .main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.hover {
    background-color: var(--dark-theme);
}

.dark-theme .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist {
    border-left-color: var(--dark-border);
    border-right-color: var(--dark-border);
}

.dark-theme .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item:hover,
.dark-theme .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item:focus {
    background-color: var(--dark-theme);
}

.dark-theme .jqvmap-label {
    background-color: var(--dark-theme);
}

.dark-theme .mapael .zoomButton {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .mapael .mapTooltip {
    background-color: var(--dark-theme);
}

.dark-theme .ql-wrapper-demo {
    background-color: var(--dark-theme);
}

.dark-theme .ql-scrolling-demo {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .ql-scrolling-demo .ql-container .ql-editor {
    color: var(--dark-color);
}

.dark-theme .ql-bubble.ql-container:not(.ql-disabled) a::before {
    background-color: var(--dark-theme);
}

.dark-theme .ql-bubble .ql-tooltip {
    background-color: var(--dark-theme);
}

.dark-theme .ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
    border-top-color: var(--dark-border);
}

.dark-theme .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
    border-bottom-color: var(--dark-border);
}

.dark-theme .ql-bubble .ql-toolbar button {
    color: var(--dark-color);
}

.dark-theme .ql-bubble .ql-toolbar button .ql-fill {
    fill: var(--dark-color);
}

.dark-theme .ql-bubble .ql-toolbar button .ql-stroke {
    stroke: var(--dark-color);
}

.dark-theme .ql-bubble .ql-toolbar button.ql-active {
    background-color: var(--dark-theme);
}

.dark-theme .ql-snow.ql-container {
    border-color: var(--dark-border);
}

.dark-theme .ql-snow.ql-toolbar {
    border-color: var(--dark-border);
}

.dark-theme .ql-snow.ql-toolbar .ql-picker-label {
    border-color: var(--dark-border);
}

.dark-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active {
    background-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
    stroke: var(--dark-color);
}

.dark-theme .ql-snow.ql-toolbar button {
    border-color: var(--dark-border);
    color: var(--dark-color);
}

.dark-theme .ql-snow.ql-toolbar button:hover,
.dark-theme .ql-snow.ql-toolbar button:focus {
    border-color: var(--dark-border);
}

.dark-theme .ql-snow.ql-toolbar button:hover .ql-stroke,
.dark-theme .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: var(--dark-color);
}

.dark-theme .ql-snow.ql-toolbar button.ql-active {
    background-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .ql-snow.ql-toolbar button.ql-active .ql-fill {
    fill: var(--dark-color);
}

.dark-theme .ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: var(--dark-color);
}

.dark-theme .ql-snow .ql-picker {
    color: var(--dark-color);
}

.dark-theme .ql-snow .ql-picker:hover .ql-picker-label,
.dark-theme .ql-snow .ql-picker:focus .ql-picker-label {
    color: var(--dark-color);
}

.dark-theme .ql-snow .ql-picker:hover .ql-picker-label .ql-stroke,
.dark-theme .ql-snow .ql-picker:focus .ql-picker-label .ql-stroke {
    stroke: var(--dark-color);
}

.dark-theme .ql-snow .ql-picker-label {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .ql-snow .ql-stroke {
    stroke: var(--dark-color);
}

.dark-theme .ql-snow .ql-editor {
    color: var(--dark-color);
}

.dark-theme .irs-line-left,
.dark-theme .irs-line-mid,
.dark-theme .irs-line-right {
    background-color: var(--dark-theme);
}

.dark-theme .irs-dark .irs-bar,
.dark-theme .irs-dark .irs-bar-edge,
.dark-theme .irs-dark .irs-slider::before,
.dark-theme .irs-dark .irs-from,
.dark-theme .irs-dark .irs-to,
.dark-theme .irs-dark .irs-single {
    background-color: var(--dark-color);
}

.dark-theme .irs-dark .irs-from::after,
.dark-theme .irs-dark .irs-to::after,
.dark-theme .irs-dark .irs-single::after {
    border-top-color: var(--dark-border);
}

.dark-theme .irs-modern .irs-slider {
    background-color: var(--dark-theme);
}

.dark-theme .irs-modern .irs-slider::before {
    background-color: var(--dark-theme);
}

.dark-theme .irs-modern.irs-dark .irs-slider {
    border-color: var(--dark-border);
}

.dark-theme .irs-modern.irs-dark .irs-slider::before {
    border-color: var(--dark-border);
}

.dark-theme .irs-outline .irs-slider {
    background-color: var(--dark-theme);
}

.dark-theme .irs-outline.irs-dark .irs-line,
.dark-theme .irs-outline.irs-dark .irs-slider {
    border-color: var(--dark-border);
}

.dark-theme .irs-outline.irs-dark .irs-line:hover::before,
.dark-theme .irs-outline.irs-dark .irs-line:focus::before {
    background-color: var(--dark-body);
}

.dark-theme .irs-outline.irs-dark .irs-slider:hover::before,
.dark-theme .irs-outline.irs-dark .irs-slider:focus::before {
    background-color: var(--dark-body);
}

.dark-theme .select2-container--default .select2-selection--single {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .select2-container--default .select2-selection--single .select2-selection__rendered,
.dark-theme .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: var(--dark-color);
}

.dark-theme .select2-container--default .select2-selection--multiple {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .select2-container--default.select2-container--focus .select2-selection--multiple,
.dark-theme .select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: var(--dark-border);
}

.dark-theme .select2-container--default .select2-results__option[aria-selected=true],
.dark-theme .select2-container--default.select2-container--disabled .select2-selection__choice {
    background-color: var(--dark-theme);
}

.dark-theme .select2-container--open .select2-selection--single,
.dark-theme .select2-container--open .select2-selection--multiple {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .select2-dropdown {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .bg-gray+.select2-container--default .select2-selection--single {
    background-color: var(--dark-theme);
}

.dark-theme .bg-gray+.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: var(--dark-color);
}

.dark-theme .select2-dropdown::-webkit-scrollbar-thumb {
    background: var(--dark-border);
}

.dark-theme .select2-dropdown *:hover::-webkit-scrollbar-thumb {
    background: var(--dark-theme);
}

.dark-theme .sp-container {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .sp-container button {
    background-color: var(--dark-theme);
}

.dark-theme .sp-container button:hover,
.dark-theme .sp-container button:focus {
    background-color: var(--dark-theme);
}

.dark-theme .sp-button-container .sp-cancel {
    color: var(--dark-color) !important;
}

.dark-theme .sp-button-container .sp-cancel:hover,
.dark-theme .sp-button-container .sp-cancel:focus {
    color: var(--dark-color) !important;
}

.dark-theme .sp-replacer {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .sp-replacer:hover,
.dark-theme .sp-replacer:focus,
.dark-theme .sp-replacer.sp-active {
    border-color: var(--dark-border);
}

.dark-theme .sp-dd::before {
    color: var(--dark-color);
}

.dark-theme .sp-choose {
    background-color: var(--dark-theme);
}

.dark-theme .sp-palette .sp-thumb-el:hover,
.dark-theme .sp-palette .sp-thumb-el.sp-thumb-active {
    border-color: var(--dark-border);
}

.dark-theme .main-datepicker .ui-datepicker .ui-datepicker-calendar th {
    color: var(--dark-color);
}

.dark-theme .main-datepicker .ui-datepicker .ui-datepicker-calendar td span,
.dark-theme .main-datepicker .ui-datepicker .ui-datepicker-calendar td a {
    color: var(--dark-color);
}

.dark-theme .main-nav-calendar-event .nav-link.exclude {
    color: var(--dark-color);
}

.dark-theme .main-nav-calendar-event .nav-link.exclude i {
    color: var(--dark-color);
}

.dark-theme .main-nav-calendar-event .nav-link.exclude::before {
    border-top-color: var(--dark-border);
}

.dark-theme .main-calendar {
    border-color: var(--dark-border);
}

.dark-theme .main-calendar .fc-content,
.dark-theme .main-calendar .fc-divider,
.dark-theme .main-calendar .fc-list-heading td,
.dark-theme .main-calendar .fc-list-view,
.dark-theme .main-calendar .fc-popover,
.dark-theme .main-calendar .fc-row,
.dark-theme .main-calendar tbody,
.dark-theme .main-calendar td,
.dark-theme .main-calendar th,
.dark-theme .main-calendar thead {
    border-color: var(--dark-border);
}

.dark-theme .main-calendar .fc-header-toolbar h2 {
    color: var(--dark-color);
}

.dark-theme .main-calendar .fc-header-toolbar button {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
    color: var(--dark-color);
}

.dark-theme .main-calendar .fc-header-toolbar button:hover,
.dark-theme .main-calendar .fc-header-toolbar button:focus,
.dark-theme .main-calendar .fc-header-toolbar button.fc-state-active {
    background-color: var(--dark-theme);
}

.dark-theme .main-calendar .fc-header-toolbar button.fc-today-button.fc-state-disabled {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .main-calendar .fc-head-container .fc-day-header {
    color: var(--dark-color);
}

.dark-theme .main-calendar .fc-view>table {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .main-calendar .fc-view>table>thead th,
.dark-theme .main-calendar .fc-view>table>thead td {
    border-color: var(--dark-border);
}

.dark-theme .main-calendar .fc-view>table>tbody>tr>td {
    border-color: var(--dark-border);
}

.dark-theme .main-calendar .fc-view .fc-other-month {
    background-color: var(--dark-body);
}

.dark-theme .main-calendar .fc-view .fc-day-number {
    color: var(--dark-color);
}

.dark-theme .main-calendar .fc-view .fc-day-number:hover,
.dark-theme .main-calendar .fc-view .fc-day-number:focus {
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme .main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:first-child,
.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main,
.dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main,
.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main span:last-child,
.dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main span:last-child {
    color: var(--dark-color);
}

.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
.dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
    background-color: var(--dark-theme);
}

.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
.dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
    border-top-color: var(--dark-border);
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
}

.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
.dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
    border-bottom-color: var(--dark-border);
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
}

.dark-theme .main-calendar .fc-view.fc-listMonth-view .fc-list-item-title a,
.dark-theme .main-calendar .fc-view.fc-listWeek-view .fc-list-item-title a {
    color: var(--dark-color);
}

.dark-theme .main-calendar td.fc-today {
    background-color: var(--dark-theme);
}

.dark-theme .main-calendar .fc-time-grid-event .fc-title,
.dark-theme .main-calendar .fc-time-grid-event .fc-desc {
    color: var(--dark-color);
}

.dark-theme .main-calendar .fc-list-empty {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

@media (max-width: 575px) {

    .dark-theme .main-calendar .fc-header-toolbar button.fc-month-button::before,
    .dark-theme .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
    .dark-theme .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
    .dark-theme .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .dark-theme .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
        color: var(--dark-color);
    }
}

.dark-theme .main-modal-calendar-schedule .modal-content>.close {
    color: var(--dark-color);
}

.dark-theme .main-modal-calendar-schedule .form-group-date {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .main-modal-calendar-event .nav-modal-event .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-modal-calendar-event .modal-body {
    background-color: var(--dark-theme);
}

.dark-theme .main-modal-calendar-event .event-start-date,
.dark-theme .main-modal-calendar-event .event-end-date {
    color: var(--dark-color);
}

.dark-theme .main-nav-line-chat {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-nav-line-chat .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-chat-contacts-wrapper {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-chat-list .media+.media {
    border-top-color: var(--dark-border);
}

.dark-theme .main-chat-list .media.new {
    background-color: var(--dark-theme);
}

.dark-theme .main-chat-list .media.new .main-img-user span {
    display: flex;
}

.dark-theme .main-chat-list .media.new .media-contact-name span:first-child,
.dark-theme .main-chat-list .media.new .media-body p {
    color: var(--dark-color);
}

.dark-theme .main-chat-list .media:hover,
.dark-theme .main-chat-list .media:focus {
    background-color: var(--dark-body);
    border-top-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-chat-list .media.selected {
    background-color: var(--dark-theme);
    border-top-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-chat-list .media.selected .media-contact-name span:first-child,
.dark-theme .main-chat-list .media.selected .media-body p {
    color: var(--dark-color);
}

.dark-theme .main-chat-list .media-body p {
    color: var(--dark-color);
}

.dark-theme .main-chat-list .media-contact-name span:first-child,
.dark-theme .main-chat-list .media-contact-name span:last-child {
    color: var(--dark-color);
}

.dark-theme .main-chat-header {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-chat-header .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-chat-msg-name h6,
.dark-theme .main-chat-msg-name small {
    color: var(--dark-color);
}

.dark-theme .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .main-chat-body .media.flex-row-reverse .main-msg-wrapper:after {
    border-left-color: var(--dark-body) !important;
    border-right-color: transparent !important;
}

.dark-theme .main-chat-body .media.flex-row-reverse .main-msg-wrapper:before {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}

.dark-theme.rtl .main-chat-body .media.flex-row-reverse .main-msg-wrapper:after {
    border-right-color: var(--dark-body) !important;
    border-left-color: transparent !important;
}

.dark-theme.rtl .main-chat-body .media.flex-row-reverse .main-msg-wrapper:before {
    border-right-color: transparent !important;
    border-left-color: transparent !important;
}

.dark-theme .main-msg-wrapper {
    background-color: var(--dark-body);
}

.dark-theme .main-msg-wrapper:before,
.dark-theme .main-msg-wrapper:after {
    border-color: transparent var(--dark-body) transparent transparent;
}

.dark-theme .main-chat-body .media-body>div:last-child {
    color: var(--dark-color);
}

.dark-theme .main-chat-time span {
    background: var(--dark-body);
}

.dark-theme .main-chat-footer {
    border-top-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .main-chat-footer .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-chat-body .media.flex-row-reverse .main-msg-wrapper2 {
    background-color: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .main-msg-wrapper2 {
    background-color: var(--dark-body);
}

.dark-theme .main-contact-label {
    color: var(--dark-color);
}

.dark-theme .main-contact-label::after {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-contact-item+.main-contact-item {
    border-top-color: var(--dark-border);
}

.dark-theme .main-contact-item:hover,
.dark-theme .main-contact-item:focus {
    background-color: var(--dark-theme);
    border-top-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-contact-item.selected {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
    border-top-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
    background-color: var(--dark-body);
}

.dark-theme .main-contact-body h6,
.dark-theme .main-contact-body span {
    color: var(--dark-color);
}

.dark-theme .main-contact-star {
    color: var(--dark-color);
}

.dark-theme .main-contact-star:hover,
.dark-theme .main-contact-star:focus {
    color: var(--dark-color);
}

.dark-theme .main-contact-info-header {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-contact-info-header .main-img-user a {
    background-color: var(--dark-body);
    color: var(--dark-color);
    box-shadow: 0 0 0 2px var(--dark-color);
}

.dark-theme .main-contact-info-header .media-body h4,
.dark-theme .main-contact-info-header .media-body p,
.dark-theme .main-contact-info-header .media-body .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-contact-action a {
    color: var(--dark-color);
}

.dark-theme .main-contact-info-body .media-icon {
    color: var(--dark-color);
}

.dark-theme .main-contact-info-body .media-body label,
.dark-theme .main-contact-info-body .media-body span {
    color: var(--dark-color);
}

.dark-theme .main-card-signin {
    background-color: var(--dark-body);
}

@media (min-width: 480px) {
    .dark-theme .main-card-signin {
        border-color: var(--dark-border);
    }
}

.dark-theme .main-signin-footer p,
.dark-theme .main-signin-footer a {
    color: var(--dark-color);
}

.dark-theme .main-signup-wrapper {
    background-color: var(--dark-body);
}

.dark-theme .main-column-signup-left h5 {
    color: var(--dark-color);
}

.dark-theme .main-column-signup {
    background-color: var(--dark-theme);
}

@media (min-width: 992px) {
    .dark-theme .main-column-signup {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }
}

.dark-theme .main-signup-header h4,
.dark-theme .main-signup-header label {
    color: var(--dark-color);
}

.dark-theme .main-signup-header .form-control {
    color: var(--dark-color);
    border-color: var(--dark-border);
}

.dark-theme .main-signup-header .form-control:focus {
    border-color: var(--dark-border);
}

.dark-theme .main-signup-header .form-control::placeholder {
    color: var(--dark-color);
}

.dark-theme .main-signup-footer p,
.dark-theme .main-signup-footer a {
    color: var(--dark-color);
}

.dark-theme .main-error-wrapper h1,
.dark-theme .main-error-wrapper h2 {
    color: var(--dark-color);
}

.dark-theme .signpages .login_form {
    background-color: var(--dark-theme);
}

.dark-theme .signpages .form__input {
    border-bottom-color: var(--dark-border);
}

.dark-theme .error-1 .nav-link.icon {
    color: var(--dark-color);
}

@media (min-width: 992px) {
    .dark-theme .main-content-left-invoice {
        border-right-color: var(--dark-border);
        border-left-color: var(--dark-border);
    }
}

.dark-theme .main-invoice-list {
    border-top-color: var(--dark-border);
}

.dark-theme .main-invoice-list .media:hover,
.dark-theme .main-invoice-list .media:focus {
    background-color: var(--dark-theme);
}

.dark-theme .main-invoice-list .media+.media::before {
    border-top-color: var(--dark-border);
}

.dark-theme .main-invoice-list .media-icon {
    color: var(--dark-color);
}

.dark-theme .main-invoice-list .media-body h6 {
    color: var(--dark-color);
}

.dark-theme .main-invoice-list .media-body p {
    color: var(--dark-color);
}

.dark-theme .main-invoice-list .media-body p span {
    color: var(--dark-color);
}

.dark-theme .main-invoice-list .selected {
    background-color: var(--dark-theme);
    border-top-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
}

.dark-theme .invoice-title,
.dark-theme .billed-from h6,
.dark-theme .billed-to h6 {
    color: var(--dark-color);
}

.dark-theme .invoice-info-row+.invoice-info-row {
    border-top-color: var(--dark-border);
}

.dark-theme .invoice-info-row span:first-child {
    color: var(--dark-color);
}

.dark-theme .table-invoice tbody>tr>th:first-child,
.dark-theme .table-invoice tbody>tr>td:first-child,
.dark-theme .table-invoice tbody>tr>th:first-child .invoice-notes p,
.dark-theme .table-invoice tbody>tr>td:first-child .invoice-notes p {
    color: var(--dark-color);
}

.dark-theme .main-mail-header .btn-group .btn,
.dark-theme .main-mail-header .btn-group .sp-container button {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .sp-container .main-mail-header .btn-group button {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .main-mail-header .btn-group .btn:hover,
.dark-theme .main-mail-header .btn-group .sp-container button:hover {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .sp-container .main-mail-header .btn-group button:hover {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .main-mail-header .btn-group .btn:focus,
.dark-theme .main-mail-header .btn-group .sp-container button:focus {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .sp-container .main-mail-header .btn-group button:focus {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .main-mail-header .btn-group .btn.disabled,
.dark-theme .main-mail-header .btn-group .sp-container button.disabled {
    background-color: var(--dark-theme);
    color: var(--dark-color);
    border-color: var(--dark-border);
}

.dark-theme .sp-container .main-mail-header .btn-group button.disabled {
    background-color: var(--dark-theme);
    color: var(--dark-color);
    border-color: var(--dark-border);
}

.dark-theme .main-mail-options {
    border-color: var(--dark-border);
}

.dark-theme .main-mail-options .btn:hover,
.dark-theme .main-mail-options .sp-container button:hover {
    background-color: var(--dark-body);
}

.dark-theme .sp-container .main-mail-options button:hover {
    background-color: var(--dark-body);
}

.dark-theme .main-mail-options .btn:focus,
.dark-theme .main-mail-options .sp-container button:focus {
    background-color: var(--dark-body);
}

.dark-theme .sp-container .main-mail-options button:focus {
    background-color: var(--dark-body);
}

.dark-theme .main-mail-options .btn.disabled,
.dark-theme .main-mail-options .sp-container button.disabled {
    color: var(--dark-color);
}

.dark-theme .sp-container .main-mail-options button.disabled {
    color: var(--dark-color);
}

.dark-theme .main-mail-item {
    border-top-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
    background-color: var(--dark-body);
}

.dark-theme .main-mail-item:hover,
.dark-theme .main-mail-item:focus {
    background-color: var(--dark-body);
}

.dark-theme .main-mail-item.unread,
.dark-theme .main-mail-item.selected {
    background-color: var(--dark-theme);
}

.dark-theme .main-mail-star {
    color: var(--dark-color);
}

.dark-theme .main-mail-subject strong,
.dark-theme .main-mail-subject span {
    color: var(--dark-color);
}

@media (max-width: 991px) {

    .dark-theme .mail-container .tab-list-items.task-menu-show,
    .dark-theme .mail-container .tab-list-items.mail-menu-show {
        background: var(--dark-theme);
    }

    .dark-theme .task-actions li .task-action-items i {
        border-color: var(--dark-border);
    }
}

.dark-theme .main-mail-date {
    color: var(--dark-color);
}

.dark-theme .main-mail-compose {
    background-color: var(--dark-body);
}

.dark-theme .main-mail-compose-header {
    background-color: var(--dark-theme);
}

.dark-theme .main-mail-compose-header .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-mail-compose-body {
    background-color: var(--dark-theme);
}

.dark-theme .main-mail-compose-body .form-group .form-label {
    color: var(--dark-color);
}

.dark-theme .main-mail-compose-body .form-group+.form-group {
    border-top-color: var(--dark-border);
}

.dark-theme .main-mail-compose-body .form-group .nav-link:hover,
.dark-theme .main-mail-compose-body .form-group .nav-link:focus {
    color: var(--dark-color);
}

.dark-theme .main-mail-two .main-header {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-mail-left {
    background-color: var(--dark-body);
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
}

.dark-theme .main-mail-content {
    background-color: var(--dark-body);
}

.dark-theme .main-content-body-show .main-header-mail-arrow {
    background: var(--dark-body);
}

.dark-theme .table-inbox tr td .fa-bookmark {
    color: var(--dark-color);
}

.dark-theme .inbox-pagination a.np-btn {
    border-color: var(--dark-border);
}

.dark-theme .mail-option .chk-all,
.dark-theme .mail-option .btn-group a.btn {
    border-color: var(--dark-border);
}

.dark-theme .table-inbox {
    border-color: var(--dark-border);
}

.dark-theme .table-inbox tr td {
    border-top-color: var(--dark-border);
}

.dark-theme .table-inbox tr td .fa-star {
    color: var(--dark-color);
}

.dark-theme .table-inbox tr td .fa-star.inbox-started,
.dark-theme .table-inbox tr td .fa-star:hover {
    color: var(--dark-color);
}

.dark-theme .mail-option .dropdown-menu>li>a {
    color: var(--dark-color);
}

.dark-theme .mail-option .dropdown-menu>li>a:hover {
    background: var(--dark-theme);
}

.dark-theme .profile-cover__img>img {
    border-color: var(--dark-border);
}

.dark-theme .profile-cover__img>.h3 {
    color: var(--dark-color);
}

@media (min-width: 601px) {
    .dark-theme .profile-cover__info .nav {
        color: var(--dark-color);
    }
}

.dark-theme .profile-cover__info .nav li {
    color: var(--dark-color);
}

.dark-theme .profile-cover__info .nav li:not(:first-child) {
    border-left-color: var(--dark-border);
    border-right-color: var(--dark-border);
}

@media (max-width: 600px) {
    .dark-theme .profile-cover__info .nav {
        color: var(--dark-color);
    }

    .dark-theme .profile-cover__info .nav li:not(:first-child) {
        border-top-color: var(--dark-border);
    }
}

.dark-theme .main-content-left-profile {
    border-bottom-color: var(--dark-border);
}

@media (min-width: 992px) {
    .dark-theme .main-content-left-profile {
        border-right-color: var(--dark-border);
        border-left-color: var(--dark-border);
    }
}

.dark-theme .main-profile-name,
.dark-theme .main-profile-name-text {
    color: var(--dark-color);
}

.dark-theme .main-content-body-profile .nav {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-content-body-profile .main-nav-line .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-profile-view-info h6 {
    color: var(--dark-color);
}

.dark-theme .main-traffic-detail-item>div:first-child>span:first-child {
    color: var(--dark-color);
}

.dark-theme .main-traffic-detail-item>div:first-child>span:last-child {
    color: var(--dark-color);
}

.dark-theme .main-traffic-detail-item>div:first-child>span:last-child span {
    color: var(--dark-color);
}

.dark-theme .main-profile-work-list .media-body h6,
.dark-theme .main-profile-work-list .media-body p {
    color: var(--dark-color);
}

.dark-theme .main-profile-contact-list .media-body span,
.dark-theme .main-profile-contact-list .media-body div {
    color: var(--dark-color);
}

@media (max-width: 767px) {
    .dark-theme .profile-cover__info .nav {
        color: var(--dark-color);
    }
}

.dark-theme .crypto-transcation.list-unstyled li {
    border-color: var(--dark-border);
}

.dark-theme .toolbar .btn {
    background: var(--dark-theme);
    color: var(--dark-color);
    border-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
}

.dark-theme .crypto-card {
    background-color: var(--dark-theme);
}

.dark-theme .card-bitcoin label,
.dark-theme .card-bitcoin p {
    color: var(--dark-color);
}

.dark-theme .crypto-wallet .chart-circle-value.circle-style {
    background: var(--dark-body);
}

.dark-theme .buy-sell .form {
    border-color: var(--dark-border);
    background: var(--dark-theme);
}

.dark-theme .payment-list .payment-item {
    color: var(--dark-color);
}

.dark-theme .payment-list .payment-item i {
    border-color: var(--dark-border);
}

.dark-theme .payment-cards {
    color: var(--dark-color);
    border-color: var(--dark-border);
}

.dark-theme .crypto-buysell-card .nav.panel-tabs li {
    background: var(--dark-theme);
}

.dark-theme .wallet .input-group-addon-right,
.dark-theme .wallet .input-group-addon {
    color: var(--dark-color);
    border-color: var(--dark-border) !important;
}

.dark-theme .wallet .clipboard-icon {
    background: var(--dark-body);
}

.dark-theme .wallet .input-group-addon-left {
    background: var(--dark-body);
    border-color: var(--dark-border) !important;
}

.dark-theme .card-order i {
    background: var(--dark-body);
}

.dark-theme .owl-theme:before {
    background: linear-gradient(to left, transparent 0%, var(--dark-body) 100%);
}

.dark-theme .owl-theme:after {
    background: linear-gradient(to right, transparent 0%, var(--dark-body) 100%);
}

.dark-theme .ratio {
    background-color: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .countrywise-sale .list-group-item {
    border-color: var(--dark-border);
}

.dark-theme .countrywise-sale .list-group-item span {
    color: var(--dark-color);
}

.dark-theme .top-selling-products .price,
.dark-theme .top-selling-products .count,
.dark-theme .top-selling-products .title,
.dark-theme .top-selling-products .amount,
.dark-theme .top-selling-products .items-sold {
    color: var(--dark-color);
}

.dark-theme .ecommerce.wizard {
    background: var(--dark-theme);
}

.dark-theme .ecommerce.wizard .nav-tabs {
    border-bottom-color: var(--dark-border);
}

.dark-theme .connecting-line {
    background: var(--dark-body);
}

@media (min-width: 768px) {
    .dark-theme #checkoutsteps>.steps .number {
        background-color: var(--dark-body);
        color: var(--dark-color);
    }

    .dark-theme #checkoutsteps .checkoutline {
        background-color: var(--dark-theme);
    }
}

@media (max-width: 767px) {
    .dark-theme #checkoutsteps>.steps .number {
        background-color: var(--dark-theme);
        color: var(--dark-color);
    }

    .dark-theme #checkoutsteps .checkoutline {
        background-color: var(--dark-theme);
    }
}

.dark-theme #checkoutsteps>.steps a {
    color: var(--dark-color);
}

.dark-theme #checkoutsteps .item {
    border-bottom-color: var(--dark-border);
}

.dark-theme #checkoutsteps .item .thumb {
    border-color: var(--dark-border);
}

.dark-theme .card-pay .tabs-menu.nav {
    background: var(--dark-body);
}

.dark-theme .card-pay .tabs-menu.nav li {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .item1-links li {
    border-top-color: var(--dark-border);
}

.dark-theme .item1-links a {
    color: var(--dark-color);
}

.dark-theme .track {
    background-color: var(--dark-body);
}

.dark-theme .track .step.active .text {
    color: var(--dark-color);
}

.dark-theme .track .icon {
    background: var(--dark-body);
}

.dark-theme .product-grid .product-like-icon {
    color: var(--dark-color);
    background: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .product-grid .product-like-icon:hover {
    color: var(--dark-color);
    background: var(--dark-theme);
}

.dark-theme .product-grid .product-link a {
    color: var(--dark-color);
    background: var(--dark-body);
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .product-grid .product-link a:hover {
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme .product-grid .product-link a span {
    color: var(--dark-color);
}

.dark-theme .product-grid .title a {
    color: var(--dark-color);
}

.dark-theme .product-grid .price {
    color: var(--dark-color);
}

.dark-theme .product-grid .price .old-price {
    color: var(--dark-color);
}

.dark-theme .product_price .old_price {
    color: var(--dark-color);
}

@media (max-width: 567px) {
    .dark-theme .card-pay .tabs-menu li {
        border-bottom-color: var(--dark-border);
    }
}

.dark-theme #wishlist .product-grid .wishlist-icon {
    border-color: var(--dark-border);
}

.dark-theme .notification .notification-time .date,
.dark-theme .notification .notification-time .time {
    color: var(--dark-color);
}

.dark-theme .notification .notification-icon a {
    background: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .notification .notification-body {
    box-shadow: 0px 8px 16px var(--dark-theme);
    background: var(--dark-theme);
}

.dark-theme .notification .notification-body:before {
    border-right-color: var(--dark-theme);
    border-left-color: transparent;
}

.dark-theme .border {
    border-color: var(--dark-border) !important;
}

.dark-theme .border-top {
    border-top-color: var(--dark-border) !important;
}

.dark-theme .border-end {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .border-bottom {
    border-bottom-color: var(--dark-border) !important;
}

.dark-theme .border-start {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .border-secondary {
    border-color: var(--dark-border) !important;
}

.dark-theme .bd {
    border-color: var(--dark-border);
}

.dark-theme .bd-t {
    border-top-color: var(--dark-border);
}

.dark-theme .bd-r {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .bd-b {
    border-bottom-color: var(--dark-border);
}

.dark-theme .bd-l {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .bd-y {
    border-top-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
}

.dark-theme .bd-x {
    border-left-color: var(--dark-border);
    border-right-color: var(--dark-border);
}

@media (min-width: 480px) {
    .dark-theme .bd-xs {
        border-color: var(--dark-border);
    }

    .dark-theme .bd-xs-t {
        border-top-color: var(--dark-border);
    }

    .dark-theme .bd-xs-r {
        border-right-color: var(--dark-border);
        border-left-color: var(--dark-border);
    }

    .dark-theme .bd-xs-b {
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-xs-l {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }

    .dark-theme .bd-xs-y {
        border-top-color: var(--dark-border);
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-xs-x {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }
}

@media (min-width: 576px) {
    .dark-theme .bd-sm {
        border-color: var(--dark-border);
    }

    .dark-theme .bd-sm-t {
        border-top-color: var(--dark-border);
    }

    .dark-theme .bd-sm-r {
        border-right-color: var(--dark-border);
        border-left-color: var(--dark-border);
    }

    .dark-theme .bd-sm-b {
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-sm-l {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }

    .dark-theme .bd-sm-y {
        border-top-color: var(--dark-border);
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-sm-x {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }
}

@media (min-width: 768px) {
    .dark-theme .bd-md {
        border-color: var(--dark-border);
    }

    .dark-theme .bd-md-t {
        border-top-color: var(--dark-border);
    }

    .dark-theme .bd-md-r {
        border-right-color: var(--dark-border);
        border-left-color: var(--dark-border);
    }

    .dark-theme .bd-md-b {
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-md-l {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }

    .dark-theme .bd-md-y {
        border-top-color: var(--dark-border);
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-md-x {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }
}

@media (min-width: 992px) {
    .dark-theme .bd-lg {
        border-color: var(--dark-border);
    }

    .dark-theme .bd-lg-t {
        border-top-color: var(--dark-border);
    }

    .dark-theme .bd-lg-r {
        border-right-color: var(--dark-border);
        border-left-color: var(--dark-border);
    }

    .dark-theme .bd-lg-b {
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-lg-l {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }

    .dark-theme .bd-lg-y {
        border-top-color: var(--dark-border);
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-lg-x {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }
}

@media (min-width: 1200px) {
    .dark-theme .bd-xl {
        border-color: var(--dark-border);
    }

    .dark-theme .bd-xl-t {
        border-top-color: var(--dark-border);
    }

    .dark-theme .bd-xl-r {
        border-right-color: var(--dark-border);
        border-left-color: var(--dark-border);
    }

    .dark-theme .bd-xl-b {
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-xl-l {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }

    .dark-theme .bd-xl-y {
        border-top-color: var(--dark-border);
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .bd-xl-x {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }
}

.dark-theme .text-body,
.dark-theme .text-muted {
    color: var(--dark-color) !important;
}

.dark-theme .landing-top-header .top .nav li a {
    color: var(--dark-color);
}

@media (max-width: 992px) {
    .dark-theme.landing-page .navbar.navbar-expand-lg.navbar-light {
        background-color: var(--dark-theme);
    }

    .dark-theme.landing-page .app-sidebar.horizontal-main {
        background-color: var(--dark-theme) !important;
    }
}

.dark-theme .demo-footer {
    background-color: var(--dark-theme);
}

.dark-theme .demo-footer .card,
.dark-theme .demo-footer .main-footer {
    background-color: var(--dark-theme);
}

.dark-theme .demo-footer hr {
    border-top-color: var(--dark-border);
}

.dark-theme .demo-footer .footer-social-list a {
    color: var(--dark-color);
}

.dark-theme .demo-footer .top-footer img {
    border-color: var(--dark-border);
}

.dark-theme.landing-page {
    background-color: var(--dark-theme);
}

.dark-theme.landing-page .demo-footer .btn-list .btn-icon {
    border-color: var(--dark-border);
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme.landing-page .feature-1,
.dark-theme.landing-page .owl-controls .owl-page.active {
    border-color: var(--dark-border);
}

.dark-theme.landing-page .footer-social-list a {
    background: var(--dark-color);
}

.dark-theme.landing-page .card.features.main-features {
    border-color: var(--dark-border) !important;
}

.dark-theme.landing-page .owl-item img {
    border-color: var(--dark-border);
}

.dark-theme.landing-page .bg-pages .card {
    border-color: var(--dark-border) !important;
    background-color: var(--dark-theme);
}

.dark-theme.landing-page .bg-landing {
    background-color: var(--dark-theme);
}

.dark-theme.landing-page .landing-statistics .card-body:hover::before,
.dark-theme.landing-page .landing-statistics .card-body:hover::after {
    background: var(--dark-theme);
}

.dark-theme.landing-page .landing-statistics .counter-icon {
    background: var(--dark-theme);
}

.dark-theme.landing-page .text-white-80 {
    color: var(--dark-color);
}

.dark-theme.landing-page .web-item {
    border-color: var(--dark-border);
}

.dark-theme.landing-page .web-item h4 {
    color: var(--dark-color);
}

.dark-theme.landing-page #faqs .card-options a:not(.btn),
.dark-theme.landing-page #faqs .card-header a:hover,
.dark-theme.landing-page #faqs i {
    color: var(--dark-color);
}

.dark-theme.landing-page .top.sticky.stickyClass {
    border-bottom-color: var(--dark-border);
    background: var(--dark-theme);
    box-shadow: 0px 4px 16px var(--dark-theme);
}

.dark-theme.landing-page #faqs .card.bg-secondary-transparent .card-header {
    border-left-color: var(--dark-border);
    border-right-color: var(--dark-border);
}

.dark-theme.landing-page #faqs .card.bg-secondary-transparent .card-header .card-options-collapse i {
    background-color: var(--dark-theme);
}

.dark-theme.landing-page #faqs .card.bg-secondary-transparent .card-body {
    border-left-color: var(--dark-border);
    border-right-color: var(--dark-border);
}

.dark-theme.landing-page .footer-social-list a:hover {
    color: var(--dark-color);
}

.dark-theme.landing-page .user-social-detail .social-profile {
    background-color: var(--dark-theme);
}

@media (min-width: 992px) and (max-width: 1275.98px) {
    .dark-theme.landing-page.horizontalmenu .side-menu>li>a {
        color: var(--dark-color);
    }
}

.dark-theme .slick-slide img {
    background-color: transparent;
}

.dark-theme .team-members {
    border-color: var(--dark-border);
}

.dark-theme.landing-page .slick-prev:before,
.dark-theme.landing-page .slick-next:before {
    border-color: var(--dark-border);
    background: var(--dark-body);
    box-shadow: 0px 16px 32px var(--dark-theme);
}

.dark-theme.landing-page .tab_wrapper.left_side>ul {
    border-bottom-color: var(--dark-border);
}

.dark-theme.landing-page .main-demo-1 h6 {
    color: var(--dark-color);
}

.dark-theme .tag {
    background-color: var(--dark-body);
}

.dark-theme a.tag:hover {
    background-color: var(--dark-theme);
}

.dark-theme .tag-addon {
    background: var(--dark-body);
}

.dark-theme .nav-tabs {
    border-bottom-color: var(--dark-border);
}

.dark-theme .nav-tabs .nav-link {
    background-color: transparent;
    color: var(--dark-color);
}

.dark-theme .nav-tabs .nav-link:hover,
.dark-theme .nav-tabs .nav-link:focus {
    border-color: var(--dark-border);
}

.dark-theme .nav-tabs .nav-link.disabled {
    color: var(--dark-color);
}

.dark-theme .nav-tabs .nav-link.active {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border) var(--dark-color) var(--dark-border);
}

.dark-theme .nav-tabs .nav-item.show .nav-link {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border) var(--dark-color) var(--dark-border);
}

.dark-theme .main-nav-tabs {
    background-color: var(--dark-theme);
}

.dark-theme .main-nav-tabs .lSAction>a {
    background-color: var(--dark-body);
}

.dark-theme .main-nav-tabs .lSAction>a:hover,
.dark-theme .main-nav-tabs .lSAction>a:focus {
    background-color: var(--dark-body);
}

.dark-theme .main-nav-tabs .lSAction>a.disabled {
    background-color: var(--dark-theme);
}

.dark-theme .main-nav-tabs .tab-link {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .main-nav-tabs .tab-link:hover,
.dark-theme .main-nav-tabs .tab-link:focus {
    background-color: var(--dark-theme);
}

.dark-theme .main-nav-tabs .tab-link.active {
    background-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .tabs-style-1 .panel-tabs {
    border-bottom-color: var(--dark-border);
}

.dark-theme .tabs-style-1 .main-nav-line .nav-link {
    border-color: var(--dark-border);
}

.dark-theme .tabs-style-1 .main-nav-line .nav-link.active {
    color: var(--dark-color);
    border-color: var(--dark-border) !important;
}

.dark-theme .tabs-style-3 {
    border-color: var(--dark-border);
}

.dark-theme .tabs-style-3 .nav.panel-tabs li a {
    color: var(--dark-color);
}

.dark-theme .tabs-style-4 .nav.panel-tabs li a,
.dark-theme .nav-link.disabled {
    color: var(--dark-color);
}

@media (min-width: 992px) {
    .dark-theme .main-navbar .nav-sub {
        background-color: var(--dark-theme);
        border-top-color: var(--dark-border);
    }
}

.dark-theme .main-navbar {
    background-color: var(--dark-theme);
}

@media (min-width: 992px) {

    .dark-theme .main-navbar .nav-sub-mega .container,
    .dark-theme .main-navbar .nav-sub-mega .container-fluid {
        background-color: var(--dark-theme);
        border-top-color: var(--dark-border);
    }
}

@media (min-width: 992px) {
    .dark-theme .main-navbar .nav-link {
        color: rgba(255, 255, 255, 0.6);
    }

    .dark-theme .main-navbar .nav-link.with-sub::after {
        color: var(--dark-color);
    }

    .dark-theme .main-navbar .nav-link.with-sub::before {
        border-top-color: var(--dark-border);
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
        background-color: var(--dark-theme);
    }

    .dark-theme .main-navbar .nav-sub-link {
        color: rgba(255, 255, 255, 0.4);
    }

    .dark-theme .main-navbar .nav-sub-link.with-sub::after {
        color: rgba(255, 255, 255, 0.4);
    }

    .dark-theme .main-navbar .nav-item .nav-sub li:not(.nav-sub-item),
    .dark-theme .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
        color: var(--dark-color);
    }
}

@media (max-width: 992px) {

    .dark-theme .main-navbar .nav-sub-link:hover,
    .dark-theme .main-navbar .nav-sub-link:focus {
        color: var(--dark-color);
    }
}

@media (min-width: 992px) {

    .dark-theme .main-navbar .nav-sub-mega .container>div+div,
    .dark-theme .main-navbar .nav-sub-mega .container-fluid>div+div {
        border-left-color: var(--dark-border);
        border-right-color: var(--dark-border);
    }
}

.dark-theme .main-navbar-header {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-navbar-search {
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-navbar-search .form-control:focus {
    border-color: var(--dark-border);
}

.dark-theme .main-navbar-three .nav-link {
    color: var(--dark-color);
}

.dark-theme .main-navbar-three .nav-link:hover,
.dark-theme .main-navbar-three .nav-link:focus {
    color: var(--dark-color);
}

.dark-theme .navbar-toggler .header-icons:hover,
.dark-theme .option-dots:hover {
    background-color: var(--dark-theme);
}

.dark-theme .wizard {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .wizard>.steps a {
    color: var(--dark-color);
}

.dark-theme .wizard>.steps a:hover,
.dark-theme .wizard>.steps a:active {
    color: var(--dark-color);
}

.dark-theme .wizard>.steps a .number,
.dark-theme .wizard>.steps a:hover .number,
.dark-theme .wizard>.steps a:active .number {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .wizard>.steps .disabled a {
    color: var(--dark-color);
}

.dark-theme .wizard>.steps .disabled a:hover,
.dark-theme .wizard>.steps .disabled a:active {
    color: var(--dark-color);
}

.dark-theme .wizard>.steps .current a .number,
.dark-theme .wizard>.steps .current a:hover .number,
.dark-theme .wizard>.steps .current a:active .number {
    color: var(--dark-color);
}

.dark-theme .wizard>.steps .done a {
    color: var(--dark-color);
}

.dark-theme .wizard>.steps .done a:hover,
.dark-theme .wizard>.steps .done a:active {
    color: var(--dark-color);
}

.dark-theme .wizard>.steps .done a .number,
.dark-theme .wizard>.steps .done a:hover .number,
.dark-theme .wizard>.steps .done a:active .number {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .wizard>.content {
    border-top-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
}

.dark-theme .wizard>.content>.title {
    color: var(--dark-color);
}

.dark-theme .wizard>.actions .disabled a {
    background-color: var(--dark-theme);
}

.dark-theme .wizard>.actions .disabled a:hover,
.dark-theme .wizard>.actions .disabled a:active {
    background-color: var(--dark-theme);
}

@media (min-width: 576px) {
    .dark-theme .wizard.vertical>.content {
        border-left-color: var(--dark-border) !important;
        border-right-color: var(--dark-border) !important;
    }

    .dark-theme .wizard.vertical>.actions {
        border-left-color: var(--dark-border) !important;
        border-right-color: var(--dark-border) !important;
    }
}

.dark-theme .wizard-style-1>.steps>ul a .number,
.dark-theme .wizard-style-1>.steps>ul a:hover .number,
.dark-theme .wizard-style-1>.steps>ul a:active .number {
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme .wizard-style-1>.steps>ul .done a .number,
.dark-theme .wizard-style-1>.steps>ul .done a:hover .number,
.dark-theme .wizard-style-1>.steps>ul .done a:active .number {
    color: var(--dark-color);
}

.dark-theme .wizard-style-2>.steps>ul a .number,
.dark-theme .wizard-style-2>.steps>ul a:hover .number,
.dark-theme .wizard-style-2>.steps>ul a:active .number {
    border-color: var(--dark-border);
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme .ps>.ps__rail-y {
    background-color: var(--dark-body);
}

.dark-theme .rdiobox span:before {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .rdiobox span:after {
    background-color: var(--dark-theme);
}

.dark-theme .custom-select {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .custom-select:focus::-ms-value,
.dark-theme .custom-select:disabled {
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme .custom-file-input:disabled~.custom-file-label {
    background-color: var(--dark-theme);
}

.dark-theme .custom-file-label {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .custom-file-label::after {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .custom-range::-webkit-slider-thumb:active {
    background-color: var(--dark-body);
}

.dark-theme .custom-range::-webkit-slider-runnable-track {
    background-color: var(--dark-theme);
}

.dark-theme .custom-range::-moz-range-thumb:active {
    background-color: var(--dark-body);
}

.dark-theme .custom-range::-moz-range-track {
    background-color: var(--dark-theme);
}

.dark-theme .custom-range::-ms-thumb:active {
    background-color: var(--dark-body);
}

.dark-theme .custom-range::-ms-fill-lower,
.dark-theme .custom-range::-ms-fill-upper {
    background-color: var(--dark-theme);
}

.dark-theme .custom-range:disabled::-webkit-slider-thumb,
.dark-theme .custom-range:disabled::-moz-range-thumb,
.dark-theme .custom-range:disabled::-ms-thumb {
    background-color: var(--dark-theme);
}

.dark-theme .custom-switch-indicator {
    background: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .custom-switch-indicator:before {
    background: var(--dark-theme);
}

.dark-theme .custom-switch-description,
.dark-theme .custom-switch-input:checked~.custom-switch-description {
    color: var(--dark-color);
}

.dark-theme .custom-switch .custom-control-label::after,
.dark-theme .custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: var(--dark-theme);
}

.dark-theme .main-rating-value {
    color: var(--dark-color);
}

.dark-theme .rating-stars .rating-stars-container .rating-star {
    color: var(--dark-color);
}

.dark-theme .rating-stars .rating-stars-container .rating-star.sm,
.dark-theme .rating-stars .rating-stars-container .rating-star.is--no-hover,
.dark-theme .rating-stars .rating-stars-container .rating-star .fa-heart .is--no-hover {
    color: var(--dark-color);
}

.dark-theme .rating-stars input {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .latest-timeline1-icon {
    color: var(--dark-color);
}

.dark-theme .latest-timeline ul.timeline:before {
    background: var(--dark-body);
}

.dark-theme .latest-timeline1 a {
    color: var(--dark-color);
}

.dark-theme .vtimeline:before {
    background-color: var(--dark-body);
}

.dark-theme .vtimeline .timeline-wrapper .timeline-panel {
    background: var(--dark-theme);
}

.dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .vtimeline .timeline-wrapper .timeline-badge {
    border-color: var(--dark-border);
}

.dark-theme .vtimeline .timeline-wrapper .timeline-badge i {
    color: var(--dark-color);
}

.dark-theme .timeline-wrapper-secondary .timeline-panel:before,
.dark-theme .timeline-wrapper-secondary .timeline-badge {
    background: var(--dark-body);
}

.dark-theme .timeline-wrapper-light .timeline-panel:before,
.dark-theme .timeline-wrapper-light .timeline-badge {
    background: var(--dark-theme);
}

.dark-theme .timeline-wrapper-dark .timeline-panel:before,
.dark-theme .timeline-wrapper-dark .timeline-badge {
    background: var(--dark-body);
}

.dark-theme .img-sm {
    border-color: var(--dark-border);
    background: var(--dark-body);
}

.dark-theme .avatar {
    color: var(--dark-color);
}

.dark-theme .main-avatar {
    color: var(--dark-color);
}

.dark-theme .main-avatar::after {
    background-color: var(--dark-theme);
}

.dark-theme .avatar-xl::after {
    box-shadow: 0 0 0 2.5px var(--dark-border);
}

.dark-theme .avatar-xxl::after {
    box-shadow: 0 0 0 3px var(--dark-border);
}

@media (min-width: 576px) {

    .dark-theme .demo-avatar-group .main-img-user+.main-img-user,
    .dark-theme .demo-avatar-group .main-img-user+.main-avatar {
        border-color: var(--dark-border);
    }

    .dark-theme .demo-avatar-group .main-avatar+.main-img-user,
    .dark-theme .demo-avatar-group .main-avatar+.main-avatar {
        border-color: var(--dark-border);
    }
}

@media (min-width: 576px) {

    .dark-theme #shapes .demo-avatar-group .main-img-user+.main-img-user,
    .dark-theme #shapes .demo-avatar-group .main-img-user+.main-avatar {
        border-color: var(--dark-border);
    }
}

.dark-theme .main-list-item+.main-list-item {
    border-top-color: var(--dark-border);
}

.dark-theme .main-list-item>div:first-child h6,
.dark-theme .main-list-item>div span {
    color: var(--dark-color);
}

.dark-theme .ckbox span:before {
    background-color: var(--dark-theme);
    border-color: var(--dark-border) !important;
}

.dark-theme .main-toggle {
    background-color: var(--dark-body);
}

.dark-theme .main-toggle span {
    background-color: var(--dark-theme);
}

.dark-theme .main-toggle span::before,
.dark-theme .main-toggle span::after {
    color: var(--dark-color);
}

.dark-theme .main-toggle-dark.on {
    background-color: var(--dark-body);
}

.dark-theme .list-group {
    color: var(--dark-color);
}

.dark-theme .list-group-item-action {
    color: var(--dark-color);
}

.dark-theme .list-group-item-action:hover,
.dark-theme .list-group-item-action:focus,
.dark-theme .list-group-item-action:active {
    color: var(--dark-color);
    background-color: var(--dark-body);
}

.dark-theme .list-group-item {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
    color: var(--dark-color);
}

.dark-theme .list-group-item.disabled,
.dark-theme .list-group-item:disabled {
    background-color: var(--dark-theme);
}

.dark-theme .custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: var(--dark-color);
    background-color: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .custom-control-input:disabled~.custom-control-label {
    color: var(--dark-color);
}

.dark-theme .custom-control-input:disabled~.custom-control-label::before {
    background-color: var(--dark-theme);
}

.dark-theme .custom-control-label::before {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .custom-switch-indicator-md {
    background: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .custom-switch-description {
    color: var(--dark-color);
}

@media print {

    .dark-theme pre,
    .dark-theme blockquote,
    .dark-theme .badge {
        border-color: var(--dark-border);
    }

    .dark-theme .table td,
    .dark-theme .table th {
        background-color: var(--dark-body) !important;
    }

    .dark-theme .table-bordered th,
    .dark-theme .table-bordered td {
        border-color: var(--dark-border) !important;
    }

    .dark-theme .table-dark th,
    .dark-theme .table-dark td,
    .dark-theme .table-dark thead th,
    .dark-theme .table-dark tbody+tbody {
        border-color: var(--dark-border);
    }

    .dark-theme .table .thead-dark th {
        border-color: var(--dark-border);
    }
}

.dark-theme .main-table-reference {
    background-color: var(--dark-theme);
}

.dark-theme .main-table-reference>thead>tr>th,
.dark-theme .main-table-reference>thead>tr>td {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .main-table-reference>tbody>tr>th,
.dark-theme .main-table-reference>tbody>tr>td {
    border-color: var(--dark-border);
}

.dark-theme .main-notification-title,
.dark-theme .main-notification-text {
    color: var(--dark-color);
}

.dark-theme .main-notification-list,
.dark-theme .main-profile-menu .dropdown-menu .dropdown-item:first-child {
    border-top-color: var(--dark-border);
}

.dark-theme .main-notification-list .media+.media {
    border-top-color: var(--dark-border);
}

.dark-theme .main-notification-list .media.new {
    color: var(--dark-color);
}

.dark-theme .main-notification-list .media:hover::before,
.dark-theme .main-notification-list .media:focus::before {
    background-color: var(--dark-theme);
    border-top-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
}

.dark-theme .main-notification-list .media-body p,
.dark-theme .main-notification-list .media-body span {
    color: var(--dark-color);
}

.dark-theme .main-dropdown-form-demo .static-dropdown {
    background-color: var(--dark-theme);
}

.dark-theme .main-dropdown-form-demo .dropdown-title {
    color: var(--dark-color);
}

.dark-theme .main-content-left-components {
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
}

.dark-theme .main-content-left-components .component-item .nav-link {
    color: var(--dark-color);
}

.dark-theme .flag-dropdown .dropdown-item {
    color: var(--dark-color);
}

.dark-theme .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

@media (max-width: 991px) {
    .dark-theme .main-header.side-header {
        border-bottom-color: var(--dark-border);
    }

    .dark-theme .header-search .select2-container--default .select2-selection--single {
        background-color: var(--dark-theme);
        border-color: var(--dark-border);
    }

    .dark-theme .responsive-navbar.navbar .navbar-collapse {
        background: var(--dark-theme);
        border-bottom-color: var(--dark-border);
        border-top-color: var(--dark-border);
    }
}

.dark-theme .media-logo,
.dark-theme .media-icon {
    color: var(--dark-color);
}

.dark-theme .flat a {
    background: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .flat a:after {
    background: var(--dark-theme);
    box-shadow: 2px -2px 0 2px var(--dark-body), 3px -3px 0 2px var(--dark-body);
}

.dark-theme .flat a.active:before,
.dark-theme .flat a:hover:before {
    color: var(--dark-color);
}

.dark-theme .flat a:before {
    background: var(--dark-body);
}

.dark-theme .page-header .breadcrumb-item a,
.dark-theme .page-header .breadcrumb-item.active {
    color: var(--dark-color);
}

@media (max-width: 991px) {
    .dark-theme .main-header {
        border-bottom-color: var(--dark-border);
    }
}

@media (max-width: 767px) {
    .dark-theme .main-content-body-contacts {
        border-top-color: var(--dark-border);
    }

    .dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
        border-right-color: var(--dark-border) !important;
        border-left-color: var(--dark-border) !important;
    }
}

@media (min-width: 992px) {
    .dark-theme .main-navbar.sticky.sticky-pin {
        border-bottom-color: var(--dark-border);
    }
}

.dark-theme .contact-icon:hover {
    background: var(--dark-border);
}

.dark-theme .component-item a:before {
    color: var(--dark-color);
}

.dark-theme .main-icon-list {
    border-color: var(--dark-border);
}

.dark-theme .skill-tags a {
    color: var(--dark-color);
    border-color: var(--dark-border);
}

.dark-theme .reviewnavs li a,
.dark-theme .dash-icon {
    color: var(--dark-color);
}

.dark-theme .pricing-tabs .nav-price li a {
    color: var(--dark-color);
    background: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .pricing-tabs .nav-price li .active {
    background: var(--dark-body);
}

.dark-theme .pricingTable2 {
    background: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .pricingTable2-header {
    background-color: var(--dark-theme);
}

.dark-theme .pricingTable2 .pricingTable2-header h3 {
    color: var(--dark-color);
}

.dark-theme .pricingTable2 .pricingTable2-sign-up {
    border-top-color: var(--dark-border);
}

.dark-theme .example {
    border-color: var(--dark-border);
}

.dark-theme .highlight {
    border-color: var(--dark-border);
    background: var(--dark-body);
}

.dark-theme .clipboard-icon {
    background: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .our-team:hover .picture img {
    box-shadow: 0 0 0 3px var(--dark-border);
}

.dark-theme .task-list {
    color: var(--dark-color);
}

.dark-theme .task-list:after {
    background: var(--dark-border);
}

.dark-theme .dt-button-collection .dropdown-item.active,
.dark-theme .dt-button-collection .dropdown-item:active {
    color: var(--dark-color);
    background-color: var(--dark-theme);
}

.dark-theme .advanced-search {
    background: var(--dark-theme);
}

.dark-theme .contact-info .contact-icon:hover {
    color: var(--dark-color);
}

.dark-theme .activity-block .task-list li .task-icon {
    color: var(--dark-color);
}

.dark-theme #global-loader {
    background: var(--dark-theme);
}

.dark-theme .page-header .page-title i {
    background: linear-gradient(89.1deg, var(--dark-body) 0.7%, var(--dark-body) 88.4%);
}

.dark-theme .feature .feature-icon {
    color: var(--dark-color);
}

.dark-theme .feature.bg-transparent {
    background: var(--dark-body) !important;
}

.dark-theme .search-imgs li img {
    border-color: var(--dark-border) !important;
}

.dark-theme .handle-counter input {
    border-color: var(--dark-border);
}

.dark-theme .handle-counter .btn:disabled {
    color: var(--dark-color);
}

.dark-theme .handle-counter .btn:disabled:hover {
    background-color: var(--dark-body);
}

.dark-theme .itemside .title {
    color: var(--dark-color);
}

.dark-theme .upgrade.custom-card {
    background: var(--dark-body);
}

.dark-theme ::-webkit-scrollbar-thumb {
    background: var(--dark-border);
}

.dark-theme .selectgroup-button {
    border-color: var(--dark-border);
    color: var(--dark-color);
}

.dark-theme .selectgroup-input:checked+.selectgroup-button {
    background: var(--dark-body);
}

.dark-theme .toast-header .btn-close {
    color: var(--dark-color);
}

.dark-theme .tag.tag-attachments,
.dark-theme .tag.tag-attachments-lg,
.dark-theme .tag.tag-attachments-sm {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .tags .tag-files span {
    color: var(--dark-color);
}

.dark-theme .theme-container .active {
    background: var(--dark-body);
}

.dark-theme .theme-container1 .active,
.dark-theme .theme-container2 .active {
    border-color: var(--dark-border);
    background: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .dark-layout {
    display: block;
}

.dark-theme .light-layout {
    display: none;
}

.dark-theme .main-content-title {
    color: #ffffff;
}

.dark-theme .card-dashboard-calendar .table tbody tr {
    background-color: transparent;
}

.dark-theme .btn-white {
    background-color: var(--dark-theme);
    border-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .btn-white:hover {
    background-color: var(--dark-theme);
    border-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .btn-white:focus,
.dark-theme .btn-white.focus {
    background-color: var(--dark-theme);
    border-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .btn-white.disabled,
.dark-theme .btn-white:disabled {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-theme);
}

.dark-theme .btn-white:not(:disabled):not(.disabled):active,
.dark-theme .btn-white:not(:disabled):not(.disabled).active {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-theme);
}

.dark-theme .show>.btn-white.dropdown-toggle {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-theme);
}

.dark-theme .table thead th,
.dark-theme .table thead td {
    color: var(--dark-color);
}

.dark-theme .bg-light {
    background-color: var(--dark-body) !important;
    color: var(--dark-color) !important;
}

@media (min-width: 992px) {
    .dark-theme .main-sidebar-header .sidemenu-logo {
        border-right-color: var(--dark-border) !important;
        border-left-color: var(--dark-border) !important;
    }

    .dark-theme.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo-dark {
        display: block;
    }

    .dark-theme.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo {
        display: none;
    }

    .dark-theme .main-navbar .nav-sub {
        box-shadow: 0 8px 16px 0 var(--dark-body);
    }

    .dark-theme .table-responsive .table> :not(caption)>*>* {
        border-bottom-color: var(--dark-border) !important;
    }
}

.dark-theme .responsive-navbar.navbar .navbar-collapse {
    box-shadow: 7px 8px 9px -2px var(--dark-theme);
}

@media (max-width: 991px) {

    .dark-theme.main-body .mobile-logo-dark,
    .dark-theme.horizontalmenu .mobile-logo-dark {
        display: block;
    }

    .dark-theme.main-body .mobile-logo,
    .dark-theme.horizontalmenu .mobile-logo {
        display: none;
    }
}

.dark-theme .navbar-toggler {
    color: var(--dark-color);
}

.dark-theme.error-1 .nav-link.icon {
    color: var(--dark-color);
}

@media screen and (min-width: 992px) {
    .dark-theme.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        box-shadow: -6px 4px 20px 0px var(--dark-body);
    }

    .dark-theme.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        box-shadow: -6px 4px 20px 0px var(--dark-body);
    }
}

.dark-theme .border-end-0 {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .border-start-0 {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .select2-search__field {
    background-color: var(--dark-body);
}

.dark-theme .text-dark {
    color: var(--dark-color) !important;
}

.dark-theme .btn-light:focus,
.dark-theme .btn-light.focus {
    box-shadow: 0 0 0 1px var(--dark-border);
}

.dark-theme .page-link {
    border-color: var(--dark-border);
}

@media (min-width: 992px) {
    .dark-theme .main-navbar.hor-menu {
        box-shadow: -8px 12px 18px 0 var(--dark-body);
    }
}

.dark-theme .apexcharts-datalabels-group text {
    fill: var(--dark-color);
}

.dark-theme .table-hover>tbody>tr:hover {
    color: #ffffff;
}

.dark-theme .handle-counter input {
    background-color: var(--dark-theme);
}

.dark-theme .form-select {
    color: var(--dark-color);
    background-color: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone:hover,
.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone:focus,
.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone:active {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.dark-theme .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: var(--dark-border);
}

.dark-theme .ql-snow .ql-picker-options {
    background-color: var(--dark-body);
}

.dark-theme .ff_fileupload_filename input {
    background-color: transparent;
}

.dark-theme .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary {
    color: var(--dark-color);
}

.dark-theme .ql-snow .ql-tooltip {
    background-color: var(--dark-body);
    border-color: var(--dark-border);
    box-shadow: 0px 0px 5px var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .ql-tooltip.ql-editing input {
    background-color: var(--dark-theme);
}

.dark-theme .ql-snow .ql-tooltip input[type=text] {
    border-color: var(--dark-border);
}

.dark-theme .ql-snow .ql-tooltip input[type=text]:focus-visible {
    outline: none;
}

.dark-theme a.text-dark:hover,
.dark-theme a.text-dark:focus {
    color: #ffffff !important;
}

.dark-theme .item1-links.nav.nav-tabs .nav-link {
    background-color: var(--dark-theme);
}

.dark-theme .tx-inverse {
    color: #ffffff;
}

.dark-theme .btn,
.dark-theme .sp-container button {
    color: #ffffff;
}

.dark-theme .table-striped>tbody>tr:nth-of-type(odd) {
    color: #ffffff;
}

.dark-theme .owl-carousel:hover .owl-nav button {
    background: var(--dark-theme) !important;
}

.dark-theme .bg-white {
    background-color: var(--dark-theme) !important;
}

.dark-theme .box-shadow {
    box-shadow: 0 8px 16px 0 var(--dark-body);
}

.dark-theme .btn-outline-dark {
    color: var(--dark-color) !important;
    border-color: var(--dark-border);
}

.dark-theme .btn-outline-warning {
    color: var(--dark-color) !important;
}

.dark-theme .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.dark-theme .bs-popover-start>.popover-arrow::after {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,
.dark-theme .bs-popover-top>.popover-arrow::after {
    border-top-color: var(--dark-border);
}

.dark-theme .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,
.dark-theme .bs-popover-bottom>.popover-arrow::after {
    border-bottom-color: var(--dark-border);
}

.dark-theme .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.dark-theme .bs-popover-end>.popover-arrow::after {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.dark-theme .bs-tooltip-start .tooltip-arrow::before {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.dark-theme .bs-tooltip-end .tooltip-arrow::before {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .toast-header h6 {
    color: var(--dark-color);
}

.dark-theme .bootstrap-tagsinput {
    background-color: transparent;
}

.dark-theme.rtl .main-msg-wrapper:before,
.dark-theme.rtl .main-msg-wrapper:after {
    border-color: transparent transparent transparent var(--dark-body) !important;
}

.dark-theme.rtl .notification .notification-body:before {
    border-left-color: var(--dark-theme);
    border-right-color: transparent;
}

.dark-theme #external-events {
    border-color: var(--dark-border);
    background: var(--dark-body);
}

.dark-theme #external-events p {
    color: var(--dark-color);
}

.dark-theme .fc-theme-standard td,
.dark-theme .fc-theme-standard th {
    border-color: var(--dark-border);
}

.dark-theme .fc .fc-non-business {
    background: var(--dark-body);
}

.dark-theme .fc-theme-standard .fc-scrollgrid {
    border-color: var(--dark-border);
}

.dark-theme .fc .fc-daygrid-day.fc-day-today {
    background-color: var(--dark-body);
}

.dark-theme .fc-daygrid-day-number {
    color: var(--dark-color);
}

.dark-theme .fc-theme-standard .fc-popover-header {
    background: var(--dark-body);
}

.dark-theme .fc-theme-standard .fc-popover {
    border-color: var(--dark-border);
    background: var(--dark-body);
}

.dark-theme .fc-theme-standard .fc-list-day-cushion {
    background-color: var(--dark-body);
}

.dark-theme .fc-theme-standard .fc-list {
    border-color: var(--dark-border);
}

.dark-theme .fc .fc-list-empty {
    background-color: var(--dark-body);
}

.dark-theme .fc .fc-list-event:hover td {
    background-color: var(--dark-theme);
}

.dark-theme .datepicker-container {
    background-color: var(--dark-body);
}

.dark-theme .datepicker-top-left::after,
.dark-theme .datepicker-top-right::after {
    border-bottom-color: var(--dark-border);
}

.dark-theme .datepicker-panel>ul>li {
    background-color: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .datepicker-panel>ul>li[data-view="years current"],
.dark-theme .datepicker-panel>ul>li[data-view="year current"],
.dark-theme .datepicker-panel>ul>li[data-view="month current"] {
    color: var(--dark-color);
}

.dark-theme .datepicker-panel>ul[data-view=week]>li {
    background-color: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .datepicker-panel>ul[data-view=week]>li:hover {
    background-color: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .datepicker-panel>ul>li:hover {
    background-color: var(--dark-theme);
}

.dark-theme .vtimeline .timeline-wrapper .timeline-panel {
    box-shadow: 0 8px 16px 0 var(--dark-body);
}

.dark-theme .sweet-alert {
    background-color: var(--dark-body);
}

.dark-theme .sweet-alert h2 {
    color: var(--dark-color);
}

.dark-theme .notifit_confirm,
.dark-theme .notifit_prompt {
    background-color: var(--dark-theme);
    box-shadow: 0px 2px 10px var(--dark-body);
}

.dark-theme .tree ul:before {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .tree li {
    color: var(--dark-color);
    border-color: var(--dark-border);
}

.dark-theme .tree ul li:before {
    border-top-color: var(--dark-border);
}

.dark-theme .tree li a {
    color: var(--dark-color);
}

.dark-theme .tree li button {
    color: var(--dark-color);
}

.dark-theme .tree li button:active,
.dark-theme .tree li button:focus {
    color: var(--dark-color);
}

.dark-theme .tree li.branch {
    background: var(--dark-body);
}

.dark-theme .tree li.branch li {
    background: var(--dark-body);
}

.dark-theme .profile-nav-line.bg-gray-100 {
    background-color: var(--dark-body);
}

.dark-theme .bd-r-0 {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .bd-l-0 {
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-theme .select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: var(--dark-body);
}

.dark-theme .intl-tel-input {
    border-color: var(--dark-border);
}

.dark-theme .intl-tel-input .flag-dropdown .selected-flag {
    background: var(--dark-body);
}

.dark-theme .intl-tel-input .flag-dropdown .selected-flag:hover {
    background-color: var(--dark-body);
}

.dark-theme .intl-tel-input .flag-dropdown .country-list {
    background-color: var(--dark-body);
    box-shadow: 0px 12px 41px 0px var(--dark-body);
}

.dark-theme .intl-tel-input .flag-dropdown .country-list .divider {
    border-bottom-color: var(--dark-border);
}

.dark-theme .intl-tel-input .flag-dropdown .country-list .country .dial-code {
    color: var(--dark-color);
}

.dark-theme .intl-tel-input .flag-dropdown .country-list .country.highlight {
    background-color: var(--dark-body);
}

.dark-theme .intl-tel-input input {
    border-color: var(--dark-border);
}

.dark-theme #mobile-number {
    background-color: var(--dark-theme);
}

.dark-theme .dropify-wrapper {
    color: var(--dark-color);
    background-color: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .dropify-wrapper:hover {
    background-image: linear-gradient(-45deg, var(--dark-theme) 25%, transparent 25%, transparent 50%, var(--dark-theme) 50%, var(--dark-theme) 75%, transparent 75%, transparent);
}

.dark-theme .dropify-wrapper .dropify-clear {
    border-color: var(--dark-border);
    color: var(--dark-color);
}

.dark-theme .dropify-wrapper .dropify-preview {
    background-color: var(--dark-body);
}

.dark-theme .note-editor.note-frame.panel.panel-default .panel-heading {
    background-color: var(--dark-body);
    border-bottom-color: var(--dark-border);
}

.dark-theme .note-btn.btn-default {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.dark-theme .note-editor.note-airframe,
.dark-theme .note-editor.note-frame {
    border-color: var(--dark-border);
}

.dark-theme .note-editor .btn-default:not(:disabled):not(.disabled):active {
    background-color: var(--dark-body);
}

.dark-theme .richText {
    border-color: var(--dark-border);
    background-color: var(--dark-body) !important;
}

.dark-theme .richText .richText-form input[type=text],
.dark-theme .richText .richText-form input[type=file],
.dark-theme .richText .richText-form input[type=number] {
    border-color: var(--dark-border);
}

.dark-theme .richText .richText-form select {
    border-color: var(--dark-border);
}

.dark-theme .richText .richText-form button {
    color: var(--dark-color);
}

.dark-theme .richText .richText-toolbar ul {
    border-bottom-color: var(--dark-border);
}

.dark-theme .richText .richText-toolbar ul li a {
    color: var(--dark-color);
}

.dark-theme .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown {
    background-color: var(--dark-body);
    border-color: var(--dark-border);
}

.dark-theme .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
    background: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a {
    border-bottom-color: var(--dark-border);
}

.dark-theme .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a:hover {
    background-color: var(--dark-body);
}

.dark-theme .richText .richText-toolbar ul li a:hover {
    background-color: var(--dark-body);
}

.dark-theme .richText .richText-editor {
    background-color: var(--dark-body);
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .richText .richText-editor table td,
.dark-theme .richText .richText-editor table th {
    border-color: var(--dark-border);
}

.dark-theme .richText .richText-initial {
    background-color: var(--dark-body);
}

.dark-theme .richText .richText-undo,
.dark-theme .richText .richText-redo {
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
}

.dark-theme .richText .richText-help-popup hr {
    border-top-color: var(--dark-border);
}

.dark-theme .richText .richText-list.list-rightclick {
    background-color: var(--dark-body);
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
    border-bottom-color: var(--dark-border);
}

.dark-theme .richText .richText-toolbar ul li a {
    border-right-color: var(--dark-border) !important;
    border-left-color: var(--dark-border) !important;
}

.dark-theme .richText-form-item input {
    background-color: var(--dark-theme);
}

.dark-theme .richText-form-item select {
    background-color: var(--dark-theme);
}

.dark-theme #back-to-top:hover {
    background: var(--dark-theme) !important;
    color: #ffffff !important;
}

.dark-theme .table-responsive .table> :not(caption)>*>* {
    border-bottom-color: var(--dark-border) !important;
}

.dark-theme .transcation-crypto .table-responsive .table th:last-child,
.dark-theme .transcation-crypto .table-responsive .table td:last-child {
    border-left-color: var(--dark-border) !important;
}

.dark-theme table.dataTable>tbody>tr.child ul.dtr-details>li {
    border-bottom-color: var(--dark-border);
}

.dark-theme table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
.dark-theme table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
    border-color: var(--dark-border);
}

.dark-theme .dtr-details tbody,
.dark-theme .dtr-details td,
.dark-theme .dtr-details tfoot,
.dark-theme .dtr-details th,
.dark-theme .dtr-details thead,
.dark-theme .dtr-details tr {
    border-color: var(--dark-border);
}

.dark-theme .crypto-card {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23262649' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.dark-theme .error-bg {
    /* background: url(../img/pngs/bg1.png); */
    background-position: center;
    background-size: cover;
    min-height: calc(100vh - 165px);
}

@media (max-width: 991px) {
    .dark-theme .error-logo-light {
        display: block;
    }

    .dark-theme .error-logo {
        display: none;
    }
}

.dark-theme .datetimepicker-hours table td,
.dark-theme .datetimepicker-minutes table td,
.dark-theme .datetimepicker-months table td,
.dark-theme .datetimepicker-years table td {
    background: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .datetimepicker table th.dow {
    background: var(--dark-theme);
}

.dark-theme .datetimepicker table td {
    background: var(--dark-theme);
    color: var(--dark-color);
}

.dark-theme .datetimepicker table th.next:hover,
.dark-theme .datetimepicker table th.prev:hover,
.dark-theme .datetimepicker table th.switch:hover {
    color: #ffffff;
}

.dark-theme .datetimepicker-years span.hour:hover,
.dark-theme .datetimepicker-years span.minute:hover,
.dark-theme .datetimepicker-years span.month:hover,
.dark-theme .datetimepicker-years span.year:hover {
    background: var(--dark-body);
}

.dark-theme .datetimepicker-minutes span.hour:hover,
.dark-theme .datetimepicker-minutes span.minute:hover,
.dark-theme .datetimepicker-minutes span.month:hover,
.dark-theme .datetimepicker-minutes span.year:hover {
    background: var(--dark-body);
}

.dark-theme .datetimepicker-hours span.hour:hover,
.dark-theme .datetimepicker-hours span.minute:hover,
.dark-theme .datetimepicker-hours span.month:hover,
.dark-theme .datetimepicker-hours span.year:hover {
    background: var(--dark-body);
}

.dark-theme .datetimepicker-days td:hover {
    background: var(--dark-body);
}

.dark-theme .datetimepicker-months span.hour:hover,
.dark-theme .datetimepicker-months span.minute:hover,
.dark-theme .datetimepicker-months span.month:hover,
.dark-theme .datetimepicker-months span.year:hover {
    background: var(--dark-body);
}

.dark-theme .datepicker table tr td span {
    background: var(--dark-theme);
}

.dark-theme .datepicker-dropdown.datepicker-orient-top:before {
    border-top-color: var(--dark-border);
}

.dark-theme .datepicker-days tbody {
    background: var(--dark-theme);
}

.dark-theme .datepicker td {
    color: var(--dark-color);
}

.dark-theme .datepicker table tr td span:hover,
.dark-theme .datepicker table tr td span.focused {
    background: var(--dark-body);
}

.dark-theme .datepicker table tr td span.active.active,
.dark-theme .datepicker table tr td span.active:hover.active {
    background-color: var(--dark-body);
}

.dark-theme .daterangepicker .input-mini {
    border-color: var(--dark-border);
}

.dark-theme .daterangepicker:after {
    border-bottom-color: var(--dark-border);
}

.dark-theme .daterangepicker .calendar-table {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .daterangepicker .calendar td {
    color: var(--dark-color) !important;
}

.dark-theme .daterangepicker td.off {
    background-color: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .daterangepicker .input-mini.active {
    border-color: var(--dark-border);
}

.dark-theme .daterangepicker td.available:hover,
.dark-theme .daterangepicker th.available:hover {
    background-color: var(--dark-body);
}

.dark-theme .daterangepicker td.active {
    background-color: var(--dark-body);
}

.dark-theme .daterangepicker td.in-range {
    background-color: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .daterangepicker td.off.in-range,
.dark-theme .daterangepicker td.off.start-date,
.dark-theme .daterangepicker td.off.end-date {
    background-color: var(--dark-body);
    color: var(--dark-color);
}

.dark-theme .gZWIKX {
    background-color: #0e0e23;
    border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark-theme .jVeafb {
    background-color: #0e0e23;
    color: #d0d0e2;
}

.dark-theme .jxflYm {
    background-color: #0e0e23;
    color: #d0d0e2;
}

.dark-theme .idPHNo {
    color: #d0d0e2;
}

.dark-theme .jVeafb:not(:last-of-type) {
    border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark-theme .bvxQGL:disabled {
    fill: rgba(255, 255, 255, 0.6);
}

.dark-theme .bvxQGL {
    fill: #d0d0e2;
}

.dark-theme .data-table-extensions>.data-table-extensions-filter>.filter-text {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.dark-theme .searchable__controls {
    background-color: #0e0e23 !important;
    border: 1px solid rgba(255, 255, 255, 0.15) !important;
}

.dark-theme .searchable__list {
    background-color: #0e0e23 !important;
    box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3) !important;
}

.dark-theme .searchable--active .searchable__controls {
    border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark-theme .searchable__list__item {
    color: #d0d0e2 !important;
}

.dark-theme .searchable__list__item:hover {
    background-color: #2a2a43 !important;
}

.dark-theme .searchable__controls input {
    color: #d0d0e2;
}

.dark-theme .rmsc .dropdown-container {
    background-color: var(--dark-theme);
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .rmsc .dropdown-content {
    background-color: var(--dark-theme) !important;
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .rmsc .gray {
    color: var(--dark-color) !important;
}

.dark-theme .rmsc.multi-select .dropdown-content {
    background-color: var(--dark-theme);
}

.dark-theme .css-1insrsq-control {
    background-color: var(--dark-theme);
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .css-1rhbuit-multiValue {
    background-color: var(--dark-theme);
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .css-109onse-indicatorSeparator {
    background-color: 323244;
}

.dark-theme .css-12jo7m5 {
    color: #d0d0e2;
}

.dark-theme .react-datepicker {
    background-color: var(--dark-theme);
    color: #d0d0e2;
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .react-datepicker__header {
    background-color: var(--dark-theme);
    border-bottom: 1px solid var(--dark-border) !important;
}

.dark-theme .react-datepicker__day:hover,
.dark-theme .react-datepicker__month-text:hover,
.dark-theme .react-datepicker__quarter-text:hover,
.dark-theme .react-datepicker__year-text:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .react-datepicker__day-name,
.dark-theme .react-datepicker__day,
.dark-theme .react-datepicker__time-name {
    color: #d0d0e2;
}

.dark-theme .react-datepicker__current-month,
.dark-theme .react-datepicker-time__header,
.dark-theme .react-datepicker-year-header {
    color: #d0d0e2;
}

.dark-theme .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
    border-top-color: #aeaeae;
}

.dark-theme .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.dark-theme .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
    border-top-color: var(--dark-theme);
}

.dark-theme .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.dark-theme .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: var(--dark-theme);
}

.dark-theme .MuiDropzoneArea-root {
    border-color: var(--dark-theme);
    background-color: var(--dark-theme);
}

.dark-theme .cnZJOg {
    border-color: var(--dark-theme);
    background-color: var(--dark-theme);
}

.dark-theme .MuiPaper-root {
    color: #d0d0e2;
    background-color: var(--dark-theme);
}

.dark-theme .intl-tel-input .country-list {
    border-color: var(--dark-border);
    background-color: var(--dark-theme);
}

.dark-theme .searchable--active .searchable__list,
.dark-theme .searchable--active .searchable__controls {
    border: 1px solid var(--dark-border);
    border-top: 0;
}

.dark-theme .apexcharts-pie-area.apexcharts-donut-slice-2,
.dark-theme .apexcharts-pie-area.apexcharts-donut-slice-0,
.dark-theme .apexcharts-pie-area.apexcharts-donut-slice-1 {
    stroke: var(--dark-theme);
}

.dark-theme .msl {
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .msl-input:before {
    color: rgba(255, 255, 255, 0.5) !important;
}

.dark-theme .msl-vars {
    --active-menu-shadow: 1px 1px 5px 0px rgba(255, 255, 255, 0.2) !important;
    --active-menu-background: #0e0e23 !important;
}

.dark-theme .msl-active-up~.msl-options {
    background: var(--dark-theme) !important;
}

.dark-theme .msl-option:focus,
.dark-theme .msl-options .msl-option:hover {
    background: rgba(255, 255, 255, 0.1) !important;
}

.dark-theme .msl-option-active {
    background: rgba(255, 255, 255, 0.1) !important;
    color: var(--dark-color) !important;
}

.dark-theme .msl-chip,
.dark-theme .msl-chip-delete,
.dark-theme .msl-arrow-btn:hover,
.dark-theme .msl-clear-btn:hover {
    background: rgba(255, 255, 255, 0.1) !important;
}

.dark-theme .form-check-input {
    background-color: transparent;
    border: 1px solid var(--dark-border);
}

.dark-theme ol.progtrckr li.progtrckr-doing,
.dark-theme ol.progtrckr li.progtrckr-todo {
    border-bottom: 4px solid var(--dark-border);
    color: var(--dark-color);
}

.dark-theme ol.progtrckr li.progtrckr-todo:before {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme #checkoutsteps .item {
    border-bottom-color: var(--dark-border) !important;
}

.dark-theme .ql-toolbar.ql-snow {
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .ql-container.ql-snow {
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .ql-toolbar.ql-snow+.ql-container.ql-snow {
    border-top: 0px !important;
}

.dark-theme .MuiDropzoneArea-root {
    border-color: var(--dark-border) !important;
}

.dark-theme .MuiDropzoneArea-icon {
    color: var(--dark-color) !important;
}

.dark-theme .ql-snow.ql-toolbar .ql-picker-label {
    border-color: transparent;
}

.dark-theme .sc-llJcti {
    border: 1px solid var(--dark-border);
}

.dark-theme .rdt_TableHeadRow {
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .rdt_TableRow {
    border-bottom: 1px solid var(--dark-border) !important;
    border-left: 1px solid var(--dark-border) !important;
    border-right: 1px solid var(--dark-border) !important;
}

.dark-theme .jxflYm {
    border-top-width: 0 !important;
}

.dark-theme .bynhzm {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: var(--dark-color) !important;
}

.dark-theme .rdt_TableHeader:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-theme .rdt_TableHeader {
    background-color: var(--dark-theme) !important;
    color: var(--dark-color) !important;
}

.dark-theme .data-table-extensions>.data-table-extensions-filter>.filter-text {
    border: 1px solid var(--dark-border) !important;
}

.dark-theme textarea:focus,
.dark-theme input:focus,
.dark-theme textarea {
    color: var(--dark-color);
}

.dark-theme .accordion-item {
    background-color: var(--dark-theme);
    border-bottom: 1px solid var(--dark-border);
}

.dark-theme .accordion-body {
    border: 1px solid var(--dark-border);
    border-bottom: 0;
}

.dark-theme .accordion-button {
    color: var(--dark-color);
}

.dark-theme .accordion-item:not(:first-of-type) {
    border-top: 0;
}

.dark-theme .css-15v22id-MuiAccordionDetails-root {
    background-color: var(--dark-theme) !important;
    color: var(--dark-color) !important;
    border: 1px solid var(--dark-border);
}

.dark-theme .css-yw020d-MuiAccordionSummary-expandIconWrapper {
    color: #ffffff !important;
}

.dark-theme .MuiPaper-elevation0 .MuiButtonBase-root.MuiAccordionSummary-root {
    background-color: var(--dark-theme) !important;
}

.dark-theme .css-1c99szj-MuiRating-icon {
    color: rgba(255, 255, 255, 0.2);
}

.dark-theme .branch .deni-react-treeview-container {
    background-color: var(--dark-theme) !important;
}

.dark-theme .branch .deni-react-treeview-container.classic .deni-react-treeview-item-container.classic {
    color: var(--dark-color) !important;
}

.dark-theme .branch .deni-react-treeview-container.classic {
    border-color: var(--dark-border);
}

.dark-theme .intl-tel-input .country-list .divider {
    border-bottom: 1px solid var(--dark-border);
}

.dark-theme .css-1s2u09g-control {
    background-color: var(--dark-theme);
    border-color: var(--dark-border) !important;
}

.dark-theme .css-1s2u09g-control:hover {
    border-color: var(--dark-border);
}

.dark-theme .css-1okebmr-indicatorSeparator {
    background-color: var(--dark-border);
}

.dark-theme .css-1pahdxg-control {
    background-color: var(--dark-theme);
    border-color: var(--dark-border) !important;
}

.dark-theme .css-1pahdxg-control:hover {
    border-color: var(--dark-border) !important;
}

.dark-theme .css-14el2xx-placeholder {
    color: var(--dark-color) !important;
}

.dark-theme .MuiStepLabel-label {
    color: #ffffff !important;
}

.dark-theme .css-ahj2mt-MuiTypography-root {
    border: 1px solid var(--dark-border);
}

.dark-theme .MuiStepContent-root {
    border-left: 1px solid var(--dark-border) !important;
}

.dark-theme .MuiStepConnector-line {
    border-color: var(--dark-border) !important;
}

.dark-theme .MuiButton-root {
    color: var(--dark-color) !important;
}

.dark-theme .rdw-editor-toolbar {
    background-color: var(--dark-theme) !important;
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .rdw-option-wrapper,
.dark-theme .rdw-dropdown-wrapper {
    background-color: rgba(255, 255, 255, 0.5) !important;
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .rdw-editor-main {
    border: 1px solid var(--dark-border);
    border-top: 0;
}

.dark-theme .rdw-dropdown-optionwrapper {
    background-color: var(--dark-body) !important;
    border: 1px solid var(--dark-border) !important;
}

.dark-theme .rdw-dropdownoption-active,
.dark-theme .rdw-dropdownoption-highlighted {
    background-color: rgba(255, 255, 255, 0.5);
}

.dark-theme .rdw-dropdown-optionwrapper:hover,
.dark-theme .rdw-dropdown-wrapper:hover {
    box-shadow: none !important;
}

.dark-theme .rdw-embedded-modal,
.dark-theme .rdw-colorpicker-modal,
.dark-theme .rdw-emoji-modal,
.dark-theme .rdw-image-modal {
    background-color: var(--dark-body);
    box-shadow: none;
    border: 1px solid var(--dark-border);
}

.dark-theme .rdw-embedded-modal-link-input,
.dark-theme .rdw-embedded-modal-size-input {
    background-color: var(--dark-theme);
    border: 1px solid var(--dark-border);
}

.dark-theme #apexcharts-radialbarTrack-0 {
    stroke: rgba(255, 255, 255, 0.1);
}

.dark-theme .searchable__controls input {
    color: var(--dark-color) !important;
}

.dark-theme #vmap2 text,
.dark-theme #vmap3 text {
    fill: #878787 !important;
}

.dark-theme .slick-arrow.slick-next::before,
.dark-theme .slick-arrow.slick-prev::before {
    color: #ffffff;
}

.dark-theme .slick-arrow.slick-next,
.dark-theme .slick-arrow.slick-prev {
    background: transparent !important;
}

.dark-theme .css-1fjvggn-MuiPaper-root-MuiAccordion-root {
    border: 1px solid var(--dark-border);
}

.dark-theme .css-ta84vm-MuiAccordionDetails-root {
    border-top: 1px solid var(--dark-border);
}

.dark-theme .fc-event.fc-h-event {
    border-color: var(--dark-border) !important;
}

.dark-theme .swal2-popup {
    background-color: var(--dark-theme);
    box-shadow: 0 0.5rem 1rem rgba(162, 169, 204, 0.15) !important;
}

.dark-theme .swal2-html-container,
.dark-theme .swal2-title {
    color: var(--dark-color);
}

.dark-theme .notification-time-date {
    color: var(--dark-color);
}

.dark-theme .react-datepicker__time-container .react-datepicker__time {
    background-color: var(--dark-theme);
}

.dark-theme .react-datepicker__time-container {
    border-left: 1px solid var(--dark-border) !important;
}

.dark-theme.rtl .react-datepicker__time-container {
    border-right: 1px solid var(--dark-border) !important;
    border-left: 0 !important;
}

.dark-theme .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-theme .css-1eoe787-MuiSlider-markLabel,
.dark-theme .css-yafthl-MuiSlider-markLabel {
    color: var(--dark-color);
}

.dark-theme .MuiChip-outlined {
    border: 1px solid var(--dark-border);
}

.dark-theme .MuiChip-root {
    color: var(--dark-color);
}

.dark-theme .MuiChip-deleteIcon,
.dark-theme .MuiChip-deleteIcon:hover {
    color: rgba(255, 255, 255, 0.1);
}

.dark-theme .cnZJOg {
    background-color: var(--dark-theme) !important;
    border-color: var(--dark-border) !important;
    color: var(--dark-color) !important;
}

.dark-theme .css-109onse-indicatorSeparator {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-theme .css-6j8wv5-Input,
.dark-theme .css-qc6sy-singleValue,
.dark-theme .css-1pndypt-Input {
    color: var(--dark-color) !important;
}

.dark-theme .btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.dark-theme .c3 path,
.dark-theme .c3 line {
    stroke: rgba(255, 255, 255, 0.3);
}

.dark-theme .chartsh.c3 g.tick text tspan,
.dark-theme .chartsh.c3 text {
    fill: var(--dark-color);
}

.dark-theme .apexcharts-tooltip.apexcharts-theme-light {
    border: 1px solid var(--dark-border);
    background: var(--dark-theme);
}

.dark-theme .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: var(--dark-theme);
    border-bottom: 1px solid var(--dark-border);
}

.dark-theme .go3450369076 {
    background-color: transparent !important;
}

.dark-theme .css-83ijpv-MuiTypography-root {
    color: var(--dark-color) !important;
}

.dark-theme .css-1eomvmf-MuiButtonBase-root-MuiIconButton-root,
.dark-theme .css-bhp9pd-MuiPaper-root-MuiCard-root {
    color: var(--dark-color) !important;
}

.dark-theme .nvd3 text {
    fill: var(--dark-color) !important;
}

.dark-theme .rdt_TableBody .rdt_TableRow,
.dark-theme .rdt_TableHeadRow,
.dark-theme .rdt_Pagination {
    background-color: var(--dark-theme) !important;
    color: var(--dark-color) !important;
}

.dark-theme .rdt_TableBody .rdt_TableRow:hover,
.dark-theme .rdt_TableHeadRow:hover,
.dark-theme .rdt_Pagination:hover {
    outline: 0px solid var(--dark-border) !important;
}

.dark-theme #pagination-first-page,
.dark-theme #pagination-previous-page {
    fill: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
}

.dark-theme #pagination-last-page,
.dark-theme #pagination-next-page {
    fill: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
}

.dark-theme .tooltip-info.bs-tooltip-left .arrow::before,
.dark-theme .tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    border-left-color: #01b8ff !important;
}

.dark-theme .tooltip-danger.bs-tooltip-right .arrow::before,
.dark-theme .tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    border-right-color: #f16d75 !important;
}

.dark-theme .tooltip-info.bs-tooltip-left .tooltip-arrow::before,
.dark-theme .tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color: #01b8ff !important;
}

.dark-theme .tooltip-danger.bs-tooltip-right .tooltip-arrow::before,
.dark-theme .tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: #f16d75 !important;
}

.dark-theme .dropzone {
    background-color: var(--dark-theme) !important;
    color: var(--dark-color) !important;
    border-color: var(--dark-border) !important;
}

.dark-theme .Select2__option {
    background-color: var(--dark-theme);
    border-left: 1px solid var(--dark-border);
    border-right: 1px solid var(--dark-border);
    border-bottom: 1px solid var(--dark-border);
}

.dark-theme .Select2__option:first-child {
    border-top: 1px solid var(--dark-border);
    border-radius: 3px 3px 0 0;
}

.dark-theme .Select2__option:last-child {
    border-radius: 0 0 3px 3px;
    border-bottom: 1px solid var(--dark-border);
}

.dark-theme .Select2__option:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .Select2__menu {
    background-color: var(--dark-theme) !important;
}

.dark-theme .multi-select .dropdown-content {
    background-color: var(--dark-theme);
}

.dark-theme .multi-select .dropdown-content div {
    background-color: var(--dark-theme);
}

.dark-theme .multi-select .dropdown-content div label:hover,
.dark-theme .multi-select .dropdown-content div label.selected {
    background-color: rgba(255, 255, 255, 0.1);
}

body.rtl {
    text-align: right;
    direction: rtl;
}

.rtl {
    /* Closed Menu Start*/
    /* Closed Menu end*/
    /* Icon-Text Menu Start*/
}

.rtl dd {
    margin-left: auto;
    margin-right: 0;
}

.rtl caption {
    text-align: right;
}

.rtl .list-inline-item:not(:last-child) {
    margin-right: 0;
    margin-left: 0.5rem;
}

.rtl .alert-dismissible {
    padding-right: 1.25rem;
    padding-left: 3.8125rem;
}

.rtl .alert-dismissible .close {
    right: auto;
    left: 0;
}

.rtl .breadcrumb-item+.breadcrumb-item {
    padding-left: 0;
    padding-right: 0.5rem;
}

.rtl .breadcrumb-item+.breadcrumb-item::before {
    padding-right: 0;
    padding-left: 0.5rem;
}

.rtl .breadcrumb-5 a {
    float: right;
}

.rtl .breadcrumb-5 a:first-child {
    padding-left: 10px;
    padding-right: 46px;
    border-radius: 0 5px 5px 0;
}

.rtl .breadcrumb-5 a:first-child:before {
    left: 0;
    right: 14px;
}

.rtl .breadcrumb-5 a:last-child {
    padding-right: 39px;
    padding-left: 20px;
}

.rtl .breadcrumb-5 a:after {
    right: auto;
    left: -18px;
    transform: scale(0.707) rotate(225deg);
}

.rtl .breadcrumb-4 ol,
.rtl .breadcrumb-4 li {
    text-align: left;
}

.rtl .btn-group>.btn:not(:first-child),
.rtl .sp-container .btn-group>button:not(:first-child) {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .btn-group>.btn-group:not(:first-child) {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .sp-container .btn-group>button:not(:last-child):not(.dropdown-toggle),
.rtl .btn-group>.btn-group:not(:last-child)>.btn,
.rtl .sp-container .btn-group>.btn-group:not(:last-child)>button {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .btn-group>.btn:not(:first-child),
.rtl .sp-container .btn-group>button:not(:first-child),
.rtl .btn-group>.btn-group:not(:first-child)>.btn,
.rtl .sp-container .btn-group>.btn-group:not(:first-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.rtl .btn-with-icon i {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .btn-icon-list .btn+.btn,
.rtl .btn-icon-list .sp-container button+.btn {
    margin-left: 0;
    margin-right: 5px;
}

.rtl .sp-container .btn-icon-list button+.btn,
.rtl .btn-icon-list .sp-container .btn+button,
.rtl .sp-container .btn-icon-list .btn+button,
.rtl .btn-icon-list .sp-container button+button,
.rtl .sp-container .btn-icon-list button+button {
    margin-left: 0;
    margin-right: 5px;
}

.rtl .btn-list>.btn:not(:last-child),
.rtl .btn-list>.dropdown:not(:last-child) {
    margin-right: 0;
    margin-left: 0.5rem;
}

.rtl .card-link+.card-link {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

@media (min-width: 576px) {
    .rtl .card-group>.card+.card {
        margin-left: auto;
        margin-right: 0;
        border-left: 1px solid #e8e8f7;
        border-right: 0;
    }

    .rtl .card-group>.card:not(:last-child) {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .rtl .card-group>.card:not(:last-child) .card-img-top,
    .rtl .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 3px;
        border-top-left-radius: 0;
    }

    .rtl .card-group>.card:not(:last-child) .card-img-bottom,
    .rtl .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }

    .rtl .card-group>.card:not(:first-child) {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .rtl .card-group>.card:not(:first-child) .card-img-top,
    .rtl .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .rtl .card-group>.card:not(:first-child) .card-img-bottom,
    .rtl .card-group>.card:not(:first-child) .card-footer {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 3px;
    }
}

.rtl .card-item .card-item-icon {
    right: auto;
    left: 20px;
}

.rtl .card-options {
    margin-right: auto;
    margin-left: -0.5rem;
}

.rtl .card-options a:not(.btn) {
    margin-left: auto;
    margin-right: 0;
}

.rtl .card-blog-overlay:before,
.rtl .card-blog-overlay1:before,
.rtl .card-blog-overlay2:before {
    right: auto;
    left: 0;
}

@media (max-width: 575px) {
    .rtl .card-box img {
        left: auto !important;
        right: -14px !important;
    }
}

.rtl .carousel-item {
    float: right;
    margin-right: auto;
    margin-left: -100%;
}

.rtl .carousel-control-prev {
    left: auto;
    right: 0;
    transform: rotate(180deg);
}

.rtl .carousel-control-next {
    right: auto;
    left: 0;
    transform: rotate(180deg);
}

.rtl .carousel-indicators {
    padding-left: inherit;
    padding-right: 0;
}

.rtl #thumbcarousel .carousel-control-prev {
    left: auto;
    right: 0;
}

.rtl #thumbcarousel .carousel-control-next {
    right: auto;
    left: 0;
}

.rtl .owl-nav .owl-prev {
    right: -25px;
    left: -1.5em;
}

.rtl .owl-nav .owl-next {
    right: auto;
    left: -25px;
}

.rtl .dropdown-toggle:empty::after {
    margin-left: auto;
    margin-right: 0;
}

.rtl .dropdown-menu-left {
    left: auto;
    right: 0;
}

.rtl .dropdown-menu-right {
    left: 0;
    right: auto;
}

@media (min-width: 576px) {
    .rtl .dropdown-menu-sm-left {
        left: auto;
        right: 0;
    }

    .rtl .dropdown-menu-sm-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 768px) {
    .rtl .dropdown-menu-md-left {
        left: auto;
        right: 0;
    }

    .rtl .dropdown-menu-md-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 992px) {
    .rtl .dropdown-menu-lg-left {
        left: auto;
        right: 0;
    }

    .rtl .dropdown-menu-lg-right {
        left: 0;
        right: auto;
    }
}

@media (min-width: 1200px) {
    .rtl .dropdown-menu-xl-left {
        left: auto;
        right: 0;
    }

    .rtl .dropdown-menu-xl-right {
        left: 0;
        right: auto;
    }
}

.rtl .dropup .dropdown-toggle::after {
    margin-left: auto;
    margin-right: 0.255em;
}

.rtl .dropup .dropdown-toggle:empty::after {
    margin-left: auto;
    margin-right: 0;
}

.rtl .dropright .dropdown-menu {
    left: auto;
    right: 100%;
    margin-left: auto;
    margin-right: 0.125rem;
}

.rtl .dropright .dropdown-toggle::after {
    margin-left: auto;
    margin-right: 0.255em;
    border-right: 0.3em solid;
    border-left: 0;
}

.rtl .dropright .dropdown-toggle:empty::after {
    margin-left: auto;
    margin-right: 0;
}

.rtl .dropleft .dropdown-menu {
    left: 100%;
    right: auto;
    margin-right: auto;
    margin-left: 0.125rem;
}

.rtl .dropleft .dropdown-toggle::after {
    margin-left: auto;
    margin-right: 0.255em;
}

.rtl .dropleft .dropdown-toggle::before {
    margin-right: auto;
    margin-left: 0.255em;
    border-right: 0;
    border-left: 0.3em solid;
}

.rtl .dropleft .dropdown-toggle:empty::after {
    margin-left: auto;
    margin-right: 0;
}

.rtl .dropdown-menu .main-header-center .search-btn {
    padding: 0.375rem 0.7rem 0.4rem 0.85rem;
}

.rtl .dropdown-menu .main-form-search .btn,
.rtl .dropdown-menu .main-form-search .sp-container button {
    padding: 0.375rem 0.7rem 0.4rem 0.85rem;
}

.rtl .dropdown-menu .sp-container .main-form-search button {
    padding: 0.375rem 0.7rem 0.4rem 0.85rem;
}

.rtl .dropdown-toggle-split::after,
.rtl .dropup .dropdown-toggle-split::after,
.rtl .dropright .dropdown-toggle-split::after {
    margin-left: auto;
    margin-right: 0;
}

.rtl .dropleft .dropdown-toggle-split::before {
    margin-right: auto;
    margin-left: 0;
}

.rtl .dropdown-menu {
    float: right;
    text-align: right;
}

.rtl .dropdown-menu[data-popper-placement^=top],
.rtl .dropdown-menu[data-popper-placement^=right],
.rtl .dropdown-menu[data-popper-placement^=bottom],
.rtl .dropdown-menu[data-popper-placement^=left] {
    right: 0;
    left: auto;
}

.rtl .form-switch {
    padding-left: 2.5em;
    padding-right: inherit;
}

.rtl .form-check {
    padding-left: inherit;
    padding-right: 1.25rem;
}

.rtl .form-check-input {
    margin-left: auto;
    margin-right: -1.25rem;
}

.rtl .form-check-inline {
    padding-left: inherit;
    padding-right: 0;
    margin-right: auto;
    margin-left: 0.75rem;
}

.rtl .form-check-inline .form-check-input {
    margin-right: 0;
    margin-left: 0.3125rem;
}

@media (min-width: 576px) {
    .rtl .form-inline .form-check {
        padding-left: inherit;
        padding-right: 0;
    }

    .rtl .form-inline .form-check-input {
        margin-right: 0;
        margin-left: 0.25rem;
    }
}

.rtl .file-browser .btn-default {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
}

.rtl .was-validated .form-control:valid,
.rtl .form-control.is-valid,
.rtl input.form-control.parsley-success,
.rtl textarea.form-control.parsley-success {
    padding-right: 0.75rem;
    padding-left: calc(1.5em + 0.75rem);
    background-position: center left calc(0.375em + 0.1875rem);
}

.rtl .was-validated textarea.form-control:valid {
    padding-right: 0.75rem;
    padding-left: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
}

.rtl textarea.form-control.is-valid,
.rtl textarea.form-control.parsley-success {
    padding-right: 0.75rem;
    padding-left: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
}

.rtl .was-validated .custom-select:valid,
.rtl .custom-select.is-valid,
.rtl input.custom-select.parsley-success,
.rtl textarea.custom-select.parsley-success {
    padding-right: 0.75rem;
    padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
}

.rtl .was-validated .form-control:invalid,
.rtl .form-control.is-invalid,
.rtl input.form-control.parsley-error,
.rtl textarea.form-control.parsley-error {
    padding-right: 0.75rem;
    padding-left: calc(1.5em + 0.75rem);
    background-position: center left calc(0.375em + 0.1875rem);
}

.rtl .was-validated textarea.form-control:invalid {
    padding-right: 0.75rem;
    padding-left: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
}

.rtl textarea.form-control.is-invalid,
.rtl textarea.form-control.parsley-error {
    padding-right: 0.75rem;
    padding-left: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) left calc(0.375em + 0.1875rem);
}

.rtl .was-validated .custom-select:invalid,
.rtl .custom-select.is-invalid,
.rtl input.custom-select.parsley-error,
.rtl textarea.custom-select.parsley-error {
    padding-right: 0.75rem;
    padding-left: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
}

.rtl .input-group>.form-control+.form-control,
.rtl .input-group>.form-control+.custom-select,
.rtl .input-group>.form-control+.custom-file {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group>.form-control-plaintext+.form-control,
.rtl .input-group>.form-control-plaintext+.custom-select,
.rtl .input-group>.form-control-plaintext+.custom-file {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group>.custom-select+.form-control,
.rtl .input-group>.custom-select+.custom-select,
.rtl .input-group>.custom-select+.custom-file {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group>.custom-file+.form-control,
.rtl .input-group>.custom-file+.custom-select,
.rtl .input-group>.custom-file+.custom-file {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group>.form-control:not(:last-child),
.rtl .input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.form-control:not(:first-child),
.rtl .input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group>.custom-file:not(:last-child) .custom-file-label {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group-prepend .btn+.btn,
.rtl .input-group-prepend .sp-container button+.btn {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .sp-container .input-group-prepend button+.btn,
.rtl .input-group-prepend .sp-container .btn+button,
.rtl .sp-container .input-group-prepend .btn+button,
.rtl .input-group-prepend .sp-container button+button,
.rtl .sp-container .input-group-prepend button+button {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group-prepend .btn+.input-group-text,
.rtl .input-group-prepend .sp-container button+.input-group-text {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .sp-container .input-group-prepend button+.input-group-text {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group-prepend .input-group-text+.input-group-text,
.rtl .input-group-prepend .input-group-text+.btn {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group-prepend .sp-container .input-group-text+button {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .sp-container .input-group-prepend .input-group-text+button {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group-append .btn+.btn,
.rtl .input-group-append .sp-container button+.btn {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .sp-container .input-group-append button+.btn,
.rtl .input-group-append .sp-container .btn+button,
.rtl .sp-container .input-group-append .btn+button,
.rtl .input-group-append .sp-container button+button,
.rtl .sp-container .input-group-append button+button {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group-append .btn+.input-group-text,
.rtl .input-group-append .sp-container button+.input-group-text {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .sp-container .input-group-append button+.input-group-text {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group-append .input-group-text+.input-group-text,
.rtl .input-group-append .input-group-text+.btn {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group-append .sp-container .input-group-text+button {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .sp-container .input-group-append .input-group-text+button {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group-prepend {
    margin-right: auto;
    margin-left: -1px;
}

.rtl .input-group-append {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .input-group-lg>.custom-select,
.rtl .input-group-sm>.custom-select {
    padding-right: inherit;
    padding-left: 1.75rem;
}

.rtl .input-group>.input-group-prepend>.btn,
.rtl .sp-container .input-group>.input-group-prepend>button {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.input-group-prepend>.input-group-text,
.rtl .input-group>.input-group-append:not(:last-child)>.btn {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .sp-container .input-group>.input-group-append:not(:last-child)>button {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.input-group-append:not(:last-child)>.input-group-text,
.rtl .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group>.input-group-append>.btn {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .sp-container .input-group>.input-group-append>button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group>.input-group-append>.input-group-text,
.rtl .input-group>.input-group-prepend:not(:first-child)>.btn {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .sp-container .input-group>.input-group-prepend:not(:first-child)>button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.rtl .input-group>.input-group-prepend:first-child>.btn:not(:first-child) {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child),
.rtl .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .close {
    float: left;
}

.rtl .modal,
.rtl .modal-backdrop {
    left: auto;
    right: 0;
}

.rtl .modal-header .close {
    margin: -1rem auto -1rem -1rem;
}

.rtl .modal-footer> :not(:first-child) {
    margin-left: inherit;
    margin-right: 0.25rem;
}

.rtl .modal-footer> :not(:last-child) {
    margin-right: inherit;
    margin-left: 0.25rem;
}

@media (min-width: 768px) {
    .rtl .main-nav .nav-link+.nav-link {
        padding-left: inherit;
        padding-right: 15px;
        margin-left: 0;
        margin-right: 15px;
    }
}

.rtl .main-nav-column .nav-link i {
    margin-right: 0;
    margin-left: 12px;
}

.rtl .main-nav-column .nav-link span {
    margin-left: 0;
    margin-right: auto;
}

.rtl .main-nav-column .nav-link.active::before {
    left: auto;
    right: -28px;
}

@media (min-width: 769px) {
    .rtl .main-nav-line .nav-link+.nav-link {
        margin-left: 0;
        margin-right: 30px;
    }
}

@media (max-width: 768px) {
    .rtl .main-nav-line .nav-link+.nav-link {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-nav-line-chat {
        padding-left: inherit;
        padding-right: 0;
    }

    .rtl .nav.main-nav-line-chat {
        padding-left: inherit;
        padding-right: 20px;
    }
}

.rtl .pagination {
    padding-left: 0;
    padding-right: 7px;
}

.rtl .pagination-circled .page-item+.page-item {
    margin-left: 0;
    margin-right: 5px;
}

.rtl .pagination-radius li a,
.rtl .pagination-radius li span {
    margin-left: 0;
    margin-right: 4px;
}

.rtl .popover {
    left: auto;
    right: 0;
    text-align: right;
}

.rtl .popover-head-primary.bs-popover-left .arrow,
.rtl .popover-head-primary.bs-popover-auto[data-popper-placement^=left] .arrow {
    right: auto;
    left: -7px;
}

.rtl .popover-head-primary.bs-popover-right .arrow,
.rtl .popover-head-primary.bs-popover-auto[data-popper-placement^=right] .arrow {
    left: auto;
    right: -7px;
}

.rtl .popover-head-secondary.bs-popover-left .arrow,
.rtl .popover-head-secondary.bs-popover-auto[data-popper-placement^=left] .arrow {
    right: auto;
    left: -7px;
}

.rtl .popover-head-secondary.bs-popover-right .arrow,
.rtl .popover-head-secondary.bs-popover-auto[data-popper-placement^=right] .arrow {
    left: auto;
    right: -7px;
}

.rtl .popover-static-demo .bs-popover-top .arrow,
.rtl .popover-static-demo .bs-popover-auto[data-popper-placement^=top] .arrow,
.rtl .popover-static-demo .bs-popover-bottom .arrow,
.rtl .popover-static-demo .bs-popover-auto[data-popper-placement^=bottom] .arrow {
    left: auto;
    right: 50%;
    margin-left: auto;
    margin-right: -5px;
}

.rtl .bs-popover-right,
.rtl .bs-popover-auto[data-popper-placement^=right] {
    margin-left: auto;
    margin-right: 0.5rem;
}

.rtl .bs-popover-right>.arrow,
.rtl .bs-popover-auto[data-popper-placement^=right]>.arrow {
    left: auto;
    right: calc((0.5rem + 1px) * -1);
}

.rtl .bs-popover-right>.arrow::before,
.rtl .bs-popover-auto[data-popper-placement^=right]>.arrow::before {
    left: auto;
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-right-color: transparent;
    border-left-color: #eaedf7;
}

.rtl .bs-popover-right>.arrow::after,
.rtl .bs-popover-auto[data-popper-placement^=right]>.arrow::after {
    left: auto;
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-right-color: transparent;
    border-left-color: #ffffff;
}

.rtl .bs-popover-bottom .popover-header::before,
.rtl .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    left: auto;
    right: 50%;
    margin-left: auto;
    margin-right: -0.5rem;
}

.rtl .bs-popover-left,
.rtl .bs-popover-auto[data-popper-placement^=left] {
    margin-right: auto;
    margin-left: 0.5rem;
}

.rtl .bs-popover-left>.arrow,
.rtl .bs-popover-auto[data-popper-placement^=left]>.arrow {
    right: auto;
    left: calc((0.5rem + 1px) * -1);
}

.rtl .bs-popover-left>.arrow::before,
.rtl .bs-popover-auto[data-popper-placement^=left]>.arrow::before {
    right: auto;
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-left-color: transparent;
    border-right-color: #eaedf7;
}

.rtl .bs-popover-left>.arrow::after,
.rtl .bs-popover-auto[data-popper-placement^=left]>.arrow::after {
    right: auto;
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-left-color: transparent;
    border-right-color: #ffffff;
}

.rtl .dataTables_wrapper .dataTables_filter input {
    margin-left: auto;
    margin-right: 0;
}

.rtl .table.dataTable th,
.rtl .table.dataTable td {
    border-left: 0;
    border-right: 1px solid #e8e8f7;
}

.rtl div.dataTables_wrapper div.dataTables_length select {
    margin-right: auto;
    margin-left: 10px;
}

.rtl .tooltip {
    text-align: right;
}

.rtl .tooltip-static-demo .bs-tooltip-top .arrow,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=top] .arrow,
.rtl .tooltip-static-demo .bs-tooltip-bottom .arrow,
.rtl .tooltip-static-demo .bs-tooltip-auto[data-popper-placement^=bottom] .arrow {
    right: 50%;
    left: auto;
    margin-right: -2px;
    margin-left: auto;
}

@media (max-width: 991.98px) {
    .rtl .main-content-left-show .main-content-left {
        left: auto;
        right: 0;
        border-right: 0;
        border-left: 1px solid #e8e8f7;
    }
}

.rtl .main-content-right {
    padding-left: inherit;
    padding-right: 25px;
    margin-right: 25px;
    margin-left: auto;
}

@media (max-width: 991.98px) {
    .rtl .main-header-menu-show .main-header-menu {
        border-right: 0;
        border-left: 1px solid rgba(28, 39, 60, 0.08);
    }
}

.rtl .main-logo i {
    margin-right: auto;
    margin-left: 8px;
}

.rtl .main-header-menu-icon {
    margin-right: auto;
    margin-left: 10px;
}

.rtl .main-header-menu-icon span::before,
.rtl .main-header-menu-icon span::after {
    left: auto;
    right: 0;
}

@media (min-width: 992px) {
    .rtl .main-header-menu-icon {
        margin-right: auto;
        margin-left: 25px;
    }
}

.rtl .main-header-menu {
    left: auto;
    right: 0;
}

.rtl .main-header-menu .nav-item::before {
    margin-left: auto;
    margin-right: 25px;
    border-left: 0;
    border-right: 2px solid rgba(28, 39, 60, 0.12);
}

.rtl .main-header-menu .nav-item>.nav-link.with-sub::after {
    margin-right: auto;
    margin-left: 0;
}

.rtl .main-header-menu .nav-item>.nav-link .typcn {
    margin-right: auto;
    margin-left: 15px;
}

.rtl .main-header-menu .nav-item.active>.nav-link::before {
    left: auto;
    right: -20px;
}

@media (min-width: 992px) {
    .rtl .main-header-menu {
        border-right: 1px solid #e8e8f7;
        border-left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-header-menu .nav-item+.nav-item {
        margin-left: auto;
        margin-right: 30px;
    }
}

@media (min-width: 992px) {
    .rtl .main-header-menu .nav-item>.nav-link.with-sub::after {
        margin-left: auto;
        margin-right: 5px;
    }
}

@media (min-width: 992px) {
    .rtl .main-header-menu .nav-item>.nav-link .typcn {
        margin-right: auto;
        margin-left: 7px;
    }
}

.rtl .main-header-center {
    margin: 0 0.8rem 0 0;
}

.rtl .main-header-center .search-btn {
    padding: 0.375rem 0.7rem 0.4rem 0.85rem;
}

.rtl .main-header-right .btn-social+.btn-social {
    margin-right: 10px;
    margin-left: auto;
}

.rtl .main-header-right .btn-buy {
    margin-left: auto;
    margin-right: 20px;
}

.rtl .main-header-search-link {
    margin-right: auto;
    margin-left: 15px;
}

.rtl .main-header-message>a.new::before {
    right: auto;
    left: 2px;
}

.rtl .main-header-notification>a.new::before {
    right: auto;
    left: 2px;
}

.rtl .main-header-notification .dropdown-menu {
    left: -10px !important;
    right: auto !important;
}

.rtl .main-header-message .dropdown-menu {
    left: -10px !important;
    right: auto;
}

.rtl .main-menu-sub {
    margin-left: auto;
    margin-right: 32px;
}

@media (min-width: 992px) {
    .rtl .main-menu-sub {
        margin-left: auto;
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-menu-sub-mega .container>div+div {
        border-right: 1px solid rgba(28, 39, 60, 0.05);
        border-left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-menu-sub-mega .nav+.nav {
        margin-left: auto;
        margin-right: 20px;
    }
}

@media (min-width: 1200px) {
    .rtl .main-menu-sub-mega .nav+.nav {
        margin-left: auto;
        margin-right: 25px;
    }
}

.rtl .main-header-notification.show .dropdown-menu::before,
.rtl .main-header-message.show .dropdown-menu::before,
.rtl .main-profile-menu.show .dropdown-menu::before {
    right: auto;
    left: 28px;
}

.rtl .header-search.show .dropdown-menu::before {
    right: auto;
    left: 28px;
    border-right: 1px solid #f0f2f8;
    border-left: 0;
}

.rtl .header-search .dropdown-menu {
    left: -10px;
    right: auto;
}

.rtl .main-form-search .form-control {
    padding-right: 2.6rem;
    padding-left: inherit;
}

.rtl .main-form-search .btn,
.rtl .main-form-search .sp-container button {
    left: auto;
    right: 8px;
    padding-left: inherit;
    padding-right: 16px;
}

.rtl .sp-container .main-form-search button {
    left: auto;
    right: 8px;
    padding-left: inherit;
    padding-right: 16px;
}

.rtl .main-message-list .media {
    padding: 10px 15px 0px 10px;
}

.rtl .main-header-flags .dropdown-menu {
    left: auto;
    right: -10px;
}

.rtl .main-header-flags.show .dropdown-menu::before {
    left: auto;
    right: 28px;
    border-left: 0;
    border-right: 1px solid #f0f2f8;
}

.rtl .main-flags-list .media {
    padding: 10px 15px 0px 10px;
}

.rtl .hor-header.hor-top-header .nav-link-badge {
    left: auto;
    right: 11px;
}

@media (max-width: 991px) {
    .rtl .hor-header.hor-top-header .main-logo {
        left: auto;
        right: 0px;
        margin-right: 0;
        margin-left: auto;
    }
}

.rtl .main-header-right .nav-link .badge {
    right: auto;
    left: 4px;
}

.rtl .sidebar-left {
    right: 0;
    left: auto;
}

.rtl .sidebar-right,
.rtl .sidebar-profile {
    right: auto;
    left: 0;
}

.rtl .row .sidebar.sidebar-left.sidebar-xs-show {
    left: auto;
    right: 0;
}

.rtl .row .sidebar.sidebar-right.sidebar-xs-show,
.rtl .row .sidebar.sidebar-profile.sidebar-xs-show {
    right: auto;
    left: 0;
}

@media (min-width: 768px) {
    .rtl .row .sidebar.sidebar-left.sidebar-sm-show {
        left: auto;
        right: 0;
    }

    .rtl .row .sidebar.sidebar-right.sidebar-sm-show,
    .rtl .row .sidebar.sidebar-profile.sidebar-sm-show {
        right: auto;
        left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .row .sidebar.sidebar-left.sidebar-md-show {
        left: auto;
        right: 0;
    }

    .rtl .row .sidebar.sidebar-right.sidebar-md-show,
    .rtl .row .sidebar.sidebar-profile.sidebar-md-show {
        right: auto;
        left: 0;
    }
}

@media (min-width: 1170px) {
    .rtl .row .sidebar.sidebar-left.sidebar-lg-show {
        left: auto;
        right: 0;
    }

    .rtl .row .sidebar.sidebar-right.sidebar-lg-show,
    .rtl .row .sidebar.sidebar-profile.sidebar-lg-show {
        right: auto;
        left: 0;
    }
}

.rtl .sidebar-icon {
    right: auto;
    left: 25px;
}

.rtl .main-profile-menu .dropdown-menu {
    right: auto;
    left: -10px;
}

.rtl .main-profile-menu .dropdown-item i {
    margin-right: 0;
    margin-left: 10px;
}

.rtl .main-iconbar {
    left: auto;
    right: 0;
    border-right: 0;
    border-left: 1px solid #e8e8f7;
}

.rtl .main-iconbar-logo::after {
    left: auto;
    right: 20px;
}

.rtl .main-iconbar-aside {
    border-right: 0;
    border-left: 1px solid #e8e8f7;
    left: auto;
    right: 64px;
}

@media (min-width: 1200px) {
    .rtl .main-iconbar-aside {
        border-right: 1px solid #e8e8f7;
        border-left: 0;
    }
}

@media (min-width: 1200px) {
    .rtl .main-iconbar-aside.show+.main-content {
        margin-right: 294px;
        margin-left: auto;
    }
}

.rtl .main-iconbar-header {
    padding-right: 0;
    padding-left: inherit;
}

@media (min-width: 1200px) {
    .rtl .main-iconbar-toggle-menu::before {
        left: auto;
        right: -1px;
        border-left: 0;
        border-right: 2px solid #000000;
    }
}

.rtl .main-iconbar-body .nav-link i {
    margin-right: auto;
    margin-left: 10px;
}

.rtl .main-iconbar-body .nav-link.with-sub::after {
    margin-left: 0;
    margin-right: auto;
}

.rtl .main-iconbar-body .nav-sub .nav-sub-item {
    margin-right: 15px;
    margin-left: auto;
}

@media (min-width: 992px) {
    .rtl .main-iconbar-body .nav-link.with-sub::after {
        margin-right: 4px;
        margin-left: auto;
    }
}

.rtl .main-iconbar-aside-primary {
    border-right: 0;
    border-left: 1px solid #e8e8f7;
}

.rtl .main-iconbar-aside-primary .main-iconbar-body .nav {
    padding-right: 0;
    padding-left: inherit;
    border-left-width: 1px;
    border-right-width: 0;
}

@media (min-width: 992px) {
    .rtl .main-iconbar-aside-primary.show+.main-content {
        margin-left: auto;
        margin-right: 64px;
    }
}

.rtl .icons-list {
    margin: 0 0 -1px -1px;
}

@media (min-width: 992px) {
    .rtl.horizontalmenu .hor-menu .nav .nav-item {
        padding-right: 0;
        padding-left: 15px;
    }

    .rtl.horizontalmenu .hor-menu li.nav-item .nav-link {
        padding-right: inherit;
        padding-left: 10px;
    }

    .rtl.horizontalmenu .hor-menu .nav-link .hor-icon {
        margin-right: 10px;
        margin-left: 5px;
    }

    .rtl.horizontalmenu .main-navbar .nav-sub-item {
        margin-left: auto;
        margin-right: 0;
    }

    .rtl.horizontalmenu .menu-nav .nav-item ul.nav-sub::before {
        left: auto;
        right: 36%;
    }

    .rtl.horizontalmenu .hor-menu .menu-nav .nav-sub {
        left: auto;
        right: inherit;
    }

    .rtl .main-navbar .nav-sub-item>.sub-nav-sub {
        left: auto;
        right: 158px;
        padding: 0 12px 0 0;
    }

    .rtl .main-navbar .nav-sub-link {
        margin-left: auto;
        margin-right: 18px;
        padding-left: inherit;
        padding-right: 8px;
    }

    .rtl .main-navbar .nav-sub-link.sub-with-sub::after {
        margin-right: auto;
        margin-left: 3px;
    }
}

.rtl .ltr #slide-right {
    left: 20px;
    right: auto;
}

.rtl .ltr #slide-left {
    right: 20px;
    left: auto;
}

@media (max-width: 991.98px) {
    .rtl.horizontalmenu .main-body-1 .nav {
        padding: 0 15px 0 0;
    }

    .rtl.horizontalmenu .main-body-1 li.nav-item.active .nav-link {
        margin-left: auto;
        margin-right: 0;
        border-left: 0px;
    }

    .rtl.horizontalmenu .main-body-1 .nav-item.active .nav-link:before,
    .rtl.horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
        border-right: 0;
        border-left: 21px solid #0e0e23;
    }

    .rtl.horizontalmenu .main-body-1 .nav-item.active .nav-link:before,
    .rtl.horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
        right: auto;
        left: 0px;
        border-left-color: #0e0e23;
        border-right-color: transparent;
    }

    .rtl.horizontalmenu .main-body-1 .nav-item.active .nav-link .shape1,
    .rtl.horizontalmenu .main-body-1 .nav-item.active .nav-link .shape2 {
        right: auto;
        left: 0px;
    }

    .rtl.horizontalmenu .main-body-1 li.active .hor-icon {
        margin-right: 0;
        margin-left: 10px;
    }

    .rtl.horizontalmenu .hor-menu i.angle {
        right: auto;
        left: 20px;
    }

    .rtl.horizontalmenu .main-navbar .nav-item {
        padding-right: 0;
        padding-left: 0;
    }

    .rtl.horizontalmenu .main-body-1 .nav-link .hor-icon {
        margin-right: 0;
        margin-left: 10px;
    }

    .rtl.horizontalmenu .main-navbar .nav-link i {
        margin-right: auto;
        margin-left: 0;
    }

    .rtl.horizontalmenu .main-body-1 .nav-sub {
        margin-left: auto;
        margin-right: 1.2rem;
        margin-left: 0;
        margin-right: 1.2rem;
    }

    .rtl.horizontalmenu .main-body-1 .nav-sub .nav-sub-link:before {
        margin-right: 0;
        margin-left: 22px;
    }

    .rtl.horizontalmenu .hor-menu .nav-link:hover .sidemenu-label {
        margin-left: 0;
        margin-right: 5px;
    }

    .rtl.horizontalmenu .hor-menu .nav-item.show i.angle {
        right: auto;
        left: 20px;
    }
}

.rtl .side-menu .nav-link:hover .sidemenu-label {
    margin-left: auto;
    margin-right: 5px;
    transition: margin-right 0.45s ease;
}

.rtl .side-menu .sidemenu-label {
    transition: margin-right 0.45s ease;
}

.rtl .side-menu i.angle,
.rtl .side-menu .nav-item.show i.angle {
    right: auto;
    left: 20px;
}

.rtl .side-badge,
.rtl.main-sidebar-open.main-sidebar-hide .side-badge {
    right: auto;
    left: 18px;
}

@media (min-width: 992px) {
    .rtl.main-sidebar-hide .main-sidebar-sticky+.main-content {
        margin-left: auto;
        margin-right: 0;
    }
}

@media (max-width: 991.98px) {
    .rtl .main-sidebar {
        left: auto;
        right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-sidebar {
        border-right-width: 0;
        border-left-width: 1px;
    }
}

.rtl .main-sidebar-sticky {
    left: auto;
    right: 0;
}

.rtl .main-sidebar-body .nav-item.active .nav-link:before,
.rtl .main-sidebar-body .nav-item.active .nav-link:after {
    right: auto;
    left: 0px;
    border-left-color: #0e0e23;
    border-right-color: transparent;
}

.rtl .main-sidebar-body .nav-item.active .nav-link .shape1,
.rtl .main-sidebar-body .nav-item.active .nav-link .shape2 {
    right: auto;
    left: 0px;
}

.rtl .main-sidebar-body li.nav-item.active .nav-link {
    margin-left: auto;
    margin-right: 0;
    border-right: 1px solid transparent;
    border-left: 0px;
    border-radius: 0 30px 30px 0;
}

.rtl .main-sidebar-body .nav-item.active .nav-link:before,
.rtl .main-sidebar-body .nav-item.active .nav-link:after {
    border-right: 0;
    border-left: 21px solid #0e0e23;
}

@media (min-width: 992px) {
    .rtl .main-sidebar-sticky+.main-content {
        margin-left: auto;
        margin-right: 220px;
    }
}

@media (min-width: 1200px) {
    .rtl .main-sidebar-sticky+.main-content {
        margin-left: auto;
        margin-right: 240px;
    }
}

.rtl .main-sidebar-loggedin .media-body {
    margin-left: auto;
    margin-right: 12px;
}

.rtl.main-body .main-sidebar-body .nav {
    padding: 0 15px 0 0;
}

.rtl .nav-header {
    margin: 0 15px 0 0;
}

.rtl .main-sidebar-body .nav-link .sidemenu-icon {
    margin-right: 0;
    margin-left: 10px;
}

.rtl .main-sidebar-body .nav-sub {
    margin-left: auto;
    margin-right: 1.2rem;
}

.rtl .main-sidebar-body .nav-sub .nav-sub-link:before {
    margin-right: 0;
    margin-left: 22px;
}

.rtl .main-sidebar-body .nav-sub-item .nav-sub {
    padding: 0 12px 0 0;
    border-left-width: 0;
    border-right-width: 1px;
    margin-left: auto;
    margin-right: 0;
}

.rtl .main-sidebar-body .nav-sub-link.with-sub::after {
    margin-left: 0;
    margin-right: auto;
}

.rtl .main-sidebar-body li.active .sidemenu-icon {
    margin-right: 0;
    margin-left: 10px;
}

@media (min-width: 992px) {
    .rtl .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-left: 0;
        margin-right: 4px;
    }
}

@media (min-width: 992px) {
    .rtl.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active {
        margin-left: auto;
        margin-right: 0;
        border-right: 1px solid transparent;
        border-left: 0px;
    }

    .rtl.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon,
    .rtl.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
        margin-right: 0;
        margin-left: 10px;
    }

    .rtl.main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
        padding: 0 15px 0 0;
    }

    .rtl.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
        margin-right: 5px !important;
        margin-left: 0;
    }

    .rtl.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:before,
    .rtl.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:after {
        right: auto;
        left: 0px;
        border-left-color: #0e0e23;
        border-right-color: transparent;
    }
}

@media (min-width: 1200px) {
    .rtl .side-content {
        margin-left: 0;
        margin-right: 240px;
    }
}

@media (min-width: 992px) {
    .rtl .side-header .main-logo {
        margin-right: auto;
        margin-left: 0;
    }

    .rtl.main-sidebar-hide .side-header,
    .rtl.main-sidebar-hide .main-footer {
        padding-left: inherit;
        padding-right: 70px;
    }

    .rtl.main-body .side-header {
        left: auto;
        right: 0;
        padding-left: inherit;
        padding-right: 240px;
    }

    .rtl.main-body.main-sidebar-hide .side-header {
        padding-left: inherit;
        padding-right: 75px;
    }

    .rtl .main-content.side-content {
        margin-left: 0;
        margin-right: 240px;
    }
}

@media (max-width: 992px) and (min-width: 991px) {
    .rtl .main-header-menu-icon {
        margin-left: auto;
        margin-right: 15px;
    }
}

@media (max-width: 991px) {
    .rtl .main-header-menu-icon {
        margin-left: auto;
        margin-right: 15px;
    }

    .rtl .side-header .icon-logo {
        margin-left: auto;
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .rtl.main-sidebar-hide .side-menu {
        left: auto;
        right: 0;
    }

    .rtl.main-sidebar-hide .main-content.side-content {
        margin-left: 0;
        margin-right: 80px;
    }

    .rtl.main-sidebar-hide.main-sidebar-open .nav-header {
        margin: 0 15px 0 0;
    }

    .rtl.main-sidebar-hide .side-menu .main-logo .icon-logo {
        margin-left: auto;
        margin-right: 10px;
    }

    .rtl.main-sidebar-open .side-menu {
        left: auto;
        right: 0;
    }

    .rtl.main-sidebar-open .main-sidebar-body .side-menu .nav-link {
        margin-left: auto;
        margin-right: 10px;
    }

    .rtl.main-sidebar-open .side-menu .nav-item.show i.angle {
        right: auto;
        left: 20px;
    }

    .rtl.main-sidebar-open .side-menu .main-sidebar-body .nav-link i {
        margin-right: auto;
        margin-left: 5px;
    }
}

.rtl .main-header .select2-container .select2-selection--single .select2-selection__rEndered {
    padding-right: 17px;
    padding-left: 20px;
}

@media (min-width: 992px) {
    .rtl .main-sidebar-header .sidemenu-logo {
        border-right: 0;
        border-left: 1px solid var(--dark-border);
    }
}

@media screen and (min-width: 992px) {
    .rtl.closed-leftmenu.main-sidebar-hide .side-menu {
        left: auto !important;
        right: -240px !important;
    }

    .rtl.closed-leftmenu.main-sidebar-hide .main-content.side-content {
        margin-right: 0px;
        margin-left: auto;
    }

    .rtl.closed-leftmenu.main-body.main-sidebar-hide .side-header {
        padding-left: inherit;
        padding-right: 0;
    }
}

@media screen and (min-width: 992px) {
    .rtl.icontext-menu.main-sidebar-hide .main-content.side-content {
        margin-left: 0;
        margin-right: 100px;
    }

    .rtl.icontext-menu.main-sidebar-hide .side-header {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl.icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
        padding: 0 15px 0 0 !important;
    }

    .rtl.icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
        margin-right: 5px !important;
        margin-left: 10px !important;
    }
}

@media screen and (min-width: 992px) {
    .rtl.hover-submenu.main-sidebar-hide .side-menu {
        left: auto;
        right: 0;
    }

    .rtl.hover-submenu.main-sidebar-hide .side-header {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl.hover-submenu.main-sidebar-hide .main-content.side-content {
        margin-left: 0;
        margin-right: 100px;
    }

    .rtl.hover-submenu.main-sidebar-hide .nav-sub {
        left: auto;
        right: 80px;
    }

    .rtl.hover-submenu.main-sidebar-hide .side-menu {
        left: auto;
        right: 0;
    }

    .rtl.hover-submenu.main-sidebar-hide .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 12px 0 0;
        border-left-width: 0;
        border-right-width: 1px;
        margin-left: auto;
        margin-right: 0;
    }

    .rtl.hover-submenu.main-sidebar-hide .side-menu .nav-link:hover .sidemenu-label {
        margin-left: auto;
        margin-right: 0;
    }

    .rtl.hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 12px 0 0;
        border-left-width: 0;
        border-right-width: 1px;
        margin-left: auto;
        margin-right: 0;
    }

    .rtl.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        padding: 12px 15px 12px 0;
    }

    .rtl.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu {
        left: auto;
        right: 0;
    }

    .rtl.hover-submenu.main-sidebar-hide.main-sidebar-open .side-header {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl.hover-submenu.main-sidebar-hide.main-sidebar-open .main-content.side-content {
        margin-left: 0;
        margin-right: 100px;
    }
}

@media screen and (min-width: 992px) {
    .rtl.hover-submenu1.main-sidebar-hide .side-menu {
        left: auto;
        right: 0;
    }

    .rtl.hover-submenu1.main-sidebar-hide .side-header {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl.hover-submenu1.main-sidebar-hide .main-content.side-content {
        margin-left: 0;
        margin-right: 100px;
    }

    .rtl.hover-submenu1.main-sidebar-hide .nav-sub {
        left: auto;
        right: 80px;
    }

    .rtl.hover-submenu1.main-sidebar-hide .side-menu {
        left: auto;
        right: 0;
    }

    .rtl.hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 12px 0 0;
        border-left-width: 0;
        border-right-width: 1px;
        margin-left: auto;
        margin-right: 0;
    }

    .rtl.hover-submenu1.main-sidebar-hide .side-menu .nav-link:hover .sidemenu-label {
        margin-left: auto;
        margin-right: 0;
    }

    .rtl.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub-link {
        padding: 8px 12px 8px 0;
    }

    .rtl.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu-label1 {
        padding: 8px 12px 8px 0;
        text-align: right;
    }

    .rtl.hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 12px 0 0;
        border-left-width: 0;
        border-right-width: 1px;
        margin-left: auto;
        margin-right: 0;
    }

    .rtl.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu {
        left: auto;
        right: 0;
    }

    .rtl.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-header {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl.hover-submenu1.main-sidebar-hide.main-sidebar-open .main-content.side-content {
        margin-left: 0;
        margin-right: 100px;
    }
}

.rtl .ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
    right: auto;
    left: 17px;
    transform: rotate(180deg);
}

.rtl .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
    left: auto;
    right: 17px;
    transform: rotate(180deg);
}

.rtl .ui-datepicker .ui-datepicker-calendar td {
    text-align: left;
}

.rtl .ui-datepicker .ui-datepicker-calendar td:last-child {
    border-right: 1px solid #e8e8f7;
    border-left: 0;
}

.rtl .ui-datepicker-multi .ui-datepicker-group {
    padding-right: inherit;
    padding-left: 15px;
    float: right;
}

.rtl .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
    left: auto;
    right: 10px;
}

.rtl .ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
    right: auto;
    left: 10px;
}

.rtl .ui-datepicker-multi .ui-datepicker-group-last {
    padding-right: inherit;
    padding-left: 0;
}

.rtl .datetimepicker table th.prev span::before {
    left: auto;
    right: 0;
}

.rtl .datetimepicker table th.next span::before {
    right: auto;
    left: 0;
}

.rtl .main-datetimepicker>.datepicker_header a:nth-child(2)::before {
    left: auto;
    right: 0;
}

.rtl .main-datetimepicker>.datepicker_header a:last-child::before {
    right: auto;
    left: 0;
}

.rtl .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist {
    border-left: 0;
    border-right: 1px solid #e8e8f7;
}

.rtl .main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item {
    margin: 0 1px 0 0;
}

.rtl #jqvmap1_ca_pin {
    margin-left: auto;
    margin-right: -2%;
}

.rtl #jqvmap1_ct_pin {
    margin-left: auto;
    margin-right: -0.25%;
}

.rtl #jqvmap1_fl_pin {
    margin-left: auto;
    margin-right: 5%;
}

.rtl #jqvmap1_id_pin {
    margin-left: auto;
    margin-right: -1%;
}

.rtl #jqvmap1_ky_pin {
    margin-left: auto;
    margin-right: 2%;
}

.rtl #jqvmap1_la_pin {
    margin-left: auto;
    margin-right: -2%;
}

.rtl #jqvmap1_mi_pin {
    margin-left: auto;
    margin-right: 3%;
}

.rtl #jqvmap1_mn_pin {
    margin-left: auto;
    margin-right: -2%;
}

.rtl #jqvmap1_nh_pin {
    margin-left: auto;
    margin-right: -0.25%;
}

.rtl #jqvmap1_ok_pin,
.rtl #jqvmap1_va_pin {
    margin-left: auto;
    margin-right: 2%;
}

.rtl #jqvmap1_wv_pin {
    margin-left: auto;
    margin-right: -1%;
}

.rtl .main-donut-chart .slice,
.rtl .chart-donut {
    left: auto;
    right: 0;
}

.rtl .flot-chart-wrapper {
    margin-right: -16px;
    margin-left: -30px;
}

.rtl .chart-legEnd div+div {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .chart-legEnd span {
    margin-right: auto;
    margin-left: 5px;
}

@media (min-width: 576px) {
    .rtl .chart-legEnd {
        right: auto;
        left: 40px;
    }

    .rtl .chart-legEnd span {
        margin-right: auto;
        margin-left: 8px;
    }

    .rtl .chart-legEnd div+div {
        margin-left: auto;
        margin-right: 30px;
    }
}

.rtl .mapael .zoomButton {
    left: auto;
    right: 10px;
}

@media (min-width: 768px) {
    .rtl .ql-scrolling-demo {
        padding-left: inherit;
        padding-right: 15px;
    }
}

.rtl .ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 0 0 8px;
}

.rtl .ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-left: auto;
    margin-right: 0;
}

@media (min-width: 576px) {
    .rtl .ql-bubble .ql-toolbar .ql-formats:first-child {
        margin-left: auto;
        margin-right: 0;
    }
}

.rtl .ql-snow.ql-toolbar .ql-formats {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .ql-snow .ql-formats button+button {
    margin: 0 2px 0 0;
}

.rtl .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: auto;
    left: 3px;
}

.rtl .ql-snow .ql-picker-label {
    padding-left: inherit;
    padding-right: 10px;
}

.rtl .irs-bar-edge,
.rtl .irs-line-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .irs-line-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .irs-slider:before {
    left: auto;
    right: 50%;
    margin-left: auto;
    margin-right: -1px;
}

.rtl .irs-modern .irs-slider::before {
    margin-left: auto;
    margin-right: -2px;
}

.rtl .irs-outline .irs-bar-edge {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rtl .irs-outline .irs-slider::before {
    margin-left: auto;
    margin-right: -4px;
}

.rtl .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: 20px;
    padding-right: 0.75rem;
}

.rtl .select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-right: auto;
    margin-left: 4px;
    padding: 3px 20px 3px 10px !important;
}

.rtl .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    left: auto;
    right: 7px;
}

.rtl .select2-container--default .select2-search--inline .select2-search__field {
    padding-left: inherit;
    padding-right: 7px;
}

.rtl .select2-container--default.select2-container--disabled .select2-selection__choice {
    padding-left: inherit;
    padding-right: 10px;
}

.rtl .sp-picker-container {
    border-left: 1px solid #e8e8f7;
    border-right: 0;
}

.rtl .sp-dd::before {
    left: auto;
    right: 0;
}

.rtl .lds-facebook div {
    left: auto;
    right: 6px;
}

.rtl .lds-facebook div:nth-child(1) {
    left: auto;
    right: 6px;
}

.rtl .lds-facebook div:nth-child(2) {
    left: auto;
    right: 26px;
}

.rtl .lds-facebook div:nth-child(3) {
    left: auto;
    right: 45px;
}

.rtl .lds-spinner div:after {
    right: 29px;
    left: auto;
}

.rtl .pulse {
    right: auto;
    left: 17px;
}

.rtl .ripple:after {
    left: auto;
    right: 0;
}

.rtl .main-nav-calendar-event .nav-link i {
    margin-right: auto;
    margin-left: 10px;
}

@media (min-width: 992px) {
    .rtl .main-content-body-calendar {
        padding-left: inherit;
        padding-right: 25px;
    }
}

.rtl .main-calendar .fc-view .fc-day-number {
    margin: 2px 0 0 2px;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
    border-left: 0;
    border-right: 4px solid transparent;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time,
.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
    border-right: 0;
    border-left: 1px solid #e8e8f7;
}

.rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
.rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-left: auto;
    margin-right: 20%;
}

.rtl .main-calendar .fc-time-grid-event {
    border-left-width: 1px;
    border-right-width: 0;
}

@media (max-width: 575px) {

    .rtl .main-calendar .fc-header-toolbar button.fc-month-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
        left: auto;
        right: 10px;
    }

    .rtl .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .rtl .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
        left: auto;
        right: 11px;
    }
}

@media (max-width: 991.98px) {

    .rtl .main-calendar .fc-view>table>thead th:first-child,
    .rtl .main-calendar .fc-view>table>thead td:first-child {
        border-left-width: 1px;
        border-right-width: 0;
    }

    .rtl .main-calendar .fc-view>table>thead th:last-child,
    .rtl .main-calendar .fc-view>table>thead td:last-child {
        border-right-width: 1px;
        border-left-width: 0;
    }
}

@media (max-width: 991.98px) {
    .rtl .main-calendar .fc-view>table>tbody>tr>td:first-child {
        border-left-width: 1px;
        border-right-width: 0;
    }

    .rtl .main-calendar .fc-view>table>tbody>tr>td:last-child {
        border-right-width: 1px;
        border-left-width: 0;
    }
}

@media (min-width: 576px) {

    .rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: auto;
        margin-right: 15%;
    }
}

@media (min-width: 768px) {

    .rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: auto;
        margin-right: 12%;
    }
}

@media (min-width: 992px) {

    .rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: auto;
        margin-right: 10%;
    }
}

@media (min-width: 1200px) {

    .rtl .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .rtl .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: auto;
        margin-right: 8%;
    }
}

@media (min-width: 576px) {
    .rtl .main-calendar .fc-event {
        border-left-width: 0;
        border-right-width: 2px;
    }
}

.rtl .main-modal-calendar-schedule .modal-content>.close {
    right: auto;
    left: 25px;
}

.rtl .main-modal-calendar-schedule .form-group-date>i {
    margin-right: auto;
    margin-left: 10px;
}

.rtl .main-modal-calendar-event .nav-modal-event .nav-link+.nav-link {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .main-nav-line-chat {
    padding-left: inherit;
    padding-right: 20px;
}

.rtl .main-nav-line-chat .nav-link+.nav-link {
    margin-left: 0;
    margin-right: 30px;
}

@media (min-width: 992px) {
    .rtl .main-chat-contacts-wrapper {
        padding: 10px 0 10px 15px;
    }
}

.rtl .main-chat-contacts {
    padding-left: inherit;
    padding-right: 20px;
}

.rtl .main-chat-list .main-img-user span {
    left: auto;
    right: -2px;
}

.rtl .main-chat-list .media-body {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .main-chat-header .nav {
    margin-left: 0;
    margin-right: auto;
}

.rtl .main-chat-header .nav-link+.nav-link {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .main-chat-msg-name {
    margin-right: 15px;
    margin-left: auto;
}

.rtl .main-chat-body .media.flex-row-reverse .media-body {
    margin-left: 20px;
    margin-right: 0;
}

.rtl .main-chat-body .media.flex-row-reverse .main-msg-wrapper:before,
.rtl .main-chat-body .media.flex-row-reverse .main-msg-wrapper:after {
    left: auto;
    right: 100%;
    border-right-color: #e9e9f9 !important;
    border-left-color: transparent !important;
}

@media (min-width: 576px) {
    .rtl .main-chat-body .media.flex-row-reverse .media-body {
        margin-left: 20px;
        margin-right: 55px;
    }
}

.rtl .main-msg-wrapper:before,
.rtl .main-msg-wrapper:after {
    right: auto;
    left: 100%;
}

.rtl .main-chat-body .media-body {
    margin-left: auto;
    margin-right: 20px;
}

@media (min-width: 576px) {
    .rtl .main-chat-body .media-body {
        margin-right: 20px;
        margin-left: 55px;
    }
}

.rtl .main-chat-footer .nav-link+.nav-link {
    margin-left: auto;
    margin-right: 10px;
}

@media (min-width: 576px) {
    .rtl .main-chat-footer .form-control {
        margin-left: auto;
        margin-right: 20px;
    }
}

.rtl .main-content-left-contacts .main-content-breadcrumb,
.rtl .main-content-left-contacts .main-content-title {
    padding-right: 20px;
    padding-left: inherit;
}

@media (min-width: 992px) {

    .rtl .main-content-left-contacts .main-content-breadcrumb,
    .rtl .main-content-left-contacts .main-content-title {
        padding-left: inherit;
        padding-right: 0;
    }
}

.rtl .main-contact-label {
    padding-right: 20px;
    padding-left: inherit;
}

.rtl .main-contact-item {
    border-right-width: 1px;
    border-left-width: 0;
}

.rtl .main-contact-item.selected {
    border-left-color: transparent;
    border-right-color: #eaedf7;
}

.rtl .main-contact-body {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .main-contact-info-header {
    padding-left: inherit;
    padding-right: 20px;
}

.rtl .main-contact-info-header .main-img-user a {
    right: auto;
    left: 0;
}

.rtl .main-contact-info-header .media-body .nav-link i {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .main-contact-info-header .media-body .nav-link+.nav-link {
    margin-left: auto;
    margin-right: 10px;
}

@media (min-width: 992px) {
    .rtl .main-contact-info-header .media-body {
        margin-left: auto;
        margin-right: 30px;
    }
}

@media (min-width: 576px) {
    .rtl .main-contact-info-header .media-body .nav-link i {
        margin-right: auto;
        margin-left: 7px;
    }
}

@media (min-width: 576px) {
    .rtl .main-contact-info-header .media-body .nav-link+.nav-link {
        margin-left: auto;
        margin-right: 25px;
    }
}

.rtl .main-contact-action {
    right: auto;
    left: 20px;
}

@media (min-width: 992px) {
    .rtl .main-contact-action {
        right: auto;
        left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-column-signup {
        border-left: 0;
        border-right: 1px solid #e8e8f7;
    }
}

.rtl .main-signup-header .row>div .btn i,
.rtl .main-signup-header .row>div .sp-container button i {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .sp-container .main-signup-header .row>div button i {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .signpages .details {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.rtl .construction {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.rtl .construction:before {
    left: auto;
    right: 0;
}

.rtl .signpages .details:before {
    left: auto;
    right: 0;
}

.rtl .signpages .login_form {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.rtl .signpages .form__input {
    padding-left: inherit;
    padding-right: 2em;
}

@media screen and (max-width: 640px) {
    .rtl .signpages .login_form {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

.rtl.error-1 .demo-icon {
    right: auto !important;
    left: 60px !important;
}

@media (max-width: 991px) {
    .rtl.error-1 .demo-icon {
        right: auto !important;
        left: 30px !important;
    }
}

@media (min-width: 992px) {
    .rtl .main-content-left-invoice {
        border-right: 0;
        border-left: 1px solid #e8e8f7;
    }
}

.rtl .main-invoice-list .media {
    border-right-width: 1px;
    border-left-width: 0;
}

.rtl .main-invoice-list .media+.media::before {
    right: 55px;
    left: 0;
}

.rtl .main-invoice-list .media-body {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .table-invoice tbody>tr>th:first-child .invoice-notes,
.rtl .table-invoice tbody>tr>td:first-child .invoice-notes {
    margin-right: auto;
    margin-left: 20px;
}

.rtl .main-content-left-invoice .main-content-breadcrumb,
.rtl .main-content-left-invoice .main-content-title {
    padding-left: inherit;
    padding-right: 20px;
}

@media (min-width: 992px) {

    .rtl .main-content-left-invoice .main-content-breadcrumb,
    .rtl .main-content-left-invoice .main-content-title {
        padding-left: inherit;
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-content-left-mail .btn-compose {
        margin-right: auto;
        margin-left: 0;
    }
}

.rtl .main-task-menu {
    padding-right: inherit;
    padding-left: 0;
}

@media (min-width: 992px) {
    .rtl .main-task-menu {
        padding-right: inherit;
        padding-left: 0;
    }
}

.rtl .main-mail-header>div:last-child>span {
    margin-right: auto;
    margin-left: 10px;
}

.rtl .main-mail-header .btn-group .btn+.btn,
.rtl .main-mail-header .btn-group .sp-container button+.btn {
    margin-left: auto;
    margin-right: -2px;
}

.rtl .sp-container .main-mail-header .btn-group button+.btn,
.rtl .main-mail-header .btn-group .sp-container .btn+button,
.rtl .sp-container .main-mail-header .btn-group .btn+button,
.rtl .main-mail-header .btn-group .sp-container button+button,
.rtl .sp-container .main-mail-header .btn-group button+button {
    margin-left: auto;
    margin-right: -2px;
}

.rtl .main-mail-options {
    padding: 5px 20px 5px 10px;
}

.rtl .main-mail-item .main-img-user,
.rtl .main-mail-item .main-avatar {
    margin-right: auto;
    margin-left: 15px;
}

.rtl .main-mail-checkbox {
    margin-right: auto;
    margin-left: 15px;
}

.rtl .main-mail-star {
    margin-right: auto;
    margin-left: 15px;
    right: auto;
    left: 0;
}

@media (min-width: 992px) {
    .rtl .main-mail-body {
        margin-right: auto;
        margin-left: 15px;
    }
}

@media (min-width: 992px) {
    .rtl .task-actions {
        float: left;
        margin-left: 0;
        margin-right: auto;
    }
}

@media (max-width: 991px) {

    .rtl .mail-container .tab-list-items.task-menu-show,
    .rtl .mail-container .tab-list-items.mail-menu-show {
        left: auto;
        right: 0;
        padding-left: inherit;
        padding-right: 27px;
    }

    .rtl .task-actions {
        float: left;
        margin-left: auto;
        margin-right: 0;
    }
}

.rtl .main-mail-attachment {
    margin-right: auto;
    margin-left: 15px;
}

@media (min-width: 992px) {
    .rtl .main-mail-attachment {
        right: auto;
        left: 63px;
    }
}

.rtl .card-box img {
    left: auto;
    right: -17px;
    transform: scaleX(-1);
}

.rtl .main-mail-date {
    right: auto;
    left: 15px;
    margin-left: 0;
    margin-right: auto;
}

.rtl .main-mail-compose {
    left: auto;
    right: 0;
}

.rtl .main-mail-compose-header .nav-link+.nav-link {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .main-mail-compose-body .form-group>div {
    margin-left: auto;
    margin-right: 10px;
}

.rtl .main-mail-compose-body .form-group .nav-link+.nav-link {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .main-mail-compose-compress,
.rtl .main-mail-compose-minimize {
    right: auto;
    left: 30px;
}

.rtl .main-mail-two .main-header-menu-icon {
    margin-right: auto;
    margin-left: 0;
}

.rtl .main-mail-left {
    border-right: 0;
    border-left: 1px solid #e8e8f7;
}

.rtl .main-mail-left .main-task-menu,
.rtl .main-mail-left .main-mail-menu {
    padding-right: inherit;
    padding-left: 0;
}

.rtl ul.inbox-pagination {
    float: left;
}

.rtl .inbox-pagination li span {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .inbox-pagination a.np-btn {
    margin-left: auto;
    margin-right: 5px;
}

.rtl .profile-cover__action {
    padding: 216px 185px 10px 30px;
}

.rtl .profile-cover__action>.btn {
    margin-left: auto;
    margin-right: 10px;
}

.rtl .profile-cover__img {
    left: auto;
    right: 50px;
}

@media (min-width: 601px) {
    .rtl .profile-cover__info .nav {
        padding: 15px 170px 10px 0;
    }
}

.rtl .profile-cover__info .nav li:not(:first-child) {
    margin-right: 30px;
    margin-left: 0;
    padding-left: inherit;
    padding-right: 30px;
    border-left: 0;
    border-right: 1px solid rgba(238, 238, 238, 0.2);
}

@media (max-width: 600px) {
    .rtl .profile-cover__info .nav {
        margin-right: auto;
        margin-left: 28px;
        padding: 39px 170px 10px 0;
        margin-right: auto;
        margin-left: 0;
    }

    .rtl .profile-cover__info .nav li {
        margin-right: auto;
        margin-left: 1rem;
    }

    .rtl .profile-cover__info .nav li:not(:first-child) {
        margin-left: auto;
        margin-right: 0;
        padding-left: inherit;
        padding-right: 0;
        border-left-width: 1px;
        border-right-width: 0;
    }

    .rtl .main-nav-line .nav-link+.nav-link {
        margin-left: auto;
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-content-left-profile {
        padding-right: inherit;
        padding-left: 20px;
        border-right: 0;
        border-left: 1px solid #e8e8f7;
    }
}

@media (min-width: 1200px) {
    .rtl .main-content-left-profile {
        padding-right: inherit;
        padding-left: 25px;
    }
}

.rtl .main-profile-social-list .media-body {
    margin-left: auto;
    margin-right: 20px;
}

@media (min-width: 992px) {
    .rtl .main-content-body-profile .nav {
        padding-left: inherit;
        padding-right: 20px;
    }
}

@media (min-width: 1200px) {
    .rtl .main-content-body-profile .nav {
        padding-left: inherit;
        padding-right: 25px;
    }
}

@media (min-width: 992px) {
    .rtl .main-profile-body {
        padding: 25px 20px 0 0;
    }
}

@media (min-width: 1200px) {
    .rtl .main-profile-body {
        padding-left: inherit;
        padding-right: 25px;
    }
}

.rtl .main-profile-view-info {
    left: auto;
    right: 0;
}

.rtl .main-profile-view-info span {
    margin-left: auto;
    margin-right: 5px;
}

.rtl .main-profile-work-list .media-body {
    margin-left: auto;
    margin-right: 20px;
}

.rtl .main-profile-contact-list .media-body {
    margin-left: auto;
    margin-right: 25px;
}

.rtl .btn-profile {
    right: auto;
    left: 50px;
}

@media (max-width: 767px) {
    .rtl .profile-cover__info .nav {
        margin-right: auto;
        margin-left: 0;
    }

    .rtl .profile-cover__info .nav li {
        margin-right: 0;
        margin-left: 1rem !important;
    }

    .rtl .profile-cover__info .nav li:not(:first-child) {
        margin-left: 0;
        margin-right: 0;
        padding-left: inherit;
        padding-right: 0;
        border-left-width: 1px;
        border-right-width: 0;
    }

    .rtl .main-nav-line .nav-link+.nav-link {
        margin-left: auto;
        margin-right: 0;
    }
}

.rtl .card-bitcoin .media-body {
    margin-left: auto;
    margin-right: 20px;
}

.rtl .crypto-card .chart-wrapper {
    left: 0;
    right: -68px;
}

.rtl .exchange-icon {
    left: auto;
    right: -51px;
}

.rtl .payment-list {
    padding-left: inherit;
    padding-right: 0;
}

.rtl .cryptoicon {
    margin-right: auto;
    margin-left: 15px;
    float: right;
    margin-top: 11px;
}

.rtl .select2-results__option .cryptoicon {
    margin-top: 0;
}

.rtl .form input:checked+label:after {
    right: auto;
    left: 0;
    border-width: 0 0 35px 35px;
}

.rtl .form input:checked+label:before {
    right: auto;
    left: 4px;
}

.rtl .crypto-buysell-card .nav.panel-tabs li .active:before {
    left: auto;
    right: 50%;
    margin-left: auto;
    margin-right: -10px;
}

.rtl .crypto-wallet .clipboard-icon {
    right: auto;
    left: 0;
}

.rtl .crypto-wallet .clipboard-icon:hover,
.rtl .crypto-wallet .clipboard-icon:focus {
    right: auto;
    left: 0;
}

.rtl .wallet .clipboard-icon {
    right: auto;
    left: 0;
    border-left: 1px solid #e8e8f7;
    border-right: 0;
}

.rtl .wallet .input-group-addon-left {
    border-right: 1px solid #e8e8f7;
    border-left: 0;
}

.rtl .transcation-crypto .table.dataTable th,
.rtl .transcation-crypto .table.dataTable td {
    border-left: 0;
    border-right: 0;
}

.rtl .transcation-crypto table.dataTable thead .sorting:before,
.rtl .transcation-crypto table.dataTable thead .sorting_asc:before,
.rtl .transcation-crypto table.dataTable thead .sorting_desc:before,
.rtl .transcation-crypto table.dataTable thead .sorting_asc_disabled:before,
.rtl .transcation-crypto table.dataTable thead .sorting_desc_disabled:before {
    right: auto;
    left: 1em;
}

.rtl .transcation-crypto table.dataTable thead .sorting:after,
.rtl .transcation-crypto table.dataTable thead .sorting_asc:after,
.rtl .transcation-crypto table.dataTable thead .sorting_desc:after {
    right: auto;
    left: 0.5em;
}

.rtl table.dataTable thead .sorting_asc_disabled:after,
.rtl .transcation-crypto table.dataTable thead .sorting_desc_disabled:after {
    right: auto;
    left: 0.5em;
}

.rtl .ratio {
    right: auto;
    left: -10px;
}

.rtl .product-timeline ul.timeline-1 {
    padding-right: 2rem;
    padding-left: initial;
}

.rtl .product-timeline ul.timeline-1>li {
    padding-left: inherit;
    padding-right: 1rem;
}

.rtl .product-timeline ul.timeline-1>li .product-icon {
    left: auto;
    right: 3px;
}

.rtl .product-timeline ul.timeline-1::before {
    margin: 20px 22px 0 20px;
    left: auto;
    right: -2px;
    border-left: 0;
    border-right: 2px dotted rgba(98, 89, 202, 0.3);
}

.rtl .countrywise-sale .list-group-item span {
    margin-left: 0;
    margin-right: auto;
}

.rtl .countrywise-sale .list-group-item p {
    margin-left: auto;
    margin-right: 10px;
}

.rtl .top-selling-products .product-img {
    margin-right: 0;
    margin-left: 1rem;
}

.rtl .top-selling-products .product-price {
    margin-left: 0;
    margin-right: auto;
    text-align: left;
}

.rtl .top-selling-products .product-amount {
    float: left;
    margin-left: 0;
    margin-right: auto;
}

.rtl .table-filter select {
    margin-left: auto;
    margin-right: 5px;
}

.rtl .table-filter .btn {
    margin-left: auto;
    margin-right: -1px;
    float: left;
    border-radius: 4px 0 0 4px;
}

.rtl .table-filter input {
    margin-left: auto;
    margin-right: 5px;
}

.rtl .status {
    margin: 2px 0 4px 6px;
}

.rtl #checkoutsteps .item .purchase {
    margin-left: auto;
    margin-right: 21px;
}

.rtl #checkoutsteps .checkout span.heading {
    margin-right: auto;
    margin-left: 5px;
}

.rtl #checkoutsteps .total {
    float: left;
}

.rtl .card-pay .tabs-menu.nav li {
    border-right: 0;
    border-left: 1px solid #d3dfea;
}

.rtl .card-pay .tabs-menu.nav li a {
    border-right: 0;
    border-left: 0;
}

.rtl .item1-links a i {
    margin-right: auto;
    margin-left: 1rem;
}

.rtl .track .step::before,
.rtl .product-image .pic-2 {
    left: auto;
    right: 0;
}

.rtl .product-grid:hover .product-image .pic-2,
.rtl .product-grid .product-discount-label {
    left: auto;
    right: 10px;
}

.rtl .product-grid .product-like-icon {
    right: auto;
    left: -50px;
}

.rtl .product-grid:hover .product-like-icon {
    right: auto;
    left: 10px;
}

.rtl .product-grid .product-link {
    left: auto;
    right: 0;
}

.rtl .product-grid .product-link a {
    border-right: 0;
    border-left: 2px solid rgba(215, 215, 215, 0.15);
}

.rtl .product-grid .product-link a:nth-child(2n) {
    border-right: 0;
    border-left: none;
}

.rtl .product-content .rating {
    padding-left: inherit;
    padding-right: 0;
}

.rtl .product-grid .price .old-price {
    margin-right: auto;
    margin-left: 12px;
}

.rtl #wishlist .product-grid {
    text-align: right;
}

.rtl #wishlist .product-grid .product-content {
    text-align: right;
}

.rtl #wishlist .product-grid .wishlist-icon {
    right: auto;
    left: 10px;
}

.rtl .notify-time {
    right: auto;
    left: 25px;
}

.rtl .notification:before {
    left: auto;
    right: 20%;
    margin-left: auto;
    margin-right: -1px;
}

.rtl .notification .notification-time {
    left: auto;
    right: 0;
    text-align: left;
}

.rtl .notification .notification-icon {
    left: auto;
    right: 15%;
}

.rtl .notification .notification-body {
    margin-right: 24%;
    margin-left: 18%;
}

.rtl .notification .notification-body:before {
    border-right-color: transparent;
    border-left-color: #ffffff;
    left: auto;
    right: -20px;
}

.rtl .border-end {
    border-right: 0 !important;
    border-left: 1px solid #e8e8f7;
}

.rtl .border-start {
    border-left: 0 !important;
    border-right: 1px solid #e8e8f7;
}

.rtl .border-end-0 {
    border-right: 1px solid #e8e8f7 !important;
    border-left: 0;
}

.rtl .border-start-0 {
    border-right: 0;
    border-left: 1px solid #e8e8f7 !important;
}

.rtl .rounded-end {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rtl .rounded-start {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.rtl .rounded-end-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.rtl .rounded-start-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rtl .rounded-end-11 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 11px !important;
    border-bottom-left-radius: 11px !important;
}

.rtl .rounded-start-11 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 11px !important;
    border-bottom-right-radius: 11px !important;
}

.rtl .bd-r {
    border-right: 0;
    border-left: 1px solid #e8e8f7;
}

.rtl .bd-l {
    border-left: 0;
    border-right: 1px solid #e8e8f7;
}

.rtl .bd-r-0 {
    border-right: 1px solid #e8e8f7;
    border-left: 0;
}

.rtl .bd-l-0 {
    border-left: 1px solid #e8e8f7;
    border-right: 0;
}

@media (min-width: 480px) {
    .rtl .bd-xs-r {
        border-right: 0;
        border-left: 1px solid #e8e8f7;
    }

    .rtl .bd-xs-l {
        border-left: 0;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 576px) {
    .rtl .bd-sm-r {
        border-right: 0;
        border-left: 1px solid #e8e8f7;
    }

    .rtl .bd-sm-l {
        border-left: 0;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 768px) {
    .rtl .bd-md-r {
        border-right: 0;
        border-left: 1px solid #e8e8f7;
    }

    .rtl .bd-md-l {
        border-left: 0;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 992px) {
    .rtl .bd-lg-r {
        border-right: 0;
        border-left: 1px solid #e8e8f7;
    }

    .rtl .bd-lg-l {
        border-left: 0;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 1200px) {
    .rtl .bd-xl-r {
        border-right: 0;
        border-left: 1px solid #e8e8f7;
    }

    .rtl .bd-xl-l {
        border-left: 0;
        border-right: 1px solid #e8e8f7;
    }
}

.rtl .mg-r-0 {
    margin-right: auto;
    margin-left: 0px;
}

.rtl .mg-l-0 {
    margin-left: auto;
    margin-right: 0px;
}

.rtl .mg-r-1 {
    margin-right: auto;
    margin-left: 1px;
}

.rtl .mg-l-1 {
    margin-left: auto;
    margin-right: 1px;
}

.rtl .mg-r-2 {
    margin-right: auto;
    margin-left: 2px;
}

.rtl .mg-l-2 {
    margin-left: auto;
    margin-right: 2px;
}

.rtl .mg-r-3 {
    margin-right: auto;
    margin-left: 3px;
}

.rtl .mg-l-3 {
    margin-left: auto;
    margin-right: 3px;
}

.rtl .mg-r-4 {
    margin-right: auto;
    margin-left: 4px;
}

.rtl .mg-l-4 {
    margin-left: auto;
    margin-right: 4px;
}

.rtl .mg-r-5 {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .mg-l-5 {
    margin-left: auto;
    margin-right: 5px;
}

.rtl .mg-r-6 {
    margin-right: auto;
    margin-left: 6px;
}

.rtl .mg-l-6 {
    margin-left: auto;
    margin-right: 6px;
}

.rtl .mg-r-7 {
    margin-right: auto;
    margin-left: 7px;
}

.rtl .mg-l-7 {
    margin-left: auto;
    margin-right: 7px;
}

.rtl .mg-r-8 {
    margin-right: auto;
    margin-left: 8px;
}

.rtl .mg-l-8 {
    margin-left: auto;
    margin-right: 8px;
}

.rtl .mg-r-9 {
    margin-right: auto;
    margin-left: 9px;
}

.rtl .mg-l-9 {
    margin-left: auto;
    margin-right: 9px;
}

.rtl .mg-r-10 {
    margin-right: auto;
    margin-left: 10px;
}

.rtl .mg-l-10 {
    margin-left: auto;
    margin-right: 10px;
}

.rtl .mg-r-15 {
    margin-right: 0;
    margin-left: 15px;
}

.rtl .mg-l-15 {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .mg-r-20 {
    margin-right: auto;
    margin-left: 20px;
}

.rtl .mg-l-20 {
    margin-left: 0;
    margin-right: 20px;
}

.rtl .mg-r-25 {
    margin-right: auto;
    margin-left: 25px;
}

.rtl .mg-l-25 {
    margin-left: auto;
    margin-right: 25px;
}

.rtl .mg-r-30 {
    margin-right: auto;
    margin-left: 30px;
}

.rtl .mg-l-30 {
    margin-left: auto;
    margin-right: 30px;
}

.rtl .mg-r-35 {
    margin-right: auto;
    margin-left: 35px;
}

.rtl .mg-l-35 {
    margin-left: auto;
    margin-right: 35px;
}

.rtl .mg-r-40 {
    margin-right: auto;
    margin-left: 40px;
}

.rtl .mg-l-40 {
    margin-left: auto;
    margin-right: 40px;
}

.rtl .mg-r-45 {
    margin-right: auto;
    margin-left: 45px;
}

.rtl .mg-l-45 {
    margin-left: auto;
    margin-right: 45px;
}

.rtl .mg-r-50 {
    margin-right: auto;
    margin-left: 50px;
}

.rtl .mg-l-50 {
    margin-left: auto;
    margin-right: 50px;
}

.rtl .mg-r-55 {
    margin-right: auto;
    margin-left: 55px;
}

.rtl .mg-l-55 {
    margin-right: 55px;
    margin-left: auto;
}

.rtl .mg-r-60 {
    margin-right: auto;
    margin-left: 60px;
}

.rtl .mg-l-60 {
    margin-left: auto;
    margin-right: 60px;
}

.rtl .mg-r-65 {
    margin-right: auto;
    margin-left: 65px;
}

.rtl .mg-l-65 {
    margin-left: auto;
    margin-right: 65px;
}

.rtl .mg-r-70 {
    margin-right: auto;
    margin-left: 70px;
}

.rtl .mg-l-70 {
    margin-left: auto;
    margin-right: 70px;
}

.rtl .mg-r-75 {
    margin-right: auto;
    margin-left: 75px;
}

.rtl .mg-l-75 {
    margin-left: auto;
    margin-right: 75px;
}

.rtl .mg-r-80 {
    margin-right: auto;
    margin-left: 80px;
}

.rtl .mg-l-80 {
    margin-left: auto;
    margin-right: 80px;
}

.rtl .mg-r-85 {
    margin-right: auto;
    margin-left: 85px;
}

.rtl .mg-l-85 {
    margin-left: auto;
    margin-right: 85px;
}

.rtl .mg-r-90 {
    margin-right: auto;
    margin-left: 90px;
}

.rtl .mg-l-90 {
    margin-left: auto;
    margin-right: 90px;
}

.rtl .mg-r-95 {
    margin-right: auto;
    margin-left: 95px;
}

.rtl .mg-l-95 {
    margin-left: auto;
    margin-right: 95px;
}

.rtl .mg-r-100 {
    margin-right: auto;
    margin-left: 100px;
}

.rtl .mg-l-100 {
    margin-left: auto;
    margin-right: 100px;
}

.rtl .mg-r-105 {
    margin-right: auto;
    margin-left: 105px;
}

.rtl .mg-l-105 {
    margin-left: auto;
    margin-right: 105px;
}

.rtl .mg-r-110 {
    margin-right: auto;
    margin-left: 110px;
}

.rtl .mg-l-110 {
    margin-left: 110px;
}

.rtl .mg-r-115 {
    margin-left: 115px;
    margin-right: auto;
}

.rtl .mg-l-115 {
    margin-left: auto;
    margin-right: 115px;
}

.rtl .mg-r-120 {
    margin-right: auto;
    margin-left: 120px;
}

.rtl .mg-l-120 {
    margin-left: auto;
    margin-right: 120px;
}

.rtl .mg-r-auto {
    margin-right: 0;
    margin-left: auto;
}

.rtl .mg-l-auto {
    margin-left: 0;
    margin-right: auto;
}

@media (min-width: 480px) {
    .rtl .mg-xs-r-0 {
        margin-right: auto;
        margin-left: 0px;
    }

    .rtl .mg-xs-l-0 {
        margin-left: auto;
        margin-right: 0px;
    }

    .rtl .mg-xs-r-1 {
        margin-right: auto;
        margin-left: 1px;
    }

    .rtl .mg-xs-l-1 {
        margin-left: auto;
        margin-right: 1px;
    }

    .rtl .mg-xs-r-2 {
        margin-right: auto;
        margin-left: 2px;
    }

    .rtl .mg-xs-l-2 {
        margin-left: auto;
        margin-right: 2px;
    }

    .rtl .mg-xs-r-3 {
        margin-right: auto;
        margin-left: 3px;
    }

    .rtl .mg-xs-l-3 {
        margin-left: auto;
        margin-right: 3px;
    }

    .rtl .mg-xs-r-4 {
        margin-right: auto;
        margin-left: 4px;
    }

    .rtl .mg-xs-l-4 {
        margin-left: auto;
        margin-right: 4px;
    }

    .rtl .mg-xs-r-5 {
        margin-right: auto;
        margin-left: 5px;
    }

    .rtl .mg-xs-l-5 {
        margin-left: auto;
        margin-right: 5px;
    }

    .rtl .mg-xs-r-6 {
        margin-right: auto;
        margin-left: 6px;
    }

    .rtl .mg-xs-l-6 {
        margin-left: auto;
        margin-right: 6px;
    }

    .rtl .mg-xs-r-7 {
        margin-right: auto;
        margin-left: 7px;
    }

    .rtl .mg-xs-l-7 {
        margin-left: auto;
        margin-right: 7px;
    }

    .rtl .mg-xs-r-8 {
        margin-right: auto;
        margin-left: 8px;
    }

    .rtl .mg-xs-l-8 {
        margin-left: auto;
        margin-right: 8px;
    }

    .rtl .mg-xs-r-9 {
        margin-right: auto;
        margin-left: 9px;
    }

    .rtl .mg-xs-l-9 {
        margin-left: auto;
        margin-right: 9px;
    }

    .rtl .mg-xs-r-10 {
        margin-right: auto;
        margin-left: 10px;
    }

    .rtl .mg-xs-l-10 {
        margin-left: auto;
        margin-right: 10px;
    }

    .rtl .mg-xs-r-15 {
        margin-right: auto;
        margin-left: 15px;
    }

    .rtl .mg-xs-l-15 {
        margin-left: auto;
        margin-right: 15px;
    }

    .rtl .mg-xs-r-20 {
        margin-right: auto;
        margin-left: 20px;
    }

    .rtl .mg-xs-l-20 {
        margin-left: auto;
        margin-right: 20px;
    }

    .rtl .mg-xs-r-25 {
        margin-right: auto;
        margin-left: 25px;
    }

    .rtl .mg-xs-l-25 {
        margin-left: auto;
        margin-right: 25px;
    }

    .rtl .mg-xs-r-30 {
        margin-right: auto;
        margin-left: 30px;
    }

    .rtl .mg-xs-l-30 {
        margin-left: auto;
        margin-right: 30px;
    }

    .rtl .mg-xs-r-35 {
        margin-right: auto;
        margin-left: 35px;
    }

    .rtl .mg-xs-l-35 {
        margin-left: auto;
        margin-right: 35px;
    }

    .rtl .mg-xs-r-40 {
        margin-right: auto;
        margin-left: 40px;
    }

    .rtl .mg-xs-l-40 {
        margin-left: auto;
        margin-right: 40px;
    }

    .rtl .mg-xs-r-45 {
        margin-right: auto;
        margin-left: 45px;
    }

    .rtl .mg-xs-l-45 {
        margin-left: auto;
        margin-right: 45px;
    }

    .rtl .mg-xs-r-50 {
        margin-right: auto;
        margin-left: 50px;
    }

    .rtl .mg-xs-l-50 {
        margin-left: auto;
        margin-right: 50px;
    }

    .rtl .mg-xs-r-55 {
        margin-right: auto;
        margin-left: 55px;
    }

    .rtl .mg-xs-l-55 {
        margin-left: auto;
        margin-right: 55px;
    }

    .rtl .mg-xs-r-60 {
        margin-right: auto;
        margin-left: 60px;
    }

    .rtl .mg-xs-l-60 {
        margin-left: auto;
        margin-right: 60px;
    }

    .rtl .mg-xs-r-65 {
        margin-right: auto;
        margin-left: 65px;
    }

    .rtl .mg-xs-l-65 {
        margin-left: auto;
        margin-right: 65px;
    }

    .rtl .mg-xs-r-70 {
        margin-right: auto;
        margin-left: 70px;
    }

    .rtl .mg-xs-l-70 {
        margin-left: auto;
        margin-right: 70px;
    }

    .rtl .mg-xs-r-75 {
        margin-right: auto;
        margin-left: 75px;
    }

    .rtl .mg-xs-l-75 {
        margin-left: auto;
        margin-right: 75px;
    }

    .rtl .mg-xs-r-80 {
        margin-right: auto;
        margin-left: 80px;
    }

    .rtl .mg-xs-l-80 {
        margin-left: auto;
        margin-right: 80px;
    }

    .rtl .mg-xs-r-85 {
        margin-right: auto;
        margin-left: 85px;
    }

    .rtl .mg-xs-l-85 {
        margin-left: auto;
        margin-right: 85px;
    }

    .rtl .mg-xs-r-90 {
        margin-right: auto;
        margin-left: 90px;
    }

    .rtl .mg-xs-l-90 {
        margin-left: auto;
        margin-right: 90px;
    }

    .rtl .mg-xs-r-95 {
        margin-right: auto;
        margin-left: 95px;
    }

    .rtl .mg-xs-l-95 {
        margin-left: auto;
        margin-right: 95px;
    }

    .rtl .mg-xs-r-100 {
        margin-right: auto;
        margin-left: 100px;
    }

    .rtl .mg-xs-l-100 {
        margin-left: auto;
        margin-right: 100px;
    }

    .rtl .mg-xs-r-auto {
        margin-right: 0;
        margin-left: auto;
    }

    .rtl .mg-xs-l-auto {
        margin-left: 0;
        margin-right: auto;
    }
}

@media (min-width: 576px) {
    .rtl .mg-sm-r-0 {
        margin-right: auto;
        margin-left: 0px;
    }

    .rtl .mg-sm-l-0 {
        margin-right: 0;
        margin-left: auto;
    }

    .rtl .mg-sm-r-1 {
        margin-right: auto;
        margin-left: 1px;
    }

    .rtl .mg-sm-l-1 {
        margin-left: auto;
        margin-right: 1px;
    }

    .rtl .mg-sm-r-2 {
        margin-right: auto;
        margin-left: 2px;
    }

    .rtl .mg-sm-l-2 {
        margin-left: auto;
        margin-right: 2px;
    }

    .rtl .mg-sm-r-3 {
        margin-right: auto;
        margin-left: 3px;
    }

    .rtl .mg-sm-l-3 {
        margin-left: auto;
        margin-right: 3px;
    }

    .rtl .mg-sm-r-4 {
        margin-right: auto;
        margin-left: 4px;
    }

    .rtl .mg-sm-l-4 {
        margin-left: auto;
        margin-right: 4px;
    }

    .rtl .mg-sm-r-5 {
        margin-right: auto;
        margin-left: 5px;
    }

    .rtl .mg-sm-l-5 {
        margin-left: auto;
        margin-right: 5px;
    }

    .rtl .mg-sm-r-6 {
        margin-right: auto;
        margin-left: 6px;
    }

    .rtl .mg-sm-l-6 {
        margin-left: auto;
        margin-right: 6px;
    }

    .rtl .mg-sm-r-7 {
        margin-right: auto;
        margin-left: 7px;
    }

    .rtl .mg-sm-l-7 {
        margin-left: auto;
        margin-right: 7px;
    }

    .rtl .mg-sm-r-8 {
        margin-right: auto;
        margin-left: 8px;
    }

    .rtl .mg-sm-l-8 {
        margin-left: auto;
        margin-right: 8px;
    }

    .rtl .mg-sm-r-9 {
        margin-right: auto;
        margin-left: 9px;
    }

    .rtl .mg-sm-l-9 {
        margin-left: auto;
        margin-right: 9px;
    }

    .rtl .mg-sm-r-10 {
        margin-right: auto;
        margin-left: 10px;
    }

    .rtl .mg-sm-l-10 {
        margin-left: auto;
        margin-right: 10px;
    }

    .rtl .mg-sm-r-15 {
        margin-right: auto;
        margin-left: 15px;
    }

    .rtl .mg-sm-l-15 {
        margin-left: auto;
        margin-right: 15px;
    }

    .rtl .mg-sm-r-20 {
        margin-right: 0;
        margin-left: 20px;
    }

    .rtl .mg-sm-l-20 {
        margin-left: auto;
        margin-right: 20px;
    }

    .rtl .mg-sm-r-25 {
        margin-right: auto;
        margin-left: 25px;
    }

    .rtl .mg-sm-l-25 {
        margin-left: auto;
        margin-right: 25px;
    }

    .rtl .mg-sm-r-30 {
        margin-right: auto;
        margin-left: 30px;
    }

    .rtl .mg-sm-l-30 {
        margin-left: auto;
        margin-right: 30px;
    }

    .rtl .mg-sm-r-35 {
        margin-right: auto;
        margin-left: 35px;
    }

    .rtl .mg-sm-l-35 {
        margin-left: auto;
        margin-right: 35px;
    }

    .rtl .mg-sm-r-40 {
        margin-right: auto;
        margin-left: 40px;
    }

    .rtl .mg-sm-l-40 {
        margin-left: auto;
        margin-right: 40px;
    }

    .rtl .mg-sm-r-45 {
        margin-right: auto;
        margin-left: 45px;
    }

    .rtl .mg-sm-l-45 {
        margin-left: auto;
        margin-right: 45px;
    }

    .rtl .mg-sm-r-50 {
        margin-right: auto;
        margin-left: 50px;
    }

    .rtl .mg-sm-l-50 {
        margin-left: auto;
        margin-right: 50px;
    }

    .rtl .mg-sm-r-55 {
        margin-right: auto;
        margin-left: 55px;
    }

    .rtl .mg-sm-l-55 {
        margin-left: auto;
        margin-right: 55px;
    }

    .rtl .mg-sm-r-60 {
        margin-right: auto;
        margin-left: 60px;
    }

    .rtl .mg-sm-l-60 {
        margin-left: auto;
        margin-right: 60px;
    }

    .rtl .mg-sm-r-65 {
        margin-right: auto;
        margin-left: 65px;
    }

    .rtl .mg-sm-l-65 {
        margin-left: auto;
        margin-right: 65px;
    }

    .rtl .mg-sm-r-70 {
        margin-right: auto;
        margin-left: 70px;
    }

    .rtl .mg-sm-l-70 {
        margin-left: auto;
        margin-right: 70px;
    }

    .rtl .mg-sm-r-75 {
        margin-right: auto;
        margin-left: 75px;
    }

    .rtl .mg-sm-l-75 {
        margin-left: auto;
        margin-right: 75px;
    }

    .rtl .mg-sm-r-80 {
        margin-right: auto;
        margin-left: 80px;
    }

    .rtl .mg-sm-l-80 {
        margin-left: auto;
        margin-right: 80px;
    }

    .rtl .mg-sm-r-85 {
        margin-right: auto;
        margin-left: 85px;
    }

    .rtl .mg-sm-l-85 {
        margin-left: auto;
        margin-right: 85px;
    }

    .rtl .mg-sm-r-90 {
        margin-right: auto;
        margin-left: 90px;
    }

    .rtl .mg-sm-l-90 {
        margin-left: auto;
        margin-right: 90px;
    }

    .rtl .mg-sm-r-95 {
        margin-right: auto;
        margin-left: 95px;
    }

    .rtl .mg-sm-l-95 {
        margin-left: auto;
        margin-right: 95px;
    }

    .rtl .mg-sm-r-100 {
        margin-right: auto;
        margin-left: 100px;
    }

    .rtl .mg-sm-l-100 {
        margin-left: auto;
        margin-right: 100px;
    }

    .rtl .mg-sm-r-auto {
        margin-right: 0;
        margin-left: auto;
    }

    .rtl .mg-sm-l-auto {
        margin-left: 0;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    .rtl .mg-md-r-0 {
        margin-left: 0;
        margin-right: auto;
    }

    .rtl .mg-md-l-0 {
        margin-right: 0;
        margin-left: auto;
    }

    .rtl .mg-md-r-1 {
        margin-right: auto;
        margin-left: 1px;
    }

    .rtl .mg-md-l-1 {
        margin-left: auto;
        margin-right: 1px;
    }

    .rtl .mg-md-r-2 {
        margin-right: auto;
        margin-left: 2px;
    }

    .rtl .mg-md-l-2 {
        margin-left: auto;
        margin-right: 2px;
    }

    .rtl .mg-md-r-3 {
        margin-right: auto;
        margin-left: 3px;
    }

    .rtl .mg-md-l-3 {
        margin-left: auto;
        margin-right: 3px;
    }

    .rtl .mg-md-r-4 {
        margin-right: auto;
        margin-left: 4px;
    }

    .rtl .mg-md-l-4 {
        margin-left: auto;
        margin-right: 4px;
    }

    .rtl .mg-md-r-5 {
        margin-right: auto;
        margin-left: 5px;
    }

    .rtl .mg-md-l-5 {
        margin-left: auto;
        margin-right: 5px;
    }

    .rtl .mg-md-r-6 {
        margin-right: auto;
        margin-left: 6px;
    }

    .rtl .mg-md-l-6 {
        margin-left: auto;
        margin-right: 6px;
    }

    .rtl .mg-md-r-7 {
        margin-right: auto;
        margin-left: 7px;
    }

    .rtl .mg-md-l-7 {
        margin-left: auto;
        margin-right: 7px;
    }

    .rtl .mg-md-r-8 {
        margin-right: auto;
        margin-left: 8px;
    }

    .rtl .mg-md-l-8 {
        margin-left: auto;
        margin-right: 8px;
    }

    .rtl .mg-md-r-9 {
        margin-right: auto;
        margin-left: 9px;
    }

    .rtl .mg-md-l-9 {
        margin-left: auto;
        margin-right: 9px;
    }

    .rtl .mg-md-r-10 {
        margin-right: auto;
        margin-left: 10px;
    }

    .rtl .mg-md-l-10 {
        margin-left: auto;
        margin-right: 10px;
    }

    .rtl .mg-md-r-15 {
        margin-right: auto;
        margin-left: 15px;
    }

    .rtl .mg-md-l-15 {
        margin-left: auto;
        margin-right: 15px;
    }

    .rtl .mg-md-r-20 {
        margin-right: 0;
        margin-left: 20px;
    }

    .rtl .mg-md-l-20 {
        margin-left: auto;
        margin-right: 20px;
    }

    .rtl .mg-md-r-25 {
        margin-right: auto;
        margin-left: 25px;
    }

    .rtl .mg-md-l-25 {
        margin-left: auto;
        margin-right: 25px;
    }

    .rtl .mg-md-r-30 {
        margin-right: auto;
        margin-left: 30px;
    }

    .rtl .mg-md-l-30 {
        margin-left: auto;
        margin-right: 30px;
    }

    .rtl .mg-md-r-35 {
        margin-right: auto;
        margin-left: 35px;
    }

    .rtl .mg-md-l-35 {
        margin-left: auto;
        margin-right: 35px;
    }

    .rtl .mg-md-r-40 {
        margin-right: auto;
        margin-left: 40px;
    }

    .rtl .mg-md-l-40 {
        margin-left: auto;
        margin-right: 40px;
    }

    .rtl .mg-md-r-45 {
        margin-right: auto;
        margin-left: 45px;
    }

    .rtl .mg-md-l-45 {
        margin-left: auto;
        margin-right: 45px;
    }

    .rtl .mg-md-r-50 {
        margin-right: auto;
        margin-left: 50px;
    }

    .rtl .mg-md-l-50 {
        margin-left: auto;
        margin-right: 50px;
    }

    .rtl .mg-md-r-55 {
        margin-right: auto;
        margin-left: 55px;
    }

    .rtl .mg-md-l-55 {
        margin-left: auto;
        margin-right: 55px;
    }

    .rtl .mg-md-r-60 {
        margin-right: auto;
        margin-left: 60px;
    }

    .rtl .mg-md-l-60 {
        margin-left: auto;
        margin-right: 60px;
    }

    .rtl .mg-md-r-65 {
        margin-right: auto;
        margin-left: 65px;
    }

    .rtl .mg-md-l-65 {
        margin-left: auto;
        margin-right: 65px;
    }

    .rtl .mg-md-r-70 {
        margin-right: auto;
        margin-left: 70px;
    }

    .rtl .mg-md-l-70 {
        margin-left: auto;
        margin-right: 70px;
    }

    .rtl .mg-md-r-75 {
        margin-right: auto;
        margin-left: 75px;
    }

    .rtl .mg-md-l-75 {
        margin-left: auto;
        margin-right: 75px;
    }

    .rtl .mg-md-r-80 {
        margin-right: auto;
        margin-left: 80px;
    }

    .rtl .mg-md-l-80 {
        margin-left: auto;
        margin-right: 80px;
    }

    .rtl .mg-md-r-85 {
        margin-right: auto;
        margin-left: 85px;
    }

    .rtl .mg-md-l-85 {
        margin-left: auto;
        margin-right: 85px;
    }

    .rtl .mg-md-r-90 {
        margin-right: auto;
        margin-left: 90px;
    }

    .rtl .mg-md-l-90 {
        margin-left: auto;
        margin-right: 90px;
    }

    .rtl .mg-md-r-95 {
        margin-right: auto;
        margin-left: 95px;
    }

    .rtl .mg-md-l-95 {
        margin-left: auto;
        margin-right: 95px;
    }

    .rtl .mg-md-r-100 {
        margin-right: auto;
        margin-left: 100px;
    }

    .rtl .mg-md-l-100 {
        margin-left: auto;
        margin-right: 100px;
    }

    .rtl .mg-md-r-auto {
        margin-right: 0;
        margin-left: auto;
    }

    .rtl .mg-md-l-auto {
        margin-left: 0;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    .rtl .mg-lg-r-0 {
        margin-left: 0;
        margin-right: auto;
    }

    .rtl .mg-lg-l-0 {
        margin-right: 0;
        margin-left: auto;
    }

    .rtl .mg-lg-r-1 {
        margin-right: auto;
        margin-left: 1px;
    }

    .rtl .mg-lg-l-1 {
        margin-left: auto;
        margin-right: 1px;
    }

    .rtl .mg-lg-r-2 {
        margin-right: auto;
        margin-left: 2px;
    }

    .rtl .mg-lg-l-2 {
        margin-left: auto;
        margin-right: 2px;
    }

    .rtl .mg-lg-r-3 {
        margin-right: auto;
        margin-left: 3px;
    }

    .rtl .mg-lg-l-3 {
        margin-left: auto;
        margin-right: 3px;
    }

    .rtl .mg-lg-r-4 {
        margin-right: auto;
        margin-left: 4px;
    }

    .rtl .mg-lg-l-4 {
        margin-left: auto;
        margin-right: 4px;
    }

    .rtl .mg-lg-r-5 {
        margin-right: auto;
        margin-left: 5px;
    }

    .rtl .mg-lg-l-5 {
        margin-left: auto;
        margin-right: 5px;
    }

    .rtl .mg-lg-r-6 {
        margin-right: auto;
        margin-left: 6px;
    }

    .rtl .mg-lg-l-6 {
        margin-left: auto;
        margin-right: 6px;
    }

    .rtl .mg-lg-r-7 {
        margin-right: auto;
        margin-left: 7px;
    }

    .rtl .mg-lg-l-7 {
        margin-left: auto;
        margin-right: 7px;
    }

    .rtl .mg-lg-r-8 {
        margin-right: auto;
        margin-left: 8px;
    }

    .rtl .mg-lg-l-8 {
        margin-left: auto;
        margin-right: 8px;
    }

    .rtl .mg-lg-r-9 {
        margin-right: auto;
        margin-left: 9px;
    }

    .rtl .mg-lg-l-9 {
        margin-left: auto;
        margin-right: 9px;
    }

    .rtl .mg-lg-r-10 {
        margin-right: auto;
        margin-left: 10px;
    }

    .rtl .mg-lg-l-10 {
        margin-left: auto;
        margin-right: 10px;
    }

    .rtl .mg-lg-r-15 {
        margin-right: auto;
        margin-left: 15px;
    }

    .rtl .mg-lg-l-15 {
        margin-left: auto;
        margin-right: 15px;
    }

    .rtl .mg-lg-r-20 {
        margin-right: auto;
        margin-left: 20px;
    }

    .rtl .mg-lg-l-20 {
        margin-left: auto;
        margin-right: 20px;
    }

    .rtl .mg-lg-r-25 {
        margin-right: auto;
        margin-left: 25px;
    }

    .rtl .mg-lg-l-25 {
        margin-left: auto;
        margin-right: 25px;
    }

    .rtl .mg-lg-r-30 {
        margin-right: auto;
        margin-left: 30px;
    }

    .rtl .mg-lg-l-30 {
        margin-left: auto;
        margin-right: 30px;
    }

    .rtl .mg-lg-r-35 {
        margin-right: auto;
        margin-left: 35px;
    }

    .rtl .mg-lg-l-35 {
        margin-left: auto;
        margin-right: 35px;
    }

    .rtl .mg-lg-r-40 {
        margin-right: auto;
        margin-left: 40px;
    }

    .rtl .mg-lg-l-40 {
        margin-left: auto;
        margin-right: 40px;
    }

    .rtl .mg-lg-r-45 {
        margin-right: auto;
        margin-left: 45px;
    }

    .rtl .mg-lg-l-45 {
        margin-left: auto;
        margin-right: 45px;
    }

    .rtl .mg-lg-r-50 {
        margin-right: auto;
        margin-left: 50px;
    }

    .rtl .mg-lg-l-50 {
        margin-left: auto;
        margin-right: 50px;
    }

    .rtl .mg-lg-r-55 {
        margin-right: auto;
        margin-left: 55px;
    }

    .rtl .mg-lg-l-55 {
        margin-left: auto;
        margin-right: 55px;
    }

    .rtl .mg-lg-r-60 {
        margin-right: auto;
        margin-left: 60px;
    }

    .rtl .mg-lg-l-60 {
        margin-left: auto;
        margin-right: 60px;
    }

    .rtl .mg-lg-r-65 {
        margin-right: auto;
        margin-left: 65px;
    }

    .rtl .mg-lg-l-65 {
        margin-left: auto;
        margin-right: 65px;
    }

    .rtl .mg-lg-r-70 {
        margin-right: auto;
        margin-left: 70px;
    }

    .rtl .mg-lg-l-70 {
        margin-left: auto;
        margin-right: 70px;
    }

    .rtl .mg-lg-r-75 {
        margin-right: auto;
        margin-left: 75px;
    }

    .rtl .mg-lg-l-75 {
        margin-left: auto;
        margin-right: 75px;
    }

    .rtl .mg-lg-r-80 {
        margin-right: auto;
        margin-left: 80px;
    }

    .rtl .mg-lg-l-80 {
        margin-left: auto;
        margin-right: 80px;
    }

    .rtl .mg-lg-r-85 {
        margin-right: auto;
        margin-left: 85px;
    }

    .rtl .mg-lg-l-85 {
        margin-left: auto;
        margin-right: 85px;
    }

    .rtl .mg-lg-r-90 {
        margin-right: auto;
        margin-left: 90px;
    }

    .rtl .mg-lg-l-90 {
        margin-left: auto;
        margin-right: 90px;
    }

    .rtl .mg-lg-r-95 {
        margin-right: auto;
        margin-left: 95px;
    }

    .rtl .mg-lg-l-95 {
        margin-left: auto;
        margin-right: 95px;
    }

    .rtl .mg-lg-r-100 {
        margin-right: auto;
        margin-left: 100px;
    }

    .rtl .mg-lg-l-100 {
        margin-left: auto;
        margin-right: 100px;
    }

    .rtl .mg-lg-r-auto {
        margin-right: 0;
        margin-left: auto;
    }

    .rtl .mg-lg-l-auto {
        margin-left: 0;
        margin-right: auto;
    }
}

@media (min-width: 1200px) {
    .rtl .mg-xl-r-0 {
        margin-left: 0;
        margin-right: auto;
    }

    .rtl .mg-xl-l-0 {
        margin-right: 0;
        margin-left: auto;
    }

    .rtl .mg-xl-r-1 {
        margin-right: auto;
        margin-left: 1px;
    }

    .rtl .mg-xl-l-1 {
        margin-left: auto;
        margin-right: 1px;
    }

    .rtl .mg-xl-r-2 {
        margin-right: auto;
        margin-left: 2px;
    }

    .rtl .mg-xl-l-2 {
        margin-left: auto;
        margin-right: 2px;
    }

    .rtl .mg-xl-r-3 {
        margin-right: auto;
        margin-left: 3px;
    }

    .rtl .mg-xl-l-3 {
        margin-left: auto;
        margin-right: 3px;
    }

    .rtl .mg-xl-r-4 {
        margin-right: auto;
        margin-left: 4px;
    }

    .rtl .mg-xl-l-4 {
        margin-left: auto;
        margin-right: 4px;
    }

    .rtl .mg-xl-r-5 {
        margin-right: auto;
        margin-left: 5px;
    }

    .rtl .mg-xl-l-5 {
        margin-left: auto;
        margin-right: 5px;
    }

    .rtl .mg-xl-r-6 {
        margin-right: auto;
        margin-left: 6px;
    }

    .rtl .mg-xl-l-6 {
        margin-left: auto;
        margin-right: 6px;
    }

    .rtl .mg-xl-r-7 {
        margin-right: auto;
        margin-left: 7px;
    }

    .rtl .mg-xl-l-7 {
        margin-left: auto;
        margin-right: 7px;
    }

    .rtl .mg-xl-r-8 {
        margin-right: auto;
        margin-left: 8px;
    }

    .rtl .mg-xl-l-8 {
        margin-left: auto;
        margin-right: 8px;
    }

    .rtl .mg-xl-r-9 {
        margin-right: auto;
        margin-left: 9px;
    }

    .rtl .mg-xl-l-9 {
        margin-left: auto;
        margin-right: 9px;
    }

    .rtl .mg-xl-r-10 {
        margin-right: auto;
        margin-left: 10px;
    }

    .rtl .mg-xl-l-10 {
        margin-left: auto;
        margin-right: 10px;
    }

    .rtl .mg-xl-r-15 {
        margin-right: auto;
        margin-left: 15px;
    }

    .rtl .mg-xl-l-15 {
        margin-left: auto;
        margin-right: 15px;
    }

    .rtl .mg-xl-r-20 {
        margin-right: auto;
        margin-left: 20px;
    }

    .rtl .mg-xl-l-20 {
        margin-left: auto;
        margin-right: 20px;
    }

    .rtl .mg-xl-r-25 {
        margin-right: auto;
        margin-left: 25px;
    }

    .rtl .mg-xl-l-25 {
        margin-left: auto;
        margin-right: 25px;
    }

    .rtl .mg-xl-r-30 {
        margin-right: auto;
        margin-left: 30px;
    }

    .rtl .mg-xl-l-30 {
        margin-left: auto;
        margin-right: 30px;
    }

    .rtl .mg-xl-r-35 {
        margin-right: auto;
        margin-left: 35px;
    }

    .rtl .mg-xl-l-35 {
        margin-left: auto;
        margin-right: 35px;
    }

    .rtl .mg-xl-r-40 {
        margin-right: auto;
        margin-left: 40px;
    }

    .rtl .mg-xl-l-40 {
        margin-left: auto;
        margin-right: 40px;
    }

    .rtl .mg-xl-r-45 {
        margin-right: auto;
        margin-left: 45px;
    }

    .rtl .mg-xl-l-45 {
        margin-left: auto;
        margin-right: 45px;
    }

    .rtl .mg-xl-r-50 {
        margin-right: auto;
        margin-left: 50px;
    }

    .rtl .mg-xl-l-50 {
        margin-left: auto;
        margin-right: 50px;
    }

    .rtl .mg-xl-r-55 {
        margin-right: auto;
        margin-left: 55px;
    }

    .rtl .mg-xl-l-55 {
        margin-left: auto;
        margin-right: 55px;
    }

    .rtl .mg-xl-r-60 {
        margin-right: auto;
        margin-left: 60px;
    }

    .rtl .mg-xl-l-60 {
        margin-left: auto;
        margin-right: 60px;
    }

    .rtl .mg-xl-r-65 {
        margin-right: auto;
        margin-left: 65px;
    }

    .rtl .mg-xl-l-65 {
        margin-left: auto;
        margin-right: 65px;
    }

    .rtl .mg-xl-r-70 {
        margin-right: auto;
        margin-left: 70px;
    }

    .rtl .mg-xl-l-70 {
        margin-left: auto;
        margin-right: 70px;
    }

    .rtl .mg-xl-r-75 {
        margin-right: auto;
        margin-left: 75px;
    }

    .rtl .mg-xl-l-75 {
        margin-left: auto;
        margin-right: 75px;
    }

    .rtl .mg-xl-r-80 {
        margin-right: auto;
        margin-left: 80px;
    }

    .rtl .mg-xl-l-80 {
        margin-left: auto;
        margin-right: 80px;
    }

    .rtl .mg-xl-r-85 {
        margin-right: auto;
        margin-left: 85px;
    }

    .rtl .mg-xl-l-85 {
        margin-left: auto;
        margin-right: 85px;
    }

    .rtl .mg-xl-r-90 {
        margin-right: auto;
        margin-left: 90px;
    }

    .rtl .mg-xl-l-90 {
        margin-left: auto;
        margin-right: 90px;
    }

    .rtl .mg-xl-r-95 {
        margin-right: auto;
        margin-left: 95px;
    }

    .rtl .mg-xl-l-95 {
        margin-left: auto;
        margin-right: 95px;
    }

    .rtl .mg-xl-r-100 {
        margin-right: auto;
        margin-left: 100px;
    }

    .rtl .mg-xl-l-100 {
        margin-left: auto;
        margin-right: 100px;
    }

    .rtl .mg-xl-r-auto {
        margin-right: 0;
        margin-left: auto;
    }

    .rtl .mg-xl-l-auto {
        margin-left: 0;
        margin-right: auto;
    }
}

.rtl .me-0 {
    margin-right: auto !important;
    margin-left: 0 !important;
}

.rtl .ms-0 {
    margin-left: auto !important;
    margin-right: 0 !important;
}

.rtl .me-1 {
    margin-right: auto !important;
    margin-left: 0.25rem !important;
}

.rtl .ms-1 {
    margin-left: auto !important;
    margin-right: 0.25rem !important;
}

.rtl .me-2 {
    margin-right: auto !important;
    margin-left: 0.5rem !important;
}

.rtl .ms-2 {
    margin-left: auto !important;
    margin-right: 0.5rem !important;
}

.rtl .me-3 {
    margin-right: auto !important;
    margin-left: 1rem !important;
}

.rtl .ms-3 {
    margin-left: auto !important;
    margin-right: 1rem !important;
}

.rtl .me-4 {
    margin-right: auto !important;
    margin-left: 1.5rem !important;
}

.rtl .ms-4 {
    margin-left: auto !important;
    margin-right: 1.5rem !important;
}

.rtl .me-5 {
    margin-right: auto !important;
    margin-left: 3rem !important;
}

.rtl .ms-5 {
    margin-left: auto !important;
    margin-right: 3rem !important;
}

.rtl .pe-0 {
    padding-right: inherit !important;
    padding-left: 0 !important;
}

.rtl .ps-0 {
    padding-left: initial !important;
    padding-right: 0 !important;
}

.rtl .pe-1 {
    padding-right: inherit !important;
    padding-left: 0.25rem !important;
}

.rtl .ps-1 {
    padding-left: inherit !important;
    padding-right: 0.25rem !important;
}

.rtl .pe-2 {
    padding-right: inherit !important;
    padding-left: 0.5rem !important;
}

.rtl .ps-2 {
    padding-left: inherit !important;
    padding-right: 0.5rem !important;
}

.rtl .pe-3 {
    padding-right: inherit !important;
    padding-left: 1rem !important;
}

.rtl .ps-3 {
    padding-left: inherit !important;
    padding-right: 1rem !important;
}

.rtl .pe-4 {
    padding-right: inherit !important;
    padding-left: 1.5rem !important;
}

.rtl .ps-4 {
    padding-left: inherit !important;
    padding-right: 1.5rem !important;
}

.rtl .pe-5 {
    padding-right: inherit !important;
    padding-left: 3rem !important;
}

.rtl .ps-5 {
    padding-left: inherit !important;
    padding-right: 3rem !important;
}

.rtl .me-auto {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.rtl .ms-auto {
    margin-left: 0 !important;
    margin-right: auto !important;
}

@media (min-width: 576px) {
    .rtl .me-sm-0 {
        margin-left: 0 !important;
        margin-right: auto !important;
    }

    .rtl .ms-sm-0 {
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    .rtl .me-sm-1 {
        margin-right: auto !important;
        margin-left: 0.25rem !important;
    }

    .rtl .ms-sm-1 {
        margin-left: auto !important;
        margin-right: 0.25rem !important;
    }

    .rtl .me-sm-2 {
        margin-right: auto !important;
        margin-left: 0.5rem !important;
    }

    .rtl .ms-sm-2 {
        margin-left: auto !important;
        margin-right: 0.5rem !important;
    }

    .rtl .me-sm-3 {
        margin-right: auto !important;
        margin-left: 1rem !important;
    }

    .rtl .ms-sm-3 {
        margin-left: auto !important;
        margin-right: 1rem !important;
    }

    .rtl .me-sm-4 {
        margin-right: auto !important;
        margin-left: 1.5rem !important;
    }

    .rtl .ms-sm-4 {
        margin-left: auto !important;
        margin-right: 1.5rem !important;
    }

    .rtl .me-sm-5 {
        margin-right: auto !important;
        margin-left: 3rem !important;
    }

    .rtl .ms-sm-5 {
        margin-left: auto !important;
        margin-right: 3rem !important;
    }

    .rtl .pe-sm-0 {
        padding-right: inherit !important;
        padding-left: 0 !important;
    }

    .rtl .ps-sm-0 {
        padding-left: inherit !important;
        padding-right: 0 !important;
    }

    .rtl .pe-sm-1 {
        padding-right: inherit !important;
        padding-left: 0.25rem !important;
    }

    .rtl .ps-sm-1 {
        padding-left: inherit !important;
        padding-right: 0.25rem !important;
    }

    .rtl .pe-sm-2 {
        padding-right: inherit !important;
        padding-left: 0.5rem !important;
    }

    .rtl .ps-sm-2 {
        padding-left: inherit !important;
        padding-right: 0.5rem !important;
    }

    .rtl .pe-sm-3 {
        padding-right: inherit !important;
        padding-left: 1rem !important;
    }

    .rtl .ps-sm-3 {
        padding-left: inherit !important;
        padding-right: 1rem !important;
    }

    .rtl .pe-sm-4 {
        padding-right: inherit !important;
        padding-left: 1.5rem !important;
    }

    .rtl .ps-sm-4 {
        padding-left: inherit !important;
        padding-right: 1.5rem !important;
    }

    .rtl .pe-sm-5 {
        padding-right: inherit !important;
        padding-left: 3rem !important;
    }

    .rtl .ps-sm-5 {
        padding-left: inherit !important;
        padding-right: 3rem !important;
    }

    .rtl .me-sm-n1 {
        margin-right: auto !important;
        margin-left: -0.25rem !important;
    }

    .rtl .ms-sm-n1 {
        margin-left: auto !important;
        margin-right: -0.25rem !important;
    }

    .rtl .me-sm-n2 {
        margin-right: auto !important;
        margin-left: -0.5rem !important;
    }

    .rtl .ms-sm-n2 {
        margin-left: auto !important;
        margin-right: -0.5rem !important;
    }

    .rtl .me-sm-n3 {
        margin-right: auto !important;
        margin-left: -1rem !important;
    }

    .rtl .ms-sm-n3 {
        margin-left: auto !important;
        margin-right: -1rem !important;
    }

    .rtl .me-sm-n4 {
        margin-right: auto !important;
        margin-left: -1.5rem !important;
    }

    .rtl .ms-sm-n4 {
        margin-left: auto !important;
        margin-right: -1.5rem !important;
    }

    .rtl .me-sm-n5 {
        margin-right: auto !important;
        margin-left: -3rem !important;
    }

    .rtl .ms-sm-n5 {
        margin-left: auto !important;
        margin-right: -3rem !important;
    }

    .rtl .me-sm-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    .rtl .ms-sm-auto {
        margin-left: 0 !important;
        margin-right: auto !important;
    }
}

@media (min-width: 768px) {
    .rtl .me-md-0 {
        margin-right: auto !important;
        margin-left: 0 !important;
    }

    .rtl .ms-md-0 {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    .rtl .me-md-1 {
        margin-right: auto !important;
        margin-left: 0.25rem !important;
    }

    .rtl .ms-md-1 {
        margin-left: auto !important;
        margin-right: 0.25rem !important;
    }

    .rtl .me-md-2 {
        margin-right: auto !important;
        margin-left: 0.5rem !important;
    }

    .rtl .ms-md-2 {
        margin-left: auto !important;
        margin-right: 0.5rem !important;
    }

    .rtl .me-md-3 {
        margin-right: auto !important;
        margin-left: 1rem !important;
    }

    .rtl .ms-md-3 {
        margin-left: auto !important;
        margin-right: 1rem !important;
    }

    .rtl .me-md-4 {
        margin-right: auto !important;
        margin-left: 1.5rem !important;
    }

    .rtl .ms-md-4 {
        margin-left: 0 !important;
        margin-right: 1.5rem !important;
    }

    .rtl .me-md-5 {
        margin-right: auto !important;
        margin-left: 3rem !important;
    }

    .rtl .ms-md-5 {
        margin-left: auto !important;
        margin-right: 3rem !important;
    }

    .rtl .pe-md-0 {
        padding-right: inherit !important;
        padding-left: 0 !important;
    }

    .rtl .ps-md-0 {
        padding-left: inherit !important;
        padding-right: 0 !important;
    }

    .rtl .pe-md-1 {
        padding-right: inherit !important;
        padding-left: 0.25rem !important;
    }

    .rtl .ps-md-1 {
        padding-left: inherit !important;
        padding-right: 0.25rem !important;
    }

    .rtl .pe-md-2 {
        padding-right: inherit !important;
        padding-left: 0.5rem !important;
    }

    .rtl .ps-md-2 {
        padding-left: inherit !important;
        padding-right: 0.5rem !important;
    }

    .rtl .pe-md-3 {
        padding-right: inherit !important;
        padding-left: 1rem !important;
    }

    .rtl .ps-md-3 {
        padding-left: inherit !important;
        padding-right: 1rem !important;
    }

    .rtl .pe-md-4 {
        padding-right: inherit !important;
        padding-left: 1.5rem !important;
    }

    .rtl .ps-md-4 {
        padding-left: inherit !important;
        padding-right: 1.5rem !important;
    }

    .rtl .pe-md-5 {
        padding-right: inherit !important;
        padding-left: 3rem !important;
    }

    .rtl .ps-md-5 {
        padding-left: inherit !important;
        padding-right: 3rem !important;
    }

    .rtl .me-md-n1 {
        margin-right: auto !important;
        margin-left: -0.25rem !important;
    }

    .rtl .ms-md-n1 {
        margin-left: auto !important;
        margin-right: -0.25rem !important;
    }

    .rtl .me-md-n2 {
        margin-right: auto !important;
        margin-left: -0.5rem !important;
    }

    .rtl .ms-md-n2 {
        margin-left: auto !important;
        margin-right: -0.5rem !important;
    }

    .rtl .me-md-n3 {
        margin-right: auto !important;
        margin-left: -1rem !important;
    }

    .rtl .ms-md-n3 {
        margin-left: auto !important;
        margin-right: -1rem !important;
    }

    .rtl .me-md-n4 {
        margin-right: auto !important;
        margin-left: -1.5rem !important;
    }

    .rtl .ms-md-n4 {
        margin-left: auto !important;
        margin-right: -1.5rem !important;
    }

    .rtl .me-md-n5 {
        margin-right: auto !important;
        margin-left: -3rem !important;
    }

    .rtl .ms-md-n5 {
        margin-left: auto !important;
        margin-right: -3rem !important;
    }

    .rtl .me-md-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    .rtl .ms-md-auto {
        margin-left: 0 !important;
        margin-right: auto !important;
    }
}

@media (min-width: 992px) {
    .rtl .me-lg-0 {
        margin-right: auto !important;
        margin-left: 0 !important;
    }

    .rtl .ms-lg-0 {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    .rtl .me-lg-1 {
        margin-right: auto !important;
        margin-left: 0.25rem !important;
    }

    .rtl .ms-lg-1 {
        margin-left: auto !important;
        margin-right: 0.25rem !important;
    }

    .rtl .me-lg-2 {
        margin-right: auto !important;
        margin-left: 0.5rem !important;
    }

    .rtl .ms-lg-2 {
        margin-left: auto !important;
        margin-right: 0.5rem !important;
    }

    .rtl .me-lg-3 {
        margin-right: auto !important;
        margin-left: 1rem !important;
    }

    .rtl .ms-lg-3 {
        margin-left: auto !important;
        margin-right: 1rem !important;
    }

    .rtl .me-lg-4 {
        margin-right: auto !important;
        margin-left: 1.5rem !important;
    }

    .rtl .ms-lg-4 {
        margin-left: auto !important;
        margin-right: 1.5rem !important;
    }

    .rtl .me-lg-5 {
        margin-right: auto !important;
        margin-left: 3rem !important;
    }

    .rtl .ms-lg-5 {
        margin-left: auto !important;
        margin-right: 3rem !important;
    }

    .rtl .pe-lg-0 {
        padding-right: inherit !important;
        padding-left: 0 !important;
    }

    .rtl .ps-lg-0 {
        padding-left: inherit !important;
        padding-right: 0 !important;
    }

    .rtl .pe-lg-1 {
        padding-right: inherit !important;
        padding-left: 0.25rem !important;
    }

    .rtl .ps-lg-1 {
        padding-left: inherit !important;
        padding-right: 0.25rem !important;
    }

    .rtl .pe-lg-2 {
        padding-right: inherit !important;
        padding-left: 0.5rem !important;
    }

    .rtl .ps-lg-2 {
        padding-left: inherit !important;
        padding-right: 0.5rem !important;
    }

    .rtl .pe-lg-3 {
        padding-right: inherit !important;
        padding-left: 1rem !important;
    }

    .rtl .ps-lg-3 {
        padding-left: inherit !important;
        padding-right: 1rem !important;
    }

    .rtl .pe-lg-4 {
        padding-right: inherit !important;
        padding-left: 1.5rem !important;
    }

    .rtl .ps-lg-4 {
        padding-left: inherit !important;
        padding-right: 1.5rem !important;
    }

    .rtl .pe-lg-5 {
        padding-right: inherit !important;
        padding-left: 3rem !important;
    }

    .rtl .ps-lg-5 {
        padding-left: inherit !important;
        padding-right: 3rem !important;
    }

    .rtl .me-lg-n1 {
        margin-right: auto !important;
        margin-left: -0.25rem !important;
    }

    .rtl .ms-lg-n1 {
        margin-left: auto !important;
        margin-right: -0.25rem !important;
    }

    .rtl .me-lg-n2 {
        margin-right: auto !important;
        margin-left: -0.5rem !important;
    }

    .rtl .ms-lg-n2 {
        margin-left: auto !important;
        margin-right: -0.5rem !important;
    }

    .rtl .me-lg-n3 {
        margin-right: auto !important;
        margin-left: -1rem !important;
    }

    .rtl .ms-lg-n3 {
        margin-left: auto !important;
        margin-right: -1rem !important;
    }

    .rtl .me-lg-n4 {
        margin-right: auto !important;
        margin-left: -1.5rem !important;
    }

    .rtl .ms-lg-n4 {
        margin-left: auto !important;
        margin-right: -1.5rem !important;
    }

    .rtl .me-lg-n5 {
        margin-right: auto !important;
        margin-left: -3rem !important;
    }

    .rtl .ms-lg-n5 {
        margin-left: auto !important;
        margin-right: -3rem !important;
    }

    .rtl .me-lg-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    .rtl .ms-lg-auto {
        margin-left: 0 !important;
        margin-right: auto !important;
    }
}

@media (min-width: 1200px) {
    .rtl .me-xl-0 {
        margin-left: 0 !important;
        margin-right: auto !important;
    }

    .rtl .ms-xl-0 {
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    .rtl .me-xl-1 {
        margin-right: auto !important;
        margin-left: 0.25rem !important;
    }

    .rtl .ms-xl-1 {
        margin-left: auto !important;
        margin-right: 0.25rem !important;
    }

    .rtl .me-xl-2 {
        margin-right: auto !important;
        margin-left: 0.5rem !important;
    }

    .rtl .ms-xl-2 {
        margin-left: auto !important;
        margin-right: 0.5rem !important;
    }

    .rtl .me-xl-3 {
        margin-right: auto !important;
        margin-left: 1rem !important;
    }

    .rtl .ms-xl-3 {
        margin-left: auto !important;
        margin-right: 1rem !important;
    }

    .rtl .me-xl-4 {
        margin-right: auto !important;
        margin-left: 1.5rem !important;
    }

    .rtl .ms-xl-4 {
        margin-left: auto !important;
        margin-right: 1.5rem !important;
    }

    .rtl .me-xl-5 {
        margin-right: auto !important;
        margin-left: 3rem !important;
    }

    .rtl .ms-xl-5 {
        margin-left: auto !important;
        margin-right: 3rem !important;
    }

    .rtl .pe-xl-0 {
        padding-right: inherit !important;
        padding-left: 0 !important;
    }

    .rtl .ps-xl-0 {
        padding-left: inherit !important;
        padding-right: 0 !important;
    }

    .rtl .pe-xl-1 {
        padding-right: inherit !important;
        padding-left: 0.25rem !important;
    }

    .rtl .ps-xl-1 {
        padding-left: inherit !important;
        padding-right: 0.25rem !important;
    }

    .rtl .pe-xl-2 {
        padding-right: inherit !important;
        padding-left: 0.5rem !important;
    }

    .rtl .ps-xl-2 {
        padding-left: inherit !important;
        padding-right: 0.5rem !important;
    }

    .rtl .pe-xl-3 {
        padding-right: inherit !important;
        padding-left: 1rem !important;
    }

    .rtl .ps-xl-3 {
        padding-left: inherit !important;
        padding-right: 1rem !important;
    }

    .rtl .pe-xl-4 {
        padding-right: inherit !important;
        padding-left: 1.5rem !important;
    }

    .rtl .ps-xl-4 {
        padding-left: inherit !important;
        padding-right: 1.5rem !important;
    }

    .rtl .pe-xl-5 {
        padding-right: inherit !important;
        padding-left: 3rem !important;
    }

    .rtl .ps-xl-5 {
        padding-left: inherit !important;
        padding-right: 3rem !important;
    }

    .rtl .me-xl-n1 {
        margin-right: auto !important;
        margin-left: -0.25rem !important;
    }

    .rtl .ms-xl-n1 {
        margin-left: auto !important;
        margin-right: -0.25rem !important;
    }

    .rtl .me-xl-n2 {
        margin-right: auto !important;
        margin-left: -0.5rem !important;
    }

    .rtl .ms-xl-n2 {
        margin-left: auto !important;
        margin-right: -0.5rem !important;
    }

    .rtl .me-xl-n3 {
        margin-right: auto !important;
        margin-left: -1rem !important;
    }

    .rtl .ms-xl-n3 {
        margin-left: auto !important;
        margin-right: -1rem !important;
    }

    .rtl .me-xl-n4 {
        margin-right: auto !important;
        margin-left: -1.5rem !important;
    }

    .rtl .ms-xl-n4 {
        margin-left: auto !important;
        margin-right: -1.5rem !important;
    }

    .rtl .me-xl-n5 {
        margin-right: auto !important;
        margin-left: -3rem !important;
    }

    .rtl .ms-xl-n5 {
        margin-left: auto !important;
        margin-right: -3rem !important;
    }

    .rtl .me-xl-auto {
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    .rtl .ms-xl-auto {
        margin-left: 0 !important;
        margin-right: auto !important;
    }
}

.rtl .pd-r-0 {
    padding-right: inherit;
    padding-left: 0px;
}

.rtl .pd-l-0 {
    padding-left: inherit;
    padding-right: 0px;
}

.rtl .pd-r-1 {
    padding-right: inherit;
    padding-left: 1px;
}

.rtl .pd-l-1 {
    padding-left: inherit;
    padding-right: 1px;
}

.rtl .pd-r-2 {
    padding-right: inherit;
    padding-left: 2px;
}

.rtl .pd-l-2 {
    padding-left: inherit;
    padding-right: 2px;
}

.rtl .pd-r-3 {
    padding-right: inherit;
    padding-left: 3px;
}

.rtl .pd-l-3 {
    padding-left: inherit;
    padding-right: 3px;
}

.rtl .pd-r-4 {
    padding-right: inherit;
    padding-left: 4px;
}

.rtl .pd-l-4 {
    padding-left: inherit;
    padding-right: 4px;
}

.rtl .pd-r-5 {
    padding-right: inherit;
    padding-left: 5px;
}

.rtl .pd-l-5 {
    padding-left: inherit;
    padding-right: 5px;
}

.rtl .pd-r-6 {
    padding-right: inherit;
    padding-left: 6px;
}

.rtl .pd-l-6 {
    padding-left: inherit;
    padding-right: 6px;
}

.rtl .pd-r-7 {
    padding-right: inherit;
    padding-left: 7px;
}

.rtl .pd-l-7 {
    padding-left: inherit;
    padding-right: 7px;
}

.rtl .pd-r-8 {
    padding-right: inherit;
    padding-left: 8px;
}

.rtl .pd-l-8 {
    padding-left: inherit;
    padding-right: 8px;
}

.rtl .pd-r-9 {
    padding-right: inherit;
    padding-left: 9px;
}

.rtl .pd-l-9 {
    padding-left: inherit;
    padding-right: 9px;
}

.rtl .pd-r-10 {
    padding-right: inherit;
    padding-left: 10px;
}

.rtl .pd-l-10 {
    padding-left: inherit;
    padding-right: 10px;
}

.rtl .pd-r-15 {
    padding-right: inherit;
    padding-left: 15px;
}

.rtl .pd-l-15 {
    padding-left: inherit;
    padding-right: 15px;
}

.rtl .pd-r-20 {
    padding-right: inherit;
    padding-left: 20px;
}

.rtl .pd-l-20 {
    padding-left: inherit;
    padding-right: 20px;
}

.rtl .pd-r-25 {
    padding-right: inherit;
    padding-left: 25px;
}

.rtl .pd-l-25 {
    padding-left: inherit;
    padding-right: 25px;
}

.rtl .pd-r-30 {
    padding-right: inherit;
    padding-left: 30px;
}

.rtl .pd-l-30 {
    padding-left: inherit;
    padding-right: 30px;
}

.rtl .pd-r-35 {
    padding-right: inherit;
    padding-left: 35px;
}

.rtl .pd-l-35 {
    padding-left: inherit;
    padding-right: 35px;
}

.rtl .pd-r-40 {
    padding-right: inherit;
    padding-left: 40px;
}

.rtl .pd-l-40 {
    padding-left: inherit;
    padding-right: 40px;
}

.rtl .pd-r-45 {
    padding-right: inherit;
    padding-left: 45px;
}

.rtl .pd-l-45 {
    padding-left: inherit;
    padding-right: 45px;
}

.rtl .pd-r-50 {
    padding-right: inherit;
    padding-left: 50px;
}

.rtl .pd-l-50 {
    padding-left: inherit;
    padding-right: 50px;
}

.rtl .pd-r-55 {
    padding-right: inherit;
    padding-left: 55px;
}

.rtl .pd-l-55 {
    padding-left: inherit;
    padding-right: 55px;
}

.rtl .pd-r-60 {
    padding-right: inherit;
    padding-left: 60px;
}

.rtl .pd-l-60 {
    padding-left: inherit;
    padding-right: 60px;
}

.rtl .pd-r-65 {
    padding-right: inherit;
    padding-left: 65px;
}

.rtl .pd-l-65 {
    padding-left: inherit;
    padding-right: 65px;
}

.rtl .pd-r-70 {
    padding-right: inherit;
    padding-left: 70px;
}

.rtl .pd-l-70 {
    padding-left: inherit;
    padding-right: 70px;
}

.rtl .pd-r-75 {
    padding-right: inherit;
    padding-left: 75px;
}

.rtl .pd-l-75 {
    padding-left: inherit;
    padding-right: 75px;
}

.rtl .pd-r-80 {
    padding-right: inherit;
    padding-left: 80px;
}

.rtl .pd-l-80 {
    padding-left: inherit;
    padding-right: 80px;
}

.rtl .pd-r-85 {
    padding-right: inherit;
    padding-left: 85px;
}

.rtl .pd-l-85 {
    padding-left: inherit;
    padding-right: 85px;
}

.rtl .pd-r-90 {
    padding-right: inherit;
    padding-left: 90px;
}

.rtl .pd-l-90 {
    padding-left: inherit;
    padding-right: 90px;
}

.rtl .pd-r-95 {
    padding-right: inherit;
    padding-left: 95px;
}

.rtl .pd-l-95 {
    padding-left: inherit;
    padding-right: 95px;
}

.rtl .pd-r-100 {
    padding-right: inherit;
    padding-left: 100px;
}

.rtl .pd-l-100 {
    padding-left: inherit;
    padding-right: 100px;
}

.rtl .pd-r-110 {
    padding-right: inherit;
    padding-left: 110px;
}

.rtl .pd-l-110 {
    padding-left: inherit;
    padding-right: 110px;
}

.rtl .pd-r-120 {
    padding-right: inherit;
    padding-left: 120px;
}

.rtl .pd-l-120 {
    padding-left: inherit;
    padding-right: 120px;
}

.rtl .pd-r-130 {
    padding-right: inherit;
    padding-left: 130px;
}

.rtl .pd-l-130 {
    padding-left: inherit;
    padding-right: 130px;
}

.rtl .pd-r-140 {
    padding-right: inherit;
    padding-left: 140px;
}

.rtl .pd-l-140 {
    padding-left: inherit;
    padding-right: 140px;
}

.rtl .pd-r-150 {
    padding-right: inherit;
    padding-left: 150px;
}

.rtl .pd-l-150 {
    padding-left: inherit;
    padding-right: 150px;
}

.rtl .pd-r-160 {
    padding-right: inherit;
    padding-left: 160px;
}

.rtl .pd-l-160 {
    padding-left: inherit;
    padding-right: 160px;
}

.rtl .pd-r-170 {
    padding-right: inherit;
    padding-left: 170px;
}

.rtl .pd-l-170 {
    padding-left: inherit;
    padding-right: 170px;
}

.rtl .pd-r-180 {
    padding-right: inherit;
    padding-left: 180px;
}

.rtl .pd-l-180 {
    padding-left: inherit;
    padding-right: 180px;
}

.rtl .pd-r-190 {
    padding-right: inherit;
    padding-left: 190px;
}

.rtl .pd-l-190 {
    padding-left: inherit;
    padding-right: 190px;
}

.rtl .pd-r-200 {
    padding-right: inherit;
    padding-left: 200px;
}

.rtl .pd-l-200 {
    padding-left: inherit;
    padding-right: 200px;
}

@media (min-width: 480px) {
    .rtl .pd-xs-r-0 {
        padding-right: inherit;
        padding-left: 0px;
    }

    .rtl .pd-xs-l-0 {
        padding-left: inherit;
        padding-right: 0px;
    }

    .rtl .pd-xs-r-1 {
        padding-right: inherit;
        padding-left: 1px;
    }

    .rtl .pd-xs-l-1 {
        padding-left: inherit;
        padding-right: 1px;
    }

    .rtl .pd-xs-r-2 {
        padding-right: inherit;
        padding-left: 2px;
    }

    .rtl .pd-xs-l-2 {
        padding-left: inherit;
        padding-right: 2px;
    }

    .rtl .pd-xs-r-3 {
        padding-right: inherit;
        padding-left: 3px;
    }

    .rtl .pd-xs-l-3 {
        padding-left: inherit;
        padding-right: 3px;
    }

    .rtl .pd-xs-r-4 {
        padding-right: inherit;
        padding-left: 4px;
    }

    .rtl .pd-xs-l-4 {
        padding-left: inherit;
        padding-right: 4px;
    }

    .rtl .pd-xs-r-5 {
        padding-right: inherit;
        padding-left: 5px;
    }

    .rtl .pd-xs-l-5 {
        padding-left: inherit;
        padding-right: 5px;
    }

    .rtl .pd-xs-r-6 {
        padding-right: inherit;
        padding-left: 6px;
    }

    .rtl .pd-xs-l-6 {
        padding-left: inherit;
        padding-right: 6px;
    }

    .rtl .pd-xs-r-7 {
        padding-right: inherit;
        padding-left: 7px;
    }

    .rtl .pd-xs-l-7 {
        padding-left: inherit;
        padding-right: 7px;
    }

    .rtl .pd-xs-r-8 {
        padding-right: inherit;
        padding-left: 8px;
    }

    .rtl .pd-xs-l-8 {
        padding-left: inherit;
        padding-right: 8px;
    }

    .rtl .pd-xs-r-9 {
        padding-right: inherit;
        padding-left: 9px;
    }

    .rtl .pd-xs-l-9 {
        padding-left: inherit;
        padding-right: 9px;
    }

    .rtl .pd-xs-r-10 {
        padding-right: inherit;
        padding-left: 10px;
    }

    .rtl .pd-xs-l-10 {
        padding-left: inherit;
        padding-right: 10px;
    }

    .rtl .pd-xs-r-15 {
        padding-right: inherit;
        padding-left: 15px;
    }

    .rtl .pd-xs-l-15 {
        padding-left: inherit;
        padding-right: 15px;
    }

    .rtl .pd-xs-r-20 {
        padding-right: inherit;
        padding-left: 20px;
    }

    .rtl .pd-xs-l-20 {
        padding-left: inherit;
        padding-right: 20px;
    }

    .rtl .pd-xs-r-25 {
        padding-right: inherit;
        padding-left: 25px;
    }

    .rtl .pd-xs-l-25 {
        padding-left: inherit;
        padding-right: 25px;
    }

    .rtl .pd-xs-r-30 {
        padding-right: inherit;
        padding-left: 30px;
    }

    .rtl .pd-xs-l-30 {
        padding-left: inherit;
        padding-right: 30px;
    }

    .rtl .pd-xs-r-35 {
        padding-right: inherit;
        padding-left: 35px;
    }

    .rtl .pd-xs-l-35 {
        padding-left: inherit;
        padding-right: 35px;
    }

    .rtl .pd-xs-r-40 {
        padding-right: inherit;
        padding-left: 40px;
    }

    .rtl .pd-xs-l-40 {
        padding-left: inherit;
        padding-right: 40px;
    }

    .rtl .pd-xs-r-45 {
        padding-right: inherit;
        padding-left: 45px;
    }

    .rtl .pd-xs-l-45 {
        padding-left: inherit;
        padding-right: 45px;
    }

    .rtl .pd-xs-r-50 {
        padding-right: inherit;
        padding-left: 50px;
    }

    .rtl .pd-xs-l-50 {
        padding-left: inherit;
        padding-right: 50px;
    }

    .rtl .pd-xs-r-55 {
        padding-right: inherit;
        padding-left: 55px;
    }

    .rtl .pd-xs-l-55 {
        padding-left: inherit;
        padding-right: 55px;
    }

    .rtl .pd-xs-r-60 {
        padding-right: inherit;
        padding-left: 60px;
    }

    .rtl .pd-xs-l-60 {
        padding-left: inherit;
        padding-right: 60px;
    }

    .rtl .pd-xs-r-65 {
        padding-right: inherit;
        padding-left: 65px;
    }

    .rtl .pd-xs-l-65 {
        padding-left: inherit;
        padding-right: 65px;
    }

    .rtl .pd-xs-r-70 {
        padding-right: inherit;
        padding-left: 70px;
    }

    .rtl .pd-xs-l-70 {
        padding-left: inherit;
        padding-right: 70px;
    }

    .rtl .pd-xs-r-75 {
        padding-right: inherit;
        padding-left: 75px;
    }

    .rtl .pd-xs-l-75 {
        padding-left: inherit;
        padding-right: 75px;
    }

    .rtl .pd-xs-r-80 {
        padding-right: inherit;
        padding-left: 80px;
    }

    .rtl .pd-xs-l-80 {
        padding-left: inherit;
        padding-right: 80px;
    }

    .rtl .pd-xs-r-85 {
        padding-right: inherit;
        padding-left: 85px;
    }

    .rtl .pd-xs-l-85 {
        padding-left: inherit;
        padding-right: 85px;
    }

    .rtl .pd-xs-r-90 {
        padding-right: inherit;
        padding-left: 90px;
    }

    .rtl .pd-xs-l-90 {
        padding-left: inherit;
        padding-right: 90px;
    }

    .rtl .pd-xs-r-95 {
        padding-right: inherit;
        padding-left: 95px;
    }

    .rtl .pd-xs-l-95 {
        padding-left: inherit;
        padding-right: 95px;
    }

    .rtl .pd-xs-r-100 {
        padding-right: inherit;
        padding-left: 100px;
    }

    .rtl .pd-xs-l-100 {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl .pd-xs-r-110 {
        padding-right: inherit;
        padding-left: 110px;
    }

    .rtl .pd-xs-l-110 {
        padding-left: inherit;
        padding-right: 110px;
    }

    .rtl .pd-xs-r-120 {
        padding-right: inherit;
        padding-left: 120px;
    }

    .rtl .pd-xs-l-120 {
        padding-left: inherit;
        padding-right: 120px;
    }

    .rtl .pd-xs-r-130 {
        padding-right: inherit;
        padding-left: 130px;
    }

    .rtl .pd-xs-l-130 {
        padding-left: inherit;
        padding-right: 130px;
    }

    .rtl .pd-xs-r-140 {
        padding-right: inherit;
        padding-left: 140px;
    }

    .rtl .pd-xs-l-140 {
        padding-left: inherit;
        padding-right: 140px;
    }

    .rtl .pd-xs-r-150 {
        padding-right: inherit;
        padding-left: 150px;
    }

    .rtl .pd-xs-l-150 {
        padding-left: inherit;
        padding-right: 150px;
    }

    .rtl .pd-xs-r-160 {
        padding-right: inherit;
        padding-left: 160px;
    }

    .rtl .pd-xs-l-160 {
        padding-left: inherit;
        padding-right: 160px;
    }

    .rtl .pd-xs-r-170 {
        padding-right: inherit;
        padding-left: 170px;
    }

    .rtl .pd-xs-l-170 {
        padding-left: inherit;
        padding-right: 170px;
    }

    .rtl .pd-xs-r-180 {
        padding-right: inherit;
        padding-left: 180px;
    }

    .rtl .pd-xs-l-180 {
        padding-left: inherit;
        padding-right: 180px;
    }

    .rtl .pd-xs-r-190 {
        padding-right: inherit;
        padding-left: 190px;
    }

    .rtl .pd-xs-l-190 {
        padding-left: inherit;
        padding-right: 190px;
    }

    .rtl .pd-xs-r-200 {
        padding-right: inherit;
        padding-left: 200px;
    }

    .rtl .pd-xs-l-200 {
        padding-left: inherit;
        padding-right: 200px;
    }
}

@media (min-width: 576px) {
    .rtl .pd-sm-r-0 {
        padding-right: inherit;
        padding-left: 0px;
    }

    .rtl .pd-sm-l-0 {
        padding-left: inherit;
        padding-right: 0px;
    }

    .rtl .pd-sm-r-1 {
        padding-right: inherit;
        padding-left: 1px;
    }

    .rtl .pd-sm-l-1 {
        padding-left: inherit;
        padding-right: 1px;
    }

    .rtl .pd-sm-r-2 {
        padding-right: inherit;
        padding-left: 2px;
    }

    .rtl .pd-sm-l-2 {
        padding-left: inherit;
        padding-right: 2px;
    }

    .rtl .pd-sm-r-3 {
        padding-right: inherit;
        padding-left: 3px;
    }

    .rtl .pd-sm-l-3 {
        padding-left: inherit;
        padding-right: 3px;
    }

    .rtl .pd-sm-r-4 {
        padding-right: inherit;
        padding-left: 4px;
    }

    .rtl .pd-sm-l-4 {
        padding-left: inherit;
        padding-right: 4px;
    }

    .rtl .pd-sm-r-5 {
        padding-right: inherit;
        padding-left: 5px;
    }

    .rtl .pd-sm-l-5 {
        padding-left: inherit;
        padding-right: 5px;
    }

    .rtl .pd-sm-r-6 {
        padding-right: inherit;
        padding-left: 6px;
    }

    .rtl .pd-sm-l-6 {
        padding-left: inherit;
        padding-right: 6px;
    }

    .rtl .pd-sm-r-7 {
        padding-right: inherit;
        padding-left: 7px;
    }

    .rtl .pd-sm-l-7 {
        padding-left: inherit;
        padding-right: 7px;
    }

    .rtl .pd-sm-r-8 {
        padding-right: inherit;
        padding-left: 8px;
    }

    .rtl .pd-sm-l-8 {
        padding-left: inherit;
        padding-right: 8px;
    }

    .rtl .pd-sm-r-9 {
        padding-right: inherit;
        padding-left: 9px;
    }

    .rtl .pd-sm-l-9 {
        padding-left: inherit;
        padding-right: 9px;
    }

    .rtl .pd-sm-r-10 {
        padding-right: inherit;
        padding-left: 10px;
    }

    .rtl .pd-sm-l-10 {
        padding-left: inherit;
        padding-right: 10px;
    }

    .rtl .pd-sm-r-15 {
        padding-right: inherit;
        padding-left: 15px;
    }

    .rtl .pd-sm-l-15 {
        padding-left: inherit;
        padding-right: 15px;
    }

    .rtl .pd-sm-r-20 {
        padding-right: inherit;
        padding-left: 20px;
    }

    .rtl .pd-sm-l-20 {
        padding-left: inherit;
        padding-right: 20px;
    }

    .rtl .pd-sm-r-25 {
        padding-right: inherit;
        padding-left: 25px;
    }

    .rtl .pd-sm-l-25 {
        padding-left: inherit;
        padding-right: 25px;
    }

    .rtl .pd-sm-r-30 {
        padding-right: inherit;
        padding-left: 30px;
    }

    .rtl .pd-sm-l-30 {
        padding-left: inherit;
        padding-right: 30px;
    }

    .rtl .pd-sm-r-35 {
        padding-right: inherit;
        padding-left: 35px;
    }

    .rtl .pd-sm-l-35 {
        padding-left: inherit;
        padding-right: 35px;
    }

    .rtl .pd-sm-r-40 {
        padding-right: inherit;
        padding-left: 40px;
    }

    .rtl .pd-sm-l-40 {
        padding-left: inherit;
        padding-right: 40px;
    }

    .rtl .pd-sm-r-45 {
        padding-right: inherit;
        padding-left: 45px;
    }

    .rtl .pd-sm-l-45 {
        padding-left: inherit;
        padding-right: 45px;
    }

    .rtl .pd-sm-r-50 {
        padding-right: inherit;
        padding-left: 50px;
    }

    .rtl .pd-sm-l-50 {
        padding-left: inherit;
        padding-right: 50px;
    }

    .rtl .pd-sm-r-55 {
        padding-right: inherit;
        padding-left: 55px;
    }

    .rtl .pd-sm-l-55 {
        padding-left: inherit;
        padding-right: 55px;
    }

    .rtl .pd-sm-r-60 {
        padding-right: inherit;
        padding-left: 60px;
    }

    .rtl .pd-sm-l-60 {
        padding-left: inherit;
        padding-right: 60px;
    }

    .rtl .pd-sm-r-65 {
        padding-right: inherit;
        padding-left: 65px;
    }

    .rtl .pd-sm-l-65 {
        padding-left: inherit;
        padding-right: 65px;
    }

    .rtl .pd-sm-r-70 {
        padding-right: inherit;
        padding-left: 70px;
    }

    .rtl .pd-sm-l-70 {
        padding-left: inherit;
        padding-right: 70px;
    }

    .rtl .pd-sm-r-75 {
        padding-right: inherit;
        padding-left: 75px;
    }

    .rtl .pd-sm-l-75 {
        padding-left: inherit;
        padding-right: 75px;
    }

    .rtl .pd-sm-r-80 {
        padding-right: inherit;
        padding-left: 80px;
    }

    .rtl .pd-sm-l-80 {
        padding-left: inherit;
        padding-right: 80px;
    }

    .rtl .pd-sm-r-85 {
        padding-right: inherit;
        padding-left: 85px;
    }

    .rtl .pd-sm-l-85 {
        padding-left: inherit;
        padding-right: 85px;
    }

    .rtl .pd-sm-r-90 {
        padding-right: inherit;
        padding-left: 90px;
    }

    .rtl .pd-sm-l-90 {
        padding-left: inherit;
        padding-right: 90px;
    }

    .rtl .pd-sm-r-95 {
        padding-right: inherit;
        padding-left: 95px;
    }

    .rtl .pd-sm-l-95 {
        padding-left: inherit;
        padding-right: 95px;
    }

    .rtl .pd-sm-r-100 {
        padding-right: inherit;
        padding-left: 100px;
    }

    .rtl .pd-sm-l-100 {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl .pd-sm-r-110 {
        padding-right: inherit;
        padding-left: 110px;
    }

    .rtl .pd-sm-l-110 {
        padding-left: inherit;
        padding-right: 110px;
    }

    .rtl .pd-sm-r-120 {
        padding-right: inherit;
        padding-left: 120px;
    }

    .rtl .pd-sm-l-120 {
        padding-left: inherit;
        padding-right: 120px;
    }

    .rtl .pd-sm-r-130 {
        padding-right: inherit;
        padding-left: 130px;
    }

    .rtl .pd-sm-l-130 {
        padding-left: inherit;
        padding-right: 130px;
    }

    .rtl .pd-sm-r-140 {
        padding-right: inherit;
        padding-left: 140px;
    }

    .rtl .pd-sm-l-140 {
        padding-left: inherit;
        padding-right: 140px;
    }

    .rtl .pd-sm-r-150 {
        padding-right: inherit;
        padding-left: 150px;
    }

    .rtl .pd-sm-l-150 {
        padding-left: inherit;
        padding-right: 150px;
    }

    .rtl .pd-sm-r-160 {
        padding-right: inherit;
        padding-left: 160px;
    }

    .rtl .pd-sm-l-160 {
        padding-left: inherit;
        padding-right: 160px;
    }

    .rtl .pd-sm-r-170 {
        padding-right: inherit;
        padding-left: 170px;
    }

    .rtl .pd-sm-l-170 {
        padding-left: inherit;
        padding-right: 170px;
    }

    .rtl .pd-sm-r-180 {
        padding-right: inherit;
        padding-left: 180px;
    }

    .rtl .pd-sm-l-180 {
        padding-left: inherit;
        padding-right: 180px;
    }

    .rtl .pd-sm-r-190 {
        padding-right: inherit;
        padding-left: 190px;
    }

    .rtl .pd-sm-l-190 {
        padding-left: inherit;
        padding-right: 190px;
    }

    .rtl .pd-sm-r-200 {
        padding-right: inherit;
        padding-left: 200px;
    }

    .rtl .pd-sm-l-200 {
        padding-left: inherit;
        padding-right: 200px;
    }
}

@media (min-width: 768px) {
    .rtl .pd-md-r-0 {
        padding-right: inherit;
        padding-left: 0px;
    }

    .rtl .pd-md-l-0 {
        padding-left: inherit;
        padding-right: 0px;
    }

    .rtl .pd-md-r-1 {
        padding-right: inherit;
        padding-left: 1px;
    }

    .rtl .pd-md-l-1 {
        padding-left: inherit;
        padding-right: 1px;
    }

    .rtl .pd-md-r-2 {
        padding-right: inherit;
        padding-left: 2px;
    }

    .rtl .pd-md-l-2 {
        padding-left: inherit;
        padding-right: 2px;
    }

    .rtl .pd-md-r-3 {
        padding-right: inherit;
        padding-left: 3px;
    }

    .rtl .pd-md-l-3 {
        padding-left: inherit;
        padding-right: 3px;
    }

    .rtl .pd-md-r-4 {
        padding-right: inherit;
        padding-left: 4px;
    }

    .rtl .pd-md-l-4 {
        padding-left: inherit;
        padding-right: 4px;
    }

    .rtl .pd-md-r-5 {
        padding-right: inherit;
        padding-left: 5px;
    }

    .rtl .pd-md-l-5 {
        padding-left: inherit;
        padding-right: 5px;
    }

    .rtl .pd-md-r-6 {
        padding-right: inherit;
        padding-left: 6px;
    }

    .rtl .pd-md-l-6 {
        padding-left: inherit;
        padding-right: 6px;
    }

    .rtl .pd-md-r-7 {
        padding-right: inherit;
        padding-left: 7px;
    }

    .rtl .pd-md-l-7 {
        padding-left: inherit;
        padding-right: 7px;
    }

    .rtl .pd-md-r-8 {
        padding-right: inherit;
        padding-left: 8px;
    }

    .rtl .pd-md-l-8 {
        padding-left: inherit;
        padding-right: 8px;
    }

    .rtl .pd-md-r-9 {
        padding-right: inherit;
        padding-left: 9px;
    }

    .rtl .pd-md-l-9 {
        padding-left: inherit;
        padding-right: 9px;
    }

    .rtl .pd-md-r-10 {
        padding-right: inherit;
        padding-left: 10px;
    }

    .rtl .pd-md-l-10 {
        padding-left: inherit;
        padding-right: 10px;
    }

    .rtl .pd-md-r-15 {
        padding-right: inherit;
        padding-left: 15px;
    }

    .rtl .pd-md-l-15 {
        padding-left: inherit;
        padding-right: 15px;
    }

    .rtl .pd-md-r-20 {
        padding-right: inherit;
        padding-left: 20px;
    }

    .rtl .pd-md-l-20 {
        padding-left: inherit;
        padding-right: 20px;
    }

    .rtl .pd-md-r-25 {
        padding-right: inherit;
        padding-left: 25px;
    }

    .rtl .pd-md-l-25 {
        padding-left: inherit;
        padding-right: 25px;
    }

    .rtl .pd-md-r-30 {
        padding-right: inherit;
        padding-left: 30px;
    }

    .rtl .pd-md-l-30 {
        padding-left: inherit;
        padding-right: 30px;
    }

    .rtl .pd-md-r-35 {
        padding-right: inherit;
        padding-left: 35px;
    }

    .rtl .pd-md-l-35 {
        padding-left: inherit;
        padding-right: 35px;
    }

    .rtl .pd-md-r-40 {
        padding-right: inherit;
        padding-left: 40px;
    }

    .rtl .pd-md-l-40 {
        padding-left: inherit;
        padding-right: 40px;
    }

    .rtl .pd-md-r-45 {
        padding-right: inherit;
        padding-left: 45px;
    }

    .rtl .pd-md-l-45 {
        padding-left: inherit;
        padding-right: 45px;
    }

    .rtl .pd-md-r-50 {
        padding-right: inherit;
        padding-left: 50px;
    }

    .rtl .pd-md-l-50 {
        padding-left: inherit;
        padding-right: 50px;
    }

    .rtl .pd-md-r-55 {
        padding-right: inherit;
        padding-left: 55px;
    }

    .rtl .pd-md-l-55 {
        padding-left: inherit;
        padding-right: 55px;
    }

    .rtl .pd-md-r-60 {
        padding-right: inherit;
        padding-left: 60px;
    }

    .rtl .pd-md-l-60 {
        padding-left: inherit;
        padding-right: 60px;
    }

    .rtl .pd-md-r-65 {
        padding-right: inherit;
        padding-left: 65px;
    }

    .rtl .pd-md-l-65 {
        padding-left: inherit;
        padding-right: 65px;
    }

    .rtl .pd-md-r-70 {
        padding-right: inherit;
        padding-left: 70px;
    }

    .rtl .pd-md-l-70 {
        padding-left: inherit;
        padding-right: 70px;
    }

    .rtl .pd-md-r-75 {
        padding-right: inherit;
        padding-left: 75px;
    }

    .rtl .pd-md-l-75 {
        padding-left: inherit;
        padding-right: 75px;
    }

    .rtl .pd-md-r-80 {
        padding-right: inherit;
        padding-left: 80px;
    }

    .rtl .pd-md-l-80 {
        padding-left: inherit;
        padding-right: 80px;
    }

    .rtl .pd-md-r-85 {
        padding-right: inherit;
        padding-left: 85px;
    }

    .rtl .pd-md-l-85 {
        padding-left: inherit;
        padding-right: 85px;
    }

    .rtl .pd-md-r-90 {
        padding-right: inherit;
        padding-left: 90px;
    }

    .rtl .pd-md-l-90 {
        padding-left: inherit;
        padding-right: 90px;
    }

    .rtl .pd-md-r-95 {
        padding-right: inherit;
        padding-left: 95px;
    }

    .rtl .pd-md-l-95 {
        padding-left: inherit;
        padding-right: 95px;
    }

    .rtl .pd-md-r-100 {
        padding-right: inherit;
        padding-left: 100px;
    }

    .rtl .pd-md-l-100 {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl .pd-md-r-110 {
        padding-right: inherit;
        padding-left: 110px;
    }

    .rtl .pd-md-l-110 {
        padding-left: inherit;
        padding-right: 110px;
    }

    .rtl .pd-md-r-120 {
        padding-right: inherit;
        padding-left: 120px;
    }

    .rtl .pd-md-l-120 {
        padding-left: inherit;
        padding-right: 120px;
    }

    .rtl .pd-md-r-130 {
        padding-right: inherit;
        padding-left: 130px;
    }

    .rtl .pd-md-l-130 {
        padding-left: inherit;
        padding-right: 130px;
    }

    .rtl .pd-md-r-140 {
        padding-right: inherit;
        padding-left: 140px;
    }

    .rtl .pd-md-l-140 {
        padding-left: inherit;
        padding-right: 140px;
    }

    .rtl .pd-md-r-150 {
        padding-right: inherit;
        padding-left: 150px;
    }

    .rtl .pd-md-l-150 {
        padding-left: inherit;
        padding-right: 150px;
    }

    .rtl .pd-md-r-160 {
        padding-right: inherit;
        padding-left: 160px;
    }

    .rtl .pd-md-l-160 {
        padding-left: inherit;
        padding-right: 160px;
    }

    .rtl .pd-md-r-170 {
        padding-right: inherit;
        padding-left: 170px;
    }

    .rtl .pd-md-l-170 {
        padding-left: inherit;
        padding-right: 170px;
    }

    .rtl .pd-md-r-180 {
        padding-right: inherit;
        padding-left: 180px;
    }

    .rtl .pd-md-l-180 {
        padding-left: inherit;
        padding-right: 180px;
    }

    .rtl .pd-md-r-190 {
        padding-right: inherit;
        padding-left: 190px;
    }

    .rtl .pd-md-l-190 {
        padding-left: inherit;
        padding-right: 190px;
    }

    .rtl .pd-md-r-200 {
        padding-right: inherit;
        padding-left: 200px;
    }

    .rtl .pd-md-l-200 {
        padding-left: inherit;
        padding-right: 200px;
    }
}

@media (min-width: 992px) {
    .rtl .pd-lg-r-0 {
        padding-right: inherit;
        padding-left: 0px;
    }

    .rtl .pd-lg-l-0 {
        padding-left: inherit;
        padding-right: 0px;
    }

    .rtl .pd-lg-r-1 {
        padding-right: inherit;
        padding-left: 1px;
    }

    .rtl .pd-lg-l-1 {
        padding-left: inherit;
        padding-right: 1px;
    }

    .rtl .pd-lg-r-2 {
        padding-right: inherit;
        padding-left: 2px;
    }

    .rtl .pd-lg-l-2 {
        padding-left: inherit;
        padding-right: 2px;
    }

    .rtl .pd-lg-r-3 {
        padding-right: inherit;
        padding-left: 3px;
    }

    .rtl .pd-lg-l-3 {
        padding-left: inherit;
        padding-right: 3px;
    }

    .rtl .pd-lg-r-4 {
        padding-right: inherit;
        padding-left: 4px;
    }

    .rtl .pd-lg-l-4 {
        padding-left: inherit;
        padding-right: 4px;
    }

    .rtl .pd-lg-r-5 {
        padding-right: inherit;
        padding-left: 5px;
    }

    .rtl .pd-lg-l-5 {
        padding-left: inherit;
        padding-right: 5px;
    }

    .rtl .pd-lg-r-6 {
        padding-right: inherit;
        padding-left: 6px;
    }

    .rtl .pd-lg-l-6 {
        padding-left: inherit;
        padding-right: 6px;
    }

    .rtl .pd-lg-r-7 {
        padding-right: inherit;
        padding-left: 7px;
    }

    .rtl .pd-lg-l-7 {
        padding-left: inherit;
        padding-right: 7px;
    }

    .rtl .pd-lg-r-8 {
        padding-right: inherit;
        padding-left: 8px;
    }

    .rtl .pd-lg-l-8 {
        padding-left: inherit;
        padding-right: 8px;
    }

    .rtl .pd-lg-r-9 {
        padding-right: inherit;
        padding-left: 9px;
    }

    .rtl .pd-lg-l-9 {
        padding-left: inherit;
        padding-right: 9px;
    }

    .rtl .pd-lg-r-10 {
        padding-right: inherit;
        padding-left: 10px;
    }

    .rtl .pd-lg-l-10 {
        padding-left: inherit;
        padding-right: 10px;
    }

    .rtl .pd-lg-r-15 {
        padding-right: inherit;
        padding-left: 15px;
    }

    .rtl .pd-lg-l-15 {
        padding-left: inherit;
        padding-right: 15px;
    }

    .rtl .pd-lg-r-20 {
        padding-right: inherit;
        padding-left: 20px;
    }

    .rtl .pd-lg-l-20 {
        padding-left: inherit;
        padding-right: 20px;
    }

    .rtl .pd-lg-r-25 {
        padding-right: inherit;
        padding-left: 25px;
    }

    .rtl .pd-lg-l-25 {
        padding-left: inherit;
        padding-right: 25px;
    }

    .rtl .pd-lg-r-30 {
        padding-right: inherit;
        padding-left: 30px;
    }

    .rtl .pd-lg-l-30 {
        padding-left: inherit;
        padding-right: 30px;
    }

    .rtl .pd-lg-r-35 {
        padding-right: inherit;
        padding-left: 35px;
    }

    .rtl .pd-lg-l-35 {
        padding-left: inherit;
        padding-right: 35px;
    }

    .rtl .pd-lg-r-40 {
        padding-right: inherit;
        padding-left: 40px;
    }

    .rtl .pd-lg-l-40 {
        padding-left: inherit;
        padding-right: 40px;
    }

    .rtl .pd-lg-r-45 {
        padding-right: inherit;
        padding-left: 45px;
    }

    .rtl .pd-lg-l-45 {
        padding-left: inherit;
        padding-right: 45px;
    }

    .rtl .pd-lg-r-50 {
        padding-right: inherit;
        padding-left: 50px;
    }

    .rtl .pd-lg-l-50 {
        padding-left: inherit;
        padding-right: 50px;
    }

    .rtl .pd-lg-r-55 {
        padding-right: inherit;
        padding-left: 55px;
    }

    .rtl .pd-lg-l-55 {
        padding-left: inherit;
        padding-right: 55px;
    }

    .rtl .pd-lg-r-60 {
        padding-right: inherit;
        padding-left: 60px;
    }

    .rtl .pd-lg-l-60 {
        padding-left: inherit;
        padding-right: 60px;
    }

    .rtl .pd-lg-r-65 {
        padding-right: inherit;
        padding-left: 65px;
    }

    .rtl .pd-lg-l-65 {
        padding-left: inherit;
        padding-right: 65px;
    }

    .rtl .pd-lg-r-70 {
        padding-right: inherit;
        padding-left: 70px;
    }

    .rtl .pd-lg-l-70 {
        padding-left: inherit;
        padding-right: 70px;
    }

    .rtl .pd-lg-r-75 {
        padding-right: inherit;
        padding-left: 75px;
    }

    .rtl .pd-lg-l-75 {
        padding-left: inherit;
        padding-right: 75px;
    }

    .rtl .pd-lg-r-80 {
        padding-right: inherit;
        padding-left: 80px;
    }

    .rtl .pd-lg-l-80 {
        padding-left: inherit;
        padding-right: 80px;
    }

    .rtl .pd-lg-r-85 {
        padding-right: inherit;
        padding-left: 85px;
    }

    .rtl .pd-lg-l-85 {
        padding-left: inherit;
        padding-right: 85px;
    }

    .rtl .pd-lg-r-90 {
        padding-right: inherit;
        padding-left: 90px;
    }

    .rtl .pd-lg-l-90 {
        padding-left: inherit;
        padding-right: 90px;
    }

    .rtl .pd-lg-r-95 {
        padding-right: inherit;
        padding-left: 95px;
    }

    .rtl .pd-lg-l-95 {
        padding-left: inherit;
        padding-right: 95px;
    }

    .rtl .pd-lg-r-100 {
        padding-right: inherit;
        padding-left: 100px;
    }

    .rtl .pd-lg-l-100 {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl .pd-lg-r-110 {
        padding-right: inherit;
        padding-left: 110px;
    }

    .rtl .pd-lg-l-110 {
        padding-left: inherit;
        padding-right: 110px;
    }

    .rtl .pd-lg-r-120 {
        padding-right: inherit;
        padding-left: 120px;
    }

    .rtl .pd-lg-l-120 {
        padding-left: inherit;
        padding-right: 120px;
    }

    .rtl .pd-lg-r-130 {
        padding-right: inherit;
        padding-left: 130px;
    }

    .rtl .pd-lg-l-130 {
        padding-left: inherit;
        padding-right: 130px;
    }

    .rtl .pd-lg-r-140 {
        padding-right: inherit;
        padding-left: 140px;
    }

    .rtl .pd-lg-l-140 {
        padding-left: inherit;
        padding-right: 140px;
    }

    .rtl .pd-lg-r-150 {
        padding-right: inherit;
        padding-left: 150px;
    }

    .rtl .pd-lg-l-150 {
        padding-left: inherit;
        padding-right: 150px;
    }

    .rtl .pd-lg-r-160 {
        padding-right: inherit;
        padding-left: 160px;
    }

    .rtl .pd-lg-l-160 {
        padding-left: inherit;
        padding-right: 160px;
    }

    .rtl .pd-lg-r-170 {
        padding-right: inherit;
        padding-left: 170px;
    }

    .rtl .pd-lg-l-170 {
        padding-left: inherit;
        padding-right: 170px;
    }

    .rtl .pd-lg-r-180 {
        padding-right: inherit;
        padding-left: 180px;
    }

    .rtl .pd-lg-l-180 {
        padding-left: inherit;
        padding-right: 180px;
    }

    .rtl .pd-lg-r-190 {
        padding-right: inherit;
        padding-left: 190px;
    }

    .rtl .pd-lg-l-190 {
        padding-left: inherit;
        padding-right: 190px;
    }

    .rtl .pd-lg-r-200 {
        padding-right: inherit;
        padding-left: 200px;
    }

    .rtl .pd-lg-l-200 {
        padding-left: inherit;
        padding-right: 200px;
    }
}

@media (min-width: 1200px) {
    .rtl .pd-xl-r-0 {
        padding-right: inherit;
        padding-left: 0px;
    }

    .rtl .pd-xl-l-0 {
        padding-left: inherit;
        padding-right: 0px;
    }

    .rtl .pd-xl-r-1 {
        padding-right: inherit;
        padding-left: 1px;
    }

    .rtl .pd-xl-l-1 {
        padding-left: inherit;
        padding-right: 1px;
    }

    .rtl .pd-xl-r-2 {
        padding-right: inherit;
        padding-left: 2px;
    }

    .rtl .pd-xl-l-2 {
        padding-left: inherit;
        padding-right: 2px;
    }

    .rtl .pd-xl-r-3 {
        padding-right: inherit;
        padding-left: 3px;
    }

    .rtl .pd-xl-l-3 {
        padding-left: inherit;
        padding-right: 3px;
    }

    .rtl .pd-xl-r-4 {
        padding-right: inherit;
        padding-left: 4px;
    }

    .rtl .pd-xl-l-4 {
        padding-left: inherit;
        padding-right: 4px;
    }

    .rtl .pd-xl-r-5 {
        padding-right: inherit;
        padding-left: 5px;
    }

    .rtl .pd-xl-l-5 {
        padding-left: inherit;
        padding-right: 5px;
    }

    .rtl .pd-xl-r-6 {
        padding-right: inherit;
        padding-left: 6px;
    }

    .rtl .pd-xl-l-6 {
        padding-left: inherit;
        padding-right: 6px;
    }

    .rtl .pd-xl-r-7 {
        padding-right: inherit;
        padding-left: 7px;
    }

    .rtl .pd-xl-l-7 {
        padding-left: inherit;
        padding-right: 7px;
    }

    .rtl .pd-xl-r-8 {
        padding-right: inherit;
        padding-left: 8px;
    }

    .rtl .pd-xl-l-8 {
        padding-left: inherit;
        padding-right: 8px;
    }

    .rtl .pd-xl-r-9 {
        padding-right: inherit;
        padding-left: 9px;
    }

    .rtl .pd-xl-l-9 {
        padding-left: inherit;
        padding-right: 9px;
    }

    .rtl .pd-xl-r-10 {
        padding-right: inherit;
        padding-left: 10px;
    }

    .rtl .pd-xl-l-10 {
        padding-left: inherit;
        padding-right: 10px;
    }

    .rtl .pd-xl-r-15 {
        padding-right: inherit;
        padding-left: 15px;
    }

    .rtl .pd-xl-l-15 {
        padding-left: inherit;
        padding-right: 15px;
    }

    .rtl .pd-xl-r-20 {
        padding-right: inherit;
        padding-left: 20px;
    }

    .rtl .pd-xl-l-20 {
        padding-left: inherit;
        padding-right: 20px;
    }

    .rtl .pd-xl-r-25 {
        padding-right: inherit;
        padding-left: 25px;
    }

    .rtl .pd-xl-l-25 {
        padding-left: inherit;
        padding-right: 25px;
    }

    .rtl .pd-xl-r-30 {
        padding-right: inherit;
        padding-left: 30px;
    }

    .rtl .pd-xl-l-30 {
        padding-left: inherit;
        padding-right: 30px;
    }

    .rtl .pd-xl-r-35 {
        padding-right: inherit;
        padding-left: 35px;
    }

    .rtl .pd-xl-l-35 {
        padding-left: inherit;
        padding-right: 35px;
    }

    .rtl .pd-xl-r-40 {
        padding-right: inherit;
        padding-left: 40px;
    }

    .rtl .pd-xl-l-40 {
        padding-left: inherit;
        padding-right: 40px;
    }

    .rtl .pd-xl-r-45 {
        padding-right: inherit;
        padding-left: 45px;
    }

    .rtl .pd-xl-l-45 {
        padding-left: inherit;
        padding-right: 45px;
    }

    .rtl .pd-xl-r-50 {
        padding-right: inherit;
        padding-left: 50px;
    }

    .rtl .pd-xl-l-50 {
        padding-left: inherit;
        padding-right: 50px;
    }

    .rtl .pd-xl-r-55 {
        padding-right: inherit;
        padding-left: 55px;
    }

    .rtl .pd-xl-l-55 {
        padding-left: inherit;
        padding-right: 55px;
    }

    .rtl .pd-xl-r-60 {
        padding-right: inherit;
        padding-left: 60px;
    }

    .rtl .pd-xl-l-60 {
        padding-left: inherit;
        padding-right: 60px;
    }

    .rtl .pd-xl-r-65 {
        padding-right: inherit;
        padding-left: 65px;
    }

    .rtl .pd-xl-l-65 {
        padding-left: inherit;
        padding-right: 65px;
    }

    .rtl .pd-xl-r-70 {
        padding-right: inherit;
        padding-left: 70px;
    }

    .rtl .pd-xl-l-70 {
        padding-left: inherit;
        padding-right: 70px;
    }

    .rtl .pd-xl-r-75 {
        padding-right: inherit;
        padding-left: 75px;
    }

    .rtl .pd-xl-l-75 {
        padding-left: inherit;
        padding-right: 75px;
    }

    .rtl .pd-xl-r-80 {
        padding-right: inherit;
        padding-left: 80px;
    }

    .rtl .pd-xl-l-80 {
        padding-left: inherit;
        padding-right: 80px;
    }

    .rtl .pd-xl-r-85 {
        padding-right: inherit;
        padding-left: 85px;
    }

    .rtl .pd-xl-l-85 {
        padding-left: inherit;
        padding-right: 85px;
    }

    .rtl .pd-xl-r-90 {
        padding-right: inherit;
        padding-left: 90px;
    }

    .rtl .pd-xl-l-90 {
        padding-left: inherit;
        padding-right: 90px;
    }

    .rtl .pd-xl-r-95 {
        padding-right: inherit;
        padding-left: 95px;
    }

    .rtl .pd-xl-l-95 {
        padding-left: inherit;
        padding-right: 95px;
    }

    .rtl .pd-xl-r-100 {
        padding-right: inherit;
        padding-left: 100px;
    }

    .rtl .pd-xl-l-100 {
        padding-left: inherit;
        padding-right: 100px;
    }

    .rtl .pd-xl-r-110 {
        padding-right: inherit;
        padding-left: 110px;
    }

    .rtl .pd-xl-l-110 {
        padding-left: inherit;
        padding-right: 110px;
    }

    .rtl .pd-xl-r-120 {
        padding-right: inherit;
        padding-left: 120px;
    }

    .rtl .pd-xl-l-120 {
        padding-left: inherit;
        padding-right: 120px;
    }

    .rtl .pd-xl-r-130 {
        padding-right: inherit;
        padding-left: 130px;
    }

    .rtl .pd-xl-l-130 {
        padding-left: inherit;
        padding-right: 130px;
    }

    .rtl .pd-xl-r-140 {
        padding-right: inherit;
        padding-left: 140px;
    }

    .rtl .pd-xl-l-140 {
        padding-left: inherit;
        padding-right: 140px;
    }

    .rtl .pd-xl-r-150 {
        padding-right: inherit;
        padding-left: 150px;
    }

    .rtl .pd-xl-l-150 {
        padding-left: inherit;
        padding-right: 150px;
    }

    .rtl .pd-xl-r-160 {
        padding-right: inherit;
        padding-left: 160px;
    }

    .rtl .pd-xl-l-160 {
        padding-left: inherit;
        padding-right: 160px;
    }

    .rtl .pd-xl-r-170 {
        padding-right: inherit;
        padding-left: 170px;
    }

    .rtl .pd-xl-l-170 {
        padding-left: inherit;
        padding-right: 170px;
    }

    .rtl .pd-xl-r-180 {
        padding-right: inherit;
        padding-left: 180px;
    }

    .rtl .pd-xl-l-180 {
        padding-left: inherit;
        padding-right: 180px;
    }

    .rtl .pd-xl-r-190 {
        padding-right: inherit;
        padding-left: 190px;
    }

    .rtl .pd-xl-l-190 {
        padding-left: inherit;
        padding-right: 190px;
    }

    .rtl .pd-xl-r-200 {
        padding-right: inherit;
        padding-left: 200px;
    }

    .rtl .pd-xl-l-200 {
        padding-left: inherit;
        padding-right: 200px;
    }
}

.rtl .pd-r-12 {
    padding-right: inherit;
    padding-left: 12px;
}

.rtl .pd-l-12 {
    padding-left: inherit;
    padding-right: 12px;
}

.rtl .pd-r-13 {
    padding-right: inherit;
    padding-left: 13px;
}

.rtl .pd-l-13 {
    padding-left: inherit;
    padding-right: 13px;
}

.rtl .r-0 {
    right: auto;
    left: 0px;
}

.rtl .l-0 {
    left: auto;
    right: 0px;
}

.rtl .r--0 {
    right: auto;
    left: 0px;
}

.rtl .l--0 {
    left: auto;
    right: 0px;
}

.rtl .r-5 {
    right: auto;
    left: 5px;
}

.rtl .l-5 {
    left: auto;
    left: 5px;
}

.rtl .r--5 {
    right: auto;
    left: -5px;
}

.rtl .l--5 {
    left: auto;
    right: -5px;
}

.rtl .r-10 {
    right: auto;
    left: 10px;
}

.rtl .l-10 {
    left: auto;
    right: 10px;
}

.rtl .r--10 {
    right: auto;
    left: -10px;
}

.rtl .l--10 {
    left: auto;
    right: -10px;
}

.rtl .r-15 {
    right: auto;
    left: 15px;
}

.rtl .l-15 {
    left: auto;
    right: 15px;
}

.rtl .r--15 {
    right: auto;
    left: -15px;
}

.rtl .l--15 {
    left: auto;
    right: -15px;
}

.rtl .r-20 {
    right: auto;
    left: 20px;
}

.rtl .l-20 {
    left: auto;
    right: 20px;
}

.rtl .r--20 {
    right: auto;
    left: -20px;
}

.rtl .l--20 {
    left: auto;
    right: -20px;
}

.rtl .r-25 {
    right: auto;
    left: 25px;
}

.rtl .l-25 {
    left: auto;
    right: 25px;
}

.rtl .r--25 {
    right: auto;
    left: -25px;
}

.rtl .l--25 {
    left: auto;
    right: -25px;
}

.rtl .r-30 {
    right: auto;
    left: 30px;
}

.rtl .l-30 {
    left: auto;
    right: 30px;
}

.rtl .r--30 {
    right: auto;
    left: -30px;
}

.rtl .l--30 {
    left: auto;
    right: -30px;
}

.rtl .r-35 {
    right: auto;
    left: 35px;
}

.rtl .l-35 {
    left: auto;
    right: 35px;
}

.rtl .r--35 {
    right: auto;
    left: -35px;
}

.rtl .l--35 {
    left: auto;
    right: -35px;
}

.rtl .r-40 {
    right: auto;
    left: 40px;
}

.rtl .l-40 {
    left: auto;
    right: 40px;
}

.rtl .r--40 {
    right: auto;
    left: -40px;
}

.rtl .l--40 {
    left: auto;
    right: -40px;
}

.rtl .r-45 {
    right: auto;
    left: 45px;
}

.rtl .l-45 {
    left: auto;
    right: 45px;
}

.rtl .r--45 {
    right: auto;
    left: -45px;
}

.rtl .l--45 {
    left: auto;
    right: -45px;
}

.rtl .r-50 {
    right: auto;
    left: 50px;
}

.rtl .l-50 {
    left: auto;
    right: 50px;
}

.rtl .r--50 {
    right: auto;
    left: -50px;
}

.rtl .l--50 {
    left: auto;
    right: -50px;
}

.rtl .r-55 {
    right: auto;
    left: 55px;
}

.rtl .l-55 {
    left: auto;
    right: 55px;
}

.rtl .r--55 {
    right: auto;
    left: -55px;
}

.rtl .l--55 {
    left: auto;
    right: -55px;
}

.rtl .r-60 {
    right: auto;
    left: 60px;
}

.rtl .l-60 {
    left: auto;
    right: 60px;
}

.rtl .r--60 {
    right: auto;
    left: -60px;
}

.rtl .l--60 {
    left: auto;
    right: -60px;
}

.rtl .r-65 {
    right: auto;
    left: 65px;
}

.rtl .l-65 {
    left: auto;
    right: 65px;
}

.rtl .r--65 {
    right: auto;
    left: -65px;
}

.rtl .l--65 {
    left: auto;
    right: -65px;
}

.rtl .r-70 {
    right: auto;
    left: 70px;
}

.rtl .l-70 {
    left: auto;
    right: 70px;
}

.rtl .r--70 {
    right: auto;
    left: -70px;
}

.rtl .l--70 {
    left: auto;
    right: -70px;
}

.rtl .r-75 {
    right: auto;
    left: 75px;
}

.rtl .l-75 {
    left: auto;
    right: 75px;
}

.rtl .r--75 {
    right: auto;
    left: -75px;
}

.rtl .l--75 {
    left: auto;
    right: -75px;
}

.rtl .r-80 {
    right: auto;
    left: 80px;
}

.rtl .l-80 {
    left: auto;
    right: 80px;
}

.rtl .r--80 {
    right: auto;
    left: -80px;
}

.rtl .l--80 {
    left: auto;
    right: -80px;
}

.rtl .r-85 {
    right: auto;
    left: 85px;
}

.rtl .l-85 {
    left: auto;
    right: 85px;
}

.rtl .r--85 {
    right: auto;
    left: -85px;
}

.rtl .l--85 {
    left: auto;
    right: -85px;
}

.rtl .r-90 {
    right: auto;
    left: 90px;
}

.rtl .l-90 {
    left: auto;
    right: 90px;
}

.rtl .r--90 {
    right: auto;
    left: -90px;
}

.rtl .l--90 {
    left: auto;
    right: -90px;
}

.rtl .r-95 {
    right: auto;
    left: 95px;
}

.rtl .l-95 {
    left: auto;
    right: 95px;
}

.rtl .r--95 {
    right: auto;
    left: -95px;
}

.rtl .l--95 {
    left: auto;
    right: -95px;
}

.rtl .r-100 {
    right: auto;
    left: 100px;
}

.rtl .l-100 {
    left: auto;
    right: 100px;
}

.rtl .r--100 {
    right: auto;
    left: -100px;
}

.rtl .l--100 {
    left: auto;
    right: -100px;
}

.rtl .r--5 {
    right: auto;
    left: -5px;
}

@media (min-width: 480px) {
    .rtl .r-xs-auto {
        right: 0;
        left: auto;
    }

    .rtl .l-xs-auto {
        right: auto;
        left: 0;
    }
}

@media (min-width: 576px) {
    .rtl .r-sm-auto {
        right: 0;
        left: auto;
    }

    .rtl .l-sm-auto {
        right: auto;
        left: 0;
    }
}

@media (min-width: 768px) {
    .rtl .r-md-auto {
        right: 0;
        left: auto;
    }

    .rtl .l-md-auto {
        right: auto;
        left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .r-lg-auto {
        right: 0;
        left: auto;
    }

    .rtl .l-lg-auto {
        right: auto;
        left: 0;
    }
}

@media (min-width: 1200px) {
    .rtl .r-xl-auto {
        right: 0;
        left: auto;
    }

    .rtl .l-xl-auto {
        right: auto;
        left: 0;
    }
}

.rtl .tx-right {
    text-align: left;
}

.rtl .tx-left {
    text-align: right;
}

@media (min-width: 480px) {
    .rtl .tx-xs-right {
        text-align: left;
    }

    .rtl .tx-xs-left {
        text-align: right;
    }
}

@media (min-width: 576px) {
    .rtl .tx-sm-right {
        text-align: left;
    }

    .rtl .tx-sm-left {
        text-align: right;
    }
}

@media (min-width: 768px) {
    .rtl .tx-md-right {
        text-align: left;
    }

    .rtl .tx-md-left {
        text-align: right;
    }
}

@media (min-width: 992px) {
    .rtl .tx-lg-right {
        text-align: left;
    }

    .rtl .tx-lg-left {
        text-align: right;
    }
}

@media (min-width: 1200px) {
    .rtl .tx-xl-right {
        text-align: left;
    }

    .rtl .tx-xl-left {
        text-align: right;
    }
}

.rtl .text-start {
    text-align: right !important;
}

.rtl .text-end {
    text-align: left !important;
}

@media (min-width: 576px) {
    .rtl .text-sm-left {
        text-align: right !important;
    }

    .rtl .text-sm-right {
        text-align: left !important;
    }
}

@media (min-width: 768px) {
    .rtl .text-md-left {
        text-align: right !important;
    }

    .rtl .text-md-right {
        text-align: left !important;
    }
}

@media (min-width: 992px) {
    .rtl .text-lg-left {
        text-align: right !important;
    }

    .rtl .text-lg-right {
        text-align: left !important;
    }
}

@media (min-width: 1200px) {
    .rtl .text-xl-left {
        text-align: right !important;
    }

    .rtl .text-xl-right {
        text-align: left !important;
    }
}

.rtl .offset-1 {
    margin-left: auto;
    margin-right: 8.33333%;
}

.rtl .offset-2 {
    margin-left: auto;
    margin-right: 16.66667%;
}

.rtl .offset-3 {
    margin-left: auto;
    margin-right: 25%;
}

.rtl .offset-4 {
    margin-left: auto;
    margin-right: 33.33333%;
}

.rtl .offset-5 {
    margin-left: auto;
    margin-right: 41.66667%;
}

.rtl .offset-6 {
    margin-left: auto;
    margin-right: 50%;
}

.rtl .offset-7 {
    margin-left: auto;
    margin-right: 58.33333%;
}

.rtl .offset-8 {
    margin-left: auto;
    margin-right: 66.66667%;
}

.rtl .offset-9 {
    margin-left: auto;
    margin-right: 75%;
}

.rtl .offset-10 {
    margin-left: auto;
    margin-right: 83.33333%;
}

.rtl .offset-11 {
    margin-left: auto;
    margin-right: 91.66667%;
}

@media (min-width: 576px) {
    .rtl .offset-sm-0 {
        margin-left: auto;
        margin-right: 0;
    }

    .rtl .offset-sm-1 {
        margin-left: auto;
        margin-right: 8.33333%;
    }

    .rtl .offset-sm-2 {
        margin-left: auto;
        margin-right: 16.66667%;
    }

    .rtl .offset-sm-3 {
        margin-left: auto;
        margin-right: 25%;
    }

    .rtl .offset-sm-4 {
        margin-left: auto;
        margin-right: 33.33333%;
    }

    .rtl .offset-sm-5 {
        margin-left: auto;
        margin-right: 41.66667%;
    }

    .rtl .offset-sm-6 {
        margin-left: auto;
        margin-right: 50%;
    }

    .rtl .offset-sm-7 {
        margin-left: auto;
        margin-right: 58.33333%;
    }

    .rtl .offset-sm-8 {
        margin-left: auto;
        margin-right: 66.66667%;
    }

    .rtl .offset-sm-9 {
        margin-left: auto;
        margin-right: 75%;
    }

    .rtl .offset-sm-10 {
        margin-left: auto;
        margin-right: 83.33333%;
    }

    .rtl .offset-sm-11 {
        margin-left: auto;
        margin-right: 91.66667%;
    }
}

@media (min-width: 768px) {
    .rtl .offset-md-0 {
        margin-left: auto;
        margin-right: 0;
    }

    .rtl .offset-md-1 {
        margin-left: auto;
        margin-right: 8.33333%;
    }

    .rtl .offset-md-2 {
        margin-left: auto;
        margin-right: 16.66667%;
    }

    .rtl .offset-md-3 {
        margin-left: auto;
        margin-right: 25%;
    }

    .rtl .offset-md-4 {
        margin-left: auto;
        margin-right: 33.33333%;
    }

    .rtl .offset-md-5 {
        margin-left: auto;
        margin-right: 41.66667%;
    }

    .rtl .offset-md-6 {
        margin-left: auto;
        margin-right: 50%;
    }

    .rtl .offset-md-7 {
        margin-left: auto;
        margin-right: 58.33333%;
    }

    .rtl .offset-md-8 {
        margin-left: auto;
        margin-right: 66.66667%;
    }

    .rtl .offset-md-9 {
        margin-left: auto;
        margin-right: 75%;
    }

    .rtl .offset-md-10 {
        margin-left: auto;
        margin-right: 83.33333%;
    }

    .rtl .offset-md-11 {
        margin-left: auto;
        margin-right: 91.66667%;
    }
}

@media (min-width: 992px) {
    .rtl .offset-lg-0 {
        margin-left: auto;
        margin-right: 0;
    }

    .rtl .offset-lg-1 {
        margin-left: auto;
        margin-right: 8.33333%;
    }

    .rtl .offset-lg-2 {
        margin-left: auto;
        margin-right: 16.66667%;
    }

    .rtl .offset-lg-3 {
        margin-left: auto;
        margin-right: 25%;
    }

    .rtl .offset-lg-4 {
        margin-left: auto;
        margin-right: 33.33333%;
    }

    .rtl .offset-lg-5 {
        margin-left: auto;
        margin-right: 41.66667%;
    }

    .rtl .offset-lg-6 {
        margin-left: auto;
        margin-right: 50%;
    }

    .rtl .offset-lg-7 {
        margin-left: auto;
        margin-right: 58.33333%;
    }

    .rtl .offset-lg-8 {
        margin-left: auto;
        margin-right: 66.66667%;
    }

    .rtl .offset-lg-9 {
        margin-left: auto;
        margin-right: 75%;
    }

    .rtl .offset-lg-10 {
        margin-left: auto;
        margin-right: 83.33333%;
    }

    .rtl .offset-lg-11 {
        margin-left: auto;
        margin-right: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .rtl .offset-xl-0 {
        margin-left: auto;
        margin-right: 0;
    }

    .rtl .offset-xl-1 {
        margin-left: auto;
        margin-right: 8.33333%;
    }

    .rtl .offset-xl-2 {
        margin-left: auto;
        margin-right: 16.66667%;
    }

    .rtl .offset-xl-3 {
        margin-left: auto;
        margin-right: 25%;
    }

    .rtl .offset-xl-4 {
        margin-left: auto;
        margin-right: 33.33333%;
    }

    .rtl .offset-xl-5 {
        margin-left: auto;
        margin-right: 41.66667%;
    }

    .rtl .offset-xl-6 {
        margin-left: auto;
        margin-right: 50%;
    }

    .rtl .offset-xl-7 {
        margin-left: auto;
        margin-right: 58.33333%;
    }

    .rtl .offset-xl-8 {
        margin-left: auto;
        margin-right: 66.66667%;
    }

    .rtl .offset-xl-9 {
        margin-left: auto;
        margin-right: 75%;
    }

    .rtl .offset-xl-10 {
        margin-left: auto;
        margin-right: 83.33333%;
    }

    .rtl .offset-xl-11 {
        margin-left: auto;
        margin-right: 91.66667%;
    }
}

@media (min-width: 1600px) {
    .rtl .offset-xxl-0 {
        margin-left: auto;
        margin-right: 0;
    }

    .rtl .offset-xxl-1 {
        margin-left: auto;
        margin-right: 8.33333%;
    }

    .rtl .offset-xxl-2 {
        margin-left: auto;
        margin-right: 16.66667%;
    }

    .rtl .offset-xxl-3 {
        margin-left: auto;
        margin-right: 25%;
    }

    .rtl .offset-xxl-4 {
        margin-left: auto;
        margin-right: 33.33333%;
    }

    .rtl .offset-xxl-5 {
        margin-left: auto;
        margin-right: 41.66667%;
    }

    .rtl .offset-xxl-6 {
        margin-left: auto;
        margin-right: 50%;
    }

    .rtl .offset-xxl-7 {
        margin-left: auto;
        margin-right: 58.33333%;
    }

    .rtl .offset-xxl-8 {
        margin-left: auto;
        margin-right: 66.66667%;
    }

    .rtl .offset-xxl-9 {
        margin-left: auto;
        margin-right: 75%;
    }

    .rtl .offset-xxl-10 {
        margin-left: auto;
        margin-right: 83.33333%;
    }

    .rtl .offset-xxl-11 {
        margin-left: auto;
        margin-right: 91.66667%;
    }
}

.rtl .slick-track {
    left: auto;
    right: 0;
}

.rtl .slick-slide {
    float: right;
}

.rtl [dir=rtl] .slick-slide {
    float: left;
}

.rtl .tag-addon {
    margin: 0 0.5rem 0 -0.5rem;
}

.rtl .tag-addon:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.rtl .tag-avatar {
    margin: 0 -0.5rem 0 0.5rem;
}

.rtl .tags>.tag:not(:last-child) {
    margin-right: auto;
    margin-left: 0.5rem;
}

.rtl .nav-tabs .nav-link+.nav-link {
    margin-left: 0;
    margin-right: 3px;
}

.rtl .main-nav-tabs .lSSlideOuter {
    padding-left: 35px;
    padding-right: 32px;
}

.rtl .main-nav-tabs .lSAction>a.lSPrev {
    left: auto;
    right: -32px;
    transform: rotate(180deg);
}

.rtl .main-nav-tabs .lSAction>a.lSNext {
    right: auto;
    left: -35px;
    transform: rotate(180deg);
}

.rtl .tabs-style-2 .main-nav-line .nav-link {
    margin: 0 0 0px 5px;
}

.rtl .tabs-style-3 .nav.panel-tabs li a {
    margin: 0 0 5px 5px;
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub {
        left: auto;
        right: 0;
    }

    .rtl .main-navbar .nav-sub-item {
        margin-left: auto;
        margin-right: 0;
    }
}

.rtl .main-navbar {
    left: auto;
    right: 0;
}

.rtl .main-navbar .nav-sub-link:before {
    left: auto;
    right: -19px;
    transform: rotate(180deg);
}

.rtl .main-navbar .nav-sub-link.sub-with-sub::after {
    transform: rotate(180deg);
}

@media (min-width: 992px) {
    .rtl .main-navbar {
        left: 0;
        right: auto;
        border-left: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-item {
        padding-right: inherit;
        padding-left: 28px;
    }

    .rtl .main-navbar .nav-link i {
        margin-right: auto;
        margin-left: 10px;
    }

    .rtl .main-navbar .nav-link.with-sub::after {
        margin-left: 0;
        margin-right: auto;
    }

    .rtl .main-navbar .nav-link.with-sub::before {
        left: auto;
        right: 31%;
        margin-left: auto;
        margin-right: -7px;
        border-left-color: transparent;
        border-right-color: rgba(61, 119, 180, 0.2);
    }

    .rtl .main-navbar .nav-sub-item>.nav-sub {
        left: auto;
        right: 159px;
    }

    .rtl .main-navbar .nav-sub-link.with-sub::after {
        margin-left: 0;
        margin-right: auto;
    }

    .rtl .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
        margin-left: auto;
        margin-right: 30px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-item+.nav-item {
        padding-left: inherit;
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-link i {
        margin-right: auto;
        margin-left: 8px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-link.with-sub::after {
        margin-left: auto;
        margin-right: 4px;
    }
}

@media (min-width: 1200px) and (max-width: 1477px) {
    .rtl .main-navbar .nav-sub-item>.nav-sub {
        left: auto !important;
        right: -200px !important;
    }
}

@media (max-width: 991.98px) {
    .rtl .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item {
        margin-left: auto;
        margin-right: 37px;
    }

    .rtl .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item>.nav-sub-link {
        padding-left: inherit;
        padding-right: 10px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub-link {
        margin-left: 0;
        margin-right: 18px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub-link.with-sub::after {
        margin-left: 0;
        margin-right: 4px;
    }
}

@media (min-width: 992px) {

    .rtl .main-navbar .nav-sub-mega .container>div+div,
    .rtl .main-navbar .nav-sub-mega .container-fluid>div+div {
        border-left: 0;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-sub-mega .nav+.nav {
        margin-left: auto;
        margin-right: 20px;
    }
}

@media (min-width: 1200px) {
    .rtl .main-navbar .nav-sub-mega .nav+.nav {
        margin-left: auto;
        margin-right: 25px;
    }
}

@media (min-width: 992px) {
    .rtl .main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
        margin-left: auto;
        margin-right: 0;
    }
}

.rtl .main-navbar-search .btn,
.rtl .main-navbar-search .sp-container button {
    right: auto;
    left: 0;
}

.rtl .sp-container .main-navbar-search button {
    right: auto;
    left: 0;
}

.rtl .main-navbar-two .nav-sub-item>.nav-sub {
    left: auto;
    right: 178px;
}

.rtl .main-navbar-three .nav-item+.nav-item {
    border-left-width: 1px;
    border-right-width: 0;
    padding-left: inherit;
    padding-right: 0;
}

.rtl .main-navbar-three .nav-sub-item .nav-sub {
    left: auto;
    right: 182px;
}

@media (min-width: 992px) {
    .rtl .main-navbar-three .nav-item+.nav-item {
        margin-left: auto;
        margin-right: 25px;
    }
}

.rtl .navbar.navbar-static .navbar-header {
    float: right;
}

.rtl .navbar .navbar-toggle.toggle-left {
    float: right;
    margin-left: auto;
    margin-right: 15px;
}

.rtl .navbar .navbar-toggle.toggle-right {
    float: left;
    margin-right: auto;
    margin-left: 15px;
}

.rtl .wizard>.steps>ul li .title,
.rtl .wizard>.steps>ul li+li {
    margin-left: 0;
    margin-right: 5px;
}

.rtl .wizard.vertical>.steps ul li+li {
    margin-left: 0;
    margin-right: 10px;
}

@media (min-width: 576px) {
    .rtl .wizard>.steps>ul li .title {
        margin-left: 0;
        margin-right: 10px;
    }

    .rtl .wizard>.steps>ul li+li {
        margin-left: 0;
        margin-right: 20px;
    }

    .rtl .wizard.vertical>.steps {
        float: right;
    }

    .rtl .wizard.vertical>.steps ul li+li {
        margin-left: auto;
        margin-right: 0;
    }

    .rtl .wizard.vertical>.content {
        float: left;
        border-left: 0;
        border-right: 1px solid #e8e8f7;
    }

    .rtl .wizard.vertical>.actions {
        float: left;
        border-left: 0;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 992px) {
    .rtl .wizard>.steps>ul li+li {
        margin-left: 0;
        margin-right: 30px;
    }
}

.rtl .wizard-style-2>.steps>ul a .title,
.rtl .wizard-style-2>.steps>ul a:hover .title,
.rtl .wizard-style-2>.steps>ul a:active .title {
    margin-right: auto;
    margin-left: 20px;
}

.rtl .ps>.ps__rail-y {
    right: auto !important;
    left: 0 !important;
}

.rtl .ps>.ps__rail-y>.ps__thumb-y {
    left: auto;
    right: 1px;
}

.rtl .ps.ps--active-y:hover>.ps__rail-y,
.rtl .ps.ps--active-y:focus>.ps__rail-y {
    right: auto !important;
    left: -1px !important;
}

.rtl .rdiobox span {
    padding-left: inherit;
    padding-right: 13px;
}

.rtl .rdiobox span:before {
    left: auto;
    right: 0;
}

.rtl .rdiobox span:after {
    left: auto;
    right: 5px;
}

.rtl .rdiobox span:empty {
    padding-left: inherit;
    padding-right: 0;
}

.rtl .form-group-rdiobox .rdiobox+.rdiobox {
    margin-left: auto;
    margin-right: 30px;
}

.rtl .form-group-rdiobox .rdiobox span {
    padding-left: inherit;
    padding-right: 0;
}

.rtl .custom-select[multiple],
.rtl .custom-select[size]:not([size="1"]) {
    padding-right: inherit;
    padding-left: 0.75rem;
}

.rtl .custom-select-sm {
    padding-left: inherit;
    padding-right: 0.5rem;
}

.rtl .custom-select-lg {
    padding-left: inherit;
    padding-right: 1rem;
}

.rtl .custom-file-label::after {
    right: auto;
    left: 0;
    border-left: 1px solid #e8e8f7;
    border-right: inherit;
}

.rtl .custom-range::-ms-fill-upper {
    margin-right: auto;
    margin-left: 15px;
}

.rtl .custom-switch-indicator:before {
    left: auto;
    right: 1px;
}

.rtl .custom-switch-description {
    margin-left: auto;
    margin-right: 0.5rem;
}

.rtl .custom-switch .custom-control-label::before {
    left: auto;
    right: -2.25rem;
}

.rtl .custom-switch .custom-control-label::after {
    left: auto;
    right: calc(-2.25rem + 2px);
}

.rtl .jq-star-svg {
    padding-left: inherit;
    padding-right: 3px;
}

.rtl .latest-timeline1-icon {
    left: auto;
    right: -38px;
}

.rtl .latest-timeline ul.timeline:before {
    left: auto;
    right: 15px;
}

.rtl .latest-timeline ul.timeline>li {
    padding-left: inherit;
    padding-right: 1rem;
}

.rtl .vtimeline:before {
    left: auto;
    right: 50%;
    margin-left: auto;
    margin-right: -1.5px;
}

.rtl .vtimeline .timeline-wrapper {
    padding-right: inherit;
    padding-left: 90px;
}

.rtl .vtimeline .timeline-wrapper .timeline-panel {
    margin-left: auto;
    margin-right: 15%;
}

.rtl .vtimeline .timeline-wrapper .timeline-panel:after {
    right: auto;
    left: -14px;
    border-left: 0 solid #cad4e4;
    border-right: 14px solid #cad4e4;
}

.rtl .vtimeline .timeline-wrapper .timeline-badge {
    left: auto;
    right: calc(50% - 7px);
}

.rtl .vtimeline .timeline-wrapper.timeline-inverted {
    padding-right: 90px;
    padding-left: 0;
}

.rtl .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-left: 15%;
    margin-right: auto;
}

.rtl .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-left-width: 14px;
    border-right-width: 0;
    right: -14px;
    left: auto;
}

.rtl .img-sm {
    margin-right: 0;
    margin-left: 20px;
}

.rtl .avatar-list .avatar:not(:last-child) {
    margin-right: 0;
    margin-left: 0.5rem;
}

.rtl .main-avatar::after {
    right: auto;
    left: 3px;
}

.rtl .avatar-md::after {
    right: auto;
    left: 2px;
}

.rtl .avatar-lg::after {
    right: auto;
    left: 4px;
}

.rtl .avatar-xl::after {
    right: auto;
    left: 5px;
}

.rtl .avatar-xxl::after {
    right: auto;
    left: 7px;
}

.rtl .demo-avatar-group .main-img-user+.main-img-user,
.rtl .demo-avatar-group .main-img-user+.main-avatar {
    margin-left: 0;
    margin-right: 5px;
}

.rtl .demo-avatar-group .main-avatar+.main-img-user,
.rtl .demo-avatar-group .main-avatar+.main-avatar {
    margin-left: 0;
    margin-right: -5px;
}

@media (min-width: 576px) {

    .rtl .demo-avatar-group .main-img-user+.main-img-user,
    .rtl .demo-avatar-group .main-img-user+.main-avatar {
        margin-left: 0;
        margin-right: -9px;
    }

    .rtl .demo-avatar-group .main-avatar+.main-img-user,
    .rtl .demo-avatar-group .main-avatar+.main-avatar {
        margin-left: 0;
        margin-right: -9px;
    }
}

@media (min-width: 576px) {

    .rtl #shapes .demo-avatar-group .main-img-user+.main-img-user,
    .rtl #shapes .demo-avatar-group .main-img-user+.main-avatar {
        margin-left: 0;
        margin-right: 9px;
    }
}

.rtl .main-list-item>div:last-child {
    text-align: left;
}

.rtl .ckbox span {
    padding-left: inherit;
    padding-right: 16px;
}

.rtl .ckbox span:empty {
    float: right;
    padding-left: inherit;
    padding-right: 0;
}

.rtl .ckbox span:before {
    left: auto;
    right: 0;
}

.rtl .ckbox span:after {
    left: auto;
    right: 0;
}

.rtl .main-toggle span {
    left: auto;
    right: 3px;
}

.rtl .main-toggle span::before {
    content: "on";
    left: auto;
    right: -25px;
}

.rtl .main-toggle span::after {
    right: auto;
    left: -29px;
}

.rtl .main-toggle.on span {
    left: auto;
    right: 37px;
}

.rtl .list-unstyled,
.rtl .list-inline {
    padding-left: 0;
    padding-right: 0;
}

.rtl .list-group-horizontal .list-group-item {
    margin-right: auto;
    margin-left: -1px;
}

.rtl .list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.rtl .list-group-horizontal .list-group-item:last-child {
    margin-right: auto;
    margin-left: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

@media (min-width: 576px) {
    .rtl .list-group-horizontal-sm .list-group-item {
        margin-right: auto;
        margin-left: -1px;
    }

    .rtl .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .rtl .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
    }
}

@media (min-width: 768px) {
    .rtl .list-group-horizontal-md .list-group-item {
        margin-right: auto;
        margin-left: -1px;
    }

    .rtl .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .rtl .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
    }
}

@media (min-width: 992px) {
    .rtl .list-group-horizontal-lg .list-group-item {
        margin-right: auto;
        margin-left: -1px;
    }

    .rtl .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .rtl .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
    }
}

@media (min-width: 1200px) {
    .rtl .list-group-horizontal-xl .list-group-item {
        margin-right: auto;
        margin-left: -1px;
    }

    .rtl .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .rtl .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
    }
}

.rtl .custom-control {
    padding-left: inherit;
    padding-right: 1.5rem;
}

.rtl .custom-control-inline {
    margin-right: auto;
    margin-left: 1rem;
}

.rtl .custom-control-label::before,
.rtl .custom-control-label::after {
    left: auto;
    right: -1.5rem;
}

.rtl .custom-control-label-md {
    padding-left: inherit;
    padding-right: 8px;
}

.rtl .custom-control-label-lg {
    padding-left: inherit;
    padding-right: 15px;
}

.rtl .custom-switch-input:checked~.custom-switch-indicator-md:before {
    left: auto;
    right: calc(1.46rem - 1px);
}

.rtl .custom-switch-input:checked~.custom-switch-indicator-lg:before {
    left: auto;
    right: calc(1.46rem + 1px);
}

.rtl .embed-responsive .embed-responsive-item,
.rtl .embed-responsive iframe,
.rtl .embed-responsive embed,
.rtl .embed-responsive object,
.rtl .embed-responsive video {
    left: auto;
    right: 0;
}

.rtl .float-start {
    float: right !important;
}

.rtl .float-end {
    float: left !important;
}

@media (min-width: 576px) {
    .rtl .float-sm-left {
        float: right !important;
    }

    .rtl .float-sm-right {
        float: left !important;
    }
}

@media (min-width: 768px) {
    .rtl .float-md-left {
        float: right !important;
    }

    .rtl .float-md-right {
        float: left !important;
    }
}

@media (min-width: 992px) {
    .rtl .float-lg-left {
        float: right !important;
    }

    .rtl .float-lg-right {
        float: left !important;
    }
}

@media (min-width: 1200px) {
    .rtl .float-xl-left {
        float: right !important;
    }

    .rtl .float-xl-right {
        float: left !important;
    }
}

.rtl.main-body::before {
    left: auto;
    right: 70%;
}

.rtl .main-notification-list .media-body {
    margin-left: auto;
    margin-right: 15px;
}

.rtl .main-toggle-group-demo .main-toggle+.main-toggle {
    margin-left: 0;
    margin-right: 10px;
}

.rtl .main-content-left-components {
    border-right: 0;
    border-left: 1px solid #e8e8f7;
}

.rtl .legend {
    margin-right: 0;
    margin-left: 10px;
}

.rtl .best-emp {
    right: auto;
    left: 0;
}

.rtl .userlist-table .dot-label {
    margin-right: 0 !important;
    margin-left: 10px !important;
}

@media (max-width: 768px) {
    .rtl .nav.main-nav-line.main-nav-line-chat.card-body .nav-link+.nav-link {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 576px) {
    .rtl .project-work.list-unstyled li {
        margin-right: auto;
        margin-left: 1rem;
    }
}

.rtl .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
    border-right: 1px solid #e8e8f7;
    border-left: 0;
}

@media (max-width: 991px) {
    .rtl .header-search .select2-container--default .select2-selection--single {
        border-right: 1px solid #e8e8f7;
        border-left: 0;
    }
}

.rtl .page-header .breadcrumb {
    padding-left: inherit !important;
    padding-right: 0 !important;
}

@media (max-width: 991px) {
    .rtl .header-search .select2-container--default .select2-selection--single .select2-selection__arrow b {
        left: auto !important;
        right: 93% !important;
    }

    .rtl .main-logo .header-brand-img {
        margin-right: auto;
        margin-left: 10px;
    }

    .rtl .main-content .page-header .btn.btn-list {
        padding-left: inherit;
        padding-right: 0;
        text-align: right;
    }
}

@media (max-width: 991px) {
    .rtl .header-search.dropdown .searchable__controls {
        border-radius: 0 3px 3px 0;
    }
}

@media (max-width: 767px) {
    .rtl div.dataTables_wrapper div.dataTables_filter input {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    .rtl div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
        padding-right: inherit;
        padding-left: 0;
    }

    .rtl .vtimeline:before {
        left: auto !important;
        right: 6px !important;
    }

    .rtl .vtimeline .timeline-wrapper {
        padding-right: inherit !important;
        padding-left: 0 !important;
    }

    .rtl .vtimeline .timeline-wrapper .timeline-badge {
        left: auto !important;
        right: 0 !important;
    }

    .rtl .vtimeline .timeline-wrapper .timeline-panel {
        margin-left: auto !important;
        margin-right: 10% !important;
    }

    .rtl .vtimeline .timeline-wrapper .timeline-panel:after {
        border-right: 0 solid #cad4e4 !important;
        border-left: 14px solid #cad4e4 !important;
        left: auto !important;
        right: -14px !important;
    }

    .rtl .vtimeline .timeline-wrapper.timeline-inverted {
        padding-left: inherit !important;
        padding-right: 0 !important;
    }

    .rtl .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
        margin-left: auto !important;
        margin-right: 10% !important;
    }
}

@media (max-width: 575.98px) {
    .rtl .modal.pos-static .modal-footer> :not(:first-child) {
        margin-left: auto;
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .rtl.horizontalmenu .main-footer {
        padding-left: inherit;
        padding-right: 0;
    }
}

.rtl #back-to-top {
    right: auto;
    left: 30px;
}

.rtl .custom-leftnav .main-content-left-components {
    border-right: 0;
    border-left: 0;
}

.rtl .component-item a:before {
    margin-right: auto;
    margin-left: 8px;
    float: right;
    transform: rotate(180deg);
}

.rtl .skill-tags a {
    float: right;
    margin-right: auto;
    margin-left: 5px;
}

.rtl .reviewnavs {
    padding-left: inherit;
    padding-right: 0;
}

.rtl .reviewnavs li {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .dash-icon {
    right: auto;
    left: 10px;
}

.rtl .price-value1 span {
    margin-left: auto;
    margin-right: 17px;
}

.rtl .clipboard-icon {
    right: auto;
    left: 10px;
}

.rtl .our-team .picture::after {
    left: auto;
    right: 0;
}

.rtl .task-list:after {
    left: auto;
    right: 30px;
}

.rtl .task-list li {
    padding-left: inherit;
    padding-right: 55px;
}

.rtl .task-list li .task-icon {
    left: auto;
    right: 22px;
}

.rtl .dot-label {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .activity-block .task-list li {
    padding-left: inherit;
    padding-right: 3rem;
}

.rtl .activity-block .task-list:after {
    left: auto;
    right: 18px;
}

.rtl .activity-block .task-list li .task-icon {
    left: auto;
    right: 1px;
}

.rtl .user-info {
    padding-left: inherit;
    padding-right: 0px;
}

.rtl .page-header .page-title i {
    margin-right: auto !important;
    margin-left: 8px !important;
}

.rtl .search-imgs li {
    margin-right: auto;
    margin-left: 1rem;
}

.rtl .search-imgs li img {
    margin-right: auto;
    margin-left: 1rem;
}

.rtl .handle-counter .counter-minus,
.rtl .handle-counter .counter-plus,
.rtl .handle-counter input {
    float: right;
}

.rtl .itemside .info {
    padding-right: 15px;
    padding-left: 7px;
}

.rtl .project-status:before {
    margin-right: auto;
    margin-left: 12px;
}

.rtl .project-status.success:before {
    border-left: 0;
    border-right: 3px solid #19b159;
}

.rtl .project-status.danger:before {
    border-left: 0;
    border-right: 3px solid #f16d75;
}

.rtl .project-status.warning:before {
    border-left: 0;
    border-right: 3px solid #ecb529;
}

@media (max-width: 699px) {
    .rtl .breadcrumb-5 a:first-child {
        padding-left: inherit !important;
        padding-right: 16px !important;
    }
}

.rtl .selectgroup-item+.selectgroup-item {
    margin-left: auto;
    margin-right: -1px;
}

.rtl .selectgroup-item:not(:first-child) .selectgroup-button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .selectgroup-item:not(:last-child) .selectgroup-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.rtl .selectgroup-input {
    left: auto;
    right: 0;
}

.rtl .selectgroup-pills .selectgroup-item {
    margin-right: auto;
    margin-left: 0.5rem;
}

.rtl .colorinput {
    margin-right: auto;
    margin-left: 5px;
}

.rtl .colorinput-color:before {
    left: auto;
    right: 0.25rem;
}

.rtl .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
    margin-right: 0px;
}

.rtl .alert .btn-close {
    right: auto;
    left: 15px;
}

.rtl .form-select {
    padding: 0.175rem 0.75rem 0.175rem 2.25rem;
}

.rtl .card-img-left {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.rtl .tags .tag-files span {
    margin-right: auto;
    margin-left: 104px;
    left: auto;
    right: 51px;
}

.rtl .image-pic {
    padding: 0px 10px 20px 0;
}

.rtl .pagination-radius .page-item+.page-item {
    margin-left: 0;
    margin-right: 5px;
}

.rtl #slide-right {
    left: 20px;
    right: auto;
}

.rtl #slide-left {
    right: 20px;
    left: auto;
}

.rtl .main-header-center .form-control .select2-container--default .select2-selection--single {
    border-right: 1px solid #e8e8f7;
    border-left: 0;
}

.rtl .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.rtl .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rtl .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-right: -1px;
    margin-left: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.rtl .main-header-center .select2-container--default .select2-selection--single {
    border-radius: 0 6px 6px 0;
}

.rtl .sidebar.sidebar-right,
.rtl .sidebar.sidebar-profile {
    transform: translate(-100%, 0);
}

.rtl .main-sidebar-body .nav-item.active .nav-link:before {
    border-radius: 0px 0px 0px 48px;
}

.rtl .main-sidebar-body .nav-item.active .nav-link:after {
    border-radius: 48px 0px 0px 0px;
}

.rtl .main-sidebar-body .angle {
    transform: rotate(180deg);
}

.rtl .main-sidebar-body .nav-sub .nav-sub-link:before {
    transform: rotate(180deg);
}

@media (max-width: 991.98px) {
    .rtl.horizontalmenu .hor-menu i.angle {
        transform: rotate(180deg);
    }
}

@media (min-width: 992px) {
    .rtl.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:before {
        border-radius: 0px 0px 0px 48px;
    }

    .rtl.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:after {
        border-radius: 48px 0px 0px 0px;
    }
}

@media (max-width: 991px) {

    .rtl .main-sidebar,
    .rtl .main-navbar {
        transform: translateX(240px);
    }
}

.rtl .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: auto;
    left: 9px;
}

.rtl .main-header-center .select2-container--default .select2-selection--single {
    border-right: 1px solid #e8e8f7;
    border-left: 0;
}

.rtl .main-header-center .btn {
    border-radius: 6px 0 0 6px !important;
}

@media (max-width: 991.98px) {
    .rtl.horizontalmenu .main-body-1 li.nav-item.active .nav-link {
        border-radius: 0 30px 30px 0;
    }

    .rtl.horizontalmenu .main-body-1 .nav-item.active .nav-link:before {
        border-radius: 0px 0px 0px 48px;
    }

    .rtl.horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
        border-radius: 48px 0px 0px 0px;
    }
}

@media (min-width: 992px) {

    .rtl .hor-menu .slide-left,
    .rtl .hor-menu .slide-right {
        transform: rotate(180deg);
    }
}

.rtl .btn-icon-text i {
    float: right;
    margin-top: 4px;
}

@media screen and (min-width: 992px) {
    .rtl.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        border-radius: 11px 0 0 11px !important;
    }

    .rtl.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        border-radius: 11px 0 0 11px !important;
    }
}

.rtl .tasks .ckbox.me-4 {
    margin-right: 0 !important;
}

.rtl .tasks .me-2 {
    margin-right: 0 !important;
}

.rtl .crypto-buysell-card .select2-container--default .select2-selection--single {
    border-radius: 6px 0 0 6px !important;
}

.rtl .crypto-wallet .clipboard-icon {
    border-radius: 4px 0 0 4px !important;
}

.rtl .crypto-cur .me-3,
.rtl .crypto-cur2 .me-3,
.rtl .wallet .me-3 {
    margin-right: 0 !important;
}

.rtl .crypto-icon.me-3 {
    margin-right: 0 !important;
}

.rtl .wallet .clipboard-icon {
    border-radius: 4px 0 0 4px !important;
}

.rtl .wallet .input-group-addon-left {
    border-radius: 0 4px 4px 0 !important;
}

.rtl .transcation-crypto .me-2 {
    margin-right: 0 !important;
}

.rtl div.dataTables_wrapper div.dataTables_filter {
    text-align: left;
}

.rtl div.dataTables_wrapper div.dataTables_length label {
    text-align: right;
}

.rtl .page-item:last-child .page-link {
    border-radius: 6px 0 0 6px;
}

.rtl .page-item:first-child .page-link {
    border-radius: 0 6px 6px 0;
}

.rtl .table-product .me-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
}

.rtl .input-group .btn.ripple.btn-primary {
    border-radius: 6px 0 0 6px !important;
}

.rtl .product-carousel #carousel .btn-list a i {
    margin-left: 5px;
    float: right;
    margin-top: 4px;
}

.rtl .btn-apply.coupon {
    border-radius: 4px 0 0 4px !important;
}

.rtl .counter-minus {
    border-radius: 0 3px 3px 0;
}

.rtl .counter-plus {
    border-radius: 3px 0 0 3px;
}

.rtl #wishlist .product-grid .btn i {
    display: inline-block;
}

.rtl .ql-snow .ql-formats {
    float: right;
}

.rtl .ql-editor {
    text-align: right;
}

.rtl .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button.ff_fileupload_start_upload {
    margin-left: 0.5em;
}

.rtl .ql-snow .ql-tooltip {
    right: 19rem !important;
    left: auto !important;
}

.rtl .item1-links.nav.nav-tabs .nav-link i {
    float: right;
}

.rtl #myaddres .btn-round i,
.rtl #myaddres .card-header i {
    float: right;
}

.rtl [type=email],
.rtl [type=number],
.rtl [type=tel],
.rtl [type=url] {
    direction: rtl;
}

.rtl .inbox-pagination a.np-btn {
    transform: rotate(180deg);
}

.rtl .item-card-desc .me-2,
.rtl .ad-post-details .me-2 {
    margin-right: 0 !important;
}

.rtl .item-card-desc .me-4,
.rtl .ad-post-details .me-4 {
    margin-right: 0 !important;
}

.rtl .alert-inner--icon i {
    float: right;
    margin-top: 4px;
    margin-left: 10px;
}

.rtl .breadcrumb-5 a {
    padding: 0 39px 0 10px;
}

.rtl .breadcrumb-5 a:last-child {
    border-radius: 5px 0 0 5px;
}

.rtl #icon .btn i {
    float: right;
    margin-top: 3px;
    margin-left: 5px;
}

.rtl .list-group.checked i {
    float: right;
}

.rtl .page-link i {
    transform: rotate(180deg);
}

.rtl .bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
.rtl .bs-popover-start>.popover-arrow {
    right: calc(-0.5rem - 1px);
    left: auto;
}

.rtl .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
.rtl .bs-popover-start>.popover-arrow::after {
    left: 1px;
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ffffff;
    border-right-color: transparent;
}

.rtl .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
.rtl .bs-popover-start>.popover-arrow::before {
    left: 0;
    right: auto;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
    border-right-color: transparent;
}

.rtl .bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
.rtl .bs-popover-end>.popover-arrow {
    left: calc(-0.5rem - 1px);
    right: auto;
}

.rtl .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
.rtl .bs-popover-end>.popover-arrow::before {
    left: 0;
    right: auto;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
    border-left-color: transparent;
}

.rtl .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
.rtl .bs-popover-end>.popover-arrow::after {
    left: 1px;
    right: auto;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ffffff;
    border-left-color: transparent;
}

.rtl .tooltip-danger.bs-tooltip-right .arrow::before,
.rtl .tooltip-danger.bs-tooltip-auto[data-popper-placement^=right] .arrow::before {
    border-left-color: #f16d75 !important;
}

.rtl .tooltip-info.bs-tooltip-left .arrow::before,
.rtl .tooltip-info.bs-tooltip-auto[data-popper-placement^=left] .arrow::before {
    border-right-color: #01b8ff !important;
}

.rtl .toast-header .ms-2 {
    margin-left: 0 !important;
}

.rtl #ChatCalls .me-2,
.rtl #ChatContacts .me-2 {
    margin-right: 0 !important;
    display: inline-block;
}

.rtl .bootstrap-tagsinput .badge [data-role=remove] {
    margin-left: auto;
    margin-right: 8px;
}

.rtl .active.carousel-item-start,
.rtl .carousel-item-prev:not(.carousel-item-end) {
    -webkit-transform: translateX(-100%);
    transform: translateX(100%) !important;
}

.rtl .active.carousel-item-end,
.rtl .carousel-item-next:not(.carousel-item-start) {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%) !important;
}

.rtl .carousel-item-next:not(.carousel-item-start) {
    transform: translateX(-100%) !important;
}

.rtl .MS-content {
    direction: ltr;
}

.rtl .main-msg-wrapper:before,
.rtl .main-msg-wrapper:after {
    border-color: transparent transparent transparent #f4f6ff !important;
}

.rtl .main-img-user.me-3 {
    margin-right: 0 !important;
}

.rtl #external-events {
    text-align: right;
}

.rtl .fc .fc-scroller-harness {
    direction: rtl;
}

.rtl .fc .fc-button .fc-icon {
    transform: rotate(180deg);
}

.rtl .datepicker-container {
    direction: rtl;
}

.rtl .datepicker-panel>ul>li {
    float: right;
}

.rtl .timeline-footer .me-1 {
    margin-right: 0 !important;
}

.rtl #ui_notifIt {
    right: auto;
    left: 10px;
}

.rtl #notifIt_close {
    right: auto;
    left: 0;
}

.rtl .notifit_confirm_bg,
.rtl .notifit_prompt_bg {
    left: auto;
    right: 0;
}

.rtl .notifit_confirm,
.rtl .notifit_prompt {
    left: auto;
    right: 0;
}

.rtl .notifit_confirm_accept,
.rtl .notifit_confirm_cancel,
.rtl .notifit_prompt_accept,
.rtl .notifit_prompt_cancel {
    margin-right: 0;
    margin-left: 5px;
}

.rtl .tree ul {
    margin-left: auto;
    margin-right: 1em;
}

.rtl .tree ul ul {
    margin-left: auto;
    margin-right: 0.5em;
}

.rtl .tree ul:before {
    left: auto;
    right: -10px;
    border-left: 0;
    border-right: 2px solid #d8e0f7;
}

.rtl .tree ul li:before {
    left: auto;
    right: -10px;
}

.rtl .tree li i {
    margin-right: auto;
    margin-left: 10px;
}

.rtl .tree .branch i {
    float: right;
    margin-top: 5px;
}

.rtl .dropdown-menu .dropdown-item i {
    float: right;
}

.rtl .settings-main-icon.me-4 {
    margin-right: 0 !important;
}

.rtl .file-browser .btn-primary {
    border-radius: 6px 0 0 6px !important;
}

.rtl .intl-tel-input .flag-dropdown .selected-flag {
    margin: 1px 1px 1px 2px;
    padding: 11px 6px 10px 16px;
}

.rtl .intl-tel-input .flag-dropdown .country-list .flag {
    margin-right: auto;
    margin-left: 6px;
}

.rtl .intl-tel-input .flag-dropdown .country-list .country-name {
    margin-right: auto;
    margin-left: 6px;
}

.rtl .intl-tel-input input {
    padding-left: 0;
    padding-right: 47px;
}

.rtl .telephone-input .btn {
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rtl .richText .richText-toolbar ul {
    padding-left: inherit;
    padding-right: 0;
}

.rtl .richText .richText-toolbar ul li {
    float: right;
}

.rtl .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
    right: auto;
    left: 10px;
}

.rtl .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline {
    float: right;
}

.rtl .richText .richText-editor {
    border-left: 0;
    border-right: #ffffff solid 2px;
}

.rtl .richText .richText-editor:focus {
    border-left: #e8e8f7 solid 2px;
    border-right: transparent solid 2px;
}

.rtl .richText .richText-help {
    float: left;
}

.rtl .richText .richText-undo,
.rtl .richText .richText-redo {
    float: left;
    border-right: 0;
    border-left: #e8e8f7 solid 1px;
}

.rtl .richText .richText-list.list-rightclick {
    border-right: 0;
    border-left: #e8e8f7 solid 1px;
}

.rtl .richText .richText-toolbar ul li a {
    border-right: 0;
    border-left: #e8e8f7 solid 1px;
}

@media screen and (min-width: 992px) {
    .rtl.icontext-menu.main-sidebar-hide.main-sidebar-open li.nav-item.active .nav-link {
        border-radius: 0 30px 30px 0;
    }
}

.rtl .dropdown-menu .main-form-search .btn,
.rtl .dropdown-menu .main-form-search .sp-container button {
    border-radius: 6px 0 0 6px;
}

.rtl .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
    border-radius: 0 3px 3px 0;
}

.rtl .transcation-crypto .table th:last-child,
.rtl .transcation-crypto .table td:last-child {
    border-left: 1px solid #e8e8f7 !important;
}

.rtl table.table-bordered.dataTable th:first-child,
.rtl table.table-bordered.dataTable td:first-child {
    border-right-width: 0px;
    border-left-width: 1px;
}

.rtl table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control {
    padding-left: inherit;
    padding-right: 30px;
}

.rtl table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
.rtl table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
    left: auto;
    right: 5px;
}

.rtl .side-menu .nav-item.show .nav-sub .nav-sub-item i.angle {
    transform: rotate(180deg);
}

.rtl .side-menu .nav-item .nav-sub .nav-sub-item.show i.angle {
    transform: rotate(90deg);
}

.rtl .side-menu .nav-item .nav-sub .nav-sub-item i.angle {
    right: auto;
    left: 20px;
}

.rtl .flag-dropdown .dropdown-menu .me-3 {
    margin-right: 0 !important;
}

.rtl table.dataTable>thead .sorting:after,
.rtl table.dataTable>thead .sorting_asc:after,
.rtl table.dataTable>thead .sorting_desc:after,
.rtl table.dataTable>thead .sorting_asc_disabled:after,
.rtl table.dataTable>thead .sorting_desc_disabled:after {
    left: 0.5em;
    right: auto;
}

.rtl table.dataTable>thead .sorting:before,
.rtl table.dataTable>thead .sorting_asc:before,
.rtl table.dataTable>thead .sorting_desc:before,
.rtl table.dataTable>thead .sorting_asc_disabled:before,
.rtl table.dataTable>thead .sorting_desc_disabled:before {
    left: 1em;
    right: auto;
}

.rtl .lg-toolbar .lg-icon {
    float: left;
}

.rtl #lg-counter {
    padding-left: inherit;
    padding-right: 20px;
}

.rtl .datetimepicker table th.prev span,
.rtl .datetimepicker table th.next span {
    transform: rotate(180deg);
}

@media (max-width: 400px) {
    .rtl .datepicker-dropdown {
        left: 0 !important;
    }
}

.rtl .item-card-desc a {
    display: inline-block;
}

.rtl .main-content-body-profile .me-2 {
    margin-right: 0 !important;
}

.rtl .table-product .me-3,
.rtl .blog-details .me-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
}

.rtl .hor-scroll div:first-child {
    margin-left: -3px;
    margin-right: 0 !important;
}

.rtl .msl-actions {
    left: 5px;
    right: auto !important;
}

.rtl .searchable__controls {
    padding-left: 34px;
    padding-right: 0 !important;
}

.rtl .searchable__controls__arrow__inner {
    right: 50%;
    left: inherit !important;
    transform: translate(50%, -50%);
}

.rtl .searchable__controls__arrow {
    left: 0;
    right: auto !important;
}

.rtl .searchable--active .searchable__controls__arrow__inner {
    transform: translate(50%, -50%) rotate(-180deg);
}

@media (min-width: 576px) {
    .rtl .float-sm-start {
        float: right !important;
    }

    .rtl .float-sm-end {
        float: left !important;
    }
}

@media (min-width: 768px) {
    .rtl .float-md-start {
        float: right !important;
    }

    .rtl .float-md-end {
        float: left !important;
    }
}

@media (min-width: 992px) {
    .rtl .float-lg-start {
        float: right !important;
    }

    .rtl .float-lg-end {
        float: left !important;
    }
}

@media (min-width: 1200px) {
    .rtl .float-xl-start {
        float: right !important;
    }

    .rtl .float-xl-end {
        float: left !important;
    }
}

.rtl .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-content {
    margin-right: 8px;
    margin-left: 0;
}

.rtl .toast-header .btn-close {
    margin-left: -0.375rem;
    margin-right: 0.75rem;
}

.rtl .go1186250972 button {
    padding: 0 10px 0 0 !important;
}

.rtl .css-1eomvmf-MuiButtonBase-root-MuiIconButton-root {
    margin-right: auto;
    margin-left: inherit;
}

.rtl .contact-info .me-1 {
    margin-right: inherit !important;
    margin-left: 0.25rem !important;
}

.rtl .contact-info .me-0 {
    margin-right: inherit !important;
    margin-left: 0 !important;
}

.rtl #carouselExample2 .slick-prev,
.rtl #carouselExample6 .slick-prev {
    right: 0px;
    left: inherit;
}

.rtl #carouselExample2 .slick-next,
.rtl #carouselExample6 .slick-next {
    left: 0px;
    right: inherit;
}

.rtl .modal-header .btn-close {
    margin: -0.5rem auto -0.5rem -0.5rem;
}

.rtl .modal-body .react-datepicker-popper {
    right: 0px !important;
    left: auto !important;
    transform: translate(-38px, 90px) !important;
}

.rtl .deni-react-treeview-item-container.level-2 {
    padding-right: 30px !important;
    padding-left: 0 !important;
}

.rtl .deni-react-treeview-item-container.level-3 {
    padding-right: 65px !important;
    padding-left: 0 !important;
}

.rtl .deni-react-treeview-item-container.level-4 {
    padding-right: 95px !important;
    padding-left: 0 !important;
}

.rtl .deni-react-treeview-item-container.level-5 {
    padding-right: 125px !important;
    padding-left: 0 !important;
}

.rtl .deni-react-treeview-item-container.level-6 {
    padding-right: 155px !important;
    padding-left: 0 !important;
}

.rtl .form-check.form-switch .form-check-label {
    margin-right: 36px;
    margin-left: 0;
}

.rtl .input-group-text input[type=radio],
.rtl .input-group-text input[type=checkbox] {
    margin-right: -5px;
    margin-left: 0;
}

.rtl .react-datepicker-wrapper .form-control {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rtl .intl-tel-input.allow-dropdown input,
.rtl .intl-tel-input.allow-dropdown input[type=text],
.rtl .intl-tel-input.allow-dropdown input[type=tel] {
    padding-left: 6px;
    padding-right: 52px;
    margin-right: 0;
    margin-left: 0;
}

.rtl .intl-tel-input .country-list {
    text-align: right;
}

.rtl .MuiStepContent-root {
    border-right: 1px solid #e8e8f7 !important;
    border-left: 0 !important;
    margin-right: 12px;
    margin-left: 0;
    padding-right: 20px;
    padding-left: 8px;
}

.rtl.dark-theme .MuiStepContent-root {
    border-right: 1px solid var(--dark-border) !important;
    border-left: 0 !important;
}

.rtl .MuiStepConnector-line {
    border-right: 1px solid #e8e8f7 !important;
    border-left: 0 !important;
}

.rtl.dark-theme .MuiStepConnector-line {
    border-right: 1px solid var(--dark-border) !important;
    border-left: 0 !important;
}

.rtl .MuiStepConnector-root {
    margin-right: 12px;
    margin-left: 0;
}

.rtl .public-DraftStyleDefault-ltr {
    direction: rtl;
    text-align: right;
}

.rtl .rdw-dropdown-carettoopen,
.rtl .rdw-dropdown-carettoclose {
    left: 10%;
    right: auto;
}

.rtl .main-header.side-header .main-header-center .searchable__controls {
    border-radius: 0 5px 5px 0;
}

.rtl .currency-item {
    flex-direction: row-reverse;
}

.rtl .msl-chip-delete {
    margin-right: 3px;
    margin-left: 0;
}

.rtl .msl-chip {
    padding: 3px 6px 3px 3px;
}

.rtl.landing-page .top-footer h6::before {
    right: -3px;
    left: auto;
}

.rtl .list-group {
    padding-right: 0;
    padding-left: 0;
}

.rtl .page-item:not(:first-child) .page-link {
    margin-right: -1px;
    margin-left: inherit;
}

.rtl .main-content.side-content {
    transition: margin-right 0.3s ease;
}

.rtl .intl-tel-input .flag-container .arrow {
    margin-right: 5px;
    margin-left: 0;
}

.rtl .chartsh.c3 .c3-legend-item text {
    text-anchor: end;
}

.rtl .apexcharts-yaxis-texts-g text {
    text-anchor: start;
}

.rtl .nvd3.nv-axis text {
    text-anchor: start !important;
}

.rtl .nvd3 .nv-legend-text {
    text-anchor: end;
}

.rtl .crypto-buysell-card .searchable__controls {
    border-radius: 5px 0 0 5px;
}

.rtl .ql-snow .ql-tooltip.ql-editing {
    right: 0 !important;
    left: inherit !important;
}

@media (max-width: 1199px) {
    .rtl .popover.bs-popover-end {
        transform: translate3d(0px, -95px, 0px) !important;
    }

    .rtl .popover.bs-popover-start {
        transform: translate3d(0px, -95px, 0px) !important;
    }
}

/**********************Light Header Start********************************/
@media (min-width: 992px) {
    .light-header.main-body .side-header {
        border-bottom-color: #e8e8f7;
        box-shadow: -7.829px 11.607px 20px 0px rgba(0, 0, 0, 0.2);
    }
}

@media (min-width: 992px) {
    .light-header.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo {
        display: block;
    }

    .light-header.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo-dark {
        display: none;
    }
}

@media (max-width: 991px) {

    .light-header.main-body .mobile-logo-dark,
    .light-header.horizontalmenu .mobile-logo-dark {
        display: none;
    }

    .light-header.main-body .mobile-logo,
    .light-header.horizontalmenu .mobile-logo {
        display: block;
    }
}

@media (max-width: 991px) {
    .light-header .main-header.side-header {
        border-bottom-color: #e8e8f7;
    }

    .light-header .responsive-navbar.navbar .navbar-collapse {
        background: #ffffff;
        border-bottom-color: #e8e8f7;
        border-top-color: #e8e8f7;
    }
}

.light-header .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
    background-color: #ffffff;
    border-color: #e8e8f7;
}

.light-header .main-header {
    background-color: #ffffff;
    border-bottom-color: #e8e8f7;
}

.light-header .main-header .form-control {
    color: #000000;
    background-color: #ffffff;
    border-color: #e8e8f7;
}

.light-header .main-header .select2-container--default .select2-selection--single {
    background-color: #ffffff;
    border-color: #e8e8f7 !important;
}

.light-header .main-header .responsive-navbar.navbar .navbar-collapse {
    box-shadow: none;
}

.light-header .main-header .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #a8afc7;
}

.light-header .main-header .navbar-toggler {
    color: #4a4d51;
}

.light-header .navbar-toggler .header-icons:hover {
    background-color: #eaedf7;
}

.light-header .main-form-search .form-control:focus {
    background-color: #ffffff;
    border-color: #e8e8f7;
}

.light-header .main-header-menu-icon span {
    background-color: #2f395f;
}

.light-header .main-header-menu-icon span::before,
.light-header .main-header-menu-icon span::after {
    background-color: #2f395f;
}

.light-header .main-header-right .nav-link.icon {
    color: #4a4d51 !important;
}

.light-header .main-header-right .header-search .header-icons {
    color: #4a4d51 !important;
}

.light-header .main-header-right .nav-link.icon:hover {
    background: #eaedf7;
}

.light-header .main-header-center .form-control {
    border-color: #e8e8f7;
}

.light-header .main-header-center .form-control:focus {
    border-color: #e8e8f7 !important;
}

.light-header .main-header .searchable__controls {
    background-color: #ffffff !important;
    border: 1px solid #e8e8f7 !important;
}

.light-header .main-header .searchable__controls input {
    color: #1d212f !important;
}

.light-header .main-header-center .search-btn {
    background-color: var(--primary-bg-color);
}

/**********************Light Header End********************************/
/**********************Dark Header Start********************************/
@media (min-width: 992px) {
    .header-dark.main-body .side-header {
        border-bottom-color: var(--dark-border);
        box-shadow: -7.829px 11.607px 20px 0px rgba(255, 255, 255, 0.2);
    }
}

@media (min-width: 992px) {
    .header-dark.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo {
        display: none;
    }

    .header-dark.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo-dark {
        display: block;
    }
}

@media (max-width: 991px) {

    .header-dark.main-body .mobile-logo-dark,
    .header-dark.horizontalmenu .mobile-logo-dark {
        display: block;
    }

    .header-dark.main-body .mobile-logo,
    .header-dark.horizontalmenu .mobile-logo {
        display: none;
    }
}

@media (max-width: 991px) {
    .header-dark .main-header.side-header {
        border-bottom-color: var(--dark-border);
    }

    .header-dark .responsive-navbar.navbar .navbar-collapse {
        background: var(--dark-theme);
        border-bottom-color: var(--dark-border);
        border-top-color: var(--dark-border);
    }
}

.header-dark .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
    background-color: var(--dark-theme);
    border-color: var(--dark-border) !important;
}

.header-dark .main-header {
    background-color: var(--dark-theme);
    border-bottom-color: var(--dark-border);
}

.header-dark .main-header .form-control {
    color: var(--dark-color);
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.header-dark .main-header .select2-container--default .select2-selection--single {
    background-color: var(--dark-theme);
    border-color: var(--dark-border) !important;
}

.header-dark .main-header .responsive-navbar.navbar .navbar-collapse {
    box-shadow: none;
}

.header-dark .main-header .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: var(--dark-color);
}

.header-dark .main-header .navbar-toggler {
    color: var(--dark-color);
}

.header-dark .navbar-toggler .header-icons:hover {
    background-color: var(--dark-body);
}

.header-dark .main-form-search .form-control:focus {
    background-color: var(--dark-theme);
    border-color: var(--dark-border);
}

.header-dark .main-header-menu-icon span {
    background-color: var(--dark-color);
}

.header-dark .main-header-menu-icon span::before,
.header-dark .main-header-menu-icon span::after {
    background-color: var(--dark-color);
}

.header-dark .main-header-right .nav-link.icon {
    color: var(--dark-color) !important;
}

.header-dark .main-header-right .header-search .header-icons {
    color: var(--dark-color) !important;
}

.header-dark .main-header-right .nav-link.icon:hover {
    background: var(--dark-body);
}

.header-dark .main-header-center .form-control {
    border-color: var(--dark-border);
}

.header-dark .main-header-center .form-control:focus {
    border-color: var(--dark-border) !important;
}

.header-dark .main-header .searchable__controls {
    background-color: var(--dark-theme) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.header-dark .main-header .searchable__controls input {
    color: var(--dark-color) !important;
}

.header-dark .main-header-center .search-btn {
    border-color: var(--primary-bg-color);
}

/**********************Dark Header End********************************/
/**********************Color Header Start********************************/
@media (min-width: 992px) {
    .color-header.main-body .side-header {
        border-bottom-color: rgba(255, 255, 255, 0.2);
        box-shadow: -4px 5px 20px 0px rgba(255, 255, 255, 0.2);
    }
}

@media (min-width: 992px) {
    .color-header.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo {
        display: none;
    }

    .color-header.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo-dark {
        display: block;
    }
}

@media (max-width: 991px) {

    .color-header.main-body .mobile-logo-dark,
    .color-header.horizontalmenu .mobile-logo-dark {
        display: block;
    }

    .color-header.main-body .mobile-logo,
    .color-header.horizontalmenu .mobile-logo {
        display: none;
    }
}

@media (max-width: 991px) {
    .color-header .main-header.side-header {
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }

    .color-header .responsive-navbar.navbar .navbar-collapse {
        background: var(--primary-bg-color);
        border-bottom-color: rgba(255, 255, 255, 0.2);
        border-top-color: rgba(255, 255, 255, 0.2);
    }
}

.color-header .main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
    background-color: var(--primary-bg-color);
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.color-header .main-header {
    background-color: var(--primary-bg-color);
    border-bottom-color: rgba(255, 255, 255, 0.2);
}

.color-header .main-header .form-control {
    color: #ffffff;
    background-color: var(--primary-bg-color);
    border-color: rgba(255, 255, 255, 0.2);
}

.color-header .main-header .select2-container--default .select2-selection--single {
    background-color: var(--primary-bg-color);
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.color-header .main-header .responsive-navbar.navbar .navbar-collapse {
    box-shadow: none;
}

.color-header .main-header .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #ffffff;
}

.color-header .main-header .navbar-toggler {
    color: #ffffff;
}

.color-header .main-header .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #ffffff;
}

.color-header .main-header .form-control::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.color-header .main-header .main-form-search .btn,
.color-header .main-header .main-form-search .sp-container button {
    background-color: #eaedf7;
    color: var(--primary-bg-color);
}

.color-header .main-header-center .form-control:focus+.btn {
    color: var(--primary-bg-color);
}

.color-header .navbar-toggler .header-icons:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.color-header .main-form-search .form-control:focus {
    background-color: var(--primary-bg-color);
    border-color: rgba(255, 255, 255, 0.2);
}

.color-header .main-header-menu-icon span {
    background-color: #ffffff;
}

.color-header .main-header-menu-icon span::before,
.color-header .main-header-menu-icon span::after {
    background-color: #ffffff;
}

.color-header .main-header-right .nav-link.icon {
    color: #ffffff;
}

.color-header .main-header-right .nav-link.icon:hover {
    background: rgba(0, 0, 0, 0.2);
}

.color-header .main-header-center .form-control {
    border-color: rgba(255, 255, 255, 0.2);
}

.color-header .main-header-center .form-control:focus {
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.color-header .main-header-center .search-btn {
    background-color: #ffffff !important;
    color: var(--primary-bg-color);
}

.color-header .main-header .searchable__controls {
    background-color: var(--primary-bg-color) !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.color-header .main-header .searchable__controls input {
    color: #ffffff !important;
}

.color-header .main-header .searchable__controls input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
}

/**********************Color Header End********************************/
/**********************Light Menu Start********************************/
.light-menu.main-body.leftmenu .side-menu .nav-link:hover {
    color: var(--primary-bg-color);
}

.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:hover,
.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:focus {
    color: var(--primary-bg-color) !important;
}

.light-menu.main-body.leftmenu .main-sidebar-sticky {
    background: #ffffff !important;
    border-right-color: #e8e8f7;
    border-left-color: #e8e8f7;
}

.light-menu.main-body.leftmenu .main-sidebar-body .nav-label {
    color: #c8c4db;
}

.light-menu.main-body.leftmenu .main-sidebar-body .nav-link {
    color: #8784a7;
}

.light-menu.main-body.leftmenu .main-sidebar-body .nav-item.active .nav-link:before,
.light-menu.main-body.leftmenu .main-sidebar-body .nav-item.active .nav-link:after {
    border-right-color: #ffffff;
    border-left-color: #ffffff;
}

@media (min-width: 992px) {
    .light-menu.main-body.leftmenu .sidemenu-logo {
        background: #ffffff;
    }
}

.light-menu.main-body.leftmenu .sidemenu-logo {
    border-bottom-color: #e8e8f7;
    border-left-color: #e8e8f7 !important;
    border-right-color: #e8e8f7 !important;
}

.light-menu.main-body.leftmenu .sidemenu-logo .header-brand-img.desktop-logo.theme-logo {
    display: block !important;
}

.light-menu.main-body.leftmenu .sidemenu-logo .header-brand-img.desktop-logo {
    display: none !important;
}

.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link,
.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub .nav-sub-link:before {
    color: #b2b2d0 !important;
}

.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub-item.active>.nav-sub-link.active {
    color: var(--primary-bg-color) !important;
}

.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub-item.active>.nav-sub-link.active::before {
    color: var(--primary-bg-color) !important;
}

@media (min-width: 992px) {
    .light-menu.main-body.leftmenu.main-sidebar-hide .side-menu .main-logo .desktop-logo {
        display: none !important;
    }

    .light-menu.main-body.leftmenu.main-sidebar-hide .side-menu .main-logo .icon-logo {
        display: none !important;
    }

    .light-menu.main-body.leftmenu.main-sidebar-hide .side-menu .main-logo .icon-logo.theme-logo {
        display: block !important;
    }

    .light-menu.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo {
        display: none !important;
    }

    .light-menu.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo.theme-logo {
        display: block !important;
    }

    .light-menu.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo {
        display: none !important;
    }
}

.light-menu.main-body.leftmenu .main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub .sidemenu-label,
.light-menu.main-body.leftmenu .main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub i,
.light-menu.main-body.leftmenu .main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub::before {
    color: var(--primary-bg-color) !important;
    opacity: 1 !important;
}

.light-menu.main-body.leftmenu .main-sidebar-body li.active li .sidemenu-label,
.light-menu.main-body.leftmenu .main-sidebar-body li.active li i {
    color: #b2b2d0 !important;
}

.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:hover.sub-with-sub .sidemenu-label,
.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:hover.sub-with-sub .angle,
.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:focus.sub-with-sub .sidemenu-label,
.light-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:focus.sub-with-sub .angle {
    color: var(--primary-bg-color) !important;
}

.light-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo.theme-logo,
.light-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo.theme-logo {
    display: none !important;
}

.light-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo.theme-logo,
.light-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo.theme-logo {
    display: block !important;
}

@media screen and (min-width: 992px) {

    .light-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub,
    .light-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        background: #ffffff;
        box-shadow: -6px 0px 4px 3px rgba(0, 0, 0, 0.2);
    }

    .light-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link,
    .light-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
        background-color: #ffffff;
    }

    .light-menu.main-body.leftmenu.hover-submenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu-label1,
    .light-menu.main-body.leftmenu.hover-submenu1.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu-label1 {
        border-bottom-color: #e8e8f7;
    }
}

.light-menu.horizontalmenu .main-navbar.hor-menu {
    background-color: #ffffff;
    border-bottom-color: #e8e8f7;
}

.light-menu.horizontalmenu .main-header {
    box-shadow: none;
}

.light-menu.horizontalmenu .main-navbar .nav-link,
.light-menu.horizontalmenu .main-navbar .nav-item:hover .nav-link.with-sub::after {
    color: #8784a7;
}

@media (min-width: 992px) {

    .light-menu.horizontalmenu .main-navbar.hor-menu .nav-item.active .nav-link,
    .light-menu.horizontalmenu .main-navbar.hor-menu .nav-item:hover .nav-link {
        color: var(--primary-bg-color);
    }

    .light-menu.horizontalmenu .main-navbar.hor-menu .nav-item.active .nav-link .hor-icon,
    .light-menu.horizontalmenu .main-navbar.hor-menu .nav-item:hover .nav-link .hor-icon {
        color: var(--primary-bg-color);
    }

    .light-menu.horizontalmenu .hor-menu .nav-link .hor-icon {
        color: #8784a7;
        fill: #8784a7;
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub {
        background-color: #ffffff;
        border-top-color: #e8e8f7;
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-link {
        color: #b2b2d0;
    }

    .light-menu.horizontalmenu .menu-nav .nav-item ul.nav-sub::before {
        border-top-color: #e8e8f7;
        border-left-color: #e8e8f7;
        background-color: #ffffff;
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-link:hover,
    .light-menu.horizontalmenu .main-navbar .nav-sub-link:focus {
        color: var(--primary-bg-color) !important;
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-link.sub-with-sub::after {
        color: #b2b2d0;
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-link:hover.sub-with-sub::after,
    .light-menu.horizontalmenu .main-navbar .nav-sub-link:focus.sub-with-sub::after {
        color: var(--primary-bg-color) !important;
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active {
        color: var(--primary-bg-color);
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
        color: var(--primary-bg-color);
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-link.active.sub-with-sub::after {
        color: var(--primary-bg-color) !important;
    }

    .light-menu.horizontalmenu .main-navbar.hor-menu .nav-item.active .nav-link.with-sub::after {
        color: var(--primary-bg-color);
    }

    .light-menu.horizontalmenu .main-navbar .sub-nav-sub {
        background-color: #ffffff;
        border-color: #e8e8f7;
    }
}

@media (max-width: 991.98px) {
    .light-menu.horizontalmenu .main-body-1 .nav-label {
        color: #c8c4db;
    }

    .light-menu.horizontalmenu .main-body-1 .nav-item.active .nav-link:before,
    .light-menu.horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
        border-right-color: #ffffff;
        border-left-color: #ffffff;
    }

    .light-menu.horizontalmenu .main-body-1 .nav-sub-link {
        color: #b2b2d0;
    }

    .light-menu.horizontalmenu .main-body-1 .nav-sub .nav-sub-link:before {
        color: #b2b2d0;
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active {
        color: var(--primary-bg-color);
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
        color: var(--primary-bg-color);
        opacity: 1;
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-link:hover,
    .light-menu.horizontalmenu .main-navbar .nav-sub-link:focus {
        color: #b2b2d0 !important;
    }

    .light-menu.horizontalmenu .main-navbar .nav-sub-link:hover:before,
    .light-menu.horizontalmenu .main-navbar .nav-sub-link:focus:before {
        opacity: 1;
    }
}

@media (min-width: 992px) {

    .light-menu .hor-menu .slide-left,
    .light-menu .hor-menu .slide-right {
        color: #000000;
        border-color: #e8e8f7;
        background-color: #ffffff;
    }
}

.light-menu .hor-scroll div:nth-child(2) div {
    background-color: #fff !important;
}

.light-menu.leftmenu .hor-scroll div:nth-child(3) div {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.light-menu.leftmenu.rtl .hor-scroll div:nth-child(3) {
    left: 2px;
    right: auto !important;
}

/**********************Light Menu End********************************/
/**********************Dark Menu Start********************************/
.dark-menu.main-body.leftmenu .side-menu .nav-link:hover {
    color: #ffffff;
}

.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:hover,
.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:focus {
    color: #ffffff !important;
}

.dark-menu.main-body.leftmenu .main-sidebar-sticky {
    background: var(--dark-theme) !important;
    border-right-color: var(--dark-border);
    border-left-color: var(--dark-border);
}

.dark-menu.main-body.leftmenu .main-sidebar-body .nav-label {
    color: rgba(255, 255, 255, 0.3);
}

.dark-menu.main-body.leftmenu .main-sidebar-body .nav-link {
    color: rgba(255, 255, 255, 0.4);
}

.dark-menu.main-body.leftmenu .main-sidebar-body .nav-item.active .nav-link:before,
.dark-menu.main-body.leftmenu .main-sidebar-body .nav-item.active .nav-link:after {
    border-right-color: var(--dark-theme);
    border-left-color: var(--dark-theme);
}

@media (min-width: 992px) {
    .dark-menu.main-body.leftmenu .sidemenu-logo {
        background: var(--dark-theme);
    }
}

.dark-menu.main-body.leftmenu .sidemenu-logo {
    border-bottom-color: var(--dark-border);
    border-left-color: var(--dark-border) !important;
    border-right-color: var(--dark-border) !important;
}

.dark-menu.main-body.leftmenu .sidemenu-logo .header-brand-img.desktop-logo.theme-logo {
    display: none !important;
}

.dark-menu.main-body.leftmenu .sidemenu-logo .header-brand-img.desktop-logo {
    display: block !important;
}

.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link,
.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub .nav-sub-link:before {
    color: rgba(255, 255, 255, 0.4) !important;
}

.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub-item.active>.nav-sub-link.active {
    color: #ffffff !important;
}

.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub-item.active>.nav-sub-link.active::before {
    color: #ffffff !important;
}

@media (min-width: 992px) {
    .dark-menu.main-body.leftmenu.main-sidebar-hide .side-menu .main-logo .desktop-logo {
        display: none !important;
    }

    .dark-menu.main-body.leftmenu.main-sidebar-hide .side-menu .main-logo .icon-logo {
        display: block !important;
    }

    .dark-menu.main-body.leftmenu.main-sidebar-hide .side-menu .main-logo .icon-logo.theme-logo {
        display: none !important;
    }

    .dark-menu.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo {
        display: block !important;
    }

    .dark-menu.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo.theme-logo {
        display: none !important;
    }

    .dark-menu.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo {
        display: none !important;
    }
}

.dark-menu.main-body.leftmenu .main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub .sidemenu-label,
.dark-menu.main-body.leftmenu .main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub i,
.dark-menu.main-body.leftmenu .main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub::before {
    color: #ffffff !important;
    opacity: 1 !important;
}

.dark-menu.main-body.leftmenu .main-sidebar-body li.active li .sidemenu-label,
.dark-menu.main-body.leftmenu .main-sidebar-body li.active li i {
    color: rgba(255, 255, 255, 0.4) !important;
}

.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:hover.sub-with-sub .sidemenu-label,
.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:hover.sub-with-sub .angle,
.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:focus.sub-with-sub .sidemenu-label,
.dark-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:focus.sub-with-sub .angle {
    color: #ffffff !important;
}

.dark-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo,
.dark-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo {
    display: none !important;
}

.dark-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo.theme-logo,
.dark-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo.theme-logo {
    display: none !important;
}

.dark-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo.theme-logo,
.dark-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo.theme-logo {
    display: none !important;
}

.dark-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo,
.dark-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo {
    display: block !important;
}

@media screen and (min-width: 992px) {

    .dark-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub,
    .dark-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        background: var(--dark-theme);
        box-shadow: -6px 0px 4px 3px rgba(0, 0, 0, 0.2);
    }

    .dark-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link,
    .dark-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--dark-theme);
    }

    .dark-menu.main-body.leftmenu.hover-submenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu-label1,
    .dark-menu.main-body.leftmenu.hover-submenu1.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu-label1 {
        border-bottom-color: var(--dark-border);
    }
}

.dark-menu.horizontalmenu .main-navbar.hor-menu {
    background-color: var(--dark-theme);
    border-bottom-color: var(--dark-border);
}

.dark-menu.horizontalmenu .main-header {
    box-shadow: none;
}

.dark-menu.horizontalmenu .main-navbar .nav-link,
.dark-menu.horizontalmenu .main-navbar .nav-item:hover .nav-link.with-sub::after {
    color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 992px) {

    .dark-menu.horizontalmenu .main-navbar.hor-menu .nav-item.active .nav-link,
    .dark-menu.horizontalmenu .main-navbar.hor-menu .nav-item:hover .nav-link {
        color: #ffffff;
    }

    .dark-menu.horizontalmenu .main-navbar.hor-menu .nav-item.active .nav-link .hor-icon,
    .dark-menu.horizontalmenu .main-navbar.hor-menu .nav-item:hover .nav-link .hor-icon {
        color: #ffffff;
    }

    .dark-menu.horizontalmenu .hor-menu .nav-link .hor-icon {
        color: rgba(255, 255, 255, 0.6);
        fill: rgba(255, 255, 255, 0.6);
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub {
        background-color: var(--dark-theme);
        border-top-color: var(--dark-border);
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-link {
        color: rgba(255, 255, 255, 0.4);
    }

    .dark-menu.horizontalmenu .menu-nav .nav-item ul.nav-sub::before {
        border-top-color: var(--dark-border);
        border-left-color: var(--dark-border);
        background-color: var(--dark-theme);
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-link:hover,
    .dark-menu.horizontalmenu .main-navbar .nav-sub-link:focus {
        color: #ffffff !important;
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-link.sub-with-sub::after {
        color: rgba(255, 255, 255, 0.4);
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-link:hover.sub-with-sub::after,
    .dark-menu.horizontalmenu .main-navbar .nav-sub-link:focus.sub-with-sub::after {
        color: #ffffff !important;
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active {
        color: #ffffff;
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
        color: #ffffff;
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-link.active.sub-with-sub::after {
        color: #ffffff !important;
    }

    .dark-menu.horizontalmenu .main-navbar.hor-menu .nav-item.active .nav-link.with-sub::after {
        color: #ffffff;
    }

    .dark-menu.horizontalmenu .main-navbar .sub-nav-sub {
        background-color: var(--dark-theme);
        border-color: var(--dark-border);
    }
}

@media (max-width: 991.98px) {
    .dark-menu.horizontalmenu .main-body-1 .nav-label {
        color: rgba(255, 255, 255, 0.3);
    }

    .dark-menu.horizontalmenu .main-body-1 .nav-item.active .nav-link:before,
    .dark-menu.horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
        border-right-color: var(--dark-theme);
        border-left-color: var(--dark-theme);
    }

    .dark-menu.horizontalmenu .main-body-1 .nav-sub-link {
        color: rgba(255, 255, 255, 0.4);
    }

    .dark-menu.horizontalmenu .main-body-1 .nav-sub .nav-sub-link:before {
        color: rgba(255, 255, 255, 0.8);
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active {
        color: #ffffff;
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
        color: #ffffff;
        opacity: 1;
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-link:hover,
    .dark-menu.horizontalmenu .main-navbar .nav-sub-link:focus {
        color: #ffffff !important;
    }

    .dark-menu.horizontalmenu .main-navbar .nav-sub-link:hover:before,
    .dark-menu.horizontalmenu .main-navbar .nav-sub-link:focus:before {
        opacity: 1;
    }
}

@media (min-width: 992px) {

    .dark-menu .hor-menu .slide-left,
    .dark-menu .hor-menu .slide-right {
        color: var(--dark-color);
        border-color: var(--dark-border);
        background-color: var(--dark-theme);
    }
}

.dark-menu .hor-scroll div:nth-child(2) div {
    background-color: var(--dark-theme) !important;
}

/**********************Dark Menu End********************************/
/**********************Color Menu Start********************************/
.color-menu.main-body.leftmenu .side-menu .nav-link:hover {
    color: #ffffff;
}

.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:hover,
.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:focus {
    color: #ffffff !important;
}

.color-menu.main-body.leftmenu .main-sidebar-sticky {
    background: var(--primary-bg-color) !important;
    border-right-color: rgba(255, 255, 255, 0.2);
    border-left-color: rgba(255, 255, 255, 0.2);
}

.color-menu.main-body.leftmenu .main-sidebar-body .nav-label {
    color: rgba(255, 255, 255, 0.3);
}

.color-menu.main-body.leftmenu .main-sidebar-body .nav-link {
    color: rgba(255, 255, 255, 0.6);
}

.color-menu.main-body.leftmenu .main-sidebar-body .nav-item.active .nav-link:before,
.color-menu.main-body.leftmenu .main-sidebar-body .nav-item.active .nav-link:after {
    border-right-color: var(--primary-bg-color);
    border-left-color: var(--primary-bg-color);
}

@media (min-width: 992px) {
    .color-menu.main-body.leftmenu .sidemenu-logo {
        background: var(--primary-bg-color);
    }
}

.color-menu.main-body.leftmenu .sidemenu-logo {
    border-bottom-color: rgba(255, 255, 255, 0.2);
    border-left-color: rgba(255, 255, 255, 0.2) !important;
    border-right-color: rgba(255, 255, 255, 0.2) !important;
}

.color-menu.main-body.leftmenu .sidemenu-logo .header-brand-img.desktop-logo.theme-logo {
    display: none !important;
}

.color-menu.main-body.leftmenu .sidemenu-logo .header-brand-img.desktop-logo {
    display: block !important;
}

.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link,
.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub .nav-sub-link:before {
    color: rgba(255, 255, 255, 0.6) !important;
}

.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub-item.active>.nav-sub-link.active {
    color: #ffffff !important;
}

.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub-item.active>.nav-sub-link.active::before {
    color: #ffffff !important;
}

@media (min-width: 992px) {
    .color-menu.main-body.leftmenu.main-sidebar-hide .side-menu .main-logo .desktop-logo {
        display: none !important;
    }

    .color-menu.main-body.leftmenu.main-sidebar-hide .side-menu .main-logo .icon-logo {
        display: block !important;
    }

    .color-menu.main-body.leftmenu.main-sidebar-hide .side-menu .main-logo .icon-logo.theme-logo {
        display: none !important;
    }

    .color-menu.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo {
        display: block !important;
    }

    .color-menu.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo.theme-logo {
        display: none !important;
    }

    .color-menu.main-body.leftmenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo {
        display: none !important;
    }
}

.color-menu.main-body.leftmenu .main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub .sidemenu-label,
.color-menu.main-body.leftmenu .main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub i,
.color-menu.main-body.leftmenu .main-sidebar-body li.active li.active .nav-sub-link.sub-with-sub::before {
    color: #ffffff !important;
    opacity: 1 !important;
}

.color-menu.main-body.leftmenu .main-sidebar-body li.active li .sidemenu-label,
.color-menu.main-body.leftmenu .main-sidebar-body li.active li i {
    color: rgba(255, 255, 255, 0.6) !important;
}

.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:hover.sub-with-sub .sidemenu-label,
.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:hover.sub-with-sub .angle,
.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:focus.sub-with-sub .sidemenu-label,
.color-menu.main-body.leftmenu .main-sidebar-body .nav-sub-link:focus.sub-with-sub .angle {
    color: #ffffff !important;
}

@media (min-width: 992px) {
    .color-menu.main-body.leftmenu.icontext-menu.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
        background: rgba(0, 0, 0, 0.2);
    }

    .color-menu.main-body.leftmenu.icontext-menu.main-sidebar-hide .main-sidebar-body li.active .sidemenu-label {
        color: #ffffff;
    }

    .color-menu.main-body.leftmenu.icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
        background: var(--primary-bg-color);
    }

    .color-menu.main-body.leftmenu.icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-label {
        color: var(--primary-bg-color);
    }
}

.color-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo,
.color-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo {
    display: none !important;
}

.color-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo.theme-logo,
.color-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo.theme-logo {
    display: none !important;
}

.color-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo,
.color-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo {
    display: block !important;
}

@media screen and (min-width: 992px) {

    .color-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub,
    .color-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        background: var(--primary-bg-color);
        box-shadow: -6px 0px 4px 3px rgba(0, 0, 0, 0.2);
    }

    .color-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link,
    .color-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--primary-bg-color);
    }

    .color-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon,
    .color-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
        background: rgba(0, 0, 0, 0.2);
    }

    .color-menu.main-body.leftmenu.hover-submenu.main-sidebar-hide .main-sidebar-body li.active .sidemenu-label,
    .color-menu.main-body.leftmenu.hover-submenu1.main-sidebar-hide .main-sidebar-body li.active .sidemenu-label {
        color: #ffffff;
    }

    .color-menu.main-body.leftmenu.hover-submenu.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu-label1,
    .color-menu.main-body.leftmenu.hover-submenu1.hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu-label1 {
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }
}

.color-menu.horizontalmenu .main-navbar.hor-menu {
    background-color: var(--primary-bg-color);
    border-bottom-color: rgba(255, 255, 255, 0.2);
}

.color-menu.horizontalmenu .main-header {
    box-shadow: none;
}

.color-menu.horizontalmenu .main-navbar .nav-link,
.color-menu.horizontalmenu .main-navbar .nav-item:hover .nav-link.with-sub::after {
    color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 992px) {

    .color-menu.horizontalmenu .main-navbar.hor-menu .nav-item.active .nav-link,
    .color-menu.horizontalmenu .main-navbar.hor-menu .nav-item:hover .nav-link {
        color: #ffffff;
    }

    .color-menu.horizontalmenu .main-navbar.hor-menu .nav-item.active .nav-link .hor-icon,
    .color-menu.horizontalmenu .main-navbar.hor-menu .nav-item:hover .nav-link .hor-icon {
        color: #ffffff;
    }

    .color-menu.horizontalmenu .hor-menu .nav-link .hor-icon {
        color: rgba(255, 255, 255, 0.6);
        fill: rgba(255, 255, 255, 0.6);
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub {
        background-color: var(--primary-bg-color);
        border-top-color: rgba(255, 255, 255, 0.2);
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-link {
        color: rgba(255, 255, 255, 0.6);
    }

    .color-menu.horizontalmenu .menu-nav .nav-item ul.nav-sub::before {
        border-top-color: rgba(255, 255, 255, 0.2);
        border-left-color: rgba(255, 255, 255, 0.2);
        background-color: var(--primary-bg-color);
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-link:hover,
    .color-menu.horizontalmenu .main-navbar .nav-sub-link:focus {
        color: #ffffff !important;
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-link.sub-with-sub::after {
        color: rgba(255, 255, 255, 0.6);
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-link:hover.sub-with-sub::after,
    .color-menu.horizontalmenu .main-navbar .nav-sub-link:focus.sub-with-sub::after {
        color: #ffffff !important;
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active {
        color: #ffffff;
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
        color: #ffffff;
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-link.active.sub-with-sub::after {
        color: #ffffff !important;
    }

    .color-menu.horizontalmenu .main-navbar.hor-menu .nav-item.active .nav-link.with-sub::after {
        color: #ffffff;
    }

    .color-menu.horizontalmenu .main-navbar .sub-nav-sub {
        background-color: var(--primary-bg-color);
        border-color: rgba(255, 255, 255, 0.2);
    }
}

@media (max-width: 991.98px) {
    .color-menu.horizontalmenu .main-body-1 .nav-label {
        color: rgba(255, 255, 255, 0.3);
    }

    .color-menu.horizontalmenu .main-body-1 .nav-item.active .nav-link:before,
    .color-menu.horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
        border-right-color: var(--primary-bg-color);
        border-left-color: var(--primary-bg-color);
    }

    .color-menu.horizontalmenu .main-body-1 .nav-sub-link {
        color: rgba(255, 255, 255, 0.6);
    }

    .color-menu.horizontalmenu .main-body-1 .nav-sub .nav-sub-link:before {
        color: rgba(255, 255, 255, 0.8);
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active {
        color: #ffffff;
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
        color: #ffffff;
        opacity: 1;
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-link:hover,
    .color-menu.horizontalmenu .main-navbar .nav-sub-link:focus {
        color: #ffffff !important;
    }

    .color-menu.horizontalmenu .main-navbar .nav-sub-link:hover:before,
    .color-menu.horizontalmenu .main-navbar .nav-sub-link:focus:before {
        opacity: 1;
    }
}

.color-menu .side-menu-label1 a {
    color: #ffffff;
}

@media (min-width: 992px) {

    .color-menu .hor-menu .slide-left,
    .color-menu .hor-menu .slide-right {
        color: #ffffff;
        border-color: rgba(255, 255, 255, 0.2);
        background-color: var(--primary-bg-color);
    }
}

.color-menu .hor-scroll div:nth-child(2) div {
    background-color: var(--primary-bg-color) !important;
}

/**********************Color Menu End********************************/
/******************** Boxed Styles start********************/
@media (min-width: 1400px) {
    body.layout-boxed {
        background: #ffffff;
    }

    body.layout-boxed.dark-theme {
        background: var(--dark-theme);
    }

    .layout-boxed.dark-theme .page {
        background: var(--dark-body);
    }

    .layout-boxed .page {
        width: 85%;
        margin: 0 auto;
        background: #eaedf7;
        position: relative;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -ms-flex-pack: center;
        -webkit-box-pack: center;
        box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
    }

    .layout-boxed .main-header.side-header {
        width: 85% !important;
        margin: 0 auto;
    }

    .layout-boxed .main-header.hor-header {
        width: 100% !important;
        margin: 0 auto;
    }

    .layout-boxed .main-sidebar {
        left: auto !important;
    }

    .layout-boxed .main-sidebar-header {
        left: auto !important;
        right: auto !important;
    }

    .layout-boxed h3.number-font {
        font-size: 1.8rem;
    }

    .layout-boxed .sticky-pin .main-navbar.hor-menu {
        width: 85% !important;
    }

    .layout-boxed .hor-content .container,
    .layout-boxed .hor-header .container,
    .layout-boxed .main-navbar.hor-menu .container {
        max-width: 95%;
    }

    .layout-boxed.horizontalmenu .hor-header.sticky-pin {
        position: relative !important;
    }

    .layout-boxed.main-body .side-header {
        left: auto;
    }

    .layout-boxed.rtl .main-sidebar {
        right: auto !important;
    }

    .layout-boxed.rtl.main-body .side-header {
        right: auto;
        left: auto;
    }
}

/******************** Boxed Styles End********************/
/******************** Scrollable Styles start********************/
.scrollable-layout .main-header.side-header.sticky {
    position: absolute !important;
    width: 100% !important;
}

.scrollable-layout .main-sidebar,
.scrollable-layout.main-body.leftmenu .sidemenu-logo {
    position: absolute;
}

.scrollable-layout .page {
    position: relative;
}

.scrollable-layout.horizontalmenu .sticky.sticky-pin {
    position: absolute !important;
}

.scrollable-layout.horizontalmenu .hor-header.sticky-pin {
    position: relative !important;
}

@media (max-width: 991px) {

    .scrollable-layout.horizontalmenu .main-header.hor-header.sticky-pin,
    .scrollable-layout.horizontalmenu .main-menu.hor-menu {
        position: absolute !important;
    }
}

@media (min-width: 992px) {
    .scrollable-layout.main-sidebar-open.main-sidebar-hide .side-menu {
        top: 0;
    }

    .scrollable-layout.main-sidebar-open.main-sidebar-hide .main-sidebar-body {
        margin-top: 65px !important;
    }
}

/******************** Scrollable Styles End********************/
/* ########## LAYOUTS  ########## */
/* ###### Main-content  ###### */
.main-content.side-content {
    transition: none;
    border-radius: 0;
    padding: 0px;
    padding-bottom: 0;
    bottom: 0;
    position: relative;
    height: 100%;
}

.main-content .container,
.main-content .container-fluid {
    padding-left: 25px;
    padding-right: 25px;
}

@media (max-width: 575px) {
    .main-content-left-show {
        overflow: hidden;
    }
}

@media (max-width: 991.98px) {
    .main-content-left-show .main-content-left {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: #ffffff;
        z-index: 800;
        border-right: 1px solid #e8e8f7;
        box-shadow: 0 0 3px rgba(28, 39, 60, 0.1);
        overflow-y: auto;
    }
}

@media (max-width: 575px) {
    .main-content-left-show .main-content-body {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .main-content-body-show .main-header .main-header-menu-icon {
        display: none;
    }

    .main-content-body-show .main-header .main-header-left .main-header-arrow {
        display: block;
    }

    .main-content-body-show .main-content-left {
        display: none;
    }

    .main-content-body-show .main-content-body {
        display: block;
    }
}

@media (min-width: 992px) {
    .main-content .container {
        padding: 0;
    }
}

@media (max-width: 991.98px) {
    .main-content .container {
        max-width: none;
    }
}

.main-content-app {
    padding-top: 20px;
}

.main-content-app .container,
.main-content-app .container-fluid {
    height: 100%;
    padding: 0;
}

@media (min-width: 992px) {
    .main-content-app {
        height: calc(100vh - 250px);
        padding-top: 30px;
    }
}

.main-content-left {
    width: 100%;
    position: relative;
    padding: 0;
    flex-shrink: 0;
    display: none;
}

@media (min-width: 992px) {
    .main-content-left {
        display: block;
        padding: 0;
    }
}

.main-content-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.main-content-header .main-logo {
    flex: 1;
    text-align: center;
}

@media (min-width: 992px) {
    .main-content-header {
        display: none;
    }
}

.main-content-body {
    flex: 1;
}

.main-content-right {
    padding-left: 25px;
    margin-left: 25px;
    width: 200px;
    display: none;
}

@media (min-width: 992px) {
    .main-content-right {
        display: block;
    }
}

@media (min-width: 992px) {
    .main-content-title {
        margin-bottom: 40px;
    }
}

.main-content-label,
.card-table-two .card-title,
.card-dashboard-eight .card-title {
    color: #1d212f;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
    letter-spacing: 0.2px;
}

.main-content-label-sm {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #3c4858;
    letter-spacing: 0.5px;
}

.main-content-text {
    font-size: 12px;
    line-height: 1.4;
    display: block;
    color: #a8afc7;
}

.main-content-choose-demo {
    position: relative;
    z-index: 5;
    background-color: #0f0373;
    color: rgba(255, 255, 255, 0.5);
    padding: 0;
}

.main-content-choose-demo .container {
    display: block;
    padding: 20px;
}

.main-content-choose-demo .title-label {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
    margin-bottom: 3px;
}

.main-content-choose-demo .title {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: -0.5px;
    margin-bottom: 10px;
}

.main-content-choose-demo .title-text {
    font-weight: 300;
    margin-bottom: 20px;
}

.main-content-choose-demo .card {
    border-width: 0;
    margin-bottom: 10px;
    height: 200px;
    overflow: hidden;
    position: relative;
}

.main-content-choose-demo .card img {
    transition: all 0.2s ease-in-out;
}

.main-content-choose-demo .card figure {
    margin-bottom: 0;
}

.main-content-choose-demo .card figure:hover img,
.main-content-choose-demo .card figure:focus img {
    transform: scale(1.75, 1.75);
}

.main-content-choose-demo .card figure:hover figcaption,
.main-content-choose-demo .card figure:focus figcaption {
    opacity: 1;
}

.main-content-choose-demo .card figcaption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(25, 4, 190, 0.75);
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.main-content-choose-demo .card figcaption .btn,
.main-content-choose-demo .card figcaption .sp-container button {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    padding: 10px 20px;
    border-radius: 2px;
}

@media (min-width: 576px) {
    .main-content-choose-demo .container {
        padding: 40px;
    }
}

@media (min-width: 992px) {
    .main-content-choose-demo .container {
        padding: 100px 40px;
    }
}

@media (min-width: 1200px) {
    .main-content-choose-demo .container {
        padding: 100px 0;
    }
}

@media (min-width: 768px) {
    .main-content-choose-demo .title-label {
        font-size: 11px;
    }
}

@media (min-width: 768px) {
    .main-content-choose-demo .title {
        font-size: 30px;
    }
}

@media (min-width: 576px) {
    .main-content-choose-demo .title-text {
        margin-bottom: 40px;
    }
}

@media (min-width: 768px) {
    .main-content-choose-demo .title-text {
        font-size: 16px;
    }
}

@media (min-width: 992px) {
    .main-content-choose-demo .title-text {
        margin-bottom: 60px;
    }
}

@media (min-width: 576px) {
    .main-content-choose-demo .card {
        height: 155px;
    }
}

@media (min-width: 768px) {
    .main-content-choose-demo .card {
        height: 215px;
        margin-bottom: 15px;
    }
}

@media (min-width: 1200px) {
    .main-content-choose-demo .card {
        height: 255px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-content-choose-demo .card img {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-content-choose-demo .card figcaption {
        transition: none;
    }
}

.sp-container .main-content-choose-demo .card figcaption button {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 11px;
    font-weight: 500;
    min-height: inherit;
    padding: 10px 20px;
    border-radius: 2px;
}

.main-content-choose-demo .card.coming-soon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8f9cc0;
    background-color: #eaedf7;
}

.main-content-choose-demo .card.coming-soon .typcn {
    line-height: 0.9;
    font-size: 48px;
    margin-bottom: 10px;
}

.main-content-choose-demo .card.coming-soon h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
}

.main-content-choose-demo .main-content-label,
.main-content-choose-demo .card-table-two .card-title {
    color: #a8afc7;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.card-table-two .main-content-choose-demo .card-title,
.main-content-choose-demo .card-dashboard-eight .card-title,
.card-dashboard-eight .main-content-choose-demo .card-title {
    color: #a8afc7;
    margin-bottom: 5px;
    letter-spacing: 1px;
}

.main-content-choose-demo .main-content-title {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    color: #ffffff;
}

@media (min-width: 768px) {
    .main-content-choose-demo .main-content-title {
        font-size: 17px;
    }
}

.main-sidebar-body .nav-header ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
}

/* ###### Main-content  ###### */
/* ###### Main-header  ###### */
@media (max-width: 991.98px) {
    .main-header-menu-show {
        overflow: hidden;
    }

    .main-header-menu-show .main-header-menu {
        border-right: 1px solid rgba(28, 39, 60, 0.08);
        box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
        transform: translateX(0);
    }

    .main-header-menu-show .main-navbar-backdrop {
        visibility: visible;
        opacity: 1;
    }
}

.main-header {
    height: 60px;
    background-color: #ffffff;
    box-shadow: none;
    border-bottom: 1px solid #e8e8f7;
    z-index: 99;
    width: 100%;
    box-shadow: -7.829px 11.607px 20px 0px rgb(228, 227, 245);
}

.main-header>.container,
.main-header>.container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 992px) {
    .main-body .main-header {
        position: fixed;
        height: 65px;
    }

    .main-header {
        position: relative;
        height: 65px;
    }
}

@media (min-width: 992px) {

    .main-header>.container,
    .main-header>.container-fluid {
        padding-left: 25px;
        padding-right: 15px;
    }
}

@media (min-width: 992px) {
    .main-header>.container {
        padding: 0 !important;
    }
}

@media (max-width: 991.98px) {
    .main-header>.container {
        max-width: none;
    }
}

@media (min-width: 992px) {
    .main-header .main-header-left .main-logo {
        display: flex;
    }
}

.main-header-left {
    display: flex;
    align-items: center;
}

.main-header-left .main-header-arrow {
    display: none;
    position: relative;
    top: -2px;
}

.main-logo {
    font-weight: 700;
    font-size: 28px;
    font-family: "Poppins", sans-serif;
    text-transform: lowercase;
    letter-spacing: -1px;
    display: flex;
    align-items: left;
    position: relative;
    margin: auto;
    justify-content: left;
}

.main-logo i {
    display: block;
    line-height: 0.9;
    margin-right: 8px;
    font-size: 40px;
}

.main-logo i::before {
    width: auto;
    height: auto;
}

.main-header-menu-icon {
    margin-right: 10px;
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
}

.main-header-menu-icon span {
    display: block;
    position: relative;
    width: 15px;
    height: 2px;
    background-color: #2f395f;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

.main-header-menu-icon span::before,
.main-header-menu-icon span::after {
    content: "";
    position: absolute;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: #2f395f;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

.main-header-menu-icon span::before {
    top: -6px;
}

.main-header-menu-icon span::after {
    bottom: -6px;
}

@media (min-width: 992px) {
    .main-header-menu-icon {
        margin-right: 25px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-header-menu-icon span {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {

    .main-header-menu-icon span::before,
    .main-header-menu-icon span::after {
        transition: none;
    }
}

.main-header-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 240px;
    background-color: #ffffff;
    overflow-y: auto;
    z-index: 1000;
    transform: translateX(-240px);
    transition: all 0.35s;
}

.main-header-menu>.nav {
    flex-direction: column;
    padding: 20px;
}

.main-header-menu .nav-item::before {
    content: "";
    position: absolute;
    top: 67px;
    margin-left: 25px;
    width: 15px;
    height: 15px;
    border-top: 2px solid rgba(28, 39, 60, 0.12);
    border-left: 2px solid rgba(28, 39, 60, 0.12);
    background-color: #ffffff;
    transform: rotate(45deg);
    z-index: 100;
    display: none;
}

.main-header-menu .nav-item+.nav-item {
    margin-top: 10px;
}

.main-header-menu .nav-item>.nav-link {
    display: flex;
    align-items: center;
    position: relative;
    color: #8f9cc0;
    font-size: 15px;
    font-weight: 500;
    padding: 0;
    transition: all 0.2s ease-in-out;
}

.main-header-menu .nav-item>.nav-link.with-sub::after {
    content: "\f3d0";
    display: inline-block;
    font-family: "Ionicons";
    font-size: 12px;
    font-weight: 400;
    margin-left: auto;
    margin-top: 3px;
    opacity: 0.5;
}

.main-header-menu .nav-item>.nav-link .typcn {
    font-size: 20px;
    line-height: 0.95;
    margin-right: 15px;
    width: 16px;
}

.main-header-menu .nav-item>.nav-link .typcn::before {
    width: auto;
}

.main-header-menu .nav-item.active>.nav-link {
    position: relative;
}

.main-header-menu .nav-item.active>.nav-link::before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: -20px;
    width: 2px;
    border-radius: 3px;
}

.main-header-menu .nav-item.show .main-menu-sub {
    display: block;
}

@media (prefers-reduced-motion: reduce) {
    .main-header-menu {
        transition: none;
    }
}

@media (min-width: 992px) {
    .main-header-menu {
        background-color: transparent;
        border-right: 0;
        position: static;
        width: auto;
        overflow-y: visible;
        transform: none;
        z-index: auto;
    }
}

@media (min-width: 992px) {
    .main-header-menu>.nav {
        padding: 0;
        flex-direction: row;
        align-items: center;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item+.nav-item {
        margin-top: 0;
        margin-left: 30px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-header-menu .nav-item>.nav-link {
        transition: none;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item>.nav-link {
        font-size: 0.875rem;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item>.nav-link.with-sub::after {
        margin-left: 5px;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item>.nav-link .typcn {
        font-size: 18px;
        margin-right: 7px;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item.active>.nav-link::before {
        top: auto;
        bottom: -22px;
        left: 0;
        right: 0;
        width: auto;
        height: 2px;
    }
}

@media (min-width: 992px) {
    .main-header-menu .nav-item.show::before {
        display: block;
    }
}

.main-header-menu-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid rgba(28, 39, 60, 0.05);
}

.main-header-menu-header .close {
    font-weight: 300;
    font-size: 28px;
}

@media (min-width: 992px) {
    .main-header-menu-header {
        display: none;
    }
}

.main-header-center {
    flex: 1;
    margin: 0 0 0 0.8rem;
    position: relative;
}

.main-header-center .search-panel .dropdown-toggle {
    border-radius: 6px 0 0 6px;
    background: #eaebfa;
    border: 1px solid #e6eaff;
    height: 40px;
    color: #828aa5;
}

.main-header-center .search-panel .btn:hover .dropdown-toggle {
    color: #828aa5;
}

.main-header-center .search-panel .dropdown-menu.show {
    display: block;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

.main-header-center .search-btn {
    padding: 0.375rem 0.85rem 0.4rem 0.7rem;
    border-radius: 0 6px 6px 0;
    color: #ffffff;
}

.main-header-center .search-btn:hover i,
.main-header-center .search-btn:focus i {
    color: #ffffff;
}

.main-header-center .search-btn:hover,
.main-header-center .search-btn:focus {
    background-color: #4140a9;
}

.main-header-center .form-control::focus {
    background: #ffffff !important;
    border: 1px solid #e8e8f7;
}

.main-header-center .select2-container--default .select2-selection--single {
    border-radius: 6px 0 0 6px;
    height: 40px;
    width: 150px;
}

.main-header-center .form-control {
    transition: none;
    height: 40px;
    border-radius: 0;
    width: auto;
    flex: inherit;
}

.main-header-center .form-control:focus {
    background-color: var(--primary01);
    border-color: #e8e8f7;
    box-shadow: none !important;
}

.main-header-center .form-control:focus+.btn {
    color: #ffffff;
}

.main-header-center .sp-container .form-control:focus+button {
    color: #a8afc7;
}

@media (min-width: 992px) and (max-width: 1172px) {
    .main-header-center .form-control {
        width: 75px !important;
        padding: 0.175rem 0.75rem 0.175rem 0.75rem;
    }
}

@media (min-width: 1173px) {
    .main-header-center .form-control {
        width: 257px;
    }
}

.sp-container .main-header-center .form-control:focus+button {
    color: #a8afc7;
}

.main-header-center .btn:hover,
.main-header-center .sp-container button:hover {
    color: #ffffff;
}

.sp-container .main-header-center button:hover {
    color: #ffffff;
}

.main-header-center .btn:focus,
.main-header-center .sp-container button:focus {
    color: #ffffff;
}

.sp-container .main-header-center button:focus {
    color: #ffffff;
}

.sp-container .main-header-center button:hover i {
    color: #b1bad6;
}

.main-header-center .btn:focus i,
.main-header-center .sp-container button:focus i {
    color: #b1bad6;
}

.sp-container .main-header-center button:focus i {
    color: #b1bad6;
}

.main-header-right {
    display: flex;
    align-items: center;
}

.main-header-right .btn-social {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a8afc7;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

.main-header-right .btn-social:hover,
.main-header-right .btn-social:focus {
    color: #3c4858;
}

.main-header-right .btn-social i {
    font-size: 18px;
    line-height: 0;
}

.main-header-right .btn-social+.btn-social {
    margin-left: 10px;
}

.main-header-right .btn-buy {
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 11px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
    margin-left: 20px;
}

@media (prefers-reduced-motion: reduce) {
    .main-header-right .btn-social {
        transition: none;
    }
}

.header-icons {
    height: 20px;
    width: 20px;
    line-height: 20px;
}

.country-Flag svg {
    padding: 0;
    min-width: 0;
    width: 21px;
    height: 21px;
    flex-shrink: 0;
    line-height: 24px;
    border-radius: 50%;
}

@media (prefers-reduced-motion: reduce) {
    .main-header-right .btn-buy {
        transition: none;
    }
}

.main-header-search-link {
    font-size: 18px;
    color: #3c4858;
    line-height: 1;
    margin-right: 15px;
}

.main-header-arrow {
    font-size: 27px;
    color: #8f9cc0;
    display: inline-block;
    line-height: 0;
}

.main-header-arrow i {
    line-height: 0;
}

.main-header-arrow:hover,
.main-header-arrow:focus {
    color: #3c4858;
}

.main-header-message,
.main-header-notification {
    position: relative;
}

.main-header-message>a,
.main-header-notification>a {
    display: block;
    font-size: 20px;
    color: #8f9cc0;
    position: relative;
    line-height: 0.9;
    outline: none;
}

.main-header-message>a.new::before {
    content: "";
    position: absolute;
    top: -2px;
    right: 2px;
    width: 7px;
    height: 7px;
    background-color: #f16d75;
    border-radius: 100%;
}

.main-header-notification>a.new::before {
    content: "";
    position: absolute;
    top: -2px;
    right: 2px;
    width: 7px;
    height: 7px;
    background-color: #f16d75;
    border-radius: 100%;
}

.main-header-notification .dropdown-menu {
    width: 300px;
    position: absolute;
    top: 48px;
    left: auto;
    right: -10px;
    bottom: auto;
    padding: 0;
    border-width: 1px;
    box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3);
    border-radius: 4px;
}

@media (min-width: 992px) {

    .main-header-notification>a::after,
    .main-header-message>a::after {
        bottom: -36px;
    }
}

.main-header-message .dropdown-menu {
    width: 300px;
    position: absolute;
    top: 48px !important;
    left: auto !important;
    right: -10px;
    bottom: auto;
    padding: 0;
    border-color: #eaedf7;
    border-width: 1px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

.main-header-notification .dropdown-footer {
    text-align: center;
    padding: 10px;
    font-size: 13px;
    border-top: 1px solid rgba(123, 65, 216, 0.1);
}

.main-header-notification.show>a::after,
.main-header-notification.show .dropdown-menu {
    display: block;
}

.main-header-message.show>a::after,
.main-header-message.show .dropdown-menu {
    display: block;
}

.main-header-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.main-header-profile .main-img-user {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
}

.main-header-profile .main-img-user::after {
    display: none;
}

.main-header-profile h6 {
    font-size: 24px;
    font-weight: 700;
    color: #8f9cc0;
    margin-bottom: 0;
}

.main-header-profile span {
    display: block;
    font-size: 13px;
}

@media (min-width: 576px) {
    .main-header-profile .main-img-user {
        width: 80px;
        height: 80px;
    }
}

@media (min-width: 576px) {
    .main-header-profile h6 {
        font-size: 18px;
    }
}

.main-header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

/* ###### Main-header  ###### */
/* ###### Main-header-nav  ###### */
.main-menu-sub {
    font-size: 13px;
    margin-left: 32px;
    margin-top: 12px;
    display: none;
}

.main-menu-sub .nav {
    flex-direction: column;
}

.main-menu-sub .nav-link {
    padding: 0;
    display: block;
    color: #3c4858;
}

.main-menu-sub .nav-link+.nav-link {
    margin-top: 6px;
    padding-top: 6px;
    border-top: 1px dotted #eaedf7;
}

.main-menu-sub .nav-link.active {
    font-weight: 500;
}

@media (min-width: 992px) {
    .main-menu-sub {
        position: absolute;
        top: 74px;
        background-color: #ffffff;
        padding: 15px 20px;
        border: 2px solid rgba(28, 39, 60, 0.12);
        min-width: 200px;
        margin-left: 0;
        margin-top: 0;
    }
}

@media (min-width: 992px) {
    .main-menu-sub .nav-link.active {
        font-weight: 400;
    }
}

.main-menu-sub-mega {
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 0;
    border: 0;
}

.main-menu-sub-mega .container {
    padding: 0;
}

.main-menu-sub-mega .container>div+div {
    margin-top: 25px;
}

.main-menu-sub-mega .nav {
    min-width: 120px;
    flex-direction: column;
}

.main-menu-sub-mega .nav+.nav {
    border-top: 1px dotted #eaedf7;
    margin-top: 6px;
    padding-top: 6px;
}

.main-menu-sub-mega .mega-subtitle {
    display: block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #8f9cc0;
    letter-spacing: 0.5px;
    margin-bottom: 15px;
    margin-top: 30px;
}

.main-menu-sub-mega .nav>span:first-of-type {
    margin-top: 0;
}

@media (max-width: 991.98px) {
    .main-menu-sub-mega .container {
        max-width: none;
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .container {
        display: flex;
        background-color: #ffffff;
        border: 2px solid rgba(28, 39, 60, 0.12);
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .container>div {
        display: flex;
        padding: 20px;
        flex: 1;
        margin-top: 0;
    }
}

@media (min-width: 1200px) {
    .main-menu-sub-mega .container>div {
        padding: 20px 25px;
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .container>div+div {
        margin-top: 0;
        border-left: 1px solid rgba(28, 39, 60, 0.05);
    }
}

@media (min-width: 1200px) {
    .main-menu-sub-mega .nav {
        min-width: 140px;
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .nav+.nav {
        margin-left: 20px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0;
    }
}

@media (min-width: 1200px) {
    .main-menu-sub-mega .nav+.nav {
        margin-left: 25px;
    }
}

@media (min-width: 992px) {
    .main-menu-sub-mega .nav>span {
        font-size: 12px;
    }
}

.header-brand-img {
    line-height: 2.5rem;
    vertical-align: middle;
    width: auto;
}

.header-navheading {
    padding: 20px 20px 15px;
    text-align: center;
}

.header-brand-img.icon-logo {
    display: none;
}

.main-header-right .nav-link.icon {
    font-size: 20px;
    color: #4a4d51;
    cursor: pointer;
    line-height: 0.9;
    padding: 0.6rem 0.6rem;
    border-radius: 50%;
    margin: 0 5px;
}

.main-header-right .nav-link.icon:hover {
    background: #f0f1ff;
}

@keyframes fadein {
    from {
        opacity: 0;
        transform: translate3d(0, 20px, 0);
    }

    to {
        opacity: 1;
        transform: none;
        transform: translate3d(0, 0, 0);
    }
}

.fadein,
.main-header-notification.show .dropdown-menu,
.main-header-message.show .dropdown-menu,
.main-profile-menu.show .dropdown-menu,
.header-search.show .dropdown-menu {
    animation-name: fadein;
    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -ms-animation-duration: 0.2s;
    -o-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

.main-header-notification.show .dropdown-menu::before,
.main-header-message.show .dropdown-menu::before,
.main-profile-menu.show .dropdown-menu::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #ffffff;
    position: absolute;
    top: -7px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #f0f2f8;
    border-left: 1px solid #f0f2f8;
}

.header-search.show .dropdown-menu {
    display: block;
    margin: 0;
}

.header-search.show .dropdown-menu::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #ffffff;
    position: absolute;
    top: -7px;
    right: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #f0f2f8;
    border-left: 1px solid #f0f2f8;
}

.header-search .dropdown-menu {
    width: 400px;
    position: absolute;
    top: 50px;
    left: auto;
    right: -10px;
    bottom: auto;
    padding: 0;
    border-color: #f0f2f8;
    border-width: 1px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

.main-form-search {
    flex: 1;
    width: 300px;
    position: relative;
}

.main-form-search .form-control {
    border-color: #f0f2f8;
    border-width: 1px;
    background-color: #ffffff;
    transition: none;
    height: 40px;
    padding-left: 2.6rem;
    border-radius: 3px;
}

.main-form-search .form-control:focus {
    background-color: #ffffff;
    border-color: #e8e8f7;
    box-shadow: none !important;
}

.main-form-search .form-control:focus+.btn {
    color: #a8afc7;
}

.main-form-search .sp-container .form-control:focus+button {
    color: #a8afc7;
}

.sp-container .main-form-search .form-control:focus+button {
    color: #a8afc7;
}

.main-form-search .btn,
.main-form-search .sp-container button {
    position: absolute;
    top: 8px;
    left: 8px;
    height: 40px;
    color: #e8e8f7;
    transition: none;
    font-size: 16px;
    padding-left: 16px;
}

.sp-container .main-form-search button {
    position: absolute;
    top: 8px;
    left: 8px;
    background-color: transparent;
    height: 40px;
    color: #e8e8f7;
    transition: none;
    font-size: 16px;
    padding-left: 16px;
}

.main-form-search .btn:hover,
.main-form-search .sp-container button:hover {
    color: #8f9cc0;
}

.sp-container .main-form-search button:hover {
    color: #8f9cc0;
}

.main-form-search .btn:focus,
.main-form-search .sp-container button:focus {
    color: #8f9cc0;
}

.sp-container .main-form-search button:focus {
    color: #8f9cc0;
}

.theme-logo {
    display: none !important;
}

.main-notification-text .badge {
    line-height: 1.5;
}

.main-message-list .media {
    padding: 10px 10px 0px 15px;
}

.main-header-message .dropdown-footer {
    text-align: center;
    padding: 10px;
    font-size: 13px;
    border-top: 1px solid rgba(123, 65, 216, 0.1);
}

.main-header-flags {
    position: relative;
}

.fadein {
    animation-name: fadein;
    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -ms-animation-duration: 0.2s;
    -o-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}

.main-header-flags.show .dropdown-menu {
    animation-name: fadein;
    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -ms-animation-duration: 0.2s;
    -o-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    display: block;
}

.main-header-flags .dropdown-menu {
    width: 200px;
    position: absolute;
    top: 48px;
    right: auto;
    left: -10px;
    bottom: auto;
    padding: 0;
    border-color: #f0f2f8;
    border-width: 1px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

.main-header-flags.show .dropdown-menu::before {
    content: "";
    width: 13px;
    height: 13px;
    background: #ffffff;
    position: absolute;
    top: -7px;
    left: 28px;
    transform: rotate(45deg);
    border-top: 1px solid #f0f2f8;
    border-left: 1px solid #f0f2f8;
}

.main-flags-list .media {
    padding: 10px 10px 0px 15px;
}

.main-header-flags .dropdown-footer {
    text-align: center;
    padding: 10px;
    font-size: 13px;
    border-top: 1px solid rgba(123, 65, 216, 0.1);
}

.header-flag,
.main-flags-list img {
    width: 1.7rem;
}

.hor-header.hor-top-header .nav-link-badge {
    position: absolute;
    top: 0px;
    left: 11px;
    display: block !important;
    padding: 3px 5px !important;
    border-radius: 50px;
    font-size: 10px;
}

.hor-header.hor-top-header .main-message-list p {
    font-size: 12px;
}

@media (max-width: 991px) {

    .main-header-message,
    .main-header-flags {
        display: none;
    }

    .hor-header.hor-top-header .main-logo {
        left: 0px;
        margin-right: auto;
    }
}

.main-header-project {
    position: relative;
}

.main-header-right .nav-link .badge {
    position: absolute;
    top: 3px;
    right: 4px;
    padding: 0.2rem 0.2rem;
    min-width: 1rem;
    font-size: 9px;
    border-radius: 50%;
}

.main-header-center .select2-container {
    z-index: 9999;
}

.main-header-center .btn {
    border-radius: 0 6px 6px 0 !important;
}

.main-content-left-chat .btn {
    border-radius: 0 4px 4px 0 !important;
}

@media (min-width: 992px) {
    .main-header.hor-header.top-header .main-header-left .main-logo {
        display: none;
    }
}

@media (max-width: 992px) {
    .header-brand2 {
        display: none;
    }
}

/* ###### Main-header-nav  ###### */
/* ###### Main-footer  ###### */
.main-footer {
    background-color: #ffffff;
    margin-top: 0;
    padding: 15px;
    position: relative;
    bottom: 0;
    display: block;
    margin-top: auto;
}

.main-footer .container {
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
}

.main-footer .container-fluid {
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 992px) {
    .main-footer {
        display: block;
    }
}

.main-footer-app .container,
.main-footer-app .container-fluid {
    border-top-width: 0;
}

.main-footer-demo {
    background-color: var(--primary-bg-color);
    color: rgba(255, 255, 255, 0.4);
    height: 60px;
}

.main-footer-demo .container {
    border-top-width: 0;
}

.main-footer-demo a {
    color: rgba(255, 255, 255, 0.5);
}

.main-footer-demo a:hover,
.main-footer-demo a:focus {
    color: #ffffff;
}

/* ###### Main-footer  ###### */
/* ###### Right-sidebar  ###### */
[data-toggle=sidebar] {
    display: block;
}

.sidebar {
    position: fixed;
    display: block;
    top: 0;
    width: 300px;
    bottom: 0;
    z-index: 9999;
    min-height: 100%;
    max-height: none;
    overflow: auto;
    background-color: #ffffff;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

.sidebar-left {
    left: 0;
}

.sidebar-right,
.sidebar-profile {
    right: 0;
}

.row .sidebar.sidebar-left.sidebar-xs-show {
    left: 0;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
}

.row .sidebar.sidebar-right.sidebar-xs-show,
.row .sidebar.sidebar-profile.sidebar-xs-show {
    right: 0;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
}

@media (min-width: 768px) {
    .row .sidebar.sidebar-left.sidebar-sm-show {
        left: 0;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .row .sidebar.sidebar-right.sidebar-sm-show,
    .row .sidebar.sidebar-profile.sidebar-sm-show {
        right: 0;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@media (min-width: 992px) {
    .row .sidebar.sidebar-left.sidebar-md-show {
        left: 0;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .row .sidebar.sidebar-right.sidebar-md-show,
    .row .sidebar.sidebar-profile.sidebar-md-show {
        right: 0;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@media (min-width: 1170px) {
    .row .sidebar.sidebar-left.sidebar-lg-show {
        left: 0;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .row .sidebar.sidebar-right.sidebar-lg-show,
    .row .sidebar.sidebar-profile.sidebar-lg-show {
        right: 0;
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

.sidebar-animate {
    -webkit-transition: -webkit-transform 300ms ease;
    -moz-transition: -moz-transform 300ms ease;
    -o-transition: transform 300ms ease;
    transition: transform 300ms ease;
}

.sidebar.sidebar-left {
    -webkit-transform: translate(-100%, 0);
    -moz-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
}

.sidebar.sidebar-left.sidebar-open {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}

.sidebar.sidebar-right,
.sidebar.sidebar-profile {
    -webkit-transform: translate(100%, 0);
    -moz-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    transform: translate(100%, 0);
}

.sidebar.sidebar-right.sidebar-open,
.sidebar.sidebar-profile.sidebar-open {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0) !important;
}

.sidebar-body h5 {
    padding: 13px 22px;
    font-size: 14px;
    border-bottom: 1px solid #f0f2f8;
    background: #eaedf7;
}

.sidebar-icon {
    position: absolute;
    right: 25px;
    top: 7px;
}

.side-header .main-profile-menu .dropdown-menu {
    top: 49px;
}

.side-header .header-search .dropdown-menu {
    top: 50px;
}

/* ###### Right-sidebar  ###### */
/* ###### Main-profile  ###### */
.main-profile-menu {
    position: relative;
    margin: 0 4px;
}

.main-profile-menu>.main-img-user {
    outline: none;
    width: 32px;
    height: 32px;
}

.main-profile-menu>.main-img-user::after {
    display: none;
}

.main-profile-menu .dropdown-menu {
    padding-top: 0px;
    position: absolute;
    top: 53px;
    left: auto;
    right: -10px;
    bottom: auto;
    width: 230px;
    border-width: 1px;
    box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.3);
}

.main-profile-menu .dropdown-item {
    position: relative;
    padding: 10px;
    height: 38px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #3c4858;
}

.main-profile-menu .dropdown-item i {
    font-size: 16px;
    margin-right: 10px;
    width: 24px;
    text-align: center;
    line-height: 0.9;
}

.main-profile-menu .dropdown-item:hover,
.main-profile-menu .dropdown-item:focus {
    background-color: #f1f2f9;
}

.main-profile-menu .dropdown-item+.dropdown-item {
    border-top: 1px solid #e8e8f7;
}

.main-profile-menu.show .main-img-user::before,
.main-profile-menu.show .dropdown-menu {
    display: block;
}

.main-profile-menu .border-top {
    border-top: 1px solid #f0f2f8 !important;
}

/* ###### Main-profile  ###### */
/* ###### Iconbar  ###### */
@media (max-width: 991.98px) {
    .main-iconbar-show .main-iconbar {
        display: flex;
    }

    .main-iconbar-show .main-iconbar-aside {
        display: block;
    }
}

.main-iconbar {
    width: 64px;
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    background-color: #ffffff;
    position: fixed;
    top: 63px;
    left: 0;
    height: 100%;
    background-color: #ffffff;
    z-index: 500;
    border-right: 1px solid #e8e8f7;
    border-top: 1px solid #e8e8f7;
}

.main-iconbar .nav {
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 5px 0;
}

.main-iconbar .nav-link {
    position: relative;
    padding: 0;
    color: #8f9cc0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-iconbar .nav-link i {
    font-size: 20px;
}

.main-iconbar .nav-link i.typcn {
    line-height: 1;
}

.main-iconbar .nav-link i.typcn::before {
    width: auto;
}

.main-iconbar .nav-link i.typcn-device-laptop,
.main-iconbar .nav-link i.typcn-th-large-outline {
    font-size: 25px;
}

.main-iconbar .nav-link i.fa-desktop {
    font-size: 23px;
}

.main-iconbar .nav-link:hover,
.main-iconbar .nav-link:focus {
    color: #3c4858;
}

.main-iconbar .nav-link+.nav-link {
    margin-top: 10px;
}

@media (min-width: 992px) {
    .main-iconbar {
        display: flex;
    }
}

.main-iconbar-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    line-height: 0.9;
    color: #8f9cc0;
    flex-shrink: 0;
    margin-bottom: 20px;
    position: relative;
}

.main-iconbar-logo::after {
    content: "";
    position: absolute;
    bottom: 6px;
    left: 20px;
    height: 3px;
    width: 24px;
    border-radius: 2px;
}

.main-iconbar-logo:hover,
.main-iconbar-logo:focus {
    color: #8f9cc0;
}

.main-iconbar-bottom {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-iconbar-help {
    line-height: 0;
    font-size: 22px;
    color: #a8afc7;
    margin-bottom: 20px;
}

.main-iconbar-help:hover,
.main-iconbar-help:focus {
    color: #8f9cc0;
}

.main-iconbar-aside {
    width: 230px;
    height: 100%;
    background-color: #ffffff;
    border-right: 1px solid #e8e8f7;
    border-top: 1px solid #e8e8f7;
    position: fixed;
    top: 63px;
    left: 64px;
    overflow-y: auto;
    z-index: 600;
    display: none;
}

@media (min-width: 1200px) {
    .main-iconbar-aside {
        border-right: 0;
        z-index: 500;
    }
}

@media (min-width: 992px) {
    .main-iconbar-aside.show {
        display: block;
    }
}

@media (min-width: 1200px) {
    .main-iconbar-aside.show+.main-content {
        margin-left: 294px;
    }
}

.main-iconbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    padding-left: 0;
}

.main-iconbar-header .main-logo {
    line-height: 1;
}

.main-iconbar-toggle-menu {
    display: block;
    position: relative;
    line-height: 0;
    color: #8f9cc0;
    font-size: 28px;
    opacity: 0.5;
}

.main-iconbar-toggle-menu:hover,
.main-iconbar-toggle-menu:focus {
    opacity: 1;
    color: #8f9cc0;
}

.main-iconbar-toggle-menu i:first-child {
    display: none;
}

@media (min-width: 1200px) {
    .main-iconbar-toggle-menu {
        font-size: 24px;
    }

    .main-iconbar-toggle-menu::before {
        content: "";
        position: absolute;
        top: 3px;
        bottom: 3px;
        left: -1px;
        border-left: 2px solid #000000;
    }

    .main-iconbar-toggle-menu i:first-child {
        display: block;
    }

    .main-iconbar-toggle-menu i:last-child {
        display: none;
    }
}

.main-iconbar-body {
    padding: 20px;
    height: calc(100vh - 68px);
}

.main-iconbar-body .nav {
    flex-direction: column;
    padding-top: 10px;
}

.main-iconbar-body .nav-item {
    position: relative;
    display: block;
}

.main-iconbar-body .nav-item+.nav-item {
    margin-top: -1px;
    border-top: 1px dotted #eaedf7;
}

.main-iconbar-body .nav-item.show .nav-sub {
    display: block;
}

.main-iconbar-body .nav-link {
    position: relative;
    color: #8f9cc0;
    font-size: 13px;
    padding: 0;
    display: flex;
    align-items: center;
    height: 38px;
}

.main-iconbar-body .nav-link i {
    color: #a8afc7;
    font-size: 16px;
    font-weight: 400;
    line-height: 0;
    margin-right: 10px;
}

.main-iconbar-body .nav-link i.typcn {
    line-height: 0.9;
}

.main-iconbar-body .nav-link i.typcn::before {
    width: auto;
}

.main-iconbar-body .nav-link.with-sub {
    justify-content: space-between;
}

.main-iconbar-body .nav-link.with-sub::after {
    content: "\f3d0";
    font-family: "Ionicons";
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #a8afc7;
}

.main-iconbar-body .nav-sub {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px dotted #eaedf7;
    display: none;
}

.main-iconbar-body .nav-sub .nav-sub-item {
    position: relative;
    display: block;
    margin-left: 15px;
}

.main-iconbar-body .nav-sub .nav-sub-item+.nav-sub-item {
    border-top: 1px dotted #eaedf7;
}

.main-iconbar-body .nav-sub .nav-sub-link {
    display: flex;
    align-items: center;
    height: 36px;
    color: #8f9cc0;
}

@media (min-width: 992px) {
    .main-iconbar-body .nav-link.with-sub::after {
        margin-left: 4px;
        font-size: 12px;
    }
}

.main-iconbar-title {
    font-size: 20px;
    font-weight: 700;
    color: #8f9cc0;
    margin-bottom: 10px;
    line-height: 1;
}

.main-iconbar-text {
    display: block;
    font-size: 12px;
    color: #a8afc7;
    margin-bottom: 12px;
}

.main-iconbar-pane {
    margin-bottom: 20px;
    display: none;
}

.main-iconbar-pane.show {
    display: block;
}

.main-iconbar-primary {
    padding-top: 0;
}

.main-iconbar-primary .main-img-user::after {
    box-shadow: none;
}

.main-iconbar-primary .main-iconbar-logo {
    color: #ffffff;
    height: 64px;
}

.main-iconbar-primary .main-iconbar-logo::after {
    display: none;
}

.main-iconbar-primary .nav {
    justify-content: center;
}

.main-iconbar-primary .nav-link {
    width: 42px;
    height: 42px;
    color: rgba(255, 255, 255, 0.5);
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
}

.main-iconbar-primary .nav-link:hover,
.main-iconbar-primary .nav-link:focus {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
}

.main-iconbar-primary .nav-link.active {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
}

.main-iconbar-primary .main-iconbar-help {
    color: rgba(255, 255, 255, 0.5);
}

.main-iconbar-primary .main-iconbar-help:hover,
.main-iconbar-primary .main-iconbar-help:focus {
    color: #ffffff;
}

@media (prefers-reduced-motion: reduce) {
    .main-iconbar-primary .nav-link {
        transition: none;
    }
}

.main-iconbar-aside-primary {
    width: 250px;
    background-color: #f1f2f9;
    box-shadow: 0 0 16px rgba(28, 39, 60, 0.08);
    border-right: 1px solid #e8e8f7;
}

.main-iconbar-aside-primary .main-iconbar-header {
    padding: 18px 25px;
    background-color: #ffffff;
    border-bottom: 1px solid #e8e8f7;
}

.main-iconbar-aside-primary .main-iconbar-body {
    padding: 20px 25px;
}

.main-iconbar-aside-primary .main-iconbar-body .nav {
    padding-top: 0;
    padding-left: 0;
    border-left-width: 0;
}

.main-iconbar-aside-primary .main-iconbar-body .nav-item+.nav-item,
.main-iconbar-aside-primary .main-iconbar-body .nav-sub,
.main-iconbar-aside-primary .main-iconbar-body .nav-sub-item+.nav-sub-item {
    border-top-color: #eaedf7;
}

.main-iconbar-aside-primary .main-iconbar-toggle-menu {
    display: none;
}

@media (min-width: 992px) {
    .main-iconbar-aside-primary.show+.main-content {
        margin-left: 64px;
    }
}

.main-icon-group {
    text-align: center;
    padding: 20px;
    color: #3b4863;
}

.main-icon-group i {
    line-height: 1;
    font-size: 24px;
}

@media (min-width: 992px) {
    .main-icon-group i {
        font-size: 18px;
    }
}

.icons-list {
    list-style: none;
    margin: 0 -1px -1px 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.icons-list-item {
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.4rem;
    font-size: 1.25rem;
    height: 55px;
    width: 55px;
    background: #f6f6ff;
    margin: 5px 5px;
    border-radius: 6px;
    border: 1px solid #e4e4f9;
}

.icons-list .flag-icon {
    border-radius: 0;
}

/* ###### Iconbar  ###### */
/***Start Horizontal Hover Styles ***/
@media (min-width: 992px) {
    .horizontalmenu-hover .menu-nav .nav-item:hover ul.nav-sub {
        display: block !important;
    }

    .horizontalmenu-hover .menu-nav .nav-sub .nav-sub-link.with-sub .angle,
    .horizontalmenu-hover .menu-nav .nav-sub .nav-sub-link.sub-with-sub .angle {
        display: none !important;
    }

    .horizontalmenu-hover .menu-nav .nav-sub .nav-sub-item:hover .sub-nav-sub {
        display: block !important;
    }

    .horizontalmenu-hover .menu-nav .sub-nav-sub .sub-nav-sub {
        display: none !important;
    }

    .horizontalmenu-hover .menu-nav .sub-nav-sub .nav-sub-item:hover .sub-nav-sub {
        display: block !important;
    }

    .horizontalmenu-hover .menu-nav .nav-item.active .nav-sub {
        display: none !important;
    }
}

/***End Horizontal Hover Styles ***/
/***Start Horizontal Styles ***/
@media (min-width: 992px) {
    .horizontalmenu .sidemenu-logo {
        display: none;
    }

    .horizontalmenu .hor-menu .nav-item .nav-link .side-badge {
        display: none;
    }

    .horizontalmenu .hor-menu .nav {
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    .horizontalmenu .hor-menu .nav .sidemenu-label {
        display: flex;
    }

    .horizontalmenu .hor-menu .nav .nav-header {
        display: none;
    }

    .horizontalmenu .main-body-1 .nav-item.active .nav-link .shape1,
    .horizontalmenu .main-body-1 .nav-item.active .nav-link .shape2 {
        display: none;
    }

    .horizontalmenu .main-navbar .nav-item.show .nav-link::before {
        display: none;
    }

    .horizontalmenu .main-navbar.hor-menu .container,
    .horizontalmenu .main-content.hor-content .container,
    .horizontalmenu .container {
        max-width: 85%;
    }

    .horizontalmenu .main-navbar .nav-link i.angle {
        display: none;
    }

    .horizontalmenu .hor-logo {
        display: block;
    }

    .horizontalmenu .main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
        display: none !important;
    }

    .horizontalmenu .nav-sub.open .nav-sub-link.with-sub .angle {
        display: none;
    }

    .horizontalmenu .nav-sub .nav-sub-link.sub-with-sub .angle {
        display: none;
    }

    .horizontalmenu .main-header-menu-icon {
        display: none;
    }

    .horizontalmenu .hor-menu li.nav-item .nav-link {
        padding: 10px 7px 10px !important;
    }

    .horizontalmenu .hor-menu .nav-link .hor-icon {
        font-size: 17px;
        line-height: 0;
        margin-right: 10px;
        width: 20px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;
        color: rgba(255, 255, 255, 0.6);
        fill: rgba(255, 255, 255, 0.6);
    }

    .horizontalmenu .main-navbar .nav-sub-item {
        position: relative;
        display: block;
        margin-left: 0;
        padding: 7px 0;
    }

    .horizontalmenu .menu-nav .nav-item {
        transition-duration: 0.25s;
    }

    .horizontalmenu .menu-nav .nav-item ul.nav-sub::before {
        content: "";
        position: absolute;
        top: -8px;
        left: 36%;
        width: 15px;
        height: 15px;
        border: 1px solid transparent;
        border-top-color: var(--dark-border);
        border-left-color: var(--dark-border);
        transform: rotate(45deg);
        background-color: var(--dark-theme);
        z-index: 901;
        display: block;
        transition-duration: 0.25s;
    }

    .horizontalmenu .main-navbar .nav-sub {
        top: 56px;
    }

    .horizontalmenu .main-navbar {
        padding: 0;
    }

    .main-navbar.hor-menu.ps {
        overflow: inherit;
    }

    .horizontalmenu .hor-menu .main-container-1 {
        overflow: hidden;
    }

    .horizontalmenu .hor-menu .main-body-1 {
        position: static;
    }

    .horizontalmenu .hor-menu .menu-nav .nav-item {
        position: static;
    }

    .horizontalmenu .hor-menu .menu-nav .nav-sub {
        left: auto;
    }

    .hor-menu .slide-left,
    .hor-menu .slide-right {
        position: absolute;
        top: 15px;
        padding: 6px;
        color: #1d212f;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        border: 1px solid #e8e8f7;
        border-radius: 50px;
        background-color: #ffffff;
    }

    .main-navbar .nav-sub-item>.sub-nav-sub {
        left: 158px;
        top: -10px;
        display: none;
        padding: 0 0 0 12px;
    }

    .main-navbar .sub-nav-sub {
        position: absolute;
        width: 180px;
        background-color: var(--dark-theme);
        border: 1px solid var(--dark-border);
        padding: 8px 20px !important;
        z-index: 900;
        border-radius: 11px;
        box-shadow: 0 2px 4px rgba(16, 35, 59, 0.15);
    }

    .main-navbar .nav-sub-link {
        font-size: 0.8125rem;
        margin-left: 18px;
        padding-left: 8px;
    }

    .main-navbar .nav-sub-link.sub-with-sub::after {
        font-family: "Ionicons";
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-left: auto;
        color: rgba(255, 255, 255, 0.4);
    }

    .main-navbar .nav-sub-link.sub-with-sub::after {
        content: "\f3d1";
        font-size: 12px;
        margin-right: 3px;
        top: -2px;
    }
}

.horizontalmenu .vertical-switcher {
    display: none !important;
}

.ltr #slide-right {
    position: absolute;
    right: 20px;
    left: auto;
}

.ltr #slide-left {
    position: absolute;
    left: 20px;
    right: auto;
}

.horizontalMenucontainer {
    background-size: cover;
    overflow: hidden;
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat;
}

@media (max-width: 991.98px) {
    .main-body-1 .nav-item.active .nav-link.with-sub.active .angle {
        display: none;
    }

    .main-navbar-show .main-navbar {
        transform: translateX(0) !important;
        visibility: visible;
    }

    .horizontalmenu .hor-menu {
        top: 60px !important;
    }

    .horizontalmenu .main-body-1 {
        padding: 0;
    }

    .horizontalmenu .main-body-1 .nav {
        flex-direction: column;
        padding: 0 0 0 15px;
        margin: 0 auto;
        justify-content: center;
        margin-top: 0;
    }

    .horizontalmenu .main-body-1 .nav-item {
        position: relative;
        display: block;
    }

    .horizontalmenu .hor-menu .nav-item {
        transition-duration: 0.25s;
        margin: 6px 0;
    }

    .horizontalmenu .main-body-1 li.nav-item.active .nav-link {
        background-color: #eaedf7;
        position: relative;
        z-index: 1;
        margin: 10px auto;
        text-align: center;
        border-radius: 30px 0 0 30px;
        margin-left: 0;
        border: 0px solid transparent;
        border-right: 0px;
        width: inherit;
        padding: 4px;
    }

    .horizontalmenu .main-body-1 .nav-link {
        display: flex;
        align-items: center;
        padding: 4px;
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.4);
    }

    .horizontalmenu .main-body-1 .nav-item.active .nav-link:before,
    .horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
        border-right: 21px solid var(--dark-theme);
        display: block !important;
    }

    .horizontalmenu .main-body-1 .nav-item.active .nav-link:before {
        content: "";
        position: absolute;
        top: -30px;
        z-index: 9;
        right: 0px;
        bottom: 0;
        height: 30px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom: transparent;
        border-radius: 0px 0px 48px 0px;
    }

    .horizontalmenu .main-body-1 .nav-item.active .nav-link:after {
        content: "";
        position: absolute;
        top: 43px;
        z-index: 0;
        right: 0px;
        bottom: 0;
        height: 30px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom: transparent;
        border-radius: 0px 48px 0px 0px;
    }

    .horizontalmenu .main-body-1 .nav-item.active .nav-link .shape1,
    .horizontalmenu .main-body-1 .nav-item.active .nav-link .shape2 {
        background: #eaedf7;
    }

    .horizontalmenu .main-body-1 .nav-item.active .nav-link .shape1 {
        position: absolute;
        top: -30px;
        width: 20px;
        height: 60px;
        right: 0px;
    }

    .horizontalmenu .main-body-1 .nav-item.active .nav-link .shape2 {
        position: absolute;
        top: 35px;
        width: 20px;
        height: 30px;
        right: 0px;
    }

    .horizontalmenu .main-body-1 li.active .hor-icon {
        font-size: 18px;
        line-height: 0;
        margin-right: 10px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        color: #ffffff;
    }

    .horizontalmenu .main-body-1 li.active .hor-icon {
        background: var(--primary-bg-color);
    }

    .horizontalmenu .main-body-1 .nav-link.with-sub {
        position: relative;
    }

    .horizontalmenu .main-body-1 .nav-link {
        display: flex;
        align-items: center;
        padding: 4px !important;
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.4);
    }

    .horizontalmenu .hor-menu i.angle {
        transform-origin: center;
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 12px;
    }

    .horizontalmenu .main-body-1 .nav-item+.nav-item {
        margin-top: -1px;
    }

    .horizontalmenu .main-navbar .nav-item {
        padding-right: 0;
    }

    .horizontalmenu .main-body-1 .nav-label {
        text-transform: uppercase;
        font-size: 11.49px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: rgba(255, 255, 255, 0.3);
        padding: 10px 0px 0 0;
    }

    .horizontalmenu .main-body-1 .nav-link .hor-icon {
        font-size: 17px;
        line-height: 0;
        margin-right: 10px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;
    }

    .horizontalmenu .main-navbar .nav-label {
        margin-bottom: 0;
    }

    .horizontalmenu .main-navbar .nav-link i {
        margin-right: 0;
    }

    .horizontalmenu .main-body-1 .nav-sub {
        list-style: none;
        padding: 0;
        margin-left: 1.2rem;
    }

    .horizontalmenu .hor-menu .nav-sub {
        display: none;
        overflow: hidden;
    }

    .horizontalmenu .main-body-1 .nav-sub-link {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.4);
        font-size: 13px;
        padding: 8px 0;
        position: relative;
    }

    .horizontalmenu .hor-menu .nav-sub-link {
        transition: all 0.25s;
    }

    .horizontalmenu .main-body-1 .nav-sub .nav-sub-link:before {
        content: "\e048";
        font-family: "typicons";
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        top: 8px;
        font-size: 12px;
        opacity: 0.4;
        margin-right: 22px;
        color: rgba(255, 255, 255, 0.8);
        position: initial;
    }

    .horizontalmenu .hor-menu .nav-item.show>.nav-sub {
        display: block;
    }

    .horizontalmenu .hor-menu .sub-nav-sub {
        display: none;
    }

    .horizontalmenu .main-navbar .nav-sub-item {
        padding: 0 0;
    }

    .horizontalmenu .main-body-1 .nav-sub {
        margin-left: 1.2rem;
        padding: 0;
    }

    .horizontalmenu .hor-menu .nav-link:hover .sidemenu-label {
        margin-left: 5px;
        transition: margin-left 0.45s ease;
    }

    .horizontalmenu .main-navbar .nav-link {
        height: auto;
    }

    .horizontalmenu .hor-menu .nav-item.show i.angle {
        transform: rotate(90deg);
        position: absolute;
        top: 11px;
        right: 20px;
        font-size: 12px;
    }

    .main-navbar .nav-item.active .nav-link {
        color: var(--primary-bg-color) !important;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .horizontalmenu .main-content.hor-content {
        margin-top: 60px;
    }
}

@media (min-width: 992px) {
    .main-header.hor-header.sticky-pin {
        height: 0;
    }
}

@media (max-width: 991px) {
    .main-navbar .nav-sub-item.active>.nav-sub-link.active:before {
        color: #ffffff;
        opacity: 1;
    }
}

/***End Horizontal Styles ***/
/*Color variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*Dark Theme*/
/*Dark Theme*/
.main-content.side-content {
    transition: margin-left 0.3s ease;
}

.sidemenu-label {
    transition: margin-left 0.45s ease;
}

.side-menu .nav-link:hover {
    color: #ffffff;
}

.side-menu .nav-link:hover .sidemenu-label {
    margin-left: 5px;
    transition: margin-left 0.45s ease;
}

.side-menu .nav-item {
    transition-duration: 0.25s;
    margin: 6px 0;
}

.side-menu .nav-sub {
    display: none;
    overflow: hidden;
}

.side-menu .nav-item.show>.nav-sub {
    display: block;
}

.side-menu .nav-sub-link {
    transition: all 0.25s;
}

.iconbtn {
    display: none;
}

.side-menu i.angle {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 12px;
}

.side-menu .nav-item.show i.angle {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.side-menu .nav-item .nav-sub .nav-sub-item i.angle {
    position: absolute;
    top: 11px;
    right: 20px;
    font-size: 12px !important;
}

.side-badge,
.main-sidebar-open.main-sidebar-hide .side-badge {
    position: absolute;
    top: 12px;
    right: 18px;
    border-radius: 50px;
    display: block;
}

.main-sidebar-hide .side-badge {
    display: none;
}

.sidemenu-logo {
    padding: 13.4px 21px;
    border-bottom: 1px solid rgba(240, 240, 255, 0.1);
    justify-content: center;
}

.side-menu .header-brand-img {
    margin-top: 0 !important;
    outline: 0;
}

.main-footer {
    transition: margin-left 0.3s ease;
}

/* ###### Sidebar  ###### */
@media (max-width: 991.98px) {
    .main-sidebar-show {
        overflow: inherit;
    }

    .main-sidebar-show .main-sidebar {
        display: flex;
        transform: translateX(0);
        visibility: visible;
        transition: left 0.3s ease, width 0.3s ease;
    }

    .main-sidebar-show .main-navbar-backdrop {
        opacity: 1;
    }
}

@media (min-width: 992px) {
    .main-sidebar-hide .main-sidebar {
        display: none;
    }

    .main-sidebar-hide .main-sidebar-sticky+.main-content {
        margin-left: 0;
    }
}

.main-body-sidebar {
    display: block;
}

.main-body-sidebar .main-content {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.main-body-sidebar .main-content-header {
    height: auto;
    padding: 20px;
    margin-bottom: 0;
}

.main-body-sidebar .main-content-body {
    padding: 0 20px 20px;
}

.main-body-sidebar .main-footer {
    margin-top: auto;
}

@media (min-width: 992px) {
    .main-body-sidebar {
        display: flex;
        flex-direction: row;
    }
}

@media (min-width: 992px) {
    .main-body-sidebar .main-header .container-fluid {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.main-sidebar {
    width: 240px;
    flex-direction: column;
    flex-shrink: 0;
    border-right: 0;
    transform: translateX(-240px);
    visibility: hidden;
    transition: all 0.4s;
    transition: left 0.4s ease, width 0.4s ease;
}

@media (prefers-reduced-motion: reduce) {
    .main-sidebar {
        transition: none;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .main-sidebar {
        width: 220px;
    }
}

@media (max-width: 991.98px) {
    .main-sidebar {
        position: fixed;
        top: 0px !important;
        left: 0;
        bottom: 0;
        z-index: 2;
        background-color: #ffffff;
    }
}

@media (min-width: 992px) {
    .main-sidebar {
        position: relative;
        display: flex;
        transform: none;
        z-index: 999;
        border-right-width: 1px;
        visibility: visible;
    }
}

.main-sidebar-sticky {
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;
    box-shadow: none;
    background: var(--dark-theme);
}

.main-sidebar-body .nav-item.active .nav-link:before {
    content: "";
    position: absolute;
    top: -30px;
    z-index: 9;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 0px 48px 0px;
}

.main-sidebar-body .nav-item.active .nav-link:after {
    content: "";
    position: absolute;
    top: 43px;
    z-index: 0;
    right: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-radius: 0px 48px 0px 0px;
}

.main-sidebar-body .nav-item.active .nav-link .shape1 {
    position: absolute;
    top: -30px;
    width: 20px;
    height: 60px;
    right: 0px;
}

.main-sidebar-body .nav-item.active .nav-link .shape2 {
    position: absolute;
    top: 35px;
    width: 20px;
    height: 30px;
    right: 0px;
}

.main-sidebar-body li.nav-item.active .nav-link {
    background-color: #eaedf7;
    position: relative;
    z-index: 1;
    margin: 10px auto;
    text-align: center;
    border-radius: 30px 0 0 30px;
    margin-left: 0;
    border: 0px solid transparent;
    border-right: 0px;
    width: inherit;
}

.main-sidebar-body .nav-item.active .nav-link:before,
.main-sidebar-body .nav-item.active .nav-link:after {
    border-right: 21px solid var(--dark-theme);
}

.main-sidebar-body .nav-item.active .nav-link .shape1,
.main-sidebar-body .nav-item.active .nav-link .shape2 {
    background: #eaedf7;
}

@media (min-width: 992px) {
    .main-sidebar-sticky+.main-content {
        margin-left: 220px;
    }
}

@media (min-width: 1200px) {
    .main-sidebar-sticky+.main-content {
        margin-left: 240px;
    }
}

.main-sidebar-header {
    flex-shrink: 0;
    height: 64px;
    align-items: center;
}

.main-sidebar-loggedin {
    padding: 10px 20px;
    display: flex;
    flex-shrink: 0;
}

.main-sidebar-loggedin .main-img-user {
    width: 36px;
    height: 36px;
}

.main-sidebar-loggedin .media-body {
    margin-left: 12px;
}

.main-sidebar-loggedin .media-body h6 {
    font-weight: 700;
    font-size: 14px;
    color: #8f9cc0;
    margin-bottom: 2px;
}

.main-sidebar-loggedin .media-body span {
    display: block;
    color: #a8afc7;
}

.main-sidebar-body {
    padding: 0;
}

@media (min-width: 1200px) {
    .main-sidebar-body {
        padding: 12px 0px 12px 0px;
    }
}

.main-body .main-sidebar-body .nav {
    flex-direction: column;
    padding: 0 0 0 15px;
    margin: 0 auto;
    justify-content: center;
    margin-top: 0;
}

.nav-header {
    margin: 0 0 0 15px;
    display: flex;
    position: relative;
}

.main-sidebar-body .nav-label {
    text-transform: uppercase;
    font-size: 11.49px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.3);
    padding: 10px 0px 0 0;
}

.second-sidemenu .nav-label {
    text-transform: uppercase;
    font-size: 11.49px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #77778c;
    padding: 10px 0px 0 0;
}

.second-sidemenu .nav-header li {
    list-style-type: none;
}

.main-sidebar-body .sub-txt {
    font-size: 10.9px;
    padding: 0 0px 0 0;
    color: #9b9ca3;
    margin-bottom: 0;
    position: absolute;
    margin-top: -1px;
}

.second-sidemenu .sub-txt {
    font-size: 10.9px;
    padding: 0 0px 0 0;
    color: #8d95b1;
    margin-bottom: 10px;
}

.main-sidebar-body .nav-item {
    position: relative;
    display: block;
}

.main-sidebar-body .nav-item+.nav-item {
    margin-top: -1px;
}

.main-sidebar-body .nav-link {
    display: flex;
    align-items: center;
    padding: 4px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
}

.main-sidebar-body .nav-link .sidemenu-icon {
    font-size: 17px;
    line-height: 0;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
}

.main-sidebar-body .nav-link i.typcn {
    line-height: 0.9;
}

.main-sidebar-body .nav-link i.typcn::before {
    width: auto;
}

.main-sidebar-body .nav-link.with-sub {
    position: relative;
}

.main-sidebar-body .nav-sub {
    list-style: none;
    padding: 0;
    margin-left: 1.2rem;
}

.main-sidebar-body .nav-sub .nav-sub-link:before {
    content: "\e048";
    font-family: "typicons";
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    top: 8px;
    font-size: 12px;
    opacity: 0.4;
    margin-right: 22px;
    color: rgba(255, 255, 255, 0.8);
}

.main-sidebar-body .nav-sub-item .nav-sub {
    padding: 0 0 0 12px;
    border-left-width: 1px;
    margin-left: 0;
}

.main-sidebar-body .nav-sub-item .nav-sub .nav-sub-link {
    height: auto;
    padding: 7px 0;
}

.main-sidebar-body .nav-sub-item.active>.nav-sub-link.active {
    color: #ffffff !important;
}

.main-sidebar-body .nav-sub-item.show .nav-sub {
    display: block;
}

.main-sidebar-body .nav-sub-link {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.4);
    font-size: 13px;
    padding: 8px 0;
    position: relative;
}

.main-sidebar-body .nav-sub-link.with-sub {
    justify-content: flex-start;
}

.main-sidebar-body .nav-sub-link.with-sub::after {
    content: "\f3d0";
    font-family: "Ionicons";
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-left: auto;
    color: #eaedf7;
}

.main-sidebar-body .nav-sub-link+.nav-sub-link {
    border-top: 1px dotted #eaedf7;
}

.main-sidebar-body li.active .sidemenu-icon {
    font-size: 18px;
    line-height: 0;
    margin-right: 10px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    color: #ffffff;
}

@media (min-width: 992px) {
    .main-sidebar-body .nav-sub-link.with-sub::after {
        margin-left: 4px;
        font-size: 12px;
    }
}

@media (min-width: 992px) {
    .main-sidebar-hide .main-sidebar-body li.active:after {
        top: 43px;
    }

    .main-sidebar-hide .main-sidebar .main-sidebar-body {
        padding: 17px 0px 12px 0px;
    }

    .main-sidebar-hide .main-sidebar-body .nav-link .sidemenu-icon {
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;
        z-index: 1;
        box-shadow: none;
    }

    .main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active {
        position: relative;
        z-index: 1;
        margin: 0 auto;
        text-align: center;
        border-radius: 30px 0 0 30px;
        margin-left: 0;
        border: 0px solid transparent;
        border-right: 0px;
        transition: none;
    }

    .main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
        font-size: 18px;
        line-height: 0;
        margin-right: 10px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-radius: 50%;
        box-shadow: none;
    }

    .main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
        font-size: 18px;
        line-height: 0;
        margin-right: 10px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: var(--primary-bg-color);
        text-align: center;
        border-radius: 50%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        color: #ffffff;
    }

    .main-body.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
        flex-direction: column;
        padding: 0 0 0 15px;
        text-align: inherit;
        margin: 0;
        justify-content: inherit;
    }

    .main-sidebar-hide .main-sidebar-body li.active {
        margin: 6px 0;
    }

    .main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
        background: var(--primary-bg-color);
        margin-left: 5px;
    }

    .main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
        width: inherit;
        transition: margin-left 0.3s ease;
    }

    .main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:before {
        content: "";
        position: absolute;
        top: -30px;
        z-index: 9;
        right: 0px;
        bottom: 0;
        height: 30px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom: transparent;
        border-radius: 0px 0px 48px 0px;
    }

    .main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:after {
        content: "";
        position: absolute;
        top: 43px;
        z-index: 0;
        right: 0px;
        bottom: 0;
        height: 30px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom: transparent;
        border-radius: 0px 48px 0px 0px;
    }
}

/* ###### Sidebar  ###### */
@media (min-width: 1200px) {
    .side-content {
        margin-left: 240px;
    }
}

@media (min-width: 992px) {
    .side-header .main-logo {
        color: inherit;
        margin-right: 0;
        font-size: 1.25rem;
        white-space: nowrap;
        font-weight: 600;
        padding: 0;
        transition: 0.3s opacity;
        line-height: 2.9rem;
        text-align: center;
        text-align: center;
    }

    .main-sidebar-hide .side-header,
    .main-sidebar-hide .main-footer {
        padding-left: 70px;
    }

    .main-body .side-header {
        position: fixed;
        width: 100%;
        z-index: 10 !important;
        border-bottom: 1px solid #e1e6f1;
        box-shadow: -7.829px 11.607px 20px 0px rgba(144, 143, 160, 0.09);
        left: 0;
        padding-left: 240px;
    }

    .main-body.main-sidebar-hide .side-header {
        padding-left: 75px;
    }

    .main-sidebar-hide .sidemenu-logo {
        padding: 8px 6px 6px 6px;
        border-bottom: 1px solid rgba(240, 240, 255, 0.1);
        justify-content: center;
        height: 65px;
    }

    .main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        padding: 13.4px;
        border-bottom: 1px solid rgba(240, 240, 255, 0.1);
        justify-content: center;
    }

    .main-content.side-content {
        margin-left: 240px;
    }

    .main-sidebar.side-menu {
        width: 240px;
    }

    .main-footer {
        margin-left: 0;
    }
}

@media (max-width: 992px) and (min-width: 991px) {
    .main-header-menu-icon {
        margin-left: 15px;
    }
}

@media (max-width: 991px) {
    .main-header-menu-icon {
        margin-left: 15px;
    }

    .side-header .icon-logo {
        margin-left: 0;
    }

    .main-sidebar-show .side-menu {
        top: 60px !important;
    }
}

@media (min-width: 992px) {
    .main-body.main-sidebar-hide.main-sidebar-open .side-menu .nav-link {
        padding: 4px 0px !important;
    }

    .main-sidebar-hide .side-menu {
        left: 0;
        width: 80px;
        display: block;
        visibility: visible;
        transform: none;
    }

    .main-sidebar-hide .main-content.side-content {
        margin-left: 80px;
    }

    .main-sidebar-hide .nav-label,
    .main-sidebar-hide .main-sidebar-body .sub-txt {
        display: none;
    }

    .main-sidebar-body .nav-label-1 {
        height: 28px;
        display: block;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: #ffffff;
        padding: 0;
        background: rgba(7, 167, 187, 0.24);
        width: 28px;
        border-radius: 50%;
        text-align: center;
        margin: 3px 11px;
        line-height: 2.1;
    }

    .main-sidebar-hide .nav-header {
        margin: 0;
    }

    .main-sidebar-hide.main-sidebar-open .nav-header {
        margin: 0 0 0 15px;
    }

    .main-sidebar-hide .sidemenu-label {
        display: none;
        font-size: 10px;
    }

    .main-sidebar-hide .nav-item {
        width: 100%;
    }

    .main-sidebar-hide .text-icon {
        display: none;
    }

    .main-sidebar-hide .iconbtn {
        display: block;
    }

    .main-sidebar-hide .main-sidebar-body i.angle {
        display: none;
    }

    .main-sidebar-hide .side-menu .nav-link {
        margin: 0 auto;
        padding: 4px 0;
        text-align: center;
        margin-bottom: 0;
    }

    .main-sidebar-hide .main-sidebar-body .nav-item {
        margin-top: -1px;
        text-align: center;
        margin: 6px 5px;
    }

    .main-sidebar-hide .main-sidebar-body .nav-item+.nav-item {
        margin-top: -1px;
        text-align: center;
    }

    .main-sidebar-hide .side-menu .main-logo .desktop-logo {
        display: none;
    }

    .main-sidebar-hide .side-menu .main-logo .icon-logo {
        display: block;
        margin-left: 10px;
    }

    .main-sidebar-hide .side-menu .header-brand-img {
        margin-top: 0 !important;
    }

    .main-sidebar-hide .main-sidebar-body .nav-item.show>.nav-sub {
        display: none;
    }

    .main-sidebar-hide .main-footer {
        margin-left: 0;
    }

    .main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
        font-size: 20px;
    }

    .main-sidebar-hide .side-menu i.angle {
        font-size: 12px !important;
    }

    /*main-sidebar-open*/
    .main-sidebar-open .side-menu {
        left: 0;
        width: 240px;
    }

    .main-sidebar-open .nav-label {
        display: block;
    }

    .main-sidebar-open .main-sidebar-body .sub-txt {
        display: block;
        font-size: 10.9px;
        padding: 0 0px 0 0;
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 0;
    }

    .main-sidebar-open .main-sidebar-body .nav-label {
        text-transform: uppercase;
        font-size: 11.49px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: rgba(255, 255, 255, 0.3);
        padding: 10px 0px 0 0;
    }

    .main-sidebar-open .sidemenu-label,
    .main-sidebar-open .text-icon {
        display: block;
    }

    .main-sidebar-open .iconbtn {
        display: none;
    }

    .main-sidebar-open .main-sidebar-body i.angle {
        display: block;
    }

    .main-sidebar-open .side-menu .nav-link {
        display: flex;
        margin-bottom: 0px;
    }

    .main-sidebar-open .main-sidebar-body .side-menu .nav-link {
        margin-left: 10px;
    }

    .main-sidebar-open .sidemenu-label {
        font-size: 14px;
    }

    .main-sidebar-open .side-menu .main-logo .desktop-logo {
        display: block;
        margin: 0 auto;
        text-align: center;
    }

    .main-sidebar-open .side-menu .main-logo .icon-logo {
        display: none;
    }

    .main-sidebar-open .main-sidebar-body .nav-item.show>.nav-sub {
        display: block;
    }

    .main-sidebar-open .side-menu .nav-item.show i.angle {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        position: absolute;
        top: 11px;
        right: 20px;
    }

    .main-sidebar-open .side-menu .main-sidebar-body .nav-link i {
        margin-right: 5px;
        font-size: 18px;
    }

    .main-sidebar-open .side-menu i.angle {
        font-size: 12px;
    }
}

.main-header .select2-container .select2-selection--single .select2-selection__rEndered {
    display: block;
    padding-left: 17px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (min-width: 992px) {
    .main-body.leftmenu .sidemenu-logo {
        position: fixed;
        z-index: 9999;
        width: 241px;
        background: var(--dark-theme);
        transition: all 0.4s;
        top: 0;
    }

    .main-body.leftmenu .main-sidebar-body {
        margin-top: 65px;
    }

    .main-sidebar-hide .sidemenu-logo {
        position: fixed;
        z-index: 999;
        width: 81px !important;
        background: var(--dark-theme);
    }

    .main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        width: 241px !important;
    }

    .main-sidebar-hide .main-sidebar-body {
        margin-top: 65px;
    }
}

*::-ms-backdrop,
.side-header {
    position: relative !important;
}

*::-ms-backdrop,
.main-sidebar-body .nav-sub .nav-sub-link:before {
    top: 0;
}

.side-menu .nav-sub-item.show>.nav-sub {
    max-height: block;
}

.side-menu .nav-sub-item>.nav-sub {
    max-height: none;
}

.main-sidebar-body .nav-sub-link.with-sub {
    position: relative;
    line-height: 2;
}

.main-sidebar-body .nav-sub-item .nav-sub-link.with-sub::after {
    display: none;
}

.side-menu .nav-item.show .nav-sub .nav-sub-link.with-sub i.angle {
    transform: rotate(0deg);
}

.side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub-link.with-sub i.angle {
    transform: rotate(90deg);
}

.side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub .nav-sub-link.with-sub i.angle {
    transform: rotate(0deg);
}

.side-menu .nav-item.show .nav-sub .nav-sub-item.show .nav-sub .nav-sub-item.show .nav-sub-link.with-sub i.angle {
    transform: rotate(90deg);
}

.nav-sub .side-menu-label1 {
    display: none !important;
}

@media (min-width: 992px) and (max-width: 992.9px) {
    .main-content.side-content {
        padding-top: 63px !important;
    }
}

.side-menu .sub-nav-sub {
    display: none;
}

@media (min-width: 992px) {
    .main-sidebar-hide .main-sidebar-body .nav-item.show>.nav-sub {
        display: none !important;
    }

    .main-sidebar-hide .main-sidebar-body .nav-item>.nav-sub {
        display: none !important;
    }

    .main-sidebar-open.main-sidebar-hide .main-sidebar-body .nav-item.show>.nav-sub {
        display: block !important;
    }

    .main-sidebar-header .sidemenu-logo {
        border-right: 1px solid var(--dark-border);
    }
}

.main-sidebar-body li.active li .sidemenu-label,
.main-sidebar-body li.active li i {
    color: rgba(255, 255, 255, 0.4) !important;
}

.side-menu .nav-item.show .nav-sub .nav-sub-item i.angle {
    transform: rotate(0deg);
}

.side-menu .nav-item .nav-sub .nav-sub-item.show i.angle {
    transform: rotate(0deg);
}

/* Closed Menu Start*/
@media screen and (min-width: 992px) {
    .closed-leftmenu.main-sidebar-hide .side-menu {
        left: -240px !important;
    }

    .closed-leftmenu.main-sidebar-hide .main-content.side-content {
        margin-left: 0px;
    }

    .closed-leftmenu.main-body.main-sidebar-hide .side-header {
        padding-left: 0;
    }
}

/* Closed Menu end*/
/* Icon-Text Menu Start*/
@media screen and (min-width: 992px) {
    .icontext-menu.main-sidebar-hide .main-sidebar-body .nav-link {
        display: block;
        padding: 2px 0;
    }

    .icontext-menu.main-sidebar-hide .nav-item.active .nav-link .shape1,
    .icontext-menu.main-sidebar-hide .nav-item.active .nav-link .shape2 {
        display: none;
    }

    .icontext-menu.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
        font-size: 17px;
    }

    .icontext-menu.main-sidebar-hide .sidemenu-label {
        display: block;
        font-size: 11px;
        position: relative;
    }

    .icontext-menu.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link:before,
    .icontext-menu.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link:after {
        display: none;
    }

    .icontext-menu.main-sidebar-hide .side-menu {
        width: 100px;
    }

    .icontext-menu.main-sidebar-hide .main-content.side-content {
        margin-left: 100px;
    }

    .icontext-menu.main-sidebar-hide .nav-link .sidemenu-icon {
        padding: 9px;
    }

    .icontext-menu.main-sidebar-hide li.nav-item.active .nav-link {
        border-radius: 0px;
        background: transparent !important;
        margin: 0px !important;
    }

    .icontext-menu.main-sidebar-hide .sidemenu-logo {
        width: 101px !important;
    }

    .icontext-menu.main-sidebar-hide .main-sidebar-body .nav {
        padding: 0 !important;
    }

    .icontext-menu.main-sidebar-hide .main-sidebar-body .nav-item {
        margin: 6px 0px;
    }

    .icontext-menu.main-sidebar-hide .main-sidebar-body .nav-item.show>.nav-sub {
        display: none !important;
    }

    .icontext-menu.main-sidebar-hide .side-header {
        padding-left: 100px;
    }

    .icontext-menu.main-sidebar-hide .side-menu .main-logo .icon-logo {
        margin: 0 auto !important;
    }

    .icontext-menu.main-sidebar-hide .main-sidebar-body .nav-link .sidemenu-icon {
        margin-right: 0;
        margin-left: 0;
    }

    .icontext-menu.main-sidebar-hide .side-menu .nav-sub.open {
        display: none !important;
    }

    .icontext-menu.main-sidebar-hide .nav-header {
        display: none;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link {
        display: flex;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .nav-item.active .nav-link .shape1,
    .icontext-menu.main-sidebar-hide.main-sidebar-open .nav-item.active .nav-link .shape2 {
        display: block;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .side-menu .main-sidebar-body .nav-link i {
        font-size: 18px;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .sidemenu-label {
        display: block;
        font-size: 14px;
        position: relative;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:before,
    .icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:after {
        display: block;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .side-menu {
        width: 240px;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .nav-link .sidemenu-icon {
        padding: 0;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open li.nav-item.active .nav-link {
        border-radius: 30px 0 0 30px;
        background: #eaedf7 !important;
        margin: 10px auto !important;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        width: 241px !important;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
        padding: 0 0 0 15px !important;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item {
        margin: 0;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.show>.nav-sub {
        display: block !important;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
        margin-right: 10px;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.active .sidemenu-icon {
        margin-left: 5px !important;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .nav-header {
        display: block;
    }

    .icontext-menu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item+.nav-item {
        margin-top: -1px !important;
        margin: 6px 5px;
    }
}

/* Icon-Text Menu end*/
/* Hover-Submenu Start*/
@media screen and (min-width: 992px) {
    .hover-submenu.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
        margin-right: 0 !important;
        margin-left: 0;
    }

    .hover-submenu.main-sidebar-hide .side-menu {
        left: 0;
        width: 100px;
        display: block;
        visibility: visible;
        transform: none;
    }

    .hover-submenu.main-sidebar-hide .sidemenu-logo {
        width: 101px !important;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link:before,
    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link:after {
        display: none;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link .shape1,
    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link .shape2 {
        display: none;
    }

    .hover-submenu.main-sidebar-hide .side-header {
        padding-left: 100px;
    }

    .hover-submenu.main-sidebar-hide .main-content.side-content {
        margin-left: 100px;
    }

    .hover-submenu.main-sidebar-hide .side-menu .main-logo .icon-logo {
        margin: 0 auto !important;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--dark-theme);
        border-radius: 0;
        justify-content: center;
        margin: 0px !important;
    }

    .hover-submenu.main-sidebar-hide .sidemenu-label {
        display: block;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav {
        padding: 0 !important;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav-link {
        display: block;
    }

    .hover-submenu.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
        padding: 9px;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav-item {
        margin: 6px 0;
    }

    .hover-submenu.main-sidebar-hide .sidemenu-label {
        display: block;
        font-size: 11px;
        position: relative;
    }

    .hover-submenu.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
        font-size: 17px;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav-link .sidemenu-icon {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav-link {
        display: block;
        padding: 2px 0;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar {
        overflow: visible;
        position: absolute !important;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav {
        flex-direction: column;
        text-align: inherit;
        margin: 0;
        justify-content: inherit;
    }

    .hover-submenu.main-sidebar-hide .nav-sub {
        max-height: inherit !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        height: auto !important;
        overflow: hidden !important;
        position: absolute;
        left: 80px;
        min-width: 220px;
        opacity: 1;
        z-index: 9999;
        visibility: hidden;
        top: 0;
        background: var(--dark-theme);
    }

    .hover-submenu.main-sidebar-hide .side-menu {
        left: 0;
        width: 100px;
        display: block;
        visibility: visible;
        transform: none;
    }

    .hover-submenu.main-sidebar-hide .nav-header {
        display: none;
    }

    .hover-submenu.main-sidebar-hide .nav-item .nav-sub .nav-sub-item i.angle {
        display: block;
        top: 11px;
    }

    .hover-submenu.main-sidebar-hide .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 0 0 12px;
        border-left-width: 1px;
        margin-left: 0;
    }

    .hover-submenu.main-sidebar-hide .side-menu .nav-link:hover .sidemenu-label {
        margin-left: 0;
        transition: none;
    }

    .hover-submenu.main-sidebar-hide .side-badge {
        display: none;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .nav-sub .sidemenu-label {
        font-size: 13px;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 0 0 12px;
        border-left-width: 1px;
        margin-left: 0;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .nav-link {
        display: block;
        margin-bottom: 0px;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        width: 101px !important;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo {
        display: block;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo {
        display: none;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        padding: 8px 6px 6px 6px;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link {
        display: block;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .nav-header {
        display: none;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        max-height: inherit !important;
        visibility: visible;
        border-radius: 0 11px 11px 0 !important;
        transition: none !important;
        padding: 12px 0px 12px 15px;
        opacity: inherit;
        display: block !important;
        background: var(--dark-theme);
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu {
        left: 0;
        width: 100px;
        display: block;
        visibility: visible;
        transform: none;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        width: 101px !important;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:before,
    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:after {
        display: none;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link .shape1,
    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link .shape2 {
        display: none;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-header {
        padding-left: 100px;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-content.side-content {
        margin-left: 100px;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo {
        margin: 0 auto !important;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--dark-theme);
        border-radius: 0;
        justify-content: center;
        margin: 0px !important;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .sidemenu-label {
        display: block;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
        padding: 0 !important;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link {
        display: block;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-sidebar-body .nav-link i {
        padding: 9px;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item {
        margin: 6px 0;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .sidemenu-label {
        display: block;
        font-size: 11px;
        position: relative;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .main-sidebar-body .nav-link i {
        font-size: 17px;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link {
        display: block;
        padding: 2px 0;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu i.angle {
        display: none;
    }

    .hover-submenu.main-sidebar-hide.main-sidebar-open .side-menu .nav-link {
        padding: 2px 0px !important;
    }
}

/* Hover-Submenu end*/
/* Hover-Submenu1 Start*/
@media screen and (min-width: 992px) {
    .hover-submenu1.main-sidebar-hide .main-sidebar-body li.active .sidemenu-icon {
        margin-right: 0 !important;
        margin-left: 0;
    }

    .hover-submenu1.main-sidebar-hide .side-menu {
        left: 0;
        width: 100px;
        display: block;
        visibility: visible;
        transform: none;
    }

    .hover-submenu1.main-sidebar-hide .sidemenu-logo {
        width: 101px !important;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link:before,
    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link:after {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link .shape1,
    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-item.active .nav-link .shape2 {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide .side-header {
        padding-left: 100px;
    }

    .hover-submenu1.main-sidebar-hide .main-content.side-content {
        margin-left: 100px;
    }

    .hover-submenu1.main-sidebar-hide .side-menu .main-logo .icon-logo {
        margin: 0 auto !important;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--dark-theme);
        border-radius: 0;
        justify-content: center;
        margin: 0px !important;
    }

    .hover-submenu1.main-sidebar-hide .sidemenu-label {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav {
        padding: 0;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-link {
        display: block;
    }

    .hover-submenu1.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
        padding: 9px;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-item {
        margin: 6px 0;
    }

    .hover-submenu1.main-sidebar-hide .side-menu .main-sidebar-body .nav-link i {
        font-size: 17px;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-link .sidemenu-icon {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-link {
        display: block;
        padding: 2px 0;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar {
        overflow: visible;
        position: absolute !important;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav {
        flex-direction: column;
        text-align: inherit;
        margin: 0;
        justify-content: inherit;
    }

    .hover-submenu1.main-sidebar-hide .nav-sub {
        max-height: inherit !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        height: auto !important;
        overflow: hidden !important;
        position: absolute;
        left: 80px;
        min-width: 220px;
        opacity: 1;
        z-index: 9999;
        visibility: hidden;
        top: 0;
        background: var(--dark-theme);
    }

    .hover-submenu1.main-sidebar-hide .side-menu {
        left: 0;
        width: 100px;
        display: block;
        visibility: visible;
        transform: none;
    }

    .hover-submenu1.main-sidebar-hide .nav-header {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide .nav-item .nav-sub .nav-sub-item i.angle {
        display: block;
        top: 11px;
    }

    .hover-submenu1.main-sidebar-hide .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 0 0 12px;
        border-left-width: 1px;
        margin-left: 0;
    }

    .hover-submenu1.main-sidebar-hide .side-menu .nav-link:hover .sidemenu-label {
        margin-left: 0;
        transition: none;
    }

    .hover-submenu1.main-sidebar-hide .side-badge {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub-link {
        padding: 8px 0 8px 12px;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-sub-link.sub-with-sub .sidemenu-label {
        display: block !important;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu-label1 {
        display: block !important;
        font-size: 15px;
        font-weight: 500;
        border-bottom: 1px solid var(--dark-border);
        padding: 8px 0 8px 12px;
        text-align: left;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-sub .sidemenu-label {
        font-size: 13px;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-sub-item .sub-nav-sub {
        padding: 0 0 0 12px;
        border-left-width: 1px;
        margin-left: 0;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .nav-link {
        display: block;
        margin-bottom: 0px;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        width: 101px !important;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo {
        display: block;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .desktop-logo {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        padding: 8px 6px 6px 6px;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link {
        display: block;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-header {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .nav-item:hover .nav-sub {
        max-height: inherit !important;
        visibility: visible;
        border-radius: 0 11px 11px 0 !important;
        transition: none !important;
        padding: 12px 0px 12px 0;
        opacity: inherit;
        display: block !important;
        background: var(--dark-theme);
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu {
        left: 0;
        width: 100px;
        display: block;
        visibility: visible;
        transform: none;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .sidemenu-logo {
        width: 101px !important;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:before,
    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link:after {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link .shape1,
    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item.active .nav-link .shape2 {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-header {
        padding-left: 100px;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-content.side-content {
        margin-left: 100px;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-logo .icon-logo {
        margin: 0 auto !important;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body li.nav-item.active .nav-link {
        background-color: var(--dark-theme);
        border-radius: 0;
        justify-content: center;
        margin: 0px !important;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav {
        padding: 0 !important;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link {
        display: block;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-sidebar-body .nav-link i {
        padding: 9px;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-item {
        margin: 6px 0;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .sidemenu-label {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .main-sidebar-body .nav-link i {
        font-size: 17px;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link .sidemenu-icon {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .main-sidebar-body .nav-link {
        display: block;
        padding: 2px 0;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu i.angle {
        display: none;
    }

    .hover-submenu1.main-sidebar-hide.main-sidebar-open .side-menu .nav-link {
        padding: 2px 0px !important;
    }
}

/* Hover-Submenu1 end*/
/* ############### CUSTOM VENDOR STYLES ############### */
/* ########## Datepicker ########## */
.ui-datepicker {
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0 0 0;
    display: none;
    width: auto !important;
    z-index: 5 !important;
}

.ui-datepicker .ui-datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #8f9cc0;
    padding: 12px;
    letter-spacing: 1px;
    border: 0;
    background-color: transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
    text-indent: -99999px;
    color: #a8afc7;
    top: 9px;
    background: #000000;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    font-size: 12px;
    font-family: "Ionicons";
    position: absolute;
    top: 10px;
    text-indent: 0;
    background: var(--primary01);
    padding: 5px;
    margin: -5px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before {
    color: #3b4863;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
    color: #3b4863;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    order: 3;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
    right: 17px;
    content: "\f3d1";
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
    left: 17px;
    content: "\f3cf";
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
    color: #3c4858;
    cursor: pointer;
    top: 1px;
    border: 0;
    background-color: transparent;
}

.ui-datepicker .ui-datepicker-title {
    color: #3b4863;
    font-weight: 700;
    font-size: 13px;
}

.ui-datepicker .ui-datepicker-calendar {
    margin: 0;
    background-color: transparent;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 0;
    text-align: center;
}

.ui-datepicker .ui-datepicker-calendar th {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 6px 9px;
    color: #3b4863;
    border: 1px solid #e8e8f7;
}

.ui-datepicker .ui-datepicker-calendar td {
    border: 1px solid #ffffff;
    padding: 0;
    background-color: #ffffff;
    text-align: right;
}

.ui-datepicker .ui-datepicker-calendar td:last-child {
    border-right: 0;
}

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
    color: #a8afc7;
}

.ui-datepicker .ui-datepicker-calendar td span {
    transition: all 0.2s ease-in-out;
    padding: 5px;
    color: #313b54;
    padding: 6px 10px;
    display: block;
    font-weight: 500;
    font-size: 12px;
    border: 0;
    border-radius: 1px;
    height: 38px;
    width: 38px;
}

.ui-datepicker .ui-datepicker-calendar td a {
    transition: all 0.2s ease-in-out;
    padding: 5px;
    color: #313b54;
    padding: 6px 10px;
    display: block;
    font-weight: 500;
    font-size: 12px;
    border: 0;
    border-radius: 1px;
    height: 38px;
    width: 38px;
}

.ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: #ffffff;
    color: #8f9cc0;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    color: #ffffff;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:hover,
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:focus {
    color: #ffffff;
}

@media (max-width: 320px) {
    .ui-datepicker .ui-datepicker-calendar th {
        padding: 4px 0;
        letter-spacing: normal;
    }
}

@media (prefers-reduced-motion: reduce) {

    .ui-datepicker .ui-datepicker-calendar td span,
    .ui-datepicker .ui-datepicker-calendar td a {
        transition: none;
    }
}

.ui-datepicker-multi .ui-datepicker-group {
    padding-right: 15px;
    width: auto;
    float: left;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-title {
    margin: auto;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
    left: 10px;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
    right: 10px;
}

.ui-datepicker-multi .ui-datepicker-group table {
    margin: 0;
}

.ui-datepicker-multi .ui-datepicker-group-last {
    padding-right: 0;
}

.ui-datepicker-inline {
    border-color: #eaedf7;
}

.picker-title {
    font-size: 15px;
    font-weight: 500;
}

.picker-cell__header {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0.4px;
}

.picker-item {
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.picker-picked {
    font-weight: 500;
}

.datepicker-panel>ul>li.highlighted {
    color: #ffffff !important;
    border-radius: 4px;
    background: var(--primary-bg-color);
}

.datepicker-panel>ul>li.highlighted:hover {
    color: #ffffff !important;
    background: var(--primary-bg-color);
}

/* ########## Datepicker ########## */
/* ########## Datetimepicker ########## */
.datetimepicker {
    box-shadow: none;
    border-radius: 0;
    border-width: 1px;
    border-color: #eaedf7;
    min-width: 270px;
    padding: 0 10px 10px;
}

.datetimepicker::before {
    display: none;
}

.datetimepicker table {
    width: 100%;
}

.datetimepicker table th.prev,
.datetimepicker table th.next,
.datetimepicker table th.switch {
    background-color: #ffffff;
    color: #8f9cc0;
}

.datetimepicker table th.prev,
.datetimepicker table th.next {
    position: relative;
}

.datetimepicker table th.prev span,
.datetimepicker table th.next span {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.datetimepicker table th.prev span::before,
.datetimepicker table th.next span::before {
    font-family: "Ionicons";
    position: absolute;
    top: 50%;
    font-size: 16px;
    color: #8f9cc0;
    line-height: 0;
}

.datetimepicker table th.prev:hover,
.datetimepicker table th.prev:focus {
    background-color: #ffffff;
}

.datetimepicker table th.next:hover,
.datetimepicker table th.next:focus {
    background-color: #ffffff;
}

.datetimepicker table th.prev span::before {
    content: "\f3cf";
    left: 0;
}

.datetimepicker table th.next span::before {
    content: "\f3d1";
    right: 0;
}

.datetimepicker table th.switch {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px;
    color: #8f9cc0;
}

.datetimepicker table td {
    font-size: 0.875rem;
    text-align: center;
}

.datetimepicker table td.old,
.datetimepicker table td.new {
    color: #a8afc7;
}

.datetimepicker .datetimepicker-days table thead tr:first-child th {
    height: 38px;
}

.datetimepicker .datetimepicker-days table thead tr:last-child th {
    height: 25px;
    text-transform: uppercase;
    color: #3c4858;
    font-weight: 700;
    font-size: 11px;
}

.main-datetimepicker {
    box-shadow: none;
    border-radius: 0;
    border-color: #eaedf7;
    margin-top: 2px;
}

.main-datetimepicker>.datepicker_header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-datetimepicker>.datepicker_header>.icon-home {
    display: none;
}

.main-datetimepicker>.datepicker_header a:nth-child(2),
.main-datetimepicker>.datepicker_header a:last-child {
    display: block;
    position: relative;
    text-indent: -999999px;
}

.main-datetimepicker>.datepicker_header a:nth-child(2)::before,
.main-datetimepicker>.datepicker_header a:last-child::before {
    font-family: "Ionicons";
    position: absolute;
    top: 50%;
    line-height: 0;
    text-indent: 0;
    font-size: 13px;
}

.main-datetimepicker>.datepicker_header a:nth-child(2)::before {
    content: "\f3cf";
    left: 0;
}

.main-datetimepicker>.datepicker_header a:last-child::before {
    content: "\f3d1";
    right: 0;
}

.main-datetimepicker>.datepicker_header span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-transform: uppercase;
}

.main-datetimepicker>.datepicker_inner_container {
    border-radius: 0;
    box-shadow: none;
    border-width: 0;
    display: flex;
    height: 225px;
    margin: 0;
    background-color: #ffffff;
}

.main-datetimepicker>.datepicker_inner_container::after {
    display: none;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar {
    width: 230px;
    border-width: 0;
    border-radius: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    padding: 5px;
    float: none;
    margin: 0;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar>table {
    width: 100%;
    height: 100%;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar th {
    font-size: 11px;
    text-transform: uppercase;
    color: #8f9cc0;
    font-weight: 700;
    text-align: center;
    padding: 8px 5px;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td {
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    padding: 5px;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.day_another_month {
    color: #a8afc7;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.hover {
    background-color: #eaedf7;
    cursor: pointer;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist {
    float: none;
    width: 70px;
    height: 100% !important;
    border-left: 1px solid #e8e8f7;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item {
    padding: 5px 8px;
    margin: 0 0 0 1px;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item:hover,
.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item:focus {
    background-color: #eaedf7;
}

/* ########## Datetimepicker ########## */
/* ###### Jqvmap  ###### */
.jqvmap-label {
    padding: 2px 8px;
    background-color: rgba(17, 17, 17, 0.9);
    border-radius: 2px;
}

.jqvmap-zoomin {
    font-size: 20px;
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
}

.jqvmap-zoomout {
    font-size: 20px;
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    top: 32px;
}

.jqvmap-pin {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    cursor: default;
    pointer-events: none;
}

#jqvmap1_ri_pin,
#jqvmap1_dc_pin,
#jqvmap1_de_pin,
#jqvmap1_md_pin {
    display: none;
}

#jqvmap1_ak_pin {
    margin-top: -2%;
}

#jqvmap1_ca_pin {
    margin-left: -2%;
}

#jqvmap1_ct_pin {
    margin-top: -0.25%;
    margin-left: -0.25%;
}

#jqvmap1_fl_pin {
    margin-left: 5%;
}

#jqvmap1_id_pin {
    margin-top: 3%;
    margin-left: -1%;
}

#jqvmap1_ky_pin {
    margin-left: 2%;
}

#jqvmap1_la_pin {
    margin-left: -2%;
}

#jqvmap1_mi_pin {
    margin-top: 4%;
    margin-left: 3%;
}

#jqvmap1_ma_pin {
    margin-top: -0.25%;
}

#jqvmap1_mn_pin {
    margin-top: 2%;
    margin-left: -2%;
}

#jqvmap1_nh_pin {
    margin-top: 1%;
    margin-left: -0.25%;
}

#jqvmap1_nj_pin {
    margin-top: 1%;
}

#jqvmap1_ok_pin,
#jqvmap1_va_pin {
    margin-left: 2%;
}

#jqvmap1_wv_pin {
    margin-left: -1%;
    margin-top: 1%;
}

@media only screen and (min-width: 320px) {
    .jqvmap-pin {
        font-size: 6px;
    }
}

@media only screen and (min-width: 480px) {
    .jqvmap-pin {
        font-size: 8px;
    }
}

@media only screen and (min-width: 640px) {
    .jqvmap-pin {
        font-size: 10px;
    }
}

@media only screen and (min-width: 800px) {
    .jqvmap-pin {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1024px) {
    .jqvmap-pin {
        font-size: 14px;
    }
}

.jvectormap-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
}

.jqstooltip {
    width: 40px;
}

/* ###### Jqvmap  ###### */
/* ###### Chart  ###### */
.main-donut-chart {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}

.main-donut-chart .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.main-donut-chart .chart-center {
    position: absolute;
    border-radius: 50%;
}

.main-donut-chart .chart-center span {
    display: block;
    text-align: center;
}

.morris-wrapper-demo {
    height: 200px;
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
    margin: 0 auto;
}

@media (min-width: 576px) {
    .morris-wrapper-demo {
        height: 300px;
    }
}

.morris-donut-wrapper-demo {
    height: 200px;
}

@media (min-width: 576px) {
    .morris-donut-wrapper-demo {
        height: 250px;
    }
}

.chartist-wrapper-demo {
    height: 200px;
}

@media (min-width: 768px) {
    .chartist-wrapper-demo {
        height: 300px;
    }
}

.morris-hover.morris-default-style {
    border-radius: 0;
}

.chart-donut {
    left: 0;
    position: relative;
}

#morrisDonut1 {
    height: 200px;
    width: 200px;
}

#morrisDonut1 svg {
    height: 200px;
    width: 200px;
    font-family: Roboto, sans-serif;
}

#revenuechart {
    height: 273px !important;
    width: 100% !important;
}

.flot-chart {
    width: 100%;
    height: 180px;
}

.flot-chart1 {
    width: 100%;
    height: 300px;
}

.flot-chart-wrapper {
    position: relative;
    margin-left: -16px;
    margin-right: -30px;
}

.chart-wrapper {
    position: absolute;
    right: -8px;
    bottom: 5px;
    left: -8px;
    position: relative;
    margin-left: -13px;
    margin-right: -13px;
}

#chartBar5 {
    height: 135px;
}

.echart-height {
    height: 250px;
}

.chart-height1 {
    min-height: 15rem;
    overflow: hidden;
}

.chart-height2 {
    height: 16rem;
    overflow: hidden;
}

.chart-height3 {
    height: 17rem;
    overflow: hidden;
}

.chart-height4 {
    height: 18rem;
    overflow: hidden;
}

.chart-height5 {
    height: 20rem;
    overflow: hidden;
}

.chart-height6 {
    height: 22rem;
    overflow: hidden;
}

.chart-text {
    text-align: center;
    margin: 0 auto;
}

.areaChart1,
.areaChart2,
.areaChart3,
.areaChart4 {
    display: block;
    position: relative;
    width: 80px !important;
    height: 50px !important;
}

.chart-legEnd {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    z-index: 1;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.chart-legEnd div {
    display: flex;
    align-items: center;
}

.chart-legEnd div+div {
    margin-left: 15px;
}

.chart-legEnd span {
    width: 10px;
    height: 4px;
    display: block;
    margin-right: 5px;
}

@media (min-width: 576px) {
    .chart-legEnd {
        top: -30px;
        left: auto;
        right: 40px;
    }

    .chart-legEnd span {
        width: 30px;
        margin-right: 8px;
    }

    .chart-legEnd div+div {
        margin-left: 30px;
    }
}

#bar-chart {
    height: 75px !important;
    width: 100% !important;
}

.chart-dropshadow2 {
    -webkit-filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
}

#chart svg path#apexcharts-radialbarTrack-0 {
    stroke: transparent;
}

/* ###### Chart  ###### */
/* ###### Mapel map  ###### */
.mapael .map {
    margin-bottom: 10px;
    position: relative;
}

.mapael .zoomButton {
    background-color: #292929;
    border: 1px solid #292929;
    color: #ffffff;
    width: 15px;
    height: 15px;
    line-height: 12px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    top: 0;
    font-weight: bold;
    left: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.mapael .zoomReset {
    top: 10px;
}

.mapael .zoomIn {
    top: 30px;
}

.mapael .zoomOut {
    top: 50px;
}

.mapael .mapTooltip {
    position: absolute;
    background-color: #474c4b;
    opacity: 0.7;
    filter: alpha(opacity=70);
    border-radius: 10px;
    padding: 10px;
    z-index: 1000;
    max-width: 200px;
    display: none;
    color: #ffffff;
}

.mapcontainer .map,
.mapcontainer1 .map {
    margin: 0 auto;
}

.mapcontainer2 .map {
    height: 300px;
    width: 300px;
    margin: 0 auto;
}

.mapcontainer3 svg {
    height: 300px;
    width: 100%;
}

/* ###### Mapel map  ###### */
/* ###### Parsley ###### */
input.parsley-error:focus,
textarea.parsley-error:focus,
input.parsley-success:focus,
textarea.parsley-success:focus {
    box-shadow: none !important;
}

.parsley-checkbox.parsley-error {
    padding: 10px;
    border: 1px solid #f16d75;
}

.parsley-checkbox.parsley-success {
    padding: 10px;
    border: 1px solid #19b159;
}

.parsley-errors-list {
    color: #f16d75;
    list-style: none;
    font-size: 12px;
    padding: 0;
    margin-bottom: 0;
}

.parsley-errors-list li {
    margin: 5px 0 0;
}

.parsley-style-1 .parsley-input.parsley-error .form-control {
    border-color: #f16d75;
    border-radius: 0;
}

.parsley-style-1 .parsley-input.parsley-success .form-control {
    border-color: #19b159;
}

.parsley-style-1 .parsley-checkbox.parsley-error {
    border: 1px solid #f16d75;
}

.parsley-style-1 .parsley-select.parsley-error .select2-container--default .select2-selection--single {
    border-radius: 0;
}

.parsley-style-1 .parsley-errors-list.filled {
    color: #f16d75;
    padding: 7px 10px;
}

.parsley-style-1 .parsley-errors-list.filled li:first-child {
    margin-top: 0;
}

/* ###### Parsley ###### */
/* ###### Quill ###### */
.ql-wrapper-demo {
    background-color: #ffffff;
}

.ql-wrapper-demo .ql-container {
    height: 250px;
}

.ql-wrapper-demo .ql-editor {
    height: 100%;
    overflow-y: auto;
}

@media (min-width: 1200px) {
    .ql-wrapper-demo {
        width: 100%;
    }
}

.ql-scrolling-demo {
    background-color: #ffffff;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    border: 1px solid #e8e8f7;
    position: relative;
}

.ql-scrolling-demo .ql-container {
    border-width: 0;
    height: auto;
    min-height: 100%;
    padding: 30px;
}

.ql-scrolling-demo .ql-container .ql-editor {
    font-size: 16px;
    overflow-y: visible;
    color: #3b4863;
}

@media (min-width: 768px) {
    .ql-scrolling-demo {
        padding-left: 15px;
    }
}

@media (min-width: 768px) {
    .ql-scrolling-demo .ql-container {
        padding: 10px;
    }
}

.ql-wrapper {
    display: flex;
    flex-direction: column;
}

.ql-wrapper-modal .ql-snow.ql-toolbar {
    border-width: 0;
    border-bottom-width: 1px;
}

.ql-wrapper-modal .ql-snow.ql-container {
    border-width: 0;
}

.ql-wrapper-modal .ql-snow .ql-editor {
    padding: 20px;
}

@media (min-width: 576px) {
    .ql-wrapper-modal .ql-snow.ql-toolbar {
        padding: 15px 20px;
    }
}

.ql-bubble.ql-container:not(.ql-disabled) a::before {
    background-color: #8f9cc0;
    border-radius: 2px;
}

.ql-bubble .ql-tooltip {
    background-color: #8f9cc0;
    border-radius: 2px;
}

.ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
    border-top-color: #8f9cc0;
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
    border-bottom-color: #8f9cc0;
}

.ql-bubble .ql-toolbar {
    padding: 0 8px 8px;
}

.ql-bubble .ql-toolbar button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8896af;
    outline: none;
}

.ql-bubble .ql-toolbar button i {
    font-size: 24px;
    line-height: 0;
    display: inline-block;
}

.ql-bubble .ql-toolbar button i.la-bold,
.ql-bubble .ql-toolbar button i.la-underline {
    width: 21px;
}

.ql-bubble .ql-toolbar button .ql-fill {
    fill: #8896af;
}

.ql-bubble .ql-toolbar button .ql-stroke {
    stroke: #8896af;
}

.ql-bubble .ql-toolbar button.ql-active {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.08);
}

.ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 8px 0 0;
}

.ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-left: 0;
}

@media (min-width: 576px) {
    .ql-bubble .ql-toolbar .ql-formats {
        margin: 8px 0 0;
    }

    .ql-bubble .ql-toolbar .ql-formats:first-child {
        margin-left: 0;
    }
}

.ql-snow.ql-container {
    border-color: #eaedf7;
    border-width: 1px;
}

.ql-snow.ql-toolbar {
    border-width: 1px;
    border-color: #eaedf7;
    border-bottom-width: 1px;
    padding: 5px 10px 10px;
}

.ql-snow.ql-toolbar .ql-picker-label {
    border-color: #eaedf7;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
    background-color: #eaedf7;
    border-color: transparent;
    color: #8f9cc0;
    font-weight: 500;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
    stroke: #000000;
}

.ql-snow.ql-toolbar button {
    border: 1px solid #eaedf7;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4f4e57;
    outline: none;
}

.ql-snow.ql-toolbar button svg {
    width: 20px;
    float: none;
}

.ql-snow.ql-toolbar button i {
    font-size: 24px;
    line-height: 0;
    display: inline-block;
}

.ql-snow.ql-toolbar button i.la-bold,
.ql-snow.ql-toolbar button i.la-underline {
    width: 21px;
}

.ql-snow.ql-toolbar button .ql-stroke {
    stroke-width: 2px;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus {
    border-color: #eaedf7;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #a8afc7;
}

.ql-snow.ql-toolbar button.ql-active {
    background-color: #eaedf7;
    color: #3b4863;
    border-color: transparent;
}

.ql-snow.ql-toolbar button.ql-active .ql-fill {
    fill: #3b4863;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
    stroke: #3b4863;
}

.ql-snow.ql-toolbar .ql-formats {
    margin-top: 5px;
    margin-right: 5px;
}

.ql-snow .ql-formats button+button {
    margin: 0 0 0 2px;
}

.ql-snow .ql-picker {
    display: block;
    border-width: 0;
    height: 32px;
    color: #3c4858;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: 3px;
}

.ql-snow .ql-picker:hover .ql-picker-label,
.ql-snow .ql-picker:focus .ql-picker-label {
    color: #8f9cc0;
}

.ql-snow .ql-picker:hover .ql-picker-label .ql-stroke,
.ql-snow .ql-picker:focus .ql-picker-label .ql-stroke {
    stroke: #000000;
}

.ql-snow .ql-picker-label {
    display: block;
    padding-left: 10px;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    border: 1px solid #e8e8f7;
    background-color: #ffffff;
    outline: none;
}

.ql-snow .ql-picker-label::before {
    line-height: 2.25;
    font-weight: 400;
}

.ql-snow .ql-stroke {
    stroke: #000000;
    stroke-linecap: square;
    stroke-linejoin: miter;
    stroke-width: 1;
}

.ql-snow .ql-editor {
    padding: 20px;
    color: #3b4863;
}

/* ###### Quill ###### */
/* ###### Rangeslider  ###### */
.irs-min,
.irs-max {
    font-family: "Roboto", sans-serif;
    border-radius: 0;
}

.irs-from,
.irs-to,
.irs-single {
    font-family: "Roboto", sans-serif;
    border-radius: 0;
}

.irs-bar {
    background-image: none;
}

.irs-bar-edge {
    background-image: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.irs-line-left {
    background-image: none;
    background-color: #eaedf7;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.irs-line-mid {
    background-image: none;
    background-color: #eaedf7;
}

.irs-line-right {
    background-image: none;
    background-color: #eaedf7;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.irs-slider {
    background-image: none;
}

.irs-slider:before {
    content: "";
    height: 18px;
    position: absolute;
    left: 50%;
    margin-left: -1px;
}

.irs-slider:hover:before,
.irs-slider:focus:before {
    background-color: #260bfa;
}

.irs-success .irs-bar,
.irs-success .irs-bar-edge,
.irs-success .irs-slider::before,
.irs-success .irs-from,
.irs-success .irs-to,
.irs-success .irs-single {
    background-color: #19b159;
}

.irs-success .irs-from::after,
.irs-success .irs-to::after,
.irs-success .irs-single::after {
    border-top-color: #19b159;
}

.irs-dark .irs-bar,
.irs-dark .irs-bar-edge,
.irs-dark .irs-slider::before,
.irs-dark .irs-from,
.irs-dark .irs-to,
.irs-dark .irs-single {
    background-color: #3b4863;
}

.irs-dark .irs-from::after,
.irs-dark .irs-to::after,
.irs-dark .irs-single::after {
    border-top-color: #3b4863;
}

.irs-modern .irs-line-left,
.irs-modern .irs-line-mid,
.irs-modern .irs-line-right {
    height: 6px;
    box-shadow: inset -2px 2px 2px 0 rgba(34, 34, 34, 0.1);
}

.irs-modern .irs-bar,
.irs-modern .irs-bar-edge {
    height: 6px;
    box-shadow: inset 0 1px 1px 0 rgba(34, 34, 34, 0.1);
}

.irs-modern .irs-slider {
    top: 19px;
    background-color: #ffffff;
    border-radius: 0;
}

.irs-modern .irs-slider::before {
    width: 4px;
    height: 9px;
    background-color: #ffffff;
    margin-left: -2px;
    top: 3px;
}

.irs-modern.irs-success .irs-slider {
    border-color: #19b159;
}

.irs-modern.irs-success .irs-slider::before {
    border-color: #19b159;
}

.irs-modern.irs-dark .irs-slider {
    border-color: #8f9cc0;
}

.irs-modern.irs-dark .irs-slider::before {
    border-color: #8f9cc0;
}

.irs-outline .irs-line {
    height: 10px;
    border-radius: 5px;
}

.irs-outline .irs-line-left,
.irs-outline .irs-line-mid,
.irs-outline .irs-line-right {
    height: 10px;
    background-color: transparent;
}

.irs-outline .irs-bar {
    height: 10px;
}

.irs-outline .irs-bar-edge {
    height: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.irs-outline .irs-slider {
    top: 21px;
    width: 18px;
    background-color: #ffffff;
    border-radius: 100%;
}

.irs-outline .irs-slider::before {
    margin-left: -4px;
    top: 4px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.irs-outline.irs-success .irs-line,
.irs-outline.irs-success .irs-slider {
    border-color: #19b159;
}

.irs-outline.irs-success .irs-line:hover::before,
.irs-outline.irs-success .irs-line:focus::before {
    background-color: #339701;
}

.irs-outline.irs-success .irs-slider:hover::before,
.irs-outline.irs-success .irs-slider:focus::before {
    background-color: #339701;
}

.irs-outline.irs-dark .irs-line,
.irs-outline.irs-dark .irs-slider {
    border-color: #3b4863;
}

.irs-outline.irs-dark .irs-line:hover::before,
.irs-outline.irs-dark .irs-line:focus::before {
    background-color: #313c53;
}

.irs-outline.irs-dark .irs-slider:hover::before,
.irs-outline.irs-dark .irs-slider:focus::before {
    background-color: #313c53;
}

/* ###### Rangeslider  ###### */
/* ###### Select2  ###### */
.select2-results__option {
    border-radius: 0;
    margin-bottom: 1px;
    font-size: 13px;
    padding: 6px 10px;
    font-size: 0.875rem;
}

.select2-container--default .select2-selection--single {
    background-color: #ffffff;
    border-color: #eaedf7;
    border-radius: 3px;
    height: 38px;
    outline: none;
    border-radius: 0;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #3c4858;
    line-height: calc(1.5em + 0.75rem + 2px) -0.1rem;
    padding-left: 0.75rem;
    height: 100%;
    display: flex;
    align-items: center;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #a8afc7;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 30px;
    height: 38px;
    line-height: calc(1.5em + 0.75rem + 2px);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: -3px;
}

.select2-container--default .select2-selection--multiple {
    background-color: #ffffff;
    border-color: #eaedf7;
    border-radius: 0;
    min-height: 38px;
    outline: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 4px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    position: relative;
    margin-top: 5px;
    margin-right: 4px;
    padding: 3px 10px 3px 20px !important;
    border-color: transparent;
    border-radius: 0;
    color: #ffffff;
    line-height: 1.45;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #ffffff;
    opacity: 0.5;
    font-size: 12px;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 7px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #eaedf7;
    border: solid #eaeafa 1px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: #eaedf7;
    border-radius: 0;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: white;
}

.select2-container--default .select2-results>.select2-results__options {
    margin: 0px;
}

.select2-container--default .select2-search--inline .select2-search__field {
    margin-top: 7px;
    line-height: 26px;
    padding-left: 7px;
    opacity: 1;
}

.select2-container--default.select2-container--disabled .select2-selection__choice {
    padding-left: 10px;
    background-color: #a8afc7;
}

.select2-container--default.select2-container--disabled .select2-selection__choice .select2-selection__choice__remove {
    display: none;
}

.select2-container--open .select2-selection--single,
.select2-container--open .select2-selection--multiple {
    background-color: #ffffff;
    border-color: #eaedf7;
}

.select2-container--open .select2-dropdown--above {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    top: 0;
}

.select2-dropdown {
    border-color: #eaedf7;
    z-index: 200;
}

.select2-search--dropdown {
    padding-bottom: 0;
}

.has-success .select2-container--default .select2-selection--single,
.parsley-select.parsley-success .select2-container--default .select2-selection--single {
    border-color: #19b159;
}

.has-warning .select2-container--default .select2-selection--single {
    border-color: #ff9b21;
}

.has-danger .select2-container--default .select2-selection--single,
.parsley-select.parsley-error .select2-container--default .select2-selection--single {
    border-color: #f16d75;
}

.select2-xs+.select2-container,
.select2-dropdown-xs .select2-results__option {
    font-size: 12px;
}

.select2-sm+.select2-container,
.select2-dropdown-sm .select2-results__option {
    font-size: 14px;
}

.select2-bd-0+.select2-container--default .select2-selection--single {
    border-width: 0;
}

.bg-gray+.select2-container--default .select2-selection--single {
    background-color: #455473;
}

.bg-gray+.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #eaedf7;
}

.select2-dropdown *::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    transition: 0.3s background;
}

.select2-dropdown::-webkit-scrollbar-thumb {
    background: #f0f2f8;
}

.select2-dropdown *:hover::-webkit-scrollbar-thumb {
    background: #adb5bd;
}

.select2-sm .select2-container--default .select2-selection--single {
    height: calc(1em + 0.5rem + 4px) !important;
}

.select2-sm .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: calc(1.5em + 0.5rem + 2px) !important;
}

.select2-lg .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 4px !important;
}

.select2-lg .select2-container--default .select2-selection--single {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.select2-lg .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 25px;
}

.select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 30px;
}

/* ###### Select2  ###### */
/* ###### Spectrum ###### */
.sp-container {
    background-color: #ffffff;
    border-color: #eaedf7;
    z-index: 200;
}

.sp-container button {
    border: 0;
    padding: 8px 15px;
    background-image: none;
    background-color: #eaedf7;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 12px;
    text-shadow: none;
    text-transform: capitalize;
    border-radius: 2px;
}

.sp-container button:hover,
.sp-container button:focus {
    border: 0;
    background-image: none;
    background-color: #a8afc7;
    text-shadow: none;
}

.sp-button-container .sp-cancel {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 12px;
    text-transform: capitalize;
    color: #a8afc7 !important;
}

.sp-button-container .sp-cancel:hover,
.sp-button-container .sp-cancel:focus {
    color: #3b4863 !important;
    text-decoration: none;
}

.sp-picker-container {
    border-left: 0;
}

.sp-replacer {
    border-color: #eaedf7;
    background-color: #ffffff;
}

.sp-replacer:hover,
.sp-replacer:focus {
    border-color: #e8e8f7;
}

.sp-replacer.sp-active {
    border-color: #a8afc7;
}

.sp-dd {
    text-indent: -99999px;
    position: relative;
    width: 10px;
}

.sp-dd::before {
    content: "\f280";
    font-family: "Ionicons";
    font-size: 15px;
    color: #a8afc7;
    position: absolute;
    text-indent: 0;
    left: 0;
    z-index: 10;
}

.sp-preview {
    border: 0;
}

.sp-dragger {
    background-color: transparent;
}

.sp-choose {
    background-color: #eaedf7;
}

.sp-palette .sp-thumb-el:hover,
.sp-palette .sp-thumb-el.sp-thumb-active {
    border-color: #3b4863;
}

/* ###### Spectrum ###### */
/* ###### Spinner ###### */
.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.lds-circle {
    display: inline-block;
    transform: translateZ(1px);
}

.lds-circle>div {
    display: inline-block;
    width: 51px;
    height: 51px;
    margin: 6px;
    border-radius: 50%;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {

    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }

    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(1800deg);
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    100% {
        transform: rotateY(3600deg);
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: 13px;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 6px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 26px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 45px;
    animation-delay: 0;
}

@keyframes lds-facebook {
    0% {
        top: 6px;
        height: 51px;
    }

    50%,
    100% {
        top: 19px;
        height: 26px;
    }
}

.lds-heart {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    transform: rotate(45deg);
    transform-origin: 32px 32px;
}

.lds-heart div {
    top: 23px;
    left: 19px;
    position: absolute;
    width: 26px;
    height: 26px;
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.lds-heart div:after {
    content: " ";
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
    top: -17px;
    border-radius: 50% 50% 0 0;
}

.lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
    left: -17px;
    border-radius: 50% 0 0 50%;
}

@keyframes lds-heart {
    0% {
        transform: scale(0.95);
    }

    5% {
        transform: scale(1.1);
    }

    39% {
        transform: scale(0.85);
    }

    45% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(0.9);
    }
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-ripple div {
    position: absolute;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-spinner div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.pulse {
    display: block;
    position: absolute;
    top: 6px;
    right: 17px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #f3ca56;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(243, 202, 86, 0.9);
    animation: pulse 2s infinite;
}

.pulse:hover {
    animation: none;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(243, 202, 86, 0.9);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(243, 202, 86, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(243, 202, 86, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(243, 202, 86, 0.9);
        box-shadow: 0 0 0 0 rgba(243, 202, 86, 0.7);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(243, 202, 86, 0);
        box-shadow: 0 0 0 10px rgba(243, 202, 86, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(243, 202, 86, 0);
        box-shadow: 0 0 0 0 rgba(243, 202, 86, 0);
    }
}

.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
}

.ripple:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
}

.ripple:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

/* ###### Spinner ###### */
/* ############### TEMPLATE STYLES ############### */
/* ###### Calendar  ###### */
.main-content-calendar .container,
.main-content-calendar .container-fluid {
    padding: 20px 0 0;
}

@media (min-width: 992px) {

    .main-content-calendar .container,
    .main-content-calendar .container-fluid {
        padding-top: 0;
    }
}

@media (min-width: 992px) {
    .main-content-left-calendar {
        width: 230px;
    }
}

.main-datepicker .ui-datepicker-inline {
    margin-top: 0;
}

.main-datepicker .ui-datepicker .ui-datepicker-title {
    letter-spacing: -0.2px;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar {
    width: 100%;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar th {
    padding: 5px;
    font-size: 10px;
    color: #8f9cc0;
    text-align: center;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar th.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
    color: #f16d75;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td {
    text-align: center;
    padding: 0 1px;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
    color: #f16d75;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td span,
.main-datepicker .ui-datepicker .ui-datepicker-calendar td a {
    padding: 4px 5px;
    font-family: Verdana, sans-serif;
    font-size: 11px;
    color: #3c4858;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    color: #ffffff;
    border-radius: 2px;
}

.main-nav-calendar-event .nav-link {
    font-size: 13px;
    position: relative;
}

.main-nav-calendar-event .nav-link i {
    font-size: 21px;
    position: relative;
    margin-right: 10px;
    top: -0.5px;
}

.main-nav-calendar-event .nav-link.exclude {
    color: #a8afc7;
}

.main-nav-calendar-event .nav-link.exclude i {
    color: #a8afc7;
}

.main-nav-calendar-event .nav-link.exclude::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    border-top: 1px solid #a8afc7;
}

@media (min-width: 992px) {
    .main-content-body-calendar {
        padding-left: 25px;
    }
}

.main-calendar {
    border-color: #eaedf7;
}

.main-calendar .fc-content,
.main-calendar .fc-divider,
.main-calendar .fc-list-heading td,
.main-calendar .fc-list-view,
.main-calendar .fc-popover,
.main-calendar .fc-row,
.main-calendar tbody,
.main-calendar td,
.main-calendar th,
.main-calendar thead {
    border-color: #eaedf7;
}

.main-calendar .fc-header-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 20px;
}

.main-calendar .fc-header-toolbar h2 {
    margin-bottom: 0;
    color: #4d4d6d;
    font-size: 20px;
    font-weight: 700;
}

.main-calendar .fc-header-toolbar .fc-right {
    order: 3;
}

.main-calendar .fc-header-toolbar .fc-clear {
    display: none;
}

.main-calendar .fc-header-toolbar button {
    background-color: #ffffff;
    background-image: none;
    border: 1px solid #e8e8f7;
    box-shadow: none;
    padding: 0 15px;
    display: flex;
    align-items: center;
    color: #8f9cc0;
    font-size: 13px;
    text-transform: capitalize;
    outline: none;
    text-shadow: none;
    border-radius: 0;
}

.main-calendar .fc-header-toolbar button:hover,
.main-calendar .fc-header-toolbar button:focus {
    background-color: #eaedf7;
}

.main-calendar .fc-header-toolbar button.fc-prev-button,
.main-calendar .fc-header-toolbar button.fc-next-button {
    padding: 0;
    width: 38px;
    justify-content: center;
}

.main-calendar .fc-header-toolbar button.fc-prev-button span,
.main-calendar .fc-header-toolbar button.fc-next-button span {
    margin: 0;
}

.main-calendar .fc-header-toolbar button.fc-state-active {
    background-color: #eaedf7;
}

.main-calendar .fc-header-toolbar button.fc-today-button {
    color: #ffffff;
}

.main-calendar .fc-header-toolbar button.fc-today-button.fc-state-disabled {
    border-color: #eaedf7;
    background-color: #efefff;
    color: #30304a;
    cursor: default;
}

.main-calendar .fc-icon-left-single-arrow,
.main-calendar .fc-icon-right-single-arrow {
    top: 0;
}

.main-calendar .fc-icon-left-single-arrow::after,
.main-calendar .fc-icon-right-single-arrow::after {
    font-family: "Ionicons";
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
}

.main-calendar .fc-icon-left-single-arrow::after {
    content: "\f3cf";
}

.main-calendar .fc-icon-right-single-arrow::after {
    content: "\f3d1";
}

.main-calendar .fc-head-container .fc-day-header {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 0;
    color: #8f9cc0;
}

.main-calendar .fc-view>table {
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.main-calendar .fc-view>table>thead th,
.main-calendar .fc-view>table>thead td {
    border-color: #eaedf7;
}

.main-calendar .fc-view>table>tbody>tr>td {
    border-color: #eaedf7;
}

.main-calendar .fc-view .fc-other-month {
    background-color: #f9f9ff;
}

.main-calendar .fc-view .fc-day-number {
    font-size: 14px;
    font-weight: 400;
    font-family: Arial, sans-serif;
    color: #3c4858;
    display: inline-block;
    padding: 5px 8px;
    position: relative;
    transition: all 0.2s ease-in-out;
    margin: 2px 2px 0 0;
    min-width: 20px;
    text-align: center;
}

.main-calendar .fc-view .fc-day-number:hover,
.main-calendar .fc-view .fc-day-number:focus {
    color: #8f9cc0;
    background-color: #f1f2f9;
}

.main-calendar .fc-view .fc-scroller {
    height: auto !important;
    overflow: visible !important;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span {
    display: block;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:first-child {
    color: #a8afc7;
    font-size: 8px;
    font-weight: 400;
    line-height: 1.2;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:last-child {
    font-weight: 700;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    line-height: 1;
}

.main-calendar .fc-view.fc-listMonth-view,
.main-calendar .fc-view.fc-listWeek-view {
    border-width: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-scroller,
.main-calendar .fc-view.fc-listWeek-view .fc-scroller {
    padding: 0 20px 20px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-table,
.main-calendar .fc-view.fc-listWeek-view .fc-list-table {
    border-collapse: separate;
    display: block;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-table>tbody,
.main-calendar .fc-view.fc-listWeek-view .fc-list-table>tbody {
    display: flex;
    flex-wrap: wrap;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 20%;
    max-width: 20%;
    margin-top: 15px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading:first-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading:first-child,
.main-calendar .fc-view.fc-listMonth-view .fc-list-heading:first-child+.fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading:first-child+.fc-list-item {
    margin-top: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading td {
    background-color: transparent;
    border-width: 0;
    padding: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    color: #a8afc7;
    font-weight: 600;
    padding: 5px;
    text-align: center;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main span:last-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main span:last-child {
    display: block;
    font-size: 30px;
    font-weight: 700;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: -1px;
    color: #8f9cc0;
    line-height: 1;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(80% - 5px);
    max-width: calc(80% - 5px);
    display: flex;
    flex-direction: column;
    border-left: 4px solid transparent;
    background-color: #ffffff;
    margin-top: 15px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item:hover,
.main-calendar .fc-view.fc-listMonth-view .fc-list-item:focus {
    cursor: pointer;
}

.main-calendar .fc-view.fc-listWeek-view .fc-list-item:hover,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item:focus {
    cursor: pointer;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item:hover td,
.main-calendar .fc-view.fc-listMonth-view .fc-list-item:focus td {
    background-color: transparent;
}

.main-calendar .fc-view.fc-listWeek-view .fc-list-item:hover td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item:focus td {
    background-color: transparent;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td {
    border-width: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
    border-top: 1px solid #e8e8f7;
    border-right: 1px solid #e8e8f7;
    box-sizing: border-box;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
    border-bottom: 1px solid #e8e8f7;
    border-right: 1px solid #e8e8f7;
    box-sizing: border-box;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-left: 20%;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-marker,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-marker {
    display: none;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-time,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-time {
    padding: 12px 15px 2px;
    font-size: 13px;
    text-transform: capitalize;
    width: 100%;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title {
    padding: 0 15px 12px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title a,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title a {
    display: block;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 5px;
    color: #8f9cc0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title .fc-desc,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title .fc-desc {
    font-size: 13px;
    line-height: 1.375;
    display: block;
}

.main-calendar td.fc-today {
    background-color: #f1f2f9;
}

.main-calendar td.fc-today .fc-day-number {
    color: #ffffff;
    border-radius: 1px;
}

.main-calendar td.fc-today .fc-day-number:hover,
.main-calendar td.fc-today .fc-day-number:focus {
    border-radius: 1px;
}

.main-calendar .fc-axis {
    font-size: 11px;
}

.main-calendar .fc-event {
    border-width: 0;
    border-top-width: 5px;
    border-radius: 0;
}

.main-calendar .fc-event.fc-day-grid-event>div {
    display: none;
}

.main-calendar .fc-event.fc-day-grid-event>div .fc-time {
    font-weight: 500;
}

.main-calendar .fc-event.fc-day-grid-event>div .fc-desc {
    display: none;
}

.main-calendar .fc-time-grid-event {
    padding: 5px;
    border-left-width: 0;
    border-top-width: 2px;
}

.main-calendar .fc-time-grid-event .fc-time {
    font-size: 12px;
}

.main-calendar .fc-time-grid-event .fc-title {
    font-size: 14px;
    font-weight: 700;
    color: #8f9cc0;
    margin-bottom: 10px;
}

.main-calendar .fc-time-grid-event .fc-desc {
    font-size: 13px;
    line-height: 1.3;
    color: rgba(28, 39, 60, 0.8);
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div {
    display: none;
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-time {
    font-size: 11px;
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-title {
    font-size: 13px;
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-desc {
    display: none;
}

.main-calendar .fc-list-empty {
    padding: 20px 0;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

@media (min-width: 992px) {
    .main-calendar .fc-header-toolbar {
        padding: 0;
        margin-bottom: 24px;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-header-toolbar h2 {
        font-size: 22px;
    }
}

@media (max-width: 767.98px) {
    .main-calendar .fc-header-toolbar .fc-center {
        order: 1;
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .main-calendar .fc-header-toolbar .fc-left {
        order: 2;
        flex: 0 0 50%;
    }

    .main-calendar .fc-header-toolbar .fc-right {
        flex: 0 0 50%;
        display: flex;
        justify-content: flex-end;
    }
}

@media (max-width: 575px) {

    .main-calendar .fc-header-toolbar button.fc-prev-button,
    .main-calendar .fc-header-toolbar button.fc-next-button {
        width: 32px;
        height: 32px;
    }

    .main-calendar .fc-header-toolbar button.fc-today-button {
        height: 32px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 11px;
    }

    .main-calendar .fc-header-toolbar button.fc-month-button,
    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button,
    .main-calendar .fc-header-toolbar button.fc-agendaDay-button,
    .main-calendar .fc-header-toolbar button.fc-listWeek-button,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button {
        text-indent: -9999px;
        width: 32px;
        height: 32px;
        position: relative;
        color: transparent;
    }

    .main-calendar .fc-header-toolbar button.fc-month-button::before,
    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
    .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
        position: absolute;
        top: 6px;
        left: 10px;
        z-index: 100;
        display: inline-block;
        text-indent: 0;
        font-size: 12px;
        font-weight: 700;
        color: #8f9cc0;
    }

    .main-calendar .fc-header-toolbar button.fc-month-button::before {
        content: "M";
    }

    .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before {
        content: "W";
    }

    .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before {
        content: "D";
    }

    .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
    .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
        content: "L";
        left: 11px;
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-icon-left-single-arrow::after,
    .main-calendar .fc-icon-right-single-arrow::after {
        line-height: 0.75;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-head-container .fc-day-header {
        font-size: 12px;
    }
}

@media (max-width: 991.98px) {

    .main-calendar .fc-view>table>thead th:first-child,
    .main-calendar .fc-view>table>thead td:first-child {
        border-left-width: 0;
    }

    .main-calendar .fc-view>table>thead th:last-child,
    .main-calendar .fc-view>table>thead td:last-child {
        border-right-width: 0;
    }
}

@media (max-width: 991.98px) {
    .main-calendar .fc-view>table>tbody>tr>td:first-child {
        border-left-width: 0;
    }

    .main-calendar .fc-view>table>tbody>tr>td:last-child {
        border-right-width: 0;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-calendar .fc-view .fc-day-number {
        transition: none;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:first-child {
        font-size: 11px;
        font-weight: 400;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:last-child {
        font-size: 25px;
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-scroller,
    .main-calendar .fc-view.fc-listWeek-view .fc-scroller {
        padding: 0;
    }
}

@media (min-width: 576px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 15%;
        max-width: 15%;
    }
}

@media (min-width: 768px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 12%;
        max-width: 12%;
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 10%;
        max-width: 10%;
    }
}

@media (min-width: 1200px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
        flex: 0 0 8%;
        max-width: 8%;
    }
}

@media (min-width: 576px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(85% - 5px);
        max-width: calc(85% - 5px);
    }
}

@media (min-width: 768px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(88% - 5px);
        max-width: calc(88% - 5px);
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(90% - 5px);
        max-width: calc(90% - 5px);
    }
}

@media (min-width: 1200px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
        flex: 0 0 calc(92% - 5px);
        max-width: calc(92% - 5px);
    }
}

@media (min-width: 576px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 15%;
    }
}

@media (min-width: 768px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 12%;
    }
}

@media (min-width: 992px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 10%;
    }
}

@media (min-width: 1200px) {

    .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
    .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
        margin-left: 8%;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-axis {
        font-size: 13px;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-event {
        border-top-width: 0;
        border-left-width: 2px;
        padding: 3px 4px;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-event.fc-day-grid-event>div {
        display: block;
    }
}

@media (min-width: 576px) {
    .main-calendar .fc-agendaWeek-view .fc-time-grid-event>div {
        display: block;
    }
}

@media (min-width: 992px) {
    .main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-desc {
        display: block;
    }
}

.main-modal-calendar-schedule .modal-content {
    border-width: 0;
    position: relative;
}

.main-modal-calendar-schedule .modal-content>.close {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 28px;
    font-weight: 700;
    color: #a8afc7;
    text-shadow: none;
}

.main-modal-calendar-schedule .modal-header {
    padding: 20px 25px;
}

.main-modal-calendar-schedule .modal-body {
    padding: 30px 25px;
}

.main-modal-calendar-schedule .form-group-date {
    display: flex;
    align-items: center;
    border: 1px solid #e8e8f7;
    background-color: #f1f2f9;
    padding: 10px 5px;
    color: #8f9cc0;
    font-size: 13px;
    font-weight: 600;
}

.main-modal-calendar-schedule .form-group-date>i {
    font-size: 32px;
    line-height: 0;
    margin-right: 10px;
    opacity: 0.5;
}

.main-modal-calendar-schedule .select2-container {
    width: 100% !important;
}

.main-modal-calendar-event .modal-content {
    border-width: 0;
    position: relative;
    background-color: transparent;
}

.main-modal-calendar-event .modal-content>.close {
    font-size: 18px;
    text-shadow: none;
}

.main-modal-calendar-event .modal-header {
    position: relative;
    padding: 20px;
    border-bottom-width: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.main-modal-calendar-event .nav-modal-event {
    align-items: center;
}

.main-modal-calendar-event .nav-modal-event .nav-link {
    padding: 0;
    color: rgba(255, 255, 255, 0.75);
    font-size: 21px;
    line-height: 0;
    position: relative;
    transition: all 0.2s ease-in-out;
}

.main-modal-calendar-event .nav-modal-event .nav-link:hover,
.main-modal-calendar-event .nav-modal-event .nav-link:focus {
    color: #ffffff;
}

.main-modal-calendar-event .nav-modal-event .nav-link+.nav-link {
    margin-left: 15px;
}

.main-modal-calendar-event .nav-modal-event .nav-link:last-child {
    font-size: 24px;
}

.main-modal-calendar-event .event-title {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1;
}

.main-modal-calendar-event .modal-body {
    padding: 20px;
    background-color: #ffffff;
}

.main-modal-calendar-event .event-start-date,
.main-modal-calendar-event .event-end-date {
    color: #8f9cc0;
    font-weight: 500;
}

.main-modal-calendar-event .event-desc {
    margin-top: 5px;
}

.main-modal-calendar-event .event-desc:empty {
    margin-bottom: 5px;
}

@media (min-width: 576px) {
    .main-modal-calendar-event .modal-header {
        padding: 20px 25px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-modal-calendar-event .nav-modal-event .nav-link {
        transition: none;
    }
}

@media (min-width: 576px) {
    .main-modal-calendar-event .modal-body {
        padding: 30px;
    }
}

/* ###### Calendar  ###### */
/* ###### Chat  ###### */
@media (max-width: 991.98px) {
    .main-content-body-show .main-header-menu-icon {
        display: none;
    }

    .main-content-body-show .main-header-left .main-header-arrow {
        display: block;
    }

    .main-content-body-show .main-content-left-chat {
        display: none;
    }

    .main-content-body-show .main-content-body-chat {
        display: flex;
    }
}

.main-content-left-chat {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.main-nav-line-chat {
    border-bottom: 1px solid #e8e8f7;
    padding: 19px;
    flex-shrink: 0;
    flex-direction: row;
    padding-left: 20px;
}

.main-nav-line-chat .nav-link {
    font-size: 13px;
    font-weight: 700;
    color: #3b4863;
    text-transform: uppercase;
}

.main-nav-line-chat .nav-link:hover,
.main-nav-line-chat .nav-link:focus,
.main-nav-line-chat .nav-link:active {
    outline: none;
}

.main-nav-line-chat .nav-link+.nav-link {
    margin-top: 0;
    margin-left: 30px;
}

.main-chat-contacts-wrapper {
    padding: 10px 20px;
    flex-shrink: 0;
    border-bottom: 1px solid #e8e8f7;
    overflow: hidden;
}

.main-chat-contacts-wrapper .main-content-label,
.main-chat-contacts-wrapper .card-table-two .card-title {
    margin-bottom: 15px;
    line-height: 1;
}

@media (min-width: 992px) {
    .main-chat-contacts-wrapper {
        padding: 10px 15px 10px 0;
    }
}

.card-table-two .main-chat-contacts-wrapper .card-title,
.main-chat-contacts-wrapper .card-dashboard-eight .card-title,
.card-dashboard-eight .main-chat-contacts-wrapper .card-title {
    margin-bottom: 15px;
    line-height: 1;
}

.main-chat-contacts-wrapper .lSSlideOuter,
.main-chat-contacts-wrapper .lSSlideWrapper {
    overflow: visible;
}

.main-chat-contacts {
    padding-left: 20px;
    display: flex;
}

.main-chat-contacts .lslide {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: none;
}

.main-chat-contacts small {
    font-size: 11px;
    width: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 3px;
    text-align: center;
}

.main-chat-contacts-more {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
}

@media (min-width: 992px) {
    .main-chat-list {
        height: calc(100% - 134px);
        position: relative;
    }
}

.main-chat-list .media {
    padding: 12px 20px;
    border: 1px solid transparent;
    position: relative;
}

.main-chat-list .media .main-img-user {
    flex-shrink: 0;
    top: 3px;
}

.main-chat-list .media+.media {
    margin-top: -1px;
    border-top: 1px solid #e8e8f7;
}

.main-chat-list .media.new {
    background-color: #ffffff;
}

.main-chat-list .media.new .main-img-user span {
    display: flex;
}

.main-chat-list .media.new .media-contact-name span:first-child {
    font-weight: 500;
    color: #404c6d;
}

.main-chat-list .media.new .media-body p {
    color: #8f9cc0;
}

.main-chat-list .media:hover,
.main-chat-list .media:focus {
    cursor: pointer;
    background-color: #f4f5fb;
    border-top-color: #eaedf7;
    border-bottom-color: #eaedf7;
}

.main-chat-list .media:hover:first-child,
.main-chat-list .media:focus:first-child {
    border-top-color: transparent;
}

.main-chat-list .media:hover:last-child,
.main-chat-list .media:focus:last-child {
    border-radius: 0 0 11px 11px;
}

.main-chat-list .media.selected {
    background-color: #f1f2f9;
    border-top-color: #eaedf7;
    border-bottom-color: #eaedf7;
}

.main-chat-list .media.selected:first-child {
    border-top-color: transparent;
}

.main-chat-list .media.selected::after {
    display: block;
}

.main-chat-list .media.selected .media-contact-name span:first-child {
    color: #404c6d;
}

.main-chat-list .media.selected .media-body p {
    color: #8f9cc0;
}

.main-chat-list .main-img-user span {
    position: absolute;
    top: 0;
    left: -2px;
    width: 14px;
    height: 14px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    font-size: 9px;
    font-weight: 500;
    color: #ffffff;
    background-color: #f16d75;
    box-shadow: 0 0 0 2px #ffffff;
}

.main-chat-list .media-body {
    margin-left: 15px;
}

.main-chat-list .media-body p {
    font-size: 13px;
    margin-bottom: 0;
    color: #a8afc7;
    line-height: 1.35;
}

.main-chat-list .media-contact-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

.main-chat-list .media-contact-name span:first-child {
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #404c6d;
}

.main-chat-list .media-contact-name span:last-child {
    display: block;
    font-size: 11px;
    color: #a8afc7;
}

@media (min-width: 992px) {
    .main-chat-list .media {
        padding: 12px 15px;
    }
}

.main-content-body-chat {
    flex-direction: column;
}

@media (min-width: 992px) {
    .main-content-body-chat {
        display: flex;
    }
}

.main-chat-header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 12px;
    border-bottom: 1px solid #e8e8f7;
}

.main-chat-header .nav {
    margin-left: auto;
    align-items: center;
}

.main-chat-header .nav-link {
    padding: 0;
    color: #a8afc7;
    font-size: 16px;
    display: none;
}

.main-chat-header .nav-link:first-child {
    display: block;
}

.main-chat-header .nav-link i {
    line-height: 0;
}

.main-chat-header .nav-link .typcn {
    line-height: 0.9;
}

.main-chat-header .nav-link .typcn::before {
    width: auto;
}

.main-chat-header .nav-link+.nav-link {
    margin-left: 15px;
}

@media (min-width: 576px) {
    .main-chat-header .nav-link {
        display: block;
    }

    .main-chat-header .nav-link:first-child {
        display: none;
    }
}

.main-chat-msg-name {
    margin-left: 15px;
}

.main-chat-msg-name h6 {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 700;
    color: #8f9cc0;
}

.main-chat-msg-name small {
    font-size: 12px;
    color: #a8afc7;
}

.main-chat-body {
    position: relative;
}

.main-chat-body .content-inner {
    padding: 20px;
}

.main-chat-body .media+.media {
    margin-top: 20px;
}

.main-chat-body .media.flex-row-reverse .media-body {
    margin-left: 0;
    margin-right: 20px;
    align-items: flex-end;
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: rgb(233, 233, 249);
    color: #1e1635;
    position: relative;
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper:before,
.main-chat-body .media.flex-row-reverse .main-msg-wrapper:after {
    right: auto;
    left: 100%;
    border-right-color: transparent;
    border-left-color: rgb(233, 233, 249);
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper:after {
    border-width: 8px;
}

@media (min-width: 992px) {
    .main-chat-body {
        padding-bottom: 0;
        height: calc(100% - 105px);
        overflow: hidden;
    }
}

@media (min-width: 576px) {
    .main-chat-body .media.flex-row-reverse .media-body {
        margin-left: 55px;
    }
}

.main-msg-wrapper {
    padding: 10px 15px;
    background-color: #f4f6ff;
    font-size: 13px;
    margin-bottom: 5px;
    display: inline-block;
    border-radius: 4px;
    position: relative;
}

.main-msg-wrapper:before,
.main-msg-wrapper:after {
    border-color: transparent #f4f6ff transparent transparent;
    border-image: none;
    border-style: solid;
    border-width: medium;
    content: " ";
    height: 0;
    pointer-events: none;
    position: absolute;
    right: 100%;
    top: 5%;
    width: 0;
}

.main-msg-wrapper:after {
    border-width: 8px;
}

.main-chat-body .media-body {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.main-chat-body .media-body>div:last-child {
    font-size: 11px;
    color: #a8afc7;
    display: flex;
    align-items: center;
}

@media (min-width: 576px) {
    .main-chat-body .media-body {
        margin-right: 55px;
    }
}

.main-chat-time {
    display: block;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.main-chat-time:first-of-type {
    margin-top: 0;
}

.main-chat-time span {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: #e6f3f9;
    padding: 5px;
    border-radius: 4px;
}

.main-chat-footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    border-top: 1px solid #e8e8f7;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.main-chat-footer .nav {
    align-items: center;
}

.main-chat-footer .nav-link {
    padding: 0;
    color: #a8afc7;
    font-size: 16px;
    display: none;
}

.main-chat-footer .nav-link+.nav-link {
    margin-left: 10px;
}

.main-chat-footer .form-control {
    flex: 1;
    margin: 0 10px;
    padding-left: 0;
    padding-right: 0;
    border-width: 0;
}

.main-chat-footer .form-control:hover,
.main-chat-footer .form-control:focus {
    box-shadow: none;
}

@media (min-width: 992px) {
    .main-chat-footer {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        border-radius: 0px 0px 5px 5px;
    }
}

@media (min-width: 576px) {
    .main-chat-footer .nav-link {
        display: block;
    }
}

@media (min-width: 576px) {
    .main-chat-footer .form-control {
        margin-left: 20px;
    }
}

@media (min-width: 768px) {
    .main-chat-footer .form-control {
        margin: 0 20px;
    }
}

.main-msg-send {
    font-size: 20px;
}

.main-content-title {
    color: #170c6b;
    font-weight: 500;
    font-size: 32px;
    text-indent: -1px;
    line-height: 1;
    position: relative;
    margin-bottom: 20px;
}

.main-content-left-contacts .main-content-breadcrumb,
.main-content-left-contacts .main-content-title {
    padding-left: 20px;
}

@media (min-width: 992px) {

    .main-content-left-contacts .main-content-breadcrumb,
    .main-content-left-contacts .main-content-title {
        padding-left: 0;
    }

    .main-content-body-contacts {
        display: block;
        overflow-y: auto;
    }
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper2 {
    background-color: #e9e9f9;
    color: #1e1635;
    position: relative;
}

.main-msg-wrapper2 {
    padding: 10px 15px;
    background-color: #f4f6ff;
    font-size: 13px;
    margin-bottom: 5px;
    display: inline-block;
    border-radius: 4px;
    position: relative;
}

.chat-account .contact-icon {
    display: inline-flex;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin: 4px;
    background-color: var(--primary01);
    color: var(--primary-bg-color);
}

/* ###### Chat  ###### */
/* ########## Contacts ########## */
@media (min-width: 768px) and (max-width: 991.98px) {
    .main-content-body-show .main-header-contacts .main-header-menu-icon {
        display: flex;
    }

    .main-content-body-show .main-content-left-contacts {
        display: block;
    }
}

.main-content-left-contacts {
    width: 100%;
    display: block;
    padding: 0;
}

@media (max-width: 768px) {
    .main-contacts-list {
        height: 570px !important;
        max-height: 570px !important;
    }
}

@media (min-width: 769px) {
    .main-contacts-list {
        height: 800px !important;
        max-height: 800px !important;
    }
}

.main-contact-label {
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    font-weight: 500;
    font-size: 12px;
    color: #a8afc7;
    position: relative;
    z-index: 0;
}

.main-contact-label::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    border-bottom: 1px solid #e8e8f7;
    z-index: 5;
}

.main-contact-item {
    padding: 10px 20px;
    border: 1px solid transparent;
    border-right-width: 0;
    display: flex;
    position: relative;
    cursor: pointer;
}

.main-contact-item+.main-contact-item {
    margin-top: -1px;
    border-top-color: transparent;
    border-top: 1px solid #e8e8f7;
}

.main-contact-item:hover,
.main-contact-item:focus {
    background-color: #f1f2f9;
    border-top-color: #eaedf7;
    border-bottom-color: #eaedf7;
}

.main-contact-item.selected {
    z-index: 0;
    border-left-color: #eaedf7;
    border-top-color: #eaedf7;
    border-bottom-color: #eaedf7;
    background-color: #f9f9ff;
}

@media (min-width: 992px) {
    .main-contact-item {
        padding: 10px 15px;
    }
}

.main-contact-body {
    flex: 1;
    margin-left: 15px;
}

.main-contact-body h6 {
    color: #454567;
    font-weight: 500;
    margin-bottom: 2px;
}

.main-contact-body span {
    font-size: 13px;
    color: #a8afc7;
}

.main-contact-star {
    font-size: 16px;
    color: #a8afc7;
}

.main-contact-star:hover,
.main-contact-star:focus {
    color: #a8afc7;
}

.main-contact-star.active {
    color: #ff9b21;
}

@media (min-width: 992px) {
    .main-content-body-contacts {
        overflow: hidden;
    }
}

.main-contact-info-header {
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8f7;
    position: relative;
}

.main-contact-info-header .media {
    display: block;
}

.main-contact-info-header .main-img-user {
    width: 100px;
    height: 100px;
}

.main-contact-info-header .main-img-user::after {
    display: none;
}

.main-contact-info-header .main-img-user a {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #f9f9ff;
    color: #454567;
    font-size: 18px;
    line-height: 0.9;
    box-shadow: 0 0 0 2px #eaedf7;
    border-radius: 100%;
}

.main-contact-info-header .media-body {
    margin-top: 30px;
}

.main-contact-info-header .media-body h4 {
    color: #454567;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 2px;
    line-height: 1;
}

.main-contact-info-header .media-body p {
    font-size: 13px;
    color: #3c4858;
    margin-bottom: 15px;
}

.main-contact-info-header .media-body .nav-link {
    padding: 0;
    display: flex;
    align-items: center;
    color: #a8afc7;
    font-size: 13px;
}

.main-contact-info-header .media-body .nav-link i {
    font-size: 18px;
    line-height: 0;
    margin-right: 5px;
}

.main-contact-info-header .media-body .nav-link i.typcn {
    line-height: 0.9;
}

.main-contact-info-header .media-body .nav-link i.typcn::before {
    width: auto;
}

.main-contact-info-header .media-body .nav-link+.nav-link {
    margin-left: 10px;
}

@media (min-width: 992px) {
    .main-contact-info-header .media {
        display: flex;
        align-items: center;
    }
}

@media (min-width: 992px) {
    .main-contact-info-header .media-body {
        margin-top: 0;
        margin-left: 30px;
    }
}

@media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link {
        font-size: 0.875rem;
    }
}

@media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link i {
        margin-right: 7px;
    }
}

@media (min-width: 576px) {
    .main-contact-info-header .media-body .nav-link+.nav-link {
        margin-left: 25px;
    }
}

.main-contact-action {
    position: absolute;
    top: 0;
    right: 20px;
    display: flex;
}

.main-contact-action a {
    display: flex;
    align-items: center;
    color: #a8afc7;
    font-size: 12px;
}

@media (min-width: 992px) {
    .main-contact-action {
        right: 0;
    }
}

.main-contact-info-body {
    padding: 1.25rem;
}

.main-contact-info-body .media-list {
    padding: 20px 0;
    padding: 15px 0;
}

.main-contact-info-body .media {
    align-items: center;
    position: relative;
}

.main-contact-info-body .media+.media {
    margin-top: 15px;
}

.main-contact-info-body .media:last-child {
    margin-bottom: 15px;
}

.main-contact-info-body .media-icon {
    font-size: 24px;
    color: #a8afc7;
    line-height: 1;
    width: 40px;
    text-align: center;
}

.main-contact-info-body .media-body>div+div {
    margin-top: 15px;
}

.main-contact-info-body .media-body label {
    color: #848eb1;
    margin-bottom: 0;
    display: block;
    font-size: 13px;
}

.main-contact-info-body .media-body span {
    display: block;
    color: #616c8c;
    font-weight: 500;
}

@media (min-width: 992px) {
    .main-contact-info-body {
        padding: 1.25rem;
        height: calc(100% - 126px);
        position: relative;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body .media-icon {
        width: 100px;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body .media-body {
        display: flex;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body .media-body>div {
        flex: 1;
    }
}

@media (min-width: 992px) {
    .main-contact-info-body .media-body>div+div {
        margin-top: 0;
    }
}

/* ########## Contacts ########## */
/* ######  Error  ###### */
.main-signin-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
}

.main-card-signin {
    height: 560px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 480px) {
    .main-card-signin {
        border: 1px solid #e8e8f7;
        width: 360px;
        padding: 30px 35px;
    }
}

.sp-container .main-signin-header button {
    margin-top: 25px;
}

.main-signin-footer p {
    color: #a8afc7;
}

.main-signin-footer p:first-child {
    margin-bottom: 5px;
}

.main-signin-footer p:last-child {
    margin-bottom: 0;
}

.main-signin-footer a {
    color: #8f9cc0;
    font-weight: 700;
}

.main-signup-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: rgba(244, 245, 248, 0.2);
}

@media (min-width: 992px) {
    .main-signup-wrapper {
        justify-content: flex-end;
    }
}

.main-column-signup-left {
    flex: 1;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
}

.main-column-signup-left>div {
    max-width: 500px;
}

.main-column-signup-left .typcn {
    font-size: 80px;
    margin-bottom: 50px;
}

.main-column-signup-left .typcn::before {
    width: auto;
}

.main-column-signup-left .main-logo {
    font-size: 40px;
    line-height: 1;
}

.main-column-signup-left h5 {
    color: #8f9cc0;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 25px;
}

.main-column-signup-left p {
    line-height: 1.7;
    margin-bottom: 25px;
}

.main-column-signup-left .btn,
.main-column-signup-left .sp-container button {
    border-width: 2px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px;
}

@media (min-width: 576px) {
    .main-column-signup-left {
        padding: 40px;
    }
}

@media (min-width: 992px) {
    .main-column-signup-left {
        display: flex;
    }
}

.sp-container .main-column-signup-left button {
    border-width: 2px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: 700;
    text-transform: uppercase;
    height: 38px;
}

.main-column-signup {
    background-color: #ffffff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (min-width: 576px) {
    .main-column-signup {
        padding: 40px;
        width: 500px;
    }
}

@media (min-width: 992px) {
    .main-column-signup {
        border-left: 1px solid #e8e8f7;
    }
}

.main-signin-header .btn,
.main-signin-header .sp-container button {
    margin-top: 25px;
}

.main-signup-header h2 {
    font-weight: 500;
    letter-spacing: -1px;
}

.main-signup-header h4 {
    font-size: 20px;
    font-weight: 400;
    color: #8f9cc0;
    margin-bottom: 25px;
}

.main-signup-header label {
    color: #a8afc7;
}

.main-signup-header .form-control {
    color: #8f9cc0;
    font-weight: 500;
    border-width: 2px;
    border-color: #eaedf7;
}

.main-signup-header .form-control:focus {
    border-color: #e8e8f7;
    box-shadow: none;
}

.main-signup-header .form-control::placeholder {
    font-weight: 400;
    color: #a8afc7;
}

.main-signup-header>.btn,
.main-signup-header .sp-container button {
    margin-top: 25px;
}

.sp-container .main-signup-header>button {
    margin-top: 25px;
}

.main-signup-header .row {
    margin-top: 20px;
}

.main-signup-header .row>div .btn i,
.main-signup-header .row>div .sp-container button i {
    font-size: 15px;
    line-height: 0;
    margin-right: 5px;
}

.sp-container .main-signup-header .row>div button i {
    font-size: 15px;
    line-height: 0;
    margin-right: 5px;
}

.main-signup-header .row>div:first-child .btn,
.main-signup-header .row>div:first-child .sp-container button {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.sp-container .main-signup-header .row>div:first-child button {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.main-signup-header .row>div:first-child .btn:hover,
.main-signup-header .row>div:first-child .sp-container button:hover {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.sp-container .main-signup-header .row>div:first-child button:hover {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.main-signup-header .row>div:first-child .btn:focus,
.main-signup-header .row>div:first-child .sp-container button:focus {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.sp-container .main-signup-header .row>div:first-child button:focus {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.main-signup-footer p {
    color: #a8afc7;
    margin-bottom: 0;
}

.main-signup-footer a {
    color: #8f9cc0;
    font-weight: 700;
}

.main-error-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    text-align: center;
}

.main-error-wrapper h1 {
    line-height: 1;
    font-size: 8rem;
    font-weight: 700;
    color: #8f9cc0;
}

.main-error-wrapper h2 {
    font-weight: 500;
    color: #3b4863;
    letter-spacing: -0.5px;
    margin-bottom: 15px;
}

.main-error-wrapper h6 {
    margin-bottom: 40px;
    font-size: 14px;
}

.main-error-wrapper .btn,
.main-error-wrapper .sp-container button {
    border-width: 2px;
    font-weight: 400;
}

.sp-container .main-error-wrapper button {
    border-width: 2px;
    font-weight: 400;
}

.error-bg {
    /* background: url(../img/pngs/bg.png); */
    background-position: center;
    background-size: cover;
    min-height: calc(100vh - 165px);
}

@media (min-width: 1300px) {
    .signpages {
        width: 50%;
        border-radius: 6px;
        margin: 5em auto;
        display: flex;
    }
}

@media (max-width: 1299px) and (min-width: 991px) {
    .signpages {
        width: 60%;
        border-radius: 6px;
        margin: 5em auto;
        display: flex;
    }
}

@media (max-width: 990px) {
    .signpages {
        width: 60%;
        border-radius: 6px;
        margin: 5em auto;
        display: flex;
    }
}

.signpages .details {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    /* background: url(../img/pngs/bg.png); */
    position: relative;
    background-size: cover;
    background-position: center;
}

.construction {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    /* background: url(../img/pngs/bg.png); */
    position: relative;
    background-size: cover;
    background-position: center;
    z-index: 0;
}

.construction:before {
    content: "";
    height: 100%;
    width: 100%;
    background: var(--primary-bg-color);
    position: absolute;
    left: 0;
    z-index: -1;
    top: 0;
}

.signpages .details:before {
    content: "";
    height: 100%;
    width: 100%;
    background: #192f53 !important;
    position: absolute;
    left: 0;
    z-index: 0;
}

.signpages .row>h2 {
    color: #008080;
}

.signpages .login_form {
    background-color: #ffffff;
    /* border-top-right-radius: 6px;
    border-bottom-right-radius: 6px; */
}

.signpages .form__input {
    width: 100%;
    border: 0px solid transparent;
    border-radius: 0;
    border-bottom: 1px solid #aaa;
    padding: 1em 0.5em 0.5em;
    padding-left: 2em;
    outline: none;
    margin: 1.5em auto;
    transition: all 0.5s ease;
}

.signpages .form__input:focus {
    border-bottom-color: #008080;
    box-shadow: 0 0 5px rgba(0, 80, 80, 0.4);
    border-radius: 4px;
}

@media screen and (max-width: 640px) {
    .signpages {
        width: 90%;
    }

    .signpages .company__info {
        display: none;
    }

    .signpages .login_form {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}

@media screen and (min-width: 642px) and (max-width: 800px) {
    .signpages .main-content {
        width: 70%;
    }
}

.error-bg .sidemenu-height .construction1 {
    margin-top: 10rem;
}

.error-1 .demo-icon {
    position: absolute;
    left: auto;
    right: 60px;
    top: 60px;
    z-index: 1;
}

.error-1 .nav-link.icon {
    font-size: 20px;
    color: #4a4d51;
    cursor: pointer;
    line-height: 0.9;
    padding: 0.6rem 0.6rem;
    border-radius: 50%;
    margin: 0 5px;
}

.error-1 .menu-styles,
.error-1 .menu-colors,
.error-1 .header-colors,
.error-1 .layout-positions,
.error-1 .vertical-switcher {
    display: none;
}

@media (max-width: 991px) {
    .error-1 .demo-icon {
        right: 30px !important;
        top: 30px !important;
    }

    .error-logo-light {
        display: none;
    }

    .error-logo {
        display: block;
    }
}

.error-1 .bg-primary .nav-link.icon {
    color: #ffffff;
}

/* ######  Error  ###### */
/* ###### Invoice  ###### */
.main-content-left-invoice {
    display: block;
    padding: 0;
}

@media (max-width: 991.98px) {
    .main-content-left-invoice {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .main-content-left-invoice {
        border-right: 1px solid #e8e8f7;
    }
}

.main-invoice-list {
    position: relative;
    border-top: 1px solid #e8e8f7;
}

.main-invoice-list .media {
    align-items: center;
    border: 1px solid transparent;
    border-right-width: 0;
    padding: 10px 20px;
    position: relative;
}

.main-invoice-list .media:hover,
.main-invoice-list .media:focus {
    cursor: pointer;
    background-color: #f1f2f9;
}

.main-invoice-list .media+.media {
    margin-top: -1px;
}

.main-invoice-list .media+.media::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 55px;
    right: 0;
    border-top: 1px dotted #eaedf7;
}

.main-invoice-list .media-icon {
    width: 24px;
    text-align: center;
    font-size: 32px;
    color: #a8afc7;
}

.main-invoice-list .media-body {
    margin-left: 15px;
}

.main-invoice-list .media-body h6 {
    font-weight: 500;
    color: #8f9cc0;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-invoice-list .media-body div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-invoice-list .media-body p {
    margin-bottom: 0;
    font-size: 11px;
    font-family: Arial, sans-serif;
    color: #3b4863;
}

.main-invoice-list .media-body p span {
    color: #a8afc7;
}

.main-invoice-list .selected {
    background-color: #f1f2f9;
    border-top-color: #eaedf7;
    border-bottom-color: #eaedf7;
}

.main-invoice-list .selected:first-child {
    border-top-color: transparent;
}

@media (min-width: 992px) {
    .main-invoice-list {
        height: calc(100% - 65px);
    }
}

@media (min-width: 992px) {
    .main-invoice-list .media {
        padding: 10px 15px;
    }
}

.main-content-body-invoice {
    position: relative;
    display: none;
}

@media (min-width: 992px) {
    .main-content-body-invoice {
        display: block;
    }
}

.card-invoice {
    background-color: transparent;
    border-width: 0;
}

.card-invoice .card-body {
    padding: 20px;
}

@media (min-width: 992px) {
    .card-invoice .card-body {
        padding: 0 50px 50px;
    }
}

@media (min-width: 768px) {
    .invoice-header {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
    }
}

.invoice-title {
    margin-bottom: 0;
    text-transform: uppercase;
    color: #eaedf7;
    font-weight: 700;
}

.billed-from {
    margin-top: 25px;
    margin-bottom: 25px;
}

.billed-from h6 {
    color: #8f9cc0;
    font-size: 14px;
    font-weight: 700;
}

.billed-from p {
    font-size: 13px;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .billed-from {
        margin-top: 0;
    }
}

.billed-to h6 {
    color: #8f9cc0;
    font-size: 14px;
    font-weight: 700;
}

.billed-to p {
    font-size: 13px;
    margin-bottom: 0;
}

.invoice-info-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    margin-bottom: 0;
    font-size: 13px;
}

.invoice-info-row+.invoice-info-row {
    border-top: 1px dotted #eaedf7;
}

.invoice-info-row span:first-child {
    color: #3c4858;
}

.table-invoice thead>tr>th,
.table-invoice thead>tr>td {
    font-size: 11px;
    font-weight: 700;
}

.table-invoice tbody>tr>th:first-child,
.table-invoice tbody>tr>td:first-child {
    color: #76769c;
    font-weight: 500;
}

.table-invoice tbody>tr>th:first-child .invoice-notes,
.table-invoice tbody>tr>td:first-child .invoice-notes {
    margin-right: 20px;
}

.table-invoice tbody>tr>th:first-child .invoice-notes .section-label-sm,
.table-invoice tbody>tr>td:first-child .invoice-notes .section-label-sm {
    font-weight: 600;
}

.table-invoice tbody>tr>th:first-child .invoice-notes p,
.table-invoice tbody>tr>td:first-child .invoice-notes p {
    font-size: 13px;
    font-weight: 400;
    color: #8f9cc0;
}

.main-content-left-invoice .main-content-breadcrumb,
.main-content-left-invoice .main-content-title {
    padding-left: 20px;
}

@media (min-width: 992px) {

    .main-content-left-invoice .main-content-breadcrumb,
    .main-content-left-invoice .main-content-title {
        padding-left: 0;
    }
}

/* ###### Invoice  ###### */
/* ###### Mail  ###### */
.main-content-mail .container,
.main-content-mail .container-fluid {
    padding: 20px 0 0;
}

.main-mail-list-items {
    height: 560px;
}

@media (min-width: 992px) {

    .main-content-mail .container,
    .main-content-mail .container-fluid {
        padding: 0;
    }
}

.main-content-left-mail {
    display: block;
}

.main-content-left-mail .btn-compose {
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    padding: 0 20px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
}

@media (min-width: 992px) {
    .main-content-left-mail .btn-compose {
        margin-right: 0;
    }
}

.main-task-menu {
    position: relative;
    padding-right: 0;
}

.main-task-menu .nav-link {
    height: 38px;
}

.main-task-menu .nav-link i {
    font-size: 22px;
}

@media (min-width: 992px) {
    .main-task-menu {
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .main-task-menu .nav-link {
        font-size: 13px;
    }
}

.main-mail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 20px;
}

.main-mail-header>div:first-child p {
    font-size: 13px;
    margin-bottom: 0;
}

.main-mail-header>div:last-child {
    display: none;
}

.main-mail-header>div:last-child>span {
    font-size: 12px;
    margin-right: 10px;
}

.main-mail-header .btn-group .btn,
.main-mail-header .btn-group .sp-container button {
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    line-height: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    border-color: #e2e8f5;
    background-color: #ffffff;
}

@media (min-width: 992px) {
    .main-mail-header {
        padding: 20px 20px 0 20px;
        margin-bottom: 25px;
    }
}

@media (min-width: 768px) {
    .main-mail-header>div:last-child {
        display: flex;
        align-items: center;
    }
}

.sp-container .main-mail-header .btn-group button {
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    line-height: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    border-color: #e2e8f5;
    background-color: #ffffff;
}

.main-mail-header .btn-group .btn:hover,
.main-mail-header .btn-group .sp-container button:hover {
    color: #1c273c;
    background-color: #f6f9fd;
}

.sp-container .main-mail-header .btn-group button:hover {
    color: #1c273c;
    background-color: #f6f9fd;
}

.main-mail-header .btn-group .btn:focus,
.main-mail-header .btn-group .sp-container button:focus {
    color: #1c273c;
    background-color: #f6f9fd;
}

.sp-container .main-mail-header .btn-group button:focus {
    color: #1c273c;
    background-color: #f6f9fd;
}

.main-mail-header .btn-group .btn.disabled,
.main-mail-header .btn-group .sp-container button.disabled {
    background-color: #ffffff;
    color: #e2e8f5;
    border-color: #e2e8f5;
    z-index: 0;
}

.sp-container .main-mail-header .btn-group button.disabled {
    background-color: #ffffff;
    color: #e2e8f5;
    border-color: #e2e8f5;
    z-index: 0;
}

.main-mail-header .btn-group .btn.disabled:focus,
.main-mail-header .btn-group .sp-container button.disabled:focus {
    box-shadow: none;
}

.sp-container .main-mail-header .btn-group button.disabled:focus {
    box-shadow: none;
}

.main-mail-header .btn-group .btn.disabled:active,
.main-mail-header .btn-group .sp-container button.disabled:active {
    box-shadow: none;
}

.sp-container .main-mail-header .btn-group button.disabled:active {
    box-shadow: none;
}

.main-mail-header .btn-group .btn+.btn,
.main-mail-header .btn-group .sp-container button+.btn {
    margin-left: -2px;
}

.sp-container .main-mail-header .btn-group button+.btn,
.main-mail-header .btn-group .sp-container .btn+button,
.sp-container .main-mail-header .btn-group .btn+button,
.main-mail-header .btn-group .sp-container button+button,
.sp-container .main-mail-header .btn-group button+button {
    margin-left: -2px;
}

.main-mail-options {
    padding: 5px 10px 5px 20px;
    border: 1px solid #e2e8f5;
    border-left-width: 0;
    border-right-width: 0;
    display: none;
    align-items: center;
    justify-content: flex-end;
}

.main-mail-options .btn,
.main-mail-options .sp-container button {
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: transparent;
}

@media (min-width: 992px) {
    .main-mail-options {
        padding: 19px 20px;
        display: flex;
        justify-content: space-between;
    }
}

.sp-container .main-mail-options button {
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: transparent;
}

.main-mail-options .btn:hover,
.main-mail-options .sp-container button:hover {
    background-color: #f6f9fd;
}

.sp-container .main-mail-options button:hover {
    background-color: #f6f9fd;
}

.main-mail-options .btn:focus,
.main-mail-options .sp-container button:focus {
    background-color: #f6f9fd;
}

.sp-container .main-mail-options button:focus {
    background-color: #f6f9fd;
}

.main-mail-options .btn i,
.main-mail-options .sp-container button i {
    line-height: 0;
}

.sp-container .main-mail-options button i {
    line-height: 0;
}

.main-mail-options .btn i.typcn,
.main-mail-options .sp-container button i.typcn {
    line-height: 0.75;
}

.sp-container .main-mail-options button i.typcn {
    line-height: 0.75;
}

.main-mail-options .btn.disabled,
.main-mail-options .sp-container button.disabled {
    background-color: transparent;
    color: #7987a1;
}

.sp-container .main-mail-options button.disabled {
    background-color: transparent;
    color: #7987a1;
}

@media (min-width: 992px) {
    .main-mail-list {
        border-top-width: 0;
    }
}

.main-mail-item {
    padding: 10px 27px;
    border-top: 1px solid #e4ebf7;
    border-bottom: 1px solid #e4ebf7;
    background-color: rgba(189, 205, 228, 0.1);
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.main-mail-item+.main-mail-item {
    margin-top: -1px;
}

.main-mail-item:first-child {
    border-top-width: 0;
}

.main-mail-item .main-img-user,
.main-mail-item .main-avatar {
    flex-shrink: 0;
    margin-right: 15px;
}

.main-mail-item .main-img-user::after,
.main-mail-item .main-avatar::after {
    display: none;
}

.main-mail-item:hover,
.main-mail-item:focus {
    background-color: #f6f9fd;
}

.main-mail-item.unread {
    background-color: #ffffff;
}

.main-mail-item.selected {
    background-color: white;
}

@media (min-width: 576px) {
    .main-content-body-mail1 .main-mail-item {
        padding: 10px 29px;
    }
}

@media (min-width: 992px) {
    .main-mail-item {
        width: auto;
        padding: 10px 1.5rem 10px 1.5rem;
    }
}

.main-mail-checkbox {
    margin-right: 15px;
    display: none;
}

@media (min-width: 992px) {
    .main-mail-checkbox {
        display: block;
    }
}

.main-mail-star {
    margin-right: 15px;
    font-size: 18px;
    line-height: 0.9;
    color: #e2e8f5;
    position: absolute;
    bottom: 10px;
    right: 0;
}

.main-mail-star.active {
    color: #f0ad4e;
}

@media (min-width: 992px) {
    .main-mail-star {
        position: relative;
        bottom: auto;
        top: 2px;
    }
}

.main-mail-body {
    width: calc(100% - 80px);
    cursor: pointer;
    max-width: 590px;
}

@media (min-width: 992px) {
    .main-mail-body {
        max-width: 460px;
        margin-right: 15px;
        flex: 1;
    }
}

@media (min-width: 1200px) {
    .main-mail-body {
        max-width: 690px;
    }

    .horizontalMenucontainer .tasks-list-box .main-mail-body,
    .tasks-list-box .main-mail-body {
        max-width: 811px;
    }
}

.main-mail-from {
    font-size: 13px;
}

@media (min-width: 576px) {
    .main-mail-subject {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
}

.main-mail-subject strong {
    font-weight: 700;
    font-size: 15px;
    color: #1c273c;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

@media (min-width: 992px) {
    .main-mail-subject strong {
        display: inline;
        width: auto;
        white-space: normal;
        text-overflow: inherit;
        overflow: visible;
    }

    .task-actions {
        font-size: 16px;
        display: flex;
        list-style-type: none;
        padding: 0;
        float: right;
        margin-left: auto;
        margin-bottom: 0;
    }
}

@media (min-width: 991px) {
    .responsive-logo {
        display: none;
    }
}

@media (max-width: 991px) {
    .responsive-logo {
        display: block;
    }
}

@media (max-width: 991px) {
    .main-mail-subject strong {
        display: block;
        width: 100%;
        white-space: normal;
        text-overflow: inherit;
        overflow: visible;
    }

    .main-header-center .input-group {
        display: none;
    }

    .mail-container .tab-list-items.task-menu-show,
    .mail-container .tab-list-items.mail-menu-show {
        position: absolute;
        z-index: 12;
        width: 265px;
        height: 638px;
        left: 0;
        background: #ffffff;
        display: block;
        padding-left: 27px;
        box-shadow: 12px 7px 13px -3px #b4bfd8;
    }

    .task-actions {
        font-size: 16px;
        list-style-type: none;
        padding: 0;
        float: right;
        margin-left: 0;
        margin-bottom: 0;
        display: flex;
        width: 100%;
    }

    .task-actions li .task-action-items {
        line-height: 30px;
        display: flex;
    }

    .task-actions li .task-action-items i {
        color: var(--primary-bg-color);
        border: 1px solid #e0e7f3;
        height: 33px;
        width: 33px;
        text-align: center;
        padding: 0 !important;
        border-radius: 5px;
        padding: 0;
        line-height: 1.62;
        margin: 12px 0 13px 0;
    }
}

#no-item-found {
    display: none;
}

#no-item-found.show {
    display: block;
}

.main-mail-subject span {
    font-size: 13px;
    color: #5c6980;
    display: none;
}

@media (min-width: 576px) {
    .main-mail-subject span {
        display: inline;
    }
}

.main-mail-attachment {
    margin-right: 15px;
    font-size: 21px;
    line-height: 0.9;
    display: none;
}

@media (min-width: 992px) {
    .main-mail-attachment {
        display: block;
        position: absolute;
        right: 63px;
        top: 8px;
    }
}

.card-box img {
    position: absolute;
    bottom: -3px;
    left: -17px;
    width: inherit;
}

.main-mail-date {
    font-size: 11px;
    position: absolute;
    top: 12px;
    right: 15px;
    color: #97a3b9;
    margin-left: auto;
}

@media (min-width: 992px) {
    .main-mail-date {
        position: absolute;
        font-size: 13px;
        top: 8px;
    }
}

.main-mail-compose {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(28, 39, 60, 0.5);
    z-index: 1000;
    display: none;
}

.main-mail-compose>div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@media (min-width: 992px) {
    .main-mail-compose .container {
        padding: 0;
    }
}

.main-mail-compose-box {
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.2);
    border-radius: 3px;
}

.main-mail-compose-header {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1c273c;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.main-mail-compose-header .nav-link {
    color: rgba(255, 255, 255, 0.3);
    font-size: 14px;
    line-height: 1;
    padding: 0;
    transition: all 0.2s ease-in-out;
}

.main-mail-compose-header .nav-link:hover,
.main-mail-compose-header .nav-link:focus {
    color: #ffffff;
}

.main-mail-compose-header .nav-link+.nav-link {
    margin-left: 15px;
}

.main-mail-compose-header .nav-link:nth-child(2) {
    display: none;
}

@media (min-width: 992px) {
    .main-mail-compose-header {
        padding: 20px 25px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .main-mail-compose-header .nav-link {
        transition: none;
    }
}

@media (min-width: 768px) {
    .main-mail-compose-header .nav-link:nth-child(2) {
        display: block;
    }
}

.main-mail-compose-body {
    background-color: #ffffff;
    padding: 20px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.main-mail-compose-body .form-group {
    display: flex;
    align-items: center;
}

.main-mail-compose-body .form-group>div {
    flex: 1;
    margin-left: 10px;
}

.main-mail-compose-body .form-group .form-label {
    margin: 0;
    color: #1c273c;
}

.main-mail-compose-body .form-group .form-control {
    border-width: 0;
    border-radius: 0;
    padding: 0;
}

.main-mail-compose-body .form-group .form-control:focus {
    box-shadow: none !important;
}

.main-mail-compose-body .form-group+.form-group {
    border-top: 1px dotted #e2e8f5;
    padding-top: 1rem;
}

.main-mail-compose-body .form-group:last-child {
    display: block;
}

.main-mail-compose-body .form-group:last-child .btn,
.main-mail-compose-body .form-group:last-child .sp-container button {
    width: 100%;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 992px) {
    .main-mail-compose-body {
        padding: 25px;
    }
}

@media (min-width: 576px) {
    .main-mail-compose-body .form-group:last-child {
        display: flex;
        justify-content: space-between;
    }
}

.sp-container .main-mail-compose-body .form-group:last-child button {
    width: 100%;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 576px) {

    .main-mail-compose-body .form-group:last-child .btn,
    .main-mail-compose-body .form-group:last-child .sp-container button {
        width: auto;
        margin-top: 0;
    }

    .sp-container .main-mail-compose-body .form-group:last-child button {
        width: auto;
        margin-top: 0;
    }
}

.main-mail-compose-body .form-group .nav-link {
    padding: 0;
    font-size: 18px;
    line-height: 0;
    color: var(--primary-bg-color);
    position: relative;
    transition: all 0.2s ease-in-out;
}

.main-mail-compose-body .form-group .nav-link:hover,
.main-mail-compose-body .form-group .nav-link:focus {
    color: #1c273c;
}

.main-mail-compose-body .form-group .nav-link+.nav-link {
    margin-left: 15px;
}

@media (prefers-reduced-motion: reduce) {
    .main-mail-compose-body .form-group .nav-link {
        transition: none;
    }
}

.main-mail-compose-compress,
.main-mail-compose-minimize {
    top: auto;
    left: auto;
    bottom: 0;
    right: 30px;
    width: 560px;
    height: auto;
    background-color: transparent;
}

.main-mail-compose-compress .container {
    max-width: none;
    padding: 0;
}

.main-mail-compose-minimize .container {
    max-width: none;
    padding: 0;
}

.main-mail-compose-minimize .main-mail-compose-body {
    display: none;
}

.main-mail-two .main-content {
    display: flex;
    flex-direction: column;
}

.main-mail-two .main-header {
    width: 100%;
    border-bottom: 1px solid #e8e8f7;
}

.main-mail-two .main-footer {
    width: 100%;
}

.main-mail-two .main-header-menu-icon {
    margin-right: 0;
}

.main-mail-two .main-content-body {
    display: flex;
    padding: 0;
}

.main-mail-left {
    background-color: #f9f9f9;
    width: 240px;
    border-right: 1px solid #e8e8f7;
    padding: 20px;
    display: none;
}

.main-mail-left .btn-compose {
    display: block;
    margin-bottom: 20px;
}

.main-mail-left .main-task-menu {
    padding-right: 0;
}

.main-mail-left .main-mail-menu {
    padding-right: 0;
}

@media (min-width: 1200px) {
    .main-mail-left {
        display: block;
    }
}

.main-mail-content {
    background-color: #f4f5fb;
    flex: 1;
    max-width: 100vw -480px;
}

.main-mail-content .main-mail-header {
    margin-bottom: 0;
    padding: 20px;
}

.main-content-body-show .main-header-mail-arrow {
    font-size: 27px;
    display: inline-block;
    line-height: 0;
    padding: 13px 31px;
    background: #f1f5ff;
    width: 100%;
    font-size: 30px;
}

.main-mail-content-body {
    display: none;
}

.main-content-body-show .main-mail-content-body {
    display: block;
}

.mail-option {
    display: inline-block;
    margin-bottom: 10px;
    width: 100%;
}

ul.inbox-pagination {
    float: right;
    list-style-type: none;
    display: flex;
}

.table-inbox tr td .fa-bookmark {
    color: #e4e4ed;
}

.inbox-pagination li span {
    display: inline-block;
    margin-right: 5px;
    margin-top: 10px;
}

.inbox-pagination a.np-btn {
    border: 1px solid #ebecf1;
    border-radius: 3px !important;
    display: inline-block;
    padding: 0.375rem 0.75rem;
    margin-left: 5px;
    vertical-align: middle;
    min-height: 0;
}

.inbox-pagination a.np-btn i {
    line-height: 0;
    vertical-align: middle;
}

.mail-option .chk-all,
.mail-option .btn-group a.btn {
    border: 1px solid #ebecf1;
    border-radius: 3px !important;
    display: inline-block;
    min-height: 0;
    /* padding: 5px 10px; */
}

.table-inbox {
    border: 1px solid #ebecf1;
    margin-bottom: 0;
}

.table-inbox tr td {
    padding: 12px !important;
    vertical-align: middle;
    border-top: 1px solid #ebecf1;
}

.table-inbox tr td .fa-star {
    color: #e4e4ed;
    font-size: 16px;
}

.table-inbox tr td .fa-star.inbox-started,
.table-inbox tr td .fa-star:hover {
    color: #e6e9f1;
}

.mail-option .dropdown-menu>li>a {
    display: block;
    padding: 0.5rem 0.75rem;
    clear: both;
    font-weight: 400;
    line-height: 1.428571429;
    color: #8f9cc0;
    white-space: nowrap;
}

.mail-option .dropdown-menu>li>a:hover {
    background: #f1f2f9;
    color: var(--primary-bg-color);
}

/* ###### Mail  ###### */
/* ###### Profile  ###### */
.profile-cover__action {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    padding: 216px 30px 10px 185px;
    border-radius: 5px 5px 0 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    overflow: hidden;
    /* background: url(../img/media/bg-img.jpg) no-repeat; */
    background-size: cover;
}

.profile-cover__action>.btn {
    margin-left: 10px;
    margin-bottom: 10px;
}

.profile-cover__img {
    position: absolute;
    left: 50px;
    top: 170px;
    text-align: center;
    z-index: 1;
}

.profile-cover__img>img {
    max-width: 120px;
    border: 5px solid #ffffff;
    border-radius: 50%;
}

.profile-cover__img>.h3 {
    color: #393939;
    font-size: 20px;
    line-height: 30px;
}

.profile-cover__img>img+.h3 {
    margin-top: 6px;
}

@media (min-width: 601px) {
    .profile-cover__info .nav {
        margin-right: 28px;
        padding: 15px 0 10px 170px;
        color: #999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-end;
    }
}

.profile-cover__info .nav li {
    margin-top: 13px;
    margin-bottom: 13px;
    font-size: 14px;
    color: #464461;
    font-weight: 500;
}

.profile-cover__info .nav li:not(:first-child) {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid rgba(238, 238, 238, 0.2);
}

.profile-cover__info .nav strong {
    display: block;
    margin-bottom: 10px;
    font-size: 29px;
}

.profile-tab .main-nav-line .nav-link {
    font-weight: 500;
}

@media (min-width: 481px) {
    .profile-cover__action>.btn {
        min-width: 125px;
    }

    .profile-cover__action>.btn>span {
        display: inline-block;
    }
}

@media (max-width: 600px) {
    .profile-cover__info .nav {
        display: flex;
        padding-left: 0;
        padding-right: 0;
        margin-right: 28px;
        padding: 39px 0 10px 170px;
        margin-right: 0;
        padding: 0;
        display: flex;
        margin-right: 0;
        padding: 18px 0 10px 0;
        color: #999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        -ms-flex-pack: end;
        -webkit-box-pack: end;
        justify-content: flex-start;
    }

    .profile-cover__info .nav li {
        margin-top: 27px;
        margin-bottom: 0;
        margin-right: 1rem;
        font-size: 13px;
    }

    .profile-cover__info .nav li:not(:first-child) {
        margin-top: 8px;
        margin-left: 0;
        padding-top: 0;
        padding-left: 0;
        border-top: 1px solid #eee;
        border-left-width: 0;
        margin-top: 8px;
        margin-left: 0;
        padding-top: 18px;
        padding-left: 0;
        border-top: 0;
        border-left-width: 0;
    }

    .main-nav-line .nav-link+.nav-link {
        margin-top: 13px;
        margin-left: 0;
    }
}

.main-content-profile {
    flex: 1;
}

@media (max-width: 991.98px) {

    .main-content-profile .container,
    .main-content-profile .container-fluid {
        display: block;
    }
}

.main-content-left-profile {
    padding-left: 0;
    padding-right: 0;
    display: block;
    border-bottom: 1px solid #e8e8f7;
    padding-bottom: 25px;
    width: auto;
}

@media (min-width: 992px) {
    .main-content-left-profile {
        width: 270px;
        padding-right: 20px;
        padding-bottom: 0;
        border-right: 1px solid #e8e8f7;
        border-bottom: 0;
    }
}

@media (min-width: 1200px) {
    .main-content-left-profile {
        padding-right: 25px;
    }
}

.main-profile-overview .main-img-user {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    margin: 0 auto;
}

.main-profile-overview .main-img-user::after {
    display: none;
}

.main-profile-overview .btn-icon-list .btn,
.main-profile-overview .btn-icon-list .sp-container button {
    border-radius: 100%;
}

.sp-container .main-profile-overview .btn-icon-list button {
    border-radius: 100%;
}

.main-profile-name {
    color: #8f9cc0;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 2px;
}

.main-profile-name-text {
    color: #a8afc7;
    font-size: 13px;
    margin-bottom: 0;
}

.main-profile-bio {
    font-size: 13px;
    margin-bottom: 20px;
}

.main-profile-social-list .media {
    align-items: center;
}

.main-profile-social-list .media+.media {
    margin-top: 20px;
}

.main-profile-social-list .media-body {
    margin-left: 20px;
}

.main-profile-social-list .media-body span {
    display: block;
    font-size: 12px;
}

.main-profile-social-list .media-body a {
    font-size: 13px;
}

.main-content-body-profile .nav {
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    border-bottom: 1px solid #e8e8f7;
}

.main-content-body-profile .main-nav-line .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: #3b4863;
}

.main-content-body-profile .main-nav-line .nav-link.active {
    font-weight: 500;
}

@media (min-width: 576px) {
    .main-content-body-profile .nav {
        flex-direction: row;
        align-items: center;
        padding-bottom: 10px;
    }
}

@media (min-width: 992px) {
    .main-content-body-profile .nav {
        padding-left: 20px;
    }
}

@media (min-width: 1200px) {
    .main-content-body-profile .nav {
        padding-left: 25px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .main-content-body-profile .main-nav-line .nav-link.active::before {
        width: auto;
        top: auto;
        left: 0;
        right: 0;
        height: 2px;
    }
}

@media (min-width: 576px) {
    .main-content-body-profile .main-nav-line .nav-link.active::before {
        bottom: -11px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .main-content-body-profile .main-nav-line .nav-link+.nav-link {
        margin-top: 0;
    }
}

.main-profile-body {
    padding: 15px 0 0;
}

@media (min-width: 576px) {
    .main-profile-body {
        padding-top: 20px;
    }
}

@media (min-width: 992px) {
    .main-profile-body {
        padding: 25px 0 0 20px;
    }
}

@media (min-width: 1200px) {
    .main-profile-body {
        padding-left: 25px;
    }
}

.main-profile-view-chart {
    position: relative;
    width: calc(100% - 10px);
    height: 200px;
}

@media (min-width: 375px) {
    .main-profile-view-chart {
        width: 100%;
    }
}

@media (min-width: 576px) {
    .main-profile-view-chart {
        height: 250px;
    }
}

.main-profile-view-info {
    position: absolute;
    top: 0;
    left: 0;
}

.main-profile-view-info h6 {
    font-size: 32px;
    font-weight: 500;
    color: #8f9cc0;
    margin-bottom: 0;
}

.main-profile-view-info span {
    font-size: 12px;
    color: #f10075;
    margin-left: 5px;
}

.main-profile-view-info p {
    font-size: 13px;
    margin-bottom: 0;
}

.main-traffic-detail-item>div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 7px;
}

.main-traffic-detail-item>div:first-child>span:first-child {
    color: #51586f;
    font-size: 14px;
}

.main-traffic-detail-item>div:first-child>span:last-child {
    font-size: 11px;
    font-weight: 700;
    color: #5e5e77;
}

.main-traffic-detail-item>div:first-child>span:last-child span {
    color: #a8afc7;
    font-weight: 400;
}

.main-traffic-detail-item+.main-traffic-detail-item {
    margin-top: 18px;
}

.main-profile-work-list .media+.media {
    margin-top: 25px;
}

.main-profile-work-list .media-body {
    margin-left: 20px;
}

.main-profile-work-list .media-body h6 {
    color: #8f9cc0;
    font-weight: 500;
    margin-bottom: 2px;
}

.main-profile-work-list .media-body span {
    display: block;
    margin-bottom: 5px;
}

.main-profile-work-list .media-body p {
    margin-bottom: 0;
    font-size: 12px;
    color: #a8afc7;
}

.main-profile-contact-list .media {
    align-items: start;
}

.main-profile-contact-list .media+.media {
    margin-top: 25px;
}

.main-profile-contact-list .media-body {
    margin-left: 25px;
}

.main-profile-contact-list .media-body span {
    font-size: 12px;
    color: #a8afc7;
    display: block;
    line-height: 1.3;
}

.main-profile-contact-list .media-body div {
    font-weight: 500;
    color: #8f9cc0;
}

.btn-profile {
    position: absolute;
    right: 50px;
    top: 180px;
}

@media (max-width: 767px) {
    .profile-cover__info .nav {
        display: flex;
        margin-right: 0;
        padding: 100px 0 10px !important;
        color: #999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        justify-content: center;
    }

    .profile-cover__info .nav li {
        margin-top: 27px;
        margin-bottom: 0;
        margin-right: 1rem;
        font-size: 13px;
    }

    .profile-cover__info .nav li:not(:first-child) {
        margin-top: 8px;
        margin-left: 0;
        padding-top: 18px;
        padding-left: 0;
        border-top: 0;
        border-left-width: 0;
    }

    .profile-cover__img {
        left: 0 !important;
        right: 0 !important;
        top: 170px;
    }

    .btn-profile {
        right: 0;
        top: 30px;
        left: 0;
        margin: 0 auto;
        text-align: center;
    }

    .main-nav-line .nav-link+.nav-link {
        margin-top: 13px;
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .profile-footer {
        display: block !important;
    }
}

@media (max-width: 400px) {

    .profile-footer .demo-avatar-group .main-img-user,
    .profile-footer .demo-avatar-group .main-avatar {
        width: 26px;
        height: 26px;
    }
}

/* ###### Profile  ###### */
/* ###### Cryptocurrencies  ###### */
.crypto-transcation.list-unstyled li {
    display: flex;
    margin: 17px;
    padding: 15px 10px;
    border: 1px solid #eaedf7;
    border-radius: 6px;
}

.toolbar .btn {
    background: #ffffff;
    color: #222;
    border: 1px solid #e7e7e7;
    border-bottom: 2px solid #ddd;
    border-radius: 4px;
    padding: 2px 17px;
}

.crypto-card {
    position: relative;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23eaedf7' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.btn-default.active {
    background: var(--primary-bg-color);
    color: #ffffff;
    border-bottom: 2px solid var(--primary-bg-hover);
}

@media (max-width: 991px) {
    .card-bitcoin .media {
        display: flex;
        align-items: flex-start;
        overflow-x: scroll;
    }
}

.card-bitcoin .media-icon {
    display: inline-table;
    text-align: center;
    line-height: 42px;
}

.card-bitcoin .media-body {
    margin-left: 20px;
    margin-bottom: 10px;
}

.card-bitcoin .row {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
}

.card-bitcoin .row>div {
    flex-shrink: 0;
}

.card-bitcoin label {
    margin-bottom: 2px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.5px;
    color: #8587a7;
}

.card-bitcoin p {
    margin-bottom: 0;
    color: #1c273c;
    font-weight: 700;
    font-size: 16px;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.crypto-wallet {
    position: relative;
}

.crypto-wallet .chart-circle-value {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 1;
    width: 130px;
    height: 130px;
    line-height: 70px;
    border-radius: 50%;
    font-size: 46px;
}

.crypto-wallet .chart-circle-value.circle-style {
    border: 2px dashed rgba(98, 89, 202, 0.7);
    background: #fcfcff;
}

.cryp-icon {
    width: 25px;
    height: 25px;
    font-size: 15px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.img-bg {
    /* background: url(../img/media/Stars.png); */
    background-position: right;
    background-size: auto;
    background-repeat: no-repeat;
}

.crypto-icon {
    width: 40px;
    height: 40px;
    font-size: 15px;
    border-radius: 50px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.buy-sell .form {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.2);
    padding: 15px 0;
    border-radius: 4px;
}

.bg-background2 {
    /* background: url(../img/media/4046534.jpg); */
    background-size: cover;
    background-position: center;
}

.bg-background2:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    display: block;
    z-index: 1;
    top: 0;
    background: var(--primary09);
}

.bg-background2 .header-text {
    position: relative;
    z-index: 1;
}

.buy-sell .form-control {
    height: 40px;
}

.buy-sell .select2-container--default .select2-selection--single {
    height: 45px;
}

.buy-sell .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 43px;
}

.buy-sell .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 3px;
}

.crypto-card .chart-wrapper {
    position: relative;
    right: 0;
    bottom: -13px;
    left: -26px;
    width: 130%;
}

.exchange-icon {
    position: absolute;
    height: 50px;
    width: 50px;
    background: #19b159;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    top: -22px;
    left: -51px;
}

.crypto-btc-usd .nav-link {
    padding: 0;
    margin: 0 3px;
    width: 33px;
    height: 28px;
    line-height: 28px;
    text-align: center;
}

.crypto-btc-usd .nav-link.active {
    background: var(--primary-bg-color);
    border-radius: 3px;
    color: #ffffff;
}

.payment-list {
    list-style-type: none;
    display: flex;
    padding-left: 0;
}

.payment-list .payment-item {
    font-size: 14px;
    text-decoration: none;
    color: #c2c6d4;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    list-style: none;
    outline: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    text-transform: capitalize;
    display: inline-block;
    margin: 0 1%;
    width: 18%;
}

.payment-list .payment-item i {
    width: 100%;
    height: 66px;
    display: block;
    border: 1px solid #e6e9f3;
    padding: 15px 0px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 30px;
    border-radius: 4px;
}

.payment-item.active i {
    border-color: #19b159;
    color: #19b159;
}

.payment-item.active span {
    color: #19b159;
}

.cryptoicon {
    width: 23px;
    height: 23px;
    font-size: 13px;
    border-radius: 50px;
    align-items: center;
    display: inline-block !important;
    justify-content: center;
    line-height: 23px;
    text-align: center;
    margin-right: 15px;
}

.payment-type {
    display: flex;
}

.payment-type input {
    display: none;
}

.payment-cards {
    position: relative;
    color: #707894;
    background-color: transparent;
    font-size: 26px;
    text-align: center;
    height: 87px;
    line-height: 46px;
    display: block;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #eaedf7;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 10px;
    border-radius: 3px;
    font-weight: 500;
}

.payment-cards img {
    position: absolute;
    top: 37px;
    opacity: 0.8;
    height: 32px;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    left: 0;
    right: 0;
}

input:checked+label.payment-cards {
    border-color: var(--primary-bg-color) !important;
    position: relative !important;
    display: block !important;
    border-width: 1px;
    color: var(--primary-bg-color);
}

.form input:checked+label:after {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 17px;
    border-style: solid;
    border-width: 0 35px 35px 0px;
    border-color: var(--primary-bg-color) var(--primary-bg-color) transparent;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}

.form input:checked+label:before {
    content: "✓";
    z-index: 999;
    position: absolute;
    top: -11px;
    right: 4px;
    font-size: 14px;
    color: #ffffff;
}

.crypto-buysell-card .nav.panel-tabs li {
    position: relative;
    z-index: 1;
    display: block;
    margin: 0;
    text-align: center;
    -webkit-flex: 1;
    -moz-flex: 1;
    -ms-flex: 1;
    flex: 1;
    line-height: 4;
    background: #ffffff;
}

.crypto-buysell-card .nav.panel-tabs li .active:before {
    position: absolute;
    top: 60px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 10px;
    border-top-color: var(--primary-bg-color);
    content: "";
    pointer-events: none;
}

.crypto-buysell-card .nav.panel-tabs li a {
    position: relative;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 4;
}

.crypto-buysell-card .nav.panel-tabs li a.active {
    background: var(--primary-bg-color);
    color: #ffffff;
}

.crypto-buysell-card .select2-container--default .select2-selection--single {
    height: 46px;
}

.crypto-buysell-card .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 45px;
}

.wallet .input-group-addon-right,
.wallet .input-group-addon {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #b7bec5;
    text-align: center;
    border: 1px solid #e3ebf3;
    border-radius: 0 0.25rem 0.25rem 0;
}

.crypto-wallet .clipboard-icon {
    background-color: var(--primary-bg-color) !important;
    border-color: var(--primary-bg-color) !important;
    border-radius: 0 4px 4px 0 !important;
    top: 0;
    right: 0;
    font-size: 14px;
    background: var(--primary-bg-color);
    border: 1px solid var(--primary-bg-color);
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 2.3 !important;
    color: #ffffff;
    position: relative;
}

.crypto-wallet .clipboard-icon:hover,
.crypto-wallet .clipboard-icon:focus {
    background-color: var(--primary-bg-color) !important;
    border-color: var(--primary-bg-color) !important;
    border-radius: 0 4px 4px 0 !important;
    top: 0;
    right: 0;
    font-size: 14px;
    background: var(--primary-bg-color);
    border: 1px solid var(--primary-bg-color);
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 2.3 !important;
    color: #ffffff;
    position: relative;
}

.wallet .clipboard-icon {
    top: 0;
    right: 0;
    position: relative;
    padding: 11px 17px;
    background: #f2f1fd;
    border-radius: 0 4px 4px 0 !important;
    border-left: 0;
    height: 46px;
}

.wallet .input-group-addon-left {
    padding: 12px 17px;
    background: #f2f1fd;
    border-radius: 4px 0 0 4px !important;
    height: 46px;
    border: 1px solid #e3ebf3;
    border-right: 0;
}

.wallet .input-group i {
    font-size: 20px;
}

.transcation-crypto .table th,
.transcation-crypto .table td {
    padding: 17px 15px;
    margin: 0 14px;
}

.transcation-crypto .table.dataTable th,
.transcation-crypto .table.dataTable td {
    border-left: 0;
}

.transcation-crypto table.dataTable thead .sorting:before,
.transcation-crypto table.dataTable thead .sorting_asc:before,
.transcation-crypto table.dataTable thead .sorting_desc:before,
.transcation-crypto table.dataTable thead .sorting_asc_disabled:before,
.transcation-crypto table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: "↑";
    top: 16px;
}

.transcation-crypto table.dataTable thead .sorting:after,
.transcation-crypto table.dataTable thead .sorting_asc:after,
.transcation-crypto table.dataTable thead .sorting_desc:after {
    right: 0.5em;
    content: "↓";
    top: 16px;
}

table.dataTable thead .sorting_asc_disabled:after,
.transcation-crypto table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: "↓";
    top: 16px;
}

@media (max-width: 767px) {
    .payment-cards img {
        top: 26px !important;
    }
}

.card-order i {
    background: #edebff;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    font-size: 21px;
}

.owl-theme:before {
    content: "";
    display: block;
    left: 0;
    background: linear-gradient(to left, transparent 0%, #eaedf7 100%);
    bottom: 0px;
    position: absolute;
    width: 100px;
    height: 100%;
    z-index: 1;
}

.owl-theme:after {
    content: "";
    display: block;
    right: 0;
    background: linear-gradient(to right, transparent 0%, #eaedf7 100%);
    bottom: 0px;
    position: absolute;
    width: 100px;
    height: 100%;
    z-index: 1;
}

.crypto-btc-usd.nav-tabs .nav-link {
    padding: 10px 0px;
    line-height: 0.5;
}

.wallet-1 .table-responsive .table> :not(caption)>*>* {
    border-bottom: 0 !important;
}

#btc_chart .apexcharts-canvas {
    width: 100% !important;
}

/* ###### Cryptocurrencies  ###### */
/* ########## Ecommerce ########## */
.ratio {
    position: absolute;
    right: -10px;
    top: 20px;
    padding: 5px 15px;
    background-color: #222222;
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #ffffff;
    border-radius: 15px 0px 0px 15px;
}

.product-timeline ul.timeline-1 {
    list-style-type: none;
    position: relative;
    padding-left: 2rem;
}

.product-timeline ul.timeline-1>li {
    margin: 21.7px 0;
    padding-left: 1rem;
}

.product-timeline ul.timeline-1>li .product-icon {
    width: 37px;
    height: 37px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    background-color: var(--primary03);
    font-size: 20px;
    line-height: 37px;
    font-size: 17px;
    color: var(--primary-bg-color);
}

.product-timeline ul.timeline-1::before {
    content: " ";
    margin: 20px 20px 0 22px;
    display: inline-block;
    position: absolute;
    left: -2px;
    height: 94%;
    z-index: 0;
    content: "";
    position: absolute;
    bottom: 0;
    border-left: 2px dotted var(--primary03);
}

.countrywise-sale .list-group-item {
    padding: 10px 0;
    border-left-width: 0;
    border-right-width: 0;
    border-style: dotted;
    border-color: #e2e8f5;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.countrywise-sale .list-group-item span {
    display: block;
    margin-left: auto;
    font-weight: 600;
    font-family: "Archivo", Arial, sans-serif;
    color: #1c273c;
}

.countrywise-sale .list-group-item p {
    margin-bottom: 0;
    margin-left: 10px;
}

.top-selling-products .product-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    line-height: 1.2;
}

.top-selling-products .product-img {
    width: 44px;
    margin-right: 1rem;
}

.top-selling-products .price,
.top-selling-products .count {
    font-size: 0.8125rem;
    color: #8094ae;
    margin-top: 5px;
}

.top-selling-products .title,
.top-selling-products .amount {
    font-size: 0.9375rem;
    color: #364a63;
    margin-bottom: 0.25rem;
}

.top-selling-products .product-price {
    margin-left: auto;
    text-align: right;
    margin-bottom: 10px;
    font-weight: 600;
}

.top-selling-products .product-img img {
    border-radius: 4px;
}

.top-selling-products .product-amount {
    float: right;
    margin-left: auto;
}

.top-selling-products .product-name {
    font-weight: 500;
}

.top-selling-products .items-sold {
    color: #8094ae;
    font-size: 11px;
}

.filter-group select.form-control {
    width: 110px;
}

.table-filter {
    padding: 5px 0 15px;
    margin-bottom: 5px;
}

.table-filter select {
    display: inline-block;
    margin-left: 5px;
}

.table-filter .btn {
    border-radius: 0 4px 4px 0;
    margin-left: -1px;
    float: right;
}

.table-filter input {
    display: inline-block;
    margin-left: 5px;
    width: 200px;
    display: inline-block;
}

.show-entries select.form-control {
    width: 65px;
    margin: 0 5px;
}

.status {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: 2px 6px 4px 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
    top: -9px;
}

.ecommerce.wizard {
    margin: 20px auto;
    background: #ffffff;
}

.ecommerce.wizard .nav-tabs {
    position: relative;
    margin: 40px auto;
    margin-bottom: 0;
    border-bottom-color: #e0e0e0;
}

.ecommerce.wizard>div.ecommerce.wizard-inner {
    position: relative;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 80%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
}

@media (min-width: 768px) {
    #checkoutsteps>.steps .number {
        background-color: #eaedf7;
        color: #9197ab;
        font-size: 20px;
        font-weight: 600;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 15px auto;
    }

    #checkoutsteps .checkoutline {
        height: 3px;
        background-color: #eaedf7;
        -webkit-transform: translateY(1.7rem);
        transform: translateY(1.7rem);
        margin: 0 11%;
        position: relative;
        z-index: 0;
        top: 26px;
    }
}

@media (max-width: 767px) {
    #checkoutsteps>.steps .number {
        background-color: #eaedf7;
        color: #9197ab;
        font-size: 15px;
        font-weight: 600;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 15px auto;
    }

    #checkoutsteps>.steps a {
        font-size: 12px !important;
    }

    #checkoutsteps .checkoutline {
        height: 3px;
        background-color: #eaedf7;
        -webkit-transform: translateY(1.7rem);
        transform: translateY(1.7rem);
        margin: 0 11%;
        position: relative;
        z-index: 0;
        top: 20px;
    }
}

#checkoutsteps>.actions a {
    display: block;
    padding: 9px 25px;
    line-height: 1.573;
    color: #ffffff;
    border-radius: 4px;
    background: var(--primary-bg-color);
}

#checkoutsteps>.actions .disabled a {
    opacity: 0.4;
}

#checkoutsteps>.steps {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 3px;
    padding: 0;
}

#checkoutsteps ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
    display: flex;
}

#checkoutsteps ul[role=tablist] li {
    display: flex;
    z-index: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-basis: auto;
    font-weight: 500;
    font-size: 1.1rem;
}

#checkoutsteps>.steps a {
    display: block;
    width: auto;
    background-color: transparent;
    color: #2d2c40;
    font-size: 16px;
    font-weight: 400;
    padding: 14px 0;
    text-decoration: none;
    border-radius: 30px;
    cursor: default;
    text-align: center;
    outline: none;
}

#checkoutsteps .actions .menu li {
    flex: inherit;
}

#checkoutsteps>.steps .current a .number {
    background: var(--primary-bg-color);
    color: #ffffff;
}

#checkoutsteps ul[role=menu] li {
    flex: inherit;
}

#checkoutsteps .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 30px;
}

#checkoutsteps .item .left {
    display: flex;
    align-items: center;
}

#checkoutsteps .item .thumb {
    display: inline-flex;
    width: 100px;
    height: 90px;
    justify-content: center;
    align-items: center;
    border: 1px solid #f2f2f2;
}

#checkoutsteps .item .purchase {
    display: inline-block;
    margin-left: 21px;
    text-decoration: none;
}

#checkoutsteps .item .price {
    font-size: 16px;
}

#checkoutsteps .checkout {
    margin-bottom: 44px;
}

#checkoutsteps .checkout .subtotal {
    margin-bottom: 18px;
}

#checkoutsteps .checkout span.heading {
    font-weight: 500;
    margin-right: 5px;
}

#checkoutsteps .total {
    float: right;
    color: var(--primary-bg-color);
}

.card-pay .tabs-menu {
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.card-pay .tabs-menu.nav {
    background: #f0f3ff;
}

.card-pay .tabs-menu.nav li {
    border-right: 1px solid #d3dfea;
}

.card-pay .tabs-menu.nav li a {
    border-right: 0;
}

.card-pay .tabs-menu div.nav-item {
    width: 33.3%;
    display: block;
}

.card-pay .tabs-menu div.nav-item a {
    padding: 0.7rem 1rem;
    display: block;
    text-align: center;
    height: 100%;
}

.card-pay .tabs-menu div.nav-item a.active {
    background: var(--primary-bg-color);
    color: #ffffff;
}

@media (min-width: 992px) {
    #checkoutsteps>.content {
        padding: 38px;
    }

    #checkoutsteps>.actions {
        padding: 20px 38px;
    }
}

.item1-links li {
    width: 100%;
    display: block;
    border-top: 1px solid #e8e8f7;
}

.item1-links a {
    padding: 0.75rem 1.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    color: #070510;
}

.item1-links a i {
    font-size: 17px;
    margin-right: 1rem;
}

.item1-links .nav-item a.active {
    background-color: var(--primary-bg-color) !important;
    color: #ffffff !important;
    border-radius: 0;
}

.item1-links .nav-link:hover,
.item1-links .nav-link:focus {
    background-color: var(--primary-bg-color);
    color: #ffffff;
    border-radius: 0;
}

.track {
    position: relative;
    background-color: #eaedf7;
    height: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    margin-top: 50px;
}

.track .step {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 25%;
    margin-top: -18px;
    text-align: center;
    position: relative;
}

.track .text {
    display: block;
    margin-top: 7px;
}

.track .step.active .text {
    font-weight: 400;
    color: #000000;
}

.track .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-radius: 100%;
    background: #eaedf7;
}

.track .step::before {
    height: 7px;
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    top: 18px;
}

.track .step.active:before {
    background: var(--primary-bg-color);
}

.track .step.active .icon {
    background: var(--primary-bg-color);
    color: #ffffff;
}

.btn-apply.coupon {
    border-radius: 0 4px 4px 0 !important;
}

.productdesc-1 img {
    margin-top: 8px;
}

.productdesc-1 .carousel-control-prev {
    top: -172px;
}

.product-grid {
    text-align: center;
}

.product-grid .product-image {
    position: relative;
    overflow: hidden;
}

.product-grid .product-image a.image {
    display: block;
}

.product-grid .product-image img {
    width: 100%;
    height: auto;
    border-radius: 6px 6px 0 0;
}

.product-image .pic-1 {
    backface-visibility: hidden;
    transition: all 0.3s ease-in 0s;
}

.product-image .pic-2 {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease-out;
}

.product-grid:hover .product-image .pic-2 {
    opacity: 0;
    left: 10px;
    top: 10px;
}

.product-grid .product-discount-label {
    color: #ffffff;
    background-color: #16b36e;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    padding: 2px 9px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.product-grid .product-like-icon {
    color: #232323;
    background: #ffffff;
    font-size: 15px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 10px;
    right: -50px;
    z-index: 2;
    transition: all 0.3s ease;
}

.product-grid .product-like-icon:hover {
    color: #ffffff;
    background: #232323;
}

.product-grid:hover .product-like-icon {
    right: 10px;
}

.product-grid .product-link {
    width: 100%;
    font-size: 0;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.3s ease;
}

.product-grid:hover .product-link {
    opacity: 1;
}

.product-grid .product-link a {
    color: #ffffff;
    background: #232323;
    font-size: 12px;
    font-weight: 600;
    width: 50%;
    height: 40px;
    padding: 10px 5px;
    margin: 0;
    border-right: 2px solid rgba(215, 215, 215, 0.15);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    display: inline-block;
    overflow: hidden;
    transition: all 0.3s ease 0.1s;
}

.product-grid .product-link a:nth-child(2n) {
    border-right: none;
}

.product-grid .product-link a:hover {
    color: #232323;
    background-color: #ffffff;
}

.product-grid .product-link a i {
    transition: all 0.3s ease;
}

.product-grid .product-link a span {
    color: #232323;
    display: block;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    transform: translateY(40px);
    transition: all 0.3s ease;
}

.product-grid .product-link a:hover i {
    transform: translateY(-30px);
}

.product-grid .product-link a:hover span {
    transform: translateY(-15px);
}

.product-content .rating {
    color: #ff9b21;
    text-align: center;
    padding-left: 0;
    margin-bottom: 0;
}

.product-grid .title {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0 0 5px;
}

.product-grid .product-content {
    text-align: center;
    padding: 20px;
}

.product-grid .title a {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #262638;
}

.product-grid .price {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    padding: 6px;
    text-align: center;
}

.product-grid .price .old-price {
    color: #909090;
    margin-right: 12px;
    text-decoration: line-through;
}

@media screen and (max-width: 990px) {
    .product-grid {
        margin: 0 0 30px;
    }
}

.product_price li {
    display: inline-block;
    padding: 0 10px;
    font-weight: 500;
    font-size: 16px;
}

.product_price .old_price {
    text-decoration: line-through !important;
    color: #a8afc7;
}

.productdesc .list-unstyled li {
    border-bottom: 0 !important;
    margin-bottom: 18px;
    display: flex;
}

.productdesc .list-unstyled li:last-child {
    margin-bottom: 0 !important;
}

#recentorders {
    padding: 0;
    max-width: 650px;
    margin: 0px auto;
}

@media (max-width: 567px) {
    .card-pay .tabs-menu li {
        width: 100%;
        border-bottom: 1px solid #e8e8f7;
    }
}

#wishlist .product-grid {
    text-align: left;
}

#wishlist .product-grid .product-content {
    text-align: left;
}

#wishlist .product-grid .title a {
    padding: 0;
}

#wishlist .product-grid .title {
    margin: 5px 0 5px;
}

#wishlist .product-grid .wishlist-icon {
    color: #ffffff;
    background: #fd6074;
    font-size: 15px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 10px;
    text-align: center;
    right: 10px;
    z-index: 9;
    transition: all 0.3s ease;
}

.item1-links.nav.nav-tabs .nav-link:hover,
.item1-links.nav.nav-tabs .nav-link:focus {
    background-color: var(--primary-bg-color);
}

@media (max-width: 380px) {
    #checkoutsteps .item .purchase {
        display: block;
        margin: 0;
        margin-top: 10px;
    }
}

/* ########## Ecommerce ########## */
/* ###### Notification List ###### */
.notify-time {
    position: absolute;
    top: auto;
    bottom: auto;
    left: auto;
    right: 25px;
}

@media (max-width: 767px) {
    .notify-time {
        display: none;
    }
}

.notification {
    list-style-type: none;
    padding: 0;
    position: relative;
}

.notification:before {
    content: "";
    position: absolute;
    top: 15px;
    bottom: 15px;
    width: 3px;
    background: var(--primary02);
    left: 20%;
    margin-left: -1px;
}

.notification>li {
    position: relative;
    min-height: 50px;
    padding: 15px 0;
}

.notification .notification-time {
    position: absolute;
    left: 0;
    width: 18%;
    text-align: right;
    top: 30px;
}

.notification .notification-time .date,
.notification .notification-time .time {
    display: block;
    font-weight: 500;
}

.notification .notification-time .date {
    line-height: 16px;
    font-size: 13px;
    margin-bottom: 4px;
    color: #1d212f;
}

.notification .notification-time .time {
    line-height: 24px;
    font-size: 21px;
    color: #1d212f;
}

.notification .notification-icon {
    left: 15%;
    position: absolute;
    width: 10%;
    text-align: center;
    top: 41px;
}

.notification .notification-icon a {
    text-decoration: none;
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 50%;
    background: #ffffff;
    line-height: 10px;
    color: #ffffff;
    font-size: 14px;
    border: 3px solid var(--primary-bg-color);
    transition: border-color 0.2s linear;
}

.notification .notification-body {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
    margin-left: 24%;
    margin-right: 18%;
    background: #ffffff;
    position: relative;
    padding: 14px 20px;
    border-radius: 6px;
}

.notification .notification-body:before {
    content: "";
    display: block;
    position: absolute;
    border: 10px solid transparent;
    border-right-color: #ffffff;
    left: -20px;
    top: 25px;
}

.notification .notification-body>div+div {
    margin-top: 15px;
}

@media (max-width: 767px) {
    .notification:before {
        display: none;
    }

    .notification .notification-time .date {
        display: none;
    }

    .notification .notification-time .time {
        display: none;
    }

    .notification .notification-icon {
        display: none;
    }

    .notification .notification-body {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.notification-time-date {
    color: #1d212f;
    font-size: 16px;
    font-weight: 500;
}

/* ###### Notification List ###### */
/* ############### UTILITIES/HELPER CLASSES ############### */
/* ###### Backgrounds ###### */
.bg-secondary {
    background-color: #f1388b !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus {
    background-color: #f1388b !important;
}

button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #f1388b !important;
}

.bg-success {
    background-color: #19b159 !important;
}

a.bg-success:hover,
a.bg-success:focus {
    background-color: #37ce4f !important;
}

button.bg-success:hover,
button.bg-success:focus {
    background-color: #37ce4f !important;
}

.bg-info {
    background-color: #01b8ff !important;
}

a.bg-info:hover,
a.bg-info:focus {
    background-color: #06aff1 !important;
}

button.bg-info:hover,
button.bg-info:focus {
    background-color: #06aff1 !important;
}

.bg-warning {
    background-color: #ff9b21 !important;
}

a.bg-warning:hover,
a.bg-warning:focus {
    background-color: #d39e00 !important;
}

button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #d39e00 !important;
}

.bg-danger {
    background-color: #f16d75 !important;
}

a.bg-danger:hover,
a.bg-danger:focus {
    background-color: #f16d75 !important;
}

button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #f16d75 !important;
}

.bg-light {
    background-color: #f4f4fd !important;
    color: #55556b !important;
}

a.bg-light:hover,
a.bg-light:focus {
    background-color: #dbe0ea !important;
}

button.bg-light:hover,
button.bg-light:focus {
    background-color: #dbe0ea !important;
}

.bg-dark {
    background-color: #3b4863 !important;
}

a.bg-dark:hover,
a.bg-dark:focus {
    background-color: #131212 !important;
}

button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #131212 !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-primary-gradient {
    background: linear-gradient(to right, var(--primary-bg-color) 0%, #71a7f5 100%);
}

.bg-success-gradient {
    background: linear-gradient(to right, #45e1d4 0%, #129ed3 100%);
}

.bg-warning-gradient {
    background: linear-gradient(to right, #f8c888 0%, #f45c6b 100%);
}

.bg-info-gradient {
    background: linear-gradient(to right, #9271fe 0%, #fb5ea3 100%);
}

.bg-white-1 {
    background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-2 {
    background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-3 {
    background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-4 {
    background-color: rgba(255, 255, 255, 0.4);
}

.bg-white-5 {
    background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-6 {
    background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-7 {
    background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-8 {
    background-color: rgba(255, 255, 255, 0.8);
}

.bg-white-9 {
    background-color: rgba(255, 255, 255, 0.9);
}

.bg-black-1 {
    background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-2 {
    background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-3 {
    background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-4 {
    background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-5 {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-6 {
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-7 {
    background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-8 {
    background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-9 {
    background-color: rgba(0, 0, 0, 0.9);
}

.bg-indigo {
    background-color: #4b0082;
}

.bg-purple {
    background-color: #6f42c1;
}

.bg-pink {
    background-color: #f1388b !important;
}

.bg-orange {
    background-color: #fd7e14;
}

.bg-teal {
    background-color: #00cccc;
}

.bg-purple-dark {
    background-color: #59339d;
}

.bg-success-transparent {
    background-color: rgba(111, 234, 163, 0.2);
}

.bg-secondary-transparent {
    background-color: rgba(240, 112, 187, 0.15);
}

.bg-primary-transparent {
    background-color: var(--primary01);
}

.bg-info-transparent {
    background-color: rgba(1, 184, 255, 0.12);
}

.bg-warning-transparent {
    background-color: rgba(243, 202, 86, 0.15);
}

.bg-danger-transparent {
    background-color: rgba(255, 71, 61, 0.12);
}

.bg-pink-transparent {
    background-color: rgba(255, 101, 165, 0.12);
}

.bg-purple-transparent {
    background-color: rgba(111, 66, 193, 0.12);
}

.bg-dark-transparent {
    background-color: rgba(28, 39, 60, 0.12);
}

.bg-white-transparent {
    background-color: rgba(255, 255, 255, 0.12);
}

.tag-outline-primary {
    color: var(--primary-bg-color) !important;
    border-color: var(--primary-bg-color) !important;
}

.tag-outline-secondary {
    color: #f1388b !important;
    border-color: #f1388b !important;
}

.tag-outline-success {
    color: #19b159 !important;
    border-color: #19b159 !important;
}

.tag-outline-warning {
    color: #ff9b21 !important;
    border-color: #ff9b21 !important;
}

.tag-outline-info {
    color: #01b8ff !important;
    border-color: #01b8ff !important;
}

.tag-outline-danger {
    color: #fd6074 !important;
    border-color: #fd6074 !important;
}

.bg-light {
    color: #55556b;
    background-color: #eaedf7;
}

a.bg-light:hover {
    color: #8f9cc0;
    background-color: #d5d9e4;
}

a.bg-light:focus {
    color: #8f9cc0;
    background-color: #d5d9e4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

a.bg-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.bg-transparent {
    background-color: transparent;
}

.bg-gray-100 {
    background-color: #eaeafb;
}

.bg-gray-200 {
    background-color: #d0d0ec;
}

.bg-gray-300 {
    background-color: #babade;
}

.bg-gray-400 {
    background-color: #8f8fb7;
}

.bg-gray-500 {
    background-color: #717196;
}

.bg-gray-600 {
    background-color: #4f4f71;
}

.bg-gray-700 {
    background-color: #3c3c5d;
}

.bg-gray-800 {
    background-color: #262644;
}

.bg-gray-900 {
    background-color: #1b1b33;
}

.bg-secondary-light {
    background-color: rgba(241, 56, 139, 0.2) !important;
    color: #f1388b;
}

.bg-warning-light {
    background-color: #fdf5e2;
    color: #ebb322;
}

.bg-danger-light {
    background-color: #ffe7e8;
    color: #f16d75;
}

/* ###### Backgrounds ###### */
/* ###### Display ###### */
.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: flex !important;
    }

    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

/* ###### Display ###### */
/* ###### Flex  ###### */
.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important;
    }

    .flex-sm-column {
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        justify-content: center !important;
    }

    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        align-items: center !important;
    }

    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    .align-content-sm-start {
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        align-content: center !important;
    }

    .align-content-sm-between {
        align-content: space-between !important;
    }

    .align-content-sm-around {
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        align-self: auto !important;
    }

    .align-self-sm-start {
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        align-self: center !important;
    }

    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    .flex-md-column {
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    .justify-content-md-between {
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        justify-content: space-around !important;
    }

    .align-items-md-start {
        align-items: flex-start !important;
    }

    .align-items-md-end {
        align-items: flex-end !important;
    }

    .align-items-md-center {
        align-items: center !important;
    }

    .align-items-md-baseline {
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        align-items: stretch !important;
    }

    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    .align-content-md-between {
        align-content: space-between !important;
    }

    .align-content-md-around {
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        align-content: stretch !important;
    }

    .align-self-md-auto {
        align-self: auto !important;
    }

    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    .align-self-md-center {
        align-self: center !important;
    }

    .align-self-md-baseline {
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important;
    }

    .flex-lg-column {
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        justify-content: center !important;
    }

    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        align-items: center !important;
    }

    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    .align-content-lg-start {
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    .align-content-lg-between {
        align-content: space-between !important;
    }

    .align-content-lg-around {
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        align-self: auto !important;
    }

    .align-self-lg-start {
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        align-self: center !important;
    }

    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important;
    }

    .flex-xl-column {
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        justify-content: center !important;
    }

    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        align-items: center !important;
    }

    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    .align-content-xl-start {
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        align-content: center !important;
    }

    .align-content-xl-between {
        align-content: space-between !important;
    }

    .align-content-xl-around {
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        align-self: auto !important;
    }

    .align-self-xl-start {
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        align-self: center !important;
    }

    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.flex-1 {
    flex: 1;
}

.flex-0 {
    flex: 0;
}

/* ###### Flex  ###### */
/* ######  Border   ###### */
.border {
    border: 1px solid #e8e8f7 !important;
}

.border-top {
    border-top: 1px solid #e8e8f7 !important;
}

.border-end {
    border-right: 1px solid #e8e8f7;
}

.border-bottom {
    border-bottom: 1px solid #e8e8f7 !important;
}

.border-start {
    border-left: 1px solid #e8e8f7;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-end-0 {
    border-right: 0;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-start-0 {
    border-left: 0;
}

.border-secondary {
    border-color: #f1388b !important;
}

.border-success {
    border-color: #19b159 !important;
}

.border-info {
    border-color: #01b8ff !important;
}

.border-warning {
    border-color: #ff9b21 !important;
}

.border-danger {
    border-color: #fd6074 !important;
}

.border-light {
    border-color: #f1f2f9 !important;
}

.border-dark {
    border-color: #3b4863 !important;
}

.border-white {
    border-color: #ffffff !important;
}

.text-pink {
    color: #f1388b !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded {
    border-radius: 3px !important;
}

.rounded-top {
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.rounded-end {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.rounded-bottom {
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rounded-start {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.rounded-top-0 {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.rounded-end-0 {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.rounded-bottom-0 {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.rounded-start-0 {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.rounded-top-11 {
    border-top-left-radius: 11px !important;
    border-top-right-radius: 11px !important;
}

.rounded-end-11 {
    border-top-right-radius: 11px !important;
    border-bottom-right-radius: 11px !important;
}

.rounded-bottom-11 {
    border-bottom-right-radius: 11px !important;
    border-bottom-left-radius: 11px !important;
}

.rounded-start-11 {
    border-top-left-radius: 11px !important;
    border-bottom-left-radius: 11px !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.bd-transparent {
    border-color: transparent;
}

.bd {
    border: 1px solid #e8e8f7;
}

.bd-t {
    border-top: 1px solid #e8e8f7;
}

.bd-r {
    border-right: 1px solid #e8e8f7;
}

.bd-b {
    border-bottom: 1px solid #e8e8f7;
}

.bd-l {
    border-left: 1px solid #e8e8f7;
}

.bd-y {
    border-top: 1px solid #e8e8f7;
    border-bottom: 1px solid #e8e8f7;
}

.bd-x {
    border-left: 1px solid #e8e8f7;
    border-right: 1px solid #e8e8f7;
}

.bd-0 {
    border-width: 0;
}

.bd-1 {
    border-width: 1px;
}

.bd-2 {
    border-width: 2px !important;
}

.bd-3 {
    border-width: 3px;
}

.bd-4 {
    border-width: 4px;
}

.bd-5 {
    border-width: 5px;
}

.bd-t-0 {
    border-top: 0;
}

.bd-r-0 {
    border-right: 0;
}

.bd-b-0 {
    border-bottom: 0;
}

.bd-l-0 {
    border-left: 0;
}

.bd-y-0 {
    border-top-width: 0;
    border-bottom-width: 0;
}

.bd-y-1 {
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.bd-x-0 {
    border-left-width: 0;
    border-right-width: 0;
}

.bd-x-1 {
    border-left-width: 1px;
    border-right-width: 1px;
}

.bd-secondary {
    border-color: #f1388b;
}

.bd-success {
    border-color: #19b159;
}

.bd-warning {
    border-color: #ff9b21;
}

.bd-danger {
    border-color: #f16d75;
}

.bd-info {
    border-color: #01b8ff;
}

.bd-white {
    border-color: #ffffff;
}

.bd-gray-100 {
    border-color: #f1f2f9;
}

.bd-gray-200,
.bd-gray-300 {
    border-color: #eaedf7;
}

.bd-gray-400 {
    border-color: #e8e8f7;
}

.bd-gray-500,
.bd-gray-600 {
    border-color: #a8afc7;
}

.bd-gray-700 {
    border-color: #3c4858;
}

.bd-gray-800 {
    border-color: #3b4863;
}

.bd-gray-900 {
    border-color: #8f9cc0;
}

.bd-white-1 {
    border-color: rgba(255, 255, 255, 0.1);
}

.bd-white-2 {
    border-color: rgba(255, 255, 255, 0.2);
}

.bd-white-3 {
    border-color: rgba(255, 255, 255, 0.3);
}

.bd-white-4 {
    border-color: rgba(255, 255, 255, 0.4);
}

.bd-white-5 {
    border-color: rgba(255, 255, 255, 0.5);
}

.bd-white-6 {
    border-color: rgba(255, 255, 255, 0.6);
}

.bd-white-7 {
    border-color: rgba(255, 255, 255, 0.7);
}

.bd-white-8 {
    border-color: rgba(255, 255, 255, 0.8);
}

.rounded-5 {
    border-radius: 5px;
}

.rounded-10 {
    border-radius: 10px;
}

.rounded-20 {
    border-radius: 20px;
}

.rounded-30 {
    border-radius: 30px !important;
}

.rounded-40 {
    border-radius: 40px;
}

.rounded-50 {
    border-radius: 50px;
}

.bd-dashed {
    border-style: dashed;
}

.bd-dotted {
    border-style: dotted;
}

@media (min-width: 480px) {
    .bd-xs {
        border: 1px solid #e8e8f7;
    }

    .bd-xs-t {
        border-top: 1px solid #e8e8f7;
    }

    .bd-xs-r {
        border-right: 1px solid #e8e8f7;
    }

    .bd-xs-b {
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-xs-l {
        border-left: 1px solid #e8e8f7;
    }

    .bd-xs-y {
        border-top: 1px solid #e8e8f7;
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-xs-x {
        border-left: 1px solid #e8e8f7;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 576px) {
    .bd-sm {
        border: 1px solid #e8e8f7;
    }

    .bd-sm-t {
        border-top: 1px solid #e8e8f7;
    }

    .bd-sm-r {
        border-right: 1px solid #e8e8f7;
    }

    .bd-sm-b {
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-sm-l {
        border-left: 1px solid #e8e8f7;
    }

    .bd-sm-y {
        border-top: 1px solid #e8e8f7;
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-sm-x {
        border-left: 1px solid #e8e8f7;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 768px) {
    .bd-md {
        border: 1px solid #e8e8f7;
    }

    .bd-md-t {
        border-top: 1px solid #e8e8f7;
    }

    .bd-md-r {
        border-right: 1px solid #e8e8f7;
    }

    .bd-md-b {
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-md-l {
        border-left: 1px solid #e8e8f7;
    }

    .bd-md-y {
        border-top: 1px solid #e8e8f7;
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-md-x {
        border-left: 1px solid #e8e8f7;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 992px) {
    .bd-lg {
        border: 1px solid #e8e8f7;
    }

    .bd-lg-t {
        border-top: 1px solid #e8e8f7;
    }

    .bd-lg-r {
        border-right: 1px solid #e8e8f7;
    }

    .bd-lg-b {
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-lg-l {
        border-left: 1px solid #e8e8f7;
    }

    .bd-lg-y {
        border-top: 1px solid #e8e8f7;
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-lg-x {
        border-left: 1px solid #e8e8f7;
        border-right: 1px solid #e8e8f7;
    }
}

@media (min-width: 1200px) {
    .bd-xl {
        border: 1px solid #e8e8f7;
    }

    .bd-xl-t {
        border-top: 1px solid #e8e8f7;
    }

    .bd-xl-r {
        border-right: 1px solid #e8e8f7;
    }

    .bd-xl-b {
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-xl-l {
        border-left: 1px solid #e8e8f7;
    }

    .bd-xl-y {
        border-top: 1px solid #e8e8f7;
        border-bottom: 1px solid #e8e8f7;
    }

    .bd-xl-x {
        border-left: 1px solid #e8e8f7;
        border-right: 1px solid #e8e8f7;
    }
}

.be-0 {
    border-radius: 0;
}

.square {
    border-radius: 0 !important;
}

.radius {
    border-radius: 5px !important;
}

/* ###### Border ###### */
/* ###### Height ###### */
.ht-5 {
    height: 5px;
}

.ht-5p {
    height: 5%;
}

.mx-ht-5p {
    max-height: 5%;
}

.mn-ht-5p {
    min-height: 5%;
}

.ht-10 {
    height: 10px;
}

.ht-10p {
    height: 10%;
}

.mx-ht-10p {
    max-height: 10%;
}

.mn-ht-10p {
    min-height: 10%;
}

.ht-15 {
    height: 15px;
}

.ht-15p {
    height: 15%;
}

.mx-ht-15p {
    max-height: 15%;
}

.mn-ht-15p {
    min-height: 15%;
}

.ht-20 {
    height: 20px;
}

.ht-20p {
    height: 20%;
}

.mx-ht-20p {
    max-height: 20%;
}

.mn-ht-20p {
    min-height: 20%;
}

.ht-25 {
    height: 25px;
}

.ht-25p {
    height: 25%;
}

.mx-ht-25p {
    max-height: 25%;
}

.mn-ht-25p {
    min-height: 25%;
}

.ht-30 {
    height: 30px;
}

.ht-30p {
    height: 30%;
}

.mx-ht-30p {
    max-height: 30%;
}

.mn-ht-30p {
    min-height: 30%;
}

.ht-35 {
    height: 35px;
}

.ht-35p {
    height: 35%;
}

.mx-ht-35p {
    max-height: 35%;
}

.mn-ht-35p {
    min-height: 35%;
}

.ht-40 {
    height: 40px;
}

.ht-40p {
    height: 40%;
}

.mx-ht-40p {
    max-height: 40%;
}

.mn-ht-40p {
    min-height: 40%;
}

.ht-45 {
    height: 45px;
}

.ht-45p {
    height: 45%;
}

.mx-ht-45p {
    max-height: 45%;
}

.mn-ht-45p {
    min-height: 45%;
}

.ht-50 {
    height: 50px;
}

.ht-50p {
    height: 50%;
}

.mx-ht-50p {
    max-height: 50%;
}

.mn-ht-50p {
    min-height: 50%;
}

.ht-55 {
    height: 55px;
}

.ht-55p {
    height: 55%;
}

.mx-ht-55p {
    max-height: 55%;
}

.mn-ht-55p {
    min-height: 55%;
}

.ht-60 {
    height: 60px;
}

.ht-60p {
    height: 60%;
}

.mx-ht-60p {
    max-height: 60%;
}

.mn-ht-60p {
    min-height: 60%;
}

.ht-65 {
    height: 65px;
}

.ht-65p {
    height: 65%;
}

.mx-ht-65p {
    max-height: 65%;
}

.mn-ht-65p {
    min-height: 65%;
}

.ht-70 {
    height: 70px;
}

.ht-70p {
    height: 70%;
}

.mx-ht-70p {
    max-height: 70%;
}

.mn-ht-70p {
    min-height: 70%;
}

.ht-75 {
    height: 75px;
}

.ht-75p {
    height: 75%;
}

.mx-ht-75p {
    max-height: 75%;
}

.mn-ht-75p {
    min-height: 75%;
}

.ht-80 {
    height: 80px;
}

.ht-80p {
    height: 80%;
}

.mx-ht-80p {
    max-height: 80%;
}

.mn-ht-80p {
    min-height: 80%;
}

.ht-85 {
    height: 85px;
}

.ht-85p {
    height: 85%;
}

.mx-ht-85p {
    max-height: 85%;
}

.mn-ht-85p {
    min-height: 85%;
}

.ht-90 {
    height: 90px;
}

.ht-90p {
    height: 90%;
}

.mx-ht-90p {
    max-height: 90%;
}

.mn-ht-90p {
    min-height: 90%;
}

.ht-95 {
    height: 95px;
}

.ht-95p {
    height: 95%;
}

.mx-ht-95p {
    max-height: 95%;
}

.mn-ht-95p {
    min-height: 95%;
}

.ht-100 {
    height: 100px !important;
}

.ht-100p {
    height: 100%;
}

.mx-ht-100p {
    max-height: 100%;
}

.mn-ht-100p {
    min-height: 100%;
}

.ht-150 {
    height: 150px;
}

.ht-200 {
    height: 200px !important;
}

.ht-250 {
    height: 250px !important;
}

.ht-300 {
    height: 300px;
}

.ht-350 {
    height: 350px;
}

.ht-400 {
    height: 400px;
}

.ht-450 {
    height: 450px;
}

.ht-500 {
    height: 500px;
}

.ht-550 {
    height: 550px;
}

.ht-600 {
    height: 600px;
}

.ht-650 {
    height: 650px;
}

.ht-700 {
    height: 700px;
}

.ht-750 {
    height: 750px;
}

.ht-800 {
    height: 800px;
}

.ht-100v {
    height: 100vh;
}

.ht-1 {
    height: 1px;
}

.ht-2 {
    height: 2px;
}

.ht-3 {
    height: 3px;
}

.ht-4 {
    height: 4px;
}

.ht-6 {
    height: 6px;
}

.ht-7 {
    height: 7px;
}

.ht-8 {
    height: 8px;
}

.ht-9 {
    height: 9px;
}

.ht-auto {
    height: auto;
}

.ht-16 {
    height: 16px;
}

.ht-32 {
    height: 32px;
}

.ht-36 {
    height: 36px;
}

.ht-48 {
    height: 48px;
}

.ht-64 {
    height: 64px;
}

.ht-72 {
    height: 72px;
}

.ht-115 {
    height: 115px;
}

.ht-120 {
    height: 120px;
}

.ht-160 {
    height: 160px;
}

@media (min-width: 480px) {
    .ht-xs-5 {
        height: 5px;
    }

    .ht-xs-5p {
        height: 5%;
    }

    .mx-ht-xs-5p {
        max-height: 5%;
    }

    .mn-ht-xs-5p {
        min-height: 5%;
    }

    .ht-xs-10 {
        height: 10px;
    }

    .ht-xs-10p {
        height: 10%;
    }

    .mx-ht-xs-10p {
        max-height: 10%;
    }

    .mn-ht-xs-10p {
        min-height: 10%;
    }

    .ht-xs-15 {
        height: 15px;
    }

    .ht-xs-15p {
        height: 15%;
    }

    .mx-ht-xs-15p {
        max-height: 15%;
    }

    .mn-ht-xs-15p {
        min-height: 15%;
    }

    .ht-xs-20 {
        height: 20px;
    }

    .ht-xs-20p {
        height: 20%;
    }

    .mx-ht-xs-20p {
        max-height: 20%;
    }

    .mn-ht-xs-20p {
        min-height: 20%;
    }

    .ht-xs-25 {
        height: 25px;
    }

    .ht-xs-25p {
        height: 25%;
    }

    .mx-ht-xs-25p {
        max-height: 25%;
    }

    .mn-ht-xs-25p {
        min-height: 25%;
    }

    .ht-xs-30 {
        height: 30px;
    }

    .ht-xs-30p {
        height: 30%;
    }

    .mx-ht-xs-30p {
        max-height: 30%;
    }

    .mn-ht-xs-30p {
        min-height: 30%;
    }

    .ht-xs-35 {
        height: 35px;
    }

    .ht-xs-35p {
        height: 35%;
    }

    .mx-ht-xs-35p {
        max-height: 35%;
    }

    .mn-ht-xs-35p {
        min-height: 35%;
    }

    .ht-xs-40 {
        height: 40px;
    }

    .ht-xs-40p {
        height: 40%;
    }

    .mx-ht-xs-40p {
        max-height: 40%;
    }

    .mn-ht-xs-40p {
        min-height: 40%;
    }

    .ht-xs-45 {
        height: 45px;
    }

    .ht-xs-45p {
        height: 45%;
    }

    .mx-ht-xs-45p {
        max-height: 45%;
    }

    .mn-ht-xs-45p {
        min-height: 45%;
    }

    .ht-xs-50 {
        height: 50px;
    }

    .ht-xs-50p {
        height: 50%;
    }

    .mx-ht-xs-50p {
        max-height: 50%;
    }

    .mn-ht-xs-50p {
        min-height: 50%;
    }

    .ht-xs-55 {
        height: 55px;
    }

    .ht-xs-55p {
        height: 55%;
    }

    .mx-ht-xs-55p {
        max-height: 55%;
    }

    .mn-ht-xs-55p {
        min-height: 55%;
    }

    .ht-xs-60 {
        height: 60px;
    }

    .ht-xs-60p {
        height: 60%;
    }

    .mx-ht-xs-60p {
        max-height: 60%;
    }

    .mn-ht-xs-60p {
        min-height: 60%;
    }

    .ht-xs-65 {
        height: 65px;
    }

    .ht-xs-65p {
        height: 65%;
    }

    .mx-ht-xs-65p {
        max-height: 65%;
    }

    .mn-ht-xs-65p {
        min-height: 65%;
    }

    .ht-xs-70 {
        height: 70px;
    }

    .ht-xs-70p {
        height: 70%;
    }

    .mx-ht-xs-70p {
        max-height: 70%;
    }

    .mn-ht-xs-70p {
        min-height: 70%;
    }

    .ht-xs-75 {
        height: 75px;
    }

    .ht-xs-75p {
        height: 75%;
    }

    .mx-ht-xs-75p {
        max-height: 75%;
    }

    .mn-ht-xs-75p {
        min-height: 75%;
    }

    .ht-xs-80 {
        height: 80px;
    }

    .ht-xs-80p {
        height: 80%;
    }

    .mx-ht-xs-80p {
        max-height: 80%;
    }

    .mn-ht-xs-80p {
        min-height: 80%;
    }

    .ht-xs-85 {
        height: 85px;
    }

    .ht-xs-85p {
        height: 85%;
    }

    .mx-ht-xs-85p {
        max-height: 85%;
    }

    .mn-ht-xs-85p {
        min-height: 85%;
    }

    .ht-xs-90 {
        height: 90px;
    }

    .ht-xs-90p {
        height: 90%;
    }

    .mx-ht-xs-90p {
        max-height: 90%;
    }

    .mn-ht-xs-90p {
        min-height: 90%;
    }

    .ht-xs-95 {
        height: 95px;
    }

    .ht-xs-95p {
        height: 95%;
    }

    .mx-ht-xs-95p {
        max-height: 95%;
    }

    .mn-ht-xs-95p {
        min-height: 95%;
    }

    .ht-xs-100 {
        height: 100px;
    }

    .ht-xs-100p {
        height: 100%;
    }

    .mx-ht-xs-100p {
        max-height: 100%;
    }

    .mn-ht-xs-100p {
        min-height: 100%;
    }

    .ht-xs-150 {
        height: 150px;
    }

    .ht-xs-150p {
        height: 150%;
    }

    .mx-ht-xs-150p {
        max-height: 150%;
    }

    .mn-ht-xs-150p {
        min-height: 150%;
    }

    .ht-xs-200 {
        height: 200px;
    }

    .ht-xs-200p {
        height: 200%;
    }

    .mx-ht-xs-200p {
        max-height: 200%;
    }

    .mn-ht-xs-200p {
        min-height: 200%;
    }

    .ht-xs-250 {
        height: 250px;
    }

    .ht-xs-250p {
        height: 250%;
    }

    .mx-ht-xs-250p {
        max-height: 250%;
    }

    .mn-ht-xs-250p {
        min-height: 250%;
    }

    .ht-xs-300 {
        height: 300px;
    }

    .ht-xs-300p {
        height: 300%;
    }

    .mx-ht-xs-300p {
        max-height: 300%;
    }

    .mn-ht-xs-300p {
        min-height: 300%;
    }

    .ht-xs-350 {
        height: 350px;
    }

    .ht-xs-350p {
        height: 350%;
    }

    .mx-ht-xs-350p {
        max-height: 350%;
    }

    .mn-ht-xs-350p {
        min-height: 350%;
    }

    .ht-xs-400 {
        height: 400px;
    }

    .ht-xs-400p {
        height: 400%;
    }

    .mx-ht-xs-400p {
        max-height: 400%;
    }

    .mn-ht-xs-400p {
        min-height: 400%;
    }

    .ht-xs-450 {
        height: 450px;
    }

    .ht-xs-450p {
        height: 450%;
    }

    .mx-ht-xs-450p {
        max-height: 450%;
    }

    .mn-ht-xs-450p {
        min-height: 450%;
    }

    .ht-xs-500 {
        height: 500px;
    }

    .ht-xs-500p {
        height: 500%;
    }

    .mx-ht-xs-500p {
        max-height: 500%;
    }

    .mn-ht-xs-500p {
        min-height: 500%;
    }

    .ht-xs-550 {
        height: 550px;
    }

    .ht-xs-550p {
        height: 550%;
    }

    .mx-ht-xs-550p {
        max-height: 550%;
    }

    .mn-ht-xs-550p {
        min-height: 550%;
    }

    .ht-xs-600 {
        height: 600px;
    }

    .ht-xs-600p {
        height: 600%;
    }

    .mx-ht-xs-600p {
        max-height: 600%;
    }

    .mn-ht-xs-600p {
        min-height: 600%;
    }

    .ht-xs-650 {
        height: 650px;
    }

    .ht-xs-650p {
        height: 650%;
    }

    .mx-ht-xs-650p {
        max-height: 650%;
    }

    .mn-ht-xs-650p {
        min-height: 650%;
    }

    .ht-xs-700 {
        height: 700px;
    }

    .ht-xs-700p {
        height: 700%;
    }

    .mx-ht-xs-700p {
        max-height: 700%;
    }

    .mn-ht-xs-700p {
        min-height: 700%;
    }

    .ht-xs-750 {
        height: 750px;
    }

    .ht-xs-750p {
        height: 750%;
    }

    .mx-ht-xs-750p {
        max-height: 750%;
    }

    .mn-ht-xs-750p {
        min-height: 750%;
    }

    .ht-xs-800 {
        height: 800px;
    }

    .ht-xs-800p {
        height: 800%;
    }

    .mx-ht-xs-800p {
        max-height: 800%;
    }

    .mn-ht-xs-800p {
        min-height: 800%;
    }

    .ht-xs-850 {
        height: 850px;
    }

    .ht-xs-850p {
        height: 850%;
    }

    .mx-ht-xs-850p {
        max-height: 850%;
    }

    .mn-ht-xs-850p {
        min-height: 850%;
    }

    .ht-xs-100v {
        height: 100vh;
    }
}

@media (min-width: 576px) {
    .ht-sm-5 {
        height: 5px;
    }

    .ht-sm-5p {
        height: 5%;
    }

    .mx-ht-sm-5p {
        max-height: 5%;
    }

    .mn-ht-sm-5p {
        min-height: 5%;
    }

    .ht-sm-10 {
        height: 10px;
    }

    .ht-sm-10p {
        height: 10%;
    }

    .mx-ht-sm-10p {
        max-height: 10%;
    }

    .mn-ht-sm-10p {
        min-height: 10%;
    }

    .ht-sm-15 {
        height: 15px;
    }

    .ht-sm-15p {
        height: 15%;
    }

    .mx-ht-sm-15p {
        max-height: 15%;
    }

    .mn-ht-sm-15p {
        min-height: 15%;
    }

    .ht-sm-20 {
        height: 20px;
    }

    .ht-sm-20p {
        height: 20%;
    }

    .mx-ht-sm-20p {
        max-height: 20%;
    }

    .mn-ht-sm-20p {
        min-height: 20%;
    }

    .ht-sm-25 {
        height: 25px;
    }

    .ht-sm-25p {
        height: 25%;
    }

    .mx-ht-sm-25p {
        max-height: 25%;
    }

    .mn-ht-sm-25p {
        min-height: 25%;
    }

    .ht-sm-30 {
        height: 30px;
    }

    .ht-sm-30p {
        height: 30%;
    }

    .mx-ht-sm-30p {
        max-height: 30%;
    }

    .mn-ht-sm-30p {
        min-height: 30%;
    }

    .ht-sm-35 {
        height: 35px;
    }

    .ht-sm-35p {
        height: 35%;
    }

    .mx-ht-sm-35p {
        max-height: 35%;
    }

    .mn-ht-sm-35p {
        min-height: 35%;
    }

    .ht-sm-40 {
        height: 40px;
    }

    .ht-sm-40p {
        height: 40%;
    }

    .mx-ht-sm-40p {
        max-height: 40%;
    }

    .mn-ht-sm-40p {
        min-height: 40%;
    }

    .ht-sm-45 {
        height: 45px;
    }

    .ht-sm-45p {
        height: 45%;
    }

    .mx-ht-sm-45p {
        max-height: 45%;
    }

    .mn-ht-sm-45p {
        min-height: 45%;
    }

    .ht-sm-50 {
        height: 50px;
    }

    .ht-sm-50p {
        height: 50%;
    }

    .mx-ht-sm-50p {
        max-height: 50%;
    }

    .mn-ht-sm-50p {
        min-height: 50%;
    }

    .ht-sm-55 {
        height: 55px;
    }

    .ht-sm-55p {
        height: 55%;
    }

    .mx-ht-sm-55p {
        max-height: 55%;
    }

    .mn-ht-sm-55p {
        min-height: 55%;
    }

    .ht-sm-60 {
        height: 60px;
    }

    .ht-sm-60p {
        height: 60%;
    }

    .mx-ht-sm-60p {
        max-height: 60%;
    }

    .mn-ht-sm-60p {
        min-height: 60%;
    }

    .ht-sm-65 {
        height: 65px;
    }

    .ht-sm-65p {
        height: 65%;
    }

    .mx-ht-sm-65p {
        max-height: 65%;
    }

    .mn-ht-sm-65p {
        min-height: 65%;
    }

    .ht-sm-70 {
        height: 70px;
    }

    .ht-sm-70p {
        height: 70%;
    }

    .mx-ht-sm-70p {
        max-height: 70%;
    }

    .mn-ht-sm-70p {
        min-height: 70%;
    }

    .ht-sm-75 {
        height: 75px;
    }

    .ht-sm-75p {
        height: 75%;
    }

    .mx-ht-sm-75p {
        max-height: 75%;
    }

    .mn-ht-sm-75p {
        min-height: 75%;
    }

    .ht-sm-80 {
        height: 80px;
    }

    .ht-sm-80p {
        height: 80%;
    }

    .mx-ht-sm-80p {
        max-height: 80%;
    }

    .mn-ht-sm-80p {
        min-height: 80%;
    }

    .ht-sm-85 {
        height: 85px;
    }

    .ht-sm-85p {
        height: 85%;
    }

    .mx-ht-sm-85p {
        max-height: 85%;
    }

    .mn-ht-sm-85p {
        min-height: 85%;
    }

    .ht-sm-90 {
        height: 90px;
    }

    .ht-sm-90p {
        height: 90%;
    }

    .mx-ht-sm-90p {
        max-height: 90%;
    }

    .mn-ht-sm-90p {
        min-height: 90%;
    }

    .ht-sm-95 {
        height: 95px;
    }

    .ht-sm-95p {
        height: 95%;
    }

    .mx-ht-sm-95p {
        max-height: 95%;
    }

    .mn-ht-sm-95p {
        min-height: 95%;
    }

    .ht-sm-100 {
        height: 100px;
    }

    .ht-sm-100p {
        height: 100%;
    }

    .mx-ht-sm-100p {
        max-height: 100%;
    }

    .mn-ht-sm-100p {
        min-height: 100%;
    }

    .ht-sm-150 {
        height: 150px;
    }

    .ht-sm-150p {
        height: 150%;
    }

    .mx-ht-sm-150p {
        max-height: 150%;
    }

    .mn-ht-sm-150p {
        min-height: 150%;
    }

    .ht-sm-200 {
        height: 200px;
    }

    .ht-sm-200p {
        height: 200%;
    }

    .mx-ht-sm-200p {
        max-height: 200%;
    }

    .mn-ht-sm-200p {
        min-height: 200%;
    }

    .ht-sm-250 {
        height: 250px;
    }

    .ht-sm-250p {
        height: 250%;
    }

    .mx-ht-sm-250p {
        max-height: 250%;
    }

    .mn-ht-sm-250p {
        min-height: 250%;
    }

    .ht-sm-300 {
        height: 300px;
    }

    .ht-sm-300p {
        height: 300%;
    }

    .mx-ht-sm-300p {
        max-height: 300%;
    }

    .mn-ht-sm-300p {
        min-height: 300%;
    }

    .ht-sm-350 {
        height: 350px;
    }

    .ht-sm-350p {
        height: 350%;
    }

    .mx-ht-sm-350p {
        max-height: 350%;
    }

    .mn-ht-sm-350p {
        min-height: 350%;
    }

    .ht-sm-400 {
        height: 400px;
    }

    .ht-sm-400p {
        height: 400%;
    }

    .mx-ht-sm-400p {
        max-height: 400%;
    }

    .mn-ht-sm-400p {
        min-height: 400%;
    }

    .ht-sm-450 {
        height: 450px;
    }

    .ht-sm-450p {
        height: 450%;
    }

    .mx-ht-sm-450p {
        max-height: 450%;
    }

    .mn-ht-sm-450p {
        min-height: 450%;
    }

    .ht-sm-500 {
        height: 500px;
    }

    .ht-sm-500p {
        height: 500%;
    }

    .mx-ht-sm-500p {
        max-height: 500%;
    }

    .mn-ht-sm-500p {
        min-height: 500%;
    }

    .ht-sm-550 {
        height: 550px;
    }

    .ht-sm-550p {
        height: 550%;
    }

    .mx-ht-sm-550p {
        max-height: 550%;
    }

    .mn-ht-sm-550p {
        min-height: 550%;
    }

    .ht-sm-600 {
        height: 600px;
    }

    .ht-sm-600p {
        height: 600%;
    }

    .mx-ht-sm-600p {
        max-height: 600%;
    }

    .mn-ht-sm-600p {
        min-height: 600%;
    }

    .ht-sm-650 {
        height: 650px;
    }

    .ht-sm-650p {
        height: 650%;
    }

    .mx-ht-sm-650p {
        max-height: 650%;
    }

    .mn-ht-sm-650p {
        min-height: 650%;
    }

    .ht-sm-700 {
        height: 700px;
    }

    .ht-sm-700p {
        height: 700%;
    }

    .mx-ht-sm-700p {
        max-height: 700%;
    }

    .mn-ht-sm-700p {
        min-height: 700%;
    }

    .ht-sm-750 {
        height: 750px;
    }

    .ht-sm-750p {
        height: 750%;
    }

    .mx-ht-sm-750p {
        max-height: 750%;
    }

    .mn-ht-sm-750p {
        min-height: 750%;
    }

    .ht-sm-800 {
        height: 800px;
    }

    .ht-sm-800p {
        height: 800%;
    }

    .mx-ht-sm-800p {
        max-height: 800%;
    }

    .mn-ht-sm-800p {
        min-height: 800%;
    }

    .ht-sm-850 {
        height: 850px;
    }

    .ht-sm-850p {
        height: 850%;
    }

    .mx-ht-sm-850p {
        max-height: 850%;
    }

    .mn-ht-sm-850p {
        min-height: 850%;
    }

    .ht-sm-100v {
        height: 100vh;
    }
}

@media (min-width: 768px) {
    .ht-md-5 {
        height: 5px;
    }

    .ht-md-5p {
        height: 5%;
    }

    .mx-ht-md-5p {
        max-height: 5%;
    }

    .mn-ht-md-5p {
        min-height: 5%;
    }

    .ht-md-10 {
        height: 10px;
    }

    .ht-md-10p {
        height: 10%;
    }

    .mx-ht-md-10p {
        max-height: 10%;
    }

    .mn-ht-md-10p {
        min-height: 10%;
    }

    .ht-md-15 {
        height: 15px;
    }

    .ht-md-15p {
        height: 15%;
    }

    .mx-ht-md-15p {
        max-height: 15%;
    }

    .mn-ht-md-15p {
        min-height: 15%;
    }

    .ht-md-20 {
        height: 20px;
    }

    .ht-md-20p {
        height: 20%;
    }

    .mx-ht-md-20p {
        max-height: 20%;
    }

    .mn-ht-md-20p {
        min-height: 20%;
    }

    .ht-md-25 {
        height: 25px;
    }

    .ht-md-25p {
        height: 25%;
    }

    .mx-ht-md-25p {
        max-height: 25%;
    }

    .mn-ht-md-25p {
        min-height: 25%;
    }

    .ht-md-30 {
        height: 30px;
    }

    .ht-md-30p {
        height: 30%;
    }

    .mx-ht-md-30p {
        max-height: 30%;
    }

    .mn-ht-md-30p {
        min-height: 30%;
    }

    .ht-md-35 {
        height: 35px;
    }

    .ht-md-35p {
        height: 35%;
    }

    .mx-ht-md-35p {
        max-height: 35%;
    }

    .mn-ht-md-35p {
        min-height: 35%;
    }

    .ht-md-40 {
        height: 40px;
    }

    .ht-md-40p {
        height: 40%;
    }

    .mx-ht-md-40p {
        max-height: 40%;
    }

    .mn-ht-md-40p {
        min-height: 40%;
    }

    .ht-md-45 {
        height: 45px;
    }

    .ht-md-45p {
        height: 45%;
    }

    .mx-ht-md-45p {
        max-height: 45%;
    }

    .mn-ht-md-45p {
        min-height: 45%;
    }

    .ht-md-50 {
        height: 50px;
    }

    .ht-md-50p {
        height: 50%;
    }

    .mx-ht-md-50p {
        max-height: 50%;
    }

    .mn-ht-md-50p {
        min-height: 50%;
    }

    .ht-md-55 {
        height: 55px;
    }

    .ht-md-55p {
        height: 55%;
    }

    .mx-ht-md-55p {
        max-height: 55%;
    }

    .mn-ht-md-55p {
        min-height: 55%;
    }

    .ht-md-60 {
        height: 60px;
    }

    .ht-md-60p {
        height: 60%;
    }

    .mx-ht-md-60p {
        max-height: 60%;
    }

    .mn-ht-md-60p {
        min-height: 60%;
    }

    .ht-md-65 {
        height: 65px;
    }

    .ht-md-65p {
        height: 65%;
    }

    .mx-ht-md-65p {
        max-height: 65%;
    }

    .mn-ht-md-65p {
        min-height: 65%;
    }

    .ht-md-70 {
        height: 70px;
    }

    .ht-md-70p {
        height: 70%;
    }

    .mx-ht-md-70p {
        max-height: 70%;
    }

    .mn-ht-md-70p {
        min-height: 70%;
    }

    .ht-md-75 {
        height: 75px;
    }

    .ht-md-75p {
        height: 75%;
    }

    .mx-ht-md-75p {
        max-height: 75%;
    }

    .mn-ht-md-75p {
        min-height: 75%;
    }

    .ht-md-80 {
        height: 80px;
    }

    .ht-md-80p {
        height: 80%;
    }

    .mx-ht-md-80p {
        max-height: 80%;
    }

    .mn-ht-md-80p {
        min-height: 80%;
    }

    .ht-md-85 {
        height: 85px;
    }

    .ht-md-85p {
        height: 85%;
    }

    .mx-ht-md-85p {
        max-height: 85%;
    }

    .mn-ht-md-85p {
        min-height: 85%;
    }

    .ht-md-90 {
        height: 90px;
    }

    .ht-md-90p {
        height: 90%;
    }

    .mx-ht-md-90p {
        max-height: 90%;
    }

    .mn-ht-md-90p {
        min-height: 90%;
    }

    .ht-md-95 {
        height: 95px;
    }

    .ht-md-95p {
        height: 95%;
    }

    .mx-ht-md-95p {
        max-height: 95%;
    }

    .mn-ht-md-95p {
        min-height: 95%;
    }

    .ht-md-100 {
        height: 100px;
    }

    .ht-md-100p {
        height: 100%;
    }

    .mx-ht-md-100p {
        max-height: 100%;
    }

    .mn-ht-md-100p {
        min-height: 100%;
    }

    .ht-md-150 {
        height: 150px;
    }

    .ht-md-150p {
        height: 150%;
    }

    .mx-ht-md-150p {
        max-height: 150%;
    }

    .mn-ht-md-150p {
        min-height: 150%;
    }

    .ht-md-200 {
        height: 200px;
    }

    .ht-md-200p {
        height: 200%;
    }

    .mx-ht-md-200p {
        max-height: 200%;
    }

    .mn-ht-md-200p {
        min-height: 200%;
    }

    .ht-md-250 {
        height: 250px;
    }

    .ht-md-250p {
        height: 250%;
    }

    .mx-ht-md-250p {
        max-height: 250%;
    }

    .mn-ht-md-250p {
        min-height: 250%;
    }

    .ht-md-300 {
        height: 300px;
    }

    .ht-md-300p {
        height: 300%;
    }

    .mx-ht-md-300p {
        max-height: 300%;
    }

    .mn-ht-md-300p {
        min-height: 300%;
    }

    .ht-md-350 {
        height: 350px;
    }

    .ht-md-350p {
        height: 350%;
    }

    .mx-ht-md-350p {
        max-height: 350%;
    }

    .mn-ht-md-350p {
        min-height: 350%;
    }

    .ht-md-400 {
        height: 400px;
    }

    .ht-md-400p {
        height: 400%;
    }

    .mx-ht-md-400p {
        max-height: 400%;
    }

    .mn-ht-md-400p {
        min-height: 400%;
    }

    .ht-md-450 {
        height: 450px;
    }

    .ht-md-450p {
        height: 450%;
    }

    .mx-ht-md-450p {
        max-height: 450%;
    }

    .mn-ht-md-450p {
        min-height: 450%;
    }

    .ht-md-500 {
        height: 500px;
    }

    .ht-md-500p {
        height: 500%;
    }

    .mx-ht-md-500p {
        max-height: 500%;
    }

    .mn-ht-md-500p {
        min-height: 500%;
    }

    .ht-md-550 {
        height: 550px;
    }

    .ht-md-550p {
        height: 550%;
    }

    .mx-ht-md-550p {
        max-height: 550%;
    }

    .mn-ht-md-550p {
        min-height: 550%;
    }

    .ht-md-600 {
        height: 600px;
    }

    .ht-md-600p {
        height: 600%;
    }

    .mx-ht-md-600p {
        max-height: 600%;
    }

    .mn-ht-md-600p {
        min-height: 600%;
    }

    .ht-md-650 {
        height: 650px;
    }

    .ht-md-650p {
        height: 650%;
    }

    .mx-ht-md-650p {
        max-height: 650%;
    }

    .mn-ht-md-650p {
        min-height: 650%;
    }

    .ht-md-700 {
        height: 700px;
    }

    .ht-md-700p {
        height: 700%;
    }

    .mx-ht-md-700p {
        max-height: 700%;
    }

    .mn-ht-md-700p {
        min-height: 700%;
    }

    .ht-md-750 {
        height: 750px;
    }

    .ht-md-750p {
        height: 750%;
    }

    .mx-ht-md-750p {
        max-height: 750%;
    }

    .mn-ht-md-750p {
        min-height: 750%;
    }

    .ht-md-800 {
        height: 800px;
    }

    .ht-md-800p {
        height: 800%;
    }

    .mx-ht-md-800p {
        max-height: 800%;
    }

    .mn-ht-md-800p {
        min-height: 800%;
    }

    .ht-md-850 {
        height: 850px;
    }

    .ht-md-850p {
        height: 850%;
    }

    .mx-ht-md-850p {
        max-height: 850%;
    }

    .mn-ht-md-850p {
        min-height: 850%;
    }

    .ht-md-100v {
        height: 100vh;
    }
}

@media (min-width: 992px) {
    .ht-lg-5 {
        height: 5px;
    }

    .ht-lg-5p {
        height: 5%;
    }

    .mx-ht-lg-5p {
        max-height: 5%;
    }

    .mn-ht-lg-5p {
        min-height: 5%;
    }

    .ht-lg-10 {
        height: 10px;
    }

    .ht-lg-10p {
        height: 10%;
    }

    .mx-ht-lg-10p {
        max-height: 10%;
    }

    .mn-ht-lg-10p {
        min-height: 10%;
    }

    .ht-lg-15 {
        height: 15px;
    }

    .ht-lg-15p {
        height: 15%;
    }

    .mx-ht-lg-15p {
        max-height: 15%;
    }

    .mn-ht-lg-15p {
        min-height: 15%;
    }

    .ht-lg-20 {
        height: 20px;
    }

    .ht-lg-20p {
        height: 20%;
    }

    .mx-ht-lg-20p {
        max-height: 20%;
    }

    .mn-ht-lg-20p {
        min-height: 20%;
    }

    .ht-lg-25 {
        height: 25px;
    }

    .ht-lg-25p {
        height: 25%;
    }

    .mx-ht-lg-25p {
        max-height: 25%;
    }

    .mn-ht-lg-25p {
        min-height: 25%;
    }

    .ht-lg-30 {
        height: 30px;
    }

    .ht-lg-30p {
        height: 30%;
    }

    .mx-ht-lg-30p {
        max-height: 30%;
    }

    .mn-ht-lg-30p {
        min-height: 30%;
    }

    .ht-lg-35 {
        height: 35px;
    }

    .ht-lg-35p {
        height: 35%;
    }

    .mx-ht-lg-35p {
        max-height: 35%;
    }

    .mn-ht-lg-35p {
        min-height: 35%;
    }

    .ht-lg-40 {
        height: 40px;
    }

    .ht-lg-40p {
        height: 40%;
    }

    .mx-ht-lg-40p {
        max-height: 40%;
    }

    .mn-ht-lg-40p {
        min-height: 40%;
    }

    .ht-lg-45 {
        height: 45px;
    }

    .ht-lg-45p {
        height: 45%;
    }

    .mx-ht-lg-45p {
        max-height: 45%;
    }

    .mn-ht-lg-45p {
        min-height: 45%;
    }

    .ht-lg-50 {
        height: 50px;
    }

    .ht-lg-50p {
        height: 50%;
    }

    .mx-ht-lg-50p {
        max-height: 50%;
    }

    .mn-ht-lg-50p {
        min-height: 50%;
    }

    .ht-lg-55 {
        height: 55px;
    }

    .ht-lg-55p {
        height: 55%;
    }

    .mx-ht-lg-55p {
        max-height: 55%;
    }

    .mn-ht-lg-55p {
        min-height: 55%;
    }

    .ht-lg-60 {
        height: 60px;
    }

    .ht-lg-60p {
        height: 60%;
    }

    .mx-ht-lg-60p {
        max-height: 60%;
    }

    .mn-ht-lg-60p {
        min-height: 60%;
    }

    .ht-lg-65 {
        height: 65px;
    }

    .ht-lg-65p {
        height: 65%;
    }

    .mx-ht-lg-65p {
        max-height: 65%;
    }

    .mn-ht-lg-65p {
        min-height: 65%;
    }

    .ht-lg-70 {
        height: 70px;
    }

    .ht-lg-70p {
        height: 70%;
    }

    .mx-ht-lg-70p {
        max-height: 70%;
    }

    .mn-ht-lg-70p {
        min-height: 70%;
    }

    .ht-lg-75 {
        height: 75px;
    }

    .ht-lg-75p {
        height: 75%;
    }

    .mx-ht-lg-75p {
        max-height: 75%;
    }

    .mn-ht-lg-75p {
        min-height: 75%;
    }

    .ht-lg-80 {
        height: 80px;
    }

    .ht-lg-80p {
        height: 80%;
    }

    .mx-ht-lg-80p {
        max-height: 80%;
    }

    .mn-ht-lg-80p {
        min-height: 80%;
    }

    .ht-lg-85 {
        height: 85px;
    }

    .ht-lg-85p {
        height: 85%;
    }

    .mx-ht-lg-85p {
        max-height: 85%;
    }

    .mn-ht-lg-85p {
        min-height: 85%;
    }

    .ht-lg-90 {
        height: 90px;
    }

    .ht-lg-90p {
        height: 90%;
    }

    .mx-ht-lg-90p {
        max-height: 90%;
    }

    .mn-ht-lg-90p {
        min-height: 90%;
    }

    .ht-lg-95 {
        height: 95px;
    }

    .ht-lg-95p {
        height: 95%;
    }

    .mx-ht-lg-95p {
        max-height: 95%;
    }

    .mn-ht-lg-95p {
        min-height: 95%;
    }

    .ht-lg-100 {
        height: 100px;
    }

    .ht-lg-100p {
        height: 100%;
    }

    .mx-ht-lg-100p {
        max-height: 100%;
    }

    .mn-ht-lg-100p {
        min-height: 100%;
    }

    .ht-lg-150 {
        height: 150px;
    }

    .ht-lg-150p {
        height: 150%;
    }

    .mx-ht-lg-150p {
        max-height: 150%;
    }

    .mn-ht-lg-150p {
        min-height: 150%;
    }

    .ht-lg-200 {
        height: 200px;
    }

    .ht-lg-200p {
        height: 200%;
    }

    .mx-ht-lg-200p {
        max-height: 200%;
    }

    .mn-ht-lg-200p {
        min-height: 200%;
    }

    .ht-lg-250 {
        height: 250px;
    }

    .ht-lg-250p {
        height: 250%;
    }

    .mx-ht-lg-250p {
        max-height: 250%;
    }

    .mn-ht-lg-250p {
        min-height: 250%;
    }

    .ht-lg-300 {
        height: 300px;
    }

    .ht-lg-300p {
        height: 300%;
    }

    .mx-ht-lg-300p {
        max-height: 300%;
    }

    .mn-ht-lg-300p {
        min-height: 300%;
    }

    .ht-lg-350 {
        height: 350px;
    }

    .ht-lg-350p {
        height: 350%;
    }

    .mx-ht-lg-350p {
        max-height: 350%;
    }

    .mn-ht-lg-350p {
        min-height: 350%;
    }

    .ht-lg-400 {
        height: 400px;
    }

    .ht-lg-400p {
        height: 400%;
    }

    .mx-ht-lg-400p {
        max-height: 400%;
    }

    .mn-ht-lg-400p {
        min-height: 400%;
    }

    .ht-lg-450 {
        height: 450px;
    }

    .ht-lg-450p {
        height: 450%;
    }

    .mx-ht-lg-450p {
        max-height: 450%;
    }

    .mn-ht-lg-450p {
        min-height: 450%;
    }

    .ht-lg-500 {
        height: 500px;
    }

    .ht-lg-500p {
        height: 500%;
    }

    .mx-ht-lg-500p {
        max-height: 500%;
    }

    .mn-ht-lg-500p {
        min-height: 500%;
    }

    .ht-lg-550 {
        height: 550px;
    }

    .ht-lg-550p {
        height: 550%;
    }

    .mx-ht-lg-550p {
        max-height: 550%;
    }

    .mn-ht-lg-550p {
        min-height: 550%;
    }

    .ht-lg-600 {
        height: 600px;
    }

    .ht-lg-600p {
        height: 600%;
    }

    .mx-ht-lg-600p {
        max-height: 600%;
    }

    .mn-ht-lg-600p {
        min-height: 600%;
    }

    .ht-lg-650 {
        height: 650px;
    }

    .ht-lg-650p {
        height: 650%;
    }

    .mx-ht-lg-650p {
        max-height: 650%;
    }

    .mn-ht-lg-650p {
        min-height: 650%;
    }

    .ht-lg-700 {
        height: 700px;
    }

    .ht-lg-700p {
        height: 700%;
    }

    .mx-ht-lg-700p {
        max-height: 700%;
    }

    .mn-ht-lg-700p {
        min-height: 700%;
    }

    .ht-lg-750 {
        height: 750px;
    }

    .ht-lg-750p {
        height: 750%;
    }

    .mx-ht-lg-750p {
        max-height: 750%;
    }

    .mn-ht-lg-750p {
        min-height: 750%;
    }

    .ht-lg-800 {
        height: 800px;
    }

    .ht-lg-800p {
        height: 800%;
    }

    .mx-ht-lg-800p {
        max-height: 800%;
    }

    .mn-ht-lg-800p {
        min-height: 800%;
    }

    .ht-lg-850 {
        height: 850px;
    }

    .ht-lg-850p {
        height: 850%;
    }

    .mx-ht-lg-850p {
        max-height: 850%;
    }

    .mn-ht-lg-850p {
        min-height: 850%;
    }

    .ht-lg-100v {
        height: 100vh;
    }
}

@media (min-width: 1200px) {
    .ht-xl-5 {
        height: 5px;
    }

    .ht-xl-5p {
        height: 5%;
    }

    .mx-ht-xl-5p {
        max-height: 5%;
    }

    .mn-ht-xl-5p {
        min-height: 5%;
    }

    .ht-xl-10 {
        height: 10px;
    }

    .ht-xl-10p {
        height: 10%;
    }

    .mx-ht-xl-10p {
        max-height: 10%;
    }

    .mn-ht-xl-10p {
        min-height: 10%;
    }

    .ht-xl-15 {
        height: 15px;
    }

    .ht-xl-15p {
        height: 15%;
    }

    .mx-ht-xl-15p {
        max-height: 15%;
    }

    .mn-ht-xl-15p {
        min-height: 15%;
    }

    .ht-xl-20 {
        height: 20px;
    }

    .ht-xl-20p {
        height: 20%;
    }

    .mx-ht-xl-20p {
        max-height: 20%;
    }

    .mn-ht-xl-20p {
        min-height: 20%;
    }

    .ht-xl-25 {
        height: 25px;
    }

    .ht-xl-25p {
        height: 25%;
    }

    .mx-ht-xl-25p {
        max-height: 25%;
    }

    .mn-ht-xl-25p {
        min-height: 25%;
    }

    .ht-xl-30 {
        height: 30px;
    }

    .ht-xl-30p {
        height: 30%;
    }

    .mx-ht-xl-30p {
        max-height: 30%;
    }

    .mn-ht-xl-30p {
        min-height: 30%;
    }

    .ht-xl-35 {
        height: 35px;
    }

    .ht-xl-35p {
        height: 35%;
    }

    .mx-ht-xl-35p {
        max-height: 35%;
    }

    .mn-ht-xl-35p {
        min-height: 35%;
    }

    .ht-xl-40 {
        height: 40px;
    }

    .ht-xl-40p {
        height: 40%;
    }

    .mx-ht-xl-40p {
        max-height: 40%;
    }

    .mn-ht-xl-40p {
        min-height: 40%;
    }

    .ht-xl-45 {
        height: 45px;
    }

    .ht-xl-45p {
        height: 45%;
    }

    .mx-ht-xl-45p {
        max-height: 45%;
    }

    .mn-ht-xl-45p {
        min-height: 45%;
    }

    .ht-xl-50 {
        height: 50px;
    }

    .ht-xl-50p {
        height: 50%;
    }

    .mx-ht-xl-50p {
        max-height: 50%;
    }

    .mn-ht-xl-50p {
        min-height: 50%;
    }

    .ht-xl-55 {
        height: 55px;
    }

    .ht-xl-55p {
        height: 55%;
    }

    .mx-ht-xl-55p {
        max-height: 55%;
    }

    .mn-ht-xl-55p {
        min-height: 55%;
    }

    .ht-xl-60 {
        height: 60px;
    }

    .ht-xl-60p {
        height: 60%;
    }

    .mx-ht-xl-60p {
        max-height: 60%;
    }

    .mn-ht-xl-60p {
        min-height: 60%;
    }

    .ht-xl-65 {
        height: 65px;
    }

    .ht-xl-65p {
        height: 65%;
    }

    .mx-ht-xl-65p {
        max-height: 65%;
    }

    .mn-ht-xl-65p {
        min-height: 65%;
    }

    .ht-xl-70 {
        height: 70px;
    }

    .ht-xl-70p {
        height: 70%;
    }

    .mx-ht-xl-70p {
        max-height: 70%;
    }

    .mn-ht-xl-70p {
        min-height: 70%;
    }

    .ht-xl-75 {
        height: 75px;
    }

    .ht-xl-75p {
        height: 75%;
    }

    .mx-ht-xl-75p {
        max-height: 75%;
    }

    .mn-ht-xl-75p {
        min-height: 75%;
    }

    .ht-xl-80 {
        height: 80px;
    }

    .ht-xl-80p {
        height: 80%;
    }

    .mx-ht-xl-80p {
        max-height: 80%;
    }

    .mn-ht-xl-80p {
        min-height: 80%;
    }

    .ht-xl-85 {
        height: 85px;
    }

    .ht-xl-85p {
        height: 85%;
    }

    .mx-ht-xl-85p {
        max-height: 85%;
    }

    .mn-ht-xl-85p {
        min-height: 85%;
    }

    .ht-xl-90 {
        height: 90px;
    }

    .ht-xl-90p {
        height: 90%;
    }

    .mx-ht-xl-90p {
        max-height: 90%;
    }

    .mn-ht-xl-90p {
        min-height: 90%;
    }

    .ht-xl-95 {
        height: 95px;
    }

    .ht-xl-95p {
        height: 95%;
    }

    .mx-ht-xl-95p {
        max-height: 95%;
    }

    .mn-ht-xl-95p {
        min-height: 95%;
    }

    .ht-xl-100 {
        height: 100px;
    }

    .ht-xl-100p {
        height: 100%;
    }

    .mx-ht-xl-100p {
        max-height: 100%;
    }

    .mn-ht-xl-100p {
        min-height: 100%;
    }

    .ht-xl-150 {
        height: 150px;
    }

    .ht-xl-150p {
        height: 150%;
    }

    .mx-ht-xl-150p {
        max-height: 150%;
    }

    .mn-ht-xl-150p {
        min-height: 150%;
    }

    .ht-xl-200 {
        height: 200px;
    }

    .ht-xl-200p {
        height: 200%;
    }

    .mx-ht-xl-200p {
        max-height: 200%;
    }

    .mn-ht-xl-200p {
        min-height: 200%;
    }

    .ht-xl-250 {
        height: 250px;
    }

    .ht-xl-250p {
        height: 250%;
    }

    .mx-ht-xl-250p {
        max-height: 250%;
    }

    .mn-ht-xl-250p {
        min-height: 250%;
    }

    .ht-xl-300 {
        height: 300px;
    }

    .ht-xl-300p {
        height: 300%;
    }

    .mx-ht-xl-300p {
        max-height: 300%;
    }

    .mn-ht-xl-300p {
        min-height: 300%;
    }

    .ht-xl-350 {
        height: 350px;
    }

    .ht-xl-350p {
        height: 350%;
    }

    .mx-ht-xl-350p {
        max-height: 350%;
    }

    .mn-ht-xl-350p {
        min-height: 350%;
    }

    .ht-xl-400 {
        height: 400px;
    }

    .ht-xl-400p {
        height: 400%;
    }

    .mx-ht-xl-400p {
        max-height: 400%;
    }

    .mn-ht-xl-400p {
        min-height: 400%;
    }

    .ht-xl-450 {
        height: 450px;
    }

    .ht-xl-450p {
        height: 450%;
    }

    .mx-ht-xl-450p {
        max-height: 450%;
    }

    .mn-ht-xl-450p {
        min-height: 450%;
    }

    .ht-xl-500 {
        height: 500px;
    }

    .ht-xl-500p {
        height: 500%;
    }

    .mx-ht-xl-500p {
        max-height: 500%;
    }

    .mn-ht-xl-500p {
        min-height: 500%;
    }

    .ht-xl-550 {
        height: 550px;
    }

    .ht-xl-550p {
        height: 550%;
    }

    .mx-ht-xl-550p {
        max-height: 550%;
    }

    .mn-ht-xl-550p {
        min-height: 550%;
    }

    .ht-xl-600 {
        height: 600px;
    }

    .ht-xl-600p {
        height: 600%;
    }

    .mx-ht-xl-600p {
        max-height: 600%;
    }

    .mn-ht-xl-600p {
        min-height: 600%;
    }

    .ht-xl-650 {
        height: 650px;
    }

    .ht-xl-650p {
        height: 650%;
    }

    .mx-ht-xl-650p {
        max-height: 650%;
    }

    .mn-ht-xl-650p {
        min-height: 650%;
    }

    .ht-xl-700 {
        height: 700px;
    }

    .ht-xl-700p {
        height: 700%;
    }

    .mx-ht-xl-700p {
        max-height: 700%;
    }

    .mn-ht-xl-700p {
        min-height: 700%;
    }

    .ht-xl-750 {
        height: 750px;
    }

    .ht-xl-750p {
        height: 750%;
    }

    .mx-ht-xl-750p {
        max-height: 750%;
    }

    .mn-ht-xl-750p {
        min-height: 750%;
    }

    .ht-xl-800 {
        height: 800px;
    }

    .ht-xl-800p {
        height: 800%;
    }

    .mx-ht-xl-800p {
        max-height: 800%;
    }

    .mn-ht-xl-800p {
        min-height: 800%;
    }

    .ht-xl-850 {
        height: 850px;
    }

    .ht-xl-850p {
        height: 850%;
    }

    .mx-ht-xl-850p {
        max-height: 850%;
    }

    .mn-ht-xl-850p {
        min-height: 850%;
    }

    .ht-xl-100v {
        height: 100vh;
    }
}

.lh-50 {
    line-height: 50px;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vh-100 {
    height: 100vh !important;
}

.h-330 {
    height: 330px !important;
}

.h-270 {
    height: 270px !important;
}

.h-300 {
    height: 300px !important;
}

/* ###### Height ###### */
/* ###### Margin ###### */
.mg-0 {
    margin: 0px;
}

.mg-y-0 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.mg-x-0 {
    margin-left: 0px;
    margin-right: 0px;
}

.mg-t-0 {
    margin-top: 0px;
}

.mg-r-0 {
    margin-right: 0px;
}

.mg-b-0 {
    margin-bottom: 0px;
}

.mg-l-0 {
    margin-left: 0px;
}

.mg-1 {
    margin: 1px;
}

.mg-y-1 {
    margin-top: 1px;
    margin-bottom: 1px;
}

.mg-x-1 {
    margin-left: 1px;
    margin-right: 1px;
}

.mg-t-1 {
    margin-top: 1px;
}

.mg-r-1 {
    margin-right: 1px;
}

.mg-b-1 {
    margin-bottom: 1px;
}

.mg-l-1 {
    margin-left: 1px;
}

.mg-2 {
    margin: 2px;
}

.mg-y-2 {
    margin-top: 2px;
    margin-bottom: 2px;
}

.mg-x-2 {
    margin-left: 2px;
    margin-right: 2px;
}

.mg-t-2 {
    margin-top: 2px;
}

.mg-r-2 {
    margin-right: 2px;
}

.mg-b-2 {
    margin-bottom: 2px;
}

.mg-l-2 {
    margin-left: 2px;
}

.mg-3 {
    margin: 3px;
}

.mg-y-3 {
    margin-top: 3px;
    margin-bottom: 3px;
}

.mg-x-3 {
    margin-left: 3px;
    margin-right: 3px;
}

.mg-t-3 {
    margin-top: 3px;
}

.mg-r-3 {
    margin-right: 3px;
}

.mg-b-3 {
    margin-bottom: 3px;
}

.mg-l-3 {
    margin-left: 3px;
}

.mg-4 {
    margin: 4px;
}

.mg-y-4 {
    margin-top: 4px;
    margin-bottom: 4px;
}

.mg-x-4 {
    margin-left: 4px;
    margin-right: 4px;
}

.mg-t-4 {
    margin-top: 4px;
}

.mg-r-4 {
    margin-right: 4px;
}

.mg-b-4 {
    margin-bottom: 4px;
}

.mg-l-4 {
    margin-left: 4px;
}

.mg-5 {
    margin: 5px;
}

.mg-y-5 {
    margin-top: 5px;
    margin-bottom: 5px;
}

.mg-x-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.mg-t-5 {
    margin-top: 5px;
}

.mg-r-5 {
    margin-right: 5px;
}

.mg-b-5 {
    margin-bottom: 5px !important;
}

.mg-l-5 {
    margin-left: 5px;
}

.mg-6 {
    margin: 6px;
}

.mg-y-6 {
    margin-top: 6px;
    margin-bottom: 6px;
}

.mg-x-6 {
    margin-left: 6px;
    margin-right: 6px;
}

.mg-t-6 {
    margin-top: 6px;
}

.mg-r-6 {
    margin-right: 6px;
}

.mg-b-6 {
    margin-bottom: 6px;
}

.mg-l-6 {
    margin-left: 6px;
}

.mg-7 {
    margin: 7px;
}

.mg-y-7 {
    margin-top: 7px;
    margin-bottom: 7px;
}

.mg-x-7 {
    margin-left: 7px;
    margin-right: 7px;
}

.mg-t-7 {
    margin-top: 7px;
}

.mg-r-7 {
    margin-right: 7px;
}

.mg-b-7 {
    margin-bottom: 7px;
}

.mg-l-7 {
    margin-left: 7px;
}

.mg-8 {
    margin: 8px;
}

.mg-y-8 {
    margin-top: 8px;
    margin-bottom: 8px;
}

.mg-x-8 {
    margin-left: 8px;
    margin-right: 8px;
}

.mg-t-8 {
    margin-top: 8px;
}

.mg-r-8 {
    margin-right: 8px;
}

.mg-b-8 {
    margin-bottom: 8px;
}

.mg-l-8 {
    margin-left: 8px;
}

.mg-9 {
    margin: 9px;
}

.mg-y-9 {
    margin-top: 9px;
    margin-bottom: 9px;
}

.mg-x-9 {
    margin-left: 9px;
    margin-right: 9px;
}

.mg-t-9 {
    margin-top: 9px;
}

.mg-r-9 {
    margin-right: 9px;
}

.mg-b-9 {
    margin-bottom: 9px;
}

.mg-l-9 {
    margin-left: 9px;
}

.mg-10 {
    margin: 10px;
}

.mg-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.mg-x-10 {
    margin-left: 10px;
    margin-right: 10px;
}

.mg-t-10 {
    margin-top: 10px;
}

.mg-r-10 {
    margin-right: 10px;
}

.mg-b-10 {
    margin-bottom: 10px !important;
}

.mg-l-10 {
    margin-left: 10px;
}

.mg-15 {
    margin: 15px;
}

.mg-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.mg-x-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.mg-t-15 {
    margin-top: 15px;
}

.mg-r-15 {
    margin-right: 15px;
}

.mg-b-15 {
    margin-bottom: 15px;
}

.mg-l-15 {
    margin-left: 15px;
}

.mg-20 {
    margin: 20px;
}

.mg-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mg-x-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.mg-t-20 {
    margin-top: 20px;
}

.mg-r-20 {
    margin-right: 20px;
}

.mg-b-20 {
    margin-bottom: 20px;
}

.mg-l-20 {
    margin-left: 20px;
}

.mg-25 {
    margin: 25px;
}

.mg-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.mg-x-25 {
    margin-left: 25px;
    margin-right: 25px;
}

.mg-t-25 {
    margin-top: 25px;
}

.mg-r-25 {
    margin-right: 25px;
}

.mg-b-25 {
    margin-bottom: 25px;
}

.mg-l-25 {
    margin-left: 25px;
}

.mg-30 {
    margin: 30px;
}

.mg-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.mg-x-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.mg-t-30 {
    margin-top: 30px;
}

.mg-r-30 {
    margin-right: 30px;
}

.mg-b-30 {
    margin-bottom: 30px;
}

.mg-l-30 {
    margin-left: 30px;
}

.mg-35 {
    margin: 35px;
}

.mg-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.mg-x-35 {
    margin-left: 35px;
    margin-right: 35px;
}

.mg-t-35 {
    margin-top: 35px;
}

.mg-r-35 {
    margin-right: 35px;
}

.mg-b-35 {
    margin-bottom: 35px;
}

.mg-l-35 {
    margin-left: 35px;
}

.mg-40 {
    margin: 40px;
}

.mg-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.mg-x-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.mg-t-40 {
    margin-top: 40px;
}

.mg-r-40 {
    margin-right: 40px;
}

.mg-b-40 {
    margin-bottom: 40px;
}

.mg-l-40 {
    margin-left: 40px;
}

.mg-45 {
    margin: 45px;
}

.mg-y-45 {
    margin-top: 45px;
    margin-bottom: 45px;
}

.mg-x-45 {
    margin-left: 45px;
    margin-right: 45px;
}

.mg-t-45 {
    margin-top: 45px;
}

.mg-r-45 {
    margin-right: 45px;
}

.mg-b-45 {
    margin-bottom: 45px;
}

.mg-l-45 {
    margin-left: 45px;
}

.mg-50 {
    margin: 50px;
}

.mg-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.mg-x-50 {
    margin-left: 50px;
    margin-right: 50px;
}

.mg-t-50 {
    margin-top: 50px;
}

.mg-r-50 {
    margin-right: 50px;
}

.mg-b-50 {
    margin-bottom: 50px;
}

.mg-l-50 {
    margin-left: 50px;
}

.mg-55 {
    margin: 55px;
}

.mg-y-55 {
    margin-top: 55px;
    margin-bottom: 55px;
}

.mg-x-55 {
    margin-left: 55px;
    margin-right: 55px;
}

.mg-t-55 {
    margin-top: 55px;
}

.mg-r-55 {
    margin-right: 55px;
}

.mg-b-55 {
    margin-bottom: 55px;
}

.mg-l-55 {
    margin-left: 55px;
}

.mg-60 {
    margin: 60px;
}

.mg-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.mg-x-60 {
    margin-left: 60px;
    margin-right: 60px;
}

.mg-t-60 {
    margin-top: 60px;
}

.mg-r-60 {
    margin-right: 60px;
}

.mg-b-60 {
    margin-bottom: 60px;
}

.mg-l-60 {
    margin-left: 60px;
}

.mg-65 {
    margin: 65px;
}

.mg-y-65 {
    margin-top: 65px;
    margin-bottom: 65px;
}

.mg-x-65 {
    margin-left: 65px;
    margin-right: 65px;
}

.mg-t-65 {
    margin-top: 65px;
}

.mg-r-65 {
    margin-right: 65px;
}

.mg-b-65 {
    margin-bottom: 65px;
}

.mg-l-65 {
    margin-left: 65px;
}

.mg-70 {
    margin: 70px;
}

.mg-y-70 {
    margin-top: 70px;
    margin-bottom: 70px;
}

.mg-x-70 {
    margin-left: 70px;
    margin-right: 70px;
}

.mg-t-70 {
    margin-top: 70px;
}

.mg-r-70 {
    margin-right: 70px;
}

.mg-b-70 {
    margin-bottom: 70px;
}

.mg-l-70 {
    margin-left: 70px;
}

.mg-75 {
    margin: 75px;
}

.mg-y-75 {
    margin-top: 75px;
    margin-bottom: 75px;
}

.mg-x-75 {
    margin-left: 75px;
    margin-right: 75px;
}

.mg-t-75 {
    margin-top: 75px;
}

.mg-r-75 {
    margin-right: 75px;
}

.mg-b-75 {
    margin-bottom: 75px;
}

.mg-l-75 {
    margin-left: 75px;
}

.mg-80 {
    margin: 80px;
}

.mg-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.mg-x-80 {
    margin-left: 80px;
    margin-right: 80px;
}

.mg-t-80 {
    margin-top: 80px;
}

.mg-r-80 {
    margin-right: 80px;
}

.mg-b-80 {
    margin-bottom: 80px;
}

.mg-l-80 {
    margin-left: 80px;
}

.mg-85 {
    margin: 85px;
}

.mg-y-85 {
    margin-top: 85px;
    margin-bottom: 85px;
}

.mg-x-85 {
    margin-left: 85px;
    margin-right: 85px;
}

.mg-t-85 {
    margin-top: 85px;
}

.mg-r-85 {
    margin-right: 85px;
}

.mg-b-85 {
    margin-bottom: 85px;
}

.mg-l-85 {
    margin-left: 85px;
}

.mg-90 {
    margin: 90px;
}

.mg-y-90 {
    margin-top: 90px;
    margin-bottom: 90px;
}

.mg-x-90 {
    margin-left: 90px;
    margin-right: 90px;
}

.mg-t-90 {
    margin-top: 90px;
}

.mg-r-90 {
    margin-right: 90px;
}

.mg-b-90 {
    margin-bottom: 90px;
}

.mg-l-90 {
    margin-left: 90px;
}

.mg-95 {
    margin: 95px;
}

.mg-y-95 {
    margin-top: 95px;
    margin-bottom: 95px;
}

.mg-x-95 {
    margin-left: 95px;
    margin-right: 95px;
}

.mg-t-95 {
    margin-top: 95px;
}

.mg-r-95 {
    margin-right: 95px;
}

.mg-b-95 {
    margin-bottom: 95px;
}

.mg-l-95 {
    margin-left: 95px;
}

.mg-100 {
    margin: 100px;
}

.mg-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

.mg-x-100 {
    margin-left: 100px;
    margin-right: 100px;
}

.mg-t-100 {
    margin-top: 100px;
}

.mg-r-100 {
    margin-right: 100px;
}

.mg-b-100 {
    margin-bottom: 100px;
}

.mg-l-100 {
    margin-left: 100px;
}

.mg-105 {
    margin: 105px;
}

.mg-y-105 {
    margin-top: 105px;
    margin-bottom: 105px;
}

.mg-x-105 {
    margin-left: 105px;
    margin-right: 105px;
}

.mg-t-105 {
    margin-top: 105px;
}

.mg-r-105 {
    margin-right: 105px;
}

.mg-b-105 {
    margin-bottom: 105px;
}

.mg-l-105 {
    margin-left: 105px;
}

.mg-110 {
    margin: 110px;
}

.mg-y-110 {
    margin-top: 110px;
    margin-bottom: 110px;
}

.mg-x-110 {
    margin-left: 110px;
    margin-right: 110px;
}

.mg-t-110 {
    margin-top: 110px;
}

.mg-r-110 {
    margin-right: 110px;
}

.mg-b-110 {
    margin-bottom: 110px;
}

.mg-l-110 {
    margin-left: 110px;
}

.mg-115 {
    margin: 115px;
}

.mg-y-115 {
    margin-top: 115px;
    margin-bottom: 115px;
}

.mg-x-115 {
    margin-left: 115px;
    margin-right: 115px;
}

.mg-t-115 {
    margin-top: 115px;
}

.mg-r-115 {
    margin-right: 115px;
}

.mg-b-115 {
    margin-bottom: 115px;
}

.mg-l-115 {
    margin-left: 115px;
}

.mg-120 {
    margin: 120px;
}

.mg-y-120 {
    margin-top: 120px;
    margin-bottom: 120px;
}

.mg-x-120 {
    margin-left: 120px;
    margin-right: 120px;
}

.mg-t-120 {
    margin-top: 120px;
}

.mg-r-120 {
    margin-right: 120px;
}

.mg-b-120 {
    margin-bottom: 120px;
}

.mg-l-120 {
    margin-left: 120px;
}

.mg-t-auto {
    margin-top: auto;
}

.mg-r-auto {
    margin-right: auto;
}

.mg-b-auto {
    margin-bottom: auto;
}

.mg-l-auto {
    margin-left: auto;
}

.mg-x-auto {
    margin: auto;
}

@media (min-width: 480px) {
    .mg-xs-0 {
        margin: 0px;
    }

    .mg-xs-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-xs-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-xs-t-0 {
        margin-top: 0px;
    }

    .mg-xs-r-0 {
        margin-right: 0px;
    }

    .mg-xs-b-0 {
        margin-bottom: 0px;
    }

    .mg-xs-l-0 {
        margin-left: 0px;
    }

    .mg-xs-1 {
        margin: 1px;
    }

    .mg-xs-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-xs-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-xs-t-1 {
        margin-top: 1px;
    }

    .mg-xs-r-1 {
        margin-right: 1px;
    }

    .mg-xs-b-1 {
        margin-bottom: 1px;
    }

    .mg-xs-l-1 {
        margin-left: 1px;
    }

    .mg-xs-2 {
        margin: 2px;
    }

    .mg-xs-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-xs-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-xs-t-2 {
        margin-top: 2px;
    }

    .mg-xs-r-2 {
        margin-right: 2px;
    }

    .mg-xs-b-2 {
        margin-bottom: 2px;
    }

    .mg-xs-l-2 {
        margin-left: 2px;
    }

    .mg-xs-3 {
        margin: 3px;
    }

    .mg-xs-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-xs-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-xs-t-3 {
        margin-top: 3px;
    }

    .mg-xs-r-3 {
        margin-right: 3px;
    }

    .mg-xs-b-3 {
        margin-bottom: 3px;
    }

    .mg-xs-l-3 {
        margin-left: 3px;
    }

    .mg-xs-4 {
        margin: 4px;
    }

    .mg-xs-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-xs-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-xs-t-4 {
        margin-top: 4px;
    }

    .mg-xs-r-4 {
        margin-right: 4px;
    }

    .mg-xs-b-4 {
        margin-bottom: 4px;
    }

    .mg-xs-l-4 {
        margin-left: 4px;
    }

    .mg-xs-5 {
        margin: 5px;
    }

    .mg-xs-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-xs-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-xs-t-5 {
        margin-top: 5px;
    }

    .mg-xs-r-5 {
        margin-right: 5px;
    }

    .mg-xs-b-5 {
        margin-bottom: 5px;
    }

    .mg-xs-l-5 {
        margin-left: 5px;
    }

    .mg-xs-6 {
        margin: 6px;
    }

    .mg-xs-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-xs-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-xs-t-6 {
        margin-top: 6px;
    }

    .mg-xs-r-6 {
        margin-right: 6px;
    }

    .mg-xs-b-6 {
        margin-bottom: 6px;
    }

    .mg-xs-l-6 {
        margin-left: 6px;
    }

    .mg-xs-7 {
        margin: 7px;
    }

    .mg-xs-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-xs-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-xs-t-7 {
        margin-top: 7px;
    }

    .mg-xs-r-7 {
        margin-right: 7px;
    }

    .mg-xs-b-7 {
        margin-bottom: 7px;
    }

    .mg-xs-l-7 {
        margin-left: 7px;
    }

    .mg-xs-8 {
        margin: 8px;
    }

    .mg-xs-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-xs-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-xs-t-8 {
        margin-top: 8px;
    }

    .mg-xs-r-8 {
        margin-right: 8px;
    }

    .mg-xs-b-8 {
        margin-bottom: 8px;
    }

    .mg-xs-l-8 {
        margin-left: 8px;
    }

    .mg-xs-9 {
        margin: 9px;
    }

    .mg-xs-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-xs-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-xs-t-9 {
        margin-top: 9px;
    }

    .mg-xs-r-9 {
        margin-right: 9px;
    }

    .mg-xs-b-9 {
        margin-bottom: 9px;
    }

    .mg-xs-l-9 {
        margin-left: 9px;
    }

    .mg-xs-10 {
        margin: 10px;
    }

    .mg-xs-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-xs-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-xs-t-10 {
        margin-top: 10px;
    }

    .mg-xs-r-10 {
        margin-right: 10px;
    }

    .mg-xs-b-10 {
        margin-bottom: 10px;
    }

    .mg-xs-l-10 {
        margin-left: 10px;
    }

    .mg-xs-15 {
        margin: 15px;
    }

    .mg-xs-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-xs-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-xs-t-15 {
        margin-top: 15px;
    }

    .mg-xs-r-15 {
        margin-right: 15px;
    }

    .mg-xs-b-15 {
        margin-bottom: 15px;
    }

    .mg-xs-l-15 {
        margin-left: 15px;
    }

    .mg-xs-20 {
        margin: 20px;
    }

    .mg-xs-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-xs-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-xs-t-20 {
        margin-top: 20px;
    }

    .mg-xs-r-20 {
        margin-right: 20px;
    }

    .mg-xs-b-20 {
        margin-bottom: 20px;
    }

    .mg-xs-l-20 {
        margin-left: 20px;
    }

    .mg-xs-25 {
        margin: 25px;
    }

    .mg-xs-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-xs-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-xs-t-25 {
        margin-top: 25px;
    }

    .mg-xs-r-25 {
        margin-right: 25px;
    }

    .mg-xs-b-25 {
        margin-bottom: 25px;
    }

    .mg-xs-l-25 {
        margin-left: 25px;
    }

    .mg-xs-30 {
        margin: 30px;
    }

    .mg-xs-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-xs-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-xs-t-30 {
        margin-top: 30px;
    }

    .mg-xs-r-30 {
        margin-right: 30px;
    }

    .mg-xs-b-30 {
        margin-bottom: 30px;
    }

    .mg-xs-l-30 {
        margin-left: 30px;
    }

    .mg-xs-35 {
        margin: 35px;
    }

    .mg-xs-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-xs-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-xs-t-35 {
        margin-top: 35px;
    }

    .mg-xs-r-35 {
        margin-right: 35px;
    }

    .mg-xs-b-35 {
        margin-bottom: 35px;
    }

    .mg-xs-l-35 {
        margin-left: 35px;
    }

    .mg-xs-40 {
        margin: 40px;
    }

    .mg-xs-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-xs-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-xs-t-40 {
        margin-top: 40px;
    }

    .mg-xs-r-40 {
        margin-right: 40px;
    }

    .mg-xs-b-40 {
        margin-bottom: 40px;
    }

    .mg-xs-l-40 {
        margin-left: 40px;
    }

    .mg-xs-45 {
        margin: 45px;
    }

    .mg-xs-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-xs-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-xs-t-45 {
        margin-top: 45px;
    }

    .mg-xs-r-45 {
        margin-right: 45px;
    }

    .mg-xs-b-45 {
        margin-bottom: 45px;
    }

    .mg-xs-l-45 {
        margin-left: 45px;
    }

    .mg-xs-50 {
        margin: 50px;
    }

    .mg-xs-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-xs-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-xs-t-50 {
        margin-top: 50px;
    }

    .mg-xs-r-50 {
        margin-right: 50px;
    }

    .mg-xs-b-50 {
        margin-bottom: 50px;
    }

    .mg-xs-l-50 {
        margin-left: 50px;
    }

    .mg-xs-55 {
        margin: 55px;
    }

    .mg-xs-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-xs-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-xs-t-55 {
        margin-top: 55px;
    }

    .mg-xs-r-55 {
        margin-right: 55px;
    }

    .mg-xs-b-55 {
        margin-bottom: 55px;
    }

    .mg-xs-l-55 {
        margin-left: 55px;
    }

    .mg-xs-60 {
        margin: 60px;
    }

    .mg-xs-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-xs-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-xs-t-60 {
        margin-top: 60px;
    }

    .mg-xs-r-60 {
        margin-right: 60px;
    }

    .mg-xs-b-60 {
        margin-bottom: 60px;
    }

    .mg-xs-l-60 {
        margin-left: 60px;
    }

    .mg-xs-65 {
        margin: 65px;
    }

    .mg-xs-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-xs-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-xs-t-65 {
        margin-top: 65px;
    }

    .mg-xs-r-65 {
        margin-right: 65px;
    }

    .mg-xs-b-65 {
        margin-bottom: 65px;
    }

    .mg-xs-l-65 {
        margin-left: 65px;
    }

    .mg-xs-70 {
        margin: 70px;
    }

    .mg-xs-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-xs-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-xs-t-70 {
        margin-top: 70px;
    }

    .mg-xs-r-70 {
        margin-right: 70px;
    }

    .mg-xs-b-70 {
        margin-bottom: 70px;
    }

    .mg-xs-l-70 {
        margin-left: 70px;
    }

    .mg-xs-75 {
        margin: 75px;
    }

    .mg-xs-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-xs-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-xs-t-75 {
        margin-top: 75px;
    }

    .mg-xs-r-75 {
        margin-right: 75px;
    }

    .mg-xs-b-75 {
        margin-bottom: 75px;
    }

    .mg-xs-l-75 {
        margin-left: 75px;
    }

    .mg-xs-80 {
        margin: 80px;
    }

    .mg-xs-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-xs-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-xs-t-80 {
        margin-top: 80px;
    }

    .mg-xs-r-80 {
        margin-right: 80px;
    }

    .mg-xs-b-80 {
        margin-bottom: 80px;
    }

    .mg-xs-l-80 {
        margin-left: 80px;
    }

    .mg-xs-85 {
        margin: 85px;
    }

    .mg-xs-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-xs-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-xs-t-85 {
        margin-top: 85px;
    }

    .mg-xs-r-85 {
        margin-right: 85px;
    }

    .mg-xs-b-85 {
        margin-bottom: 85px;
    }

    .mg-xs-l-85 {
        margin-left: 85px;
    }

    .mg-xs-90 {
        margin: 90px;
    }

    .mg-xs-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-xs-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-xs-t-90 {
        margin-top: 90px;
    }

    .mg-xs-r-90 {
        margin-right: 90px;
    }

    .mg-xs-b-90 {
        margin-bottom: 90px;
    }

    .mg-xs-l-90 {
        margin-left: 90px;
    }

    .mg-xs-95 {
        margin: 95px;
    }

    .mg-xs-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-xs-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-xs-t-95 {
        margin-top: 95px;
    }

    .mg-xs-r-95 {
        margin-right: 95px;
    }

    .mg-xs-b-95 {
        margin-bottom: 95px;
    }

    .mg-xs-l-95 {
        margin-left: 95px;
    }

    .mg-xs-100 {
        margin: 100px;
    }

    .mg-xs-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-xs-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-xs-t-100 {
        margin-top: 100px;
    }

    .mg-xs-r-100 {
        margin-right: 100px;
    }

    .mg-xs-b-100 {
        margin-bottom: 100px;
    }

    .mg-xs-l-100 {
        margin-left: 100px;
    }

    .mg-xs-t-auto {
        margin-top: auto;
    }

    .mg-xs-r-auto {
        margin-right: auto;
    }

    .mg-xs-b-auto {
        margin-bottom: auto;
    }

    .mg-xs-l-auto {
        margin-left: auto;
    }

    .mg-xs-auto {
        margin: auto;
    }

    .mg-xs-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 576px) {
    .mg-sm-0 {
        margin: 0px;
    }

    .mg-sm-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-sm-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-sm-t-0 {
        margin-top: 0px;
    }

    .mg-sm-r-0 {
        margin-right: 0px;
    }

    .mg-sm-b-0 {
        margin-bottom: 0px;
    }

    .mg-sm-l-0 {
        margin-left: 0px;
    }

    .mg-sm-1 {
        margin: 1px;
    }

    .mg-sm-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-sm-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-sm-t-1 {
        margin-top: 1px;
    }

    .mg-sm-r-1 {
        margin-right: 1px;
    }

    .mg-sm-b-1 {
        margin-bottom: 1px;
    }

    .mg-sm-l-1 {
        margin-left: 1px;
    }

    .mg-sm-2 {
        margin: 2px;
    }

    .mg-sm-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-sm-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-sm-t-2 {
        margin-top: 2px;
    }

    .mg-sm-r-2 {
        margin-right: 2px;
    }

    .mg-sm-b-2 {
        margin-bottom: 2px;
    }

    .mg-sm-l-2 {
        margin-left: 2px;
    }

    .mg-sm-3 {
        margin: 3px;
    }

    .mg-sm-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-sm-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-sm-t-3 {
        margin-top: 3px;
    }

    .mg-sm-r-3 {
        margin-right: 3px;
    }

    .mg-sm-b-3 {
        margin-bottom: 3px;
    }

    .mg-sm-l-3 {
        margin-left: 3px;
    }

    .mg-sm-4 {
        margin: 4px;
    }

    .mg-sm-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-sm-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-sm-t-4 {
        margin-top: 4px;
    }

    .mg-sm-r-4 {
        margin-right: 4px;
    }

    .mg-sm-b-4 {
        margin-bottom: 4px;
    }

    .mg-sm-l-4 {
        margin-left: 4px;
    }

    .mg-sm-5 {
        margin: 5px;
    }

    .mg-sm-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-sm-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-sm-t-5 {
        margin-top: 5px;
    }

    .mg-sm-r-5 {
        margin-right: 5px;
    }

    .mg-sm-b-5 {
        margin-bottom: 5px;
    }

    .mg-sm-l-5 {
        margin-left: 5px;
    }

    .mg-sm-6 {
        margin: 6px;
    }

    .mg-sm-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-sm-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-sm-t-6 {
        margin-top: 6px;
    }

    .mg-sm-r-6 {
        margin-right: 6px;
    }

    .mg-sm-b-6 {
        margin-bottom: 6px;
    }

    .mg-sm-l-6 {
        margin-left: 6px;
    }

    .mg-sm-7 {
        margin: 7px;
    }

    .mg-sm-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-sm-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-sm-t-7 {
        margin-top: 7px;
    }

    .mg-sm-r-7 {
        margin-right: 7px;
    }

    .mg-sm-b-7 {
        margin-bottom: 7px;
    }

    .mg-sm-l-7 {
        margin-left: 7px;
    }

    .mg-sm-8 {
        margin: 8px;
    }

    .mg-sm-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-sm-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-sm-t-8 {
        margin-top: 8px;
    }

    .mg-sm-r-8 {
        margin-right: 8px;
    }

    .mg-sm-b-8 {
        margin-bottom: 8px;
    }

    .mg-sm-l-8 {
        margin-left: 8px;
    }

    .mg-sm-9 {
        margin: 9px;
    }

    .mg-sm-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-sm-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-sm-t-9 {
        margin-top: 9px;
    }

    .mg-sm-r-9 {
        margin-right: 9px;
    }

    .mg-sm-b-9 {
        margin-bottom: 9px;
    }

    .mg-sm-l-9 {
        margin-left: 9px;
    }

    .mg-sm-10 {
        margin: 10px;
    }

    .mg-sm-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-sm-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-sm-t-10 {
        margin-top: 10px;
    }

    .mg-sm-r-10 {
        margin-right: 10px;
    }

    .mg-sm-b-10 {
        margin-bottom: 10px;
    }

    .mg-sm-l-10 {
        margin-left: 10px;
    }

    .mg-sm-15 {
        margin: 15px;
    }

    .mg-sm-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-sm-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-sm-t-15 {
        margin-top: 15px;
    }

    .mg-sm-r-15 {
        margin-right: 15px;
    }

    .mg-sm-b-15 {
        margin-bottom: 15px;
    }

    .mg-sm-l-15 {
        margin-left: 15px;
    }

    .mg-sm-20 {
        margin: 20px;
    }

    .mg-sm-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-sm-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-sm-t-20 {
        margin-top: 20px;
    }

    .mg-sm-r-20 {
        margin-right: 20px;
    }

    .mg-sm-b-20 {
        margin-bottom: 20px;
    }

    .mg-sm-l-20 {
        margin-left: 20px;
    }

    .mg-sm-25 {
        margin: 25px;
    }

    .mg-sm-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-sm-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-sm-t-25 {
        margin-top: 25px;
    }

    .mg-sm-r-25 {
        margin-right: 25px;
    }

    .mg-sm-b-25 {
        margin-bottom: 25px;
    }

    .mg-sm-l-25 {
        margin-left: 25px;
    }

    .mg-sm-30 {
        margin: 30px;
    }

    .mg-sm-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-sm-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-sm-t-30 {
        margin-top: 30px;
    }

    .mg-sm-r-30 {
        margin-right: 30px;
    }

    .mg-sm-b-30 {
        margin-bottom: 30px;
    }

    .mg-sm-l-30 {
        margin-left: 30px;
    }

    .mg-sm-35 {
        margin: 35px;
    }

    .mg-sm-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-sm-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-sm-t-35 {
        margin-top: 35px;
    }

    .mg-sm-r-35 {
        margin-right: 35px;
    }

    .mg-sm-b-35 {
        margin-bottom: 35px;
    }

    .mg-sm-l-35 {
        margin-left: 35px;
    }

    .mg-sm-40 {
        margin: 40px;
    }

    .mg-sm-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-sm-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-sm-t-40 {
        margin-top: 40px;
    }

    .mg-sm-r-40 {
        margin-right: 40px;
    }

    .mg-sm-b-40 {
        margin-bottom: 40px;
    }

    .mg-sm-l-40 {
        margin-left: 40px;
    }

    .mg-sm-45 {
        margin: 45px;
    }

    .mg-sm-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-sm-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-sm-t-45 {
        margin-top: 45px;
    }

    .mg-sm-r-45 {
        margin-right: 45px;
    }

    .mg-sm-b-45 {
        margin-bottom: 45px;
    }

    .mg-sm-l-45 {
        margin-left: 45px;
    }

    .mg-sm-50 {
        margin: 50px;
    }

    .mg-sm-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-sm-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-sm-t-50 {
        margin-top: 50px;
    }

    .mg-sm-r-50 {
        margin-right: 50px;
    }

    .mg-sm-b-50 {
        margin-bottom: 50px;
    }

    .mg-sm-l-50 {
        margin-left: 50px;
    }

    .mg-sm-55 {
        margin: 55px;
    }

    .mg-sm-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-sm-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-sm-t-55 {
        margin-top: 55px;
    }

    .mg-sm-r-55 {
        margin-right: 55px;
    }

    .mg-sm-b-55 {
        margin-bottom: 55px;
    }

    .mg-sm-l-55 {
        margin-left: 55px;
    }

    .mg-sm-60 {
        margin: 60px;
    }

    .mg-sm-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-sm-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-sm-t-60 {
        margin-top: 60px;
    }

    .mg-sm-r-60 {
        margin-right: 60px;
    }

    .mg-sm-b-60 {
        margin-bottom: 60px;
    }

    .mg-sm-l-60 {
        margin-left: 60px;
    }

    .mg-sm-65 {
        margin: 65px;
    }

    .mg-sm-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-sm-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-sm-t-65 {
        margin-top: 65px;
    }

    .mg-sm-r-65 {
        margin-right: 65px;
    }

    .mg-sm-b-65 {
        margin-bottom: 65px;
    }

    .mg-sm-l-65 {
        margin-left: 65px;
    }

    .mg-sm-70 {
        margin: 70px;
    }

    .mg-sm-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-sm-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-sm-t-70 {
        margin-top: 70px;
    }

    .mg-sm-r-70 {
        margin-right: 70px;
    }

    .mg-sm-b-70 {
        margin-bottom: 70px;
    }

    .mg-sm-l-70 {
        margin-left: 70px;
    }

    .mg-sm-75 {
        margin: 75px;
    }

    .mg-sm-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-sm-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-sm-t-75 {
        margin-top: 75px;
    }

    .mg-sm-r-75 {
        margin-right: 75px;
    }

    .mg-sm-b-75 {
        margin-bottom: 75px;
    }

    .mg-sm-l-75 {
        margin-left: 75px;
    }

    .mg-sm-80 {
        margin: 80px;
    }

    .mg-sm-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-sm-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-sm-t-80 {
        margin-top: 80px;
    }

    .mg-sm-r-80 {
        margin-right: 80px;
    }

    .mg-sm-b-80 {
        margin-bottom: 80px;
    }

    .mg-sm-l-80 {
        margin-left: 80px;
    }

    .mg-sm-85 {
        margin: 85px;
    }

    .mg-sm-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-sm-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-sm-t-85 {
        margin-top: 85px;
    }

    .mg-sm-r-85 {
        margin-right: 85px;
    }

    .mg-sm-b-85 {
        margin-bottom: 85px;
    }

    .mg-sm-l-85 {
        margin-left: 85px;
    }

    .mg-sm-90 {
        margin: 90px;
    }

    .mg-sm-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-sm-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-sm-t-90 {
        margin-top: 90px;
    }

    .mg-sm-r-90 {
        margin-right: 90px;
    }

    .mg-sm-b-90 {
        margin-bottom: 90px;
    }

    .mg-sm-l-90 {
        margin-left: 90px;
    }

    .mg-sm-95 {
        margin: 95px;
    }

    .mg-sm-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-sm-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-sm-t-95 {
        margin-top: 95px;
    }

    .mg-sm-r-95 {
        margin-right: 95px;
    }

    .mg-sm-b-95 {
        margin-bottom: 95px;
    }

    .mg-sm-l-95 {
        margin-left: 95px;
    }

    .mg-sm-100 {
        margin: 100px;
    }

    .mg-sm-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-sm-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-sm-t-100 {
        margin-top: 100px;
    }

    .mg-sm-r-100 {
        margin-right: 100px;
    }

    .mg-sm-b-100 {
        margin-bottom: 100px;
    }

    .mg-sm-l-100 {
        margin-left: 100px;
    }

    .mg-sm-t-auto {
        margin-top: auto;
    }

    .mg-sm-r-auto {
        margin-right: auto;
    }

    .mg-sm-b-auto {
        margin-bottom: auto;
    }

    .mg-sm-l-auto {
        margin-left: auto;
    }

    .mg-sm-auto {
        margin: auto;
    }

    .mg-sm-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 768px) {
    .mg-md-0 {
        margin: 0px;
    }

    .mg-md-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-md-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-md-t-0 {
        margin-top: 0px;
    }

    .mg-md-r-0 {
        margin-right: 0px;
    }

    .mg-md-b-0 {
        margin-bottom: 0px;
    }

    .mg-md-l-0 {
        margin-left: 0px;
    }

    .mg-md-1 {
        margin: 1px;
    }

    .mg-md-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-md-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-md-t-1 {
        margin-top: 1px;
    }

    .mg-md-r-1 {
        margin-right: 1px;
    }

    .mg-md-b-1 {
        margin-bottom: 1px;
    }

    .mg-md-l-1 {
        margin-left: 1px;
    }

    .mg-md-2 {
        margin: 2px;
    }

    .mg-md-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-md-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-md-t-2 {
        margin-top: 2px;
    }

    .mg-md-r-2 {
        margin-right: 2px;
    }

    .mg-md-b-2 {
        margin-bottom: 2px;
    }

    .mg-md-l-2 {
        margin-left: 2px;
    }

    .mg-md-3 {
        margin: 3px;
    }

    .mg-md-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-md-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-md-t-3 {
        margin-top: 3px;
    }

    .mg-md-r-3 {
        margin-right: 3px;
    }

    .mg-md-b-3 {
        margin-bottom: 3px;
    }

    .mg-md-l-3 {
        margin-left: 3px;
    }

    .mg-md-4 {
        margin: 4px;
    }

    .mg-md-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-md-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-md-t-4 {
        margin-top: 4px;
    }

    .mg-md-r-4 {
        margin-right: 4px;
    }

    .mg-md-b-4 {
        margin-bottom: 4px;
    }

    .mg-md-l-4 {
        margin-left: 4px;
    }

    .mg-md-5 {
        margin: 5px;
    }

    .mg-md-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-md-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-md-t-5 {
        margin-top: 5px;
    }

    .mg-md-r-5 {
        margin-right: 5px;
    }

    .mg-md-b-5 {
        margin-bottom: 5px;
    }

    .mg-md-l-5 {
        margin-left: 5px;
    }

    .mg-md-6 {
        margin: 6px;
    }

    .mg-md-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-md-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-md-t-6 {
        margin-top: 6px;
    }

    .mg-md-r-6 {
        margin-right: 6px;
    }

    .mg-md-b-6 {
        margin-bottom: 6px;
    }

    .mg-md-l-6 {
        margin-left: 6px;
    }

    .mg-md-7 {
        margin: 7px;
    }

    .mg-md-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-md-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-md-t-7 {
        margin-top: 7px !important;
    }

    .mg-md-r-7 {
        margin-right: 7px;
    }

    .mg-md-b-7 {
        margin-bottom: 7px;
    }

    .mg-md-l-7 {
        margin-left: 7px;
    }

    .mg-md-8 {
        margin: 8px;
    }

    .mg-md-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-md-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-md-t-8 {
        margin-top: 8px;
    }

    .mg-md-r-8 {
        margin-right: 8px;
    }

    .mg-md-b-8 {
        margin-bottom: 8px;
    }

    .mg-md-l-8 {
        margin-left: 8px;
    }

    .mg-md-9 {
        margin: 9px;
    }

    .mg-md-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-md-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-md-t-9 {
        margin-top: 9px;
    }

    .mg-md-r-9 {
        margin-right: 9px;
    }

    .mg-md-b-9 {
        margin-bottom: 9px;
    }

    .mg-md-l-9 {
        margin-left: 9px;
    }

    .mg-md-10 {
        margin: 10px;
    }

    .mg-md-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-md-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-md-t-10 {
        margin-top: 10px;
    }

    .mg-md-r-10 {
        margin-right: 10px;
    }

    .mg-md-b-10 {
        margin-bottom: 10px;
    }

    .mg-md-l-10 {
        margin-left: 10px;
    }

    .mg-md-15 {
        margin: 15px;
    }

    .mg-md-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-md-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-md-t-15 {
        margin-top: 15px;
    }

    .mg-md-r-15 {
        margin-right: 15px;
    }

    .mg-md-b-15 {
        margin-bottom: 15px;
    }

    .mg-md-l-15 {
        margin-left: 15px;
    }

    .mg-md-20 {
        margin: 20px;
    }

    .mg-md-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-md-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-md-t-20 {
        margin-top: 20px;
    }

    .mg-md-r-20 {
        margin-right: 20px;
    }

    .mg-md-b-20 {
        margin-bottom: 20px;
    }

    .mg-md-l-20 {
        margin-left: 20px;
    }

    .mg-md-25 {
        margin: 25px;
    }

    .mg-md-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-md-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-md-t-25 {
        margin-top: 25px;
    }

    .mg-md-r-25 {
        margin-right: 25px;
    }

    .mg-md-b-25 {
        margin-bottom: 25px;
    }

    .mg-md-l-25 {
        margin-left: 25px;
    }

    .mg-md-30 {
        margin: 30px;
    }

    .mg-md-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-md-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-md-t-30 {
        margin-top: 30px;
    }

    .mg-md-r-30 {
        margin-right: 30px;
    }

    .mg-md-b-30 {
        margin-bottom: 30px;
    }

    .mg-md-l-30 {
        margin-left: 30px;
    }

    .mg-md-35 {
        margin: 35px;
    }

    .mg-md-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-md-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-md-t-35 {
        margin-top: 35px;
    }

    .mg-md-r-35 {
        margin-right: 35px;
    }

    .mg-md-b-35 {
        margin-bottom: 35px;
    }

    .mg-md-l-35 {
        margin-left: 35px;
    }

    .mg-md-40 {
        margin: 40px;
    }

    .mg-md-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-md-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-md-t-40 {
        margin-top: 40px;
    }

    .mg-md-r-40 {
        margin-right: 40px;
    }

    .mg-md-b-40 {
        margin-bottom: 40px;
    }

    .mg-md-l-40 {
        margin-left: 40px;
    }

    .mg-md-45 {
        margin: 45px;
    }

    .mg-md-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-md-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-md-t-45 {
        margin-top: 45px;
    }

    .mg-md-r-45 {
        margin-right: 45px;
    }

    .mg-md-b-45 {
        margin-bottom: 45px;
    }

    .mg-md-l-45 {
        margin-left: 45px;
    }

    .mg-md-50 {
        margin: 50px;
    }

    .mg-md-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-md-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-md-t-50 {
        margin-top: 50px;
    }

    .mg-md-r-50 {
        margin-right: 50px;
    }

    .mg-md-b-50 {
        margin-bottom: 50px;
    }

    .mg-md-l-50 {
        margin-left: 50px;
    }

    .mg-md-55 {
        margin: 55px;
    }

    .mg-md-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-md-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-md-t-55 {
        margin-top: 55px;
    }

    .mg-md-r-55 {
        margin-right: 55px;
    }

    .mg-md-b-55 {
        margin-bottom: 55px;
    }

    .mg-md-l-55 {
        margin-left: 55px;
    }

    .mg-md-60 {
        margin: 60px;
    }

    .mg-md-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-md-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-md-t-60 {
        margin-top: 60px;
    }

    .mg-md-r-60 {
        margin-right: 60px;
    }

    .mg-md-b-60 {
        margin-bottom: 60px;
    }

    .mg-md-l-60 {
        margin-left: 60px;
    }

    .mg-md-65 {
        margin: 65px;
    }

    .mg-md-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-md-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-md-t-65 {
        margin-top: 65px;
    }

    .mg-md-r-65 {
        margin-right: 65px;
    }

    .mg-md-b-65 {
        margin-bottom: 65px;
    }

    .mg-md-l-65 {
        margin-left: 65px;
    }

    .mg-md-70 {
        margin: 70px;
    }

    .mg-md-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-md-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-md-t-70 {
        margin-top: 70px;
    }

    .mg-md-r-70 {
        margin-right: 70px;
    }

    .mg-md-b-70 {
        margin-bottom: 70px;
    }

    .mg-md-l-70 {
        margin-left: 70px;
    }

    .mg-md-75 {
        margin: 75px;
    }

    .mg-md-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-md-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-md-t-75 {
        margin-top: 75px;
    }

    .mg-md-r-75 {
        margin-right: 75px;
    }

    .mg-md-b-75 {
        margin-bottom: 75px;
    }

    .mg-md-l-75 {
        margin-left: 75px;
    }

    .mg-md-80 {
        margin: 80px;
    }

    .mg-md-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-md-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-md-t-80 {
        margin-top: 80px;
    }

    .mg-md-r-80 {
        margin-right: 80px;
    }

    .mg-md-b-80 {
        margin-bottom: 80px;
    }

    .mg-md-l-80 {
        margin-left: 80px;
    }

    .mg-md-85 {
        margin: 85px;
    }

    .mg-md-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-md-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-md-t-85 {
        margin-top: 85px;
    }

    .mg-md-r-85 {
        margin-right: 85px;
    }

    .mg-md-b-85 {
        margin-bottom: 85px;
    }

    .mg-md-l-85 {
        margin-left: 85px;
    }

    .mg-md-90 {
        margin: 90px;
    }

    .mg-md-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-md-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-md-t-90 {
        margin-top: 90px;
    }

    .mg-md-r-90 {
        margin-right: 90px;
    }

    .mg-md-b-90 {
        margin-bottom: 90px;
    }

    .mg-md-l-90 {
        margin-left: 90px;
    }

    .mg-md-95 {
        margin: 95px;
    }

    .mg-md-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-md-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-md-t-95 {
        margin-top: 95px;
    }

    .mg-md-r-95 {
        margin-right: 95px;
    }

    .mg-md-b-95 {
        margin-bottom: 95px;
    }

    .mg-md-l-95 {
        margin-left: 95px;
    }

    .mg-md-100 {
        margin: 100px;
    }

    .mg-md-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-md-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-md-t-100 {
        margin-top: 100px;
    }

    .mg-md-r-100 {
        margin-right: 100px;
    }

    .mg-md-b-100 {
        margin-bottom: 100px;
    }

    .mg-md-l-100 {
        margin-left: 100px;
    }

    .mg-md-t-auto {
        margin-top: auto;
    }

    .mg-md-r-auto {
        margin-right: auto;
    }

    .mg-md-b-auto {
        margin-bottom: auto;
    }

    .mg-md-l-auto {
        margin-left: auto;
    }

    .mg-md-auto {
        margin: auto;
    }

    .mg-md-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 992px) {
    .mg-lg-0 {
        margin: 0px;
    }

    .mg-lg-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-lg-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-lg-t-0 {
        margin-top: 0px;
    }

    .mg-lg-r-0 {
        margin-right: 0px;
    }

    .mg-lg-b-0 {
        margin-bottom: 0px;
    }

    .mg-lg-l-0 {
        margin-left: 0px;
    }

    .mg-lg-1 {
        margin: 1px;
    }

    .mg-lg-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-lg-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-lg-t-1 {
        margin-top: 1px;
    }

    .mg-lg-r-1 {
        margin-right: 1px;
    }

    .mg-lg-b-1 {
        margin-bottom: 1px;
    }

    .mg-lg-l-1 {
        margin-left: 1px;
    }

    .mg-lg-2 {
        margin: 2px;
    }

    .mg-lg-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-lg-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-lg-t-2 {
        margin-top: 2px;
    }

    .mg-lg-r-2 {
        margin-right: 2px;
    }

    .mg-lg-b-2 {
        margin-bottom: 2px;
    }

    .mg-lg-l-2 {
        margin-left: 2px;
    }

    .mg-lg-3 {
        margin: 3px;
    }

    .mg-lg-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-lg-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-lg-t-3 {
        margin-top: 3px;
    }

    .mg-lg-r-3 {
        margin-right: 3px;
    }

    .mg-lg-b-3 {
        margin-bottom: 3px;
    }

    .mg-lg-l-3 {
        margin-left: 3px;
    }

    .mg-lg-4 {
        margin: 4px;
    }

    .mg-lg-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-lg-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-lg-t-4 {
        margin-top: 4px;
    }

    .mg-lg-r-4 {
        margin-right: 4px;
    }

    .mg-lg-b-4 {
        margin-bottom: 4px;
    }

    .mg-lg-l-4 {
        margin-left: 4px;
    }

    .mg-lg-5 {
        margin: 5px;
    }

    .mg-lg-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-lg-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-lg-t-5 {
        margin-top: 5px;
    }

    .mg-lg-r-5 {
        margin-right: 5px;
    }

    .mg-lg-b-5 {
        margin-bottom: 5px;
    }

    .mg-lg-l-5 {
        margin-left: 5px;
    }

    .mg-lg-6 {
        margin: 6px;
    }

    .mg-lg-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-lg-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-lg-t-6 {
        margin-top: 6px;
    }

    .mg-lg-r-6 {
        margin-right: 6px;
    }

    .mg-lg-b-6 {
        margin-bottom: 6px;
    }

    .mg-lg-l-6 {
        margin-left: 6px;
    }

    .mg-lg-7 {
        margin: 7px;
    }

    .mg-lg-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-lg-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-lg-t-7 {
        margin-top: 7px;
    }

    .mg-lg-r-7 {
        margin-right: 7px;
    }

    .mg-lg-b-7 {
        margin-bottom: 7px;
    }

    .mg-lg-l-7 {
        margin-left: 7px;
    }

    .mg-lg-8 {
        margin: 8px;
    }

    .mg-lg-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-lg-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-lg-t-8 {
        margin-top: 8px;
    }

    .mg-lg-r-8 {
        margin-right: 8px;
    }

    .mg-lg-b-8 {
        margin-bottom: 8px;
    }

    .mg-lg-l-8 {
        margin-left: 8px;
    }

    .mg-lg-9 {
        margin: 9px;
    }

    .mg-lg-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-lg-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-lg-t-9 {
        margin-top: 9px;
    }

    .mg-lg-r-9 {
        margin-right: 9px;
    }

    .mg-lg-b-9 {
        margin-bottom: 9px;
    }

    .mg-lg-l-9 {
        margin-left: 9px;
    }

    .mg-lg-10 {
        margin: 10px;
    }

    .mg-lg-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-lg-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-lg-t-10 {
        margin-top: 10px;
    }

    .mg-lg-r-10 {
        margin-right: 10px;
    }

    .mg-lg-b-10 {
        margin-bottom: 10px;
    }

    .mg-lg-l-10 {
        margin-left: 10px;
    }

    .mg-lg-15 {
        margin: 15px;
    }

    .mg-lg-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-lg-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-lg-t-15 {
        margin-top: 15px;
    }

    .mg-lg-r-15 {
        margin-right: 15px;
    }

    .mg-lg-b-15 {
        margin-bottom: 15px;
    }

    .mg-lg-l-15 {
        margin-left: 15px;
    }

    .mg-lg-20 {
        margin: 20px;
    }

    .mg-lg-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-lg-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-lg-t-20 {
        margin-top: 20px;
    }

    .mg-lg-r-20 {
        margin-right: 20px;
    }

    .mg-lg-b-20 {
        margin-bottom: 20px;
    }

    .mg-lg-l-20 {
        margin-left: 20px;
    }

    .mg-lg-25 {
        margin: 25px;
    }

    .mg-lg-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-lg-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-lg-t-25 {
        margin-top: 25px;
    }

    .mg-lg-r-25 {
        margin-right: 25px;
    }

    .mg-lg-b-25 {
        margin-bottom: 25px;
    }

    .mg-lg-l-25 {
        margin-left: 25px;
    }

    .mg-lg-30 {
        margin: 30px;
    }

    .mg-lg-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-lg-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-lg-t-30 {
        margin-top: 30px;
    }

    .mg-lg-r-30 {
        margin-right: 30px;
    }

    .mg-lg-b-30 {
        margin-bottom: 30px;
    }

    .mg-lg-l-30 {
        margin-left: 30px;
    }

    .mg-lg-35 {
        margin: 35px;
    }

    .mg-lg-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-lg-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-lg-t-35 {
        margin-top: 35px;
    }

    .mg-lg-r-35 {
        margin-right: 35px;
    }

    .mg-lg-b-35 {
        margin-bottom: 35px;
    }

    .mg-lg-l-35 {
        margin-left: 35px;
    }

    .mg-lg-40 {
        margin: 40px;
    }

    .mg-lg-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-lg-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-lg-t-40 {
        margin-top: 40px;
    }

    .mg-lg-r-40 {
        margin-right: 40px;
    }

    .mg-lg-b-40 {
        margin-bottom: 40px;
    }

    .mg-lg-l-40 {
        margin-left: 40px;
    }

    .mg-lg-45 {
        margin: 45px;
    }

    .mg-lg-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-lg-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-lg-t-45 {
        margin-top: 45px;
    }

    .mg-lg-r-45 {
        margin-right: 45px;
    }

    .mg-lg-b-45 {
        margin-bottom: 45px;
    }

    .mg-lg-l-45 {
        margin-left: 45px;
    }

    .mg-lg-50 {
        margin: 50px;
    }

    .mg-lg-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-lg-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-lg-t-50 {
        margin-top: 50px;
    }

    .mg-lg-r-50 {
        margin-right: 50px;
    }

    .mg-lg-b-50 {
        margin-bottom: 50px;
    }

    .mg-lg-l-50 {
        margin-left: 50px;
    }

    .mg-lg-55 {
        margin: 55px;
    }

    .mg-lg-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-lg-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-lg-t-55 {
        margin-top: 55px;
    }

    .mg-lg-r-55 {
        margin-right: 55px;
    }

    .mg-lg-b-55 {
        margin-bottom: 55px;
    }

    .mg-lg-l-55 {
        margin-left: 55px;
    }

    .mg-lg-60 {
        margin: 60px;
    }

    .mg-lg-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-lg-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-lg-t-60 {
        margin-top: 60px;
    }

    .mg-lg-r-60 {
        margin-right: 60px;
    }

    .mg-lg-b-60 {
        margin-bottom: 60px;
    }

    .mg-lg-l-60 {
        margin-left: 60px;
    }

    .mg-lg-65 {
        margin: 65px;
    }

    .mg-lg-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-lg-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-lg-t-65 {
        margin-top: 65px;
    }

    .mg-lg-r-65 {
        margin-right: 65px;
    }

    .mg-lg-b-65 {
        margin-bottom: 65px;
    }

    .mg-lg-l-65 {
        margin-left: 65px;
    }

    .mg-lg-70 {
        margin: 70px;
    }

    .mg-lg-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-lg-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-lg-t-70 {
        margin-top: 70px;
    }

    .mg-lg-r-70 {
        margin-right: 70px;
    }

    .mg-lg-b-70 {
        margin-bottom: 70px;
    }

    .mg-lg-l-70 {
        margin-left: 70px;
    }

    .mg-lg-75 {
        margin: 75px;
    }

    .mg-lg-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-lg-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-lg-t-75 {
        margin-top: 75px;
    }

    .mg-lg-r-75 {
        margin-right: 75px;
    }

    .mg-lg-b-75 {
        margin-bottom: 75px;
    }

    .mg-lg-l-75 {
        margin-left: 75px;
    }

    .mg-lg-80 {
        margin: 80px;
    }

    .mg-lg-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-lg-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-lg-t-80 {
        margin-top: 80px;
    }

    .mg-lg-r-80 {
        margin-right: 80px;
    }

    .mg-lg-b-80 {
        margin-bottom: 80px;
    }

    .mg-lg-l-80 {
        margin-left: 80px;
    }

    .mg-lg-85 {
        margin: 85px;
    }

    .mg-lg-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-lg-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-lg-t-85 {
        margin-top: 85px;
    }

    .mg-lg-r-85 {
        margin-right: 85px;
    }

    .mg-lg-b-85 {
        margin-bottom: 85px;
    }

    .mg-lg-l-85 {
        margin-left: 85px;
    }

    .mg-lg-90 {
        margin: 90px;
    }

    .mg-lg-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-lg-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-lg-t-90 {
        margin-top: 90px;
    }

    .mg-lg-r-90 {
        margin-right: 90px;
    }

    .mg-lg-b-90 {
        margin-bottom: 90px;
    }

    .mg-lg-l-90 {
        margin-left: 90px;
    }

    .mg-lg-95 {
        margin: 95px;
    }

    .mg-lg-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-lg-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-lg-t-95 {
        margin-top: 95px;
    }

    .mg-lg-r-95 {
        margin-right: 95px;
    }

    .mg-lg-b-95 {
        margin-bottom: 95px;
    }

    .mg-lg-l-95 {
        margin-left: 95px;
    }

    .mg-lg-100 {
        margin: 100px;
    }

    .mg-lg-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-lg-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-lg-t-100 {
        margin-top: 100px;
    }

    .mg-lg-r-100 {
        margin-right: 100px;
    }

    .mg-lg-b-100 {
        margin-bottom: 100px;
    }

    .mg-lg-l-100 {
        margin-left: 100px;
    }

    .mg-lg-t-auto {
        margin-top: auto;
    }

    .mg-lg-r-auto {
        margin-right: auto;
    }

    .mg-lg-b-auto {
        margin-bottom: auto;
    }

    .mg-lg-l-auto {
        margin-left: auto;
    }

    .mg-lg-auto {
        margin: auto;
    }

    .mg-lg-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 1200px) {
    .mg-xl-0 {
        margin: 0px;
    }

    .mg-xl-y-0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .mg-xl-x-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .mg-xl-t-0 {
        margin-top: 0px;
    }

    .mg-xl-r-0 {
        margin-right: 0px;
    }

    .mg-xl-b-0 {
        margin-bottom: 0px;
    }

    .mg-xl-l-0 {
        margin-left: 0px;
    }

    .mg-xl-1 {
        margin: 1px;
    }

    .mg-xl-y-1 {
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .mg-xl-x-1 {
        margin-left: 1px;
        margin-right: 1px;
    }

    .mg-xl-t-1 {
        margin-top: 1px;
    }

    .mg-xl-r-1 {
        margin-right: 1px;
    }

    .mg-xl-b-1 {
        margin-bottom: 1px;
    }

    .mg-xl-l-1 {
        margin-left: 1px;
    }

    .mg-xl-2 {
        margin: 2px;
    }

    .mg-xl-y-2 {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mg-xl-x-2 {
        margin-left: 2px;
        margin-right: 2px;
    }

    .mg-xl-t-2 {
        margin-top: 2px;
    }

    .mg-xl-r-2 {
        margin-right: 2px;
    }

    .mg-xl-b-2 {
        margin-bottom: 2px;
    }

    .mg-xl-l-2 {
        margin-left: 2px;
    }

    .mg-xl-3 {
        margin: 3px;
    }

    .mg-xl-y-3 {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .mg-xl-x-3 {
        margin-left: 3px;
        margin-right: 3px;
    }

    .mg-xl-t-3 {
        margin-top: 3px;
    }

    .mg-xl-r-3 {
        margin-right: 3px;
    }

    .mg-xl-b-3 {
        margin-bottom: 3px;
    }

    .mg-xl-l-3 {
        margin-left: 3px;
    }

    .mg-xl-4 {
        margin: 4px;
    }

    .mg-xl-y-4 {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .mg-xl-x-4 {
        margin-left: 4px;
        margin-right: 4px;
    }

    .mg-xl-t-4 {
        margin-top: 4px;
    }

    .mg-xl-r-4 {
        margin-right: 4px;
    }

    .mg-xl-b-4 {
        margin-bottom: 4px;
    }

    .mg-xl-l-4 {
        margin-left: 4px;
    }

    .mg-xl-5 {
        margin: 5px;
    }

    .mg-xl-y-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mg-xl-x-5 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .mg-xl-t-5 {
        margin-top: 5px;
    }

    .mg-xl-r-5 {
        margin-right: 5px;
    }

    .mg-xl-b-5 {
        margin-bottom: 5px;
    }

    .mg-xl-l-5 {
        margin-left: 5px;
    }

    .mg-xl-6 {
        margin: 6px;
    }

    .mg-xl-y-6 {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mg-xl-x-6 {
        margin-left: 6px;
        margin-right: 6px;
    }

    .mg-xl-t-6 {
        margin-top: 6px;
    }

    .mg-xl-r-6 {
        margin-right: 6px;
    }

    .mg-xl-b-6 {
        margin-bottom: 6px;
    }

    .mg-xl-l-6 {
        margin-left: 6px;
    }

    .mg-xl-7 {
        margin: 7px;
    }

    .mg-xl-y-7 {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .mg-xl-x-7 {
        margin-left: 7px;
        margin-right: 7px;
    }

    .mg-xl-t-7 {
        margin-top: 7px;
    }

    .mg-xl-r-7 {
        margin-right: 7px;
    }

    .mg-xl-b-7 {
        margin-bottom: 7px;
    }

    .mg-xl-l-7 {
        margin-left: 7px;
    }

    .mg-xl-8 {
        margin: 8px;
    }

    .mg-xl-y-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .mg-xl-x-8 {
        margin-left: 8px;
        margin-right: 8px;
    }

    .mg-xl-t-8 {
        margin-top: 8px;
    }

    .mg-xl-r-8 {
        margin-right: 8px;
    }

    .mg-xl-b-8 {
        margin-bottom: 8px;
    }

    .mg-xl-l-8 {
        margin-left: 8px;
    }

    .mg-xl-9 {
        margin: 9px;
    }

    .mg-xl-y-9 {
        margin-top: 9px;
        margin-bottom: 9px;
    }

    .mg-xl-x-9 {
        margin-left: 9px;
        margin-right: 9px;
    }

    .mg-xl-t-9 {
        margin-top: 9px;
    }

    .mg-xl-r-9 {
        margin-right: 9px;
    }

    .mg-xl-b-9 {
        margin-bottom: 9px;
    }

    .mg-xl-l-9 {
        margin-left: 9px;
    }

    .mg-xl-10 {
        margin: 10px;
    }

    .mg-xl-y-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mg-xl-x-10 {
        margin-left: 10px;
        margin-right: 10px;
    }

    .mg-xl-t-10 {
        margin-top: 10px;
    }

    .mg-xl-r-10 {
        margin-right: 10px;
    }

    .mg-xl-b-10 {
        margin-bottom: 10px;
    }

    .mg-xl-l-10 {
        margin-left: 10px;
    }

    .mg-xl-15 {
        margin: 15px;
    }

    .mg-xl-y-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mg-xl-x-15 {
        margin-left: 15px;
        margin-right: 15px;
    }

    .mg-xl-t-15 {
        margin-top: 15px;
    }

    .mg-xl-r-15 {
        margin-right: 15px;
    }

    .mg-xl-b-15 {
        margin-bottom: 15px;
    }

    .mg-xl-l-15 {
        margin-left: 15px;
    }

    .mg-xl-20 {
        margin: 20px;
    }

    .mg-xl-y-20 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mg-xl-x-20 {
        margin-left: 20px;
        margin-right: 20px;
    }

    .mg-xl-t-20 {
        margin-top: 20px;
    }

    .mg-xl-r-20 {
        margin-right: 20px;
    }

    .mg-xl-b-20 {
        margin-bottom: 20px;
    }

    .mg-xl-l-20 {
        margin-left: 20px;
    }

    .mg-xl-25 {
        margin: 25px;
    }

    .mg-xl-y-25 {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mg-xl-x-25 {
        margin-left: 25px;
        margin-right: 25px;
    }

    .mg-xl-t-25 {
        margin-top: 25px;
    }

    .mg-xl-r-25 {
        margin-right: 25px;
    }

    .mg-xl-b-25 {
        margin-bottom: 25px;
    }

    .mg-xl-l-25 {
        margin-left: 25px;
    }

    .mg-xl-30 {
        margin: 30px;
    }

    .mg-xl-y-30 {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mg-xl-x-30 {
        margin-left: 30px;
        margin-right: 30px;
    }

    .mg-xl-t-30 {
        margin-top: 30px;
    }

    .mg-xl-r-30 {
        margin-right: 30px;
    }

    .mg-xl-b-30 {
        margin-bottom: 30px;
    }

    .mg-xl-l-30 {
        margin-left: 30px;
    }

    .mg-xl-35 {
        margin: 35px;
    }

    .mg-xl-y-35 {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .mg-xl-x-35 {
        margin-left: 35px;
        margin-right: 35px;
    }

    .mg-xl-t-35 {
        margin-top: 35px;
    }

    .mg-xl-r-35 {
        margin-right: 35px;
    }

    .mg-xl-b-35 {
        margin-bottom: 35px;
    }

    .mg-xl-l-35 {
        margin-left: 35px;
    }

    .mg-xl-40 {
        margin: 40px;
    }

    .mg-xl-y-40 {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mg-xl-x-40 {
        margin-left: 40px;
        margin-right: 40px;
    }

    .mg-xl-t-40 {
        margin-top: 40px;
    }

    .mg-xl-r-40 {
        margin-right: 40px;
    }

    .mg-xl-b-40 {
        margin-bottom: 40px;
    }

    .mg-xl-l-40 {
        margin-left: 40px;
    }

    .mg-xl-45 {
        margin: 45px;
    }

    .mg-xl-y-45 {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .mg-xl-x-45 {
        margin-left: 45px;
        margin-right: 45px;
    }

    .mg-xl-t-45 {
        margin-top: 45px;
    }

    .mg-xl-r-45 {
        margin-right: 45px;
    }

    .mg-xl-b-45 {
        margin-bottom: 45px;
    }

    .mg-xl-l-45 {
        margin-left: 45px;
    }

    .mg-xl-50 {
        margin: 50px;
    }

    .mg-xl-y-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mg-xl-x-50 {
        margin-left: 50px;
        margin-right: 50px;
    }

    .mg-xl-t-50 {
        margin-top: 50px;
    }

    .mg-xl-r-50 {
        margin-right: 50px;
    }

    .mg-xl-b-50 {
        margin-bottom: 50px;
    }

    .mg-xl-l-50 {
        margin-left: 50px;
    }

    .mg-xl-55 {
        margin: 55px;
    }

    .mg-xl-y-55 {
        margin-top: 55px;
        margin-bottom: 55px;
    }

    .mg-xl-x-55 {
        margin-left: 55px;
        margin-right: 55px;
    }

    .mg-xl-t-55 {
        margin-top: 55px;
    }

    .mg-xl-r-55 {
        margin-right: 55px;
    }

    .mg-xl-b-55 {
        margin-bottom: 55px;
    }

    .mg-xl-l-55 {
        margin-left: 55px;
    }

    .mg-xl-60 {
        margin: 60px;
    }

    .mg-xl-y-60 {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mg-xl-x-60 {
        margin-left: 60px;
        margin-right: 60px;
    }

    .mg-xl-t-60 {
        margin-top: 60px;
    }

    .mg-xl-r-60 {
        margin-right: 60px;
    }

    .mg-xl-b-60 {
        margin-bottom: 60px;
    }

    .mg-xl-l-60 {
        margin-left: 60px;
    }

    .mg-xl-65 {
        margin: 65px;
    }

    .mg-xl-y-65 {
        margin-top: 65px;
        margin-bottom: 65px;
    }

    .mg-xl-x-65 {
        margin-left: 65px;
        margin-right: 65px;
    }

    .mg-xl-t-65 {
        margin-top: 65px;
    }

    .mg-xl-r-65 {
        margin-right: 65px;
    }

    .mg-xl-b-65 {
        margin-bottom: 65px;
    }

    .mg-xl-l-65 {
        margin-left: 65px;
    }

    .mg-xl-70 {
        margin: 70px;
    }

    .mg-xl-y-70 {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .mg-xl-x-70 {
        margin-left: 70px;
        margin-right: 70px;
    }

    .mg-xl-t-70 {
        margin-top: 70px;
    }

    .mg-xl-r-70 {
        margin-right: 70px;
    }

    .mg-xl-b-70 {
        margin-bottom: 70px;
    }

    .mg-xl-l-70 {
        margin-left: 70px;
    }

    .mg-xl-75 {
        margin: 75px;
    }

    .mg-xl-y-75 {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    .mg-xl-x-75 {
        margin-left: 75px;
        margin-right: 75px;
    }

    .mg-xl-t-75 {
        margin-top: 75px;
    }

    .mg-xl-r-75 {
        margin-right: 75px;
    }

    .mg-xl-b-75 {
        margin-bottom: 75px;
    }

    .mg-xl-l-75 {
        margin-left: 75px;
    }

    .mg-xl-80 {
        margin: 80px;
    }

    .mg-xl-y-80 {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    .mg-xl-x-80 {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mg-xl-t-80 {
        margin-top: 80px;
    }

    .mg-xl-r-80 {
        margin-right: 80px;
    }

    .mg-xl-b-80 {
        margin-bottom: 80px;
    }

    .mg-xl-l-80 {
        margin-left: 80px;
    }

    .mg-xl-85 {
        margin: 85px;
    }

    .mg-xl-y-85 {
        margin-top: 85px;
        margin-bottom: 85px;
    }

    .mg-xl-x-85 {
        margin-left: 85px;
        margin-right: 85px;
    }

    .mg-xl-t-85 {
        margin-top: 85px;
    }

    .mg-xl-r-85 {
        margin-right: 85px;
    }

    .mg-xl-b-85 {
        margin-bottom: 85px;
    }

    .mg-xl-l-85 {
        margin-left: 85px;
    }

    .mg-xl-90 {
        margin: 90px;
    }

    .mg-xl-y-90 {
        margin-top: 90px;
        margin-bottom: 90px;
    }

    .mg-xl-x-90 {
        margin-left: 90px;
        margin-right: 90px;
    }

    .mg-xl-t-90 {
        margin-top: 90px;
    }

    .mg-xl-r-90 {
        margin-right: 90px;
    }

    .mg-xl-b-90 {
        margin-bottom: 90px;
    }

    .mg-xl-l-90 {
        margin-left: 90px;
    }

    .mg-xl-95 {
        margin: 95px;
    }

    .mg-xl-y-95 {
        margin-top: 95px;
        margin-bottom: 95px;
    }

    .mg-xl-x-95 {
        margin-left: 95px;
        margin-right: 95px;
    }

    .mg-xl-t-95 {
        margin-top: 95px;
    }

    .mg-xl-r-95 {
        margin-right: 95px;
    }

    .mg-xl-b-95 {
        margin-bottom: 95px;
    }

    .mg-xl-l-95 {
        margin-left: 95px;
    }

    .mg-xl-100 {
        margin: 100px;
    }

    .mg-xl-y-100 {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .mg-xl-x-100 {
        margin-left: 100px;
        margin-right: 100px;
    }

    .mg-xl-t-100 {
        margin-top: 100px;
    }

    .mg-xl-r-100 {
        margin-right: 100px;
    }

    .mg-xl-b-100 {
        margin-bottom: 100px;
    }

    .mg-xl-l-100 {
        margin-left: 100px;
    }

    .mg-xl-t-auto {
        margin-top: auto;
    }

    .mg-xl-r-auto {
        margin-right: auto;
    }

    .mg-xl-b-auto {
        margin-bottom: auto;
    }

    .mg-xl-l-auto {
        margin-left: auto;
    }

    .mg-xl-auto {
        margin: auto;
    }

    .mg-xl-x-auto {
        margin-left: auto;
        margin-right: auto;
    }
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.me-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ms-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.me-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ms-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.me-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}
.select-pending :where(.css-dev-only-do-not-override-w8mnev).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #f1911d;
  color: #fff;
}

.select-pending :where(.css-w8mnev).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f1911d;
  color: #fff;
}

.select-approve :where(.css-dev-only-do-not-override-w8mnev).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #15A552;
  color: #fff;
}


.ms-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.me-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}
.overflow-x-auto{
overflow-x: auto;
}

.ms-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.me-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ms-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.me-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ms-5,
.mx-5 {
    margin-left: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.me-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ms-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .me-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ms-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .me-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ms-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .me-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ms-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .me-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ms-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .me-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ms-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .me-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ms-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .me-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ms-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .me-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ms-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .me-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ms-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .me-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ms-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .me-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ms-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .me-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ms-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .me-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ms-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .me-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ms-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .me-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ms-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .me-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ms-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .me-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ms-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .me-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ms-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .me-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ms-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .me-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ms-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .me-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ms-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .me-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ms-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .me-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ms-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .me-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ms-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .me-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ms-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .me-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ms-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .me-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ms-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .me-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ms-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .me-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ms-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .me-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ms-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .me-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ms-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .me-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ms-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .me-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ms-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .me-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ms-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .me-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ms-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .me-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ms-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .me-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ms-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .me-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ms-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .me-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ms-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .me-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ms-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .me-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ms-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .me-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ms-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .me-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ms-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .me-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ms-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .me-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ms-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .me-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ms-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .me-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ms-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .me-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ms-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

/* ###### Margin ###### */
/* ###### Opacity  ###### */
.op-0 {
    opacity: 0;
}

.op-1 {
    opacity: 0.1;
}

.op-2 {
    opacity: 0.2;
}

.op-3 {
    opacity: 0.3;
}

.op-4 {
    opacity: 0.4;
}

.op-5 {
    opacity: 0.5;
}

.op-6 {
    opacity: 0.6;
}

.op-7 {
    opacity: 0.7;
}

.op-8 {
    opacity: 0.8;
}

.op-9 {
    opacity: 0.9;
}

.opacity-1 {
    opacity: 1;
}
.w-full{
    width: 100%;
}

.shadow-base {
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

.shadow-1 {
    box-shadow: 0 0 7px 7px rgba(28, 39, 60, 0.1);
}

.shadow-2 {
    box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(162, 169, 204, 0.075) !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(162, 169, 204, 0.15) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(162, 169, 204, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.overflow-y-auto {
    overflow-y: auto;
}

@media (min-width: 480px) {
    .op-xs-0 {
        opacity: 0;
    }

    .op-xs-1 {
        opacity: 0.1;
    }

    .op-xs-2 {
        opacity: 0.2;
    }

    .op-xs-3 {
        opacity: 0.3;
    }

    .op-xs-4 {
        opacity: 0.4;
    }

    .op-xs-5 {
        opacity: 0.5;
    }

    .op-xs-6 {
        opacity: 0.6;
    }

    .op-xs-7 {
        opacity: 0.7;
    }

    .op-xs-8 {
        opacity: 0.8;
    }

    .op-xs-9 {
        opacity: 0.9;
    }
}

@media (min-width: 576px) {
    .op-sm-0 {
        opacity: 0;
    }

    .op-sm-1 {
        opacity: 0.1;
    }

    .op-sm-2 {
        opacity: 0.2;
    }

    .op-sm-3 {
        opacity: 0.3;
    }

    .op-sm-4 {
        opacity: 0.4;
    }

    .op-sm-5 {
        opacity: 0.5;
    }

    .op-sm-6 {
        opacity: 0.6;
    }

    .op-sm-7 {
        opacity: 0.7;
    }

    .op-sm-8 {
        opacity: 0.8;
    }

    .op-sm-9 {
        opacity: 0.9;
    }
}

@media (min-width: 768px) {
    .op-md-0 {
        opacity: 0;
    }

    .op-md-1 {
        opacity: 0.1;
    }

    .op-md-2 {
        opacity: 0.2;
    }

    .op-md-3 {
        opacity: 0.3;
    }

    .op-md-4 {
        opacity: 0.4;
    }

    .op-md-5 {
        opacity: 0.5;
    }

    .op-md-6 {
        opacity: 0.6;
    }

    .op-md-7 {
        opacity: 0.7;
    }

    .op-md-8 {
        opacity: 0.8;
    }

    .op-md-9 {
        opacity: 0.9;
    }
}

@media (min-width: 992px) {
    .op-lg-0 {
        opacity: 0;
    }

    .op-lg-1 {
        opacity: 0.1;
    }

    .op-lg-2 {
        opacity: 0.2;
    }

    .op-lg-3 {
        opacity: 0.3;
    }

    .op-lg-4 {
        opacity: 0.4;
    }

    .op-lg-5 {
        opacity: 0.5;
    }

    .op-lg-6 {
        opacity: 0.6;
    }

    .op-lg-7 {
        opacity: 0.7;
    }

    .op-lg-8 {
        opacity: 0.8;
    }

    .op-lg-9 {
        opacity: 0.9;
    }
}

@media (min-width: 1200px) {
    .op-xl-0 {
        opacity: 0;
    }

    .op-xl-1 {
        opacity: 0.1;
    }

    .op-xl-2 {
        opacity: 0.2;
    }

    .op-xl-3 {
        opacity: 0.3;
    }

    .op-xl-4 {
        opacity: 0.4;
    }

    .op-xl-5 {
        opacity: 0.5;
    }

    .op-xl-6 {
        opacity: 0.6;
    }

    .op-xl-7 {
        opacity: 0.7;
    }

    .op-xl-8 {
        opacity: 0.8;
    }

    .op-xl-9 {
        opacity: 0.9;
    }
}

/* ###### Opacity  ###### */
/* ###### Padding  ###### */
.pd-0 {
    padding: 0px;
}

.pd-y-0 {
    padding-top: 0px;
    padding-bottom: 0px;
}

.pd-x-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.pd-t-0 {
    padding-top: 0px;
}

.pd-r-0 {
    padding-right: 0px;
}

.pd-b-0 {
    padding-bottom: 0px;
}

.pd-l-0 {
    padding-left: 0px;
}

.pd-1 {
    padding: 1px;
}

.pd-y-1 {
    padding-top: 1px;
    padding-bottom: 1px;
}

.pd-x-1 {
    padding-left: 1px;
    padding-right: 1px;
}

.pd-t-1 {
    padding-top: 1px;
}

.pd-r-1 {
    padding-right: 1px;
}

.pd-b-1 {
    padding-bottom: 1px;
}

.pd-l-1 {
    padding-left: 1px;
}

.pd-2 {
    padding: 2px;
}

.pd-y-2 {
    padding-top: 2px;
    padding-bottom: 2px;
}

.pd-x-2 {
    padding-left: 2px;
    padding-right: 2px;
}

.pd-t-2 {
    padding-top: 2px;
}

.pd-r-2 {
    padding-right: 2px;
}

.pd-b-2 {
    padding-bottom: 2px;
}

.pd-l-2 {
    padding-left: 2px;
}

.pd-3 {
    padding: 3px;
}

.pd-y-3 {
    padding-top: 3px;
    padding-bottom: 3px;
}

.pd-x-3 {
    padding-left: 3px;
    padding-right: 3px;
}

.pd-t-3 {
    padding-top: 3px;
}

.pd-r-3 {
    padding-right: 3px;
}

.pd-b-3 {
    padding-bottom: 3px;
}

.pd-l-3 {
    padding-left: 3px;
}

.pd-4 {
    padding: 4px;
}

.pd-y-4 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.pd-x-4 {
    padding-left: 4px;
    padding-right: 4px;
}

.pd-t-4 {
    padding-top: 4px;
}

.pd-r-4 {
    padding-right: 4px;
}

.pd-b-4 {
    padding-bottom: 4px;
}

.pd-l-4 {
    padding-left: 4px;
}

.pd-5 {
    padding: 5px;
}

.pd-y-5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.pd-x-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.pd-t-5 {
    padding-top: 5px;
}

.pd-r-5 {
    padding-right: 5px;
}

.pd-b-5 {
    padding-bottom: 5px;
}

.pd-l-5 {
    padding-left: 5px;
}

.pd-6 {
    padding: 6px;
}

.pd-y-6 {
    padding-top: 6px;
    padding-bottom: 6px;
}

.pd-x-6 {
    padding-left: 6px;
    padding-right: 6px;
}

.pd-t-6 {
    padding-top: 6px;
}

.pd-r-6 {
    padding-right: 6px;
}

.pd-b-6 {
    padding-bottom: 6px;
}

.pd-l-6 {
    padding-left: 6px;
}

.pd-7 {
    padding: 7px;
}

.pd-y-7 {
    padding-top: 7px;
    padding-bottom: 7px;
}

.pd-x-7 {
    padding-left: 7px;
    padding-right: 7px;
}

.pd-t-7 {
    padding-top: 7px;
}

.pd-r-7 {
    padding-right: 7px;
}

.pd-b-7 {
    padding-bottom: 7px;
}

.pd-l-7 {
    padding-left: 7px;
}

.pd-8 {
    padding: 8px;
}

.pd-y-8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.pd-x-8 {
    padding-left: 8px;
    padding-right: 8px;
}

.pd-t-8 {
    padding-top: 8px;
}

.pd-r-8 {
    padding-right: 8px;
}

.pd-b-8 {
    padding-bottom: 8px;
}

.pd-l-8 {
    padding-left: 8px;
}

.pd-9 {
    padding: 9px;
}

.pd-y-9 {
    padding-top: 9px;
    padding-bottom: 9px;
}

.pd-x-9 {
    padding-left: 9px;
    padding-right: 9px;
}

.pd-t-9 {
    padding-top: 9px;
}

.pd-r-9 {
    padding-right: 9px;
}

.pd-b-9 {
    padding-bottom: 9px;
}

.pd-l-9 {
    padding-left: 9px;
}

.pd-10 {
    padding: 10px;
}

.pd-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.pd-x-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.pd-t-10 {
    padding-top: 10px;
}

.pd-r-10 {
    padding-right: 10px;
}

.pd-b-10 {
    padding-bottom: 10px;
}

.pd-l-10 {
    padding-left: 10px;
}

.pd-15 {
    padding: 15px;
}

.pd-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pd-x-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.pd-t-15 {
    padding-top: 15px;
}

.pd-r-15 {
    padding-right: 15px;
}

.pd-b-15 {
    padding-bottom: 15px;
}

.pd-l-15 {
    padding-left: 15px;
}

.pd-20 {
    padding: 20px;
}

.pd-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pd-x-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.pd-t-20 {
    padding-top: 20px;
}

.pd-r-20 {
    padding-right: 20px;
}

.pd-b-20 {
    padding-bottom: 20px;
}

.pd-l-20 {
    padding-left: 20px;
}

.pd-25 {
    padding: 25px;
}

.pd-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.pd-x-25 {
    padding-left: 25px;
    padding-right: 25px;
}

.pd-t-25 {
    padding-top: 25px;
}

.pd-r-25 {
    padding-right: 25px;
}

.pd-b-25 {
    padding-bottom: 25px;
}

.pd-l-25 {
    padding-left: 25px;
}

.pd-30 {
    padding: 30px;
}

.pd-y-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pd-x-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.pd-t-30 {
    padding-top: 30px;
}

.pd-r-30 {
    padding-right: 30px;
}

.pd-b-30 {
    padding-bottom: 30px;
}

.pd-l-30 {
    padding-left: 30px;
}

.pd-35 {
    padding: 35px;
}

.pd-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.pd-x-35 {
    padding-left: 35px;
    padding-right: 35px;
}

.pd-t-35 {
    padding-top: 35px;
}

.pd-r-35 {
    padding-right: 35px;
}

.pd-b-35 {
    padding-bottom: 35px;
}

.pd-l-35 {
    padding-left: 35px;
}

.pd-40 {
    padding: 40px;
}

.pd-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.pd-x-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.pd-t-40 {
    padding-top: 40px;
}

.pd-r-40 {
    padding-right: 40px;
}

.pd-b-40 {
    padding-bottom: 40px;
}

.pd-l-40 {
    padding-left: 40px;
}

.pd-45 {
    padding: 45px;
}

.pd-y-45 {
    padding-top: 45px;
    padding-bottom: 45px;
}

.pd-x-45 {
    padding-left: 45px;
    padding-right: 45px;
}

.pd-t-45 {
    padding-top: 45px;
}

.pd-r-45 {
    padding-right: 45px;
}

.pd-b-45 {
    padding-bottom: 45px;
}

.pd-l-45 {
    padding-left: 45px;
}

.pd-50 {
    padding: 50px;
}

.pd-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pd-x-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.pd-t-50 {
    padding-top: 50px;
}

.pd-r-50 {
    padding-right: 50px;
}

.pd-b-50 {
    padding-bottom: 50px;
}

.pd-l-50 {
    padding-left: 50px;
}

.pd-55 {
    padding: 55px;
}

.pd-y-55 {
    padding-top: 55px;
    padding-bottom: 55px;
}

.pd-x-55 {
    padding-left: 55px;
    padding-right: 55px;
}

.pd-t-55 {
    padding-top: 55px;
}

.pd-r-55 {
    padding-right: 55px;
}

.pd-b-55 {
    padding-bottom: 55px;
}

.pd-l-55 {
    padding-left: 55px;
}

.pd-60 {
    padding: 60px;
}

.pd-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.pd-x-60 {
    padding-left: 60px;
    padding-right: 60px;
}

.pd-t-60 {
    padding-top: 60px;
}

.pd-r-60 {
    padding-right: 60px;
}

.pd-b-60 {
    padding-bottom: 60px;
}

.pd-l-60 {
    padding-left: 60px;
}

.pd-65 {
    padding: 65px;
}

.pd-y-65 {
    padding-top: 65px;
    padding-bottom: 65px;
}

.pd-x-65 {
    padding-left: 65px;
    padding-right: 65px;
}

.pd-t-65 {
    padding-top: 65px;
}

.pd-r-65 {
    padding-right: 65px;
}

.pd-b-65 {
    padding-bottom: 65px;
}

.pd-l-65 {
    padding-left: 65px;
}

.pd-70 {
    padding: 70px;
}

.pd-y-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.pd-x-70 {
    padding-left: 70px;
    padding-right: 70px;
}

.pd-t-70 {
    padding-top: 70px;
}

.pd-r-70 {
    padding-right: 70px;
}

.pd-b-70 {
    padding-bottom: 70px;
}

.pd-l-70 {
    padding-left: 70px;
}

.pd-75 {
    padding: 75px;
}

.pd-y-75 {
    padding-top: 75px;
    padding-bottom: 75px;
}

.pd-x-75 {
    padding-left: 75px;
    padding-right: 75px;
}

.pd-t-75 {
    padding-top: 75px;
}

.pd-r-75 {
    padding-right: 75px;
}

.pd-b-75 {
    padding-bottom: 75px;
}

.pd-l-75 {
    padding-left: 75px;
}

.pd-80 {
    padding: 80px;
}

.pd-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.pd-x-80 {
    padding-left: 80px;
    padding-right: 80px;
}

.pd-t-80 {
    padding-top: 80px;
}

.pd-r-80 {
    padding-right: 80px;
}

.pd-b-80 {
    padding-bottom: 80px;
}

.pd-l-80 {
    padding-left: 80px;
}

.pd-85 {
    padding: 85px;
}

.pd-y-85 {
    padding-top: 85px;
    padding-bottom: 85px;
}

.pd-x-85 {
    padding-left: 85px;
    padding-right: 85px;
}

.pd-t-85 {
    padding-top: 85px;
}

.pd-r-85 {
    padding-right: 85px;
}

.pd-b-85 {
    padding-bottom: 85px;
}

.pd-l-85 {
    padding-left: 85px;
}

.pd-90 {
    padding: 90px;
}

.pd-y-90 {
    padding-top: 90px;
    padding-bottom: 90px;
}

.pd-x-90 {
    padding-left: 90px;
    padding-right: 90px;
}

.pd-t-90 {
    padding-top: 90px;
}

.pd-r-90 {
    padding-right: 90px;
}

.pd-b-90 {
    padding-bottom: 90px;
}

.pd-l-90 {
    padding-left: 90px;
}

.pd-95 {
    padding: 95px;
}

.pd-y-95 {
    padding-top: 95px;
    padding-bottom: 95px;
}

.pd-x-95 {
    padding-left: 95px;
    padding-right: 95px;
}

.pd-t-95 {
    padding-top: 95px;
}

.pd-r-95 {
    padding-right: 95px;
}

.pd-b-95 {
    padding-bottom: 95px;
}

.pd-l-95 {
    padding-left: 95px;
}

.pd-100 {
    padding: 100px;
}

.pd-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pd-x-100 {
    padding-left: 100px;
    padding-right: 100px;
}

.pd-t-100 {
    padding-top: 100px;
}

.pd-r-100 {
    padding-right: 100px;
}

.pd-b-100 {
    padding-bottom: 100px;
}

.pd-l-100 {
    padding-left: 100px;
}

.pd-110 {
    padding: 110px;
}

.pd-y-110 {
    padding-top: 110px;
    padding-bottom: 110px;
}

.pd-x-110 {
    padding-left: 110px;
    padding-right: 110px;
}

.pd-t-110 {
    padding-top: 110px;
}

.pd-r-110 {
    padding-right: 110px;
}

.pd-b-110 {
    padding-bottom: 110px;
}

.pd-l-110 {
    padding-left: 110px;
}

.pd-120 {
    padding: 120px;
}

.pd-y-120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.pd-x-120 {
    padding-left: 120px;
    padding-right: 120px;
}

.pd-t-120 {
    padding-top: 120px;
}

.pd-r-120 {
    padding-right: 120px;
}

.pd-b-120 {
    padding-bottom: 120px;
}

.pd-l-120 {
    padding-left: 120px;
}

.pd-130 {
    padding: 130px;
}

.pd-y-130 {
    padding-top: 130px;
    padding-bottom: 130px;
}

.pd-x-130 {
    padding-left: 130px;
    padding-right: 130px;
}

.pd-t-130 {
    padding-top: 130px;
}

.pd-r-130 {
    padding-right: 130px;
}

.pd-b-130 {
    padding-bottom: 130px;
}

.pd-l-130 {
    padding-left: 130px;
}

.pd-140 {
    padding: 140px;
}

.pd-y-140 {
    padding-top: 140px;
    padding-bottom: 140px;
}

.pd-x-140 {
    padding-left: 140px;
    padding-right: 140px;
}

.pd-t-140 {
    padding-top: 140px;
}

.pd-r-140 {
    padding-right: 140px;
}

.pd-b-140 {
    padding-bottom: 140px;
}

.pd-l-140 {
    padding-left: 140px;
}

.pd-150 {
    padding: 150px;
}

.pd-y-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.pd-x-150 {
    padding-left: 150px;
    padding-right: 150px;
}

.pd-t-150 {
    padding-top: 150px;
}

.pd-r-150 {
    padding-right: 150px;
}

.pd-b-150 {
    padding-bottom: 150px;
}

.pd-l-150 {
    padding-left: 150px;
}

.pd-160 {
    padding: 160px;
}

.pd-y-160 {
    padding-top: 160px;
    padding-bottom: 160px;
}

.pd-x-160 {
    padding-left: 160px;
    padding-right: 160px;
}

.pd-t-160 {
    padding-top: 160px;
}

.pd-r-160 {
    padding-right: 160px;
}

.pd-b-160 {
    padding-bottom: 160px;
}

.pd-l-160 {
    padding-left: 160px;
}

.pd-170 {
    padding: 170px;
}

.pd-y-170 {
    padding-top: 170px;
    padding-bottom: 170px;
}

.pd-x-170 {
    padding-left: 170px;
    padding-right: 170px;
}

.pd-t-170 {
    padding-top: 170px;
}

.pd-r-170 {
    padding-right: 170px;
}

.pd-b-170 {
    padding-bottom: 170px;
}

.pd-l-170 {
    padding-left: 170px;
}

.pd-180 {
    padding: 180px;
}

.pd-y-180 {
    padding-top: 180px;
    padding-bottom: 180px;
}

.pd-x-180 {
    padding-left: 180px;
    padding-right: 180px;
}

.pd-t-180 {
    padding-top: 180px;
}

.pd-r-180 {
    padding-right: 180px;
}

.pd-b-180 {
    padding-bottom: 180px;
}

.pd-l-180 {
    padding-left: 180px;
}

.pd-190 {
    padding: 190px;
}

.pd-y-190 {
    padding-top: 190px;
    padding-bottom: 190px;
}

.pd-x-190 {
    padding-left: 190px;
    padding-right: 190px;
}

.pd-t-190 {
    padding-top: 190px;
}

.pd-r-190 {
    padding-right: 190px;
}

.pd-b-190 {
    padding-bottom: 190px;
}

.pd-l-190 {
    padding-left: 190px;
}

.pd-200 {
    padding: 200px;
}

.pd-y-200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.pd-x-200 {
    padding-left: 200px;
    padding-right: 200px;
}

.pd-t-200 {
    padding-top: 200px;
}

.pd-r-200 {
    padding-right: 200px;
}

.pd-b-200 {
    padding-bottom: 200px;
}

.pd-l-200 {
    padding-left: 200px;
}

@media (min-width: 480px) {
    .pd-xs-0 {
        padding: 0px;
    }

    .pd-xs-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-xs-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-xs-t-0 {
        padding-top: 0px;
    }

    .pd-xs-r-0 {
        padding-right: 0px;
    }

    .pd-xs-b-0 {
        padding-bottom: 0px;
    }

    .pd-xs-l-0 {
        padding-left: 0px;
    }

    .pd-xs-1 {
        padding: 1px;
    }

    .pd-xs-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-xs-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-xs-t-1 {
        padding-top: 1px;
    }

    .pd-xs-r-1 {
        padding-right: 1px;
    }

    .pd-xs-b-1 {
        padding-bottom: 1px;
    }

    .pd-xs-l-1 {
        padding-left: 1px;
    }

    .pd-xs-2 {
        padding: 2px;
    }

    .pd-xs-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-xs-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-xs-t-2 {
        padding-top: 2px;
    }

    .pd-xs-r-2 {
        padding-right: 2px;
    }

    .pd-xs-b-2 {
        padding-bottom: 2px;
    }

    .pd-xs-l-2 {
        padding-left: 2px;
    }

    .pd-xs-3 {
        padding: 3px;
    }

    .pd-xs-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-xs-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-xs-t-3 {
        padding-top: 3px;
    }

    .pd-xs-r-3 {
        padding-right: 3px;
    }

    .pd-xs-b-3 {
        padding-bottom: 3px;
    }

    .pd-xs-l-3 {
        padding-left: 3px;
    }

    .pd-xs-4 {
        padding: 4px;
    }

    .pd-xs-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-xs-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-xs-t-4 {
        padding-top: 4px;
    }

    .pd-xs-r-4 {
        padding-right: 4px;
    }

    .pd-xs-b-4 {
        padding-bottom: 4px;
    }

    .pd-xs-l-4 {
        padding-left: 4px;
    }

    .pd-xs-5 {
        padding: 5px;
    }

    .pd-xs-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-xs-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-xs-t-5 {
        padding-top: 5px;
    }

    .pd-xs-r-5 {
        padding-right: 5px;
    }

    .pd-xs-b-5 {
        padding-bottom: 5px;
    }

    .pd-xs-l-5 {
        padding-left: 5px;
    }

    .pd-xs-6 {
        padding: 6px;
    }

    .pd-xs-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-xs-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-xs-t-6 {
        padding-top: 6px;
    }

    .pd-xs-r-6 {
        padding-right: 6px;
    }

    .pd-xs-b-6 {
        padding-bottom: 6px;
    }

    .pd-xs-l-6 {
        padding-left: 6px;
    }

    .pd-xs-7 {
        padding: 7px;
    }

    .pd-xs-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-xs-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-xs-t-7 {
        padding-top: 7px;
    }

    .pd-xs-r-7 {
        padding-right: 7px;
    }

    .pd-xs-b-7 {
        padding-bottom: 7px;
    }

    .pd-xs-l-7 {
        padding-left: 7px;
    }

    .pd-xs-8 {
        padding: 8px;
    }

    .pd-xs-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-xs-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-xs-t-8 {
        padding-top: 8px;
    }

    .pd-xs-r-8 {
        padding-right: 8px;
    }

    .pd-xs-b-8 {
        padding-bottom: 8px;
    }

    .pd-xs-l-8 {
        padding-left: 8px;
    }

    .pd-xs-9 {
        padding: 9px;
    }

    .pd-xs-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-xs-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-xs-t-9 {
        padding-top: 9px;
    }

    .pd-xs-r-9 {
        padding-right: 9px;
    }

    .pd-xs-b-9 {
        padding-bottom: 9px;
    }

    .pd-xs-l-9 {
        padding-left: 9px;
    }

    .pd-xs-10 {
        padding: 10px;
    }

    .pd-xs-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-xs-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-xs-t-10 {
        padding-top: 10px;
    }

    .pd-xs-r-10 {
        padding-right: 10px;
    }

    .pd-xs-b-10 {
        padding-bottom: 10px;
    }

    .pd-xs-l-10 {
        padding-left: 10px;
    }

    .pd-xs-15 {
        padding: 15px;
    }

    .pd-xs-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-xs-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-xs-t-15 {
        padding-top: 15px;
    }

    .pd-xs-r-15 {
        padding-right: 15px;
    }

    .pd-xs-b-15 {
        padding-bottom: 15px;
    }

    .pd-xs-l-15 {
        padding-left: 15px;
    }

    .pd-xs-20 {
        padding: 20px;
    }

    .pd-xs-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-xs-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-xs-t-20 {
        padding-top: 20px;
    }

    .pd-xs-r-20 {
        padding-right: 20px;
    }

    .pd-xs-b-20 {
        padding-bottom: 20px;
    }

    .pd-xs-l-20 {
        padding-left: 20px;
    }

    .pd-xs-25 {
        padding: 25px;
    }

    .pd-xs-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-xs-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-xs-t-25 {
        padding-top: 25px;
    }

    .pd-xs-r-25 {
        padding-right: 25px;
    }

    .pd-xs-b-25 {
        padding-bottom: 25px;
    }

    .pd-xs-l-25 {
        padding-left: 25px;
    }

    .pd-xs-30 {
        padding: 30px;
    }

    .pd-xs-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-xs-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-xs-t-30 {
        padding-top: 30px;
    }

    .pd-xs-r-30 {
        padding-right: 30px;
    }

    .pd-xs-b-30 {
        padding-bottom: 30px;
    }

    .pd-xs-l-30 {
        padding-left: 30px;
    }

    .pd-xs-35 {
        padding: 35px;
    }

    .pd-xs-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-xs-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-xs-t-35 {
        padding-top: 35px;
    }

    .pd-xs-r-35 {
        padding-right: 35px;
    }

    .pd-xs-b-35 {
        padding-bottom: 35px;
    }

    .pd-xs-l-35 {
        padding-left: 35px;
    }

    .pd-xs-40 {
        padding: 40px;
    }

    .pd-xs-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-xs-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-xs-t-40 {
        padding-top: 40px;
    }

    .pd-xs-r-40 {
        padding-right: 40px;
    }

    .pd-xs-b-40 {
        padding-bottom: 40px;
    }

    .pd-xs-l-40 {
        padding-left: 40px;
    }

    .pd-xs-45 {
        padding: 45px;
    }

    .pd-xs-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-xs-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-xs-t-45 {
        padding-top: 45px;
    }

    .pd-xs-r-45 {
        padding-right: 45px;
    }

    .pd-xs-b-45 {
        padding-bottom: 45px;
    }

    .pd-xs-l-45 {
        padding-left: 45px;
    }

    .pd-xs-50 {
        padding: 50px;
    }

    .pd-xs-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-xs-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-xs-t-50 {
        padding-top: 50px;
    }

    .pd-xs-r-50 {
        padding-right: 50px;
    }

    .pd-xs-b-50 {
        padding-bottom: 50px;
    }

    .pd-xs-l-50 {
        padding-left: 50px;
    }

    .pd-xs-55 {
        padding: 55px;
    }

    .pd-xs-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-xs-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-xs-t-55 {
        padding-top: 55px;
    }

    .pd-xs-r-55 {
        padding-right: 55px;
    }

    .pd-xs-b-55 {
        padding-bottom: 55px;
    }

    .pd-xs-l-55 {
        padding-left: 55px;
    }

    .pd-xs-60 {
        padding: 60px;
    }

    .pd-xs-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-xs-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-xs-t-60 {
        padding-top: 60px;
    }

    .pd-xs-r-60 {
        padding-right: 60px;
    }

    .pd-xs-b-60 {
        padding-bottom: 60px;
    }

    .pd-xs-l-60 {
        padding-left: 60px;
    }

    .pd-xs-65 {
        padding: 65px;
    }

    .pd-xs-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-xs-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-xs-t-65 {
        padding-top: 65px;
    }

    .pd-xs-r-65 {
        padding-right: 65px;
    }

    .pd-xs-b-65 {
        padding-bottom: 65px;
    }

    .pd-xs-l-65 {
        padding-left: 65px;
    }

    .pd-xs-70 {
        padding: 70px;
    }

    .pd-xs-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-xs-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-xs-t-70 {
        padding-top: 70px;
    }

    .pd-xs-r-70 {
        padding-right: 70px;
    }

    .pd-xs-b-70 {
        padding-bottom: 70px;
    }

    .pd-xs-l-70 {
        padding-left: 70px;
    }

    .pd-xs-75 {
        padding: 75px;
    }

    .pd-xs-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-xs-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-xs-t-75 {
        padding-top: 75px;
    }

    .pd-xs-r-75 {
        padding-right: 75px;
    }

    .pd-xs-b-75 {
        padding-bottom: 75px;
    }

    .pd-xs-l-75 {
        padding-left: 75px;
    }

    .pd-xs-80 {
        padding: 80px;
    }

    .pd-xs-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-xs-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-xs-t-80 {
        padding-top: 80px;
    }

    .pd-xs-r-80 {
        padding-right: 80px;
    }

    .pd-xs-b-80 {
        padding-bottom: 80px;
    }

    .pd-xs-l-80 {
        padding-left: 80px;
    }

    .pd-xs-85 {
        padding: 85px;
    }

    .pd-xs-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-xs-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-xs-t-85 {
        padding-top: 85px;
    }

    .pd-xs-r-85 {
        padding-right: 85px;
    }

    .pd-xs-b-85 {
        padding-bottom: 85px;
    }

    .pd-xs-l-85 {
        padding-left: 85px;
    }

    .pd-xs-90 {
        padding: 90px;
    }

    .pd-xs-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-xs-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-xs-t-90 {
        padding-top: 90px;
    }

    .pd-xs-r-90 {
        padding-right: 90px;
    }

    .pd-xs-b-90 {
        padding-bottom: 90px;
    }

    .pd-xs-l-90 {
        padding-left: 90px;
    }

    .pd-xs-95 {
        padding: 95px;
    }

    .pd-xs-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-xs-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-xs-t-95 {
        padding-top: 95px;
    }

    .pd-xs-r-95 {
        padding-right: 95px;
    }

    .pd-xs-b-95 {
        padding-bottom: 95px;
    }

    .pd-xs-l-95 {
        padding-left: 95px;
    }

    .pd-xs-100 {
        padding: 100px;
    }

    .pd-xs-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-xs-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-xs-t-100 {
        padding-top: 100px;
    }

    .pd-xs-r-100 {
        padding-right: 100px;
    }

    .pd-xs-b-100 {
        padding-bottom: 100px;
    }

    .pd-xs-l-100 {
        padding-left: 100px;
    }

    .pd-xs-110 {
        padding: 110px;
    }

    .pd-xs-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-xs-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-xs-t-110 {
        padding-top: 110px;
    }

    .pd-xs-r-110 {
        padding-right: 110px;
    }

    .pd-xs-b-110 {
        padding-bottom: 110px;
    }

    .pd-xs-l-110 {
        padding-left: 110px;
    }

    .pd-xs-120 {
        padding: 120px;
    }

    .pd-xs-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-xs-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-xs-t-120 {
        padding-top: 120px;
    }

    .pd-xs-r-120 {
        padding-right: 120px;
    }

    .pd-xs-b-120 {
        padding-bottom: 120px;
    }

    .pd-xs-l-120 {
        padding-left: 120px;
    }

    .pd-xs-130 {
        padding: 130px;
    }

    .pd-xs-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-xs-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-xs-t-130 {
        padding-top: 130px;
    }

    .pd-xs-r-130 {
        padding-right: 130px;
    }

    .pd-xs-b-130 {
        padding-bottom: 130px;
    }

    .pd-xs-l-130 {
        padding-left: 130px;
    }

    .pd-xs-140 {
        padding: 140px;
    }

    .pd-xs-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-xs-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-xs-t-140 {
        padding-top: 140px;
    }

    .pd-xs-r-140 {
        padding-right: 140px;
    }

    .pd-xs-b-140 {
        padding-bottom: 140px;
    }

    .pd-xs-l-140 {
        padding-left: 140px;
    }

    .pd-xs-150 {
        padding: 150px;
    }

    .pd-xs-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-xs-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-xs-t-150 {
        padding-top: 150px;
    }

    .pd-xs-r-150 {
        padding-right: 150px;
    }

    .pd-xs-b-150 {
        padding-bottom: 150px;
    }

    .pd-xs-l-150 {
        padding-left: 150px;
    }

    .pd-xs-160 {
        padding: 160px;
    }

    .pd-xs-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-xs-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-xs-t-160 {
        padding-top: 160px;
    }

    .pd-xs-r-160 {
        padding-right: 160px;
    }

    .pd-xs-b-160 {
        padding-bottom: 160px;
    }

    .pd-xs-l-160 {
        padding-left: 160px;
    }

    .pd-xs-170 {
        padding: 170px;
    }

    .pd-xs-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-xs-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-xs-t-170 {
        padding-top: 170px;
    }

    .pd-xs-r-170 {
        padding-right: 170px;
    }

    .pd-xs-b-170 {
        padding-bottom: 170px;
    }

    .pd-xs-l-170 {
        padding-left: 170px;
    }

    .pd-xs-180 {
        padding: 180px;
    }

    .pd-xs-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-xs-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-xs-t-180 {
        padding-top: 180px;
    }

    .pd-xs-r-180 {
        padding-right: 180px;
    }

    .pd-xs-b-180 {
        padding-bottom: 180px;
    }

    .pd-xs-l-180 {
        padding-left: 180px;
    }

    .pd-xs-190 {
        padding: 190px;
    }

    .pd-xs-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-xs-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-xs-t-190 {
        padding-top: 190px;
    }

    .pd-xs-r-190 {
        padding-right: 190px;
    }

    .pd-xs-b-190 {
        padding-bottom: 190px;
    }

    .pd-xs-l-190 {
        padding-left: 190px;
    }

    .pd-xs-200 {
        padding: 200px;
    }

    .pd-xs-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-xs-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-xs-t-200 {
        padding-top: 200px;
    }

    .pd-xs-r-200 {
        padding-right: 200px;
    }

    .pd-xs-b-200 {
        padding-bottom: 200px;
    }

    .pd-xs-l-200 {
        padding-left: 200px;
    }
}

@media (min-width: 576px) {
    .pd-sm-0 {
        padding: 0px;
    }

    .pd-sm-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-sm-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-sm-t-0 {
        padding-top: 0px;
    }

    .pd-sm-r-0 {
        padding-right: 0px;
    }

    .pd-sm-b-0 {
        padding-bottom: 0px;
    }

    .pd-sm-l-0 {
        padding-left: 0px;
    }

    .pd-sm-1 {
        padding: 1px;
    }

    .pd-sm-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-sm-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-sm-t-1 {
        padding-top: 1px;
    }

    .pd-sm-r-1 {
        padding-right: 1px;
    }

    .pd-sm-b-1 {
        padding-bottom: 1px;
    }

    .pd-sm-l-1 {
        padding-left: 1px;
    }

    .pd-sm-2 {
        padding: 2px;
    }

    .pd-sm-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-sm-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-sm-t-2 {
        padding-top: 2px;
    }

    .pd-sm-r-2 {
        padding-right: 2px;
    }

    .pd-sm-b-2 {
        padding-bottom: 2px;
    }

    .pd-sm-l-2 {
        padding-left: 2px;
    }

    .pd-sm-3 {
        padding: 3px;
    }

    .pd-sm-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-sm-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-sm-t-3 {
        padding-top: 3px;
    }

    .pd-sm-r-3 {
        padding-right: 3px;
    }

    .pd-sm-b-3 {
        padding-bottom: 3px;
    }

    .pd-sm-l-3 {
        padding-left: 3px;
    }

    .pd-sm-4 {
        padding: 4px;
    }

    .pd-sm-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-sm-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-sm-t-4 {
        padding-top: 4px;
    }

    .pd-sm-r-4 {
        padding-right: 4px;
    }

    .pd-sm-b-4 {
        padding-bottom: 4px;
    }

    .pd-sm-l-4 {
        padding-left: 4px;
    }

    .pd-sm-5 {
        padding: 5px;
    }

    .pd-sm-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-sm-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-sm-t-5 {
        padding-top: 5px;
    }

    .pd-sm-r-5 {
        padding-right: 5px;
    }

    .pd-sm-b-5 {
        padding-bottom: 5px;
    }

    .pd-sm-l-5 {
        padding-left: 5px;
    }

    .pd-sm-6 {
        padding: 6px;
    }

    .pd-sm-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-sm-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-sm-t-6 {
        padding-top: 6px;
    }

    .pd-sm-r-6 {
        padding-right: 6px;
    }

    .pd-sm-b-6 {
        padding-bottom: 6px;
    }

    .pd-sm-l-6 {
        padding-left: 6px;
    }

    .pd-sm-7 {
        padding: 7px;
    }

    .pd-sm-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-sm-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-sm-t-7 {
        padding-top: 7px;
    }

    .pd-sm-r-7 {
        padding-right: 7px;
    }

    .pd-sm-b-7 {
        padding-bottom: 7px;
    }

    .pd-sm-l-7 {
        padding-left: 7px;
    }

    .pd-sm-8 {
        padding: 8px;
    }

    .pd-sm-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-sm-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-sm-t-8 {
        padding-top: 8px;
    }

    .pd-sm-r-8 {
        padding-right: 8px;
    }

    .pd-sm-b-8 {
        padding-bottom: 8px;
    }

    .pd-sm-l-8 {
        padding-left: 8px;
    }

    .pd-sm-9 {
        padding: 9px;
    }

    .pd-sm-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-sm-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-sm-t-9 {
        padding-top: 9px;
    }

    .pd-sm-r-9 {
        padding-right: 9px;
    }

    .pd-sm-b-9 {
        padding-bottom: 9px;
    }

    .pd-sm-l-9 {
        padding-left: 9px;
    }

    .pd-sm-10 {
        padding: 10px;
    }

    .pd-sm-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-sm-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-sm-t-10 {
        padding-top: 10px;
    }

    .pd-sm-r-10 {
        padding-right: 10px;
    }

    .pd-sm-b-10 {
        padding-bottom: 10px;
    }

    .pd-sm-l-10 {
        padding-left: 10px;
    }

    .pd-sm-15 {
        padding: 15px;
    }

    .pd-sm-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-sm-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-sm-t-15 {
        padding-top: 15px;
    }

    .pd-sm-r-15 {
        padding-right: 15px;
    }

    .pd-sm-b-15 {
        padding-bottom: 15px;
    }

    .pd-sm-l-15 {
        padding-left: 15px;
    }

    .pd-sm-20 {
        padding: 20px;
    }

    .pd-sm-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-sm-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-sm-t-20 {
        padding-top: 20px;
    }

    .pd-sm-r-20 {
        padding-right: 20px;
    }

    .pd-sm-b-20 {
        padding-bottom: 20px;
    }

    .pd-sm-l-20 {
        padding-left: 20px;
    }

    .pd-sm-25 {
        padding: 25px;
    }

    .pd-sm-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-sm-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-sm-t-25 {
        padding-top: 25px;
    }

    .pd-sm-r-25 {
        padding-right: 25px;
    }

    .pd-sm-b-25 {
        padding-bottom: 25px;
    }

    .pd-sm-l-25 {
        padding-left: 25px;
    }

    .pd-sm-30 {
        padding: 30px;
    }

    .pd-sm-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-sm-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-sm-t-30 {
        padding-top: 30px;
    }

    .pd-sm-r-30 {
        padding-right: 30px;
    }

    .pd-sm-b-30 {
        padding-bottom: 30px;
    }

    .pd-sm-l-30 {
        padding-left: 30px;
    }

    .pd-sm-35 {
        padding: 35px;
    }

    .pd-sm-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-sm-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-sm-t-35 {
        padding-top: 35px;
    }

    .pd-sm-r-35 {
        padding-right: 35px;
    }

    .pd-sm-b-35 {
        padding-bottom: 35px;
    }

    .pd-sm-l-35 {
        padding-left: 35px;
    }

    .pd-sm-40 {
        padding: 40px;
    }

    .pd-sm-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-sm-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-sm-t-40 {
        padding-top: 40px;
    }

    .pd-sm-r-40 {
        padding-right: 40px;
    }

    .pd-sm-b-40 {
        padding-bottom: 40px;
    }

    .pd-sm-l-40 {
        padding-left: 40px;
    }

    .pd-sm-45 {
        padding: 45px;
    }

    .pd-sm-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-sm-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-sm-t-45 {
        padding-top: 45px;
    }

    .pd-sm-r-45 {
        padding-right: 45px;
    }

    .pd-sm-b-45 {
        padding-bottom: 45px;
    }

    .pd-sm-l-45 {
        padding-left: 45px;
    }

    .pd-sm-50 {
        padding: 50px;
    }

    .pd-sm-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-sm-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-sm-t-50 {
        padding-top: 50px;
    }

    .pd-sm-r-50 {
        padding-right: 50px;
    }

    .pd-sm-b-50 {
        padding-bottom: 50px;
    }

    .pd-sm-l-50 {
        padding-left: 50px;
    }

    .pd-sm-55 {
        padding: 55px;
    }

    .pd-sm-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-sm-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-sm-t-55 {
        padding-top: 55px;
    }

    .pd-sm-r-55 {
        padding-right: 55px;
    }

    .pd-sm-b-55 {
        padding-bottom: 55px;
    }

    .pd-sm-l-55 {
        padding-left: 55px;
    }

    .pd-sm-60 {
        padding: 60px;
    }

    .pd-sm-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-sm-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-sm-t-60 {
        padding-top: 60px;
    }

    .pd-sm-r-60 {
        padding-right: 60px;
    }

    .pd-sm-b-60 {
        padding-bottom: 60px;
    }

    .pd-sm-l-60 {
        padding-left: 60px;
    }

    .pd-sm-65 {
        padding: 65px;
    }

    .pd-sm-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-sm-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-sm-t-65 {
        padding-top: 65px;
    }

    .pd-sm-r-65 {
        padding-right: 65px;
    }

    .pd-sm-b-65 {
        padding-bottom: 65px;
    }

    .pd-sm-l-65 {
        padding-left: 65px;
    }

    .pd-sm-70 {
        padding: 70px;
    }

    .pd-sm-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-sm-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-sm-t-70 {
        padding-top: 70px;
    }

    .pd-sm-r-70 {
        padding-right: 70px;
    }

    .pd-sm-b-70 {
        padding-bottom: 70px;
    }

    .pd-sm-l-70 {
        padding-left: 70px;
    }

    .pd-sm-75 {
        padding: 75px;
    }

    .pd-sm-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-sm-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-sm-t-75 {
        padding-top: 75px;
    }

    .pd-sm-r-75 {
        padding-right: 75px;
    }

    .pd-sm-b-75 {
        padding-bottom: 75px;
    }

    .pd-sm-l-75 {
        padding-left: 75px;
    }

    .pd-sm-80 {
        padding: 80px;
    }

    .pd-sm-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-sm-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-sm-t-80 {
        padding-top: 80px;
    }

    .pd-sm-r-80 {
        padding-right: 80px;
    }

    .pd-sm-b-80 {
        padding-bottom: 80px;
    }

    .pd-sm-l-80 {
        padding-left: 80px;
    }

    .pd-sm-85 {
        padding: 85px;
    }

    .pd-sm-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-sm-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-sm-t-85 {
        padding-top: 85px;
    }

    .pd-sm-r-85 {
        padding-right: 85px;
    }

    .pd-sm-b-85 {
        padding-bottom: 85px;
    }

    .pd-sm-l-85 {
        padding-left: 85px;
    }

    .pd-sm-90 {
        padding: 90px;
    }

    .pd-sm-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-sm-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-sm-t-90 {
        padding-top: 90px;
    }

    .pd-sm-r-90 {
        padding-right: 90px;
    }

    .pd-sm-b-90 {
        padding-bottom: 90px;
    }

    .pd-sm-l-90 {
        padding-left: 90px;
    }

    .pd-sm-95 {
        padding: 95px;
    }

    .pd-sm-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-sm-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-sm-t-95 {
        padding-top: 95px;
    }

    .pd-sm-r-95 {
        padding-right: 95px;
    }

    .pd-sm-b-95 {
        padding-bottom: 95px;
    }

    .pd-sm-l-95 {
        padding-left: 95px;
    }

    .pd-sm-100 {
        padding: 100px;
    }

    .pd-sm-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-sm-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-sm-t-100 {
        padding-top: 100px;
    }

    .pd-sm-r-100 {
        padding-right: 100px;
    }

    .pd-sm-b-100 {
        padding-bottom: 100px;
    }

    .pd-sm-l-100 {
        padding-left: 100px;
    }

    .pd-sm-110 {
        padding: 110px;
    }

    .pd-sm-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-sm-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-sm-t-110 {
        padding-top: 110px;
    }

    .pd-sm-r-110 {
        padding-right: 110px;
    }

    .pd-sm-b-110 {
        padding-bottom: 110px;
    }

    .pd-sm-l-110 {
        padding-left: 110px;
    }

    .pd-sm-120 {
        padding: 120px;
    }

    .pd-sm-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-sm-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-sm-t-120 {
        padding-top: 120px;
    }

    .pd-sm-r-120 {
        padding-right: 120px;
    }

    .pd-sm-b-120 {
        padding-bottom: 120px;
    }

    .pd-sm-l-120 {
        padding-left: 120px;
    }

    .pd-sm-130 {
        padding: 130px;
    }

    .pd-sm-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-sm-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-sm-t-130 {
        padding-top: 130px;
    }

    .pd-sm-r-130 {
        padding-right: 130px;
    }

    .pd-sm-b-130 {
        padding-bottom: 130px;
    }

    .pd-sm-l-130 {
        padding-left: 130px;
    }

    .pd-sm-140 {
        padding: 140px;
    }

    .pd-sm-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-sm-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-sm-t-140 {
        padding-top: 140px;
    }

    .pd-sm-r-140 {
        padding-right: 140px;
    }

    .pd-sm-b-140 {
        padding-bottom: 140px;
    }

    .pd-sm-l-140 {
        padding-left: 140px;
    }

    .pd-sm-150 {
        padding: 150px;
    }

    .pd-sm-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-sm-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-sm-t-150 {
        padding-top: 150px;
    }

    .pd-sm-r-150 {
        padding-right: 150px;
    }

    .pd-sm-b-150 {
        padding-bottom: 150px;
    }

    .pd-sm-l-150 {
        padding-left: 150px;
    }

    .pd-sm-160 {
        padding: 160px;
    }

    .pd-sm-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-sm-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-sm-t-160 {
        padding-top: 160px;
    }

    .pd-sm-r-160 {
        padding-right: 160px;
    }

    .pd-sm-b-160 {
        padding-bottom: 160px;
    }

    .pd-sm-l-160 {
        padding-left: 160px;
    }

    .pd-sm-170 {
        padding: 170px;
    }

    .pd-sm-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-sm-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-sm-t-170 {
        padding-top: 170px;
    }

    .pd-sm-r-170 {
        padding-right: 170px;
    }

    .pd-sm-b-170 {
        padding-bottom: 170px;
    }

    .pd-sm-l-170 {
        padding-left: 170px;
    }

    .pd-sm-180 {
        padding: 180px;
    }

    .pd-sm-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-sm-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-sm-t-180 {
        padding-top: 180px;
    }

    .pd-sm-r-180 {
        padding-right: 180px;
    }

    .pd-sm-b-180 {
        padding-bottom: 180px;
    }

    .pd-sm-l-180 {
        padding-left: 180px;
    }

    .pd-sm-190 {
        padding: 190px;
    }

    .pd-sm-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-sm-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-sm-t-190 {
        padding-top: 190px;
    }

    .pd-sm-r-190 {
        padding-right: 190px;
    }

    .pd-sm-b-190 {
        padding-bottom: 190px;
    }

    .pd-sm-l-190 {
        padding-left: 190px;
    }

    .pd-sm-200 {
        padding: 200px;
    }

    .pd-sm-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-sm-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-sm-t-200 {
        padding-top: 200px;
    }

    .pd-sm-r-200 {
        padding-right: 200px;
    }

    .pd-sm-b-200 {
        padding-bottom: 200px;
    }

    .pd-sm-l-200 {
        padding-left: 200px;
    }
}

@media (min-width: 768px) {
    .pd-md-0 {
        padding: 0px;
    }

    .pd-md-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-md-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-md-t-0 {
        padding-top: 0px;
    }

    .pd-md-r-0 {
        padding-right: 0px;
    }

    .pd-md-b-0 {
        padding-bottom: 0px;
    }

    .pd-md-l-0 {
        padding-left: 0px;
    }

    .pd-md-1 {
        padding: 1px;
    }

    .pd-md-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-md-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-md-t-1 {
        padding-top: 1px;
    }

    .pd-md-r-1 {
        padding-right: 1px;
    }

    .pd-md-b-1 {
        padding-bottom: 1px;
    }

    .pd-md-l-1 {
        padding-left: 1px;
    }

    .pd-md-2 {
        padding: 2px;
    }

    .pd-md-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-md-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-md-t-2 {
        padding-top: 2px;
    }

    .pd-md-r-2 {
        padding-right: 2px;
    }

    .pd-md-b-2 {
        padding-bottom: 2px;
    }

    .pd-md-l-2 {
        padding-left: 2px;
    }

    .pd-md-3 {
        padding: 3px;
    }

    .pd-md-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-md-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-md-t-3 {
        padding-top: 3px;
    }

    .pd-md-r-3 {
        padding-right: 3px;
    }

    .pd-md-b-3 {
        padding-bottom: 3px;
    }

    .pd-md-l-3 {
        padding-left: 3px;
    }

    .pd-md-4 {
        padding: 4px;
    }

    .pd-md-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-md-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-md-t-4 {
        padding-top: 4px;
    }

    .pd-md-r-4 {
        padding-right: 4px;
    }

    .pd-md-b-4 {
        padding-bottom: 4px;
    }

    .pd-md-l-4 {
        padding-left: 4px;
    }

    .pd-md-5 {
        padding: 5px;
    }

    .pd-md-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-md-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-md-t-5 {
        padding-top: 5px;
    }

    .pd-md-r-5 {
        padding-right: 5px;
    }

    .pd-md-b-5 {
        padding-bottom: 5px;
    }

    .pd-md-l-5 {
        padding-left: 5px;
    }

    .pd-md-6 {
        padding: 6px;
    }

    .pd-md-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-md-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-md-t-6 {
        padding-top: 6px;
    }

    .pd-md-r-6 {
        padding-right: 6px;
    }

    .pd-md-b-6 {
        padding-bottom: 6px;
    }

    .pd-md-l-6 {
        padding-left: 6px;
    }

    .pd-md-7 {
        padding: 7px;
    }

    .pd-md-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-md-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-md-t-7 {
        padding-top: 7px;
    }

    .pd-md-r-7 {
        padding-right: 7px;
    }

    .pd-md-b-7 {
        padding-bottom: 7px;
    }

    .pd-md-l-7 {
        padding-left: 7px;
    }

    .pd-md-8 {
        padding: 8px;
    }

    .pd-md-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-md-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-md-t-8 {
        padding-top: 8px;
    }

    .pd-md-r-8 {
        padding-right: 8px;
    }

    .pd-md-b-8 {
        padding-bottom: 8px;
    }

    .pd-md-l-8 {
        padding-left: 8px;
    }

    .pd-md-9 {
        padding: 9px;
    }

    .pd-md-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-md-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-md-t-9 {
        padding-top: 9px;
    }

    .pd-md-r-9 {
        padding-right: 9px;
    }

    .pd-md-b-9 {
        padding-bottom: 9px;
    }

    .pd-md-l-9 {
        padding-left: 9px;
    }

    .pd-md-10 {
        padding: 10px;
    }

    .pd-md-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-md-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-md-t-10 {
        padding-top: 10px;
    }

    .pd-md-r-10 {
        padding-right: 10px;
    }

    .pd-md-b-10 {
        padding-bottom: 10px;
    }

    .pd-md-l-10 {
        padding-left: 10px;
    }

    .pd-md-15 {
        padding: 15px;
    }

    .pd-md-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-md-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-md-t-15 {
        padding-top: 15px;
    }

    .pd-md-r-15 {
        padding-right: 15px;
    }

    .pd-md-b-15 {
        padding-bottom: 15px;
    }

    .pd-md-l-15 {
        padding-left: 15px;
    }

    .pd-md-20 {
        padding: 20px;
    }

    .pd-md-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-md-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-md-t-20 {
        padding-top: 20px;
    }

    .pd-md-r-20 {
        padding-right: 20px;
    }

    .pd-md-b-20 {
        padding-bottom: 20px;
    }

    .pd-md-l-20 {
        padding-left: 20px;
    }

    .pd-md-25 {
        padding: 25px;
    }

    .pd-md-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-md-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-md-t-25 {
        padding-top: 25px;
    }

    .pd-md-r-25 {
        padding-right: 25px;
    }

    .pd-md-b-25 {
        padding-bottom: 25px;
    }

    .pd-md-l-25 {
        padding-left: 25px;
    }

    .pd-md-30 {
        padding: 30px;
    }

    .pd-md-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-md-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-md-t-30 {
        padding-top: 30px;
    }

    .pd-md-r-30 {
        padding-right: 30px;
    }

    .pd-md-b-30 {
        padding-bottom: 30px;
    }

    .pd-md-l-30 {
        padding-left: 30px;
    }

    .pd-md-35 {
        padding: 35px;
    }

    .pd-md-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-md-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-md-t-35 {
        padding-top: 35px;
    }

    .pd-md-r-35 {
        padding-right: 35px;
    }

    .pd-md-b-35 {
        padding-bottom: 35px;
    }

    .pd-md-l-35 {
        padding-left: 35px;
    }

    .pd-md-40 {
        padding: 40px;
    }

    .pd-md-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-md-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-md-t-40 {
        padding-top: 40px;
    }

    .pd-md-r-40 {
        padding-right: 40px;
    }

    .pd-md-b-40 {
        padding-bottom: 40px;
    }

    .pd-md-l-40 {
        padding-left: 40px;
    }

    .pd-md-45 {
        padding: 45px;
    }

    .pd-md-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-md-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-md-t-45 {
        padding-top: 45px;
    }

    .pd-md-r-45 {
        padding-right: 45px;
    }

    .pd-md-b-45 {
        padding-bottom: 45px;
    }

    .pd-md-l-45 {
        padding-left: 45px;
    }

    .pd-md-50 {
        padding: 50px;
    }

    .pd-md-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-md-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-md-t-50 {
        padding-top: 50px;
    }

    .pd-md-r-50 {
        padding-right: 50px;
    }

    .pd-md-b-50 {
        padding-bottom: 50px;
    }

    .pd-md-l-50 {
        padding-left: 50px;
    }

    .pd-md-55 {
        padding: 55px;
    }

    .pd-md-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-md-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-md-t-55 {
        padding-top: 55px;
    }

    .pd-md-r-55 {
        padding-right: 55px;
    }

    .pd-md-b-55 {
        padding-bottom: 55px;
    }

    .pd-md-l-55 {
        padding-left: 55px;
    }

    .pd-md-60 {
        padding: 60px;
    }

    .pd-md-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-md-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-md-t-60 {
        padding-top: 60px;
    }

    .pd-md-r-60 {
        padding-right: 60px;
    }

    .pd-md-b-60 {
        padding-bottom: 60px;
    }

    .pd-md-l-60 {
        padding-left: 60px;
    }

    .pd-md-65 {
        padding: 65px;
    }

    .pd-md-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-md-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-md-t-65 {
        padding-top: 65px;
    }

    .pd-md-r-65 {
        padding-right: 65px;
    }

    .pd-md-b-65 {
        padding-bottom: 65px;
    }

    .pd-md-l-65 {
        padding-left: 65px;
    }

    .pd-md-70 {
        padding: 70px;
    }

    .pd-md-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-md-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-md-t-70 {
        padding-top: 70px;
    }

    .pd-md-r-70 {
        padding-right: 70px;
    }

    .pd-md-b-70 {
        padding-bottom: 70px;
    }

    .pd-md-l-70 {
        padding-left: 70px;
    }

    .pd-md-75 {
        padding: 75px;
    }

    .pd-md-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-md-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-md-t-75 {
        padding-top: 75px;
    }

    .pd-md-r-75 {
        padding-right: 75px;
    }

    .pd-md-b-75 {
        padding-bottom: 75px;
    }

    .pd-md-l-75 {
        padding-left: 75px;
    }

    .pd-md-80 {
        padding: 80px;
    }

    .pd-md-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-md-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-md-t-80 {
        padding-top: 80px;
    }

    .pd-md-r-80 {
        padding-right: 80px;
    }

    .pd-md-b-80 {
        padding-bottom: 80px;
    }

    .pd-md-l-80 {
        padding-left: 80px;
    }

    .pd-md-85 {
        padding: 85px;
    }

    .pd-md-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-md-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-md-t-85 {
        padding-top: 85px;
    }

    .pd-md-r-85 {
        padding-right: 85px;
    }

    .pd-md-b-85 {
        padding-bottom: 85px;
    }

    .pd-md-l-85 {
        padding-left: 85px;
    }

    .pd-md-90 {
        padding: 90px;
    }

    .pd-md-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-md-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-md-t-90 {
        padding-top: 90px;
    }

    .pd-md-r-90 {
        padding-right: 90px;
    }

    .pd-md-b-90 {
        padding-bottom: 90px;
    }

    .pd-md-l-90 {
        padding-left: 90px;
    }

    .pd-md-95 {
        padding: 95px;
    }

    .pd-md-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-md-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-md-t-95 {
        padding-top: 95px;
    }

    .pd-md-r-95 {
        padding-right: 95px;
    }

    .pd-md-b-95 {
        padding-bottom: 95px;
    }

    .pd-md-l-95 {
        padding-left: 95px;
    }

    .pd-md-100 {
        padding: 100px;
    }

    .pd-md-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-md-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-md-t-100 {
        padding-top: 100px;
    }

    .pd-md-r-100 {
        padding-right: 100px;
    }

    .pd-md-b-100 {
        padding-bottom: 100px;
    }

    .pd-md-l-100 {
        padding-left: 100px;
    }

    .pd-md-110 {
        padding: 110px;
    }

    .pd-md-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-md-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-md-t-110 {
        padding-top: 110px;
    }

    .pd-md-r-110 {
        padding-right: 110px;
    }

    .pd-md-b-110 {
        padding-bottom: 110px;
    }

    .pd-md-l-110 {
        padding-left: 110px;
    }

    .pd-md-120 {
        padding: 120px;
    }

    .pd-md-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-md-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-md-t-120 {
        padding-top: 120px;
    }

    .pd-md-r-120 {
        padding-right: 120px;
    }

    .pd-md-b-120 {
        padding-bottom: 120px;
    }

    .pd-md-l-120 {
        padding-left: 120px;
    }

    .pd-md-130 {
        padding: 130px;
    }

    .pd-md-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-md-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-md-t-130 {
        padding-top: 130px;
    }

    .pd-md-r-130 {
        padding-right: 130px;
    }

    .pd-md-b-130 {
        padding-bottom: 130px;
    }

    .pd-md-l-130 {
        padding-left: 130px;
    }

    .pd-md-140 {
        padding: 140px;
    }

    .pd-md-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-md-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-md-t-140 {
        padding-top: 140px;
    }

    .pd-md-r-140 {
        padding-right: 140px;
    }

    .pd-md-b-140 {
        padding-bottom: 140px;
    }

    .pd-md-l-140 {
        padding-left: 140px;
    }

    .pd-md-150 {
        padding: 150px;
    }

    .pd-md-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-md-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-md-t-150 {
        padding-top: 150px;
    }

    .pd-md-r-150 {
        padding-right: 150px;
    }

    .pd-md-b-150 {
        padding-bottom: 150px;
    }

    .pd-md-l-150 {
        padding-left: 150px;
    }

    .pd-md-160 {
        padding: 160px;
    }

    .pd-md-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-md-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-md-t-160 {
        padding-top: 160px;
    }

    .pd-md-r-160 {
        padding-right: 160px;
    }

    .pd-md-b-160 {
        padding-bottom: 160px;
    }

    .pd-md-l-160 {
        padding-left: 160px;
    }

    .pd-md-170 {
        padding: 170px;
    }

    .pd-md-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-md-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-md-t-170 {
        padding-top: 170px;
    }

    .pd-md-r-170 {
        padding-right: 170px;
    }

    .pd-md-b-170 {
        padding-bottom: 170px;
    }

    .pd-md-l-170 {
        padding-left: 170px;
    }

    .pd-md-180 {
        padding: 180px;
    }

    .pd-md-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-md-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-md-t-180 {
        padding-top: 180px;
    }

    .pd-md-r-180 {
        padding-right: 180px;
    }

    .pd-md-b-180 {
        padding-bottom: 180px;
    }

    .pd-md-l-180 {
        padding-left: 180px;
    }

    .pd-md-190 {
        padding: 190px;
    }

    .pd-md-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-md-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-md-t-190 {
        padding-top: 190px;
    }

    .pd-md-r-190 {
        padding-right: 190px;
    }

    .pd-md-b-190 {
        padding-bottom: 190px;
    }

    .pd-md-l-190 {
        padding-left: 190px;
    }

    .pd-md-200 {
        padding: 200px;
    }

    .pd-md-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-md-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-md-t-200 {
        padding-top: 200px;
    }

    .pd-md-r-200 {
        padding-right: 200px;
    }

    .pd-md-b-200 {
        padding-bottom: 200px;
    }

    .pd-md-l-200 {
        padding-left: 200px;
    }
}

@media (min-width: 992px) {
    .pd-lg-0 {
        padding: 0px;
    }

    .pd-lg-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-lg-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-lg-t-0 {
        padding-top: 0px;
    }

    .pd-lg-r-0 {
        padding-right: 0px;
    }

    .pd-lg-b-0 {
        padding-bottom: 0px;
    }

    .pd-lg-l-0 {
        padding-left: 0px;
    }

    .pd-lg-1 {
        padding: 1px;
    }

    .pd-lg-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-lg-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-lg-t-1 {
        padding-top: 1px;
    }

    .pd-lg-r-1 {
        padding-right: 1px;
    }

    .pd-lg-b-1 {
        padding-bottom: 1px;
    }

    .pd-lg-l-1 {
        padding-left: 1px;
    }

    .pd-lg-2 {
        padding: 2px;
    }

    .pd-lg-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-lg-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-lg-t-2 {
        padding-top: 2px;
    }

    .pd-lg-r-2 {
        padding-right: 2px;
    }

    .pd-lg-b-2 {
        padding-bottom: 2px;
    }

    .pd-lg-l-2 {
        padding-left: 2px;
    }

    .pd-lg-3 {
        padding: 3px;
    }

    .pd-lg-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-lg-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-lg-t-3 {
        padding-top: 3px;
    }

    .pd-lg-r-3 {
        padding-right: 3px;
    }

    .pd-lg-b-3 {
        padding-bottom: 3px;
    }

    .pd-lg-l-3 {
        padding-left: 3px;
    }

    .pd-lg-4 {
        padding: 4px;
    }

    .pd-lg-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-lg-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-lg-t-4 {
        padding-top: 4px;
    }

    .pd-lg-r-4 {
        padding-right: 4px;
    }

    .pd-lg-b-4 {
        padding-bottom: 4px;
    }

    .pd-lg-l-4 {
        padding-left: 4px;
    }

    .pd-lg-5 {
        padding: 5px;
    }

    .pd-lg-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-lg-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-lg-t-5 {
        padding-top: 5px;
    }

    .pd-lg-r-5 {
        padding-right: 5px;
    }

    .pd-lg-b-5 {
        padding-bottom: 5px;
    }

    .pd-lg-l-5 {
        padding-left: 5px;
    }

    .pd-lg-6 {
        padding: 6px;
    }

    .pd-lg-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-lg-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-lg-t-6 {
        padding-top: 6px;
    }

    .pd-lg-r-6 {
        padding-right: 6px;
    }

    .pd-lg-b-6 {
        padding-bottom: 6px;
    }

    .pd-lg-l-6 {
        padding-left: 6px;
    }

    .pd-lg-7 {
        padding: 7px;
    }

    .pd-lg-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-lg-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-lg-t-7 {
        padding-top: 7px;
    }

    .pd-lg-r-7 {
        padding-right: 7px;
    }

    .pd-lg-b-7 {
        padding-bottom: 7px;
    }

    .pd-lg-l-7 {
        padding-left: 7px;
    }

    .pd-lg-8 {
        padding: 8px;
    }

    .pd-lg-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-lg-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-lg-t-8 {
        padding-top: 8px;
    }

    .pd-lg-r-8 {
        padding-right: 8px;
    }

    .pd-lg-b-8 {
        padding-bottom: 8px;
    }

    .pd-lg-l-8 {
        padding-left: 8px;
    }

    .pd-lg-9 {
        padding: 9px;
    }

    .pd-lg-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-lg-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-lg-t-9 {
        padding-top: 9px;
    }

    .pd-lg-r-9 {
        padding-right: 9px;
    }

    .pd-lg-b-9 {
        padding-bottom: 9px;
    }

    .pd-lg-l-9 {
        padding-left: 9px;
    }

    .pd-lg-10 {
        padding: 10px;
    }

    .pd-lg-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-lg-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-lg-t-10 {
        padding-top: 10px;
    }

    .pd-lg-r-10 {
        padding-right: 10px;
    }

    .pd-lg-b-10 {
        padding-bottom: 10px;
    }

    .pd-lg-l-10 {
        padding-left: 10px;
    }

    .pd-lg-15 {
        padding: 15px;
    }

    .pd-lg-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-lg-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-lg-t-15 {
        padding-top: 15px;
    }

    .pd-lg-r-15 {
        padding-right: 15px;
    }

    .pd-lg-b-15 {
        padding-bottom: 15px;
    }

    .pd-lg-l-15 {
        padding-left: 15px;
    }

    .pd-lg-20 {
        padding: 20px;
    }

    .pd-lg-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-lg-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-lg-t-20 {
        padding-top: 20px;
    }

    .pd-lg-r-20 {
        padding-right: 20px;
    }

    .pd-lg-b-20 {
        padding-bottom: 20px;
    }

    .pd-lg-l-20 {
        padding-left: 20px;
    }

    .pd-lg-25 {
        padding: 25px;
    }

    .pd-lg-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-lg-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-lg-t-25 {
        padding-top: 25px;
    }

    .pd-lg-r-25 {
        padding-right: 25px;
    }

    .pd-lg-b-25 {
        padding-bottom: 25px;
    }

    .pd-lg-l-25 {
        padding-left: 25px;
    }

    .pd-lg-30 {
        padding: 30px;
    }

    .pd-lg-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-lg-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-lg-t-30 {
        padding-top: 30px;
    }

    .pd-lg-r-30 {
        padding-right: 30px;
    }

    .pd-lg-b-30 {
        padding-bottom: 30px;
    }

    .pd-lg-l-30 {
        padding-left: 30px;
    }

    .pd-lg-35 {
        padding: 35px;
    }

    .pd-lg-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-lg-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-lg-t-35 {
        padding-top: 35px;
    }

    .pd-lg-r-35 {
        padding-right: 35px;
    }

    .pd-lg-b-35 {
        padding-bottom: 35px;
    }

    .pd-lg-l-35 {
        padding-left: 35px;
    }

    .pd-lg-40 {
        padding: 40px;
    }

    .pd-lg-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-lg-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-lg-t-40 {
        padding-top: 40px;
    }

    .pd-lg-r-40 {
        padding-right: 40px;
    }

    .pd-lg-b-40 {
        padding-bottom: 40px;
    }

    .pd-lg-l-40 {
        padding-left: 40px;
    }

    .pd-lg-45 {
        padding: 45px;
    }

    .pd-lg-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-lg-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-lg-t-45 {
        padding-top: 45px;
    }

    .pd-lg-r-45 {
        padding-right: 45px;
    }

    .pd-lg-b-45 {
        padding-bottom: 45px;
    }

    .pd-lg-l-45 {
        padding-left: 45px;
    }

    .pd-lg-50 {
        padding: 50px;
    }

    .pd-lg-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-lg-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-lg-t-50 {
        padding-top: 50px;
    }

    .pd-lg-r-50 {
        padding-right: 50px;
    }

    .pd-lg-b-50 {
        padding-bottom: 50px;
    }

    .pd-lg-l-50 {
        padding-left: 50px;
    }

    .pd-lg-55 {
        padding: 55px;
    }

    .pd-lg-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-lg-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-lg-t-55 {
        padding-top: 55px;
    }

    .pd-lg-r-55 {
        padding-right: 55px;
    }

    .pd-lg-b-55 {
        padding-bottom: 55px;
    }

    .pd-lg-l-55 {
        padding-left: 55px;
    }

    .pd-lg-60 {
        padding: 60px;
    }

    .pd-lg-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-lg-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-lg-t-60 {
        padding-top: 60px;
    }

    .pd-lg-r-60 {
        padding-right: 60px;
    }

    .pd-lg-b-60 {
        padding-bottom: 60px;
    }

    .pd-lg-l-60 {
        padding-left: 60px;
    }

    .pd-lg-65 {
        padding: 65px;
    }

    .pd-lg-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-lg-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-lg-t-65 {
        padding-top: 65px;
    }

    .pd-lg-r-65 {
        padding-right: 65px;
    }

    .pd-lg-b-65 {
        padding-bottom: 65px;
    }

    .pd-lg-l-65 {
        padding-left: 65px;
    }

    .pd-lg-70 {
        padding: 70px;
    }

    .pd-lg-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-lg-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-lg-t-70 {
        padding-top: 70px;
    }

    .pd-lg-r-70 {
        padding-right: 70px;
    }

    .pd-lg-b-70 {
        padding-bottom: 70px;
    }

    .pd-lg-l-70 {
        padding-left: 70px;
    }

    .pd-lg-75 {
        padding: 75px;
    }

    .pd-lg-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-lg-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-lg-t-75 {
        padding-top: 75px;
    }

    .pd-lg-r-75 {
        padding-right: 75px;
    }

    .pd-lg-b-75 {
        padding-bottom: 75px;
    }

    .pd-lg-l-75 {
        padding-left: 75px;
    }

    .pd-lg-80 {
        padding: 80px;
    }

    .pd-lg-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-lg-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-lg-t-80 {
        padding-top: 80px;
    }

    .pd-lg-r-80 {
        padding-right: 80px;
    }

    .pd-lg-b-80 {
        padding-bottom: 80px;
    }

    .pd-lg-l-80 {
        padding-left: 80px;
    }

    .pd-lg-85 {
        padding: 85px;
    }

    .pd-lg-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-lg-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-lg-t-85 {
        padding-top: 85px;
    }

    .pd-lg-r-85 {
        padding-right: 85px;
    }

    .pd-lg-b-85 {
        padding-bottom: 85px;
    }

    .pd-lg-l-85 {
        padding-left: 85px;
    }

    .pd-lg-90 {
        padding: 90px;
    }

    .pd-lg-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-lg-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-lg-t-90 {
        padding-top: 90px;
    }

    .pd-lg-r-90 {
        padding-right: 90px;
    }

    .pd-lg-b-90 {
        padding-bottom: 90px;
    }

    .pd-lg-l-90 {
        padding-left: 90px;
    }

    .pd-lg-95 {
        padding: 95px;
    }

    .pd-lg-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-lg-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-lg-t-95 {
        padding-top: 95px;
    }

    .pd-lg-r-95 {
        padding-right: 95px;
    }

    .pd-lg-b-95 {
        padding-bottom: 95px;
    }

    .pd-lg-l-95 {
        padding-left: 95px;
    }

    .pd-lg-100 {
        padding: 100px;
    }

    .pd-lg-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-lg-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-lg-t-100 {
        padding-top: 100px;
    }

    .pd-lg-r-100 {
        padding-right: 100px;
    }

    .pd-lg-b-100 {
        padding-bottom: 100px;
    }

    .pd-lg-l-100 {
        padding-left: 100px;
    }

    .pd-lg-110 {
        padding: 110px;
    }

    .pd-lg-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-lg-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-lg-t-110 {
        padding-top: 110px;
    }

    .pd-lg-r-110 {
        padding-right: 110px;
    }

    .pd-lg-b-110 {
        padding-bottom: 110px;
    }

    .pd-lg-l-110 {
        padding-left: 110px;
    }

    .pd-lg-120 {
        padding: 120px;
    }

    .pd-lg-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-lg-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-lg-t-120 {
        padding-top: 120px;
    }

    .pd-lg-r-120 {
        padding-right: 120px;
    }

    .pd-lg-b-120 {
        padding-bottom: 120px;
    }

    .pd-lg-l-120 {
        padding-left: 120px;
    }

    .pd-lg-130 {
        padding: 130px;
    }

    .pd-lg-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-lg-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-lg-t-130 {
        padding-top: 130px;
    }

    .pd-lg-r-130 {
        padding-right: 130px;
    }

    .pd-lg-b-130 {
        padding-bottom: 130px;
    }

    .pd-lg-l-130 {
        padding-left: 130px;
    }

    .pd-lg-140 {
        padding: 140px;
    }

    .pd-lg-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-lg-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-lg-t-140 {
        padding-top: 140px;
    }

    .pd-lg-r-140 {
        padding-right: 140px;
    }

    .pd-lg-b-140 {
        padding-bottom: 140px;
    }

    .pd-lg-l-140 {
        padding-left: 140px;
    }

    .pd-lg-150 {
        padding: 150px;
    }

    .pd-lg-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-lg-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-lg-t-150 {
        padding-top: 150px;
    }

    .pd-lg-r-150 {
        padding-right: 150px;
    }

    .pd-lg-b-150 {
        padding-bottom: 150px;
    }

    .pd-lg-l-150 {
        padding-left: 150px;
    }

    .pd-lg-160 {
        padding: 160px;
    }

    .pd-lg-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-lg-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-lg-t-160 {
        padding-top: 160px;
    }

    .pd-lg-r-160 {
        padding-right: 160px;
    }

    .pd-lg-b-160 {
        padding-bottom: 160px;
    }

    .pd-lg-l-160 {
        padding-left: 160px;
    }

    .pd-lg-170 {
        padding: 170px;
    }

    .pd-lg-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-lg-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-lg-t-170 {
        padding-top: 170px;
    }

    .pd-lg-r-170 {
        padding-right: 170px;
    }

    .pd-lg-b-170 {
        padding-bottom: 170px;
    }

    .pd-lg-l-170 {
        padding-left: 170px;
    }

    .pd-lg-180 {
        padding: 180px;
    }

    .pd-lg-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-lg-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-lg-t-180 {
        padding-top: 180px;
    }

    .pd-lg-r-180 {
        padding-right: 180px;
    }

    .pd-lg-b-180 {
        padding-bottom: 180px;
    }

    .pd-lg-l-180 {
        padding-left: 180px;
    }

    .pd-lg-190 {
        padding: 190px;
    }

    .pd-lg-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-lg-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-lg-t-190 {
        padding-top: 190px;
    }

    .pd-lg-r-190 {
        padding-right: 190px;
    }

    .pd-lg-b-190 {
        padding-bottom: 190px;
    }

    .pd-lg-l-190 {
        padding-left: 190px;
    }

    .pd-lg-200 {
        padding: 200px;
    }

    .pd-lg-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-lg-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-lg-t-200 {
        padding-top: 200px;
    }

    .pd-lg-r-200 {
        padding-right: 200px;
    }

    .pd-lg-b-200 {
        padding-bottom: 200px;
    }

    .pd-lg-l-200 {
        padding-left: 200px;
    }
}

@media (min-width: 1200px) {
    .pd-xl-0 {
        padding: 0px;
    }

    .pd-xl-y-0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .pd-xl-x-0 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .pd-xl-t-0 {
        padding-top: 0px;
    }

    .pd-xl-r-0 {
        padding-right: 0px;
    }

    .pd-xl-b-0 {
        padding-bottom: 0px;
    }

    .pd-xl-l-0 {
        padding-left: 0px;
    }

    .pd-xl-1 {
        padding: 1px;
    }

    .pd-xl-y-1 {
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .pd-xl-x-1 {
        padding-left: 1px;
        padding-right: 1px;
    }

    .pd-xl-t-1 {
        padding-top: 1px;
    }

    .pd-xl-r-1 {
        padding-right: 1px;
    }

    .pd-xl-b-1 {
        padding-bottom: 1px;
    }

    .pd-xl-l-1 {
        padding-left: 1px;
    }

    .pd-xl-2 {
        padding: 2px;
    }

    .pd-xl-y-2 {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .pd-xl-x-2 {
        padding-left: 2px;
        padding-right: 2px;
    }

    .pd-xl-t-2 {
        padding-top: 2px;
    }

    .pd-xl-r-2 {
        padding-right: 2px;
    }

    .pd-xl-b-2 {
        padding-bottom: 2px;
    }

    .pd-xl-l-2 {
        padding-left: 2px;
    }

    .pd-xl-3 {
        padding: 3px;
    }

    .pd-xl-y-3 {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .pd-xl-x-3 {
        padding-left: 3px;
        padding-right: 3px;
    }

    .pd-xl-t-3 {
        padding-top: 3px;
    }

    .pd-xl-r-3 {
        padding-right: 3px;
    }

    .pd-xl-b-3 {
        padding-bottom: 3px;
    }

    .pd-xl-l-3 {
        padding-left: 3px;
    }

    .pd-xl-4 {
        padding: 4px;
    }

    .pd-xl-y-4 {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .pd-xl-x-4 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .pd-xl-t-4 {
        padding-top: 4px;
    }

    .pd-xl-r-4 {
        padding-right: 4px;
    }

    .pd-xl-b-4 {
        padding-bottom: 4px;
    }

    .pd-xl-l-4 {
        padding-left: 4px;
    }

    .pd-xl-5 {
        padding: 5px;
    }

    .pd-xl-y-5 {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .pd-xl-x-5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pd-xl-t-5 {
        padding-top: 5px;
    }

    .pd-xl-r-5 {
        padding-right: 5px;
    }

    .pd-xl-b-5 {
        padding-bottom: 5px;
    }

    .pd-xl-l-5 {
        padding-left: 5px;
    }

    .pd-xl-6 {
        padding: 6px;
    }

    .pd-xl-y-6 {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .pd-xl-x-6 {
        padding-left: 6px;
        padding-right: 6px;
    }

    .pd-xl-t-6 {
        padding-top: 6px;
    }

    .pd-xl-r-6 {
        padding-right: 6px;
    }

    .pd-xl-b-6 {
        padding-bottom: 6px;
    }

    .pd-xl-l-6 {
        padding-left: 6px;
    }

    .pd-xl-7 {
        padding: 7px;
    }

    .pd-xl-y-7 {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .pd-xl-x-7 {
        padding-left: 7px;
        padding-right: 7px;
    }

    .pd-xl-t-7 {
        padding-top: 7px;
    }

    .pd-xl-r-7 {
        padding-right: 7px;
    }

    .pd-xl-b-7 {
        padding-bottom: 7px;
    }

    .pd-xl-l-7 {
        padding-left: 7px;
    }

    .pd-xl-8 {
        padding: 8px;
    }

    .pd-xl-y-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .pd-xl-x-8 {
        padding-left: 8px;
        padding-right: 8px;
    }

    .pd-xl-t-8 {
        padding-top: 8px;
    }

    .pd-xl-r-8 {
        padding-right: 8px;
    }

    .pd-xl-b-8 {
        padding-bottom: 8px;
    }

    .pd-xl-l-8 {
        padding-left: 8px;
    }

    .pd-xl-9 {
        padding: 9px;
    }

    .pd-xl-y-9 {
        padding-top: 9px;
        padding-bottom: 9px;
    }

    .pd-xl-x-9 {
        padding-left: 9px;
        padding-right: 9px;
    }

    .pd-xl-t-9 {
        padding-top: 9px;
    }

    .pd-xl-r-9 {
        padding-right: 9px;
    }

    .pd-xl-b-9 {
        padding-bottom: 9px;
    }

    .pd-xl-l-9 {
        padding-left: 9px;
    }

    .pd-xl-10 {
        padding: 10px;
    }

    .pd-xl-y-10 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pd-xl-x-10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pd-xl-t-10 {
        padding-top: 10px;
    }

    .pd-xl-r-10 {
        padding-right: 10px;
    }

    .pd-xl-b-10 {
        padding-bottom: 10px;
    }

    .pd-xl-l-10 {
        padding-left: 10px;
    }

    .pd-xl-15 {
        padding: 15px;
    }

    .pd-xl-y-15 {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .pd-xl-x-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .pd-xl-t-15 {
        padding-top: 15px;
    }

    .pd-xl-r-15 {
        padding-right: 15px;
    }

    .pd-xl-b-15 {
        padding-bottom: 15px;
    }

    .pd-xl-l-15 {
        padding-left: 15px;
    }

    .pd-xl-20 {
        padding: 20px;
    }

    .pd-xl-y-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .pd-xl-x-20 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .pd-xl-t-20 {
        padding-top: 20px;
    }

    .pd-xl-r-20 {
        padding-right: 20px;
    }

    .pd-xl-b-20 {
        padding-bottom: 20px;
    }

    .pd-xl-l-20 {
        padding-left: 20px;
    }

    .pd-xl-25 {
        padding: 25px;
    }

    .pd-xl-y-25 {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .pd-xl-x-25 {
        padding-left: 25px;
        padding-right: 25px;
    }

    .pd-xl-t-25 {
        padding-top: 25px;
    }

    .pd-xl-r-25 {
        padding-right: 25px;
    }

    .pd-xl-b-25 {
        padding-bottom: 25px;
    }

    .pd-xl-l-25 {
        padding-left: 25px;
    }

    .pd-xl-30 {
        padding: 30px;
    }

    .pd-xl-y-30 {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .pd-xl-x-30 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .pd-xl-t-30 {
        padding-top: 30px;
    }

    .pd-xl-r-30 {
        padding-right: 30px;
    }

    .pd-xl-b-30 {
        padding-bottom: 30px;
    }

    .pd-xl-l-30 {
        padding-left: 30px;
    }

    .pd-xl-35 {
        padding: 35px;
    }

    .pd-xl-y-35 {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .pd-xl-x-35 {
        padding-left: 35px;
        padding-right: 35px;
    }

    .pd-xl-t-35 {
        padding-top: 35px;
    }

    .pd-xl-r-35 {
        padding-right: 35px;
    }

    .pd-xl-b-35 {
        padding-bottom: 35px;
    }

    .pd-xl-l-35 {
        padding-left: 35px;
    }

    .pd-xl-40 {
        padding: 40px;
    }

    .pd-xl-y-40 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pd-xl-x-40 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .pd-xl-t-40 {
        padding-top: 40px;
    }

    .pd-xl-r-40 {
        padding-right: 40px;
    }

    .pd-xl-b-40 {
        padding-bottom: 40px;
    }

    .pd-xl-l-40 {
        padding-left: 40px;
    }

    .pd-xl-45 {
        padding: 45px;
    }

    .pd-xl-y-45 {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .pd-xl-x-45 {
        padding-left: 45px;
        padding-right: 45px;
    }

    .pd-xl-t-45 {
        padding-top: 45px;
    }

    .pd-xl-r-45 {
        padding-right: 45px;
    }

    .pd-xl-b-45 {
        padding-bottom: 45px;
    }

    .pd-xl-l-45 {
        padding-left: 45px;
    }

    .pd-xl-50 {
        padding: 50px;
    }

    .pd-xl-y-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pd-xl-x-50 {
        padding-left: 50px;
        padding-right: 50px;
    }

    .pd-xl-t-50 {
        padding-top: 50px;
    }

    .pd-xl-r-50 {
        padding-right: 50px;
    }

    .pd-xl-b-50 {
        padding-bottom: 50px;
    }

    .pd-xl-l-50 {
        padding-left: 50px;
    }

    .pd-xl-55 {
        padding: 55px;
    }

    .pd-xl-y-55 {
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .pd-xl-x-55 {
        padding-left: 55px;
        padding-right: 55px;
    }

    .pd-xl-t-55 {
        padding-top: 55px;
    }

    .pd-xl-r-55 {
        padding-right: 55px;
    }

    .pd-xl-b-55 {
        padding-bottom: 55px;
    }

    .pd-xl-l-55 {
        padding-left: 55px;
    }

    .pd-xl-60 {
        padding: 60px;
    }

    .pd-xl-y-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .pd-xl-x-60 {
        padding-left: 60px;
        padding-right: 60px;
    }

    .pd-xl-t-60 {
        padding-top: 60px;
    }

    .pd-xl-r-60 {
        padding-right: 60px;
    }

    .pd-xl-b-60 {
        padding-bottom: 60px;
    }

    .pd-xl-l-60 {
        padding-left: 60px;
    }

    .pd-xl-65 {
        padding: 65px;
    }

    .pd-xl-y-65 {
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .pd-xl-x-65 {
        padding-left: 65px;
        padding-right: 65px;
    }

    .pd-xl-t-65 {
        padding-top: 65px;
    }

    .pd-xl-r-65 {
        padding-right: 65px;
    }

    .pd-xl-b-65 {
        padding-bottom: 65px;
    }

    .pd-xl-l-65 {
        padding-left: 65px;
    }

    .pd-xl-70 {
        padding: 70px;
    }

    .pd-xl-y-70 {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .pd-xl-x-70 {
        padding-left: 70px;
        padding-right: 70px;
    }

    .pd-xl-t-70 {
        padding-top: 70px;
    }

    .pd-xl-r-70 {
        padding-right: 70px;
    }

    .pd-xl-b-70 {
        padding-bottom: 70px;
    }

    .pd-xl-l-70 {
        padding-left: 70px;
    }

    .pd-xl-75 {
        padding: 75px;
    }

    .pd-xl-y-75 {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .pd-xl-x-75 {
        padding-left: 75px;
        padding-right: 75px;
    }

    .pd-xl-t-75 {
        padding-top: 75px;
    }

    .pd-xl-r-75 {
        padding-right: 75px;
    }

    .pd-xl-b-75 {
        padding-bottom: 75px;
    }

    .pd-xl-l-75 {
        padding-left: 75px;
    }

    .pd-xl-80 {
        padding: 80px;
    }

    .pd-xl-y-80 {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .pd-xl-x-80 {
        padding-left: 80px;
        padding-right: 80px;
    }

    .pd-xl-t-80 {
        padding-top: 80px;
    }

    .pd-xl-r-80 {
        padding-right: 80px;
    }

    .pd-xl-b-80 {
        padding-bottom: 80px;
    }

    .pd-xl-l-80 {
        padding-left: 80px;
    }

    .pd-xl-85 {
        padding: 85px;
    }

    .pd-xl-y-85 {
        padding-top: 85px;
        padding-bottom: 85px;
    }

    .pd-xl-x-85 {
        padding-left: 85px;
        padding-right: 85px;
    }

    .pd-xl-t-85 {
        padding-top: 85px;
    }

    .pd-xl-r-85 {
        padding-right: 85px;
    }

    .pd-xl-b-85 {
        padding-bottom: 85px;
    }

    .pd-xl-l-85 {
        padding-left: 85px;
    }

    .pd-xl-90 {
        padding: 90px;
    }

    .pd-xl-y-90 {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .pd-xl-x-90 {
        padding-left: 90px;
        padding-right: 90px;
    }

    .pd-xl-t-90 {
        padding-top: 90px;
    }

    .pd-xl-r-90 {
        padding-right: 90px;
    }

    .pd-xl-b-90 {
        padding-bottom: 90px;
    }

    .pd-xl-l-90 {
        padding-left: 90px;
    }

    .pd-xl-95 {
        padding: 95px;
    }

    .pd-xl-y-95 {
        padding-top: 95px;
        padding-bottom: 95px;
    }

    .pd-xl-x-95 {
        padding-left: 95px;
        padding-right: 95px;
    }

    .pd-xl-t-95 {
        padding-top: 95px;
    }

    .pd-xl-r-95 {
        padding-right: 95px;
    }

    .pd-xl-b-95 {
        padding-bottom: 95px;
    }

    .pd-xl-l-95 {
        padding-left: 95px;
    }

    .pd-xl-100 {
        padding: 100px;
    }

    .pd-xl-y-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pd-xl-x-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .pd-xl-t-100 {
        padding-top: 100px;
    }

    .pd-xl-r-100 {
        padding-right: 100px;
    }

    .pd-xl-b-100 {
        padding-bottom: 100px;
    }

    .pd-xl-l-100 {
        padding-left: 100px;
    }

    .pd-xl-110 {
        padding: 110px;
    }

    .pd-xl-y-110 {
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .pd-xl-x-110 {
        padding-left: 110px;
        padding-right: 110px;
    }

    .pd-xl-t-110 {
        padding-top: 110px;
    }

    .pd-xl-r-110 {
        padding-right: 110px;
    }

    .pd-xl-b-110 {
        padding-bottom: 110px;
    }

    .pd-xl-l-110 {
        padding-left: 110px;
    }

    .pd-xl-120 {
        padding: 120px;
    }

    .pd-xl-y-120 {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .pd-xl-x-120 {
        padding-left: 120px;
        padding-right: 120px;
    }

    .pd-xl-t-120 {
        padding-top: 120px;
    }

    .pd-xl-r-120 {
        padding-right: 120px;
    }

    .pd-xl-b-120 {
        padding-bottom: 120px;
    }

    .pd-xl-l-120 {
        padding-left: 120px;
    }

    .pd-xl-130 {
        padding: 130px;
    }

    .pd-xl-y-130 {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .pd-xl-x-130 {
        padding-left: 130px;
        padding-right: 130px;
    }

    .pd-xl-t-130 {
        padding-top: 130px;
    }

    .pd-xl-r-130 {
        padding-right: 130px;
    }

    .pd-xl-b-130 {
        padding-bottom: 130px;
    }

    .pd-xl-l-130 {
        padding-left: 130px;
    }

    .pd-xl-140 {
        padding: 140px;
    }

    .pd-xl-y-140 {
        padding-top: 140px;
        padding-bottom: 140px;
    }

    .pd-xl-x-140 {
        padding-left: 140px;
        padding-right: 140px;
    }

    .pd-xl-t-140 {
        padding-top: 140px;
    }

    .pd-xl-r-140 {
        padding-right: 140px;
    }

    .pd-xl-b-140 {
        padding-bottom: 140px;
    }

    .pd-xl-l-140 {
        padding-left: 140px;
    }

    .pd-xl-150 {
        padding: 150px;
    }

    .pd-xl-y-150 {
        padding-top: 150px;
        padding-bottom: 150px;
    }

    .pd-xl-x-150 {
        padding-left: 150px;
        padding-right: 150px;
    }

    .pd-xl-t-150 {
        padding-top: 150px;
    }

    .pd-xl-r-150 {
        padding-right: 150px;
    }

    .pd-xl-b-150 {
        padding-bottom: 150px;
    }

    .pd-xl-l-150 {
        padding-left: 150px;
    }

    .pd-xl-160 {
        padding: 160px;
    }

    .pd-xl-y-160 {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .pd-xl-x-160 {
        padding-left: 160px;
        padding-right: 160px;
    }

    .pd-xl-t-160 {
        padding-top: 160px;
    }

    .pd-xl-r-160 {
        padding-right: 160px;
    }

    .pd-xl-b-160 {
        padding-bottom: 160px;
    }

    .pd-xl-l-160 {
        padding-left: 160px;
    }

    .pd-xl-170 {
        padding: 170px;
    }

    .pd-xl-y-170 {
        padding-top: 170px;
        padding-bottom: 170px;
    }

    .pd-xl-x-170 {
        padding-left: 170px;
        padding-right: 170px;
    }

    .pd-xl-t-170 {
        padding-top: 170px;
    }

    .pd-xl-r-170 {
        padding-right: 170px;
    }

    .pd-xl-b-170 {
        padding-bottom: 170px;
    }

    .pd-xl-l-170 {
        padding-left: 170px;
    }

    .pd-xl-180 {
        padding: 180px;
    }

    .pd-xl-y-180 {
        padding-top: 180px;
        padding-bottom: 180px;
    }

    .pd-xl-x-180 {
        padding-left: 180px;
        padding-right: 180px;
    }

    .pd-xl-t-180 {
        padding-top: 180px;
    }

    .pd-xl-r-180 {
        padding-right: 180px;
    }

    .pd-xl-b-180 {
        padding-bottom: 180px;
    }

    .pd-xl-l-180 {
        padding-left: 180px;
    }

    .pd-xl-190 {
        padding: 190px;
    }

    .pd-xl-y-190 {
        padding-top: 190px;
        padding-bottom: 190px;
    }

    .pd-xl-x-190 {
        padding-left: 190px;
        padding-right: 190px;
    }

    .pd-xl-t-190 {
        padding-top: 190px;
    }

    .pd-xl-r-190 {
        padding-right: 190px;
    }

    .pd-xl-b-190 {
        padding-bottom: 190px;
    }

    .pd-xl-l-190 {
        padding-left: 190px;
    }

    .pd-xl-200 {
        padding: 200px;
    }

    .pd-xl-y-200 {
        padding-top: 200px;
        padding-bottom: 200px;
    }

    .pd-xl-x-200 {
        padding-left: 200px;
        padding-right: 200px;
    }

    .pd-xl-t-200 {
        padding-top: 200px;
    }

    .pd-xl-r-200 {
        padding-right: 200px;
    }

    .pd-xl-b-200 {
        padding-bottom: 200px;
    }

    .pd-xl-l-200 {
        padding-left: 200px;
    }
}

.pd-12 {
    padding: 12px;
}

.pd-y-12 {
    padding-top: 12px;
    padding-bottom: 12px;
}

.pd-x-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.pd-t-12 {
    padding-top: 12px;
}

.pd-r-12 {
    padding-right: 12px;
}

.pd-b-12 {
    padding-bottom: 12px;
}

.pd-l-12 {
    padding-left: 12px;
}

.pd-13 {
    padding: 13px;
}

.pd-y-13 {
    padding-top: 13px;
    padding-bottom: 13px;
}

.pd-x-13 {
    padding-left: 13px;
    padding-right: 13px;
}

.pd-t-13 {
    padding-top: 13px;
}

.pd-r-13 {
    padding-right: 13px;
}

.pd-b-13 {
    padding-bottom: 13px;
}

.pd-l-13 {
    padding-left: 13px;
}

@media (min-width: 768px) {
    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pe-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .ps-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pe-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .ps-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pe-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .ps-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pe-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .ps-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pe-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .ps-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pe-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .ps-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 1200px) {
    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pe-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .ps-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pe-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .ps-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pe-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .ps-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pe-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .ps-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pe-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .ps-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pe-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .ps-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pe-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.ps-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pe-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.ps-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pe-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.ps-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pe-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.ps-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pe-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.ps-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pe-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.ps-5,
.px-5 {
    padding-left: 3rem !important;
}

@media (min-width: 576px) {
    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pe-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .ps-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pe-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .ps-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pe-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .ps-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pe-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .ps-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pe-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .ps-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pe-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .ps-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }
}

@media (min-width: 992px) {
    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pe-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .ps-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pe-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .ps-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pe-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .ps-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pe-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .ps-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pe-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .ps-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pe-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .ps-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }
}

/* ###### Padding  ###### */
/* ###### Position  ###### */
.pos-absolute {
    position: absolute;
}

.pos-relative {
    position: relative !important;
}

.pos-fixed {
    position: fixed;
}

.pos-static {
    position: static;
}

.t-0 {
    top: 0px;
}

.r-0 {
    right: 0px;
}

.b-0 {
    bottom: 0px;
}

.l-0 {
    left: 0px;
}

.t--0 {
    top: 0px;
}

.r--0 {
    right: 0px;
}

.b--0 {
    bottom: 0px;
}

.l--0 {
    left: 0px;
}

.a-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.x-0,
.y-0 {
    left: 0px;
    right: 0px;
}

.t-5 {
    top: 5px;
}

.r-5 {
    right: 5px;
}

.b-5 {
    bottom: 5px;
}

.l-5 {
    left: 5px;
}

.t--5 {
    top: -5px;
}

.b--5 {
    bottom: -5px;
}

.l--5 {
    left: -5px;
}

.a-5 {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
}

.x-5,
.y-5 {
    left: 5px;
    right: 5px;
}

.t-10 {
    top: 10px;
}

.r-10 {
    right: 10px;
}

.b-10 {
    bottom: 10px;
}

.l-10 {
    left: 10px;
}

.t--10 {
    top: -10px;
}

.r--10 {
    right: -10px;
}

.b--10 {
    bottom: -10px;
}

.l--10 {
    left: -10px;
}

.a-10 {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
}

.x-10,
.y-10 {
    left: 10px;
    right: 10px;
}

.t-15 {
    top: 15px;
}

.r-15 {
    right: 15px;
}

.b-15 {
    bottom: 15px;
}

.l-15 {
    left: 15px;
}

.t--15 {
    top: -15px;
}

.r--15 {
    right: -15px;
}

.b--15 {
    bottom: -15px;
}

.l--15 {
    left: -15px;
}

.a-15 {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
}

.x-15,
.y-15 {
    left: 15px;
    right: 15px;
}

.t-20 {
    top: 20px;
}

.r-20 {
    right: 20px;
}

.b-20 {
    bottom: 20px;
}

.l-20 {
    left: 20px;
}

.t--20 {
    top: -20px;
}

.r--20 {
    right: -20px;
}

.b--20 {
    bottom: -20px;
}

.l--20 {
    left: -20px;
}

.a-20 {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
}

.x-20,
.y-20 {
    left: 20px;
    right: 20px;
}

.t-25 {
    top: 25px;
}

.r-25 {
    right: 25px;
}

.b-25 {
    bottom: 25px;
}

.l-25 {
    left: 25px;
}

.t--25 {
    top: -25px;
}

.r--25 {
    right: -25px;
}

.b--25 {
    bottom: -25px;
}

.l--25 {
    left: -25px;
}

.a-25 {
    top: 25px;
    right: 25px;
    bottom: 25px;
    left: 25px;
}

.x-25,
.y-25 {
    left: 25px;
    right: 25px;
}

.t-30 {
    top: 30px;
}

.r-30 {
    right: 30px;
}

.b-30 {
    bottom: 30px;
}

.l-30 {
    left: 30px;
}

.t--30 {
    top: -30px;
}

.r--30 {
    right: -30px;
}

.b--30 {
    bottom: -30px;
}

.l--30 {
    left: -30px;
}

.a-30 {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
}

.x-30,
.y-30 {
    left: 30px;
    right: 30px;
}

.t-35 {
    top: 35px;
}

.r-35 {
    right: 35px;
}

.b-35 {
    bottom: 35px;
}

.l-35 {
    left: 35px;
}

.t--35 {
    top: -35px;
}

.r--35 {
    right: -35px;
}

.b--35 {
    bottom: -35px;
}

.l--35 {
    left: -35px;
}

.a-35 {
    top: 35px;
    right: 35px;
    bottom: 35px;
    left: 35px;
}

.x-35,
.y-35 {
    left: 35px;
    right: 35px;
}

.t-40 {
    top: 40px;
}

.r-40 {
    right: 40px;
}

.b-40 {
    bottom: 40px;
}

.l-40 {
    left: 40px;
}

.t--40 {
    top: -40px;
}

.r--40 {
    right: -40px;
}

.b--40 {
    bottom: -40px;
}

.l--40 {
    left: -40px;
}

.a-40 {
    top: 40px;
    right: 40px;
    bottom: 40px;
    left: 40px;
}

.x-40,
.y-40 {
    left: 40px;
    right: 40px;
}

.t-45 {
    top: 45px;
}

.r-45 {
    right: 45px;
}

.b-45 {
    bottom: 45px;
}

.l-45 {
    left: 45px;
}

.t--45 {
    top: -45px;
}

.r--45 {
    right: -45px;
}

.b--45 {
    bottom: -45px;
}

.l--45 {
    left: -45px;
}

.a-45 {
    top: 45px;
    right: 45px;
    bottom: 45px;
    left: 45px;
}

.x-45,
.y-45 {
    left: 45px;
    right: 45px;
}

.t-50 {
    top: 50px;
}

.r-50 {
    right: 50px;
}

.b-50 {
    bottom: 50px;
}

.l-50 {
    left: 50px;
}

.t--50 {
    top: -50px;
}

.r--50 {
    right: -50px;
}

.b--50 {
    bottom: -50px;
}

.l--50 {
    left: -50px;
}

.a-50 {
    top: 50px;
    right: 50px;
    bottom: 50px;
    left: 50px;
}

.x-50,
.y-50 {
    left: 50px;
    right: 50px;
}

.t-55 {
    top: 55px;
}

.r-55 {
    right: 55px;
}

.b-55 {
    bottom: 55px;
}

.l-55 {
    left: 55px;
}

.t--55 {
    top: -55px;
}

.r--55 {
    right: -55px;
}

.b--55 {
    bottom: -55px;
}

.l--55 {
    left: -55px;
}

.a-55 {
    top: 55px;
    right: 55px;
    bottom: 55px;
    left: 55px;
}

.x-55,
.y-55 {
    left: 55px;
    right: 55px;
}

.t-60 {
    top: 60px;
}

.r-60 {
    right: 60px;
}

.b-60 {
    bottom: 60px;
}

.l-60 {
    left: 60px;
}

.t--60 {
    top: -60px;
}

.r--60 {
    right: -60px;
}

.b--60 {
    bottom: -60px;
}

.l--60 {
    left: -60px;
}

.a-60 {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px;
}

.x-60,
.y-60 {
    left: 60px;
    right: 60px;
}

.t-65 {
    top: 65px;
}

.r-65 {
    right: 65px;
}

.b-65 {
    bottom: 65px;
}

.l-65 {
    left: 65px;
}

.t--65 {
    top: -65px;
}

.r--65 {
    right: -65px;
}

.b--65 {
    bottom: -65px;
}

.l--65 {
    left: -65px;
}

.a-65 {
    top: 65px;
    right: 65px;
    bottom: 65px;
    left: 65px;
}

.x-65,
.y-65 {
    left: 65px;
    right: 65px;
}

.t-70 {
    top: 70px;
}

.r-70 {
    right: 70px;
}

.b-70 {
    bottom: 70px;
}

.l-70 {
    left: 70px;
}

.t--70 {
    top: -70px;
}

.r--70 {
    right: -70px;
}

.b--70 {
    bottom: -70px;
}

.l--70 {
    left: -70px;
}

.a-70 {
    top: 70px;
    right: 70px;
    bottom: 70px;
    left: 70px;
}

.x-70,
.y-70 {
    left: 70px;
    right: 70px;
}

.t-75 {
    top: 75px;
}

.r-75 {
    right: 75px;
}

.b-75 {
    bottom: 75px;
}

.l-75 {
    left: 75px;
}

.t--75 {
    top: -75px;
}

.r--75 {
    right: -75px;
}

.b--75 {
    bottom: -75px;
}

.l--75 {
    left: -75px;
}

.a-75 {
    top: 75px;
    right: 75px;
    bottom: 75px;
    left: 75px;
}

.x-75,
.y-75 {
    left: 75px;
    right: 75px;
}

.t-80 {
    top: 80px;
}

.r-80 {
    right: 80px;
}

.b-80 {
    bottom: 80px;
}

.l-80 {
    left: 80px;
}

.t--80 {
    top: -80px;
}

.r--80 {
    right: -80px;
}

.b--80 {
    bottom: -80px;
}

.l--80 {
    left: -80px;
}

.a-80 {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px;
}

.x-80,
.y-80 {
    left: 80px;
    right: 80px;
}

.t-85 {
    top: 85px;
}

.r-85 {
    right: 85px;
}

.b-85 {
    bottom: 85px;
}

.l-85 {
    left: 85px;
}

.t--85 {
    top: -85px;
}

.r--85 {
    right: -85px;
}

.b--85 {
    bottom: -85px;
}

.l--85 {
    left: -85px;
}

.a-85 {
    top: 85px;
    right: 85px;
    bottom: 85px;
    left: 85px;
}

.x-85,
.y-85 {
    left: 85px;
    right: 85px;
}

.t-90 {
    top: 90px;
}

.r-90 {
    right: 90px;
}

.b-90 {
    bottom: 90px;
}

.l-90 {
    left: 90px;
}

.t--90 {
    top: -90px;
}

.r--90 {
    right: -90px;
}

.b--90 {
    bottom: -90px;
}

.l--90 {
    left: -90px;
}

.a-90 {
    top: 90px;
    right: 90px;
    bottom: 90px;
    left: 90px;
}

.x-90,
.y-90 {
    left: 90px;
    right: 90px;
}

.t-95 {
    top: 95px;
}

.r-95 {
    right: 95px;
}

.b-95 {
    bottom: 95px;
}

.l-95 {
    left: 95px;
}

.t--95 {
    top: -95px;
}

.r--95 {
    right: -95px;
}

.b--95 {
    bottom: -95px;
}

.l--95 {
    left: -95px;
}

.a-95 {
    top: 95px;
    right: 95px;
    bottom: 95px;
    left: 95px;
}

.x-95,
.y-95 {
    left: 95px;
    right: 95px;
}

.t-100 {
    top: 100px;
}

.r-100 {
    right: 100px;
}

.b-100 {
    bottom: 100px;
}

.l-100 {
    left: 100px;
}

.t--100 {
    top: -100px;
}

.r--100 {
    right: -100px;
}

.b--100 {
    bottom: -100px;
}

.l--100 {
    left: -100px;
}

.a-100 {
    top: 100px;
    right: 100px;
    bottom: 100px;
    left: 100px;
}

.x-100,
.y-100 {
    left: 100px;
    right: 100px;
}

.t-2 {
    top: 2px;
}

.r--5 {
    right: -5px;
}

.z-index-0 {
    z-index: 0;
}

.z-index-10 {
    z-index: 10;
}

.z-index-50 {
    z-index: 50;
}

.z-index-100 {
    z-index: 100;
}

.z-index-150 {
    z-index: 150;
}

.z-index-200 {
    z-index: 200;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@media (min-width: 480px) {
    .pos-xs-absolute {
        position: absolute;
    }

    .pos-xs-relative {
        position: relative;
    }

    .pos-xs-fixed {
        position: fixed;
    }

    .pos-xs-static {
        position: static;
    }

    .t-xs-auto {
        top: auto;
    }

    .r-xs-auto {
        right: auto;
    }

    .b-xs-auto {
        bottom: auto;
    }

    .l-xs-auto {
        left: auto;
    }
}

@media (min-width: 576px) {
    .pos-sm-absolute {
        position: absolute;
    }

    .pos-sm-relative {
        position: relative;
    }

    .pos-sm-fixed {
        position: fixed;
    }

    .pos-sm-static {
        position: static;
    }

    .t-sm-auto {
        top: auto;
    }

    .r-sm-auto {
        right: auto;
    }

    .b-sm-auto {
        bottom: auto;
    }

    .l-sm-auto {
        left: auto;
    }
}

@media (min-width: 768px) {
    .pos-md-absolute {
        position: absolute;
    }

    .pos-md-relative {
        position: relative;
    }

    .pos-md-fixed {
        position: fixed;
    }

    .pos-md-static {
        position: static;
    }

    .t-md-auto {
        top: auto;
    }

    .r-md-auto {
        right: auto;
    }

    .b-md-auto {
        bottom: auto;
    }

    .l-md-auto {
        left: auto;
    }
}

@media (min-width: 992px) {
    .pos-lg-absolute {
        position: absolute;
    }

    .pos-lg-relative {
        position: relative;
    }

    .pos-lg-fixed {
        position: fixed;
    }

    .pos-lg-static {
        position: static;
    }

    .t-lg-auto {
        top: auto;
    }

    .r-lg-auto {
        right: auto;
    }

    .b-lg-auto {
        bottom: auto;
    }

    .l-lg-auto {
        left: auto;
    }
}

@media (min-width: 1200px) {
    .pos-xl-absolute {
        position: absolute;
    }

    .pos-xl-relative {
        position: relative;
    }

    .pos-xl-fixed {
        position: fixed;
    }

    .pos-xl-static {
        position: static;
    }

    .t-xl-auto {
        top: auto;
    }

    .r-xl-auto {
        right: auto;
    }

    .b-xl-auto {
        bottom: auto;
    }

    .l-xl-auto {
        left: auto;
    }
}

/* ###### Position  ###### */
/* ###### Typography  ###### */
.tx-black {
    font-weight: 900;
    color: #8f9cc0;
}

.tx-bold {
    font-weight: 700;
}

.tx-semibold {
    font-weight: 600;
}

.tx-medium {
    font-weight: 500;
}

.tx-normal {
    font-weight: 400;
}

.tx-light {
    font-weight: 300;
}

.tx-thin {
    font-weight: 200;
}

.tx-xthin {
    font-weight: 100;
}

.tx-sserif {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.tx-roboto {
    font-family: "Roboto", sans-serif;
}

.tx-white {
    color: #ffffff;
}

.tx-success {
    color: #19b159;
}

.tx-warning {
    color: #ff9b21;
}

.tx-danger {
    color: #f16d75;
}

.tx-info {
    color: #01b8ff;
}

.tx-inverse {
    color: #3c4858;
}

.tx-teal {
    color: #00cccc;
}

.tx-dark {
    color: #38415a;
}

.tx-indigo {
    color: #3F00FF;
}

.tx-purple {
    color: #6f42c1;
}

.tx-orange {
    color: #fd7e14;
}

.tx-pink {
    color: #f10075;
}

.tx-gray-100 {
    color: #f1f2f9;
}

.tx-gray-200,
.tx-gray-300 {
    color: #eaedf7;
}

.tx-gray-400 {
    color: #e8e8f7;
}

.tx-gray-500,
.tx-gray-600 {
    color: #a8afc7;
}

.tx-gray-700 {
    color: #3c4858;
}

.tx-gray-800 {
    color: #3b4863;
}

.tx-gray-900 {
    color: #8f9cc0;
}

.tx-white-2 {
    color: rgba(255, 255, 255, 0.2);
}

.tx-white-3 {
    color: rgba(255, 255, 255, 0.3);
}

.tx-white-4 {
    color: rgba(255, 255, 255, 0.4);
}

.tx-white-5 {
    color: rgba(255, 255, 255, 0.5);
}

.tx-white-6 {
    color: rgba(255, 255, 255, 0.6);
}

.tx-white-7 {
    color: rgba(255, 255, 255, 0.7);
}

.tx-white-8 {
    color: rgba(255, 255, 255, 0.8);
}

.tx-spacing-1 {
    letter-spacing: 0.5px;
}

.tx-spacing-2 {
    letter-spacing: 1px;
}

.tx-spacing-3 {
    letter-spacing: 1.5px;
}

.tx-spacing-4 {
    letter-spacing: 2px;
}

.tx-spacing-5 {
    letter-spacing: 2.5px;
}

.tx-spacing-6 {
    letter-spacing: 3px;
}

.tx-spacing-7 {
    letter-spacing: 3.5px;
}

.tx-spacing-8 {
    letter-spacing: 4px;
}

.tx-spacing--1 {
    letter-spacing: -0.5px;
}

.tx-spacing--2 {
    letter-spacing: -1px;
}

.tx-spacing--3 {
    letter-spacing: -1.5px;
}

.tx-spacing--4 {
    letter-spacing: -2px;
}

.tx-spacing--5 {
    letter-spacing: -2.5px;
}

.tx-spacing--6 {
    letter-spacing: -3px;
}

.tx-spacing--7 {
    letter-spacing: -3.5px;
}

.tx-spacing--8 {
    letter-spacing: -4px;
}

.tx-uppercase {
    text-transform: uppercase;
}

.tx-lowercase {
    text-transform: lowercase;
}

.tx-transform-none {
    text-transform: none;
}

.tx-center {
    text-align: center;
}

.tx-right {
    text-align: right;
}

.tx-left {
    text-align: left;
}

.tx-italic {
    font-style: italic;
}

.tx-style-normal {
    font-style: normal;
}

.lh-base {
    line-height: 1.5;
}

.lh-normal {
    line-height: normal;
}

.lh-0 {
    line-height: 0;
}

.lh--1 {
    line-height: 0.1;
}

.lh--2 {
    line-height: 0.2;
}

.lh--3 {
    line-height: 0.3;
}

.lh--4 {
    line-height: 0.4;
}

.lh--5 {
    line-height: 0.5;
}

.lh--6 {
    line-height: 0.6;
}

.lh--7 {
    line-height: 0.7;
}

.lh--8 {
    line-height: 0.8;
}

.lh--9 {
    line-height: 0.9;
}

.lh-1 {
    line-height: 1.1;
}

.lh-2 {
    line-height: 1.2;
}

.lh-3 {
    line-height: 1.3;
}

.lh-4 {
    line-height: 1.4;
}

.lh-5 {
    line-height: 1.5 !important;
}

.lh-6 {
    line-height: 1.6;
}

.lh-7 {
    line-height: 1.7;
}

.lh-8 {
    line-height: 1.8;
}

.lh-9 {
    line-height: 1.9;
}

.lh-10 {
    line-height: 2;
}

.lh-11 {
    line-height: 2.1;
}

.lh-12 {
    line-height: 2.2;
}

.lh-13 {
    line-height: 2.3;
}

.lh-14 {
    line-height: 2.4;
}

.lh-15 {
    line-height: 2.5;
}

.valign-top {
    vertical-align: top;
}

.valign-middle {
    vertical-align: middle;
}

.valign-bottom {
    vertical-align: baseline;
}

.tx-base {
    font-size: 0.875rem;
}

.tx-8 {
    font-size: 8px;
}

.tx-9 {
    font-size: 9px;
}

.tx-10 {
    font-size: 10px !important;
}

.tx-11 {
    font-size: 11px;
}

.tx-12 {
    font-size: 12px;
}

.tx-13 {
    font-size: 13px;
}

.tx-14 {
    font-size: 14px;
}

.tx-15 {
    font-size: 15px !important;
}

.tx-16 {
    font-size: 16px;
}

.tx-18 {
    font-size: 18px;
}

.tx-20 {
    font-size: 20px;
}

.tx-22 {
    font-size: 22px;
}

.tx-24 {
    font-size: 24px;
}

.tx-26 {
    font-size: 26px;
}

.tx-28 {
    font-size: 28px;
}

.tx-30 {
    font-size: 30px;
}

.tx-32 {
    font-size: 32px;
}

.tx-34 {
    font-size: 34px;
}

.tx-36 {
    font-size: 36px;
}

.tx-38 {
    font-size: 38px;
}

.tx-40 {
    font-size: 40px;
}

.tx-42 {
    font-size: 42px;
}

.tx-44 {
    font-size: 44px;
}

.tx-46 {
    font-size: 46px;
}

.tx-48 {
    font-size: 48px;
}

.tx-50 {
    font-size: 50px;
}

.tx-52 {
    font-size: 52px;
}

.tx-54 {
    font-size: 54px;
}

.tx-56 {
    font-size: 56px;
}

.tx-58 {
    font-size: 58px;
}

.tx-60 {
    font-size: 60px;
}

.tx-62 {
    font-size: 62px;
}

.tx-64 {
    font-size: 64px;
}

.tx-66 {
    font-size: 66px;
}

.tx-68 {
    font-size: 68px;
}

.tx-70 {
    font-size: 70px;
}

.tx-72 {
    font-size: 72px;
}

.tx-74 {
    font-size: 74px;
}

.tx-76 {
    font-size: 76px;
}

.tx-78 {
    font-size: 78px;
}

.tx-80 {
    font-size: 80px;
}

.tx-82 {
    font-size: 82px;
}

.tx-84 {
    font-size: 84px;
}

.tx-86 {
    font-size: 86px;
}

.tx-88 {
    font-size: 88px;
}

.tx-90 {
    font-size: 90px;
}

.tx-92 {
    font-size: 92px;
}

.tx-94 {
    font-size: 94px;
}

.tx-96 {
    font-size: 96px;
}

.tx-98 {
    font-size: 98px;
}

.tx-100 {
    font-size: 100px;
}

.tx-102 {
    font-size: 102px;
}

.tx-104 {
    font-size: 104px;
}

.tx-106 {
    font-size: 106px;
}

.tx-108 {
    font-size: 108px;
}

.tx-110 {
    font-size: 110px;
}

.tx-112 {
    font-size: 112px;
}

.tx-114 {
    font-size: 114px;
}

.tx-116 {
    font-size: 116px;
}

.tx-118 {
    font-size: 118px;
}

.tx-120 {
    font-size: 120px;
}

.tx-122 {
    font-size: 122px;
}

.tx-124 {
    font-size: 124px;
}

.tx-126 {
    font-size: 126px;
}

.tx-128 {
    font-size: 128px;
}

.tx-130 {
    font-size: 130px;
}

.tx-132 {
    font-size: 132px;
}

.tx-134 {
    font-size: 134px;
}

.tx-136 {
    font-size: 136px;
}

.tx-138 {
    font-size: 138px;
}

.tx-140 {
    font-size: 140px;
}

@media (min-width: 480px) {
    .tx-xs-8 {
        font-size: 8px;
    }

    .tx-xs-9 {
        font-size: 9px;
    }

    .tx-xs-10 {
        font-size: 10px;
    }

    .tx-xs-11 {
        font-size: 11px;
    }

    .tx-xs-12 {
        font-size: 12px;
    }

    .tx-xs-13 {
        font-size: 13px;
    }

    .tx-xs-14 {
        font-size: 14px;
    }

    .tx-xs-15 {
        font-size: 15px;
    }

    .tx-xs-16 {
        font-size: 16px;
    }

    .tx-xs-18 {
        font-size: 18px;
    }

    .tx-xs-20 {
        font-size: 20px;
    }

    .tx-xs-22 {
        font-size: 22px;
    }

    .tx-xs-24 {
        font-size: 24px;
    }

    .tx-xs-26 {
        font-size: 26px;
    }

    .tx-xs-28 {
        font-size: 28px;
    }

    .tx-xs-30 {
        font-size: 30px;
    }

    .tx-xs-32 {
        font-size: 32px;
    }

    .tx-xs-34 {
        font-size: 34px;
    }

    .tx-xs-36 {
        font-size: 36px;
    }

    .tx-xs-38 {
        font-size: 38px;
    }

    .tx-xs-40 {
        font-size: 40px;
    }

    .tx-xs-42 {
        font-size: 42px;
    }

    .tx-xs-44 {
        font-size: 44px;
    }

    .tx-xs-46 {
        font-size: 46px;
    }

    .tx-xs-48 {
        font-size: 48px;
    }

    .tx-xs-50 {
        font-size: 50px;
    }

    .tx-xs-52 {
        font-size: 52px;
    }

    .tx-xs-54 {
        font-size: 54px;
    }

    .tx-xs-56 {
        font-size: 56px;
    }

    .tx-xs-58 {
        font-size: 58px;
    }

    .tx-xs-60 {
        font-size: 60px;
    }

    .tx-xs-62 {
        font-size: 62px;
    }

    .tx-xs-64 {
        font-size: 64px;
    }

    .tx-xs-66 {
        font-size: 66px;
    }

    .tx-xs-68 {
        font-size: 68px;
    }

    .tx-xs-70 {
        font-size: 70px;
    }

    .tx-xs-72 {
        font-size: 72px;
    }

    .tx-xs-74 {
        font-size: 74px;
    }

    .tx-xs-76 {
        font-size: 76px;
    }

    .tx-xs-78 {
        font-size: 78px;
    }

    .tx-xs-80 {
        font-size: 80px;
    }

    .tx-xs-82 {
        font-size: 82px;
    }

    .tx-xs-84 {
        font-size: 84px;
    }

    .tx-xs-86 {
        font-size: 86px;
    }

    .tx-xs-88 {
        font-size: 88px;
    }

    .tx-xs-90 {
        font-size: 90px;
    }

    .tx-xs-92 {
        font-size: 92px;
    }

    .tx-xs-94 {
        font-size: 94px;
    }

    .tx-xs-96 {
        font-size: 96px;
    }

    .tx-xs-98 {
        font-size: 98px;
    }

    .tx-xs-100 {
        font-size: 100px;
    }

    .tx-xs-102 {
        font-size: 102px;
    }

    .tx-xs-104 {
        font-size: 104px;
    }

    .tx-xs-106 {
        font-size: 106px;
    }

    .tx-xs-108 {
        font-size: 108px;
    }

    .tx-xs-110 {
        font-size: 110px;
    }

    .tx-xs-112 {
        font-size: 112px;
    }

    .tx-xs-114 {
        font-size: 114px;
    }

    .tx-xs-116 {
        font-size: 116px;
    }

    .tx-xs-118 {
        font-size: 118px;
    }

    .tx-xs-120 {
        font-size: 120px;
    }

    .tx-xs-122 {
        font-size: 122px;
    }

    .tx-xs-124 {
        font-size: 124px;
    }

    .tx-xs-126 {
        font-size: 126px;
    }

    .tx-xs-128 {
        font-size: 128px;
    }

    .tx-xs-130 {
        font-size: 130px;
    }

    .tx-xs-132 {
        font-size: 132px;
    }

    .tx-xs-134 {
        font-size: 134px;
    }

    .tx-xs-136 {
        font-size: 136px;
    }

    .tx-xs-138 {
        font-size: 138px;
    }

    .tx-xs-140 {
        font-size: 140px;
    }

    .tx-xs-base {
        font-size: 0.875rem;
    }

    .tx-xs-bold {
        font-weight: 700;
    }

    .tx-xs-semibold {
        font-weight: 600;
    }

    .tx-xs-medium {
        font-weight: 500;
    }

    .tx-xs-normal {
        font-weight: 400;
    }

    .tx-xs-light {
        font-weight: 300;
    }

    .tx-xs-thin {
        font-weight: 200;
    }

    .tx-xs-xthin {
        font-weight: 100;
    }

    .lh-xs-0 {
        line-height: 0;
    }

    .lh-xs-1 {
        line-height: 1.1;
    }

    .lh-xs-2 {
        line-height: 1.2;
    }

    .lh-xs-3 {
        line-height: 1.3;
    }

    .lh-xs-4 {
        line-height: 1.4;
    }

    .lh-xs-5 {
        line-height: 1.5;
    }

    .lh-xs-6 {
        line-height: 1.6;
    }

    .lh-xs-7 {
        line-height: 1.7;
    }

    .lh-xs-8 {
        line-height: 1.8;
    }

    .lh-xs-9 {
        line-height: 1.9;
    }

    .lh-xs-10 {
        line-height: 2;
    }

    .lh-xs-11 {
        line-height: 2.1;
    }

    .lh-xs-12 {
        line-height: 2.2;
    }

    .lh-xs-13 {
        line-height: 2.3;
    }

    .lh-xs-14 {
        line-height: 2.4;
    }

    .lh-xs-15 {
        line-height: 2.5;
    }

    .tx-xs-center {
        text-align: center;
    }

    .tx-xs-right {
        text-align: right;
    }

    .tx-xs-left {
        text-align: left;
    }
}

@media (min-width: 576px) {
    .tx-sm-8 {
        font-size: 8px;
    }

    .tx-sm-9 {
        font-size: 9px;
    }

    .tx-sm-10 {
        font-size: 10px;
    }

    .tx-sm-11 {
        font-size: 11px;
    }

    .tx-sm-12 {
        font-size: 12px;
    }

    .tx-sm-13 {
        font-size: 13px;
    }

    .tx-sm-14 {
        font-size: 14px;
    }

    .tx-sm-15 {
        font-size: 15px;
    }

    .tx-sm-16 {
        font-size: 16px;
    }

    .tx-sm-18 {
        font-size: 18px;
    }

    .tx-sm-20 {
        font-size: 20px;
    }

    .tx-sm-22 {
        font-size: 22px;
    }

    .tx-sm-24 {
        font-size: 24px;
    }

    .tx-sm-26 {
        font-size: 26px;
    }

    .tx-sm-28 {
        font-size: 28px;
    }

    .tx-sm-30 {
        font-size: 30px;
    }

    .tx-sm-32 {
        font-size: 32px;
    }

    .tx-sm-34 {
        font-size: 34px;
    }

    .tx-sm-36 {
        font-size: 36px;
    }

    .tx-sm-38 {
        font-size: 38px;
    }

    .tx-sm-40 {
        font-size: 40px;
    }

    .tx-sm-42 {
        font-size: 42px;
    }

    .tx-sm-44 {
        font-size: 44px;
    }

    .tx-sm-46 {
        font-size: 46px;
    }

    .tx-sm-48 {
        font-size: 48px;
    }

    .tx-sm-50 {
        font-size: 50px;
    }

    .tx-sm-52 {
        font-size: 52px;
    }

    .tx-sm-54 {
        font-size: 54px;
    }

    .tx-sm-56 {
        font-size: 56px;
    }

    .tx-sm-58 {
        font-size: 58px;
    }

    .tx-sm-60 {
        font-size: 60px;
    }

    .tx-sm-62 {
        font-size: 62px;
    }

    .tx-sm-64 {
        font-size: 64px;
    }

    .tx-sm-66 {
        font-size: 66px;
    }

    .tx-sm-68 {
        font-size: 68px;
    }

    .tx-sm-70 {
        font-size: 70px;
    }

    .tx-sm-72 {
        font-size: 72px;
    }

    .tx-sm-74 {
        font-size: 74px;
    }

    .tx-sm-76 {
        font-size: 76px;
    }

    .tx-sm-78 {
        font-size: 78px;
    }

    .tx-sm-80 {
        font-size: 80px;
    }

    .tx-sm-82 {
        font-size: 82px;
    }

    .tx-sm-84 {
        font-size: 84px;
    }

    .tx-sm-86 {
        font-size: 86px;
    }

    .tx-sm-88 {
        font-size: 88px;
    }

    .tx-sm-90 {
        font-size: 90px;
    }

    .tx-sm-92 {
        font-size: 92px;
    }

    .tx-sm-94 {
        font-size: 94px;
    }

    .tx-sm-96 {
        font-size: 96px;
    }

    .tx-sm-98 {
        font-size: 98px;
    }

    .tx-sm-100 {
        font-size: 100px;
    }

    .tx-sm-102 {
        font-size: 102px;
    }

    .tx-sm-104 {
        font-size: 104px;
    }

    .tx-sm-106 {
        font-size: 106px;
    }

    .tx-sm-108 {
        font-size: 108px;
    }

    .tx-sm-110 {
        font-size: 110px;
    }

    .tx-sm-112 {
        font-size: 112px;
    }

    .tx-sm-114 {
        font-size: 114px;
    }

    .tx-sm-116 {
        font-size: 116px;
    }

    .tx-sm-118 {
        font-size: 118px;
    }

    .tx-sm-120 {
        font-size: 120px;
    }

    .tx-sm-122 {
        font-size: 122px;
    }

    .tx-sm-124 {
        font-size: 124px;
    }

    .tx-sm-126 {
        font-size: 126px;
    }

    .tx-sm-128 {
        font-size: 128px;
    }

    .tx-sm-130 {
        font-size: 130px;
    }

    .tx-sm-132 {
        font-size: 132px;
    }

    .tx-sm-134 {
        font-size: 134px;
    }

    .tx-sm-136 {
        font-size: 136px;
    }

    .tx-sm-138 {
        font-size: 138px;
    }

    .tx-sm-140 {
        font-size: 140px;
    }

    .tx-sm-base {
        font-size: 0.875rem;
    }

    .tx-sm-bold {
        font-weight: 700;
    }

    .tx-sm-semibold {
        font-weight: 600;
    }

    .tx-sm-medium {
        font-weight: 500;
    }

    .tx-sm-normal {
        font-weight: 400;
    }

    .tx-sm-light {
        font-weight: 300;
    }

    .tx-sm-thin {
        font-weight: 200;
    }

    .tx-sm-xthin {
        font-weight: 100;
    }

    .lh-sm-0 {
        line-height: 0;
    }

    .lh-sm-1 {
        line-height: 1.1;
    }

    .lh-sm-2 {
        line-height: 1.2;
    }

    .lh-sm-3 {
        line-height: 1.3;
    }

    .lh-sm-4 {
        line-height: 1.4;
    }

    .lh-sm-5 {
        line-height: 1.5;
    }

    .lh-sm-6 {
        line-height: 1.6;
    }

    .lh-sm-7 {
        line-height: 1.7;
    }

    .lh-sm-8 {
        line-height: 1.8;
    }

    .lh-sm-9 {
        line-height: 1.9;
    }

    .lh-sm-10 {
        line-height: 2;
    }

    .lh-sm-11 {
        line-height: 2.1;
    }

    .lh-sm-12 {
        line-height: 2.2;
    }

    .lh-sm-13 {
        line-height: 2.3;
    }

    .lh-sm-14 {
        line-height: 2.4;
    }

    .lh-sm-15 {
        line-height: 2.5;
    }

    .tx-sm-center {
        text-align: center;
    }

    .tx-sm-right {
        text-align: right;
    }

    .tx-sm-left {
        text-align: left;
    }
}

@media (min-width: 768px) {
    .tx-md-8 {
        font-size: 8px;
    }

    .tx-md-9 {
        font-size: 9px;
    }

    .tx-md-10 {
        font-size: 10px;
    }

    .tx-md-11 {
        font-size: 11px;
    }

    .tx-md-12 {
        font-size: 12px;
    }

    .tx-md-13 {
        font-size: 13px;
    }

    .tx-md-14 {
        font-size: 14px;
    }

    .tx-md-15 {
        font-size: 15px;
    }

    .tx-md-16 {
        font-size: 16px;
    }

    .tx-md-18 {
        font-size: 18px;
    }

    .tx-md-20 {
        font-size: 20px;
    }

    .tx-md-22 {
        font-size: 22px;
    }

    .tx-md-24 {
        font-size: 24px;
    }

    .tx-md-26 {
        font-size: 26px;
    }

    .tx-md-28 {
        font-size: 28px;
    }

    .tx-md-30 {
        font-size: 30px;
    }

    .tx-md-32 {
        font-size: 32px;
    }

    .tx-md-34 {
        font-size: 34px;
    }

    .tx-md-36 {
        font-size: 36px;
    }

    .tx-md-38 {
        font-size: 38px;
    }

    .tx-md-40 {
        font-size: 40px;
    }

    .tx-md-42 {
        font-size: 42px;
    }

    .tx-md-44 {
        font-size: 44px;
    }

    .tx-md-46 {
        font-size: 46px;
    }

    .tx-md-48 {
        font-size: 48px;
    }

    .tx-md-50 {
        font-size: 50px;
    }

    .tx-md-52 {
        font-size: 52px;
    }

    .tx-md-54 {
        font-size: 54px;
    }

    .tx-md-56 {
        font-size: 56px;
    }

    .tx-md-58 {
        font-size: 58px;
    }

    .tx-md-60 {
        font-size: 60px;
    }

    .tx-md-62 {
        font-size: 62px;
    }

    .tx-md-64 {
        font-size: 64px;
    }

    .tx-md-66 {
        font-size: 66px;
    }

    .tx-md-68 {
        font-size: 68px;
    }

    .tx-md-70 {
        font-size: 70px;
    }

    .tx-md-72 {
        font-size: 72px;
    }

    .tx-md-74 {
        font-size: 74px;
    }

    .tx-md-76 {
        font-size: 76px;
    }

    .tx-md-78 {
        font-size: 78px;
    }

    .tx-md-80 {
        font-size: 80px;
    }

    .tx-md-82 {
        font-size: 82px;
    }

    .tx-md-84 {
        font-size: 84px;
    }

    .tx-md-86 {
        font-size: 86px;
    }

    .tx-md-88 {
        font-size: 88px;
    }

    .tx-md-90 {
        font-size: 90px;
    }

    .tx-md-92 {
        font-size: 92px;
    }

    .tx-md-94 {
        font-size: 94px;
    }

    .tx-md-96 {
        font-size: 96px;
    }

    .tx-md-98 {
        font-size: 98px;
    }

    .tx-md-100 {
        font-size: 100px;
    }

    .tx-md-102 {
        font-size: 102px;
    }

    .tx-md-104 {
        font-size: 104px;
    }

    .tx-md-106 {
        font-size: 106px;
    }

    .tx-md-108 {
        font-size: 108px;
    }

    .tx-md-110 {
        font-size: 110px;
    }

    .tx-md-112 {
        font-size: 112px;
    }

    .tx-md-114 {
        font-size: 114px;
    }

    .tx-md-116 {
        font-size: 116px;
    }

    .tx-md-118 {
        font-size: 118px;
    }

    .tx-md-120 {
        font-size: 120px;
    }

    .tx-md-122 {
        font-size: 122px;
    }

    .tx-md-124 {
        font-size: 124px;
    }

    .tx-md-126 {
        font-size: 126px;
    }

    .tx-md-128 {
        font-size: 128px;
    }

    .tx-md-130 {
        font-size: 130px;
    }

    .tx-md-132 {
        font-size: 132px;
    }

    .tx-md-134 {
        font-size: 134px;
    }

    .tx-md-136 {
        font-size: 136px;
    }

    .tx-md-138 {
        font-size: 138px;
    }

    .tx-md-140 {
        font-size: 140px;
    }

    .tx-md-base {
        font-size: 0.875rem;
    }

    .tx-md-bold {
        font-weight: 700;
    }

    .tx-md-semibold {
        font-weight: 600;
    }

    .tx-md-medium {
        font-weight: 500;
    }

    .tx-md-normal {
        font-weight: 400;
    }

    .tx-md-light {
        font-weight: 300;
    }

    .tx-md-thin {
        font-weight: 200;
    }

    .tx-md-xthin {
        font-weight: 100;
    }

    .lh-md-0 {
        line-height: 0;
    }

    .lh-md-1 {
        line-height: 1.1;
    }

    .lh-md-2 {
        line-height: 1.2;
    }

    .lh-md-3 {
        line-height: 1.3;
    }

    .lh-md-4 {
        line-height: 1.4;
    }

    .lh-md-5 {
        line-height: 1.5;
    }

    .lh-md-6 {
        line-height: 1.6;
    }

    .lh-md-7 {
        line-height: 1.7;
    }

    .lh-md-8 {
        line-height: 1.8;
    }

    .lh-md-9 {
        line-height: 1.9;
    }

    .lh-md-10 {
        line-height: 2;
    }

    .lh-md-11 {
        line-height: 2.1;
    }

    .lh-md-12 {
        line-height: 2.2;
    }

    .lh-md-13 {
        line-height: 2.3;
    }

    .lh-md-14 {
        line-height: 2.4;
    }

    .lh-md-15 {
        line-height: 2.5;
    }

    .tx-md-center {
        text-align: center;
    }

    .tx-md-right {
        text-align: right;
    }

    .tx-md-left {
        text-align: left;
    }
}

@media (min-width: 992px) {
    .tx-lg-8 {
        font-size: 8px;
    }

    .tx-lg-9 {
        font-size: 9px;
    }

    .tx-lg-10 {
        font-size: 10px;
    }

    .tx-lg-11 {
        font-size: 11px;
    }

    .tx-lg-12 {
        font-size: 12px;
    }

    .tx-lg-13 {
        font-size: 13px;
    }

    .tx-lg-14 {
        font-size: 14px;
    }

    .tx-lg-15 {
        font-size: 15px;
    }

    .tx-lg-16 {
        font-size: 16px;
    }

    .tx-lg-18 {
        font-size: 18px;
    }

    .tx-lg-20 {
        font-size: 20px;
    }

    .tx-lg-22 {
        font-size: 22px;
    }

    .tx-lg-24 {
        font-size: 24px;
    }

    .tx-lg-26 {
        font-size: 26px;
    }

    .tx-lg-28 {
        font-size: 28px;
    }

    .tx-lg-30 {
        font-size: 30px;
    }

    .tx-lg-32 {
        font-size: 32px;
    }

    .tx-lg-34 {
        font-size: 34px;
    }

    .tx-lg-36 {
        font-size: 36px;
    }

    .tx-lg-38 {
        font-size: 38px;
    }

    .tx-lg-40 {
        font-size: 40px;
    }

    .tx-lg-42 {
        font-size: 42px;
    }

    .tx-lg-44 {
        font-size: 44px;
    }

    .tx-lg-46 {
        font-size: 46px;
    }

    .tx-lg-48 {
        font-size: 48px;
    }

    .tx-lg-50 {
        font-size: 50px;
    }

    .tx-lg-52 {
        font-size: 52px;
    }

    .tx-lg-54 {
        font-size: 54px;
    }

    .tx-lg-56 {
        font-size: 56px;
    }

    .tx-lg-58 {
        font-size: 58px;
    }

    .tx-lg-60 {
        font-size: 60px;
    }

    .tx-lg-62 {
        font-size: 62px;
    }

    .tx-lg-64 {
        font-size: 64px;
    }

    .tx-lg-66 {
        font-size: 66px;
    }

    .tx-lg-68 {
        font-size: 68px;
    }

    .tx-lg-70 {
        font-size: 70px;
    }

    .tx-lg-72 {
        font-size: 72px;
    }

    .tx-lg-74 {
        font-size: 74px;
    }

    .tx-lg-76 {
        font-size: 76px;
    }

    .tx-lg-78 {
        font-size: 78px;
    }

    .tx-lg-80 {
        font-size: 80px;
    }

    .tx-lg-82 {
        font-size: 82px;
    }

    .tx-lg-84 {
        font-size: 84px;
    }

    .tx-lg-86 {
        font-size: 86px;
    }

    .tx-lg-88 {
        font-size: 88px;
    }

    .tx-lg-90 {
        font-size: 90px;
    }

    .tx-lg-92 {
        font-size: 92px;
    }

    .tx-lg-94 {
        font-size: 94px;
    }

    .tx-lg-96 {
        font-size: 96px;
    }

    .tx-lg-98 {
        font-size: 98px;
    }

    .tx-lg-100 {
        font-size: 100px;
    }

    .tx-lg-102 {
        font-size: 102px;
    }

    .tx-lg-104 {
        font-size: 104px;
    }

    .tx-lg-106 {
        font-size: 106px;
    }

    .tx-lg-108 {
        font-size: 108px;
    }

    .tx-lg-110 {
        font-size: 110px;
    }

    .tx-lg-112 {
        font-size: 112px;
    }

    .tx-lg-114 {
        font-size: 114px;
    }

    .tx-lg-116 {
        font-size: 116px;
    }

    .tx-lg-118 {
        font-size: 118px;
    }

    .tx-lg-120 {
        font-size: 120px;
    }

    .tx-lg-122 {
        font-size: 122px;
    }

    .tx-lg-124 {
        font-size: 124px;
    }

    .tx-lg-126 {
        font-size: 126px;
    }

    .tx-lg-128 {
        font-size: 128px;
    }

    .tx-lg-130 {
        font-size: 130px;
    }

    .tx-lg-132 {
        font-size: 132px;
    }

    .tx-lg-134 {
        font-size: 134px;
    }

    .tx-lg-136 {
        font-size: 136px;
    }

    .tx-lg-138 {
        font-size: 138px;
    }

    .tx-lg-140 {
        font-size: 140px;
    }

    .tx-lg-base {
        font-size: 0.875rem;
    }

    .tx-lg-bold {
        font-weight: 700;
    }

    .tx-lg-semibold {
        font-weight: 600;
    }

    .tx-lg-medium {
        font-weight: 500;
    }

    .tx-lg-normal {
        font-weight: 400;
    }

    .tx-lg-light {
        font-weight: 300;
    }

    .tx-lg-thin {
        font-weight: 200;
    }

    .tx-lg-xthin {
        font-weight: 100;
    }

    .lh-lg-0 {
        line-height: 0;
    }

    .lh-lg-1 {
        line-height: 1.1;
    }

    .lh-lg-2 {
        line-height: 1.2;
    }

    .lh-lg-3 {
        line-height: 1.3;
    }

    .lh-lg-4 {
        line-height: 1.4;
    }

    .lh-lg-5 {
        line-height: 1.5;
    }

    .lh-lg-6 {
        line-height: 1.6;
    }

    .lh-lg-7 {
        line-height: 1.7;
    }

    .lh-lg-8 {
        line-height: 1.8;
    }

    .lh-lg-9 {
        line-height: 1.9;
    }

    .lh-lg-10 {
        line-height: 2;
    }

    .lh-lg-11 {
        line-height: 2.1;
    }

    .lh-lg-12 {
        line-height: 2.2;
    }

    .lh-lg-13 {
        line-height: 2.3;
    }

    .lh-lg-14 {
        line-height: 2.4;
    }

    .lh-lg-15 {
        line-height: 2.5;
    }

    .tx-lg-center {
        text-align: center;
    }

    .tx-lg-right {
        text-align: right;
    }

    .tx-lg-left {
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .tx-xl-8 {
        font-size: 8px;
    }

    .tx-xl-9 {
        font-size: 9px;
    }

    .tx-xl-10 {
        font-size: 10px;
    }

    .tx-xl-11 {
        font-size: 11px;
    }

    .tx-xl-12 {
        font-size: 12px;
    }

    .tx-xl-13 {
        font-size: 13px;
    }

    .tx-xl-14 {
        font-size: 14px;
    }

    .tx-xl-15 {
        font-size: 15px;
    }

    .tx-xl-16 {
        font-size: 16px;
    }

    .tx-xl-18 {
        font-size: 18px;
    }

    .tx-xl-20 {
        font-size: 20px;
    }

    .tx-xl-22 {
        font-size: 22px;
    }

    .tx-xl-24 {
        font-size: 24px;
    }

    .tx-xl-26 {
        font-size: 26px;
    }

    .tx-xl-28 {
        font-size: 28px;
    }

    .tx-xl-30 {
        font-size: 30px;
    }

    .tx-xl-32 {
        font-size: 32px;
    }

    .tx-xl-34 {
        font-size: 34px;
    }

    .tx-xl-36 {
        font-size: 36px;
    }

    .tx-xl-38 {
        font-size: 38px;
    }

    .tx-xl-40 {
        font-size: 40px;
    }

    .tx-xl-42 {
        font-size: 42px;
    }

    .tx-xl-44 {
        font-size: 44px;
    }

    .tx-xl-46 {
        font-size: 46px;
    }

    .tx-xl-48 {
        font-size: 48px;
    }

    .tx-xl-50 {
        font-size: 50px;
    }

    .tx-xl-52 {
        font-size: 52px;
    }

    .tx-xl-54 {
        font-size: 54px;
    }

    .tx-xl-56 {
        font-size: 56px;
    }

    .tx-xl-58 {
        font-size: 58px;
    }

    .tx-xl-60 {
        font-size: 60px;
    }

    .tx-xl-62 {
        font-size: 62px;
    }

    .tx-xl-64 {
        font-size: 64px;
    }

    .tx-xl-66 {
        font-size: 66px;
    }

    .tx-xl-68 {
        font-size: 68px;
    }

    .tx-xl-70 {
        font-size: 70px;
    }

    .tx-xl-72 {
        font-size: 72px;
    }

    .tx-xl-74 {
        font-size: 74px;
    }

    .tx-xl-76 {
        font-size: 76px;
    }

    .tx-xl-78 {
        font-size: 78px;
    }

    .tx-xl-80 {
        font-size: 80px;
    }

    .tx-xl-82 {
        font-size: 82px;
    }

    .tx-xl-84 {
        font-size: 84px;
    }

    .tx-xl-86 {
        font-size: 86px;
    }

    .tx-xl-88 {
        font-size: 88px;
    }

    .tx-xl-90 {
        font-size: 90px;
    }

    .tx-xl-92 {
        font-size: 92px;
    }

    .tx-xl-94 {
        font-size: 94px;
    }

    .tx-xl-96 {
        font-size: 96px;
    }

    .tx-xl-98 {
        font-size: 98px;
    }

    .tx-xl-100 {
        font-size: 100px;
    }

    .tx-xl-102 {
        font-size: 102px;
    }

    .tx-xl-104 {
        font-size: 104px;
    }

    .tx-xl-106 {
        font-size: 106px;
    }

    .tx-xl-108 {
        font-size: 108px;
    }

    .tx-xl-110 {
        font-size: 110px;
    }

    .tx-xl-112 {
        font-size: 112px;
    }

    .tx-xl-114 {
        font-size: 114px;
    }

    .tx-xl-116 {
        font-size: 116px;
    }

    .tx-xl-118 {
        font-size: 118px;
    }

    .tx-xl-120 {
        font-size: 120px;
    }

    .tx-xl-122 {
        font-size: 122px;
    }

    .tx-xl-124 {
        font-size: 124px;
    }

    .tx-xl-126 {
        font-size: 126px;
    }

    .tx-xl-128 {
        font-size: 128px;
    }

    .tx-xl-130 {
        font-size: 130px;
    }

    .tx-xl-132 {
        font-size: 132px;
    }

    .tx-xl-134 {
        font-size: 134px;
    }

    .tx-xl-136 {
        font-size: 136px;
    }

    .tx-xl-138 {
        font-size: 138px;
    }

    .tx-xl-140 {
        font-size: 140px;
    }

    .tx-xl-base {
        font-size: 0.875rem;
    }

    .tx-xl-bold {
        font-weight: 700;
    }

    .tx-xl-semibold {
        font-weight: 600;
    }

    .tx-xl-medium {
        font-weight: 500;
    }

    .tx-xl-normal {
        font-weight: 400;
    }

    .tx-xl-light {
        font-weight: 300;
    }

    .tx-xl-thin {
        font-weight: 200;
    }

    .tx-xl-xthin {
        font-weight: 100;
    }

    .lh-xl-0 {
        line-height: 0;
    }

    .lh-xl-1 {
        line-height: 1.1;
    }

    .lh-xl-2 {
        line-height: 1.2;
    }

    .lh-xl-3 {
        line-height: 1.3;
    }

    .lh-xl-4 {
        line-height: 1.4;
    }

    .lh-xl-5 {
        line-height: 1.5;
    }

    .lh-xl-6 {
        line-height: 1.6;
    }

    .lh-xl-7 {
        line-height: 1.7;
    }

    .lh-xl-8 {
        line-height: 1.8;
    }

    .lh-xl-9 {
        line-height: 1.9;
    }

    .lh-xl-10 {
        line-height: 2;
    }

    .lh-xl-11 {
        line-height: 2.1;
    }

    .lh-xl-12 {
        line-height: 2.2;
    }

    .lh-xl-13 {
        line-height: 2.3;
    }

    .lh-xl-14 {
        line-height: 2.4;
    }

    .lh-xl-15 {
        line-height: 2.5;
    }

    .tx-xl-center {
        text-align: center;
    }

    .tx-xl-right {
        text-align: right;
    }

    .tx-xl-left {
        text-align: left;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
    font-size: 14px;
    line-height: 1.66;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-start {
    text-align: left !important;
}

.text-end {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-semibold {
    font-weight: 500 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #ffffff !important;
}

.text-secondary {
    color: #f1388b !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #e06b32 !important;
}

.text-success {
    color: #19b159 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #226401 !important;
}

.text-info {
    color: #01b8ff !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #0f6674 !important;
}

.text-warning {
    color: #ff9b21 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #ba8b00 !important;
}

.text-danger {
    color: #f16d75 !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #a71d2a !important;
}

.text-light {
    color: #f1f2f9 !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #c5cbda !important;
}

.text-dark {
    color: #465988 !important;
}


a.text-dark:hover,
a.text-dark:focus {
    color: #1e2533 !important;
}

.text-body {
    color: #465988 !important;
}

.text-muted {
    color: #8f8fb1 !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

.dash-20 {
    font-size: 20px;
}

.dash-25 {
    font-size: 25px;
}

.dash-30 {
    font-size: 30px;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-30 {
    font-size: 30px;
}

.fs-40 {
    font-size: 40px;
}

.fs-50 {
    font-size: 50px;
}

/* ###### Typography  ###### */
/* ###### Width  ###### */
.wd-1 {
    width: 1px;
}

.wd-2 {
    width: 2px;
}

.wd-3 {
    width: 3px;
}

.wd-4 {
    width: 4px;
}

.wd-6 {
    width: 6px;
}

.wd-7 {
    width: 7px;
}

.wd-8 {
    width: 8px;
}

.wd-9 {
    width: 9px;
}

.wd-280 {
    width: 280px;
}

.wd-5 {
    width: 5px;
}

.wd-5p {
    width: 5%;
}

.mx-wd-5p {
    max-width: 5%;
}

.mn-wd-5p {
    min-width: 5%;
}

.wd-10 {
    width: 10px;
}

.wd-10p {
    width: 10%;
}

.mx-wd-10p {
    max-width: 10%;
}

.mn-wd-10p {
    min-width: 10%;
}

.wd-15 {
    width: 15px;
}

.wd-15p {
    width: 15%;
}

.mx-wd-15p {
    max-width: 15%;
}

.mn-wd-15p {
    min-width: 15%;
}

.wd-20 {
    width: 20px;
}

.wd-20p {
    width: 20%;
}

.mx-wd-20p {
    max-width: 20%;
}

.mn-wd-20p {
    min-width: 20%;
}

.wd-25 {
    width: 25px;
}

.wd-25p {
    width: 25%;
}

.mx-wd-25p {
    max-width: 25%;
}

.mn-wd-25p {
    min-width: 25%;
}

.wd-30 {
    width: 30px !important;
}

.wd-30p {
    width: 30% !important;
}

.mx-wd-30p {
    max-width: 30%;
}

.mn-wd-30p {
    min-width: 30%;
}

.wd-35 {
    width: 35px;
}

.wd-35p {
    width: 35%;
}

.mx-wd-35p {
    max-width: 35%;
}

.mn-wd-35p {
    min-width: 35%;
}

.wd-40 {
    width: 40px;
}

.wd-40p {
    width: 40%;
}

.mx-wd-40p {
    max-width: 40%;
}

.mn-wd-40p {
    min-width: 40%;
}

.wd-45 {
    width: 45px;
}

.wd-45p {
    width: 45%;
}

.mx-wd-45p {
    max-width: 45%;
}

.mn-wd-45p {
    min-width: 45%;
}

.wd-50 {
    width: 50px;
}

.wd-50p {
    width: 50%;
}

.mx-wd-50p {
    max-width: 50%;
}

.mn-wd-50p {
    min-width: 50%;
}

.wd-55 {
    width: 55px;
}

.wd-55p {
    width: 55%;
}

.mx-wd-55p {
    max-width: 55%;
}

.mn-wd-55p {
    min-width: 55%;
}

.wd-60 {
    width: 60px;
}

.wd-60p {
    width: 60%;
}

.mx-wd-60p {
    max-width: 60%;
}

.mn-wd-60p {
    min-width: 60%;
}

.wd-65 {
    width: 65px;
}

.wd-65p {
    width: 65%;
}

.mx-wd-65p {
    max-width: 65%;
}

.mn-wd-65p {
    min-width: 65%;
}

.wd-70 {
    width: 70px;
}

.wd-70p {
    width: 70%;
}

.mx-wd-70p {
    max-width: 70%;
}

.mn-wd-70p {
    min-width: 70%;
}

.wd-75 {
    width: 75px;
}

.wd-75p {
    width: 75%;
}

.mx-wd-75p {
    max-width: 75%;
}

.mn-wd-75p {
    min-width: 75%;
}

.wd-80 {
    width: 80px;
}

.wd-80p {
    width: 80%;
}

.mx-wd-80p {
    max-width: 80%;
}

.mn-wd-80p {
    min-width: 80%;
}

.wd-85 {
    width: 85px;
}

.wd-85p {
    width: 85%;
}

.mx-wd-85p {
    max-width: 85%;
}

.mn-wd-85p {
    min-width: 85%;
}

.wd-90 {
    width: 90px;
}

.wd-90p {
    width: 90%;
}

.mx-wd-90p {
    max-width: 90%;
}

.mn-wd-90p {
    min-width: 90%;
}

.wd-95 {
    width: 95px;
}

.wd-95p {
    width: 95%;
}

.mx-wd-95p {
    max-width: 95%;
}

.mn-wd-95p {
    min-width: 95%;
}

.wd-100 {
    width: 100px;
}

.wd-100p {
    width: 100%;
}

.mx-wd-100p {
    max-width: 100%;
}

.mn-wd-100p {
    min-width: 100%;
}

.wd-150 {
    width: 150px;
}

.wd-200 {
    width: 200px;
}

.wd-250 {
    width: 250px;
}

.wd-276 {
    width: 276px;
}

.wd-300 {
    width: 300px;
}

.wd-350 {
    width: 350px;
}

.wd-400 {
    width: 400px;
}

.wd-450 {
    width: 450px;
}

.wd-500 {
    width: 500px;
}

.wd-550 {
    width: 550px;
}

.wd-600 {
    width: 600px;
}

.wd-650 {
    width: 650px;
}

.wd-700 {
    width: 700px;
}

.wd-750 {
    width: 750px;
}

.wd-800 {
    width: 800px;
}

.wd-850 {
    width: 850px;
}

.wd-900 {
    width: 900px;
}

.wd-950 {
    width: 950px;
}

.wd-1000 {
    width: 1000px;
}

@media (min-width: 480px) {
    .wd-xs-5 {
        width: 5px;
    }

    .wd-xs-5p {
        width: 5%;
    }

    .mx-wd-xs-5p {
        max-width: 5%;
    }

    .mn-wd-xs-5p {
        min-width: 5%;
    }

    .wd-xs-10 {
        width: 10px;
    }

    .wd-xs-10p {
        width: 10%;
    }

    .mx-wd-xs-10p {
        max-width: 10%;
    }

    .mn-wd-xs-10p {
        min-width: 10%;
    }

    .wd-xs-15 {
        width: 15px;
    }

    .wd-xs-15p {
        width: 15%;
    }

    .mx-wd-xs-15p {
        max-width: 15%;
    }

    .mn-wd-xs-15p {
        min-width: 15%;
    }

    .wd-xs-20 {
        width: 20px;
    }

    .wd-xs-20p {
        width: 20%;
    }

    .mx-wd-xs-20p {
        max-width: 20%;
    }

    .mn-wd-xs-20p {
        min-width: 20%;
    }

    .wd-xs-25 {
        width: 25px;
    }

    .wd-xs-25p {
        width: 25%;
    }

    .mx-wd-xs-25p {
        max-width: 25%;
    }

    .mn-wd-xs-25p {
        min-width: 25%;
    }

    .wd-xs-30 {
        width: 30px;
    }

    .wd-xs-30p {
        width: 30%;
    }

    .mx-wd-xs-30p {
        max-width: 30%;
    }

    .mn-wd-xs-30p {
        min-width: 30%;
    }

    .wd-xs-35 {
        width: 35px;
    }

    .wd-xs-35p {
        width: 35%;
    }

    .mx-wd-xs-35p {
        max-width: 35%;
    }

    .mn-wd-xs-35p {
        min-width: 35%;
    }

    .wd-xs-40 {
        width: 40px;
    }

    .wd-xs-40p {
        width: 40%;
    }

    .mx-wd-xs-40p {
        max-width: 40%;
    }

    .mn-wd-xs-40p {
        min-width: 40%;
    }

    .wd-xs-45 {
        width: 45px;
    }

    .wd-xs-45p {
        width: 45%;
    }

    .mx-wd-xs-45p {
        max-width: 45%;
    }

    .mn-wd-xs-45p {
        min-width: 45%;
    }

    .wd-xs-50 {
        width: 50px;
    }

    .wd-xs-50p {
        width: 50%;
    }

    .mx-wd-xs-50p {
        max-width: 50%;
    }

    .mn-wd-xs-50p {
        min-width: 50%;
    }

    .wd-xs-55 {
        width: 55px;
    }

    .wd-xs-55p {
        width: 55%;
    }

    .mx-wd-xs-55p {
        max-width: 55%;
    }

    .mn-wd-xs-55p {
        min-width: 55%;
    }

    .wd-xs-60 {
        width: 60px;
    }

    .wd-xs-60p {
        width: 60%;
    }

    .mx-wd-xs-60p {
        max-width: 60%;
    }

    .mn-wd-xs-60p {
        min-width: 60%;
    }

    .wd-xs-65 {
        width: 65px;
    }

    .wd-xs-65p {
        width: 65%;
    }

    .mx-wd-xs-65p {
        max-width: 65%;
    }

    .mn-wd-xs-65p {
        min-width: 65%;
    }

    .wd-xs-70 {
        width: 70px;
    }

    .wd-xs-70p {
        width: 70%;
    }

    .mx-wd-xs-70p {
        max-width: 70%;
    }

    .mn-wd-xs-70p {
        min-width: 70%;
    }

    .wd-xs-75 {
        width: 75px;
    }

    .wd-xs-75p {
        width: 75%;
    }

    .mx-wd-xs-75p {
        max-width: 75%;
    }

    .mn-wd-xs-75p {
        min-width: 75%;
    }

    .wd-xs-80 {
        width: 80px;
    }

    .wd-xs-80p {
        width: 80%;
    }

    .mx-wd-xs-80p {
        max-width: 80%;
    }

    .mn-wd-xs-80p {
        min-width: 80%;
    }

    .wd-xs-85 {
        width: 85px;
    }

    .wd-xs-85p {
        width: 85%;
    }

    .mx-wd-xs-85p {
        max-width: 85%;
    }

    .mn-wd-xs-85p {
        min-width: 85%;
    }

    .wd-xs-90 {
        width: 90px;
    }

    .wd-xs-90p {
        width: 90%;
    }

    .mx-wd-xs-90p {
        max-width: 90%;
    }

    .mn-wd-xs-90p {
        min-width: 90%;
    }

    .wd-xs-95 {
        width: 95px;
    }

    .wd-xs-95p {
        width: 95%;
    }

    .mx-wd-xs-95p {
        max-width: 95%;
    }

    .mn-wd-xs-95p {
        min-width: 95%;
    }

    .wd-xs-100 {
        width: 100px;
    }

    .wd-xs-100p {
        width: 100%;
    }

    .mx-wd-xs-100p {
        max-width: 100%;
    }

    .mn-wd-xs-100p {
        min-width: 100%;
    }

    .wd-xs-150 {
        width: 150px;
    }

    .wd-xs-150p {
        width: 150%;
    }

    .mx-wd-xs-150p {
        max-width: 150%;
    }

    .mn-wd-xs-150p {
        min-width: 150%;
    }

    .wd-xs-200 {
        width: 200px;
    }

    .wd-xs-200p {
        width: 200%;
    }

    .mx-wd-xs-200p {
        max-width: 200%;
    }

    .mn-wd-xs-200p {
        min-width: 200%;
    }

    .wd-xs-250 {
        width: 250px;
    }

    .wd-xs-250p {
        width: 250%;
    }

    .mx-wd-xs-250p {
        max-width: 250%;
    }

    .mn-wd-xs-250p {
        min-width: 250%;
    }

    .wd-xs-300 {
        width: 300px;
    }

    .wd-xs-300p {
        width: 300%;
    }

    .mx-wd-xs-300p {
        max-width: 300%;
    }

    .mn-wd-xs-300p {
        min-width: 300%;
    }

    .wd-xs-350 {
        width: 350px;
    }

    .wd-xs-350p {
        width: 350%;
    }

    .mx-wd-xs-350p {
        max-width: 350%;
    }

    .mn-wd-xs-350p {
        min-width: 350%;
    }

    .wd-xs-400 {
        width: 400px;
    }

    .wd-xs-400p {
        width: 400%;
    }

    .mx-wd-xs-400p {
        max-width: 400%;
    }

    .mn-wd-xs-400p {
        min-width: 400%;
    }

    .wd-xs-450 {
        width: 450px;
    }

    .wd-xs-450p {
        width: 450%;
    }

    .mx-wd-xs-450p {
        max-width: 450%;
    }

    .mn-wd-xs-450p {
        min-width: 450%;
    }

    .wd-xs-500 {
        width: 500px;
    }

    .wd-xs-500p {
        width: 500%;
    }

    .mx-wd-xs-500p {
        max-width: 500%;
    }

    .mn-wd-xs-500p {
        min-width: 500%;
    }

    .wd-xs-550 {
        width: 550px;
    }

    .wd-xs-550p {
        width: 550%;
    }

    .mx-wd-xs-550p {
        max-width: 550%;
    }

    .mn-wd-xs-550p {
        min-width: 550%;
    }

    .wd-xs-600 {
        width: 600px;
    }

    .wd-xs-600p {
        width: 600%;
    }

    .mx-wd-xs-600p {
        max-width: 600%;
    }

    .mn-wd-xs-600p {
        min-width: 600%;
    }

    .wd-xs-650 {
        width: 650px;
    }

    .wd-xs-650p {
        width: 650%;
    }

    .mx-wd-xs-650p {
        max-width: 650%;
    }

    .mn-wd-xs-650p {
        min-width: 650%;
    }

    .wd-xs-700 {
        width: 700px;
    }

    .wd-xs-700p {
        width: 700%;
    }

    .mx-wd-xs-700p {
        max-width: 700%;
    }

    .mn-wd-xs-700p {
        min-width: 700%;
    }

    .wd-xs-750 {
        width: 750px;
    }

    .wd-xs-750p {
        width: 750%;
    }

    .mx-wd-xs-750p {
        max-width: 750%;
    }

    .mn-wd-xs-750p {
        min-width: 750%;
    }

    .wd-xs-800 {
        width: 800px;
    }

    .wd-xs-800p {
        width: 800%;
    }

    .mx-wd-xs-800p {
        max-width: 800%;
    }

    .mn-wd-xs-800p {
        min-width: 800%;
    }

    .wd-xs-850 {
        width: 850px;
    }

    .wd-xs-850p {
        width: 850%;
    }

    .mx-wd-xs-850p {
        max-width: 850%;
    }

    .mn-wd-xs-850p {
        min-width: 850%;
    }

    .wd-xs-900 {
        width: 900px;
    }

    .wd-xs-900p {
        width: 900%;
    }

    .mx-wd-xs-900p {
        max-width: 900%;
    }

    .mn-wd-xs-900p {
        min-width: 900%;
    }

    .wd-xs-950 {
        width: 950px;
    }

    .wd-xs-950p {
        width: 950%;
    }

    .mx-wd-xs-950p {
        max-width: 950%;
    }

    .mn-wd-xs-950p {
        min-width: 950%;
    }

    .wd-xs-1000 {
        width: 1000px;
    }

    .wd-xs-1000p {
        width: 1000%;
    }

    .mx-wd-xs-1000p {
        max-width: 1000%;
    }

    .mn-wd-xs-1000p {
        min-width: 1000%;
    }

    .wd-xs-auto {
        width: auto;
    }
}

@media (min-width: 576px) {
    .wd-sm-5 {
        width: 5px;
    }

    .wd-sm-5p {
        width: 5%;
    }

    .mx-wd-sm-5p {
        max-width: 5%;
    }

    .mn-wd-sm-5p {
        min-width: 5%;
    }

    .wd-sm-10 {
        width: 10px;
    }

    .wd-sm-10p {
        width: 10%;
    }

    .mx-wd-sm-10p {
        max-width: 10%;
    }

    .mn-wd-sm-10p {
        min-width: 10%;
    }

    .wd-sm-15 {
        width: 15px;
    }

    .wd-sm-15p {
        width: 15%;
    }

    .mx-wd-sm-15p {
        max-width: 15%;
    }

    .mn-wd-sm-15p {
        min-width: 15%;
    }

    .wd-sm-20 {
        width: 20px;
    }

    .wd-sm-20p {
        width: 20%;
    }

    .mx-wd-sm-20p {
        max-width: 20%;
    }

    .mn-wd-sm-20p {
        min-width: 20%;
    }

    .wd-sm-25 {
        width: 25px;
    }

    .wd-sm-25p {
        width: 25%;
    }

    .mx-wd-sm-25p {
        max-width: 25%;
    }

    .mn-wd-sm-25p {
        min-width: 25%;
    }

    .wd-sm-30 {
        width: 30px;
    }

    .wd-sm-30p {
        width: 30%;
    }

    .mx-wd-sm-30p {
        max-width: 30%;
    }

    .mn-wd-sm-30p {
        min-width: 30%;
    }

    .wd-sm-35 {
        width: 35px;
    }

    .wd-sm-35p {
        width: 35%;
    }

    .mx-wd-sm-35p {
        max-width: 35%;
    }

    .mn-wd-sm-35p {
        min-width: 35%;
    }

    .wd-sm-40 {
        width: 40px;
    }

    .wd-sm-40p {
        width: 40%;
    }

    .mx-wd-sm-40p {
        max-width: 40%;
    }

    .mn-wd-sm-40p {
        min-width: 40%;
    }

    .wd-sm-45 {
        width: 45px;
    }

    .wd-sm-45p {
        width: 45%;
    }

    .mx-wd-sm-45p {
        max-width: 45%;
    }

    .mn-wd-sm-45p {
        min-width: 45%;
    }

    .wd-sm-50 {
        width: 50px;
    }

    .wd-sm-50p {
        width: 50%;
    }

    .mx-wd-sm-50p {
        max-width: 50%;
    }

    .mn-wd-sm-50p {
        min-width: 50%;
    }

    .wd-sm-55 {
        width: 55px;
    }

    .wd-sm-55p {
        width: 55%;
    }

    .mx-wd-sm-55p {
        max-width: 55%;
    }

    .mn-wd-sm-55p {
        min-width: 55%;
    }

    .wd-sm-60 {
        width: 60px;
    }

    .wd-sm-60p {
        width: 60%;
    }

    .mx-wd-sm-60p {
        max-width: 60%;
    }

    .mn-wd-sm-60p {
        min-width: 60%;
    }

    .wd-sm-65 {
        width: 65px;
    }

    .wd-sm-65p {
        width: 65%;
    }

    .mx-wd-sm-65p {
        max-width: 65%;
    }

    .mn-wd-sm-65p {
        min-width: 65%;
    }

    .wd-sm-70 {
        width: 70px;
    }

    .wd-sm-70p {
        width: 70%;
    }

    .mx-wd-sm-70p {
        max-width: 70%;
    }

    .mn-wd-sm-70p {
        min-width: 70%;
    }

    .wd-sm-75 {
        width: 75px;
    }

    .wd-sm-75p {
        width: 75%;
    }

    .mx-wd-sm-75p {
        max-width: 75%;
    }

    .mn-wd-sm-75p {
        min-width: 75%;
    }

    .wd-sm-80 {
        width: 80px;
    }

    .wd-sm-80p {
        width: 80%;
    }

    .mx-wd-sm-80p {
        max-width: 80%;
    }

    .mn-wd-sm-80p {
        min-width: 80%;
    }

    .wd-sm-85 {
        width: 85px;
    }

    .wd-sm-85p {
        width: 85%;
    }

    .mx-wd-sm-85p {
        max-width: 85%;
    }

    .mn-wd-sm-85p {
        min-width: 85%;
    }

    .wd-sm-90 {
        width: 90px;
    }

    .wd-sm-90p {
        width: 90%;
    }

    .mx-wd-sm-90p {
        max-width: 90%;
    }

    .mn-wd-sm-90p {
        min-width: 90%;
    }

    .wd-sm-95 {
        width: 95px;
    }

    .wd-sm-95p {
        width: 95%;
    }

    .mx-wd-sm-95p {
        max-width: 95%;
    }

    .mn-wd-sm-95p {
        min-width: 95%;
    }

    .wd-sm-100 {
        width: 100px;
    }

    .wd-sm-100p {
        width: 100%;
    }

    .mx-wd-sm-100p {
        max-width: 100%;
    }

    .mn-wd-sm-100p {
        min-width: 100%;
    }

    .wd-sm-150 {
        width: 150px;
    }

    .wd-sm-150p {
        width: 150%;
    }

    .mx-wd-sm-150p {
        max-width: 150%;
    }

    .mn-wd-sm-150p {
        min-width: 150%;
    }

    .wd-sm-200 {
        width: 200px;
    }

    .wd-sm-200p {
        width: 200%;
    }

    .mx-wd-sm-200p {
        max-width: 200%;
    }

    .mn-wd-sm-200p {
        min-width: 200%;
    }

    .wd-sm-250 {
        width: 250px;
    }

    .wd-sm-250p {
        width: 250%;
    }

    .mx-wd-sm-250p {
        max-width: 250%;
    }

    .mn-wd-sm-250p {
        min-width: 250%;
    }

    .wd-sm-300 {
        width: 300px;
    }

    .wd-sm-300p {
        width: 300%;
    }

    .mx-wd-sm-300p {
        max-width: 300%;
    }

    .mn-wd-sm-300p {
        min-width: 300%;
    }

    .wd-sm-350 {
        width: 350px;
    }

    .wd-sm-350p {
        width: 350%;
    }

    .mx-wd-sm-350p {
        max-width: 350%;
    }

    .mn-wd-sm-350p {
        min-width: 350%;
    }

    .wd-sm-400 {
        width: 400px;
    }

    .wd-sm-400p {
        width: 400%;
    }

    .mx-wd-sm-400p {
        max-width: 400%;
    }

    .mn-wd-sm-400p {
        min-width: 400%;
    }

    .wd-sm-450 {
        width: 450px;
    }

    .wd-sm-450p {
        width: 450%;
    }

    .mx-wd-sm-450p {
        max-width: 450%;
    }

    .mn-wd-sm-450p {
        min-width: 450%;
    }

    .wd-sm-500 {
        width: 500px;
    }

    .wd-sm-500p {
        width: 500%;
    }

    .mx-wd-sm-500p {
        max-width: 500%;
    }

    .mn-wd-sm-500p {
        min-width: 500%;
    }

    .wd-sm-550 {
        width: 550px;
    }

    .wd-sm-550p {
        width: 550%;
    }

    .mx-wd-sm-550p {
        max-width: 550%;
    }

    .mn-wd-sm-550p {
        min-width: 550%;
    }

    .wd-sm-600 {
        width: 600px;
    }

    .wd-sm-600p {
        width: 600%;
    }

    .mx-wd-sm-600p {
        max-width: 600%;
    }

    .mn-wd-sm-600p {
        min-width: 600%;
    }

    .wd-sm-650 {
        width: 650px;
    }

    .wd-sm-650p {
        width: 650%;
    }

    .mx-wd-sm-650p {
        max-width: 650%;
    }

    .mn-wd-sm-650p {
        min-width: 650%;
    }

    .wd-sm-700 {
        width: 700px;
    }

    .wd-sm-700p {
        width: 700%;
    }

    .mx-wd-sm-700p {
        max-width: 700%;
    }

    .mn-wd-sm-700p {
        min-width: 700%;
    }

    .wd-sm-750 {
        width: 750px;
    }

    .wd-sm-750p {
        width: 750%;
    }

    .mx-wd-sm-750p {
        max-width: 750%;
    }

    .mn-wd-sm-750p {
        min-width: 750%;
    }

    .wd-sm-800 {
        width: 800px;
    }

    .wd-sm-800p {
        width: 800%;
    }

    .mx-wd-sm-800p {
        max-width: 800%;
    }

    .mn-wd-sm-800p {
        min-width: 800%;
    }

    .wd-sm-850 {
        width: 850px;
    }

    .wd-sm-850p {
        width: 850%;
    }

    .mx-wd-sm-850p {
        max-width: 850%;
    }

    .mn-wd-sm-850p {
        min-width: 850%;
    }

    .wd-sm-900 {
        width: 900px;
    }

    .wd-sm-900p {
        width: 900%;
    }

    .mx-wd-sm-900p {
        max-width: 900%;
    }

    .mn-wd-sm-900p {
        min-width: 900%;
    }

    .wd-sm-950 {
        width: 950px;
    }

    .wd-sm-950p {
        width: 950%;
    }

    .mx-wd-sm-950p {
        max-width: 950%;
    }

    .mn-wd-sm-950p {
        min-width: 950%;
    }

    .wd-sm-1000 {
        width: 1000px;
    }

    .wd-sm-1000p {
        width: 1000%;
    }

    .mx-wd-sm-1000p {
        max-width: 1000%;
    }

    .mn-wd-sm-1000p {
        min-width: 1000%;
    }

    .wd-sm-auto {
        width: auto;
    }
}

@media (min-width: 768px) {
    .wd-md-5 {
        width: 5px;
    }

    .wd-md-5p {
        width: 5%;
    }

    .mx-wd-md-5p {
        max-width: 5%;
    }

    .mn-wd-md-5p {
        min-width: 5%;
    }

    .wd-md-10 {
        width: 10px;
    }

    .wd-md-10p {
        width: 10%;
    }

    .mx-wd-md-10p {
        max-width: 10%;
    }

    .mn-wd-md-10p {
        min-width: 10%;
    }

    .wd-md-15 {
        width: 15px;
    }

    .wd-md-15p {
        width: 15%;
    }

    .mx-wd-md-15p {
        max-width: 15%;
    }

    .mn-wd-md-15p {
        min-width: 15%;
    }

    .wd-md-20 {
        width: 20px;
    }

    .wd-md-20p {
        width: 20%;
    }

    .mx-wd-md-20p {
        max-width: 20%;
    }

    .mn-wd-md-20p {
        min-width: 20%;
    }

    .wd-md-25 {
        width: 25px;
    }

    .wd-md-25p {
        width: 25%;
    }

    .mx-wd-md-25p {
        max-width: 25%;
    }

    .mn-wd-md-25p {
        min-width: 25%;
    }

    .wd-md-30 {
        width: 30px;
    }

    .wd-md-30p {
        width: 30%;
    }

    .mx-wd-md-30p {
        max-width: 30%;
    }

    .mn-wd-md-30p {
        min-width: 30%;
    }

    .wd-md-35 {
        width: 35px;
    }

    .wd-md-35p {
        width: 35%;
    }

    .mx-wd-md-35p {
        max-width: 35%;
    }

    .mn-wd-md-35p {
        min-width: 35%;
    }

    .wd-md-40 {
        width: 40px;
    }

    .wd-md-40p {
        width: 40%;
    }

    .mx-wd-md-40p {
        max-width: 40%;
    }

    .mn-wd-md-40p {
        min-width: 40%;
    }

    .wd-md-45 {
        width: 45px;
    }

    .wd-md-45p {
        width: 45%;
    }

    .mx-wd-md-45p {
        max-width: 45%;
    }

    .mn-wd-md-45p {
        min-width: 45%;
    }

    .wd-md-50 {
        width: 50px;
    }

    .wd-md-50p {
        width: 50%;
    }

    .mx-wd-md-50p {
        max-width: 50%;
    }

    .mn-wd-md-50p {
        min-width: 50%;
    }

    .wd-md-55 {
        width: 55px;
    }

    .wd-md-55p {
        width: 55%;
    }

    .mx-wd-md-55p {
        max-width: 55%;
    }

    .mn-wd-md-55p {
        min-width: 55%;
    }

    .wd-md-60 {
        width: 60px;
    }

    .wd-md-60p {
        width: 60%;
    }

    .mx-wd-md-60p {
        max-width: 60%;
    }

    .mn-wd-md-60p {
        min-width: 60%;
    }

    .wd-md-65 {
        width: 65px;
    }

    .wd-md-65p {
        width: 65%;
    }

    .mx-wd-md-65p {
        max-width: 65%;
    }

    .mn-wd-md-65p {
        min-width: 65%;
    }

    .wd-md-70 {
        width: 70px;
    }

    .wd-md-70p {
        width: 70%;
    }

    .mx-wd-md-70p {
        max-width: 70%;
    }

    .mn-wd-md-70p {
        min-width: 70%;
    }

    .wd-md-75 {
        width: 75px;
    }

    .wd-md-75p {
        width: 75%;
    }

    .mx-wd-md-75p {
        max-width: 75%;
    }

    .mn-wd-md-75p {
        min-width: 75%;
    }

    .wd-md-80 {
        width: 80px;
    }

    .wd-md-80p {
        width: 80%;
    }

    .mx-wd-md-80p {
        max-width: 80%;
    }

    .mn-wd-md-80p {
        min-width: 80%;
    }

    .wd-md-85 {
        width: 85px;
    }

    .wd-md-85p {
        width: 85%;
    }

    .mx-wd-md-85p {
        max-width: 85%;
    }

    .mn-wd-md-85p {
        min-width: 85%;
    }

    .wd-md-90 {
        width: 90px;
    }

    .wd-md-90p {
        width: 90%;
    }

    .mx-wd-md-90p {
        max-width: 90%;
    }

    .mn-wd-md-90p {
        min-width: 90%;
    }

    .wd-md-95 {
        width: 95px;
    }

    .wd-md-95p {
        width: 95%;
    }

    .mx-wd-md-95p {
        max-width: 95%;
    }

    .mn-wd-md-95p {
        min-width: 95%;
    }

    .wd-md-100 {
        width: 100px;
    }

    .wd-md-100p {
        width: 100%;
    }

    .mx-wd-md-100p {
        max-width: 100%;
    }

    .mn-wd-md-100p {
        min-width: 100%;
    }

    .wd-md-150 {
        width: 150px;
    }

    .wd-md-150p {
        width: 150%;
    }

    .mx-wd-md-150p {
        max-width: 150%;
    }

    .mn-wd-md-150p {
        min-width: 150%;
    }

    .wd-md-200 {
        width: 200px;
    }

    .wd-md-200p {
        width: 200%;
    }

    .mx-wd-md-200p {
        max-width: 200%;
    }

    .mn-wd-md-200p {
        min-width: 200%;
    }

    .wd-md-250 {
        width: 250px;
    }

    .wd-md-250p {
        width: 250%;
    }

    .mx-wd-md-250p {
        max-width: 250%;
    }

    .mn-wd-md-250p {
        min-width: 250%;
    }

    .wd-md-300 {
        width: 300px;
    }

    .wd-md-300p {
        width: 300%;
    }

    .mx-wd-md-300p {
        max-width: 300%;
    }

    .mn-wd-md-300p {
        min-width: 300%;
    }

    .wd-md-350 {
        width: 350px;
    }

    .wd-md-350p {
        width: 350%;
    }

    .mx-wd-md-350p {
        max-width: 350%;
    }

    .mn-wd-md-350p {
        min-width: 350%;
    }

    .wd-md-400 {
        width: 400px;
    }

    .wd-md-400p {
        width: 400%;
    }

    .mx-wd-md-400p {
        max-width: 400%;
    }

    .mn-wd-md-400p {
        min-width: 400%;
    }

    .wd-md-450 {
        width: 450px;
    }

    .wd-md-450p {
        width: 450%;
    }

    .mx-wd-md-450p {
        max-width: 450%;
    }

    .mn-wd-md-450p {
        min-width: 450%;
    }

    .wd-md-500 {
        width: 500px;
    }

    .wd-md-500p {
        width: 500%;
    }

    .mx-wd-md-500p {
        max-width: 500%;
    }

    .mn-wd-md-500p {
        min-width: 500%;
    }

    .wd-md-550 {
        width: 550px;
    }

    .wd-md-550p {
        width: 550%;
    }

    .mx-wd-md-550p {
        max-width: 550%;
    }

    .mn-wd-md-550p {
        min-width: 550%;
    }

    .wd-md-600 {
        width: 600px;
    }

    .wd-md-600p {
        width: 600%;
    }

    .mx-wd-md-600p {
        max-width: 600%;
    }

    .mn-wd-md-600p {
        min-width: 600%;
    }

    .wd-md-650 {
        width: 650px;
    }

    .wd-md-650p {
        width: 650%;
    }

    .mx-wd-md-650p {
        max-width: 650%;
    }

    .mn-wd-md-650p {
        min-width: 650%;
    }

    .wd-md-700 {
        width: 700px;
    }

    .wd-md-700p {
        width: 700%;
    }

    .mx-wd-md-700p {
        max-width: 700%;
    }

    .mn-wd-md-700p {
        min-width: 700%;
    }

    .wd-md-750 {
        width: 750px;
    }

    .wd-md-750p {
        width: 750%;
    }

    .mx-wd-md-750p {
        max-width: 750%;
    }

    .mn-wd-md-750p {
        min-width: 750%;
    }

    .wd-md-800 {
        width: 800px;
    }

    .wd-md-800p {
        width: 800%;
    }

    .mx-wd-md-800p {
        max-width: 800%;
    }

    .mn-wd-md-800p {
        min-width: 800%;
    }

    .wd-md-850 {
        width: 850px;
    }

    .wd-md-850p {
        width: 850%;
    }

    .mx-wd-md-850p {
        max-width: 850%;
    }

    .mn-wd-md-850p {
        min-width: 850%;
    }

    .wd-md-900 {
        width: 900px;
    }

    .wd-md-900p {
        width: 900%;
    }

    .mx-wd-md-900p {
        max-width: 900%;
    }

    .mn-wd-md-900p {
        min-width: 900%;
    }

    .wd-md-950 {
        width: 950px;
    }

    .wd-md-950p {
        width: 950%;
    }

    .mx-wd-md-950p {
        max-width: 950%;
    }

    .mn-wd-md-950p {
        min-width: 950%;
    }

    .wd-md-1000 {
        width: 1000px;
    }

    .wd-md-1000p {
        width: 1000%;
    }

    .mx-wd-md-1000p {
        max-width: 1000%;
    }

    .mn-wd-md-1000p {
        min-width: 1000%;
    }

    .wd-md-auto {
        width: auto;
    }

    .wd-md-120 {
        width: 120px;
    }
}

@media (min-width: 992px) {
    .wd-lg-5 {
        width: 5px;
    }

    .wd-lg-5p {
        width: 5%;
    }

    .mx-wd-lg-5p {
        max-width: 5%;
    }

    .mn-wd-lg-5p {
        min-width: 5%;
    }

    .wd-lg-10 {
        width: 10px;
    }

    .wd-lg-10p {
        width: 10%;
    }

    .mx-wd-lg-10p {
        max-width: 10%;
    }

    .mn-wd-lg-10p {
        min-width: 10%;
    }

    .wd-lg-15 {
        width: 15px;
    }

    .wd-lg-15p {
        width: 15%;
    }

    .mx-wd-lg-15p {
        max-width: 15%;
    }

    .mn-wd-lg-15p {
        min-width: 15%;
    }

    .wd-lg-20 {
        width: 20px;
    }

    .wd-lg-20p {
        width: 20%;
    }

    .mx-wd-lg-20p {
        max-width: 20%;
    }

    .mn-wd-lg-20p {
        min-width: 20%;
    }

    .wd-lg-25 {
        width: 25px;
    }

    .wd-lg-25p {
        width: 25%;
    }

    .mx-wd-lg-25p {
        max-width: 25%;
    }

    .mn-wd-lg-25p {
        min-width: 25%;
    }

    .wd-lg-30 {
        width: 30px;
    }

    .wd-lg-30p {
        width: 30%;
    }

    .mx-wd-lg-30p {
        max-width: 30%;
    }

    .mn-wd-lg-30p {
        min-width: 30%;
    }

    .wd-lg-35 {
        width: 35px;
    }

    .wd-lg-35p {
        width: 35%;
    }

    .mx-wd-lg-35p {
        max-width: 35%;
    }

    .mn-wd-lg-35p {
        min-width: 35%;
    }

    .wd-lg-40 {
        width: 40px;
    }

    .wd-lg-40p {
        width: 40%;
    }

    .mx-wd-lg-40p {
        max-width: 40%;
    }

    .mn-wd-lg-40p {
        min-width: 40%;
    }

    .wd-lg-45 {
        width: 45px;
    }

    .wd-lg-45p {
        width: 45%;
    }

    .mx-wd-lg-45p {
        max-width: 45%;
    }

    .mn-wd-lg-45p {
        min-width: 45%;
    }

    .wd-lg-50 {
        width: 50px;
    }

    .wd-lg-50p {
        width: 50%;
    }

    .mx-wd-lg-50p {
        max-width: 50%;
    }

    .mn-wd-lg-50p {
        min-width: 50%;
    }

    .wd-lg-55 {
        width: 55px;
    }

    .wd-lg-55p {
        width: 55%;
    }

    .mx-wd-lg-55p {
        max-width: 55%;
    }

    .mn-wd-lg-55p {
        min-width: 55%;
    }

    .wd-lg-60 {
        width: 60px;
    }

    .wd-lg-60p {
        width: 60%;
    }

    .mx-wd-lg-60p {
        max-width: 60%;
    }

    .mn-wd-lg-60p {
        min-width: 60%;
    }

    .wd-lg-65 {
        width: 65px;
    }

    .wd-lg-65p {
        width: 65%;
    }

    .mx-wd-lg-65p {
        max-width: 65%;
    }

    .mn-wd-lg-65p {
        min-width: 65%;
    }

    .wd-lg-70 {
        width: 70px;
    }

    .wd-lg-70p {
        width: 70%;
    }

    .mx-wd-lg-70p {
        max-width: 70%;
    }

    .mn-wd-lg-70p {
        min-width: 70%;
    }

    .wd-lg-75 {
        width: 75px;
    }

    .wd-lg-75p {
        width: 75%;
    }

    .mx-wd-lg-75p {
        max-width: 75%;
    }

    .mn-wd-lg-75p {
        min-width: 75%;
    }

    .wd-lg-80 {
        width: 80px;
    }

    .wd-lg-80p {
        width: 80%;
    }

    .mx-wd-lg-80p {
        max-width: 80%;
    }

    .mn-wd-lg-80p {
        min-width: 80%;
    }

    .wd-lg-85 {
        width: 85px;
    }

    .wd-lg-85p {
        width: 85%;
    }

    .mx-wd-lg-85p {
        max-width: 85%;
    }

    .mn-wd-lg-85p {
        min-width: 85%;
    }

    .wd-lg-90 {
        width: 90px;
    }

    .wd-lg-90p {
        width: 90%;
    }

    .mx-wd-lg-90p {
        max-width: 90%;
    }

    .mn-wd-lg-90p {
        min-width: 90%;
    }

    .wd-lg-95 {
        width: 95px;
    }

    .wd-lg-95p {
        width: 95%;
    }

    .mx-wd-lg-95p {
        max-width: 95%;
    }

    .mn-wd-lg-95p {
        min-width: 95%;
    }

    .wd-lg-100 {
        width: 100px;
    }

    .wd-lg-100p {
        width: 100%;
    }

    .mx-wd-lg-100p {
        max-width: 100%;
    }

    .mn-wd-lg-100p {
        min-width: 100%;
    }

    .wd-lg-150 {
        width: 150px;
    }

    .wd-lg-150p {
        width: 150%;
    }

    .mx-wd-lg-150p {
        max-width: 150%;
    }

    .mn-wd-lg-150p {
        min-width: 150%;
    }

    .wd-lg-200 {
        width: 200px;
    }

    .wd-lg-200p {
        width: 200%;
    }

    .mx-wd-lg-200p {
        max-width: 200%;
    }

    .mn-wd-lg-200p {
        min-width: 200%;
    }

    .wd-lg-250 {
        width: 250px;
    }

    .wd-lg-250p {
        width: 250%;
    }

    .mx-wd-lg-250p {
        max-width: 250%;
    }

    .mn-wd-lg-250p {
        min-width: 250%;
    }

    .wd-lg-280 {
        width: 280px;
    }

    .wd-lg-300 {
        width: 300px;
    }

    .wd-lg-300p {
        width: 300%;
    }

    .mx-wd-lg-300p {
        max-width: 300%;
    }

    .mn-wd-lg-300p {
        min-width: 300%;
    }

    .wd-lg-350 {
        width: 350px;
    }

    .wd-lg-350p {
        width: 350%;
    }

    .mx-wd-lg-350p {
        max-width: 350%;
    }

    .mn-wd-lg-350p {
        min-width: 350%;
    }

    .wd-lg-400 {
        width: 400px;
    }

    .wd-lg-400p {
        width: 400%;
    }

    .mx-wd-lg-400p {
        max-width: 400%;
    }

    .mn-wd-lg-400p {
        min-width: 400%;
    }

    .wd-lg-450 {
        width: 450px;
    }

    .wd-lg-450p {
        width: 450%;
    }

    .mx-wd-lg-450p {
        max-width: 450%;
    }

    .mn-wd-lg-450p {
        min-width: 450%;
    }

    .wd-lg-500 {
        width: 500px;
    }

    .wd-lg-500p {
        width: 500%;
    }

    .mx-wd-lg-500p {
        max-width: 500%;
    }

    .mn-wd-lg-500p {
        min-width: 500%;
    }

    .wd-lg-550 {
        width: 550px;
    }

    .wd-lg-550p {
        width: 550%;
    }

    .mx-wd-lg-550p {
        max-width: 550%;
    }

    .mn-wd-lg-550p {
        min-width: 550%;
    }

    .wd-lg-600 {
        width: 600px;
    }

    .wd-lg-600p {
        width: 600%;
    }

    .mx-wd-lg-600p {
        max-width: 600%;
    }

    .mn-wd-lg-600p {
        min-width: 600%;
    }

    .wd-lg-650 {
        width: 650px;
    }

    .wd-lg-650p {
        width: 650%;
    }

    .mx-wd-lg-650p {
        max-width: 650%;
    }

    .mn-wd-lg-650p {
        min-width: 650%;
    }

    .wd-lg-700 {
        width: 700px;
    }

    .wd-lg-700p {
        width: 700%;
    }

    .mx-wd-lg-700p {
        max-width: 700%;
    }

    .mn-wd-lg-700p {
        min-width: 700%;
    }

    .wd-lg-750 {
        width: 750px;
    }

    .wd-lg-750p {
        width: 750%;
    }

    .mx-wd-lg-750p {
        max-width: 750%;
    }

    .mn-wd-lg-750p {
        min-width: 750%;
    }

    .wd-lg-800 {
        width: 800px;
    }

    .wd-lg-800p {
        width: 800%;
    }

    .mx-wd-lg-800p {
        max-width: 800%;
    }

    .mn-wd-lg-800p {
        min-width: 800%;
    }

    .wd-lg-850 {
        width: 850px;
    }

    .wd-lg-850p {
        width: 850%;
    }

    .mx-wd-lg-850p {
        max-width: 850%;
    }

    .mn-wd-lg-850p {
        min-width: 850%;
    }

    .wd-lg-900 {
        width: 900px;
    }

    .wd-lg-900p {
        width: 900%;
    }

    .mx-wd-lg-900p {
        max-width: 900%;
    }

    .mn-wd-lg-900p {
        min-width: 900%;
    }

    .wd-lg-950 {
        width: 950px;
    }

    .wd-lg-950p {
        width: 950%;
    }

    .mx-wd-lg-950p {
        max-width: 950%;
    }

    .mn-wd-lg-950p {
        min-width: 950%;
    }

    .wd-lg-1000 {
        width: 1000px;
    }

    .wd-lg-1000p {
        width: 1000%;
    }

    .mx-wd-lg-1000p {
        max-width: 1000%;
    }

    .mn-wd-lg-1000p {
        min-width: 1000%;
    }

    .wd-lg-auto {
        width: auto;
    }
}

@media (min-width: 1200px) {
    .wd-xl-5 {
        width: 5px;
    }

    .wd-xl-5p {
        width: 5%;
    }

    .mx-wd-xl-5p {
        max-width: 5%;
    }

    .mn-wd-xl-5p {
        min-width: 5%;
    }

    .wd-xl-10 {
        width: 10px;
    }

    .wd-xl-10p {
        width: 10%;
    }

    .mx-wd-xl-10p {
        max-width: 10%;
    }

    .mn-wd-xl-10p {
        min-width: 10%;
    }

    .wd-xl-15 {
        width: 15px;
    }

    .wd-xl-15p {
        width: 15%;
    }

    .mx-wd-xl-15p {
        max-width: 15%;
    }

    .mn-wd-xl-15p {
        min-width: 15%;
    }

    .wd-xl-20 {
        width: 20px;
    }

    .wd-xl-20p {
        width: 20%;
    }

    .mx-wd-xl-20p {
        max-width: 20%;
    }

    .mn-wd-xl-20p {
        min-width: 20%;
    }

    .wd-xl-25 {
        width: 25px;
    }

    .wd-xl-25p {
        width: 25%;
    }

    .mx-wd-xl-25p {
        max-width: 25%;
    }

    .mn-wd-xl-25p {
        min-width: 25%;
    }

    .wd-xl-30 {
        width: 30px;
    }

    .wd-xl-30p {
        width: 30%;
    }

    .mx-wd-xl-30p {
        max-width: 30%;
    }

    .mn-wd-xl-30p {
        min-width: 30%;
    }

    .wd-xl-35 {
        width: 35px;
    }

    .wd-xl-35p {
        width: 35%;
    }

    .mx-wd-xl-35p {
        max-width: 35%;
    }

    .mn-wd-xl-35p {
        min-width: 35%;
    }

    .wd-xl-40 {
        width: 40px;
    }

    .wd-xl-40p {
        width: 40%;
    }

    .mx-wd-xl-40p {
        max-width: 40%;
    }

    .mn-wd-xl-40p {
        min-width: 40%;
    }

    .wd-xl-45 {
        width: 45px;
    }

    .wd-xl-45p {
        width: 45%;
    }

    .mx-wd-xl-45p {
        max-width: 45%;
    }

    .mn-wd-xl-45p {
        min-width: 45%;
    }

    .wd-xl-50 {
        width: 50px;
    }

    .wd-xl-50p {
        width: 50%;
    }

    .mx-wd-xl-50p {
        max-width: 50%;
    }

    .mn-wd-xl-50p {
        min-width: 50%;
    }

    .wd-xl-55 {
        width: 55px;
    }

    .wd-xl-55p {
        width: 55%;
    }

    .mx-wd-xl-55p {
        max-width: 55%;
    }

    .mn-wd-xl-55p {
        min-width: 55%;
    }

    .wd-xl-60 {
        width: 60px;
    }

    .wd-xl-60p {
        width: 60%;
    }

    .mx-wd-xl-60p {
        max-width: 60%;
    }

    .mn-wd-xl-60p {
        min-width: 60%;
    }

    .wd-xl-65 {
        width: 65px;
    }

    .wd-xl-65p {
        width: 65%;
    }

    .mx-wd-xl-65p {
        max-width: 65%;
    }

    .mn-wd-xl-65p {
        min-width: 65%;
    }

    .wd-xl-70 {
        width: 70px;
    }

    .wd-xl-70p {
        width: 70%;
    }

    .mx-wd-xl-70p {
        max-width: 70%;
    }

    .mn-wd-xl-70p {
        min-width: 70%;
    }

    .wd-xl-75 {
        width: 75px;
    }

    .wd-xl-75p {
        width: 75%;
    }

    .mx-wd-xl-75p {
        max-width: 75%;
    }

    .mn-wd-xl-75p {
        min-width: 75%;
    }

    .wd-xl-80 {
        width: 80px;
    }

    .wd-xl-80p {
        width: 80%;
    }

    .mx-wd-xl-80p {
        max-width: 80%;
    }

    .mn-wd-xl-80p {
        min-width: 80%;
    }

    .wd-xl-85 {
        width: 85px;
    }

    .wd-xl-85p {
        width: 85%;
    }

    .mx-wd-xl-85p {
        max-width: 85%;
    }

    .mn-wd-xl-85p {
        min-width: 85%;
    }

    .wd-xl-90 {
        width: 90px;
    }

    .wd-xl-90p {
        width: 90%;
    }

    .mx-wd-xl-90p {
        max-width: 90%;
    }

    .mn-wd-xl-90p {
        min-width: 90%;
    }

    .wd-xl-95 {
        width: 95px;
    }

    .wd-xl-95p {
        width: 95%;
    }

    .mx-wd-xl-95p {
        max-width: 95%;
    }

    .mn-wd-xl-95p {
        min-width: 95%;
    }

    .wd-xl-100 {
        width: 100px;
    }

    .wd-xl-100p {
        width: 100%;
    }

    .mx-wd-xl-100p {
        max-width: 100%;
    }

    .mn-wd-xl-100p {
        min-width: 100%;
    }

    .wd-xl-150 {
        width: 150px;
    }

    .wd-xl-150p {
        width: 150%;
    }

    .mx-wd-xl-150p {
        max-width: 150%;
    }

    .mn-wd-xl-150p {
        min-width: 150%;
    }

    .wd-xl-200 {
        width: 200px;
    }

    .wd-xl-200p {
        width: 200%;
    }

    .mx-wd-xl-200p {
        max-width: 200%;
    }

    .mn-wd-xl-200p {
        min-width: 200%;
    }

    .wd-xl-250 {
        width: 250px;
    }

    .wd-xl-250p {
        width: 250%;
    }

    .mx-wd-xl-250p {
        max-width: 250%;
    }

    .mn-wd-xl-250p {
        min-width: 250%;
    }

    .wd-xl-300 {
        width: 300px;
    }

    .wd-xl-300p {
        width: 300%;
    }

    .mx-wd-xl-300p {
        max-width: 300%;
    }

    .mn-wd-xl-300p {
        min-width: 300%;
    }

    .wd-xl-350 {
        width: 350px;
    }

    .wd-xl-350p {
        width: 350%;
    }

    .mx-wd-xl-350p {
        max-width: 350%;
    }

    .mn-wd-xl-350p {
        min-width: 350%;
    }

    .wd-xl-400 {
        width: 400px;
    }

    .wd-xl-400p {
        width: 400%;
    }

    .mx-wd-xl-400p {
        max-width: 400%;
    }

    .mn-wd-xl-400p {
        min-width: 400%;
    }

    .wd-xl-450 {
        width: 450px;
    }

    .wd-xl-450p {
        width: 450%;
    }

    .mx-wd-xl-450p {
        max-width: 450%;
    }

    .mn-wd-xl-450p {
        min-width: 450%;
    }

    .wd-xl-500 {
        width: 500px;
    }

    .wd-xl-500p {
        width: 500%;
    }

    .mx-wd-xl-500p {
        max-width: 500%;
    }

    .mn-wd-xl-500p {
        min-width: 500%;
    }

    .wd-xl-550 {
        width: 550px;
    }

    .wd-xl-550p {
        width: 550%;
    }

    .mx-wd-xl-550p {
        max-width: 550%;
    }

    .mn-wd-xl-550p {
        min-width: 550%;
    }

    .wd-xl-600 {
        width: 600px;
    }

    .wd-xl-600p {
        width: 600%;
    }

    .mx-wd-xl-600p {
        max-width: 600%;
    }

    .mn-wd-xl-600p {
        min-width: 600%;
    }

    .wd-xl-650 {
        width: 650px;
    }

    .wd-xl-650p {
        width: 650%;
    }

    .mx-wd-xl-650p {
        max-width: 650%;
    }

    .mn-wd-xl-650p {
        min-width: 650%;
    }

    .wd-xl-700 {
        width: 700px;
    }

    .wd-xl-700p {
        width: 700%;
    }

    .mx-wd-xl-700p {
        max-width: 700%;
    }

    .mn-wd-xl-700p {
        min-width: 700%;
    }

    .wd-xl-750 {
        width: 750px;
    }

    .wd-xl-750p {
        width: 750%;
    }

    .mx-wd-xl-750p {
        max-width: 750%;
    }

    .mn-wd-xl-750p {
        min-width: 750%;
    }

    .wd-xl-800 {
        width: 800px;
    }

    .wd-xl-800p {
        width: 800%;
    }

    .mx-wd-xl-800p {
        max-width: 800%;
    }

    .mn-wd-xl-800p {
        min-width: 800%;
    }

    .wd-xl-850 {
        width: 850px;
    }

    .wd-xl-850p {
        width: 850%;
    }

    .mx-wd-xl-850p {
        max-width: 850%;
    }

    .mn-wd-xl-850p {
        min-width: 850%;
    }

    .wd-xl-900 {
        width: 900px;
    }

    .wd-xl-900p {
        width: 900%;
    }

    .mx-wd-xl-900p {
        max-width: 900%;
    }

    .mn-wd-xl-900p {
        min-width: 900%;
    }

    .wd-xl-950 {
        width: 950px;
    }

    .wd-xl-950p {
        width: 950%;
    }

    .mx-wd-xl-950p {
        max-width: 950%;
    }

    .mn-wd-xl-950p {
        min-width: 950%;
    }

    .wd-xl-1000 {
        width: 1000px;
    }

    .wd-xl-1000p {
        width: 1000%;
    }

    .mx-wd-xl-1000p {
        max-width: 1000%;
    }

    .mn-wd-xl-1000p {
        min-width: 1000%;
    }

    .wd-xl-auto {
        width: auto;
        width: auto !important;
    }
}

.wd-100v {
    width: 100vw;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.vw-100 {
    width: 100vw !important;
}

/* ###### Width  ###### */
/* ############### CUSTOM ELEMENT STYLES ############### */
/* ###### Component-cols ###### */
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
}


.col2-1,
.col2-2,
.col2-3,
.col2-4,
.col2-5,
.col2-6,
.col2-7,
.col2-8,
.col2-9,
.col2-10,
.col2-11,
.col2-12,
.col2,
.col2-auto,
.col2-sm-1,
.col2-sm-2,
.col2-sm-3,
.col2-sm-4,
.col2-sm-5,
.col2-sm-6,
.col2-sm-7,
.col2-sm-8,
.col2-sm-9,
.col2-sm-10,
.col2-sm-11,
.col2-sm-12,
.col2-sm,
.col2-sm-auto,
.col2-md-1,
.col2-md-2,
.col2-md-3,
.col2-md-4,
.col2-md-5,
.col2-md-6,
.col2-md-7,
.col2-md-8,
.col2-md-9,
.col2-md-10,
.col2-md-11,
.col2-md-12,
.col2-md,
.col2-md-auto,
.col2-lg-1,
.col2-lg-2,
.col2-lg-3,
.col2-lg-4,
.col2-lg-5,
.col2-lg-6,
.col2-lg-7,
.col2-lg-8,
.col2-lg-9,
.col2-lg-10,
.col2-lg-11,
.col2-lg-12,
.col2-lg,
.col2-lg-auto,
.col2-xl-1,
.col2-xl-2,
.col2-xl-3,
.col2-xl-4,
.col2-xl-5,
.col2-xl-6,
.col2-xl-7,
.col2-xl-8,
.col2-xl-9,
.col2-xl-10,
.col2-xl-11,
.col2-xl-12,
.col2-xl,
.col2-xl-auto {
    position: relative;
    width: 100% !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
}
.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto !important;
    max-width: 100%;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        order: -1;
    }

    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    .order-sm-6 {
        order: 6;
    }

    .order-sm-7 {
        order: 7;
    }

    .order-sm-8 {
        order: 8;
    }

    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333%;
    }

    .offset-sm-2 {
        margin-left: 16.66667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333%;
    }

    .offset-sm-5 {
        margin-left: 41.66667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333%;
    }

    .offset-sm-8 {
        margin-left: 66.66667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333%;
    }

    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    .order-md-2 {
        order: 2;
    }

    .order-md-3 {
        order: 3;
    }

    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    .order-md-6 {
        order: 6;
    }

    .order-md-7 {
        order: 7;
    }

    .order-md-8 {
        order: 8;
    }

    .order-md-9 {
        order: 9;
    }

    .order-md-10 {
        order: 10;
    }

    .order-md-11 {
        order: 11;
    }

    .order-md-12 {
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333%;
    }

    .offset-md-2 {
        margin-left: 16.66667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333%;
    }

    .offset-md-5 {
        margin-left: 41.66667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333%;
    }

    .offset-md-8 {
        margin-left: 66.66667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333%;
    }

    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        order: -1;
    }

    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    .order-lg-6 {
        order: 6;
    }

    .order-lg-7 {
        order: 7;
    }

    .order-lg-8 {
        order: 8;
    }

    .order-lg-9 {
        order: 9;
    }

    .order-lg-10 {
        order: 10;
    }

    .order-lg-11 {
        order: 11;
    }

    .order-lg-12 {
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .custom-data-table {
        width: 70%;
    }
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
/*****CHANGES   HERE FOR SCREEEN TABLE NEBHIOURHOOD*****/
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;                                       
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    .order-xl-2 {
        order: 2;
    }

    .order-xl-3 {
        order: 3;
    }

    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    .order-xl-6 {
        order: 6;
    }

    .order-xl-7 {
        order: 7;
    }

    .order-xl-8 {
        order: 8;
    }

    .order-xl-9 {
        order: 9;
    }

    .order-xl-10 {
        order: 10;
    }

    .order-xl-11 {
        order: 11;
    }

    .order-xl-12 {
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1600px) {
    .col-xxl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xxl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xxl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xxl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xxl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xxl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xxl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xxl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xxl-first {
        order: -1;
    }

    .order-xxl-last {
        order: 13;
    }

    .order-xxl-0 {
        order: 0;
    }

    .order-xxl-1 {
        order: 1;
    }

    .order-xxl-2 {
        order: 2;
    }

    .order-xxl-3 {
        order: 3;
    }

    .order-xxl-4 {
        order: 4;
    }

    .order-xxl-5 {
        order: 5;
    }

    .order-xxl-6 {
        order: 6;
    }

    .order-xxl-7 {
        order: 7;
    }

    .order-xxl-8 {
        order: 8;
    }

    .order-xxl-9 {
        order: 9;
    }

    .order-xxl-10 {
        order: 10;
    }

    .order-xxl-11 {
        order: 11;
    }

    .order-xxl-12 {
        order: 12;
    }

    .offset-xxl-0 {
        margin-left: 0;
    }

    .offset-xxl-1 {
        margin-left: 8.33333%;
    }

    .offset-xxl-2 {
        margin-left: 16.66667%;
    }

    .offset-xxl-3 {
        margin-left: 25%;
    }

    .offset-xxl-4 {
        margin-left: 33.33333%;
    }

    .offset-xxl-5 {
        margin-left: 41.66667%;
    }

    .offset-xxl-6 {
        margin-left: 50%;
    }

    .offset-xxl-7 {
        margin-left: 58.33333%;
    }

    .offset-xxl-8 {
        margin-left: 66.66667%;
    }

    .offset-xxl-9 {
        margin-left: 75%;
    }

    .offset-xxl-10 {
        margin-left: 83.33333%;
    }

    .offset-xxl-11 {
        margin-left: 91.66667%;
    }
}

/* ###### Component-cols ###### */
.landing-top-header {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    border-radius: 0px;
}

.landing-top-header::before {
    position: absolute;
    content: "";
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    right: 0;
    width: auto;
    height: 152%;
    top: 0px;
    overflow: hidden;
    /* background-image: url(../landing/images/bg4.png); */
}

.landing-top-header .top {
    border-bottom: 1px solid transparent;
}

.landing-top-header .top .nav li {
    margin-left: 0;
    margin-right: 28px;
}

.landing-top-header .top .nav li a {
    color: #1d212f;
    font-size: 15px;
    text-transform: capitalize;
}

.landing-top-header .top .nav li a.active {
    color: var(--primary-bg-color);
}

@media (max-width: 992px) {
    .landing-page .nav.navbar-nav.nav-pills {
        display: block;
    }

    .landing-page .navbar-toggler {
        border: 0 !important;
    }

    .landing-page .navbar.navbar-expand-lg.navbar-light {
        background-color: #ffffff;
    }

    .landing-page .side-menu__item {
        padding-left: 25px;
    }

    .landing-page .app-sidebar.horizontalmenu {
        background-color: #ffffff !important;
    }
}

.landing-top-header .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: transparent;
    font-weight: bold;
    color: var(--primary-bg-color);
}

.logo-demo {
    padding-top: 0px;
    margin: 15px 0;
}

.spacing-top {
    padding-top: 50px;
}

.demo-screen-headline h1 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 500;
    text-align: center;
}

.demo-screen-headline h1 {
    line-height: 1.4;
    margin-bottom: 20px;
    text-shadow: none;
}

.demo-screen-headline {
    padding-top: 100px;
    padding-bottom: 70px;
}

.landing-top-header .demo-screen-headline h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
}

.demo-footer {
    background-color: var(--dark-theme);
    padding-top: 3rem;
    color: var(--dark-color);
}

.demo-footer .card {
    background-color: var(--dark-theme);
}

.demo-footer .main-footer {
    background-color: var(--dark-theme);
}

.demo-footer hr {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.demo-footer .footer-social-list a {
    color: #ffffff;
}

.demo-footer .top-footer img {
    padding: 0;
    width: auto !important;
    height: auto !important;
}

.demo-footer .card.container {
    box-shadow: none;
}

.landing-page {
    background-color: #ffffff;
}

.landing-page .bottom-footer-shape {
    bottom: -2px;
    position: absolute;
    pointer-events: none;
    right: 0;
    left: 0;
    width: 100%;
    color: var(--dark-theme) !important;
}

.landing-page .shape svg {
    -webkit-transform: scale(2);
    transform: scale(2);
    width: 100%;
    height: auto;
    -webkit-transform-origin: top center;
    transform-origin: top center;
}

.landing-page svg:not(:root) {
    overflow: hidden;
}

.landing-page .card {
    border: 0px;
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
}

.landing-page .pricing-card.advanced {
    box-shadow: 0px 16px 32px rgba(33, 33, 33, 0.15);
}

.landing-page .demo-footer .btn-list .btn-icon {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: var(--dark-color);
    background-color: rgba(255, 255, 255, 0.2);
    padding: 5px;
}

.landing-page .feature-1 {
    border: 1px solid #e9edf4;
    padding: 22px;
    border-radius: 10px;
    position: relative;
    text-align: center;
}

.landing-page .feature-1 a {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.landing-page .top-footer h6 {
    font-size: 17px;
    color: #ffffff;
}

.landing-page .top-footer h6::before {
    position: absolute;
    content: "";
    height: 8px;
    border: 2px solid var(--primary-bg-color);
    margin: 0px auto;
    transform: rotate(45deg);
    width: 8px;
    left: -3px;
    margin-bottom: 10px;
    top: 5px;
}

.landing-page .owl-controls .owl-page {
    display: inline-block;
    zoom: 1;
}

.landing-page .owl-controls .owl-page {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background: url(../landing/images/10.jpg) no-repeat; */
    background-size: cover;
    transition: all 0.3s ease-in-out 0s;
    border: 4px solid #e8e8f7;
}

.landing-page .owl-controls .owl-page:nth-child(2) {
    /* background: url(../landing/images/16.jpg) no-repeat; */
    background-size: cover;
}

.landing-page .owl-controls .owl-page:nth-child(3) {
    /* background: url(../landing/images/21.jpg) no-repeat; */
    background-size: cover;
}

.landing-page .testimonia .post {
    color: #ffffff;
}

.landing-page .owl-controls .owl-page.active {
    border-color: #fd6074;
    opacity: 1;
}

.landing-page .testimonia .title {
    color: #ffffff;
}

.landing-page .rating-stars .jq-star {
    width: 18px !important;
    height: 18px !important;
}

.landing-page .services-statistics .counter-icon {
    border-radius: 0 40% 0 40%;
}

.landing-page .footer-social-list a {
    font-size: inherit;
    background: #74829c;
    border-radius: 50px;
    padding: 8px 13px;
}

.landing-page .card {
    box-shadow: none;
}

.landing-page .featured-icon {
    height: 55px;
    width: 55px;
    border-radius: 26% 74% 73% 27%/30% 49% 51% 70%;
}

.landing-page .featured-icon:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 26% 74% 73% 27%/30% 49% 51% 70%;
    left: 0;
    background-color: #000;
    z-index: inherit;
    transform: rotate(23deg);
}

.landing-page .card.features.main-features {
    border: 1px solid #e8e8f7 !important;
    box-shadow: none !important;
    transition: all ease 0.3s !important;
    text-align: center;
}

.landing-page .owl-item {
    border-radius: 5px;
}

.landing-page .owl-item img {
    border: 1px solid #e9edf4;
    border-radius: 5px;
}

.landing-page .bg-pages .card {
    border-radius: 50px 10px;
    border: 0 !important;
}

.landing-page .bg-pages .feature-icon {
    width: 55px;
    height: 55px;
    text-align: center;
    border-radius: 50%;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    line-height: 0;
    padding: 15px;
    bottom: 0;
}

.landing-page .bg-pages .feature-icon i {
    font-size: 25px;
}

.landing-page .bg-pages .card {
    border-radius: 50px 10px;
    border: 1px solid #e8e8f7 !important;
    background-color: #f4f4f7;
}

.landing-page #faqs .card .card-body {
    padding: 3.5rem 3.5rem 1.5rem 3.5rem;
    transition: 0.3s ease all;
}

.landing-page #faqs .card {
    padding: 1rem;
    transition: 0.3s ease all;
}

.landing-page #faqs .card-header {
    background: none;
    padding: 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    background-color: transparent;
    letter-spacing: 0.2px;
    width: 100% !important;
    height: 100% !important;
    border-bottom: 0;
}

.landing-page #highlights .template-highlights .card-header {
    border-radius: 7px;
}

.landing-page #highlights .template-highlights .card-header:first-child {
    border-radius: 7px 7px 0 0 !important;
}

.landing-page #faqs .card-options-collapse {
    background-color: transparent !important;
}

.landing-page .bg-pages .card:hover {
    box-shadow: 0px 6px 18px 4px rgba(6, 10, 48, 0.08);
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
}

.landing-page .bg-landing {
    background-color: #f8f9fb;
}

.landing-page .form-landing .form-control {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}

.landing-page .form-landing .btn {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.landing-page .bg-image-landing {
    /* background-image: url(../landing/images/bg4.png); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
}

.landing-page .bg-image-landing::before {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content: "";
    left: -19%;
    right: 0;
    width: 35%;
    height: 80%;
    top: 0px;
    /* background-image: url(../landing/images/bg.png); */
}

.landing-page .landing-statistics .card-body:hover::before {
    width: 0px;
    background: #ffffff;
}

.landing-page .landing-statistics .card-body:hover::after {
    height: 0px;
    background: #ffffff;
}

.landing-page .landing-statistics .counter-icon {
    border-radius: 10px;
    border: transparent;
    background: rgba(0, 0, 0, 0.2);
    width: 4rem;
    height: 4rem;
    padding: 17px 18px;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.landing-page .landing-statistics .counter-icon i {
    color: #fff;
    font-size: 1.8rem;
}

.landing-page .text-white-80 {
    color: rgba(255, 255, 255, 0.8);
}

.landing-page .landing-title {
    background: linear-gradient(to right, transparent, var(--primary-bg-color), transparent);
    height: 1px;
    margin: 0px auto;
    width: 70px;
    margin-bottom: 10px;
}

@keyframes text {

    0%,
    90%,
    100% {
        width: 0px;
    }

    90%,
    100% {
        width: 100px;
    }
}

.landing-page .animate-heading::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    animation: cursorAnimate 0.8s steps(9) infinite;
}

.landing-page .web-images {
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-page .web-item {
    margin-left: 10px;
    align-items: center;
    padding: 30px 0px;
    justify-content: center;
    border: 1px solid #e9edf4;
    border-radius: 7px;
}

.landing-page .web-item img {
    width: 50px;
    height: 50px;
}

.landing-page .web-item h4 {
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    color: #74829c;
    font-size: 18px;
}

.landing-page #faqs .card:hover {
    box-shadow: none;
}

.landing-page #faqs .card-header {
    background: none;
    padding: 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    background-color: transparent;
    letter-spacing: 0.2px;
    width: 100% !important;
    height: 100% !important;
}

.landing-page #faqs .card-title {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.landing-page #faqs .card-options a:not(.btn) {
    margin-left: 0.5rem;
    color: #76839a;
    display: inline-block;
    min-width: 1rem;
}

.landing-page #faqs .card-collapsed> :not(.card-header):not(.card-status) {
    display: none;
}

.landing-page #faqs .card-header a:hover {
    color: #000;
}

.landing-page #faqs .card .card-body {
    padding: 3.5rem 3.5rem 1.5rem 3.5rem;
    transition: 0.3s ease all;
}

.landing-page #faqs .card .card-header {
    transition: 0.3s ease all;
}

.landing-page #faqs i {
    transform: rotate(180deg);
    transition: all 0.3s;
    color: white;
    border-radius: 50px;
}

.landing-page #faqs .card {
    padding: 1rem;
    transition: 0.3s ease all;
}

.landing-page #faqs.demo-screen-demo #grid p {
    margin-top: 0;
    font-size: 14px;
    line-height: inherit;
    font-family: inherit;
    text-align: left;
}

.landing-page #faqs .card-collapsed> :not(.card-header):not(.card-status) {
    display: none;
}

.landing-page #highlights .card:hover {
    box-shadow: none;
}

.landing-page #highlights .card-header {
    background: none;
    padding: 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    background-color: transparent;
    letter-spacing: 0.2px;
    width: 100% !important;
    height: 100% !important;
}

.landing-page #highlights .card-title {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.landing-page #highlights .card-options a:not(.btn) {
    margin-left: 0.5rem;
    color: #76839a;
    display: inline-block;
    min-width: 1rem;
}

.landing-page #highlights .card-options {
    margin-left: inherit;
}

.landing-page #highlights .card-collapsed> :not(.card-header):not(.card-status) {
    display: none;
}

.landing-page #highlights .card-header a:hover {
    color: #000;
}

.landing-page #highlights .card .card-body {
    padding: 3.5rem 3.5rem 1.5rem 3.5rem;
    transition: 0.3s ease all;
}

.landing-page #highlights .card .card-header {
    transition: 0.3s ease all;
}

.landing-page #highlights i {
    transform: rotate(180deg);
    transition: all 0.3s;
    color: white;
    border-radius: 50px;
}

.landing-page #highlights .card {
    padding: 1rem;
    transition: 0.3s ease all;
}

.landing-page #highlights.demo-screen-demo #grid p {
    margin-top: 0;
    font-size: 14px;
    line-height: inherit;
    font-family: inherit;
    text-align: left;
}

.landing-page #highlights .card-collapsed> :not(.card-header):not(.card-status) {
    display: none;
}

.landing-page #highlights .card .card-body {
    padding: 3.5rem 3.5rem 1.5rem 3.5rem;
    transition: 0.3s ease all;
}

.landing-page #highlights .card {
    padding: 1rem;
    transition: 0.3s ease all;
}

.landing-page #highlights .card-header {
    background: none;
    padding: 1.5rem 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    background-color: transparent;
    letter-spacing: 0.2px;
    width: 100% !important;
    height: 100% !important;
    border-bottom: 0;
}

.landing-page #highlights .card-collapsed .card-header {
    border-radius: 7px;
}

.landing-page .social-profile-buttons {
    position: fixed;
    z-index: 9;
    right: 0;
    top: 20%;
}

.landing-page .social-profile-buttons .social-icon {
    display: grid;
    margin-bottom: 15px;
    border-radius: 5px;
}

.landing-page .top.sticky.stickyClass {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    border-bottom: 1px solid #ebedfa;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.landing-page #faqs .card.bg-primary-transparent .card-header {
    border-left: 5px solid var(--primary-bg-color);
}

.landing-page #faqs .card.bg-primary-transparent .card-header .card-options-collapse i {
    background-color: var(--primary-bg-color);
}

.landing-page #faqs .card.bg-primary-transparent .card-body {
    border-left: 5px solid var(--primary-bg-color);
    border-radius: 0 0 7px 7px;
}

.landing-page #faqs .card.bg-secondary-transparent .card-header {
    border-left: 5px solid #f1388b;
}

.landing-page #faqs .card.bg-secondary-transparent .card-header .card-options-collapse i {
    background-color: #f1388b;
}

.landing-page #faqs .card.bg-secondary-transparent .card-body {
    border-left: 5px solid #f1388b;
    border-radius: 0 0 7px 7px;
}

.landing-page #faqs .card.bg-success-transparent .card-header {
    border-left: 5px solid #19b159;
}

.landing-page #faqs .card.bg-success-transparent .card-header .card-options-collapse i {
    background-color: #19b159;
}

.landing-page #faqs .card.bg-success-transparent .card-body {
    border-left: 5px solid #19b159;
    border-radius: 0 0 7px 7px;
}

.landing-page #faqs .card.bg-danger-transparent .card-header {
    border-left: 5px solid #fd6074;
}

.landing-page #faqs .card.bg-danger-transparent .card-header .card-options-collapse i {
    background-color: #fd6074;
}

.landing-page #faqs .card.bg-danger-transparent .card-body {
    border-left: 5px solid #fd6074;
    border-radius: 0 0 7px 7px;
}

.landing-page #faqs .card.bg-warning-transparent .card-header {
    border-left: 5px solid #ff9b21;
}

.landing-page #faqs .card.bg-warning-transparent .card-header .card-options-collapse i {
    background-color: #ff9b21;
}

.landing-page #faqs .card.bg-warning-transparent .card-body {
    border-left: 5px solid #ff9b21;
    border-radius: 0 0 7px 7px;
}

.landing-page #faqs .card.bg-info-transparent .card-header {
    border-left: 5px solid #01b8ff;
}

.landing-page #faqs .card.bg-info-transparent .card-header .card-options-collapse i {
    background-color: #01b8ff;
}

.landing-page #faqs .card.bg-info-transparent .card-body {
    border-left: 5px solid #01b8ff;
    border-radius: 0 0 7px 7px;
}

.landing-page #highlights .card.bg-primary-transparent .card-header {
    border-left: 5px solid var(--primary-bg-color);
}

.landing-page #highlights .card.bg-primary-transparent .card-header .card-options-collapse i {
    background-color: var(--primary-bg-color);
}

.landing-page #highlights .card.bg-primary-transparent .card-body {
    border-left: 5px solid var(--primary-bg-color);
    border-radius: 0 0 7px 7px;
}

.landing-page #highlights .card.bg-secondary-transparent .card-header {
    border-left: 5px solid #f1388b;
}

.landing-page #highlights .card.bg-secondary-transparent .card-header .card-options-collapse i {
    background-color: #f1388b;
}

.landing-page #highlights .card.bg-secondary-transparent .card-body {
    border-left: 5px solid #f1388b;
    border-radius: 0 0 7px 7px;
}

.landing-page #highlights .card.bg-success-transparent .card-header {
    border-left: 5px solid #19b159;
}

.landing-page #highlights .card.bg-success-transparent .card-header .card-options-collapse i {
    background-color: #19b159;
}

.landing-page #highlights .card.bg-success-transparent .card-body {
    border-left: 5px solid #19b159;
    border-radius: 0 0 7px 7px;
}

.landing-page #highlights .card.bg-danger-transparent .card-header {
    border-left: 5px solid #fd6074;
}

.landing-page #highlights .card.bg-danger-transparent .card-header .card-options-collapse i {
    background-color: #fd6074;
}

.landing-page #highlights .card.bg-danger-transparent .card-body {
    border-left: 5px solid #fd6074;
    border-radius: 0 0 7px 7px;
}

.landing-page #highlights .card.bg-warning-transparent .card-header {
    border-left: 5px solid #ff9b21;
}

.landing-page #highlights .card.bg-warning-transparent .card-header .card-options-collapse i {
    background-color: #ff9b21;
}

.landing-page #highlights .card.bg-warning-transparent .card-body {
    border-left: 5px solid #ff9b21;
    border-radius: 0 0 7px 7px;
}

.landing-page #highlights .card.bg-info-transparent .card-header {
    border-left: 5px solid #01b8ff;
}

.landing-page #highlights .card.bg-info-transparent .card-header .card-options-collapse i {
    background-color: #01b8ff;
}

.landing-page #highlights .card.bg-info-transparent .card-body {
    border-left: 5px solid #01b8ff;
    border-radius: 0 0 7px 7px;
}

.landing-page h2 {
    font-size: 30px;
}

.landing-page .buynow-landing {
    top: 70px;
    border-radius: 25px;
    z-index: 1;
    box-shadow: 0px 16px 32px rgba(33, 33, 33, 0.15);
}

.landing-page .buynow-landing::before {
    border-radius: 25px;
}

.landing-page .card-shadow {
    box-shadow: 0 4px 25px 0 rgba(168, 180, 208, 0.1);
}

.landing-page .footer-social-list a:hover {
    color: #ffffff;
}

.landing-page .user-social-detail .social-profile {
    height: 35px;
    width: 35px;
    background-color: #ffffff;
    border: 1px solid var(--primary-bg-color);
    font-size: 14px;
    line-height: 2.3;
}

.landing-page .collapse.navbar-collapse {
    background-color: transparent;
}

.landing-page .features.main-features-1.card:hover {
    border: 1px solid var(--primary-bg-color) !important;
    box-shadow: 0px 16px 32px rgba(33, 33, 33, 0.15);
}

.landing-page .features.main-features-2.card:hover {
    border: 1px solid #ff9b21 !important;
}

.landing-page .features.main-features-3.card:hover {
    border: 1px solid #19b159 !important;
}

.landing-page .features.main-features-4.card:hover {
    border: 1px solid #01b8ff !important;
}

.landing-page .features.main-features-5.card:hover {
    border: 1px solid #f1388b !important;
}

.landing-page .features.main-features-6.card:hover {
    border: 1px solid #fd6074 !important;
}

.landing-page .features.main-features-7.card:hover {
    border: 1px solid var(--primary-bg-color) !important;
}

.landing-page .features.main-features-8.card:hover {
    border: 1px solid #ff9b21 !important;
}

.landing-page .bg-primary-transparent {
    background-color: rgba(98, 89, 202, 0.16);
}

@media (min-width: 1276px) {

    .landing-page.horizontalmenu .hor-header .container,
    .landing-page.horizontalmenu .horizontalmenu .container,
    .landing-page.horizontalmenu .main-content.hor-content .container {
        max-width: 1140px !important;
    }

    .landing-page.horizontalmenu .main-demo .container {
        max-width: 1140px !important;
    }
}

@media (min-width: 992px) and (max-width: 1275.98px) {

    .landing-page.horizontalmenu .hor-header .container,
    .landing-page.horizontalmenu .horizontalmenu .container,
    .landing-page.horizontalmenu .main-content.hor-content .container {
        max-width: 960px !important;
    }

    .landing-page.horizontalmenu .side-menu>li>a {
        margin: -3px 0px !important;
        color: #1d212f;
    }

    .landing-page.horizontalmenu .side-menu .slide .side-menu__item.active {
        font-weight: 500;
    }

    .landing-page.horizontalmenu .fixed-header {
        position: relative;
    }
}

.landing-page .navbar .navbar-brand {
    padding: 8px 15px;
}

.landing-page.horizontalmenu .slide {
    margin: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .landing-page .landing-top-header .app-sidebar .container {
        max-width: 960px !important;
    }
}

@media (min-width: 768px) {
    .landing-page .hor-content .container {
        max-width: 720px !important;
    }
}

@media (min-width: 576px) {
    .landing-page .hor-content .container {
        max-width: 540px;
    }
}

@media (max-width: 576px) {
    .landing-page .slick-next {
        right: -10px !important;
    }

    .landing-page .demo-screen-headline h1 {
        font-size: 35px;
    }

    .landing-page .top-footer h6::before {
        display: none;
    }
}

@media (min-width: 576px) {
    .landing-page .demo-footer .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .landing-page .demo-footer .container {
        max-width: 720px !important;
    }
}

@media (min-width: 992px) and (max-width: 1275.98px) {
    .landing-page .demo-footer .container {
        max-width: 960px !important;
    }
}

@media (min-width: 1276px) {
    .landing-page .demo-footer .container {
        max-width: 1140px !important;
    }
}

@media (max-width: 991px) {
    .landing-page .navresponsive-toggler {
        padding: 3px;
    }
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-duration: 1s;
    animation-delay: 1s;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-20px);
        -ms-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
    }
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

@keyframes run {
    0% {
        top: -50%;
    }

    100% {
        top: 110%;
    }
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 3px;
    }
}

.landing-page .feature-logos .slick-slider.slick-initialized .slick-list {
    height: 160px;
}

.landing-page #Clients .slick-slider.slick-initialized .slick-list {
    height: 330px;
}

.landing-page #Contact .card,
.landing-page #Contact .form-control {
    background-color: transparent;
}

.landing-page .slick-slide img {
    width: 75px;
    height: 75px;
    padding: 14px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.landing-page .slick-slide img {
    display: block;
}

.landing-page .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.landing-page .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.landing-page .slick-list:focus {
    outline: none;
}

.landing-page .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.landing-page .slick-slider .slick-track,
.landing-page .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.landing-page .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.landing-page .slick-track:before,
.landing-page .slick-track:after {
    display: table;
    content: "";
}

.landing-page .slick-track:after {
    clear: both;
}

.landing-page .slick-loading .slick-track {
    visibility: hidden;
}

.landing-page .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.landing-page [dir=rtl] .slick-slide {
    float: right;
}

.landing-page .slick-slide.slick-loading img {
    display: none;
}

.landing-page .slick-slide.dragging img {
    pointer-events: none;
}

.landing-page .slick-initialized .slick-slide {
    display: block;
}

.landing-page .slick-loading .slick-slide {
    visibility: hidden;
}

.landing-page .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.landing-page .slick-arrow.slick-hidden {
    display: none;
}

@media (min-width: 992px) {
    .landing-page .navbar-toggler {
        display: none;
    }

    .landing-page .main-header.hor-header {
        display: none;
    }

    .landing-page .main-sidemenu.container {
        max-width: 960px !important;
    }

    .landing-page .top.sticky.sticky-pin .app-sidebar {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        background-color: var(--primary-bg-color) !important;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .landing-page.horizontalmenu .side-menu>li>a {
        margin: -3px 5px !important;
        font-weight: 500;
        padding: 16px 16px !important;
        color: rgba(255, 255, 255, 0.7);
    }

    .landing-page.horizontalmenu .side-menu>li>a.active {
        color: #ffffff;
    }

    .landing-page.horizontalmenu .side-menu {
        display: flex;
        overflow: hidden;
        padding: 0;
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .landing-page .main-sidemenu.container {
        max-width: 720px !important;
    }
}

.team-members {
    position: absolute;
    border: 2px solid #ffffff;
    top: -35px;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 16px 32px rgba(33, 33, 33, 0.15);
}

.bg-image-style {
    /* background-image: url(../landing/images/bg3.png); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    border-radius: 0px;
}

.landing-page .slick-prev,
.landing-page .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.landing-page .slick-prev:hover,
.landing-page .slick-prev:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.landing-page .slick-next:hover,
.landing-page .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.landing-page .slick-prev:hover:before,
.landing-page .slick-prev:focus:before {
    opacity: 1;
}

.landing-page .slick-next:hover:before,
.landing-page .slick-next:focus:before {
    opacity: 1;
}

.landing-page .slick-prev.slick-disabled:before,
.landing-page .slick-next.slick-disabled:before {
    opacity: 0.25;
}

.landing-page .slick-prev:before,
.landing-page .slick-next:before {
    font-family: "feather";
    font-size: 20px;
    padding: 10px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}

.landing-page .slick-prev {
    left: -25px;
}

.landing-page [dir=rtl] .slick-prev {
    right: -25px;
    left: auto;
}

.landing-page .slick-prev:before {
    content: "\e92e";
}

.landing-page [dir=rtl] .slick-prev:before {
    content: "→";
}

.landing-page .slick-next {
    right: -25px;
}

.landing-page [dir=rtl] .slick-next {
    right: auto;
    left: -25px;
}

.landing-page .slick-next:before {
    content: "\e92f";
}

.landing-page [dir=rtl] .slick-next:before {
    content: "<";
}

.landing-page .section {
    padding: 70px 0;
    background-position: center center;
    background-size: cover;
}

.landing-page .dotes {
    content: "";
    margin-top: 3px;
    box-shadow: 0px 4px 16px rgba(135, 96, 251, 0.51);
    background: var(--primary-bg-color);
    width: 19px;
    height: 15px;
    border-radius: 57%;
    color: #ffffff;
}

.landing-page .tab_wrapper.left_side>ul {
    width: 60%;
    float: left;
    border-bottom: 1pxpx solid #ccc;
    font-size: 12px;
}

.landing-page .tab_wrapper.left_side .content_wrapper {
    width: 350px;
    height: 264px;
    border: 1px solid var(--primary-bg-color);
    float: left;
    border-radius: 0px 5px 5px 0px;
}

.landing-page .tab_wrapper .content_wrapper .tab_content {
    display: none;
    padding: 10px;
}

.landing-page .tab_wrapper .content_wrapper {
    top: 0px;
}

.landing-page.horizontalmenu .slide {
    list-style: none;
}

.landing-page .main-navbar .nav-link.with-sub::after {
    display: none;
}

.landing-page .main-logo {
    margin: 0;
}

.landing-page .desktop-logo {
    display: none;
}

.landing-page .main-demo-1 h4,
.landing-page .main-demo-1 h1 {
    color: #ffffff;
}

.landing-page .main-demo-1 h6 {
    color: rgba(255, 255, 255, 0.6);
}

@media (min-width: 992px) {
    .landing-page .main-content {
        padding-top: 0;
    }
}

@media (min-width: 1200px) {

    .landing-page.horizontalmenu .main-navbar.hor-menu .container,
    .landing-page.horizontalmenu .main-content.hor-content .container,
    .landing-page.horizontalmenu .container {
        max-width: 1140px !important;
    }
}

@media (min-width: 992px) {

    .landing-page.horizontalmenu .main-navbar.hor-menu .container,
    .landing-page.horizontalmenu .main-content.hor-content .container,
    .landing-page.horizontalmenu .container {
        max-width: 960px;
    }
}

.testimonial-owl-landing {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 0;
    left: 0;
    right: 0;
    color: #ffffff;
    position: relative;
    border-radius: 0px;
    /* background: url(../landing/images/bg2.jpg) center center; */
    padding: 50px 0;
}

.testimonial-owl-landing::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    display: block;
    z-index: 0;
    background: rgba(105, 100, 247, 0.4) !important;
    top: 0;
}

.student-img img {
    position: absolute;
    bottom: -190px;
    right: 0;
}

.services-statistics.landing-statistics .reveal {
    margin-bottom: 0;
    background: transparent;
}

@media screen and (max-width: 991px) {
    .landing-page .main-logo {
        display: none;
    }

    .landing-top-header::before {
        height: 100%;
    }

    .student-img img {
        display: none;
    }

    .services-statistics.landing-statistics .reveal {
        margin-bottom: 10px;
    }
}

.landing-page #back-to-top i {
    line-height: 1;
}

.landing-page .landing-card-header {
    color: var(--primary-bg-color);
}

.landing-page ul li {
    list-style: none;
}

.counter-body {
    padding-top: 15px;
}

.hexagon-wrapper {
    margin: auto;
    display: flex;
    text-align: initial;
    width: 200px;
    cursor: pointer;
}

.hexagon {
    position: relative;
    width: 20%;
    height: 35%;
    margin: 0 auto;
    color: white;
    display: flex;
    align-content: center;
    justify-content: center;
    transition: 0.5s;
    border-radius: 5px;
    transition: 0.5s;
}

.hexagon i {
    z-index: 1;
    margin: auto;
    font-size: 30px;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    padding: 18px;
}

.hexagon:before,
.hexagon:after {
    position: absolute;
    content: "";
    background: inherit;
    height: 100%;
    width: 100%;
    border-radius: 0;
    transition: 0.5s;
    transform-origin: center;
}

.hexagon:before {
    transform: rotateZ(60deg);
    border-radius: 5px;
    transition: 0.5s;
}

.hexagon:after {
    transform: rotateZ(-60deg);
    border-radius: 5px;
    transition: 0.5s;
}

.main-features-1 .hexagon {
    background: var(--primary-bg-color);
}

.main-features-2 .hexagon {
    background: #ff9b21;
}

.main-features-3 .hexagon {
    background: #19b159;
}

.main-features-4 .hexagon {
    background: #01b8ff;
}

.main-features-5 .hexagon {
    background: #f1388b;
}

.main-features-6 .hexagon {
    background: #fd6074;
}

.main-features-7 .hexagon {
    background: var(--primary-bg-color);
}

.main-features-8 .hexagon {
    background: #ff9b21;
}

@media (max-width: 377px) {
    .landing-page .working-section .working-svg {
        transform: scale(0.85) translateX(-35px);
    }
}

.landing-page .working-section .working-svg-container svg {
    width: 300px;
    height: 300px;
}

.landing-page #grid .card.card-collapsed .card-controls .expand {
    display: block;
}

.landing-page #grid .card:not(.card-collapsed) .card-controls .expand {
    display: none;
}

.landing-page #grid .card.card-collapsed .card-controls .shrink {
    display: none;
}

.landing-page #grid .card:not(.card-collapsed) .card-controls .shrink {
    display: block;
}

.landing-page #grid .card .card-controls {
    position: absolute;
    right: 12px;
}

.landing-page #Clients .landing-title {
    background: linear-gradient(to right, transparent, #ffffff, transparent);
    position: absolute;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    right: 0;
    left: 0;
}

.landing-page #Contact .services-statistics .counter-icon {
    margin-bottom: 1.5rem;
    margin-top: 14px;
    display: inline-flex;
    width: 5rem;
    height: 5rem;
    padding: 26px;
    border-radius: 40% 40%;
    text-align: center;
    line-height: 6rem;
    font-size: 30px;
}

.landing-page .top-footer a {
    color: var(--dark-color);
    line-height: 2;
}

.landing-page .demo-footer .btn-icon {
    display: initial;
}

.landing-page .main-footer {
    font-size: 14px;
    font-weight: 400;
}

@media (min-width: 992px) {

    .landing-page .demo-screen-skin,
    .landing-page .demo-screen-rtl {
        padding: 60px 0;
    }
}

@media (max-width: 991.98px) {
    .landing-page .app-sidebar {
        background-color: #fff !important;
    }

    .landing-page .app-sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        color: #8a98ac;
        z-index: 99;
        background: #fff !important;
        transition: all ease 0.2s;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    }

    .landing-page.horizontalmenu {
        padding-top: 0px;
    }

    .landing-page.horizontalmenu .app-sidebar {
        width: 270px;
    }

    .landing-page.app.sidenav-toggled .app-sidebar {
        left: 0;
    }

    .landing-page.horizontalmenu.app .app-sidebar {
        top: 60px;
    }

    .landing-page.horizontalmenu .app-sidebar {
        left: -300px;
    }

    .landing-page .side-menu__item {
        padding-left: 5px;
    }

    .landing-page .side-menu__item {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: start;
        padding: 0.625rem 1.5rem;
        font-size: 14px;
        font-weight: 400;
        -webkit-transition: all ease 0.3s;
        -o-transition: all ease 0.3s;
        transition: all ease 0.3s;
        color: #1d212f;
        margin: 0px 0px 2px 0px;
    }

    .landing-page .side-menu__label {
        white-space: nowrap;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        position: relative;
        top: 1px;
        line-height: inherit;
    }

    .landing-page .side-menu__item.active .side-menu__label {
        color: var(--primary-bg-color) !important;
    }

    .landing-page .side-menu__item.active .side-menu__label::before,
    .landing-page .landing-page .side-menu__item:hover .side-menu__label::before {
        transform: scale(1, 1) !important;
        transform-origin: left center !important;
    }

    .landing-page .side-menu__item .side-menu__label::before {
        content: "";
        position: absolute;
        transform-origin: right center;
        bottom: -4px;
        width: 70%;
        height: 0.2rem;
        transform: scale(0, 1);
        border-radius: 50px;
        background: var(--primary-bg-color);
        transition: transform 0.3s cubic-bezier(0.5, 0.7, 0.8, 1);
    }

    .landing-page .side-menu {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .working-container {
        padding-top: 20px;
    }
}

/* ###### Tags  ###### */
.tag {
    font-size: 0.75rem;
    background-color: #e9edfb;
    border-radius: 0px;
    padding: 0 0.5rem;
    line-height: 2em;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: default;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tag-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 50px;
}

a.tag {
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s color, 0.3s background;
}

a.tag:hover {
    background-color: rgba(110, 118, 135, 0.2);
    color: inherit;
}

.tag-addon {
    display: inline-block;
    padding: 0 0.5rem;
    color: inherit;
    text-decoration: none;
    margin: 0 -0.5rem 0 0.5rem;
    text-align: center;
    min-width: 1.5rem;
    background: #e1e1ff;
}

.tag-addon:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.tag-addon i {
    vertical-align: middle;
    margin: 0 -0.25rem;
}

a.tag-addon {
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s color, 0.3s background;
}

a.tag-addon:hover {
    background: rgba(0, 0, 0, 0.16);
    color: inherit;
}

.tag-avatar {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 3px 0 0 3px;
    margin: 0 0.5rem 0 -0.5rem;
}

.tag-blue {
    background-color: #467fcf !important;
    color: #ffffff !important;
}

.tag-indigo {
    background-color: var(--primary-bg-color) !important;
    color: #ffffff !important;
}

.tag-purple {
    background-color: #8500ff !important;
    color: #ffffff !important;
}

.tag-pink {
    background-color: #ec82ef !important;
    color: #ffffff !important;
}

.tag-red {
    background-color: #ec2d38 !important;
    color: #ffffff !important;
}

.tag-yellow {
    background-color: #fdb901 !important;
    color: #ffffff !important;
}

.tag-green {
    background-color: #0fa751 !important;
    color: #ffffff !important;
}

.tag-cyan {
    background-color: #00b9ff !important;
    color: #ffffff !important;
}

.tag-white {
    background-color: #ffffff !important;
    color: #ffffff !important;
}

.tag-gray {
    background-color: #868e96 !important;
    color: #ffffff !important;
}

.tag-gray-dark {
    background-color: #343a40 !important;
    color: #ffffff !important;
}

.tag-azure {
    background-color: #17c1f4 !important;
    color: #ffffff !important;
}

.tag-lime {
    background-color: #7bd235 !important;
    color: #ffffff !important;
}

.tag-secondary {
    background-color: #f1388b !important;
    color: #ffffff !important;
}

.tag-success {
    background-color: #19b159 !important;
    color: #ffffff !important;
}

.tag-info {
    background-color: #01b8ff !important;
    color: #ffffff !important;
}

.tag-default {
    background-color: #eaedf7;
    color: #828db1;
}

.tag-warning {
    background-color: #ff9b21 !important;
    color: #ffffff !important;
}

.tag-danger {
    background-color: #f16d75 !important;
    color: #ffffff !important;
}

.tag-light {
    background-color: #f8f9fa !important;
    color: #ffffff !important;
}

.tag-dark {
    background-color: #8f9cc0 !important;
    color: #ffffff !important;
}

.tag-rounded {
    border-radius: 50px;
}

.tag-rounded .tag-avatar {
    border-radius: 50px;
}

.tags {
    margin-bottom: -0.5rem;
    font-size: 0;
}

.tags>.tag {
    margin-bottom: 0.5rem;
}

.tags>.tag:not(:last-child) {
    margin-right: 0.5rem;
}

/* ###### Tags  ###### */
/* ###### Tabs ###### */
.nav-tabs {
    border-bottom: 1px solid #e8e8f7;
    border-bottom-width: 0;
}

.nav-tabs .nav-item {
    margin-bottom: 0px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: rgba(255, 255, 255, 0.3);
    border-width: 0;
    border-radius: 0;
    padding: 10px 15px;
    line-height: 1.428;
    color: #3c4858;
}

.nav-tabs .nav-link+.nav-link {
    margin-left: 3px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #eaedf7 #eaedf7 #eaedf7;
}

.nav-tabs .nav-link.disabled {
    color: #a8afc7;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active {
    color: #3c4858;
    background-color: #ffffff;
    border-color: #eaedf7 #eaedf7 #ffffff;
    color: #8f9cc0;
    font-weight: 500;
    letter-spacing: -0.1px;
}

.nav-tabs .nav-item.show .nav-link {
    color: #3c4858;
    background-color: #ffffff;
    border-color: #eaedf7 #eaedf7 #ffffff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.main-nav-tabs {
    padding: 15px 15px 0;
    background-color: #eaedf7;
}

.main-nav-tabs .lSSlideOuter {
    position: relative;
    padding-left: 32px;
    padding-right: 35px;
}

.main-nav-tabs .lSSlideWrapper {
    overflow: visible;
}

.main-nav-tabs .lSAction>a {
    display: block;
    height: 40px;
    top: 16px;
    opacity: 1;
    background-color: #d7d7f3;
    background-image: none;
}

.main-nav-tabs .lSAction>a:hover,
.main-nav-tabs .lSAction>a:focus {
    background-color: #d3d3ea;
}

.main-nav-tabs .lSAction>a::before {
    font-family: "Ionicons";
    font-size: 14px;
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-nav-tabs .lSAction>a.lSPrev {
    left: -32px;
}

.main-nav-tabs .lSAction>a.lSPrev::before {
    content: "\f3cf";
}

.main-nav-tabs .lSAction>a.lSNext {
    right: -35px;
}

.main-nav-tabs .lSAction>a.lSNext::before {
    content: "\f3d1";
}

.main-nav-tabs .lSAction>a.disabled {
    background-color: #eaedf7;
    color: #ffffff;
}

.main-nav-tabs .lightSlider {
    display: flex;
}

.main-nav-tabs .tab-item {
    flex-shrink: 0;
    display: block;
    float: none;
    min-width: 150px;
}

.main-nav-tabs .tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    line-height: 1.428;
    color: #3c4858;
    white-space: nowrap;
    background-color: #eaedf7;
}

.main-nav-tabs .tab-link:hover,
.main-nav-tabs .tab-link:focus {
    background-color: #f1f2f9;
}

.main-nav-tabs .tab-link.active {
    background-color: #ffffff;
    color: #4b4b63;
    font-weight: 500;
}

.main-tab-pane {
    display: none;
}

.main-tab-pane.active {
    display: block;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.tabs-style-1 .panel-body,
.tabs-style-2 .panel-body,
.tabs-style-3 .panel-body,
.tabs-style-4 .panel-body {
    padding: 15px;
}

.tabs-style-1 .panel-tabs {
    border-bottom: 1px solid #e0e6ed;
}

.tabs-style-1 .main-nav-line .nav-link.active {
    color: #242f48;
    border: 1px solid;
    border-color: #e0e6ed #e0e6ed #ffffff;
    border-radius: 6px 6px 0px 0px;
}

.tabs-style-1 .main-nav-line .nav-link {
    padding: 10px 18px 10px 18px;
    background: transparent;
    border-color: #e0e6ed;
}

.tabs-style-2 .main-nav-line .nav-link.active,
.tabs-style-3 .nav.panel-tabs li a.active,
.tabs-style-4 .nav.panel-tabs li a.active {
    background: var(--primary-bg-color);
    color: #ffffff;
}

.tabs-style-2 .main-nav-line .nav-link {
    padding: 10px 18px 10px 18px;
    background: var(--primary-transparentcolor);
    margin: 0 5px 0px 0;
    border-radius: 6px 6px 0 0;
}

.tabs-style-3 {
    padding: 20px;
    border: 1px solid #e8e8f7;
}

.tabs-style-3 .nav.panel-tabs li a.active {
    background: var(--primary-bg-color);
    color: #ffffff;
    border-radius: 6px;
}

.tabs-style-3 .nav.panel-tabs li a {
    padding: 10px 18px 10px 18px;
    background: var(--primary-transparentcolor);
    border-radius: 6px;
    margin: 0 5px 5px 0;
    text-align: center;
    display: block;
    color: #14112d;
}

@media (min-width: 768px) {
    .tabs-style-4 .tab-menu-heading {
        width: 200px;
    }
}

.tabs-style-4 .nav.panel-tabs li {
    display: block;
    width: 100%;
}

.tabs-style-4 .nav.panel-tabs li a.active {
    background: var(--primary-bg-color);
    color: #ffffff;
    border-radius: 6px;
}

.tabs-style-4 .nav.panel-tabs li a {
    padding: 10px 18px 10px 18px;
    background: var(--primary-transparentcolor);
    border-radius: 6px;
    margin: 0 0 5px 0;
    text-align: center;
    display: block;
    color: #14112d;
    width: 100%;
}

/* ###### Tags  ###### */
/* ###### Navbar  ###### */
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #a8afc7;
    pointer-events: none;
    cursor: default;
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

@media (min-width: 992px) {
    .main-navbar .nav-sub {
        position: absolute;
        top: 45px;
        left: 0;
        width: 180px;
        background-color: var(--dark-theme);
        border: 1px solid transparent;
        border-top: 1px solid var(--dark-border);
        padding: 8px 20px !important;
        z-index: 900;
        border-radius: 11px;
        box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
    }

    .nav-item-mega .nav-sub {
        box-shadow: none;
    }

    .main-navbar .nav-sub .container {
        box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
    }

    .main-navbar .nav-sub-item {
        margin-left: 0;
    }
}

.horizontalmenu .main-navbar .nav-sub-link,
.horizontalmenu .main-navbar .nav-sub-item {
    transition: none;
}

@media (prefers-reduced-motion: reduce) {
    .main-navbar-backdrop {
        transition: none;
    }
}

.main-navbar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 240px;
    overflow-y: auto;
    background-color: var(--dark-theme);
    z-index: 1000;
    visibility: hidden;
    transform: translateX(-240px);
}

.main-navbar .nav-sub-link:before {
    content: "\e048";
    font-family: "typicons" !important;
    opacity: 0.7;
    font-size: 16px;
    position: absolute;
    left: -19px;
    border-radius: 50%;
    border: 0;
    display: block;
    background: transparent;
    top: -3px;
}

.main-navbar>.container,
.main-navbar>.container-fluid {
    display: flex;
    flex-direction: column;
}

@media (prefers-reduced-motion: reduce) {
    .main-navbar {
        transition: none;
    }
}

@media (min-width: 992px) {
    .main-navbar {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        bottom: auto;
        padding: 5px;
        border-right: 0;
        overflow-y: inherit;
        display: block;
        border-bottom: 0;
        visibility: visible;
        transform: none;
        z-index: auto;
        z-index: 10;
        box-shadow: 0 2px 17px 1px rgba(162, 169, 204, 0.24);
        border-bottom: 1px solid transparent;
    }

    .main-navbar .nav-sub-mega .container,
    .main-navbar .nav-sub-mega .container-fluid {
        display: flex;
        background-color: #ffffff;
        border: 1px solid transparent;
        border-top: 1px solid #e8e8f7;
    }
}

@media (max-width: 991.98px) {

    .main-navbar>.container,
    .main-navbar>.container-fluid {
        padding: 0;
    }
}

@media (min-width: 992px) {

    .main-navbar>.container,
    .main-navbar>.container-fluid {
        flex-direction: row;
        align-items: center;
    }
}

.hor-menu.main-navbar .nav-link {
    padding: 5px 0;
}

@media (min-width: 992px) {
    .main-navbar>.container {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .main-navbar>.container-fluid {
        padding-left: 20px;
        padding-right: 20px;
    }

    .main-navbar .nav {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .main-navbar .nav-label {
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        color: #a8afc7;
        letter-spacing: 1px;
        display: block;
        margin-bottom: 10px;
    }

    .main-navbar .nav-item {
        position: relative;
        padding-right: 28px;
    }

    .main-navbar .nav-item:hover .nav-link {
        color: #ffffff;
    }

    .main-navbar.hor-menu .nav-item.active .nav-link.with-sub::after {
        color: #ffffff;
    }

    .main-navbar .nav-item:hover .nav-link.with-sub::after {
        color: #ffffff;
    }

    .main-navbar.hor-menu {
        box-shadow: -8px 12px 18px 0 rgba(21, 21, 62, 0.03);
    }

    .main-navbar.hor-menu .nav-item.active .nav-link,
    .main-navbar.hor-menu .nav-item:hover .nav-link {
        color: #ffffff;
    }

    .main-navbar.hor-menu .nav-item.active .nav-link .hor-icon,
    .main-navbar.hor-menu .nav-item:hover .nav-link .hor-icon {
        color: #ffffff;
    }

    .main-navbar .nav-item-mega {
        position: static;
    }

    .main-navbar .nav-link {
        padding: 0;
        color: rgba(255, 255, 255, 0.6);
        font-size: 15px;
        font-weight: 400;
        position: relative;
        display: flex;
        align-items: center;
        height: 42px;
        outline: none;
    }

    .main-navbar .nav-link i {
        font-size: 18px;
        margin-right: 10px;
    }

    .main-navbar .nav-link i.typcn {
        line-height: 1;
        width: 20px;
    }

    .main-navbar .nav-link i.typcn::before {
        width: auto;
    }

    .main-navbar .nav-link.with-sub::after {
        content: "\f3d0";
        font-family: "Ionicons";
        font-size: 11px;
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-left: auto;
        color: #a8afc7;
    }

    .main-navbar .nav-link.with-sub::before {
        content: "";
        position: absolute;
        top: 37px;
        left: 31%;
        margin-left: -7px;
        width: 15px;
        height: 15px;
        border: 1px solid transparent;
        border-top-color: rgba(61, 119, 180, 0.2);
        border-left-color: rgba(61, 119, 180, 0.2);
        transform: rotate(45deg);
        background-color: #ffffff;
        z-index: 901;
        display: none;
    }

    .main-navbar .nav-sub {
        display: none;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .main-navbar .nav-sub-item {
        position: relative;
        display: block;
        padding: 7px 0;
    }

    .main-navbar .nav-sub-item>.nav-sub {
        left: 159px;
        top: -10px;
        display: none;
    }

    .main-navbar .nav-sub-item.show>.nav-sub {
        display: block;
    }

    .main-navbar .nav-sub-link {
        position: relative;
        font-size: 0.875rem;
        color: rgba(255, 255, 255, 0.4);
        display: flex;
    }

    .main-navbar .nav-sub-link.with-sub {
        justify-content: space-between;
    }

    .main-navbar .nav-sub-link.with-sub::after {
        content: "\f3d0";
        font-family: "Ionicons";
        font-size: 11px;
        font-weight: 400;
        display: inline-block;
        position: relative;
        margin-left: auto;
        color: #a8afc7;
    }

    .main-navbar .nav-sub-mega {
        top: 43px;
        left: 0;
        right: 0;
        background-color: transparent;
        padding: 7px 0;
        border: 0;
        width: auto;
        pointer-events: none;
        border-top: 0;
    }

    .main-navbar .nav-sub-mega .container,
    .main-navbar .nav-sub-mega .container-fluid {
        padding: 0;
        pointer-events: auto;
    }

    .main-navbar .nav-sub-mega .container>div+div,
    .main-navbar .nav-sub-mega .container-fluid>div+div {
        margin-top: 25px;
    }

    .main-navbar .nav-sub-mega .nav {
        min-width: 120px;
        flex-direction: column;
        align-items: stretch;
        padding: 0;
        margin: 0;
    }

    .main-navbar .nav-item .nav-sub.nav-sub-mega li:not(.nav-sub-item) {
        margin-bottom: 15px !important;
    }

    .main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #525277;
        letter-spacing: 0.5px;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
    }

    .main-navbar .nav-item .nav-sub li:not(.nav-sub-item):first-of-type {
        margin-top: 0;
    }

    .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #525277;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        margin-top: 30px;
        margin-left: 30px;
    }

    .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item):first-of-type {
        margin-top: 0;
    }

    .main-navbar .nav-sub-mega .nav-sub-item+.nav-sub-item {
        padding-top: 7px;
        margin-top: 7px;
    }

    .main-navbar .nav-sub-mega .nav-sub-link {
        height: auto;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav {
        flex-direction: row;
        align-items: center;
        margin-bottom: 0;
        padding: 0;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-label {
        display: none;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-item+.nav-item {
        border-top: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-item.show .nav-link::before {
        display: block;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-link {
        height: auto;
        font-size: 14px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-link i {
        margin-right: 8px;
        font-size: 18px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-link i.typcn {
        line-height: 0.9;
        width: auto;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-link.with-sub::after {
        margin-left: 4px;
    }
}

@media (min-width: 1200px) and (max-width: 1477px) {
    .main-navbar .nav-sub-item>.nav-sub {
        left: -200px !important;
    }
}

@media (max-width: 991.98px) {
    .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item {
        margin-left: 37px;
    }

    .main-navbar .nav-sub-item>.nav-sub>.nav-sub-item>.nav-sub-link {
        padding-left: 10px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-link {
        font-size: 0.8125rem;
        margin-left: 18px;
    }
}

@media (max-width: 992px) {

    .main-navbar .nav-sub-link:hover,
    .main-navbar .nav-sub-link:focus {
        color: #ffffff;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-link.with-sub::after {
        margin-left: 4px;
        content: "\f3d1";
        font-size: 12px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-mega {
        padding: 0 25px;
        border-top: 0;
    }
}

@media (max-width: 991.98px) {

    .main-navbar .nav-sub-mega .container,
    .main-navbar .nav-sub-mega .container-fluid {
        max-width: none;
    }
}

@media (min-width: 992px) {

    .main-navbar .nav-sub-mega .container>div,
    .main-navbar .nav-sub-mega .container-fluid>div {
        display: flex;
        padding: 20px;
        flex: 1;
        margin-top: 0;
    }
}

@media (min-width: 1200px) {

    .main-navbar .nav-sub-mega .container>div,
    .main-navbar .nav-sub-mega .container-fluid>div {
        padding: 20px 25px;
    }
}

@media (min-width: 992px) {

    .main-navbar .nav-sub-mega .container>div+div,
    .main-navbar .nav-sub-mega .container-fluid>div+div {
        margin-top: 0;
        border-left: 1px solid #e8e8f7;
    }
}

@media (min-width: 1200px) {
    .main-navbar .nav-sub-mega .nav {
        min-width: 140px;
    }
}

@media (max-width: 991.98px) {
    .main-navbar .nav-sub-mega .nav+.nav .nav-sub-item:first-child {
        margin-top: 6px;
        padding-top: 6px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-sub-mega .nav+.nav {
        margin-left: 20px;
        margin-top: 31px;
        padding-top: 0;
        border-top: 0;
    }
}

@media (min-width: 1200px) {
    .main-navbar .nav-sub-mega .nav+.nav {
        margin-left: 25px;
    }
}

@media (min-width: 992px) {
    .main-navbar .nav-item .nav-sub li:not(.nav-sub-item) {
        margin-bottom: 15px;
        margin-left: 0;
    }
}

.main-navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid #e8e8f7;
    height: 60px;
}

@media (min-width: 992px) {
    .main-navbar-header {
        display: none;
    }
}

.main-navbar-search {
    position: relative;
    padding: 0 20px;
    border-bottom: 1px solid #e8e8f7;
}

.main-navbar-search .form-control {
    border-width: 0;
    padding: 0;
}

.main-navbar-search .form-control:focus {
    box-shadow: none !important;
    border-color: #eaedf7;
}

.main-navbar-search .btn,
.main-navbar-search .sp-container button {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1;
}

@media (min-width: 992px) {
    .main-navbar-search {
        display: none;
    }
}

.sp-container .main-navbar-search button {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1;
}

.main-navbar-two .nav-item.active {
    position: relative;
}

.main-navbar-two .nav-item.active::before {
    content: "";
    position: absolute;
}

.main-navbar-two .nav-item.show .nav-link::before {
    display: none;
}

.main-navbar-two .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}

.main-navbar-two .nav-sub {
    border-width: 1px;
    top: 39px;
    border-top-width: 0;
}

.main-navbar-two .nav-sub-item>.nav-sub {
    left: 178px;
    top: -8px;
    border-top-width: 1px;
}

.main-navbar-two .nav-sub-mega {
    top: 58px;
}

.main-navbar-three .nav-item {
    display: block;
}

.main-navbar-three .nav-item+.nav-item {
    border-left-width: 0;
    padding-left: 0;
}

.main-navbar-three .nav-item.show .nav-link::before {
    display: none;
}

.main-navbar-three .nav-item.active .nav-link {
    font-weight: 700;
}

.main-navbar-three .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: #3b4863;
    position: relative;
}

.main-navbar-three .nav-link:hover,
.main-navbar-three .nav-link:focus {
    color: #8f9cc0;
}

.main-navbar-three .nav-sub {
    top: 35px;
    border-width: 0;
    padding-bottom: 5px;
}

.main-navbar-three .nav-sub-item+.nav-sub-item {
    border-top: 0;
}

.main-navbar-three .nav-sub-item .nav-sub {
    top: -8px;
    left: 182px;
}

.main-navbar-three .nav-sub-link {
    height: 30px;
}

@media (min-width: 992px) {
    .main-navbar-three .nav-item+.nav-item {
        margin-left: 25px;
    }
}

@media (min-width: 992px) {
    .main-navbar-three .nav-link {
        display: block;
    }
}

@media (min-width: 992px) {
    .main-navbar-three .nav-link i {
        display: none;
    }
}

@media (min-width: 992px) {
    .main-navbar-three .nav-sub {
        box-shadow: 0 0 0 2px rgba(180, 189, 206, 0.5);
        padding-bottom: 8px;
    }
}

@media (min-width: 992px) {
    .main-navbar-three .nav-sub-link {
        height: 36px;
    }
}

.navbar.navbar-static .navbar-header {
    float: left;
}

.navbar .navbar-toggle.toggle-left {
    float: left;
    margin-left: 15px;
}

.navbar .navbar-toggle.toggle-right {
    float: right;
    margin-right: 15px;
}

.navbar .navbar-toggle.toggle-sidebar {
    display: block;
}

.navbar-toggler {
    font-size: 1.09375rem;
    text-align: center;
    padding: 0;
    background-color: transparent;
    border: 1px solid transparent;
}

.navbar-toggler .header-icons {
    height: 45px;
    width: 45px;
    padding: 0;
    border-radius: 50%;
    line-height: 45px;
}

.navbar-toggler .header-icons:hover,
.option-dots:hover {
    background-color: #eaedf7;
    border-radius: 50%;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
}

/* ###### Navbar  ###### */
/* ###### Wizard ###### */
.wizard {
    border: 1px solid #e8e8f7;
    background-color: #ffffff;
}

.wizard>.steps {
    padding: 20px;
}

.wizard>.steps>ul {
    padding: 0;
    margin-bottom: 0;
    display: flex;
}

.wizard>.steps>ul li {
    float: none;
    display: block;
    width: auto;
}

.wizard>.steps>ul li .current-info {
    display: none;
}

.wizard>.steps>ul li .title {
    margin-left: 5px;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
}

.wizard>.steps>ul li+li {
    margin-left: 5px;
}

.wizard>.steps a {
    color: #8f9cc0;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wizard>.steps a:hover,
.wizard>.steps a:active {
    color: #8f9cc0;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wizard>.steps a .number,
.wizard>.steps a:hover .number,
.wizard>.steps a:active .number {
    flex-shrink: 0;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #8f9cc0;
    display: block;
    text-align: center;
    line-height: 2;
    width: 30px;
    height: 30px;
    background-color: #eaedf7;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
}

.wizard>.steps .disabled {
    display: none;
}

.wizard>.steps .disabled a {
    color: #a8afc7;
}

.wizard>.steps .disabled a:hover,
.wizard>.steps .disabled a:active {
    color: #a8afc7;
}

.wizard>.steps .current a .title,
.wizard>.steps .current a:hover .title,
.wizard>.steps .current a:active .title {
    display: inline-block;
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:hover .number,
.wizard>.steps .current a:active .number {
    color: #ffffff;
    border-radius: 3px;
}

.wizard>.steps .done a {
    color: #a8afc7;
}

.wizard>.steps .done a:hover,
.wizard>.steps .done a:active {
    color: #a8afc7;
}

.wizard>.steps .done a .title,
.wizard>.steps .done a:hover .title,
.wizard>.steps .done a:active .title {
    display: none;
}

.wizard>.steps .done a .number,
.wizard>.steps .done a:hover .number,
.wizard>.steps .done a:active .number {
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.wizard>.content {
    border-top: 1px solid #e8e8f7;
    border-bottom: 1px solid #e8e8f7;
    min-height: 150px;
    padding: 20px;
}

.wizard>.content>.title {
    font-size: 18px;
    color: #8f9cc0;
    font-weight: 700;
    margin-bottom: 5px;
    display: none;
}

.wizard>.content>.title.current {
    display: none;
}

.wizard>.content>.body {
    float: none;
    position: static;
    width: auto;
    height: auto;
}

.wizard>.content>.body input.parsley-error {
    border-color: #f16d75;
}

.wizard>.content>.body input.parsley-error+ul {
    list-style: none !important;
}

.wizard>.actions {
    padding: 20px;
}

.wizard>.actions>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
}

.wizard>.actions>ul>li:last-child a {
    background-color: #f16d75;
}

.wizard>.actions a {
    display: block;
    padding: 9px 25px;
    line-height: 1.573;
    color: #ffffff;
    border-radius: 4px;
    background: var(--primary-bg-color);
}

.wizard>.actions a:hover,
.wizard>.actions a:active {
    display: block;
    padding: 9px 25px;
    line-height: 1.573;
    color: #ffffff;
    border-radius: 4px;
}

.wizard>.actions .disabled a {
    background-color: #f1388b;
    opacity: 0.6;
}

.wizard>.actions .disabled a:hover,
.wizard>.actions .disabled a:active {
    background-color: #f1388b;
    opacity: 0.8;
}

.wizard.vertical>.steps {
    padding: 20px;
}

.wizard.vertical>.steps ul li+li {
    margin-left: 10px;
}

.wizard.vertical>.steps ul li .title {
    display: none;
}

.wizard.vertical>.steps .current a .title {
    display: inline-block;
}

.wizard.vertical>.content {
    margin: 0;
    padding: 20px;
}

.wizard.vertical>.actions {
    padding: 20px;
}

.wizard.vertical>.actions ul {
    float: none;
    margin: 0;
    padding: 0;
}

@media (min-width: 768px) {
    .wizard>.steps {
        padding: 25px;
    }
}

@media (min-width: 992px) {
    .wizard>.steps {
        padding: 20px;
    }
}

@media (prefers-reduced-motion: reduce) {
    .wizard>.steps>ul li .title {
        transition: none;
    }
}

@media (min-width: 576px) {
    .wizard>.steps>ul li .title {
        display: none;
        margin-left: 10px;
    }
}

@media (min-width: 768px) {
    .wizard>.steps>ul li .title {
        display: inline-block;
    }
}

@media (min-width: 576px) {
    .wizard>.steps>ul li+li {
        margin-left: 20px;
    }
}

@media (min-width: 992px) {
    .wizard>.steps>ul li+li {
        margin-left: 30px;
    }
}

@media (min-width: 1200px) {
    .wizard>.steps a {
        justify-content: flex-start;
    }

    .wizard>.steps a:hover,
    .wizard>.steps a:active {
        justify-content: flex-start;
    }
}

@media (prefers-reduced-motion: reduce) {

    .wizard>.steps a .number,
    .wizard>.steps a:hover .number,
    .wizard>.steps a:active .number {
        transition: none;
    }
}

@media (min-width: 576px) {

    .wizard>.steps a .number,
    .wizard>.steps a:hover .number,
    .wizard>.steps a:active .number {
        font-size: 14px;
        font-weight: 600;
        width: 30px;
        height: 30px;
        vertical-align: middle;
    }
}

@media (min-width: 576px) {
    .wizard>.steps .disabled {
        display: block;
    }
}

@media (min-width: 768px) {

    .wizard>.steps .done a .title,
    .wizard>.steps .done a:hover .title,
    .wizard>.steps .done a:active .title {
        display: inline-block;
    }
}

@media (min-width: 768px) {
    .wizard>.content {
        padding: 25px;
    }
}

@media (min-width: 992px) {
    .wizard>.content {
        padding: 20px;
    }
}

@media (min-width: 768px) {
    .wizard>.actions {
        padding: 25px;
    }
}

@media (min-width: 992px) {
    .wizard>.actions {
        padding: 10px;
    }
}

@media (min-width: 576px) {
    .wizard.vertical>.steps {
        float: left;
        width: 20%;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.steps {
        width: 15%;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps {
        padding: 25px;
        width: 30%;
    }
}

@media (min-width: 576px) {
    .wizard.vertical>.steps ul {
        flex-direction: column;
    }
}

@media (min-width: 576px) {
    .wizard.vertical>.steps ul li+li {
        margin-top: 10px;
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.steps ul li+li {
        margin-top: 20px;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps ul li .title {
        display: block;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps a {
        justify-content: flex-start;
    }
}

@media (min-width: 576px) {
    .wizard.vertical>.steps .current a .title {
        display: none;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps .current a .title {
        display: inline-block;
    }
}

@media (min-width: 576px) {
    .wizard.vertical>.content {
        border-top-width: 0;
        border-bottom-width: 0;
        width: 80%;
        float: right;
        border-left: 1px solid #e8e8f7;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.content {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.content {
        width: 70%;
        padding: 25px;
    }
}

@media (min-width: 576px) {
    .wizard.vertical>.actions {
        width: 80%;
        float: right;
        border-left: 1px solid #e8e8f7;
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.actions {
        width: 85%;
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.actions {
        width: 70%;
        padding: 25px;
    }
}

.wizard-style-1>.steps>ul a {
    padding: 0;
    height: 50px;
}

.wizard-style-1>.steps>ul a:hover,
.wizard-style-1>.steps>ul a:active {
    padding: 0;
    height: 50px;
}

.wizard-style-1>.steps>ul a .number,
.wizard-style-1>.steps>ul a:hover .number,
.wizard-style-1>.steps>ul a:active .number {
    width: 50px;
    height: 100%;
    border: 0;
    font-size: 18px;
    font-weight: bold;
    color: #a8afc7;
    background-color: #eaedf7;
    border-radius: 0;
}

.wizard-style-1>.steps>ul a .title,
.wizard-style-1>.steps>ul a:hover .title,
.wizard-style-1>.steps>ul a:active .title {
    margin-right: 20px;
    margin-left: 20px;
}

.wizard-style-1>.steps>ul .done a .number,
.wizard-style-1>.steps>ul .done a:hover .number,
.wizard-style-1>.steps>ul .done a:active .number {
    background-color: #643ab0;
    color: #ffffff;
}

.wizard-style-2>.steps>ul a {
    padding: 0;
    height: 50px;
    border-radius: 50px;
}

.wizard-style-2>.steps>ul a:hover,
.wizard-style-2>.steps>ul a:active {
    padding: 0;
    height: 50px;
    border-radius: 50px;
}

.wizard-style-2>.steps>ul a .number,
.wizard-style-2>.steps>ul a:hover .number,
.wizard-style-2>.steps>ul a:active .number {
    width: 50px;
    height: 100%;
    border: 2px solid #eaedf7;
    font-size: 18px;
    font-weight: bold;
    color: #a8afc7;
    background-color: #ffffff;
}

.wizard-style-2>.steps>ul a .title,
.wizard-style-2>.steps>ul a:hover .title,
.wizard-style-2>.steps>ul a:active .title {
    margin-right: 20px;
}

.wizard-style-2>.steps>ul .done a .number,
.wizard-style-2>.steps>ul .done a:hover .number,
.wizard-style-2>.steps>ul .done a:active .number {
    border-color: #6f42c1;
    color: #6f42c1;
}

/* ###### Wizard ###### */
/* ###### EQUAL COLUMN WIDTH STEP INDICATOR ###### */
.step-equal-width>.steps>ul {
    display: flex;
    display: flex;
}

.step-equal-width>.steps>ul>li {
    flex: 1;
    width: auto;
    float: none;
    flex: 1;
    width: auto;
    float: none;
}

/* ###### EQUAL COLUMN WIDTH STEP INDICATOR ###### */
/* ###### Perfect-scroll  ###### */
.ps {
    overflow: hidden;
}

.ps>.ps__rail-y {
    width: 5px;
    background-color: rgba(28, 39, 60, 0.04);
    z-index: 10;
    position: absolute;
    left: auto !important;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;
}

.ps>.ps__rail-y>.ps__thumb-y {
    position: absolute;
    border-radius: 0;
    width: 3px;
    left: 1px;
    background-color: tranparent;
}

.ps.ps--active-y:hover>.ps__rail-y,
.ps.ps--active-y:focus>.ps__rail-y {
    opacity: 0;
    right: -1px !important;
}

@media (prefers-reduced-motion: reduce) {
    .ps>.ps__rail-y {
        transition: none;
    }
}

/* ###### Perfect-scroll  ###### */
/* ###### Radio ###### */
.rdiobox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: 18px;
}

.rdiobox span {
    padding-left: 13px;
}

.rdiobox span:before,
.rdiobox span:after {
    line-height: 18px;
    position: absolute;
}

.rdiobox span:before {
    content: "";
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border: 1px solid #a8afc7;
    border-radius: 50px;
    top: 2px;
    left: 0;
}

.rdiobox span:after {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #ffffff;
    border-radius: 50px;
    top: 7px;
    left: 5px;
    display: none;
}

.rdiobox span:empty {
    padding-left: 0;
    width: 16px;
    display: block;
}

.rdiobox input[type=radio] {
    opacity: 0;
    margin: 0;
}

.rdiobox input[type=radio]:checked+span:before {
    border-color: transparent;
}

.rdiobox input[type=radio]:checked+span:after {
    display: block;
}

.rdiobox input[type=radio][disabled]+span {
    opacity: 0.75;
}

.rdiobox input[type=radio][disabled]+span:before,
.rdiobox input[type=radio][disabled]+span:after {
    opacity: 0.75;
}

.rdiobox-inline {
    display: inline-block;
}

.form-group-rdiobox {
    display: flex;
    align-items: center;
}

.form-group-rdiobox .rdiobox {
    margin-bottom: 0;
}

.form-group-rdiobox .rdiobox+.rdiobox {
    margin-left: 30px;
}

.form-group-rdiobox .rdiobox span {
    padding-left: 0;
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(135, 96, 251, 0.5);
}

/* ###### Radio ###### */
/* ###### Custom-select ###### */
.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c4858;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
    border-radius: 0px;
    appearance: none;
}

.custom-select:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(135, 96, 251, 0.25);
}

.custom-select:focus::-ms-value {
    color: #3c4858;
    background-color: #ffffff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #a8afc7;
    background-color: #eaedf7;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.76562rem;
}

.custom-select-lg {
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.09375rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
    box-shadow: none;
}

.custom-file-input:disabled~.custom-file-label {
    background-color: #eaedf7;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 0;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c4858;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
    border-radius: 0px;
    height: 38px;
    line-height: 1.8;
    border-radius: 0;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #3c4858;
    content: "Browse";
    background-color: #e5e9f3;
    border-left: inherit;
    border-radius: 0 3px 3px 0;
    line-height: 1.8;
    border-radius: 0;
    height: auto;
}

/* ###### Custom-select ###### */
/* ###### Custom-Range ###### */
.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb,
.custom-range:focus::-moz-range-thumb,
.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(135, 96, 251, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #e6ecff;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eaedf7;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

.custom-range::-moz-range-thumb:active {
    background-color: #e6ecff;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eaedf7;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

.custom-range::-ms-thumb:active {
    background-color: #e6ecff;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #eaedf7;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #eaedf7;
    border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #a8afc7;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #a8afc7;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #a8afc7;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none;
    }
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none;
    }
}

/* ###### Custom-Range ###### */
/* ###### Custom-Switch ###### */
.custom-switches-stacked {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}

.custom-switches-stacked .custom-switch {
    margin-bottom: 0.5rem;
}

.custom-switch-indicator {
    display: inline-block;
    height: 1.25rem;
    width: 2.25rem;
    background: #e5e9f3;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #f0f2f8;
    transition: 0.3s border-color, 0.3s background-color;
}

.custom-switch-indicator:before {
    content: "";
    position: absolute;
    height: calc(1.25rem - 4px);
    width: calc(1.25rem - 4px);
    top: 1px;
    left: 1px;
    background: #ffffff;
    border-radius: 50%;
    transition: 0.3s left;
    box-shadow: none;
}

.custom-switch-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-switch-input:checked~.custom-switch-indicator:before {
    left: calc(1rem + 1px);
}

.custom-switch-input:focus~.custom-switch-indicator {
    box-shadow: none;
}

.custom-switch-description {
    margin-left: 0.5rem;
    color: #a8afc7;
    transition: 0.3s color;
}

.custom-switch-input:checked~.custom-switch-description {
    color: #8f9cc0;
}

.custom-switch {
    padding-left: 2.25rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    padding-left: 0;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0rem;
}

.custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #a8afc7;
    border-radius: 0rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #ffffff;
    transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(135, 96, 251, 0.5);
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}

/* ###### Custom-Switch ###### */
/* ###### Rating  ###### */
.main-rating-value {
    font-size: 40px;
    font-weight: 400;
    font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    color: #8f9cc0;
    margin-bottom: 0;
    letter-spacing: -0.5px;
    line-height: 0.7;
}

.main-rating-label {
    margin-bottom: 10px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.rating-stars {
    width: 100%;
    text-align: center;
    font-size: 20px;
}

.rating-stars .rating-stars-container {
    font-size: 0px;
}

.rating-stars .rating-stars-container .rating-star {
    display: inline-block;
    font-size: 30px;
    cursor: pointer;
    padding: 4px 8px;
    color: #f0f2f8;
}

.rating-stars .rating-stars-container .rating-star.is--active .fa-heart,
.rating-stars .rating-stars-container .rating-star.is--hover .fa-heart {
    color: #fb0d00;
}

.rating-stars .rating-stars-container .rating-star.sm {
    display: inline-block;
    font-size: 14px;
    color: #eaedf1;
    cursor: pointer;
    padding: 5px;
}

.rating-stars .rating-stars-container .rating-star.is--active,
.rating-stars .rating-stars-container .rating-star.is--hover {
    color: #f1c40f;
}

.rating-stars .rating-stars-container .rating-star.is--no-hover,
.rating-stars .rating-stars-container .rating-star .fa-heart .is--no-hover {
    color: #f1f1f9;
}

.rating-stars input {
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.6;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #eaedf1;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.rating-stars i {
    padding: 4px;
}

.jq-stars {
    display: inline-block;
}

.jq-rating-label {
    font-size: 22px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-family: helvetica, arial, verdana;
}

.jq-star {
    width: 100px;
    height: 100px;
    display: inline-block;
    cursor: pointer;
}

.jq-star-svg {
    padding-left: 3px;
    width: 100%;
    height: 100%;
}

.jq-star-svg path {
    stroke-linejoin: round;
}

/* un-used */
.jq-shadow {
    -webkit-filter: drop-shadow(-2px -2px 2px #888);
    filter: drop-shadow(-2px -2px 2px #888);
}

/* ###### Rating  ###### */
/* ###### Timeline  ###### */
.latest-timeline1 li {
    position: relative;
}

.latest-timeline.latest-timeline1-icon ul.timeline>li:before {
    display: none;
}

.latest-timeline1-icon {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 7px;
    line-height: 2rem;
    text-align: center;
    left: 0;
    z-index: 1;
    color: #ffffff;
    font-weight: 500;
    left: -38px;
}

.latest-timeline ul.timeline {
    list-style-type: none;
    position: relative;
}

.latest-timeline ul.timeline:before {
    content: " ";
    background: #eff0f6;
    display: inline-block;
    position: absolute;
    left: 15px;
    width: 3px;
    height: 100%;
    z-index: 1;
}

.latest-timeline ul.timeline>li {
    margin: 20px 0;
    padding-left: 1rem;
}

.latest-timeline1 a {
    color: #181727;
    font-weight: 600;
}

.vtimeline {
    list-style: none;
    padding: 0;
    position: relative;
    margin-bottom: 20px;
}

.vtimeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #d1d4e4;
    left: 50%;
    margin-left: -1.5px;
}

.vtimeline .timeline-wrapper {
    display: block;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    padding-right: 90px;
}

.vtimeline .timeline-wrapper:before {
    content: " ";
    display: table;
}

.vtimeline .timeline-wrapper:after {
    content: " ";
    display: table;
    clear: both;
}

.vtimeline .timeline-wrapper .timeline-panel {
    border-radius: 2px;
    padding: 20px;
    position: relative;
    background: #ffffff;
    border-radius: 0px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
    width: 35%;
    margin-left: 15%;
}

.vtimeline .timeline-wrapper .timeline-panel:before {
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    content: "";
    left: 0;
    right: 0;
}

.vtimeline .timeline-wrapper .timeline-panel:after {
    position: absolute;
    top: 10px;
    right: -14px;
    display: inline-block;
    border-top: 14px solid transparent;
    border-left: 14px solid #cad4e4;
    border-right: 0 solid #cad4e4;
    border-bottom: 14px solid transparent;
    content: " ";
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-title {
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 0.625rem;
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-body p+p {
    margin-top: 5px;
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-body ul {
    margin-bottom: 0;
}

.vtimeline .timeline-wrapper .timeline-panel .timeline-footer span {
    font-size: 0.6875rem;
}

.vtimeline .timeline-wrapper .timeline-badge {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 16px;
    left: calc(50% - 7px);
    z-index: 0;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border: 2px solid #ffffff;
}

.vtimeline .timeline-wrapper .timeline-badge i {
    color: #ffffff;
}

.vtimeline .timeline-wrapper.timeline-inverted {
    padding-right: 0;
    padding-left: 90px;
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-left: auto;
    margin-right: 15%;
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline-wrapper-secondary .timeline-panel:before,
.timeline-wrapper-secondary .timeline-badge {
    background: #f1388b;
}

.timeline-wrapper-success .timeline-panel:before,
.timeline-wrapper-success .timeline-badge {
    background: #19b159;
}

.timeline-wrapper-info .timeline-panel:before,
.timeline-wrapper-info .timeline-badge {
    background: #01b8ff;
}

.timeline-wrapper-warning .timeline-panel:before,
.timeline-wrapper-warning .timeline-badge {
    background: #fcd539;
}

.timeline-wrapper-danger .timeline-panel:before,
.timeline-wrapper-danger .timeline-badge {
    background: #f16d75;
}

.timeline-wrapper-light .timeline-panel:before,
.timeline-wrapper-light .timeline-badge {
    background: #f1f2f9;
}

.timeline-wrapper-dark .timeline-panel:before,
.timeline-wrapper-dark .timeline-badge {
    background: #828db1;
}

/* ###### Timeline  ###### */
/* ###### Image ###### */
.main-img-user {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.main-img-user img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.img-fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-thumbnail .caption {
    padding: 15px;
}

.img-flag {
    width: 25px;
    height: 12px;
    margin-top: -4px;
}

.img-sm {
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: 5px;
    margin-right: 20px;
    border: 1px solid #eaedf7;
    background: #f4f4fb;
    padding: 10px;
}

/* ###### Image ###### */
/* ###### Avatar ###### */
.avatar-list {
    margin: 0 0 -0.5rem;
    padding: 0;
    font-size: 0;
}

.avatar-list .avatar {
    margin-bottom: 0.5rem;
}

.avatar-list .avatar:not(:last-child) {
    margin-right: 0.5rem;
}

.avatar {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
}

.main-avatar {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
}

.main-avatar::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 6px;
    height: 6px;
    background-color: #a8afc7;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
    border-radius: 100%;
}

.main-avatar.online::after {
    background-color: #19b159;
}

.avatar-xs {
    width: 24px;
    height: 24px;
    font-size: 11px;
}

.avatar-xs::after {
    width: 5px;
    height: 5px;
}

.avatar-sm {
    width: 32px;
    height: 32px;
    font-size: 14px;
}

.avatar-sm::after {
    width: 7px;
    height: 7px;
}

.avatar-md {
    width: 40px;
    height: 40px;
    font-size: 24px;
}

.avatar-md::after {
    width: 9px;
    height: 9px;
    right: 2px;
    bottom: 2px;
}

.avatar-lg {
    width: 64px;
    height: 64px;
    font-size: 28px;
}

.avatar-lg::after {
    width: 10px;
    height: 10px;
    bottom: 3px;
    right: 4px;
}

.avatar-xl {
    width: 72px;
    height: 72px;
    font-size: 36px;
}

.avatar-xl::after {
    width: 11px;
    height: 11px;
    bottom: 4px;
    right: 5px;
    box-shadow: 0 0 0 2.5px #ffffff;
}

.avatar-xxl {
    width: 100px;
    height: 100px;
    font-size: 50px;
}

.avatar-xxl::after {
    width: 13px;
    height: 13px;
    bottom: 6px;
    right: 7px;
    box-shadow: 0 0 0 3px #ffffff;
}

.demo-avatar-group {
    display: flex;
    align-items: center;
}

.demo-avatar-group .main-img-user,
.demo-avatar-group .main-avatar {
    flex-shrink: 0;
}

.demo-avatar-group .main-img-user+.main-img-user,
.demo-avatar-group .main-img-user+.main-avatar {
    margin-left: 5px;
}

.demo-avatar-group .main-avatar+.main-img-user,
.demo-avatar-group .main-avatar+.main-avatar {
    margin-left: -5px;
}

@media (min-width: 576px) {

    .demo-avatar-group .main-img-user+.main-img-user,
    .demo-avatar-group .main-img-user+.main-avatar {
        margin-left: -9px;
        border: 2px solid #ffffff;
    }

    .demo-avatar-group .main-avatar+.main-img-user,
    .demo-avatar-group .main-avatar+.main-avatar {
        margin-left: -9px;
        border: 2px solid #ffffff;
    }
}

@media (min-width: 576px) {

    #shapes .demo-avatar-group .main-img-user+.main-img-user,
    #shapes .demo-avatar-group .main-img-user+.main-avatar {
        margin-left: 9px;
        border: 0px solid #ffffff;
    }
}

/* ###### Avatars ###### */
/* ###### List ###### */
.main-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.main-list-item+.main-list-item {
    border-top: 1px solid #e8e8f7;
}

.main-list-item:last-child {
    padding-bottom: 0;
}

.main-list-item h6 {
    margin-bottom: 2px;
    font-weight: 600;
}

.main-list-item>div:first-child h6 {
    color: #1d212f;
}

.main-list-item>div span {
    color: #a8afc7;
    font-size: 12px;
}

.main-list-item>div:last-child {
    text-align: right;
}

.main-list-item>div:last-child h6 {
    line-height: 1;
}

.list-unstyled li {
    display: inline-flex;
    margin-bottom: 0;
}

/* ###### List ###### */
/* ###### Checkbox ###### */
.ckbox {
    font-weight: normal;
    position: relative;
    display: block;
    line-height: 1;
    margin-bottom: 0;
}

.ckbox span {
    padding-left: 16px;
}

.ckbox span:empty {
    float: left;
    padding-left: 0;
    width: 3px;
}

.ckbox span:before,
.ckbox span:after {
    line-height: 18px;
    position: absolute;
}

.ckbox span:before {
    content: "";
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border: 1px solid #ccd3e4 !important;
    top: 1px;
    left: 0;
    border-radius: 3px;
}

.ckbox span:after {
    top: 1px;
    left: 0;
    width: 16px;
    height: 16px;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
    background-size: 66%;
    background-repeat: no-repeat;
    background-position: 55% 45%;
    line-height: 17px;
    display: none;
    border-radius: 3px;
}

.ckbox input[type=checkbox] {
    opacity: 0;
    margin: 0;
}

.ckbox input[type=checkbox]:checked+span:after {
    display: block;
}

.ckbox input[type=checkbox][disabled]+span {
    opacity: 0.75;
}

.ckbox input[type=checkbox][disabled]+span:before,
.ckbox input[type=checkbox][disabled]+span:after {
    opacity: 0.75;
}

.ckbox-inline {
    display: inline-block;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(135, 96, 251, 0.5);
}

/* ###### Checkbox ###### */
/* ###### Toggle ###### */
.main-toggle {
    width: 60px;
    height: 25px;
    background-color: #e8e8f7;
    padding: 2px;
    position: relative;
    overflow: hidden;
}

.main-toggle span {
    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    display: block;
    width: 20px;
    background-color: #ffffff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.main-toggle span::before,
.main-toggle span::after {
    position: absolute;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #ffffff;
    top: 2px;
    line-height: 1.38;
}

.main-toggle span::before {
    content: "on";
    left: -25px;
}

.main-toggle span::after {
    content: "off";
    right: -29px;
}

.main-toggle.on span {
    left: 37px;
}

@media (prefers-reduced-motion: reduce) {
    .main-toggle span {
        transition: none;
    }
}

.main-toggle-secondary.on {
    background-color: #f1388b;
}

.main-toggle-success.on {
    background-color: #19b159;
}

.main-toggle-dark.on {
    background-color: #8f9cc0;
}

/* ###### Toggle ###### */
/* ###### List-group ###### */
.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    color: #3c4858;
}

.list-group-item-action {
    width: 100%;
    color: #3c4858;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    color: #3c4858;
    text-decoration: none;
    background-color: #f1f2f9;
}

.list-group-item-action:active {
    color: #8f9cc0;
    background-color: #eaedf7;
}

.list-group-item-action h5 {
    font-size: 16px;
}

.list-group-item-action p {
    font-size: 14px;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 16px 15px;
    margin-bottom: -1px;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #a8afc7;
    pointer-events: none;
    background-color: #ffffff;
}

.list-group-horizontal {
    flex-direction: row;
}

.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 0;
}

.listgroup-example2 ul ul {
    list-style-type: circle;
    margin: 10px 0 0 0;
}

.listgroup-example ul,
.listgroup-example2 ul {
    list-style-type: none;
}

.listgroup-example ul li,
.listgroup-example2 ul li {
    padding: 5px 5px;
}

.listgroup-example .list-group.checked .list-group-item {
    border: 0;
}

.listorder {
    position: relative;
    margin-bottom: 0;
    border: 0;
    list-style-type: decimal;
    list-style-position: inside;
    padding: 7px 11px !important;
    margin-right: 0;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }

    .list-group-horizontal-sm .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }

    .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }

    .list-group-horizontal-md .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }

    .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }

    .list-group-horizontal-lg .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }

    .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }

    .list-group-horizontal-xl .list-group-item {
        margin-right: -1px;
        margin-bottom: 0;
    }

    .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 0;
    }
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
    margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.list-group-item-primary {
    color: var(--primary-bg-color) !important;
    background-color: var(--primary08) !important;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: var(--primary-bg-color);
    background-color: var(--primary08);
}

.list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.list-group-item-secondary {
    color: #f1388b !important;
    background-color: #f9e5db !important;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #3f4654;
    background-color: #cacfdb;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #3f4654;
    border-color: #3f4654;
}

.list-group-item-success {
    color: #1f5c01 !important;
    background-color: #c8e9b8 !important;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #1f5c01;
    background-color: #b9e3a5;
}

.list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #1f5c01;
    border-color: #1f5c01;
}

.list-group-item-info {
    color: #0c5460 !important;
    background-color: #bee5eb !important;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #0c5460;
    border-color: #0c5460;
}

.list-group-item-warning {
    color: #856404 !important;
    background-color: #ffeeba !important;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #856404;
    border-color: #856404;
}

.list-group-item-danger {
    color: #721c24 !important;
    background-color: #f5c6cb !important;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #721c24;
    border-color: #721c24;
}

.list-group-item-light {
    color: #8f9cc0 !important;
    background-color: #f1f2f9 !important;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #8f9cc0;
    background-color: #eaedf7;
}

.list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #7f7f81;
    border-color: #7f7f81;
}

.list-group-item-dark {
    color: #1f2533 !important;
    background-color: #c8ccd3 !important;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #1f2533;
    background-color: #babfc8;
}

.list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #1f2533;
    border-color: #1f2533;
}

.list-group-item.active {
    color: #ffffff !important;
    background-color: var(--primary-bg-color) !important;
    border-color: var(--primary-bg-color) !important;
}

/* ###### List-group ###### */
/* ###### Jumbotron ###### */
.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #eaedf7;
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

/* ###### Jumbotron ###### */
/* ###### Custom-control ###### */
.custom-control {
    position: relative;
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #ffffff;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #ffffff;
    background-color: #e6ecff;
    border-color: #e6ecff;
}

.custom-control-input:disabled~.custom-control-label {
    color: #a8afc7;
}

.custom-control-input:disabled~.custom-control-label::before {
    background-color: #eaedf7;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #dedef5 solid 1px;
    border-radius: 4px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.custom-control-input.is-valid~.valid-feedback,
input.custom-control-input.parsley-success~.valid-feedback,
textarea.custom-control-input.parsley-success~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
input.custom-control-input.parsley-success~.valid-tooltip,
textarea.custom-control-input.parsley-success~.valid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
input.custom-control-input.parsley-error~.invalid-feedback,
textarea.custom-control-input.parsley-error~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
input.custom-control-input.parsley-error~.invalid-tooltip,
textarea.custom-control-input.parsley-error~.invalid-tooltip {
    display: block;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none;
    }
}

.custom-control-md {
    min-height: 2rem !important;
}

.custom-control-label-md {
    padding-left: 8px;
    padding-top: 6px;
}

.custom-control-label-md::before,
.custom-control-label-md::after {
    top: 0.25rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.custom-control-lg {
    min-height: 2rem !important;
}

.custom-control-label-lg {
    padding-left: 15px;
    padding-top: 10px;
}

.custom-control-label-lg::before,
.custom-control-label-lg::after {
    top: 0.25rem !important;
    width: 2rem !important;
    height: 2rem !important;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgb(169, 164, 236);
    border-color: #a9a4ec;
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgb(169, 164, 236);
    border-color: #a9a4ec;
}

.custom-switch-indicator-md {
    display: inline-block;
    height: 1.5rem;
    width: 2.8rem;
    background: #f5f6fb;
    border-radius: 50px;
    position: relative;
    vertical-align: bottom;
    border: 1px solid #ebeaf1;
    transition: 0.3s border-color, 0.3s background-color;
}

.custom-switch-input:checked~.custom-switch-indicator-md:before {
    left: calc(1.46rem - 1px);
}

.custom-switch-indicator-md:before {
    top: 2px;
    height: calc(1.25rem - 1px);
    width: calc(1.25rem - 1px);
}

.custom-switch-indicator-lg {
    height: 1.9rem;
    width: 3.3rem;
}

.custom-switch-input:checked~.custom-switch-indicator-lg:before {
    left: calc(1.46rem + 1px);
}

.custom-switch-indicator-lg:before {
    height: calc(1.25rem + 4px);
    width: calc(1.25rem + 4px);
    top: 2px;
}

.custom-switch-description {
    color: #1d212f;
}

/* ###### Custom-control ###### */
/* ###### Custom-styles ###### */
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.float-start {
    float: left !important;
}

.float-end {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
}

@media print {
    * {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: " ('attr(title) ')";
    }

    pre {
        white-space: pre-wrap !important;
        border: 1px solid #a8afc7;
        page-break-inside: avoid;
    }

    blockquote {
        border: 1px solid #a8afc7;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body,
    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #ffffff !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #e8e8f7 !important;
    }

    .table-dark {
        color: inherit;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: #eaedf7;
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #eaedf7;
    }
}

.main-body,
.main-dashboard {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.main-body::before {
    content: "main";
    position: fixed;
    top: 45%;
    left: 70%;
    transform: translate3d(-50%, -58%, 0);
    font-size: 1300px;
    font-weight: 600;
    letter-spacing: -10px;
    line-height: 0.5;
    opacity: 0.02;
    z-index: -1;
    display: none;
}

.main-table-reference {
    margin-top: 40px;
    background-color: #ffffff;
}

.main-table-reference>thead>tr>th,
.main-table-reference>thead>tr>td {
    padding: 8px 10px;
    border: 1px solid #e8e8f7;
    font-size: 13px;
    background-color: #eaedf7;
    color: #4b4f56;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 0;
}

.main-table-reference>tbody>tr>th,
.main-table-reference>tbody>tr>td {
    padding: 8px 10px;
    border: 1px solid #e8e8f7;
    font-size: 13px;
}

.main-notification-title {
    font-size: 18px;
    font-weight: 600;
    color: #11141f;
    margin-bottom: 5px;
    line-height: 1;
}

.main-notification-text {
    font-size: 13px;
    margin-bottom: 0px;
    color: #3c4858;
}

.main-notification-list,
.main-profile-menu .dropdown-menu .dropdown-item:first-child {
    border-top: 1px solid #e8e8f7;
}

.main-notification-list .media {
    padding: 10px 10px;
    position: relative;
}

.main-notification-list .media+.media {
    border-top: 1px solid rgba(123, 65, 216, 0.1);
}

.main-notification-list .media.new {
    color: #8f9cc0;
}

.main-notification-list .media:hover,
.main-notification-list .media:focus {
    cursor: pointer;
    z-index: 1;
}

.main-notification-list .media:hover::before,
.main-notification-list .media:focus::before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0px;
    bottom: -1px;
    right: 0px;
    background-color: #f1f2f9;
    border-top: 1px solid #e8e8f7;
    border-bottom: 1px solid #e8e8f7;
    z-index: -1;
}

.main-notification-list .media-body {
    margin-left: 15px;
    font-size: 13px;
}

.main-notification-list .media-body p {
    margin-bottom: 5px;
    line-height: 1.3;
    color: #444469;
}

.main-notification-list .media-body strong {
    font-weight: 500;
}

.main-notification-list .media-body span {
    display: block;
    font-size: 11px;
    color: #a8afc7;
}

.main-toggle-group-demo {
    display: flex;
}

.main-toggle-group-demo .main-toggle+.main-toggle {
    margin-left: 10px;
}

.main-dropdown-form-demo .static-dropdown {
    padding: 20px;
    background-color: #eaedf7;
    display: inline-flex;
    justify-content: center;
}

.main-dropdown-form-demo .static-dropdown .dropdown-menu {
    display: block;
    position: static;
    float: none;
}

.main-dropdown-form-demo .dropdown-menu {
    padding: 20px;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

.main-dropdown-form-demo .dropdown-title {
    font-size: 20px;
    font-weight: 700;
    color: #565673;
    margin-bottom: 5px;
}

@media (min-width: 576px) {
    .main-dropdown-form-demo .static-dropdown {
        padding: 40px;
    }
}

@media (min-width: 576px) {
    .main-dropdown-form-demo .dropdown-menu {
        width: 300px;
        padding: 30px 25px;
    }
}

.main-content-left-components {
    border-right: 1px solid #e8e8f7;
    margin-bottom: 20px;
}

.main-content-left-components .component-item label {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.main-content-left-components .component-item label~label {
    margin-top: 30px;
}

.main-content-left-components .component-item .nav-link {
    padding: 0;
    display: block;
    font-size: 13px;
    color: #3c4858;
}

.main-content-left-components .component-item .nav-link+.nav-link {
    margin-top: 5px;
}

.main-content-left-components .component-item .nav-link.active {
    font-weight: 500;
}

input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.main-body #chartLine {
    height: 275px !important;
}

.legend {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 2px;
    margin-right: 10px;
    margin-top: 6px;
}

.fullscreen .fullscreen {
    display: none;
}

.fullscreen .exit-fullscreen {
    display: block !important;
}

.exit-fullscreen {
    display: none;
}

.best-emp {
    position: absolute;
    right: 0;
    top: -15px;
    height: 135px;
}

.flag-dropdown .dropdown-menu {
    width: 150px;
    padding: 8px 0;
}

.flag-dropdown .dropdown-menu img {
    min-width: 0;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    line-height: 24px;
    border-radius: 50%;
}

.flag-dropdown .dropdown-item {
    padding: 3px 10px;
    font-size: 13px;
    color: #2e2e4a;
    font-weight: 500;
}

.userlist-table img {
    max-width: inherit;
}

.userlist-table .dot-label {
    margin-top: 5px;
    margin-right: 10px !important;
}

@media (max-width: 768px) {
    .text-md-nowrap {
        white-space: nowrap !important;
    }

    .nav.main-nav-line.main-nav-line-chat.card-body {
        display: flex;
    }

    .nav.main-nav-line.main-nav-line-chat.card-body a {
        display: flex;
        padding: 0 8px;
    }

    .nav.main-nav-line.main-nav-line-chat.card-body .nav-link+.nav-link {
        margin-top: 0;
        margin-left: 0;
    }
}

@media (max-width: 576px) {
    .page-header {
        display: block !important;
    }

    .project-work.list-unstyled li {
        margin-top: 1rem;
        margin-right: 1rem;
    }

    .main-header-notification.show .dropdown-menu::before,
    .main-header-message.show .dropdown-menu::before,
    .main-profile-menu.show .dropdown-menu::before {
        display: none;
    }
}

.main-header-right .dropdown-menu .select2-container--default .select2-selection--single {
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
    border-radius: 3px 0 0 3px;
    border-right: 0;
}

@media (min-width: 992px) {
    .navbar-toggler.navresponsive-toggler {
        display: none;
    }
}

@media (max-width: 991px) {

    .main-body .mobile-logo-dark,
    .horizontalmenu .mobile-logo-dark {
        display: none;
    }

    .main-header.side-header {
        border-bottom: 1px solid #ddddf7;
    }

    .sidemenu-logo .main-logo {
        display: none;
    }

    .main-header {
        z-index: 9999;
    }

    .header-search .select2-container--default .select2-selection--single {
        background-color: #ffffff;
        border: 1px solid #e8e8f7;
        border-radius: 3px 0 0 3px;
        border-right: 0;
        height: 40px;
    }

    .sidebar {
        top: 60px !important;
    }

    .horizontalmenu .header-brand-img.desktop-logo {
        display: none;
    }

    .responsive-navbar.navbar {
        position: relative !important;
        display: -ms-flexbox;
        display: flex;
        margin: 0;
        margin-bottom: 0 !important;
        padding: 0;
    }

    .responsive-navbar.navbar .navbar-collapse {
        padding: 5px 0;
        position: fixed;
        width: 100%;
        background: #ffffff;
        margin-top: 0;
        z-index: 999;
        top: 59px;
        border-bottom: 1px solid #ffffff;
        border-top: 1px solid #eaedf7;
        box-shadow: 7px 8px 9px -2px #c5c5d8;
        left: 0;
        right: 0;
    }
}

@media (max-width: 991px) {
    .main-header-center {
        margin: 0;
        position: relative;
        margin: 0 auto;
        text-align: center;
    }

    .responsive-navbar .dropdown {
        position: initial;
        margin: auto 0;
    }

    #navbarSupportedContent-4 .dropdown-menu {
        min-width: 93% !important;
        left: 10px !important;
        right: 10px !important;
        margin: 0 auto;
        justify-content: center;
    }

    #navbarSupportedContent-4 .main-header-notification.show .dropdown-menu::before,
    #navbarSupportedContent-4 .main-header-message.show .dropdown-menu::before,
    #navbarSupportedContent-4 .main-profile-menu.show .dropdown-menu::before {
        display: none;
    }

    .main-header-notification .dropdown-menu,
    .main-header-message .dropdown-menu {
        top: 51px !important;
    }

    #navbarSupportedContent-4 .nav-link.icon {
        font-size: 18px;
    }

    .sidemenu-logo,
    .main-calendar .fc-header-toolbar {
        padding: 0 !important;
    }

    .mapcontainer.mapael svg,
    .mapcontainer1.mapael svg,
    .mapcontainer2 svg,
    .mapcontainer3 svg {
        width: 250px !important;
    }

    .breadcrumbitem {
        display: none;
    }

    .intl-tel-input input {
        width: 156px !important;
    }

    .horizontalmenu .mobile-logo-dark {
        display: none;
    }

    .sidemenu-logo {
        display: none;
    }
}

.media img {
    border-radius: 4px;
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
}

.media-logo,
.media-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    color: #ffffff;
    font-size: 21px;
    position: relative;
    top: 2px;
    border-radius: 100%;
}

.flat a {
    background: white;
    color: black;
    transition: all 0.5s;
}

.flat a:after {
    background: white;
    color: black;
    transition: all 0.5s;
}

.flat a.active:before,
.flat a:hover:before {
    color: #000000;
}

.flat a:before {
    background: white;
    box-shadow: 0 0 0 1px #ccc;
}

@media (max-width: 768px) {
    .nav.main-nav-line {
        display: block;
    }
    .page-header2 {
        display: flex;
        justify-content: space-between;
        padding: 0;
        min-height: 10px;
        margin: 125px 0 10px 0;
      }
}
@media (min-width: 769px) {
.page-header2 {
  display: flex;
  justify-content: space-between;
  padding: 0;
  min-height: 10px;
  margin: 10px 0 10px 0;
}
}
.user-lock .option-dots {
    margin: inherit !important;
    width: inherit !important;
    height: inherit !important;
    border-radius: inherit !important;
    background: transparent;
    text-align: inherit !important;
    line-height: inherit !important;
    display: initial !important;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    border-radius: 10px;
}

.box-shadow {
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
}

.box-shadow-0 {
    box-shadow: none !important;
}

.cover-image {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important;
}
.z-index9999999{
    z-index: 99999999999;
}
.border-black{
border: 1.5px solid #000;
}
/*************CHANGES HERE FOR TABLE NEBIHOURHOOD***************/
.page-header {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 1.5rem 0 1.5rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    position: relative;
    min-height: 50px;
    margin: 1.8rem 0 1.5rem 0;
    border-radius: 7px;
}

.page-header .breadcrumb {
    background: none;
    padding-left: 0 !important;
    padding: 0;
    margin-bottom: 0;
}

.page-header .breadcrumb-item a {
    color: #8f9cc0;
}

.page-header .breadcrumb-item.active {
    color: #7787bc;
}

@media (max-width: 991px) {
    .main-navbar-show .responsive-navbar.navbar .navbar-collapse {
        z-index: 9999;
    }

    .main-navbar-show .sidebar {
        z-index: 99999;
    }

    .header-search .select2-container--default .select2-selection--single .select2-selection__arrow b {
        left: 93% !important;
    }

    .jumps-prevent {
        padding-top: 0 !important;
    }

    .main-navbar.sticky.sticky-pin {
        margin-bottom: 0 !important;
    }

    .page-header {
        display: block;
    }

    .page-header .breadcrumb {
        margin-bottom: 1rem;
    }

    #mailContent .main-mail-list {
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    #mailContent .main-mail-item {
        -webkit-overflow-scrolling: touch;
    }

    .main-chat-footer {
        position: inherit;
    }

    .main-header-notification>a::after,
    .main-header-message>a::after {
        top: 40px;
    }

    .main-header-project .dropdown-menu {
        top: 46px;
    }

    .main-header-project>a::after {
        top: 40px;
    }

    .main-profile-menu .dropdown-menu {
        top: 51px;
    }

    .main-profile-menu>.main-img-user::before {
        bottom: -20px;
    }

    .main-header {
        position: fixed;
        box-shadow: none;
        border-bottom: 1px solid #f0f2f8;
        border-top: 0;
    }

    .main-logo .header-brand-img {
        margin-right: 10px;
    }

    .side-header {
        position: fixed !important;
    }

    .main-sidebar-hide .side-header {
        left: 0px;
        right: 0px;
    }

    .main-sidebar-hide .sidemenu-logo {
        padding: 5px;
    }

    .main-content .page-header .btn.btn-list {
        padding-left: 0;
        text-align: left;
    }

    #example-input.dataTable>tbody>tr.child ul.dtr-details>li {
        display: flex !important;
    }
}

@media (max-width: 767px) {
    .main-content-body-contacts {
        border-top: 1px solid #f0f2f8;
    }

    div.dataTables_wrapper div.dataTables_filter input {
        margin-left: 0 !important;
    }

    div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
        padding-right: 0;
        padding: 0;
    }

    .card-option {
        display: inline-block !important;
    }

    .card-option-title {
        margin-bottom: 10px;
    }

    .header-search.dropdown {
        position: initial !important;
    }

    .header-search .dropdown-menu {
        position: absolute !important;
        left: -1px !important;
        right: -1px !important;
        width: 100% !important;
        top: 100% !important;
    }

    .main-content.side-content .page-header,
    .main-content .page-header {
        padding-top: 63px !important;
    }

    .header-search.show .dropdown-menu::before {
        display: none;
    }

    .timeline .btn {
        font-size: 12px;
    }

    .vtimeline:before {
        left: 6px !important;
    }

    .vtimeline .timeline-wrapper {
        padding-right: 0 !important;
    }

    .vtimeline .timeline-wrapper .timeline-badge {
        left: 0 !important;
    }

    .vtimeline .timeline-wrapper .timeline-panel {
        width: auto !important;
        margin-left: 10% !important;
    }

    .vtimeline .timeline-wrapper .timeline-panel:after {
        border-right: 14px solid #cad4e4 !important;
        border-left: 0 solid #cad4e4 !important;
        left: -14px !important;
        right: auto !important;
    }

    .vtimeline .timeline-wrapper.timeline-inverted {
        padding-left: 0 !important;
    }

    .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
        margin-right: 0 !important;
        width: auto !important;
    }
}

@media (max-width: 380px) {
    .main-header-message {
        display: none;
    }

    .carousel-slider #thumbcarousel .carousel-item .thumb {
        margin: 5px !important;
    }

    .main-nav-line-chat .nav-link {
        font-size: 12px;
    }

    .main-mail-header>div:last-child {
        display: none;
    }
}

@media (max-width: 575.98px) {
    .main-logo .header-brand-img.desktop-logo {
        display: none;
    }

    .main-logo .header-brand-img.icon-logo {
        display: block;
    }

    .flot-chart1 {
        height: 200px !important;
    }

    .main-contact-info-header .media-body .nav-link {
        font-size: 12px;
    }

    .modal.pos-static .modal-footer {
        display: block;
    }

    .modal.pos-static .modal-footer .btn {
        width: 100%;
    }

    .modal.pos-static .modal-footer> :not(:first-child) {
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (min-width: 992px) {
    .sticky {
        width: 100%;
    }

    .horizontalmenu .sticky-pin {
        position: fixed !important;
        top: 0 !important;
        width: 100%;
        z-index: 99;
    }

    .main-navbar.sticky.sticky-pin {
        border-bottom: 1px solid #f0f2f8;
    }

    .main-content {
        padding-top: 58px;
        padding-top: 58px;
    }

    .main-content.side-content {
        padding-top: 63px;
    }

    .main-content-app,
    .main-content-calEndar,
    .main-content-contacts {
        display: flex;
    }

    .main-error-wrapper h1 {
        font-size: 12rem;
    }

    .horizontalmenu .main-footer {
        padding-left: 0;
    }
}

.flag-icon {
    font-size: 24px;
    line-height: 1;
    border-radius: 100%;
}

#back-to-top {
    color: #ffffff;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    display: none;
    text-align: center;
    z-index: 10000;
    height: 32px;
    width: 32px;
    line-height: 15px;
    background-repeat: no-repeat;
    border: 2px solid transparent;
    background-position: center;
    transition: background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -webkit-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
    border-radius: 3px;
}

#back-to-top i {
    font-size: 14px;
}

#back-to-top:hover {
    background: #ffffff !important;
    color: var(--primary-bg-color) !important;
}

.contact-icon {
    display: inline-flex;
    width: 35px;
    height: 35px;
    text-align: center;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.contact-icon:hover {
    background: #f0f2f8;
}

.custom-leftnav .main-content-left-components {
    position: -webkit-sticky;
    position: sticky;
    border-right: 0;
    top: 4rem;
}

.component-item a:before {
    content: "\e92f";
    margin-right: 8px;
    position: relative;
    font-family: "feather" !important;
    color: #68798b;
}

.main-icon-list {
    padding: 10px;
    border: 1px solid #f0f2f8;
}

.description-block {
    display: block;
    margin: 10px 0;
    text-align: center;
}

.skill-tags a {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 0px;
    color: #a8afc7;
    font-size: 12px;
    border: 1px solid #f0f2f8;
}

.reviewnavs {
    padding-left: 0;
    list-style: none;
    display: inline-flex;
}

.reviewnavs li {
    margin-right: 5px;
}

.reviewnavs li a {
    color: #a8afc7;
}

.dash-icon {
    position: absolute;
    right: 10px;
    font-size: 3rem;
    opacity: 0.5;
    color: #d3d9e4;
    top: 10px;
}

.main-traffic-detail-product-item {
    margin-bottom: 1.5rem;
}

.price-img {
    padding-top: 2rem;
}

.price-img i {
    font-size: 3rem;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    line-height: 100px;
}

.price-1 .list-unstyled li {
    padding: 7px 0;
}

.card-pricing h1 {
    font-size: 2.5rem !important;
    padding: 15px 30px;
    text-align: center;
}

.pricing-title {
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    font-weight: 500;
}

.card-pricing2 h1,
.card-pricing3 h1,
.card-pricing4 h1 {
    font-size: 2.5rem !important;
    padding: 15px 30px;
    text-align: center;
}

.card-pricing .list-unstyled li,
.card-pricing2 .list-unstyled li,
.card-pricing3 .list-unstyled li,
.card-pricing4 .list-unstyled li {
    padding: 0.5rem 0;
    border-bottom: 0;
    display: block;
}

.pricing-tabs .pri-tabs-heading {
    padding: 15px;
    text-align: center;
    margin: 0 auto;
    display: inline-table;
}

.pricing-tabs .nav-price li a {
    padding: 10px 20px 10px 20px;
    color: #828db1;
    background: #ffffff;
    border: 1px solid #f0f2f8;
}

.pricing-tabs .nav-price li .active {
    background: #ececfc;
}

.pricing-tabs .tab-content {
    padding: 15px;
    margin-top: 1.5rem;
}

.pricing1 .price {
    font-size: 2.5rem;
}

.pricingTable2 {
    text-align: center;
    background: #ffffff;
    border: 1px solid #e0e5f3;
}

.pricingTable2-header {
    padding: 25px 10px;
    background-color: #ffffff;
    border-radius: 6px !important;
}

.pricingTable2 .pricingTable2-header h3 {
    font-size: 21px;
    letter-spacing: 2px;
    color: #000000;
    text-transform: uppercase;
    margin: 0px;
}

.pricingTable2-header span {
    display: none;
    font-size: 12px;
}

.card-pricing.pricing1 .list-unstyled li {
    display: block;
}

.pricing-plans {
    padding: 25px 0;
}

.price-value1 {
    font-size: 25px;
    line-height: 35px;
    color: #ffffff;
    display: inline-block;
    position: relative;
}

.price-value1 i {
    position: absolute;
    top: 3px;
    font-size: 22px;
}

.price-value1 span {
    font-size: 40px;
    color: #ffffff;
    margin-left: 17px;
}

.pricing-plans .month {
    color: #ffffff;
    display: inline-block;
    font-size: 15px;
    margin-top: 15px;
    font-weight: 100;
}

.pricingContent2 ul {
    padding: 0 15px;
    margin-bottom: 0;
    list-style: none;
}

.pricingContent2 ul li {
    padding: 25px 0 0 0;
    text-transform: capitalize;
}

.pricingContent2 ul li:last-child {
    padding-bottom: 25px;
}

.price-value-content ul li {
    padding: 8px 0;
    text-transform: capitalize;
}

.pricingTable2 .pricingTable2-sign-up {
    padding: 25px 0;
    border-top: 1px solid #e0e5f3;
}

.pricingTable2 .btn-block {
    width: 50%;
    margin: 0 auto;
    padding: 10px 0;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 50px;
    transition: 0.3s ease;
}

.pricingTable2.green .pricing-plans {
    background-color: #10d403;
}

.pricingTable2.info .pricing-plans {
    background-color: #0ab2e6;
}

@media screen and (max-width: 990px) {
    .pricingTable2 {
        margin-bottom: 30px;
    }
}

.example {
    padding: 1rem;
    border: 1px solid #f0f2f8;
    font-size: 0.9375rem;
}

.example+.highlight {
    border-top: none;
    margin-top: 0;
}

.text-wrap> :last-child {
    margin-bottom: 0;
}

.highlight {
    margin: 1rem 0 2rem;
    border: 1px solid #f0f2f8;
    border-radius: 0px;
    font-size: 0.9375rem;
    max-height: 20rem;
    background: #edeff7;
}

.clip-widget {
    position: relative;
    overflow-y: scroll;
}

.clipboard-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    background: #ffffff;
    border: 1px solid #f0f2f8;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.user-card .main-img-user {
    margin: 0 auto;
}

.icon-service {
    display: inline-flex;
    width: 3.5rem;
    height: 3.5rem;
    padding: 1.1rem;
    margin-bottom: 0.5rem;
}

.icon-service i {
    font-size: 1.2rem;
}

.our-team .picture {
    height: 100px;
    width: 100px;
    margin: 0 auto;
    z-index: 0;
    position: relative;
    overflow: hidden;
}

.our-team:hover .picture::before {
    height: 100%;
}

.our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.our-team .picture img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
    box-shadow: 0 0 0 3px #f0f2f8;
    transform: scale(0.8);
}

.iti__hide {
    display: none;
}

.token.operator,
.token.entity,
.token.url {
    background: none;
}

.language-css .token.string,
.style .token.string,
.token.variable {
    background: none;
}

.user-lock img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
}

.eamil-body {
    margin-top: 30px;
}

.task-list {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 30px 0 0;
    color: #3c4858;
}

.task-list:after {
    content: "";
    position: absolute;
    background: #f0f2f8;
    height: 100%;
    width: 1px;
    top: 0;
    left: 30px;
    z-index: 1;
}

.task-list li {
    margin-bottom: 30px;
    padding-left: 55px;
    position: relative;
}

.task-list li:last-child {
    margin-bottom: 0;
}

.task-list li .task-icon {
    position: absolute;
    left: 22px;
    top: 13px;
    border-radius: 50%;
    padding: 2px;
    width: 17px;
    height: 17px;
    z-index: 2;
}

.task-list li h6 {
    margin-bottom: 5px;
}

.item-card .product-info {
    padding: 0;
    margin: 0;
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: -30px;
    z-index: 1;
    transition: all 0.5s ease 0s;
}

.item-card:hover .product-info {
    opacity: 1;
    bottom: 30%;
}

.acc-step-number {
    padding: 5px 10px;
    font-size: 14px;
}

.dt-button-collection .dropdown-item.active,
.dt-button-collection .dropdown-item:active {
    color: #8f9cc0;
    text-decoration: none;
    background-color: #ffffff;
}

.advanced-search {
    background: #ffffff;
    padding: 1.25rem;
    box-shadow: 0 8px 16px 0 rgba(162, 169, 204, 0.24);
    margin-bottom: 20px;
}

.theme-logos {
    display: none;
}

.dot-label {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
}

.contact-info .contact-icon:hover {
    color: #000000;
}

.scroll-widget {
    width: 100%;
    height: 385px;
    overflow: hidden;
}

.activity-block .task-list {
    padding-top: 0;
}

.activity-block .task-list li {
    padding-left: 3rem;
    margin-bottom: 20px;
}

.activity-block h6 {
    margin-bottom: 0px;
}

.activity-block .task-list:after {
    left: 18px;
    z-index: 0;
}

.activity-block .task-list li .task-icon {
    top: 0;
    left: 1px;
    width: 35px;
    height: 35px;
    text-align: center;
    position: absolute;
    line-height: 32px;
    font-size: 15px;
    color: #ffffff;
}

.z-index2 {
    z-index: 2;
}

#global-loader {
    position: fixed;
    z-index: 50000;
    background: #ffffff;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: block;
}

.loader-img {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 43%;
    left: 0;
    margin: 0 auto;
    text-align: center;
}

.user-info {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0px;
}

.setting-header {
    font-weight: 400;
    font-size: 15px;
}

.sales-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 5px;
    line-height: 1.5;
    text-align: center;
    font-size: 22px;
    padding: 0.75rem;
    margin: auto 0;
}

.gift-tittle {
    font-size: 20px;
}

.gift-box span {
    font-size: 15px;
    font-weight: 500;
}

.giftbox-img {
    width: 40%;
}

.page-title {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
}

.page-header .page-title i {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    position: relative;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    margin-right: 8px !important;
    border-radius: 7px;
    background: linear-gradient(89.1deg, rgb(240, 244, 255) 0.7%, rgb(250, 251, 254) 88.4%);
    color: #577ff5;
}

.feature {
    width: 50px;
    border-radius: 5px;
}

.feature .feature-icon {
    display: flex;
    width: 50px;
    height: 50px;
    line-height: 2em;
    vertical-align: middle;
    padding-top: 0px;
    color: #ffffff;
    font-size: 18px;
    border-radius: 8px;
    position: relative;
    align-items: center;
    text-align: center;
    left: 0;
    right: 0;
    justify-content: center;
}

.feature.bg-transparent {
    background: #fafbfe !important;
}

.browser-stats i {
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 50%;
    line-height: 30px;
    position: inherit;
    font-size: 13px;
}

.search-imgs li {
    margin-right: 1rem;
    display: contents;
}

.search-imgs li img {
    margin-right: 1rem;
    border: 1px solid #d5dae8 !important;
}

.handle-counter {
    overflow: hidden;
    display: flex;
}

.handle-counter .counter-minus,
.handle-counter .counter-plus {
    float: left;
    text-align: center;
}

.handle-counter input {
    float: left;
    text-align: center;
    height: 40px;
    border-radius: 0;
    width: 104px;
    border: 1px solid #e0e6f0;
}

.handle-counter .btn {
    padding: 9px 14px !important;
}

.counter-minus {
    border-radius: 3px 0 0 3px;
}

.counter-minus.btn {
    padding: 9px 15px !important;
}

.counter-plus {
    border-radius: 0 3px 3px 0;
}

.handle-counter .btn:disabled {
    background-color: #7a7aaf;
    cursor: not-allowed;
    color: #ffffff;
}

.handle-counter .btn:disabled:hover {
    background-color: #7a869c;
    cursor: not-allowed;
}

.whishlist {
    height: 20px;
    width: 20px;
}

.trash {
    height: 20px;
    width: 20px;
    fill: #fd6074 !important;
}

.itemside {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.itemside .info {
    padding-left: 15px;
    padding-right: 7px;
}

.itemside .title {
    font-size: 0.9rem;
    font-weight: 500;
    color: #283250;
}

.dlist-align {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.file-browser .btn-primary {
    border-radius: 0 6px 6px 0 !important;
}

.horizontalmenu .main-header {
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.03);
}

.content-height {
    height: 70vh;
}

.project .deadline {
    margin-bottom: 0;
    padding: 5px 10px;
    border-radius: 4px;
}

.project-status:before {
    content: "";
    margin-right: 12px;
}

.project-status.success:before {
    border-left: 3px solid #19b159;
}

.project-status.danger:before {
    border-left: 3px solid #f16d75;
}

.project-status.warning:before {
    border-left: 3px solid #ecb529;
}

.list-card {
    padding: 14px 18px;
    border-radius: 11px;
    margin-bottom: 20px;
    position: relative;
}

.forth.circle .chart-circle-value.circle-style {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 1;
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
}

.forth.circle canvas {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.list-card.danger {
    background: #fff3f4;
}

.list-card.success {
    background: #e8f7ee;
}

.tasks .ckbox span:before,
.tasks .ckbox span:after {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
}

.transcations.table th,
.transcations.table td {
    padding: 14px 0;
    line-height: 1.462;
}

.transcations.table.table-hover tbody tr:hover {
    background-color: transparent;
}

.tasks .table th,
.tasks .table td {
    padding: 11px 15px;
}

@media (max-width: 991px) and (min-width: 767px) {
    .main-body.leftmenu .main-content.side-content {
        margin-top: 60px;
    }
}

@media (max-width: 599px) {
    .horizontalmenu .main-header-center {
        margin: 0 !important;
    }
}

.upgrade.custom-card {
    background: #f3f2fd;
}

.sidemenu-logo img {
    margin: 0 auto;
}

@media (max-width: 699px) {
    .horizontalmenu .page-header {
        display: block;
    }

    .breadcrumb-5 a:first-child {
        padding-left: 16px !important;
    }
}

.user-lock .option-dots:hover {
    background: transparent;
}

@media (min-width: 1173px) {
    .main-header.hor-header.top-header .form-control {
        width: auto !important;
    }

    .horizontalmenu .main-header.hor-header.top-header .main-header-center {
        margin: auto !important;
    }
}

@media (min-width: 992px) {
    .hor-header.top-header .header-brand2 .top-header-logo1 {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 13px;
    }

    .hor-header.top-header .header-brand {
        display: none;
    }
}

@media (max-width: 767px) {
    #checkoutsteps>.actions a {
        padding: 7px 12px;
    }
}

@media (max-width: 480px) {
    #group .pd-x-25 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pagination-circle li a,
    .pagination-circle li span {
        height: 26px;
        width: 26px;
        min-width: 0;
    }

    .pagination-circled .page-link {
        width: 35px;
        height: 35px;
    }

    .page-link {
        padding: 5px;
    }

    .pagination-radius li a,
    .pagination-radius li span {
        width: 30px;
        height: 30px;
        min-width: 0;
    }
}

@media (max-width: 991px) {
    #inptags .form-group {
        margin-bottom: 0rem;
    }

    .bootstrap-tagsinput input {
        display: none;
    }

    .responsive-logo a img {
        margin: 0 auto;
    }
}


@media (min-width: 1855px) {
    .searchtextres {
        width: 650px;
    }
    .payingres{
        width : 200px;
    }
}
@media (min-width: 1600px) {
    .searchtextres {
        width: 550px;
    }
    .payingres{
        width : 200px;
    }
}
@media (min-width: 1440px) {
    .searchtextres {
        width: 490px;
    }
    .payingres{
        width : 200px;
    }
}
@media (min-width: 1200px) {
    .searchtextres {
        width: 200px;
    }
    .payingres{
        width : 200px;
    }
}
@media (width: 1199px) {
    .searchtextres {
        width: 200px;
    }
    .payingres{
        width : 200px;
    }
}


*::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    transition: 0.3s background;
}

::-webkit-scrollbar-thumb {
    background: #efefef;
}

/* ###### Custom-styles ###### */
/* ###### Select Group ###### */
.selectgroup {
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.selectgroup-item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
}

.selectgroup-item+.selectgroup-item {
    margin-left: -1px;
}

.selectgroup-item:not(:first-child) .selectgroup-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.selectgroup-item:not(:last-child) .selectgroup-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.selectgroup-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

.selectgroup-button {
    display: block;
    border: 1px solid #f0f2f8;
    text-align: center;
    padding: 0.375rem 1rem;
    position: relative;
    cursor: pointer;
    border-radius: 3px;
    color: #686868;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectgroup-button-icon {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 1rem;
}

.selectgroup-input:checked+.selectgroup-button {
    z-index: 1;
    background: #e5e9f3;
}

.selectgroup-input:focus+.selectgroup-button {
    z-index: 2;
    box-shadow: none;
}

.selectgroup-pills {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: start;
    align-items: flex-start;
}

.selectgroup-pills .selectgroup-item {
    margin-right: 0.5rem;
    -ms-flex-positive: 0;
    flex-grow: 0;
}

/* ###### Select Group ###### */
/* ###### Colorinput ###### */
.colorinput {
    margin-right: 5px;
    position: relative;
    cursor: pointer;
}

.colorinput-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.colorinput-color {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    border: 1px solid transparent;
    color: #ffffff;
    box-shadow: none;
}

.colorinput-color:before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: 0.3s opacity;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
}

.colorinput-input:checked~.colorinput-color:before {
    opacity: 1;
}

/* ###### Colorinput ###### */
/* ###### ie css ###### */
*::-ms-backdrop,
.b-img {
    height: 197px !important;
}

@media (min-width: 1200px) {

    *::-ms-backdrop,
    .main-navbar .nav-sub-mega .nav {
        min-width: 0 !important;
    }
}

*::-ms-backdrop,
.d-sm-flex.media.media-list {
    display: initial;
}

*::-ms-backdrop,
.t-img {
    height: 197px !important;
}

*::-ms-backdrop,
.draggable-img,
*::-ms-backdrop,
.blog-img {
    height: 258px !important;
}

*::-ms-backdrop,
.main-error-wrapper {
    align-items: inherit !important;
}

*::-ms-backdrop,
.search-imgs {
    display: -ms-inline-flexbox;
}

*::-ms-backdrop,
.main-content-body-profile .media-body {
    flex: inherit;
}

*::-ms-backdrop,
.main-msg-wrapper {
    display: inline-table;
}

*::-ms-backdrop,
.flex-wrap.ht-50.mb-lg-4 {
    height: inherit;
    margin-bottom: 0;
}

*::-ms-backdrop,
.btn-icon {
    width: inherit;
    height: inherit;
}

*::-ms-backdrop,
.bg-img:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
    top: 0;
}

*::-ms-backdrop,
.crypto-transcation .media-body {
    flex: inherit;
}

*::-ms-backdrop,
.sales-product-info #chart {
    min-height: 219px !important;
}

*::-ms-backdrop,
.main-footer {
    width: 100%;
    position: relative;
    bottom: -5px;
    margin-top: 1.5rem;
    margin-bottom: -10px;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
}

*::-ms-backdrop,
.main-content {
    height: 100%;
}

*::-ms-backdrop,
#checkoutsteps .item .thumb {
    display: inline;
}

*::-ms-backdrop,
.transcation-crypto .wd-30 {
    width: inherit;
}

*::-ms-backdrop,
.breadcrumb-4 ol {
    justify-content: flex-end;
    display: flex;
}

*::-ms-backdrop,
.breadcrumb-3 ol {
    justify-content: center;
    display: flex;
}

*::-ms-backdrop,
html.fullscreen {
    width: 100%;
}

*::-ms-backdrop,
html.fullscreen .main-content {
    overflow: scroll;
    overflow-x: hidden;
    height: 100vh;
}

*::-ms-backdrop,
.owl-item .wd-30,
.table-responsive-sm .wd-30 {
    width: inherit;
}

*::-ms-backdrop,
.table-responsive .media-body {
    flex: inherit;
}

*::-ms-backdrop,
body.horizontalmenu {
    display: flex;
    flex-direction: column;
}

/* ###### ie css ###### */
.table-hover>tbody>tr:hover {
    --bs-table-accent-bg: transparent;
}

.blockquote-footer {
    margin-top: 0rem;
}

.table> :not(caption)>*>* {
    background-color: transparent;
    box-shadow: none;
}

.table-responsive .table> :not(caption)>*>* {
    border-bottom-width: 1px !important;
}

.card-table.table> :not(caption)>*>* {
    border-bottom-width: 0px !important;
}

.alert .btn-close {
    position: absolute;
    top: 3px;
    right: 15px;
    color: inherit !important;
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    background: none;
    font-size: 1.5rem;
    text-shadow: none;
    opacity: 0.7;
    transition: 0.3s color;
}

.btn-close:focus {
    box-shadow: none;
}

.btn-close:hover {
    color: inherit;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: none;
}

.toast-header .btn-close {
    font-weight: 300;
    color: #a8afc7;
    margin-top: 4px;
    font-size: 10px;
}

.crypto-buysell-card .select2-container--default .select2-selection--single {
    border-radius: 0 6px 6px 0 !important;
}

hr {
    opacity: 0.1;
}

.card-dashboard-calendar .table> :not(caption)>*>* {
    border-bottom-width: 0px !important;
}

.crypto-wallet .table> :not(caption)>*>* {
    border-bottom-width: 0px !important;
}

.form-select {
    padding: 0.175rem 2.25rem 0.175rem 0.75rem;
}

.hor-header.top-header {
    display: none;
}

.centerlogo-horizontal .header.top-header {
    display: none;
}

.centerlogo-horizontal .hor-header.top-header {
    display: block;
}

@media (max-width: 380px) {

    .btn,
    .sp-container button {
        font-size: 0.765rem;
        padding: 0.475rem 0.75rem;
    }

    .chartjs-wrapper-demo #chartDonut,
    .chartjs-wrapper-demo #chartPie {
        height: 220px !important;
        width: 250px !important;
    }
}

.input-group .btn.ripple.btn-primary {
    border-radius: 0 6px 6px 0 !important;
}

.carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
}

.card-img-left {
    width: 100%;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.card-img-top {
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.file-manger-icon img {
    width: 70px;
    height: 70px;
}

.file-manger-icon {
    position: relative;
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

.option-dots {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    margin: 0 auto;
    text-align: center;
    line-height: 32px;
    display: block;
}

.tag.tag-attachments {
    padding: 4px 15px 2px 15px;
    font-size: 15px;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.tag.tag-attachments-lg {
    padding: 5px 17px 3px 17px;
    font-size: 17px;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.tag.tag-attachments-sm {
    padding: 3px 10px 1px 10px;
    font-size: 13px;
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.tags .tag-files span {
    position: absolute;
    top: 21px;
    margin-right: 104px;
    left: 51px;
    font-size: 12px;
    color: #a5b1d9;
}

.attached-file-grid6 .icons li a {
    font-size: 13px;
    line-height: 30px;
    text-align: center;
    height: 30px;
    width: 30px;
    margin: 0 auto;
    border-radius: 5px;
    display: block;
    position: relative;
    transition: all 0.3s ease-in-out;
    background: #ffffff;
}

.attached-file-grid6 .icons li a {
    color: var(--primary-bg-color);
    border: 1px solid #ffffff;
}

.attached-file-grid6 .icons {
    padding: 0;
    margin: 0;
    list-style: none;
    opacity: 0;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    bottom: -3px;
    z-index: 1;
    transition: all 0.5s ease 0s;
    display: block;
    margin: 0 auto;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 100%);
}

.pro-img-box {
    position: relative;
}

.attached-file-grid6 .icons li {
    margin: 0 auto;
    display: inline-block;
    opacity: 0;
    transition: all 0.4s ease;
}

.attached-file-grid6 .icons li a:hover {
    color: #ffffff;
}

.attached-file-grid6 .icons li a:hover {
    border: var(--primary-bg-color);
    background: var(--primary-bg-color);
}

.attached-file-grid6 .file-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.attached-file-image .pic-1 {
    transition: all 0.3s ease;
}

.attached-file-image .pic-2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    bottom: 0;
    right: 0;
    margin: 0 auto;
}

.attached-file-grid6:hover .attached-file-image .pic-2 {
    opacity: 0;
}

.attached-file-grid6:hover .icons {
    opacity: 1;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 10px;
}

.attached-file-grid6:hover .icons li {
    opacity: 1;
}

.image-pic {
    position: absolute;
    right: 0;
    left: 0;
    top: 0px;
    color: #ffffff;
    font-size: 17px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 100%);
    padding: 0px 0px 20px 10px;
    border-radius: 5px;
}

.theme-container .active {
    border: 1px solid var(--primary-bg-color);
    padding: 5px 10px;
    background: white;
    border-radius: 5px;
    color: var(--primary-bg-color);
}

.theme-container1 .active {
    border: 1px solid #f1388b;
    padding: 5px 10px;
    background: white;
    border-radius: 5px;
    color: #f1388b;
}

.theme-container2 .active {
    border: 1px solid #05c3fb;
    padding: 5px 10px;
    background: white;
    border-radius: 5px;
    color: #05c3fb;
}

@media (max-width: 420px) {
    .pcr-app[data-theme=classic] {
        max-width: 70vw !important;
    }
}

.show-entries .select2-container {
    width: 60px !important;
}

.filter-group .select2-container {
    width: 90px !important;
}

.productdesc .select2-container {
    width: 100px !important;
}

div.dataTables_wrapper div.dataTables_length label .select2-container {
    width: 50% !important;
}

#example-input .select2-container {
    width: 100% !important;
}

@media (min-width: 992px) and (max-width: 1300px) {
    .nav-sub.nav-sub-mega .container {
        max-width: 100%;
    }
}

.table-responsive .table> :not(caption)>*>* {
    border-bottom: 1px solid #e8e8f7 !important;
}

.dataTable.table> :not(caption)>*>* {
    border-bottom: 0 !important;
}

.pagination-radius .page-item+.page-item {
    margin-left: 5px;
}

.dark-layout {
    display: none;
}

.light-layout {
    display: block;
}

@media (min-width: 992px) {
    .horizontalmenu .hor-logo {
        display: block;
    }
}

.hor-logo {
    display: none;
}

.horizontalmenu .main-header .main-header-left .main-logo .desktop-logo-dark {
    display: none;
}

#slide-right {
    position: absolute;
    right: 20px;
    left: auto;
}

#slide-left {
    position: absolute;
    left: 20px;
    right: auto;
}

.slide-left,
.slide-right {
    display: none;
}

@media (max-width: 767px) {

    .main-content.side-content .page-header,
    .main-content .page-header {
        padding-top: 120px !important;
    }
}

@media (max-width: 991px) {
    .page-header {
        display: block;
        padding-top: 60px;
    }
}

@media (max-width: 370px) {
    .main-header-right .nav-link.icon {
        padding: 0.6rem 0.3rem;
    }
}

.statistics-icon {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 5px;
}

.moto-icon {
    width: 30px;
    height: 30px;
}

.moto-icon path[fill="#b2b1ff"],
.moto-icon path[fill="#d8d8ff"] {
    fill: var(--primary03);
}

.moto-icon path[fill="#6d7ee5"],
.moto-icon path[fill="#8c8aff"] {
    fill: var(--primary08);
}

.moto-icon circle {
    fill: var(--primary03);
}

.main-header-center .form-control {
    border-color: #e8e8f7;
}

.main-header-center .form-control:focus {
    background: transparent;
}

.main-header-center .form-control .select2-container--default .select2-selection--single {
    border-right: 0;
}

pre {
    background-color: #ecebfb;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f6f6ff;
}

.selectgroup-input:checked+.selectgroup-button {
    background: #e5e9f3;
}

.input-group-text {
    background-color: #f6f6ff;
    border: 1px solid #e8e8f7;
}

.irs-min,
.irs-max {
    color: #78769a;
    background: #f2f1ff;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #f2f1ff;
}

.select2-container--default .select2-selection--single {
    background-color: #ffffff;
    border: 1px solid #e8e8f7;
}

.ff_fileupload_wrap .ff_fileupload_dropzone {
    border-color: 2px dashed #dcd9f5;
    background-color: #f2f1ff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #f8f9ff !important;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #f1f2f9;
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:hover .number,
.wizard>.steps .current a:active .number {
    color: #ffffff;
}

.wizard>.steps a .number,
.wizard>.steps a:hover .number,
.wizard>.steps a:active .number {
    color: #8f9cc0;
    background-color: #eaedf7;
}

.pricing-tabs .nav-price li .active {
    background: #ececfc;
}

.table-hover tbody tr:hover {
    background-color: #f8f8ff;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e0e2f7;
    border-color: 1px solid #e0e2f7;
    color: #1d212f;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #1d212f;
}

.project .deadline {
    background: #eaedf7;
}

.peity {
    position: relative;
}

.peity:before {
    content: "5%";
    position: absolute;
}

.forth.circle .chart-circle-value.circle-style {
    background: #fcfcff;
}

.popover-secondary {
    background-color: #f1388b;
    border-color: #f1388b;
}

#crypto-donut {
    margin: 0 auto;
}

.handle-counter input:focus-visible {
    outline: none;
}

.img-responsive {
    border-radius: 11px;
}

.product-grid .price {
    display: inline-flex;
}

#myTabContent #whishlist .btn i {
    display: inline-flex;
}

.dtr-details tbody,
.dtr-details td,
.dtr-details tfoot,
.dtr-details th,
.dtr-details thead,
.dtr-details tr {
    border: 1px solid #e8e8f7;
}

@media screen and (max-width: 400px) {
    #checkoutsteps .item .left {
        display: block;
    }
}

@media (min-width: 767px) {
    .wizard>.actions {
        padding: 20px 14px;
    }
}

@media (min-width: 992px) and (max-width: 1103px) {
    .table-filter input {
        width: 100px;
    }

    .table-filter .btn {
        height: 20px;
    }
}

@media (max-width: 450px) {
    .ql-snow .ql-tooltip.ql-editing {
        left: 0 !important;
        right: 0 !important;
    }

    .ql-snow .ql-tooltip.ql-editing input {
        display: block !important;
    }

    #background p {
        font-size: 7px;
    }

    .main-header-right .nav-link.icon:hover {
        background: transparent !important;
    }
}

.ql-snow .ql-tooltip.ql-editing {
    left: 0 !important;
    top: 0 !important;
}

.main-content-left-chat .nav-tabs .nav-link {
    line-height: 0.2;
}

.main-content-left-chat .tab-content>.active {
    padding: 0;
}

.main-content-left-chat .nav-tabs .nav-link:hover {
    background-color: transparent;
    color: var(--primary-bg-color) !important;
}

#ChatBody .ps__rail-x {
    display: none;
}

.main-content-left-contacts .nav-tabs .nav-link {
    line-height: 0.2;
}

.main-content-left-contacts .tab-content>.active {
    padding: 0;
}

.main-content-left-contacts .nav-tabs .nav-link:hover {
    background-color: transparent;
    color: var(--primary-bg-color) !important;
}

.main-nav-line.nav-tabs .nav-link:hover,
.main-nav-line.nav-tabs .nav-link:focus {
    background-color: transparent;
    color: var(--primary-bg-color) !important;
}

.profile-tab.tab-menu-heading .nav-link {
    padding: 0 0.5rem;
    font-weight: 500;
}

.profile-tab.tab-menu-heading .nav-link.active {
    background-color: transparent;
    color: var(--primary-bg-color) !important;
}

@media (max-width: 580px) {
    .profile-tab.tab-menu-heading .nav {
        display: block;
    }

    .profile-tab.tab-menu-heading .nav .nav-link {
        margin-top: 5px;
    }
}

.input-group .btn {
    z-index: 0;
}

.form-control::-webkit-file-upload-button {
    padding: 0.675rem 0.75rem;
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: var(--primary-bg-color);
    color: #ffffff;
}

@media (max-width: 400px) {
    .main-nav.flex-column.navbar-expand .navbar-nav {
        flex-direction: column;
    }
}

.select-pending
  :where(.css-dev-only-do-not-override-gzal6t).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #f1911d;
  color: #fff;
}
.select-approve
  :where(.css-dev-only-do-not-override-gzal6t).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #15a552;
  color: #fff;
}
.select-reject
  :where(.css-dev-only-do-not-override-gzal6t).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #8f9cc0;
  color: #fff;
}
.select-inactive
  :where(.css-dev-only-do-not-override-gzal6t).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #01b8ff;
  color: #fff;
}
.select-delete
  :where(.css-dev-only-do-not-override-gzal6t).ant-select-single:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #fd6074;
  color: #fff;
}

.select-pending
  :where(.css-gzal6t).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f1911d;
  color: #fff;
}

.select-approve
  :where(.css-gzal6t).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #15a552;
  color: #fff;
}
.select-reject
  :where(.css-gzal6t).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #8f9cc0;
  color: #fff;
}
.select-inactive
  :where(.css-gzal6t).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #01b8ff;
  color: #fff;
}
.select-delete
  :where(.css-gzal6t).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #fd6074;
  color: #fff;
}

/*MODAL CSS FOR REPORT US SHOW BUTTON*/

/***********Headline*************/
.modal-page{
    display: flex;
    width: 100%;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.12);
    position:"relative"
    }
    .headlineframe1{
        display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    }
    .headlineframe2{
        display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    }
    .headlineframe3{
        display: flex;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    }
    .headtitle{
        flex: 1 0 0;
        color: var(--Title, #172426);
    font-family: Work Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    }
    .headlineclose{
        display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
   position: absolute;
   right:0;
    }
    /***********Headline*************/
    
    
    
    /***********Sloganline*************/
    @font-face {
        font-family: Work Sans;
        src: url('https://fonts.googleapis.com/css2?family=Rubik+Lines&family=Work+Sans&display=swap');
    }
    
    .statusframe01{
        display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    }
    .statusline{
        /* width: 319px; */
        color: var(--Title, #172426);
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    color: var(--Title, #172426);
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    }
    /***********Sloganline*************/
    
    
    
    
    /***********PosterImage*************/
    .imageframe1{
        display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    /* background: #F7F3EA; */
    width: 100%;
    }
    .imagehead{
    width: 420px;
    height: 300px;
    border-radius: 8px;

    }
    /***********PosterImage*************/
    
    
    /***********OptionMenu*************/
    .FoodMenu{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 31px;
    }
    .FoodMenu01{
        display: flex;
    padding: 4px 10px;
    align-items: flex-start;
    gap: 4px;
    border-radius: 100px;
    background: var(--Secondary, #FC9D4A);
    }
    .FoodMenuText01{
        color: var(--White, #FFF);
    font-family: Work Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }
  
    /***********OptionMenu*************/

  
    /***********CommentOption*************/
    .CommentMenu{
        display: flex;
        width: 100%;
        padding-top: 10px;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid var(--Border, #DCD8CC);
    }
    .CommentMenu01{
        display: flex;
align-items: center;
gap: 2px;
    }
    .CommentMenu02{
        display: flex;
justify-content: center;
align-items: center;
gap: 2px;
    }

    .runninstatus{
        color: var(--Text, #3F4E50);
font-family: Work Sans;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.26px;
margin: 10px;
    }
  
.daterangepicker{
z-index: 99999999 !important;
}


.tooltip-code {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-code .tooltiptext-code {
  visibility: visible;
  width: 60px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  right:30px;
  position: absolute;
  z-index: 1;
}
